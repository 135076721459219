import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Global } from '@emotion/react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionSummaryProps,
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  styled,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import { common, grey, red } from '@mui/material/colors';
import { Capabilities, getAllCapabilities, useCapabilityOverrides } from 'auth-capabilities';
import { drawerWidth } from 'config';
import { useAuth } from 'context/appContext';
import { useFeatures } from 'context/FeatureContext';
import React, { useEffect, useLayoutEffect, useState } from 'react';

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: common.white,
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper': {
    display: 'none',
  },
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
  },
}));

interface Props {
  open: boolean;
}

export const CapabilitiesOverrideDrawer = ({ open }: Props) => {
  const { hasAuth } = useAuth();
  const { overrides, setOverrides, clearAllOverrides } = useCapabilityOverrides();
  const theme = useTheme();
  const flags = useFeatures();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [expanded, setExpanded] = useState<Capabilities[]>([]);
  const drawerBleeding = 40;
  const leftDisplacement = open ? drawerWidth : theme.spacing(8.5);

  const toggleDrawer = (newOpen: boolean) => () => {
    setDrawerOpen(newOpen);
  };

  const handleExpand = (capability: Capabilities) => {
    if (!expanded.includes(capability)) {
      setExpanded(old => [...old, capability]);
      return;
    }
    setExpanded(old => old.filter(cap => cap != capability));
  };

  const capabilitiesList = getAllCapabilities();

  const handleOverride = (e: React.ChangeEvent<HTMLInputElement>, capability: Capabilities) => {
    setOverrides(capability, e.target.checked);
  };

  const handleClearAll = () => {
    clearAllOverrides();
  };

  useEffect(() => {
    if (!flags.auth_capabilities_override) {
      handleClearAll();
    }
  }, [flags]);

  return (
    <div>
      <Global
        styles={{
          '.CapabilitiesDrawer > .MuiPaper-root': {
            height: `calc(60% - ${drawerBleeding}px)`,
            width: `calc(100% - ${leftDisplacement}px)`,
            overflow: 'visible',
            left: leftDisplacement,
          },
        }}
      />
      {hasAuth(Capabilities.overrideCapabilities) && flags.auth_capabilities_override
        && (
          <Drawer
            className="CapabilitiesDrawer"
            anchor="bottom"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: -drawerBleeding,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                visibility: 'visible',
                right: 0,
                left: 0,
                cursor: 'pointer',
                backgroundColor: drawerOpen || !overrides.length ? grey[300] : red[300],
              }}
              onClick={() => setDrawerOpen(!drawerOpen)}
            >
              <Puller />
              <div style={{ display: 'inline-flex', width: '100%', alignItems: 'center' }}>
                <Typography sx={{ p: 2, color: 'text.primary' }}>
                  <strong>{overrides.length ? 'Capability Overrides Enabled' : 'No Capabilities Overriden'}</strong>
                </Typography>
              </div>
            </Box>
            <Box
              sx={{
                px: 2,
                py: 2,
                height: '100%',
                overflow: 'auto',
                backgroundColor: drawerOpen || !overrides.length ? grey[300] : red[300],
              }}
            >
              <div style={{ paddingBottom: 5 }}>
                <Button sx={{ height: 40 }} onClick={handleClearAll}>Clear All Overrides</Button>
              </div>
              {Object.entries(capabilitiesList).map(([key, value], idx) => {
                const cap = key as Capabilities;
                return (
                  <Accordion
                    key={idx}
                    expanded={expanded.includes(cap)}
                    TransitionProps={{ unmountOnExit: true }}
                  >
                    <StyledAccordionSummary sx={{ pointerEvents: 'none' }}>
                      <Switch
                        checked={overrides.find(c => c.capability == cap)?.enabled || hasAuth(cap)}
                        disabled={cap == Capabilities.overrideCapabilities}
                        sx={{ pointerEvents: 'auto' }}
                        onChange={(e) => handleOverride(e, cap)}
                      />
                      <div
                        style={{ pointerEvents: 'auto', display: 'inline-flex', width: '100%', cursor: 'pointer' }}
                        onClick={() => handleExpand(cap)}
                      >
                        <Typography sx={{ width: '33%', flexShrink: 0, paddingLeft: 2 }}>
                          {cap}
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{value.help}</Typography>
                        {expanded.includes(cap)
                          ? <UpOutlined style={{ marginLeft: 'auto', color: grey[600] }} />
                          : <DownOutlined style={{ marginLeft: 'auto', color: grey[600] }} />}
                      </div>
                    </StyledAccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Requires that <i>all</i> of the following conditions are met:
                      </Typography>
                      <List>
                        {value.checkAll.map(check => {
                          return <ListItem key={check.name}>• {check.name}</ListItem>;
                        })}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          </Drawer>
        )}
      {!hasAuth(Capabilities.overrideCapabilities) && flags.auth_capabilities_override && (
        <span>You do not have authorization to override your capabilities</span>
      )}
    </div>
  );
};
