import { Autocomplete, Chip, TextField } from '@mui/material';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import apiClient from '../api-client';

export default function FoodPicker({
  excludeTerms,
  label,
  onChangeValue,
  value,
  onBlur,
}: {
  excludeTerms?: string[],
  label: string,
  onChangeValue: (newValue: string[]) => void,
  value: string[],
  onBlur?: () => void,
}) {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<string[]>([]);
  const debouncedSearchFoods = useCallback(
    _.debounce(async (query: string) => {
      setOptions(
        (await apiClient.searchFoods(query, 0, 10, excludeTerms)).items.map(item => item.name),
      );
    }, 250),
    [excludeTerms],
  );

  useEffect(() => {
    debouncedSearchFoods(inputValue);

    return () => {
      debouncedSearchFoods.cancel();
    };
  }, [inputValue, excludeTerms]);

  return (
    <Autocomplete
      multiple
      options={options}
      onChange={(_event, newValue) => {
        onChangeValue(newValue);
      }}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onBlur={onBlur}
      renderInput={params => <TextField {...params} label={label} variant="standard" fullWidth />}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          // eslint-disable-next-line react/jsx-key
          <Chip
            component="a"
            label={option}
            {...getTagProps({ index })}
            href={`/foods/${encodeURIComponent(option)}`}
            onDelete={event => {
              getTagProps({ index }).onDelete(event);
              event.preventDefault();
            }}
            target="_blank"
          />
        ))}
      value={value}
    />
  );
}
