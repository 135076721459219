import React, { useEffect, useState } from 'react';

import { useQueries, useQuery } from '@tanstack/react-query';
import { foodApi, foodEngineApi } from 'api';
import {
  FoodApiAppApiFoodFoodSearchGetFoodSearchV2InteractiveRequest,
  FoodServingUnit,
  MealItemResponse,
  UsdaNutritionResponse,
} from 'api/generated/MNT';
import { searchByTextWithContext } from 'apiClients/search';
import { assertUnreachable } from 'async-result/utils';
import { FoodSearchItem, FoodSearchResult, stripPunctuation } from 'client-search/food-search-index';
import { useFoodSearchIndex } from 'client-search/useFoodSearchIndex';
import { config } from 'config';
import { useAuth } from 'context/appContext';
import { useFeatures } from 'context/FeatureContext';
import { usdaNutritionToFoodResponse } from 'food-editor/api-client';
import { FoodEditorValue } from 'food-editor/types';
import emptyFoodEditorValue from 'food-editor/utils/empty-food-editor-value';
import { formatNumber } from 'food-editor/utils/utils';
import _ from 'lodash';
import { NumberingSystem } from 'luxon';
import { encodeQueryParams, useQueryDebounce, useQueryNeverRefetch } from 'utils';
import { nutritionixDummyResults } from './nutritionix-dummy-results';
import { nutritionxNutrientsToUsdaNutrients } from './nutritionix-nutrients';
import { usdaDummyResults } from './usda-dummy-results';

export type ExternalFoodSearchResult = {
  id: string,
  externalId: string,
  sourceId: string,
  sourceIdAbbr: string,
  item_name: string,
  full_name: string,
  brand_name: string | null,
  serving_unit_label: string,
  servings: number,
  serving_unit_amount: number | null,
  nutrients: Partial<UsdaNutritionResponse>,
  thumbUrl?: string | null,
  sourceUrl?: string | null,
};

export const externalFoodSearchResultToFoodEditorValue = (
  externalFood: ExternalFoodSearchResult,
) => {
  const defaultValue: FoodEditorValue = emptyFoodEditorValue();

  return {
    ...defaultValue,
    foodImageUrl: externalFood.thumbUrl ? 'x-please-load:' + externalFood.thumbUrl : null,
    measures: [
      {
        label: externalFood.serving_unit_label,
        eqv: externalFood.serving_unit_amount || 1,
        qty: externalFood.servings,
        root_label: '',
        addon: false,
        ranking: 1,
      },
    ],
    term: [externalFood.brand_name, externalFood.item_name].filter(Boolean).join(', ').toLocaleLowerCase(),
    suggestedServingCount: externalFood.servings.toLocaleString(),
    suggestedServingUnitLabel: externalFood.serving_unit_label,
    suggestedServingAmountG: ((externalFood.serving_unit_amount || 1) * externalFood.servings).toLocaleString(),
    ...Object.fromEntries(
      Object.entries(usdaNutritionToFoodResponse(externalFood.nutrients)).map((
        [k, v],
      ) => [k, typeof v == 'number' ? '' + v : undefined]),
    ),
  };
};

export const safeDivNull = (a: number | null, b: number | null) => {
  if (a == null || b == null) {
    return null;
  }
  return a / b;
};

abstract class ExternalFoodApi {
  abstract sourceId: string;
  abstract sourceIdAbbr: string;
  abstract searchByText(query: string): Promise<ExternalFoodSearchResult[]>;

  _debounceCounter = 0;
  async debounce(seconds: number) {
    const counter = ++this._debounceCounter;
    await new Promise(resolve => setTimeout(resolve, seconds * 1000));
    return counter != this._debounceCounter;
  }
}

export type UsdaParsedMeasure = {
  label: string,
  servings: number,
  eqv: number,
  rank: number,
};

const matchCountLabel = (label: string): [null, null] | [number, string] => {
  if (!label) {
    return [null, null];
  }

  const [_, countStr, val] = label.match(/(\d+)\s+(.*)/) || [];
  if (!countStr || !val) {
    return [null, null];
  }

  if (isNaN(parseFloat(countStr))) {
    return [null, null];
  }

  return [parseFloat(countStr), val];
};

const parseUsdaFoodMeasures = (measures: typeof usdaDummyResults['foods'][0]['foodMeasures']) => {
  const res = measures.map(measure => {
    if (measure.disseminationText == 'Quantity not specified') {
      return null;
    }

    const [_, countStr, label] = measure.disseminationText.match(/(\d+)\s+(.*)/) || [];
    if (!countStr || !label) {
      console.warn('Failed to parse USDA measure:', measure);
      return null;
    }
    const count = isNaN(parseFloat(countStr)) ? 1 : parseFloat(countStr);

    return {
      label: label.trim().toLowerCase(),
      servings: count,
      eqv: measure.gramWeight,
      rank: measure.rank,
    } satisfies UsdaParsedMeasure;
  });
  return res.filter(Boolean) as Array<UsdaParsedMeasure>;
};

export const _usdaResultGetMeasure = (item: any): null | UsdaParsedMeasure => {
  if (item.servingSize && item.servingSizeUnit) {
    // This is a branded item, which has a defined serving size:
    //   "servingSizeUnit": "g",
    //   "servingSize": 85,
    //   "householdServingFullText": "1 slice",
    const gramServingSizes = ['g', 'grm'];
    if (!gramServingSizes.includes(item.servingSizeUnit.toLocaleLowerCase())) {
      return null;
    }

    const [count, label] = matchCountLabel(item.householdServingFullText);
    if (!count || !label) {
      return null;
    }

    return {
      label: label.trim().toLowerCase(),
      servings: count,
      eqv: item.servingSize,
      rank: 1,
    };
  }

  const measures = parseUsdaFoodMeasures(item.foodMeasures);
  measures.sort((a, b) => a.rank - b.rank);
  return measures[0] ?? null;
};

class UsdaFoodApi extends ExternalFoodApi {
  sourceId = 'usda_api';
  sourceIdAbbr = 'usda';
  usdaApiKey = 'c8QLZVgVhamZMOe4idVrnfDgGeF5ZI568JXa3Mym';

  usdaResultGetMeasure = _usdaResultGetMeasure;

  async searchByText(query: string): Promise<ExternalFoodSearchResult[]> {
    if (query.length < 3) {
      return [];
    }

    const q = {
      api_key: this.usdaApiKey,
      query: query,
    };

    const res = await fetch(`https://api.nal.usda.gov/fdc/v1/foods/search?${encodeQueryParams(q)}`);
    const resJson = await res.json();

    const results: Array<ExternalFoodSearchResult> = resJson?.foods?.map((item: any) => {
      const measure = this.usdaResultGetMeasure(item);
      if (!measure) {
        return null;
      }
      const res: ExternalFoodSearchResult = {
        id: '' + item.fdcId,
        externalId: '' + item.fdcId,
        sourceId: this.sourceId,
        sourceIdAbbr: this.sourceIdAbbr,
        item_name: item.description.toLowerCase(),
        full_name: item.description.toLowerCase(),
        brand_name: 'brandName' in item ? item.brandName.toLowerCase() as string : null,
        serving_unit_label: measure?.label,
        servings: measure?.servings || 1,
        serving_unit_amount: safeDivNull(measure?.eqv, measure?.servings || 1),
        nutrients: nutritionxNutrientsToUsdaNutrients(item.foodNutrients || []),
        thumbUrl: null,
      };
      return res;
    }) || [];

    return results.filter(i => !!i && !!i.serving_unit_amount && !!i.serving_unit_label);
  }
}

class NutritionixFoodApi extends ExternalFoodApi {
  sourceId = 'nutritionix';
  sourceIdAbbr = 'nutrx';

  _headers = {
    'x-app-id': 'bea9ffb2', // 'd1ed1089',
    'x-app-key': '2ae289d25cc7fb2585e7c448427133c0', // 'f599b192a648033bd1ace26294e64ef7',
    'x-remote-user-id': '0',
  };

  async searchByText(query: string): Promise<ExternalFoodSearchResult[]> {
    if (await this.debounce(0.2)) {
      return [];
    }

    if (query.length < 3) {
      return [];
    }

    const q = {
      query: query,
      branded: true,
      common: false,
      taxonomy: true,
      detailed: true,
    };

    const res = config.IS_DEV
      ? nutritionixDummyResults
      : await fetch(`https://trackapi.nutritionix.com/v2/search/instant?${encodeQueryParams(q)}`, {
        method: 'GET',
        headers: this._headers,
      }).then(res => res.json());

    if (!res.branded) {
      return [];
    }

    const results = res.branded
      .map((item: any) => {
        const res: ExternalFoodSearchResult = {
          id: item.nix_item_id,
          externalId: item.nix_item_id,
          sourceId: this.sourceId,
          sourceIdAbbr: this.sourceIdAbbr,
          item_name: item.food_name,
          full_name: item.brand_name_item_name,
          brand_name: item.brand_name,
          serving_unit_label: item.serving_unit.toLowerCase(),
          servings: item.serving_qty,
          serving_unit_amount: safeDivNull(item.serving_weight_grams, item.serving_qty),
          nutrients: nutritionxNutrientsToUsdaNutrients(item.full_nutrients || []),
          thumbUrl: item.photo?.thumb,
        };
        return res;
      })
      .filter((item: ExternalFoodSearchResult) => {
        // Note: there are a number of bugs which come up when a food has no
        // serving unit amount (ex, if the portion is changed, the macros will
        // become massive).
        // As a hot fix, we filter out any foods which don't have a serving
        // unit amount.
        return !!item.serving_unit_amount;
      });
    return results;
  }
}

class LoblawsFoodApi extends ExternalFoodApi {
  sourceId = 'loblaws';
  sourceIdAbbr = 'lobl';

  async searchByText(query: string): Promise<ExternalFoodSearchResult[]> {
    if (await this.debounce(0.2)) {
      return [];
    }

    if (query.length < 3) {
      return [];
    }

    const res = await fetch(
      `${config.EXTERNAL_FOOD_SEARCH_URL}/api/search_from_products/${query}`,
      {
        method: 'GET',
      },
    ).then(res => res.json());

    const results = res.map((item: any) => {
      const res: ExternalFoodSearchResult = {
        id: item.externalId,
        externalId: item.externalId,
        sourceId: this.sourceId,
        sourceIdAbbr: this.sourceIdAbbr,
        item_name: item.item_name,
        full_name: item.brand_name + ', ' + item.item_name,
        brand_name: item.brand_name,
        serving_unit_label: item.serving_unit_label.toLowerCase(),
        servings: item.servings,
        serving_unit_amount: item.serving_unit_amount || null,
        nutrients: {
          energy_kcal: item.energy_kcal,
          fat_g: item.fat_g,
          carbohydrate_g: item.carbohydrate_g,
          protein_g: item.protein_g,
          sodium_mg: item.sodium_mg,
          cholesterol_mg: item.cholesterol_mg,
          potassium_mg: item.potassium_mg,
          sugars_g: item.sugars_g,
          fiber_g: item.fiber_g,
          fattyacids_totalsaturated_g: item.fattyacids_totalsaturated_g,
          fattyacids_totaltrans_g: item.fattyacids_totaltrans_g,
          fattyacids_totalpolyunsaturated_g: item.fattyacids_totalpolyunsaturated_g,
        },
        thumbUrl: null,
      };
      return res;
    })
      .filter((item: ExternalFoodSearchResult) => {
        // Note: there are a number of bugs which come up when a food has no
        // serving unit amount (ex, if the portion is changed, the macros will
        // become massive).
        // As a hot fix, we filter out any foods which don't have a serving
        // unit amount.
        return !!item.serving_unit_amount;
      });
    return results;
  }
}

export const FOOD_ENGINE_MAX_RESULTS = 30;

export const useExternalFoodSearch = (query: string) => {
  return useFoodEngineSearch(query);
};

const useFoodEngineSearch = (query: string) => {
  const features = useFeatures();

  const queryRes = useQuery([
    'use-food-engine-search',
    query,
    features.food_engine_sources,
  ], async () => {
    if (!query) {
      return [];
    }

    const res = await foodEngineApi.searchByTerm({
      queryText: query.trim(),
      sources: features.food_engine_sources,
    }).then(res => res.data);

    let items = res.items.map(item => ({
      id: item.id,
      externalId: item.id,
      sourceId: item.source_name,
      sourceIdAbbr: item.source_name_abbr,
      item_name: item.item_name,
      full_name: item.full_name,
      brand_name: item.brand_name,
      serving_unit_label: item.serving_units[0]?.unit_label,
      servings: item.serving_units[0]?.servings,
      serving_unit_amount: item.serving_units[0]?.unit_amount_g,
      nutrients: item.nutrient_map,
      thumbUrl: item.photos?.[0]?.url,
      sourceUrl: item.source_details_page_url,
    } satisfies ExternalFoodSearchResult));

    if (items.length > FOOD_ENGINE_MAX_RESULTS) {
      const countPerSource = {} as Record<string, number>;
      items.forEach(item => {
        countPerSource[item.sourceId] = (countPerSource[item.sourceId] || 0) + 1;
      });

      while (_.sum(Object.values(countPerSource)) > FOOD_ENGINE_MAX_RESULTS) {
        const largestSource = _.sortBy(Object.entries(countPerSource), ([, count]) => -count)[0][0];
        countPerSource[largestSource] -= 1;
      }

      items = items.filter(item => {
        const count = countPerSource[item.sourceId];
        if (count > 0) {
          countPerSource[item.sourceId] -= 1;
          return true;
        }
        return false;
      });
    }

    return items;
  }, useQueryNeverRefetch);

  return {
    queries: queryRes,
    isLoading: queryRes.isLoading,
    isSuccess: queryRes.isSuccess,
    isError: queryRes.isError,
    error: queryRes.error,
    results: queryRes.data || [],
  };
};

/**
 * @deprecated delete once food engine is fully implemented
 */
const _useExternalFoodSearchLegacy = (query: string) => {
  const flags = useFeatures();

  const providers = React.useMemo(() => {
    return [
      new NutritionixFoodApi(),
      flags.food_search_use_external_usda && new UsdaFoodApi(),
      // new LoblawsFoodApi(),
    ].filter(x => !!x) as ExternalFoodApi[];
  }, [flags.food_search_use_external_usda]);

  const queries = useQueries({
    queries: providers.map(provider => ({
      queryKey: ['use-external-food-search', provider.sourceId, query],
      queryFn: () => provider.searchByText(query),
      ...useQueryNeverRefetch,
    })),
  });

  const allResults = queries.map(query => query.data || []).flat();

  return {
    queries: queries,
    isLoading: queries.some(query => query.isLoading),
    results: allResults,
  };
};

export type FoodSearchContext = Omit<
  FoodApiAppApiFoodFoodSearchGetFoodSearchV2InteractiveRequest,
  'q'
>;

export const useFoodSearch = (opts: {
  context: FoodSearchContext,
  limit?: number,
}) => {
  const { context } = opts;
  const forceLegacySearch = false;
  const limit = opts.limit ?? 20;

  const { authInfo } = useAuth();
  const foodIndex = useFoodSearchIndex();

  const [activeSearch, setActiveSearch] = React.useState({
    type: 'db' as 'db' | 'external',
    text: '',
  });

  const external = useExternalFoodSearch(activeSearch.type == 'external' ? activeSearch.text : '');

  const fastResultsQuery = useQuery<FoodSearchItem[]>([
    'use-food-search-fast',
    context,
    activeSearch.type,
    activeSearch.text,
  ], async () => {
    if (!activeSearch.text) {
      return [];
    }

    if (!foodIndex.isDone) {
      return [];
    }

    const indexResults = await foodIndex.result.search('flexi', activeSearch.text);
    return indexResults.items.slice(0, limit);
  }, {
    ...useQueryNeverRefetch,
    keepPreviousData: activeSearch.text != '',
  });

  const slowResultQuery = useQueryDebounce<FoodSearchItem[]>({
    queryKey: [
      'use-food-search-slow',
      context,
      activeSearch.type,
      activeSearch.text,
      forceLegacySearch,
    ],
    queryFn: async () => {
      if (!authInfo?.access_token) {
        return [];
      }

      const updateTime = new Date().toISOString();

      if (forceLegacySearch) {
        const remoteResults = await foodApi.appApiFoodFoodSearchSearchByText({
          search_text: activeSearch.text,
          context_user_id: context.context_user_id?.toString(),
          food_name_only: true,
        });
        return remoteResults.data.results.slice(0, limit).map((item, idx) => ({
          id: idx,
          name: item.name,
          updated_time: updateTime,
          cursor: '',
          nameNormalized: stripPunctuation(item.name),
          rxfood_id: item.rxfood_id!,
          name_translations: item.name_translations!,
          serving_units: item.serving_units!,
          food_image_url: item.food_image_url!,
        }));
      }

      const remoteResultsInternal = await foodApi.appApiFoodFoodSearchGetFoodSearchV2Interactive({
        q: activeSearch.text,
        ...context,
      });
      return remoteResultsInternal.data.matches.slice(0, limit).map((item, idx) => ({
        id: idx,
        name: item.food_name,
        updated_time: updateTime,
        cursor: '',
        nameNormalized: stripPunctuation(item.food_name),
        rxfood_id: null,
        name_translations: item.food_name_translations || null,
        serving_units: item.serving_units || [],
        food_image_url: item.food_photo_url || null,
        is_from_user_recents: item.is_from_user_recents,
      } satisfies FoodSearchItem));
    },
    debounce: 100,
    keepPreviousData: activeSearch.text != '',
  });

  return {
    fastResults: fastResultsQuery.data || [],
    fastQuery: fastResultsQuery,
    slowResults: slowResultQuery.data || [],
    slowQuery: slowResultQuery,
    externalResults: external,
    loading: fastResultsQuery.isLoading || slowResultQuery.isLoading || external.isLoading,
    clearActiveSearch: () => setActiveSearch({ type: activeSearch.type, text: '' }),
    activeSearch,
    setActiveSearch,
    foodIndex,
    indexStatusStr: `(${foodIndex.loadState.state} / ${formatNumber(foodIndex.indexSize)})`,
  };
};
