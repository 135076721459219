import { useQuery } from '@tanstack/react-query';
import { MealPhotoQueueQualityAssuranceResponse } from 'api/generated/MNT';
import { getQueuesForQa } from 'apiClients/mpq';
import { useAuth } from 'context/appContext';

export const useInitialQAItems = (opts?: {
  disabled?: boolean,
}) => {
  const { authInfo } = useAuth();
  const qaItemListQuery = useQuery<MealPhotoQueueQualityAssuranceResponse[]>(['initial-qa-list'], async () => {
    return getQueuesForQa(authInfo!.access_token, {
      data_reviewer_id: authInfo!.reviewer_id,
      is_initial: 'true',
      limit: 20,
    });
  }, {
    refetchInterval: 60 * 1000,
    enabled: !opts?.disabled,
  });
  return {
    qaItems: qaItemListQuery.isSuccess ? qaItemListQuery.data : [],
    query: qaItemListQuery,
  };
};
