import { Divider, ListItemButton, ListItemText } from '@mui/material';
import { MouseEvent } from 'react';
import { useHref, useNavigate } from 'react-router-dom';
import { LimitedFoodResponse } from '../types';
import { formatHumanTime } from './HumanTime';

export default function FoodSearchResult({
  value,
  last,
  onFoodSelect,
}: {
  value: LimitedFoodResponse,
  last: boolean,
  onFoodSelect?: (foodName: string) => void,
}) {
  const navigate = useNavigate();
  const label = value.name + (
    'updated_time' in value && value.updated_time ? ' (last updated: ' + formatHumanTime(value.updated_time) + ')' : ''
  );

  return (
    <>
      <ListItemButton
        component="a"
        href={useHref(`/foods/${encodeURIComponent(value.name)}`)}
        onClick={(event: MouseEvent) => {
          event.stopPropagation();
          if (event.ctrlKey || event.shiftKey || event.metaKey) {
            return;
          }
          event.preventDefault();
          if (onFoodSelect) {
            onFoodSelect(value.name);
          } else {
            navigate(`/foods/${encodeURIComponent(value.name)}`);
          }
        }}
      >
        <ListItemText primary={label} />
      </ListItemButton>
      {!last && <Divider />}
    </>
  );
}
