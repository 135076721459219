import foodHasTempOntology from 'food-editor/validations/food-has-temp-ontology';
import { FoodEditorAttributions, FoodEditorValue } from '../types';
import foodAlreadyAssigned from '../validations/food-already-assigned';
import mixedDishMustTotalOneHundred from '../validations/mixed-dish-must-total-one-hundred';
import ndbNumberRequired from '../validations/ndb-number-required';
import ontologyRequired from '../validations/ontology-required';
import termRequired from '../validations/term-required';

export function foodEditorGetErrors(value: FoodEditorValue, update = false) {
  const errors = [
    termRequired(value.term),
    update && ndbNumberRequired(value.ndbNumber),
    ontologyRequired(value.ontologyLevels),
    mixedDishMustTotalOneHundred(value.mixedDishComponents),
  ];
  return errors.filter((e) => !!e) as string[];
}

export function foodEditorGetWarnings(
  foodEditorAttributions: FoodEditorAttributions,
  foodStatus?: string,
  reviewer_id?: number,
  ontologyLevels?: string[],
) {
  const warnings = [
    foodAlreadyAssigned(foodEditorAttributions, foodStatus, reviewer_id),
    foodHasTempOntology(ontologyLevels),
  ];
  return warnings.filter((w) => !!w) as string[];
}
