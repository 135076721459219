import { Typography } from '@mui/material';
import { MealPhotoQueueResponse } from 'api/generated/MNT';
import { DateTime } from 'luxon';
import { useMealForQueue } from './useMealForQueue';

export const MealNameAndTimeForQueue = (props: {
  queueItem: MealPhotoQueueResponse,
}) => {
  const { queueItem } = props;

  const { meal } = useMealForQueue({ queueItem });

  if (!meal) {
    return <span>No meal associated</span>;
  }

  const mealDate = DateTime.fromSQL(meal.meal_date + ' ' + meal.meal_time);
  const queueDate = DateTime.fromISO(queueItem.created_time);

  const diff = mealDate.diff(queueDate, 'hours');
  const diffInHours = Math.abs(+diff.toFormat('h'));
  const beforeAfter = mealDate < queueDate ? ' before queue' : ' after queue';
  const diffString = diffInHours < 12
    ? ''
    : diffInHours < 24
    ? diff.toFormat('h').replace('-', '') + ' hours' + beforeAfter
    : diff.toFormat('d').replace('-', '') + ' days' + beforeAfter;

  return (
    <div>
      <Typography>
        <b>Meal:{' '}</b>
        <span>{meal?.meal_name}</span>
      </Typography>

      <Typography>
        <b>Meal time:{' '}</b>
        <span>{meal.meal_date} {meal.meal_time.substring(0, 5)}</span>
        {diffString && <i>{' '}({diffString})</i>}
      </Typography>
    </div>
  );
};
