// A material UI drawer with a search box at the top that allows for searching for foods and dis
// Clicking on a food will add it to the meal
import React, { MouseEvent, useContext } from 'react';

import { CloseOutlined, FormOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  Stack,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { dataReviewApi, foodApi } from 'api';
import type { MealItemResponse, MealResponse, UsdaNutritionResponse } from 'api/generated/MNT';
import { type MealItem } from 'apiClients/mpq';
import { postNewFoodRequest } from 'apiClients/review';
import { getFoodByQueryWithContext, type SearchItem, spellCheckFoodTerm } from 'apiClients/search';
import { FoodSearchItem } from 'client-search/food-search-index';
import { useFoodDetailsPopup } from 'components/FoodDetailsPopup';
import { MealNoteView } from 'components/MealNoteView';
import { SpellCheck } from 'components/spell-check';
import { useAuth } from 'context/appContext';
import { useFeatures } from 'context/FeatureContext';
import { logTrackedError } from 'errorTracking';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { PatientAge, PatientDiet } from 'pages/QueueItem/meal-builder/PatientAge';
import { RecentItems } from 'pages/QueueItem/meal-builder/RecentItems';
import { RecommendedMeals } from 'pages/QueueItem/meal-builder/RecommendedMeals';
import { useEffect, useMemo, useState } from 'react';
import { UseAsyncResult, useAsyncResult } from 'react-use-async-result';
import { getExternalItemSourceLink } from 'services/FoodEngineService';
import type { DraftItem, MealItemFoodMatchDetailsSearch, MixpanelMealItemSource } from 'types/DraftItem';
import { parseQueryError } from 'utils';
import { useQueryNeverRefetch } from 'utils';
import {
  isValidMealItem,
  mealHistoryItemToMealItem,
  recentItemToMealItem,
  searchItemToMealItem,
} from 'utils/mealItems';
import { round } from 'utils/numerical';
import { defaultSearchItem } from '../../../constants';
import { useQueueItemEditor, useRelevantNutrients } from '../services/QueueItemEditorService';
import { ExternalFoodSearchResult, FOOD_ENGINE_MAX_RESULTS, useFoodSearch } from './useFoodSearch';
import { useMealForQueue } from './useMealForQueue';
import { usePatientContext } from './usePatientContext';

export type FoodSearchSelection = {
  source: MixpanelMealItemSource,
  idx: number,
  name: string,
  item: SearchItem,
};

const FoodSearchResultsColumn = (props: {
  query: UseQueryResult<unknown>,
  name: string,
  mixpanelSource: MixpanelMealItemSource,
  results: FoodSearchItem[],
  dimFoodNames?: Set<string>,
  hideFoodNames?: Set<string>,
  theme: Theme,
  saveReq?: UseAsyncResult<unknown>,
  onFoodSelect: (selection: FoodSearchSelection, idx: number, evt: MouseEvent) => void,
  onCustomSelect?: (selection: FoodSearchSelection, idx: number, evt: MouseEvent) => void,
  showFoodLinks?: boolean,
}) => {
  const { query, results } = props;
  const foodDetailsPopup = useFoodDetailsPopup();

  return (
    <Stack spacing={0} flex={1}>
      {query.isLoading && (
        <Typography variant="body1" sx={{ fontStyle: 'italic', color: 'gray' }}>Loading...</Typography>
      )}
      {query.isError && (
        <Typography variant="body1" sx={{ fontStyle: 'italic' }}>Error: {parseQueryError(query.error)}</Typography>
      )}
      {query.isSuccess && !results.length && (
        <Typography variant="body1" sx={{ fontStyle: 'italic', color: 'gray' }}>No {props.name} results.</Typography>
      )}
      {results.map((option, idx) => {
        if (props.hideFoodNames?.has(option.name)) {
          return null;
        }
        return (
          <Stack
            {...foodDetailsPopup.getShowOnHoverProps({ internalFoodName: option.name, anchorTo: 'element' })}
            key={option.name}
            sx={{
              padding: 0.5,
              '&:hover': { backgroundColor: props.theme.palette.primary.lighter },
              '& .on-hover': { display: 'none' },
              '&:hover .on-hover': { display: 'inline' },
              cursor: props.saveReq?.isPending ? 'progress' : 'default',
              opacity: props.dimFoodNames?.has(option.name) ? 0.7 : 1,
              fontWeight: option.is_from_user_recents ? 'bold' : 'normal',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            direction="row"
            onClick={(evt) => {
              props.onFoodSelect(
                {
                  idx,
                  source: props.mixpanelSource,
                  name: option.name,
                  item: option,
                },
                idx,
                evt,
              );
            }}
          >
            <Typography variant="body1">
              {option.name}
              {!!props.onCustomSelect && (
                <FormOutlined
                  style={{
                    marginLeft: '0.5em',
                    cursor: props.saveReq?.isPending ? 'progress' : 'pointer',
                    verticalAlign: 'middle',
                    color: props.theme.palette.primary.main,
                  }}
                  onClick={(evt) =>
                    props.onCustomSelect?.(
                      {
                        idx,
                        source: props.mixpanelSource,
                        name: option.name,
                        item: option,
                      },
                      idx,
                      evt,
                    )}
                />
              )}
            </Typography>
            {props.showFoodLinks && (
              <Link
                href={`/foods/${encodeURIComponent(option.name)}`}
                target="_blank"
              >
                <OpenInNewIcon
                  className="on-hover"
                  sx={{
                    fontSize: '1rem',
                    verticalAlign: 'middle',
                  }}
                  onClick={event => {
                    event.stopPropagation();
                  }}
                />
              </Link>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

export const FoodSearchResults = (props: {
  foodSearch: ReturnType<typeof useFoodSearch>,
  onFoodSelect: (selection: FoodSearchSelection, idx: number, evt: MouseEvent) => void,
  onCustomSelect?: (selection: FoodSearchSelection, idx: number, evt: MouseEvent) => void,
  onExternalSelect?: (option: ExternalFoodSearchResult, idx: number, evt: MouseEvent) => void,
  saveReq?: UseAsyncResult<unknown>,
  dimFoodNames?: Set<string>,
  hideFoodNames?: Set<string>,
  showFoodLinks?: boolean,
}) => {
  const theme = useTheme();
  const { foodSearch } = props;

  const sortedExternalResults = _.sortBy(foodSearch.externalResults.results, r => r.sourceId == 'nutritionix' ? 0 : 1);

  const showFastColumn = foodSearch.activeSearch.type == 'db';
  const showSlowColumn = true;
  const showExternalColumn = !!props.onExternalSelect && foodSearch.activeSearch.type == 'external';

  const extLink = getExternalItemSourceLink;

  const dimFastResults = useMemo(() => {
    return new Set([foodSearch.slowResults.map(r => r.name), ...(props.dimFoodNames, [])].flat());
  }, [foodSearch.slowResults, props.dimFoodNames]);

  const foodDetailsPopup = useFoodDetailsPopup();

  return (
    <Stack spacing={2} direction="row" sx={{ flex: 1 }}>
      {showFastColumn && (
        <FoodSearchResultsColumn
          query={foodSearch.fastQuery}
          results={foodSearch.fastResults}
          dimFoodNames={dimFastResults}
          hideFoodNames={props.hideFoodNames}
          mixpanelSource="ClientSearch"
          name="fast"
          theme={theme}
          saveReq={props.saveReq}
          onFoodSelect={props.onFoodSelect}
          onCustomSelect={props.onCustomSelect}
          showFoodLinks={props.showFoodLinks}
        />
      )}
      {showSlowColumn && (
        <FoodSearchResultsColumn
          query={foodSearch.slowQuery}
          results={foodSearch.slowResults}
          dimFoodNames={props.dimFoodNames}
          hideFoodNames={props.hideFoodNames}
          mixpanelSource="ElasticSearch"
          name="elastic"
          theme={theme}
          saveReq={props.saveReq}
          onFoodSelect={props.onFoodSelect}
          onCustomSelect={props.onCustomSelect}
          showFoodLinks={props.showFoodLinks}
        />
      )}
      {showExternalColumn && (
        <Stack spacing={1} flex={1}>
          {foodSearch.externalResults.isLoading && (
            <Typography variant="body1" sx={{ fontStyle: 'italic', color: 'gray' }}>Loading...</Typography>
          )}
          {foodSearch.externalResults.isError && (
            <Typography variant="body1" sx={{ fontStyle: 'italic', color: 'gray' }}>
              Error loading external results: {'' + foodSearch.externalResults.error}
            </Typography>
          )}
          {foodSearch.externalResults.isSuccess && !sortedExternalResults.length && (
            <Typography variant="body1" sx={{ fontStyle: 'italic', color: 'gray' }}>No external results.</Typography>
          )}
          {sortedExternalResults.map((res, idx) => (
            <Stack
              {...foodDetailsPopup.getShowOnHoverProps({ externalFoodRes: res, anchorTo: 'element' })}
              key={`${res.sourceId}:${res.id}`}
              flex={1}
              direction="row"
              sx={{
                cursor: 'pointer',
                '&:hover': { backgroundColor: theme.palette.primary.lighter },
                '& .on-hover': { display: 'none' },
                '&:hover .on-hover': { display: 'inline' },
              }}
              onClick={evt => {
                console.log('Selected external result:', res, idx);
                props.onExternalSelect?.(res, idx, evt);
              }}
            >
              <Stack
                flex={1}
                sx={{ padding: 1 }}
              >
                <Typography variant="body1">
                  <Stack
                    style={{
                      float: 'right',
                      display: 'inline-flex',
                      paddingRight: 5,
                      alignItems: 'flex-end',
                    }}
                  >
                    <Typography variant="body2">
                      {res.sourceIdAbbr}
                    </Typography>
                    {props.showFoodLinks && !!extLink(res) && (
                      <Link
                        href={extLink(res)!}
                        className="on-hover"
                        target="_blank"
                        style={{ marginTop: -3 }}
                      >
                        <OpenInNewIcon
                          sx={{
                            fontSize: '1rem',
                            verticalAlign: 'middle',
                          }}
                        />
                      </Link>
                    )}
                  </Stack>
                  {res.item_name}&nbsp;
                  <ExternalResultNutrientAvailabilityIcon nutrients={res.nutrients} />
                  <Typography variant="body1" style={{ whiteSpace: 'nowrap', overflow: 'ellipsis' }}>
                    <i>{res.brand_name}</i>
                  </Typography>
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

/**
 * Indicates whether an external search item has complete nutrient information.
 */
const ExternalResultNutrientAvailabilityIcon = (props: { nutrients: Partial<UsdaNutritionResponse> }) => {
  const relevantNutrients = useRelevantNutrients({
    context: 'item',
    showAllMacros: true,
  });

  const missingNutrients = useMemo(() => {
    const relevantNames = relevantNutrients.map(nutrient => nutrient.nutrient);
    return relevantNames.filter(name => typeof props.nutrients[name] !== 'number');
  }, [props.nutrients, relevantNutrients]);

  const title = missingNutrients.length > 0 ? `Missing nutrients: ${missingNutrients.join(', ')}` : '';

  if (missingNutrients.length > 4) {
    return <span title={title} style={{ color: 'red' }}>×</span>;
  }

  // note: it's uncommon for external foods to include polyols, so don't count them as missing
  if (missingNutrients.length == 0 || (missingNutrients.length == 1 && missingNutrients[0] == 'polyols_g')) {
    return <span title={title} style={{ color: 'green' }}>✓</span>;
  }
  return <span title={title} style={{ color: 'orange' }}>⚠️</span>;
};

export type FoodDrawerState = ReturnType<typeof useNewFoodDrawerState>;
export const FoodDrawerStateCtx = React.createContext<FoodDrawerState>({} as any);

export const useFoodDrawerState = () => {
  return useContext(FoodDrawerStateCtx);
};

export const useNewFoodDrawerState = () => {
  const [isOpen, setOpen] = useState(false);
  const [initialSearchText, setInitialSearchText] = useState('');
  const foodDetailsPopup = useFoodDetailsPopup();

  return {
    isOpen,
    initialSearchText,
    close: () => {
      foodDetailsPopup.forceClear();
      setOpen(false);
    },
    show: (opts: {
      initialSearchText: string,
    }) => {
      setOpen(true);
      setInitialSearchText(opts.initialSearchText);
    },
  };
};

export const FoodDrawerProvider = (props: {
  children: React.ReactNode,
}) => {
  const state = useNewFoodDrawerState();

  return (
    <FoodDrawerStateCtx.Provider value={state}>
      {props.children}
    </FoodDrawerStateCtx.Provider>
  );
};

export const FoodDrawer = (props: {
  disableUserRecents?: boolean,
}) => {
  const flags = useFeatures();
  const state = useFoodDrawerState();
  const editor = useQueueItemEditor();
  const foodLoadReq = useAsyncResult<unknown>();
  const { meal } = useMealForQueue({
    queueItem: editor.queueItem,
  });
  const foodSearch = useFoodSearch({
    context: {
      context_user_id: editor.queueItem.patient_id,
      context_meal_name: meal?.meal_name,
    },
    limit: FOOD_ENGINE_MAX_RESULTS,
  });
  const patientContext = usePatientContext(editor.queueItem);
  const foodDetailsPopup = useFoodDetailsPopup();

  const mealNote = editor.queueItem.patient_note;

  /*
  mealNote={queueItem.patient_note}
  currentDraftItem={selectedItem}
  */

  const { recentItems, recentMeals } = usePatientMealHistory(
    editor.queueItem.id,
    props.disableUserRecents ? '__xxx_disable_recents__' : foodSearch.activeSearch.text,
  );
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const [pendingCustom, setPendingCustom] = useState<
    {
      searchItem: SearchItem,
      index: number,
      mealItem: MealItem,
      initialName?: string,
    } | null
  >(null);
  const [showNewCustomFoodModal, setShowNewCustomFoodModal] = useState(false);
  const [newFoodNameMissing, setNewFoodNameMissing] = useState(false);
  const addFoodReq = useAsyncResult<unknown>();

  const { authInfo } = useAuth();

  const similarEmbItemsQuery = useQuery([
    'similar-emb-items',
    authInfo,
    editor.queueItem,
  ], async () => {
    if (!authInfo || !authInfo.reviewer_id) {
      return [];
    }
    /*
    DW note: commented out until mnt is merged
    const response = await dataReviewApi.appApiDataReviewerGetSimilarEmbItems({
      data_reviewer_id: authInfo?.reviewer_id,
      meal_photo_queue_id: queueItem.id,
      top_k: 16,
    });
    return response.data ?? [];
    */
    return [] as any[];
  }, {
    enabled: !!authInfo && !!authInfo.reviewer_id,
  });

  const similarEmbMeals = useMemo(() => {
    if (!flags.show_vdb_suggestions) {
      return [];
    }

    if (!foodSearch.activeSearch.text) {
      return similarEmbItemsQuery.data ?? [];
    }
    return similarEmbItemsQuery.data?.filter((item) => {
      return matchMealItem(foodSearch.activeSearch.text, item.meal);
    }) ?? [];
  }, [similarEmbItemsQuery, foodSearch.activeSearch.text, flags.show_vdb_suggestions]);

  const recentMealsByDate = useMemo(() => {
    return _(recentMeals)
      .orderBy(['meal_date', 'meal_time'], ['desc', 'desc'])
      .value();
  }, [recentMeals]);

  useEffect(() => {
    const initialText = state.initialSearchText || '';
    if (initialText) {
      foodSearch.setActiveSearch({
        type: 'db',
        text: initialText,
      });
    } else {
      foodSearch.clearActiveSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.initialSearchText]);

  useEffect(() => {
    if (state.isOpen) {
      searchInputRef.current?.focus();
    }
  }, [state.isOpen]);

  const addOrUpdateMealItem = (
    mealItem: MealItem,
    searchItem: SearchItem,
    foodMatchDetails: MealItemFoodMatchDetailsSearch,
    opts?: {
      overrideExisting: boolean,
      // When adding from patient recents, multiple items will be added at once.
      // This index is used so that the first item can replace the existing selected
      // item, and subsequent items will be added to the queue.
      fromRecentIdx?: number,
    },
  ) => {
    mixpanel.track('Meal item selected', { ...foodMatchDetails.source_details.mixpanel });
    const prevDetails = editor.selectedItem?.foodMatchDetails;
    const newSourceDetails = {
      ...(prevDetails?.source_details || {}),
      ...foodMatchDetails.source_details,
    };

    const currentDraftItem = editor.selectedItem;
    if (!currentDraftItem || (opts?.fromRecentIdx ?? 0) > 1) {
      editor.addDraftItem({
        id: null,
        item: mealItem,
        searchItem,
        queryText: foodSearch.activeSearch.text,
        foodMatchDetails,
      });
      return;
    }

    const newServingUnitOptions = searchItem.serving_units || [];
    if (newServingUnitOptions.find(unit => unit.label == currentDraftItem.item.serving_unit_label)) {
      mealItem.serving_unit_label = currentDraftItem.item.serving_unit_label;
      mealItem.serving_unit_amount = currentDraftItem.item.serving_unit_amount;
    }
    editor.updateDraftItem(currentDraftItem, {
      item: {
        ...(currentDraftItem.item || {}),
        ...mealItem,
        servings: (
          opts?.overrideExisting
            ? mealItem.servings
            : currentDraftItem.item.servings
        ),
        food_name_alias: (
          currentDraftItem.item.food_name_alias
            ? currentDraftItem.item.food_name_alias
            : mealItem.food_name_alias
        ),
        percent_eaten: currentDraftItem.item.percent_eaten ?? mealItem.percent_eaten,
        addons: [...currentDraftItem.item.addons],
        custom_addons: ((
          opts?.overrideExisting
            ? mealItem.custom_addons
            : currentDraftItem.item.custom_addons
        ) || []).concat(),
      },
      searchItem: searchItem,
      queryText: foodSearch.activeSearch.text,
      foodMatchDetails: {
        ...foodMatchDetails,
        source_details: newSourceDetails,
      },
    });
  };

  const addItemFromRecent = async (recentItem: MealItemResponse, index: number) => {
    const item = recentItemToMealItem(editor.queueItem, recentItem);
    addItemFromPatientHistory('User recent items', editor.queueItem.patient_id, item, index);
  };

  const addItemFromPatientHistory = async (
    source: MixpanelMealItemSource,
    patientId: number,
    item: MealItem,
    index: number,
    onResolve?: () => void,
  ) => {
    if (addFoodReq.isPending) {
      return;
    }
    const req = _addItemFromPatientHistory(source, patientId, item, index);
    addFoodReq.bind(req);
    if (onResolve) {
      req.then(onResolve);
    }
  };

  const _addItemFromPatientHistory = async (
    source: MixpanelMealItemSource,
    patientId: number,
    item: MealItem,
    index: number,
  ) => {
    if (item.serving_type_label == '') { // this check should be removed once all bad data for the serving_type_label is removed from the backend
      item.serving_type_label = null;
    }

    let searchItem: SearchItem | null = null;
    try {
      searchItem = await getFoodByQueryWithContext(item.food_name, patientId);
    } catch (err) {
      logTrackedError({
        sourceName: 'addItemFromPatientHistory.getFoodByQueryWithContext',
        origin: err as any,
        stackError: new Error(),
        context: { patientId, foodName: item.food_name },
        userMessage:
          `Unable to obtain item from backend for '${item.food_name}'. Data may not yet be available if entry is a custom item, please add item manually.`,
      });
      return;
    }

    const isValidItem = isValidMealItem(item);
    if (isValidItem && item.food_name != null) {
      addOrUpdateMealItem(item, searchItem, {
        user_type: 'internal',
        source_name: 'search',
        source_details: {
          mixpanel: {
            Type: 'Regular item',
            Source: source,
            'Food name': item.food_name,
            'Search text': foodSearch.activeSearch.text,
            'Add or update': 'add',
            Index: index,
            Editor: 'sidebar',
          },
        },
      }, {
        overrideExisting: true,
        fromRecentIdx: index,
      });
    } else {
      alert(
        "Error: Unable to find an appropriate item for '" + item.food_name
          + "' from historical items (attempted with search item '" + searchItem.name
          + "'). Invalid data may exist in previous entry, or not yet be available for a custom item, please add food item manually.",
      );
    }
    foodSearch.clearActiveSearch();
    state.close();
  };

  const loadFood = async (
    foodName: string,
  ) => {
    if (foodLoadReq.isPending) {
      return;
    }
    const foodQuery = foodApi.appApiFoodFoodSearchGetFoodQuery({
      food_name: foodName,
    });
    foodLoadReq.bind(foodQuery);
    foodQuery.catch(err => {
      logTrackedError({
        sourceName: 'FoodDrawer.loadFood',
        origin: err as any,
        stackError: new Error(),
        context: { foodName },
        userMessage: `Error loading food item '${foodName}'. Please try again.`,
      });
    });
    return foodQuery.then(foodResp => foodResp.data);
  };

  const handleCustomCreate = async (searchItem: SearchItem, index: number, mealItem: MealItem) => {
    setPendingCustom({ searchItem, index, mealItem });
    setTimeout(() => {
      document.getElementById('pending-custom-name')?.focus();
    }, 10);
  };

  const handleNewFoodRequest = async () => {
    setShowNewCustomFoodModal(true);
  };

  const sendNewFoodRequest = async () => {
    const newFoodName = (document.getElementById('new-food-name') as any)!.value;
    const newFoodBrand = (document.getElementById('new-food-brand') as any)!.value;
    const newFoodManufacturer = (document.getElementById('new-food-manufacturer') as any)!.value;
    const newFoodUrl = (document.getElementById('new-food-url') as any)!.value;
    if (!newFoodName || !showNewCustomFoodModal) {
      setNewFoodNameMissing(true);
      return;
    }

    if (!authInfo) {
      return;
    }

    const checked = await spellCheckFoodTerm(newFoodName);
    if (checked != newFoodName) {
      if (
        !window.confirm(`Possible spelling error detected in "${newFoodName}". Proceed with this custom item name?`)
      ) {
        return;
      }
    }

    const { access_token, reviewer_id } = authInfo;
    const success = await postNewFoodRequest(
      reviewer_id,
      newFoodName,
      newFoodBrand,
      newFoodManufacturer,
      newFoodUrl,
      access_token,
    );

    if (success) {
      const customItem: MealItem = {
        addons: [],
        custom_addons: [],
        custom_item: true,
        custom_item_source: 'baseless',
        custom_item_source_id: null,
        custom_usda_id: null,
        food_name: newFoodName,
        food_name_alias: null,
        food_replacement_name: null,
        meal_photo_id: editor.queueItem.meal_photo_id,
        note: null,
        nutrition_source: null,
        serving_type_label: null,
        serving_type_multiplier: null,
        serving_unit_amount: defaultSearchItem.serving_units![0].amount,
        serving_unit_label: defaultSearchItem.serving_units![0].label,
        servings: 1,
      };

      addOrUpdateMealItem(
        customItem,
        defaultSearchItem,
        {
          user_type: 'internal',
          source_name: 'search',
          source_details: {
            mixpanel: {
              Type: 'Regular item',
              Source: 'Custom: baseless',
              'Food name': newFoodName,
              'Search text': foodSearch.activeSearch.text,
              'Add or update': 'add',
              Index: -1,
              Editor: 'sidebar',
            },
          },
        },
      );
      setShowNewCustomFoodModal(false);
      setNewFoodNameMissing(false);
      foodSearch.clearActiveSearch();
      state.close();
    }
  };

  const confirmPendingCustom = async () => {
    const newCustomName = (document.getElementById('pending-custom-name') as any)!.value;
    if (!pendingCustom) {
      return;
    }
    const { searchItem, index, mealItem } = pendingCustom;
    if (!newCustomName) {
      return;
    }

    const item: MealItem = mealItem.custom_item_source != null
      ? {
        ...mealItem,
        food_name: newCustomName,
      }
      : {
        addons: [],
        custom_addons: [],
        custom_item: true,
        custom_item_source: 'ia_db',
        custom_item_source_id: searchItem.name,
        custom_usda_id: searchItem.nutrition_source_id!,
        food_name: newCustomName,
        food_name_alias: null,
        food_replacement_name: null,
        meal_photo_id: editor.queueItem.meal_photo_id,
        note: null,
        nutrition_source: null,
        serving_type_label: null,
        serving_type_multiplier: null,
        serving_unit_amount: searchItem.serving_units![0].amount,
        serving_unit_label: searchItem.serving_units![0].label,
        servings: 1,
      };

    const itemSource: MixpanelMealItemSource = item.custom_item_source == 'ia_db'
      ? 'Custom: internal'
      : item.custom_item_source
      ? 'Custom: external'
      : 'Custom: baseless';

    const itemExtSourceId = item.custom_item_source && item.custom_item_source != 'ia_db'
      ? item.custom_item_source.replace('ext:', '')
      : null;
    const mixpanelExtra = itemExtSourceId
      ? {
        'Engine source order': flags.food_engine_sources,
        'Engine source match idx': (() => {
          let prevSource: string | null = null;
          let prevSourceCount = 0;
          for (const res of foodSearch.externalResults?.results ?? []) {
            if (res.sourceId == itemExtSourceId) {
              return prevSourceCount;
            }
            if (prevSource != res.sourceId) {
              prevSource = res.sourceId;
              prevSourceCount++;
            }
          }
          return -1;
        })(),
        'Engine source engine idx': (
          flags.food_engine_sources
            .split(',')
            .map(x => x.trim())
            .indexOf(itemExtSourceId)
        ),
      }
      : {};

    // const checked = await spellCheckFoodTerm(newCustomName);
    // if (checked != newCustomName) {
    //   if (
    //     !window.confirm(`Possible spelling error detected in "${newCustomName}". Proceed with this custom item name?`)
    //   ) {
    //     return;
    //   }
    // }
    const [nutritionSource, nutritionSourceId] = searchItem.nutrition_source && searchItem.nutrition_source_id
      ? [searchItem.nutrition_source, searchItem.nutrition_source_id]
      : (mealItem.nutrition_source?.indexOf(':') ?? -1) > 0
      ? mealItem.nutrition_source!.split(':')
      : [null, null];

    addOrUpdateMealItem(
      item,
      defaultSearchItem,
      {
        user_type: 'internal',
        source_name: 'search',
        source_details: {
          mixpanel: {
            Type: 'Custom item',
            Source: itemSource,
            'Add or update': 'add',
            'Food name': newCustomName,
            'Search text': foodSearch.activeSearch.text,
            'Custom item name': newCustomName,
            'Custom item source': item.custom_item_source,
            Index: index,
            Editor: 'sidebar',
            ...mixpanelExtra,
          },
          nutrition_source: nutritionSource
            ? {
              nutrition_source: nutritionSource,
              nutrition_source_id: nutritionSourceId,
            }
            : undefined,
        },
      },
      {
        overrideExisting: true,
      },
    );
    setPendingCustom(null);
    if (mealItem.nutrition_source != null) {
      setShowNewCustomFoodModal(false);
      setNewFoodNameMissing(false);
    }
    foodSearch.clearActiveSearch();
    state.close();
  };

  const addItemFromFoodSearch = (selection: FoodSearchSelection, idx: number) => {
    if (addFoodReq.isPending) {
      return;
    }
    addFoodReq.bind(_addItemFromFoodSearch(selection, idx));
  };

  const _addItemFromFoodSearch = async (selection: FoodSearchSelection, idx: number) => {
    if (selection.name === 'unknown food') {
      selectUnknownFood();
      return;
    }
    const food = await loadFood(selection.name);
    if (!food) {
      return;
    }
    const mealItem = searchItemToMealItem(food);
    const prepMethodOverride = (
      food.brand
        ? undefined // don't override prep method for branded foods
        : editor.queueItem.preparation_method // otherwise use the prep method from the queue item
    );
    addOrUpdateMealItem(
      {
        ...mealItem,
        preparation_method: prepMethodOverride,
      },
      food,
      {
        user_type: 'internal',
        source_name: 'search',
        source_details: {
          mixpanel: {
            Type: 'Regular item',
            Source: selection.source,
            'Food name': mealItem.food_name,
            'Search text': foodSearch.activeSearch.text,
            'Add or update': 'add',
            Index: -1,
            Editor: 'sidebar',
          },
        },
      },
    );
    foodSearch.clearActiveSearch();
    state.close();
  };

  const selectUnknownFood = () => {
    const customItem: MealItem = {
      addons: [],
      custom_addons: [],
      custom_item: true,
      custom_usda_id: null,
      food_name: 'unknown food',
      food_name_alias: null,
      food_replacement_name: null,
      meal_photo_id: editor.queueItem.meal_photo_id,
      note: null,
      nutrition_source: null,
      serving_type_label: null,
      serving_type_multiplier: null,
      serving_unit_amount: defaultSearchItem.serving_units![0].amount,
      serving_unit_label: defaultSearchItem.serving_units![0].label,
      servings: 1,
      custom_item_source: null,
      custom_item_source_id: null,
    };
    addOrUpdateMealItem(
      customItem,
      defaultSearchItem,
      {
        user_type: 'internal',
        source_name: 'search',
        source_details: {
          mixpanel: {
            Type: 'Regular item',
            Source: 'Custom: baseless',
            'Food name': 'unknown food',
            'Search text': foodSearch.activeSearch.text,
            'Add or update': 'add',
            Index: -1,
            Editor: 'sidebar',
          },
        },
      },
    );
    foodSearch.clearActiveSearch();
    state.close();
  };

  const dietRestrictions = editor.queueItem.queue_metadata?.patient_context?.diet_restrictions
    ?? patientContext.context?.diet_restrictions
    ?? [];

  const handleRecommendedMealClick = (mealResponse: MealResponse, index: number, source?: string) => {
    mixpanel.track('Meal item selected', {
      Type: 'Regular item',
      Source: source,
      'Item count': mealResponse.meal_items.length,
      Index: index,
    });
    editor.addBulkDraftItems({
      replaceSelected: true,
      items: mealResponse.meal_items.map(mealHistoryItemToMealItem).map((item) => ({
        id: null,
        item,
        searchItem: null,
        queryText: null,
        foodMatchDetails: null,
      } satisfies DraftItem)),
    });
    state.close();
  };

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={state.isOpen}
      onClose={state.close}
      hideBackdrop={false}
      ModalProps={{ keepMounted: true }}
      transitionDuration={100}
      disableEnforceFocus
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: 600,
          borderRight: `1px solid #e0e0e0`,
          backgroundImage: 'none',
          borderLeft: `1px solid #e0e0e0`,
          boxShadow: '8',
        },
        '.MuiBackdrop-root': {
          opacity: '0 !important',
        },
      }}
    >
      <Dialog
        open={!!pendingCustom}
        onClose={() => setPendingCustom(null)}
        PaperProps={{
          sx: {
            position: 'fixed',
            bottom: 50,
            right: '10%',
            width: 400,
          },
        }}
        slotProps={{
          backdrop: {
            style: {
              opacity: 0.2,
            },
          },
        }}
      >
        <DialogTitle>New custom item</DialogTitle>
        <DialogContent>
          <DialogContentText>
            New custom based on{' '}
            <em>
              <strong>{pendingCustom?.searchItem.name}</strong>
            </em>:
          </DialogContentText>
          <TextField
            id="pending-custom-name"
            autoFocus
            margin="dense"
            label="Custom name"
            fullWidth
            variant="standard"
            defaultValue={pendingCustom?.initialName}
            onKeyPress={evt => {
              if (evt.key == 'Enter') {
                confirmPendingCustom();
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setPendingCustom(null)}>Cancel</Button>
          <Button onClick={confirmPendingCustom}>
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Stack spacing={1} sx={{ padding: '1rem', height: '100%' }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4">
            {editor.selectedItem
              ? (
                <span>
                  Change: <i>{editor.selectedItem.item.food_name}</i>
                </span>
              )
              : 'Add Food'}
          </Typography>
          <IconButton onClick={state.close} color="error">
            <CloseOutlined />
          </IconButton>
        </Stack>
        <Stack spacing={0}>
          <PatientAge patientContext={patientContext} />
          <PatientDiet dietRestrictions={dietRestrictions} />
          {mealNote && (
            <Typography variant="body1">
              <span style={{ fontWeight: 'bold' }}>Meal note:</span>{' '}
              <MealNoteView
                value={mealNote}
                onClick={item =>
                  foodSearch.setActiveSearch({
                    type: 'db',
                    text: item.food_name ?? '',
                  })}
                mealItems={editor.draftItems}
                queueId={editor.queueItem.id}
              />
            </Typography>
          )}
          {!!editor.selectedItem?.item?.food_name && (
            <Typography>
              <strong>Current food</strong>: {editor.selectedItem.item.food_name}
            </Typography>
          )}
        </Stack>

        <OutlinedInput
          autoComplete="off"
          inputRef={searchInputRef}
          value={foodSearch.activeSearch.text}
          onChange={(e) => {
            console.log('setting active search', e.target.value);
            foodSearch.setActiveSearch({
              type: 'db',
              text: e.target.value,
            });
            foodDetailsPopup.forceClear();
          }}
          placeholder="Search for food"
          id="history-filter"
          autoFocus={true}
          // startAdornment={<SearchOutlined />}
          onKeyUp={(evt: React.KeyboardEvent<any>) => {
            if (evt.code == 'Enter') {
              foodSearch.setActiveSearch({
                type: 'external',
                text: foodSearch.activeSearch.text,
              });
            }
          }}
          endAdornment={
            <InputAdornment position="end">
              {foodSearch.indexStatusStr}
              <IconButton
                color="secondary"
                edge="end"
                disabled={foodSearch.loading}
                onClick={() => {
                  foodSearch.setActiveSearch({
                    type: 'external',
                    text: foodSearch.activeSearch.text,
                  });
                }}
              >
                {foodSearch.loading ? <LoadingOutlined /> : <SearchOutlined />}
              </IconButton>
            </InputAdornment>
          }
        />

        {foodSearch.activeSearch.type === 'external' && (
          <SpellCheck
            term={foodSearch.activeSearch.text}
            updateSpelling={(correctSpelling) => {
              foodSearch.setActiveSearch({
                type: 'external',
                text: correctSpelling,
              });
            }}
          />
        )}

        <Box sx={{ overflowY: 'scroll', flex: 1 }}>
          {similarEmbMeals.length > 0 && (
            <RecommendedMeals
              title="Similar meals"
              mealHistory={similarEmbMeals.map(i => i.meal)}
              mealTitles={similarEmbMeals.map(i => `Score: ${i.score?.toFixed(2)}`)}
              addItemSource="Clip similar"
              handleClick={handleRecommendedMealClick}
            />
          )}

          {recentMeals.length > 0 && (
            <RecommendedMeals
              title="Recent meals"
              mealHistory={recentMealsByDate}
              addItemSource="Recent meals"
              handleClick={handleRecommendedMealClick}
            />
          )}

          {recentItems.length > 0 && (
            <Box sx={{ columnCount: 2 }}>
              <RecentItems
                items={recentItems}
                isFiltered={foodSearch.activeSearch.text != ''}
                isSavePending={addFoodReq.isPending}
                addItemFromRecent={addItemFromRecent}
              />
            </Box>
          )}

          <FoodSearchResults
            foodSearch={foodSearch}
            saveReq={addFoodReq}
            onFoodSelect={addItemFromFoodSearch}
            onCustomSelect={async (selection, idx, evt) => {
              evt.stopPropagation();
              const food = await loadFood(selection.name);
              const mealItem: MealItem = {
                addons: [],
                custom_usda_id: null,
                food_name: selection.name,
                custom_item_source: null,
                custom_item_source_id: null,
                serving_unit_amount: defaultSearchItem.serving_units![0].amount,
                serving_unit_label: defaultSearchItem.serving_units![0].label,
                servings: 1,
              };
              food && handleCustomCreate(food, idx, mealItem);
            }}
            onExternalSelect={(ext, idx, evt) => {
              evt.stopPropagation();
              const extFoodName = [ext.brand_name, ext.item_name].filter(Boolean).join(', ').toLocaleLowerCase();
              const servingUnitAmount = ext.serving_unit_amount ?? defaultSearchItem.serving_units![0].amount;
              const servings = ext.servings ?? 1;
              const customItem: MealItem = {
                addons: [],
                custom_addons: [],
                custom_item: true,
                custom_item_source: `ext:${ext.sourceId}`,
                custom_item_source_id: ext.id,
                custom_usda_id: null,
                food_name: extFoodName,
                food_name_alias: null,
                food_replacement_name: null,
                meal_photo_id: editor.queueItem.meal_photo_id,
                note: null,
                serving_type_label: null,
                serving_type_multiplier: null,
                serving_unit_amount: round(servingUnitAmount, 2),
                serving_unit_label: ext.serving_unit_label || defaultSearchItem.serving_units![0].label,
                servings: servings,
                ...ext.nutrients,
                custom_nutrient_estimates: Object.fromEntries(
                  Object.entries(ext.nutrients ?? {})
                    .map(([key, val]) => {
                      if (typeof val !== 'number') {
                        return [key, null];
                      }
                      const newVal = (val / 100) * (servings * servingUnitAmount);
                      if (isNaN(newVal)) {
                        return [key, null];
                      }
                      return [key, newVal];
                    })
                    .filter(([key, val]) => typeof val === 'number'),
                ),
              };
              if (!ext.serving_unit_amount) {
                alert('!!! WARNING !!!\n\nNo default serving unit amount (g) found! Double check macros!');
              }
              setPendingCustom({
                searchItem: {
                  ...defaultSearchItem,
                  name: extFoodName,
                },
                index: idx,
                mealItem: customItem,
                initialName: extFoodName,
              });

              // addOrUpdateMealItem(
              //   'External',
              //   customItem,
              //   {
              //     ...defaultSearchItem,
              //     queryText: foodSearch.activeSearch.text,
              //   },
              //   idx,
              // );
              // setShowNewCustomFoodModal(false);
              // setNewFoodNameMissing(false);
              // foodSearch.clearActiveSearch();
              // onClose();
            }}
          />

          {foodSearch.activeSearch.text && (
            <Box style={{ marginTop: 10 }}>
              <Button variant="contained" onClick={handleNewFoodRequest}>
                Request new food item
              </Button>
              <Dialog
                PaperProps={{
                  sx: {
                    position: 'fixed',
                    bottom: 50,
                    right: '5%',
                    width: 600,
                  },
                }}
                slotProps={{
                  backdrop: {
                    style: {
                      opacity: 0.2,
                    },
                  },
                }}
                open={showNewCustomFoodModal}
                onClose={() => setShowNewCustomFoodModal(false)}
              >
                <DialogTitle>New Food Request</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Please enter the following information about the new food
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="new-food-name"
                    label="Food Name"
                    type="text"
                    variant="standard"
                    error={newFoodNameMissing}
                    fullWidth
                    required
                  />
                  {newFoodNameMissing && (
                    <label style={{ paddingTop: '10px', color: 'red', fontSize: '12px' }}>
                      *Food name is a required field
                    </label>
                  )}
                  <TextField
                    margin="dense"
                    id="new-food-brand"
                    label="Food Brand"
                    type="text"
                    variant="standard"
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="new-food-manufacturer"
                    label="Manufacturer"
                    type="text"
                    variant="standard"
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="new-food-url"
                    label="Reference URL"
                    type="url"
                    variant="standard"
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button color="secondary" onClick={() => setShowNewCustomFoodModal(false)}>Cancel</Button>
                  <Button onClick={sendNewFoodRequest}>Submit</Button>
                </DialogActions>
              </Dialog>
            </Box>
          )}
        </Box>
      </Stack>
    </Drawer>
  );
};

const matchMealItem = (filter: string | undefined, meal: MealResponse) => {
  if (!filter) {
    return true;
  }

  const mealName = meal.meal_name.toLowerCase();
  const itemNames = meal.meal_items.map((item) => item.food_name.toLowerCase());
  const itemNameAliases = meal.meal_items.map((item) => item.food_name_alias?.toLowerCase() || '');
  const addonNames = meal.meal_items.reduce((prev, item) => {
    const addons = item.custom_addons ? item.custom_addons : [];
    return [...prev, ...(addons.map((addon) => addon.food_name.toLowerCase()))];
  }, [] as string[]);
  const searchTerms = [mealName, ...itemNames, ...itemNameAliases, ...addonNames];
  return searchTerms.some((term) => term.includes(filter.toLowerCase()));
};

const filterMealResponse = (filter: string | undefined, meals: MealResponse[]) => {
  return filter
    ? meals.filter((meal) => matchMealItem(filter, meal))
    : meals;
};

export const usePatientMealHistory = (
  queueItemId: number,
  filter?: string,
) => {
  const { authInfo } = useAuth();

  const recentItemsQuery = useQuery(['recentItems', authInfo?.reviewer_id, queueItemId], async () => {
    if (!authInfo) {
      return { results: [] };
    }
    const recentItemsResponse = await dataReviewApi.appApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewer({
      data_reviewer_id: authInfo.reviewer_id,
      meal_photo_queue_id: queueItemId,
    });
    return recentItemsResponse.data;
  }, {
    ...useQueryNeverRefetch,
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
  });

  const recentMealsQuery = useQuery(['recentMeals', authInfo?.reviewer_id, queueItemId], async () => {
    if (!authInfo) {
      return { results: null };
    }
    try {
      const recentMealsResponse = await dataReviewApi.appApiDataReviewerGetMealSimilarMeals({
        data_reviewer_id: authInfo.reviewer_id,
        meal_photo_queue_id: queueItemId,
      });
      const mh = recentMealsResponse.data.meal_history;
      if (mh) {
        mh.meals.sort((a, b) => a.meal_name <= b.meal_name ? -1 : 1);
        return { results: mh };
      }
    } catch (e) {
      logTrackedError({
        sourceName: 'usePatientMealHistory.getRecentMeals',
        origin: e as any,
        stackError: new Error(),
        context: { queueId: queueItemId },
        userMessage: `Error retrieving meal item history for queue item ${queueItemId}.`,
      });
    }
    return { results: null };
  }, {
    ...useQueryNeverRefetch,
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
  });

  const recentItems = useMemo(() => {
    if (!recentItemsQuery.isSuccess || recentItemsQuery.isError || recentItemsQuery.isFetching) {
      return [];
    }

    return filter
      ? recentItemsQuery.data.results.filter((item) => {
        const foodName = item.food_name.toLowerCase();
        const foodNameAlias = item.food_name_alias?.toLowerCase() || '';
        const baseFoodName = item.base_food_name ? item.base_food_name.toLowerCase() : '';
        const addonNames = item.addons.map((addon) => addon.toLowerCase());
        const searchTerms = [foodName, baseFoodName, foodNameAlias, ...addonNames];
        return searchTerms.some((term) => term.includes(filter.toLowerCase()));
      })
      : recentItemsQuery.data.results;
  }, [recentItemsQuery, filter]);

  const recentMeals = useMemo(() => {
    if (
      !recentMealsQuery.isSuccess || recentMealsQuery.isError || recentMealsQuery.isFetching
    ) {
      return [];
    }

    const pastMeals = recentMealsQuery.data.results ? recentMealsQuery.data.results.meals : [];
    const filtered = filterMealResponse(filter, pastMeals);
    return filtered.sort((lhs, rhs) =>
      `${lhs.meal_date} ${lhs.meal_time}`.localeCompare(`${rhs.meal_date} ${rhs.meal_time}`)
    );
  }, [recentMealsQuery, filter]);

  return {
    recentItems,
    recentMeals,
    loading: recentItemsQuery.isFetching || recentMealsQuery.isFetching,
    error: recentItemsQuery.isError || recentMealsQuery.isError,
  };
};
