/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const FoodOptionRestrictionType = {
    Includes: 'includes',
    IncludedBy: 'included_by',
    Excludes: 'excludes',
    ExcludedBy: 'excluded_by',
    Implies: 'implies',
    ImpliedBy: 'implied_by',
    Suggests: 'suggests',
    SuggestedBy: 'suggested_by'
} as const;

export type FoodOptionRestrictionType = typeof FoodOptionRestrictionType[keyof typeof FoodOptionRestrictionType];



