import { Chip, useTheme } from '@mui/material';
import { useSocketIOPresenceActiveUsers } from 'socketio/SocketIOService';

export const PatientID = (props: {
  userId: string | number | null | undefined,
  hideOffline?: boolean,
  isPriority: boolean | null | undefined,
}) => {
  const { userId, isPriority } = props;
  const theme = useTheme();
  const { activeUsersById, isConnected } = useSocketIOPresenceActiveUsers();

  if (!userId) {
    return <span />;
  }

  const isOnline = !!activeUsersById[userId];

  if (!isConnected) {
    return (
      <span title={isPriority ? '(Priority User)' : ''}>
        {userId}
        {isPriority && (
          <Chip
            style={{ marginLeft: '0.15rem', marginRight: '0.15rem' }}
            label="P"
            size="small"
            color="error"
            title="Priority patient"
          />
        )}
      </span>
    );
  }

  if (props.hideOffline && !isOnline) {
    return (
      <span title={isPriority ? '(Priority User)' : ''}>
        {userId} {isPriority && (
          <Chip
            style={{ marginLeft: '0.15rem', marginRight: '0.15rem' }}
            label="P"
            size="small"
            color="error"
            title="Priority patient"
          />
        )}
      </span>
    );
  }

  return (
    <span>
      {userId}
      {isPriority && (
        <Chip
          style={{ marginLeft: '0.15rem', marginRight: '0.15rem' }}
          label="P"
          size="small"
          color="error"
          title="Priority patient"
        />
      )}
      <div
        style={{
          marginLeft: '0.15rem',
          display: 'inline-block',
          width: '0.8rem',
          height: '0.8rem',
          borderRadius: '50%',
          marginBottom: '0.05rem',
          backgroundColor: isOnline ? theme.palette.success.main : theme.palette.secondary.main,
          // border: isPriority ? '0.1em solid red' : 'none',
        }}
        title={(isOnline ? 'Online' : 'Offline') + (isPriority ? ' (Priority User)' : '')}
      />
    </span>
  );
};
