import { useEffect } from 'react';

import { Grid } from '@mui/material';
import mixpanel from 'mixpanel-browser';

import { Capabilities } from 'auth-capabilities';
import { ItemTable } from 'components/queue/ItemTable';
import { MyItems } from 'components/queue/MyItems';
import { useAuth } from 'context/appContext';
import { useNavigate } from 'react-router';
import { useMealQueueService } from 'services/MealQueueService';

export const QueuePage = () => {
  const { query, allActiveQueues: queue } = useMealQueueService();
  const { authInfo, hasAuth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    mixpanel.track('loaded Editor');
  }, []);

  if (!hasAuth(Capabilities.mpqItemView)) {
    navigate('/foods');
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <MyItems
          items={queue.filter(i => i.first_reviewer_user_id === authInfo?.reviewer_id)}
          loading={query.isLoading}
        />
      </Grid>
      <Grid item xs={12}>
        <ItemTable
          items={queue.filter(i => i.first_reviewer_user_id !== authInfo?.reviewer_id)}
          loading={query.isLoading}
        />
      </Grid>
    </Grid>
  );
};
