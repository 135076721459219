import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Navigate, Route, Routes, useLocation, useOutlet } from 'react-router-dom';

import { KBarProvider } from 'kbar';
import mixpanel from 'mixpanel-browser';

import { FoodSearchProvider } from 'client-search/useFoodSearchIndex';
import { KBar } from 'components/kbar/KBar';
import { config } from 'config';
import { AppContextProvider, useAuth } from 'context/appContext';
import { FeatureContextProvider } from 'context/FeatureContext';
import { Page } from 'layout/Page';
import { CreateFoodPage } from 'pages/CreateFoodPage';
import { CustomMealItems } from 'pages/CustomMealItems';
import { EngPage } from 'pages/Eng';
import { FeedbackPage } from 'pages/Feedback';
import { FoodSearchPage } from 'pages/FoodSearch';
import { FoodSearchTest } from 'pages/FoodSearchTest';
import { InitialQAPage } from 'pages/InitialQA';
import { LegacyReviewItem } from 'pages/LegacyReviewItem';
import { Login } from 'pages/Login';
import { MealEntryTest } from 'pages/MealEntryTest';
import NotFound from 'pages/NotFound';
import { QAPage } from 'pages/QA';
import { QueuePage } from 'pages/Queue';
import { MealOrQueueItemPage, QueueItemPage } from 'pages/QueueItem/QueueItemPage';
import { ReplayLogsPage } from 'pages/ReplayLogs';
import { ReviewPage } from 'pages/Review';

import ThemeCustomization from 'themes';

import { LoadingButton } from '@mui/lab';
import { broadcastQueryClient } from '@tanstack/query-broadcast-client-experimental';
import { MyFoodTablePage } from 'components/FoodDatabaseTable';
import { FoodDetailsPopupSingletonContainer } from 'components/FoodDetailsPopup';
import { LocalStoragePopup } from 'components/LocalStoragePopup';
import { RxRadialMenuRootView } from 'components/RxRadialMenu';
import { FoodOptionsTest } from 'pages/FoodOptionsTest';
import { FoodTablePage } from 'pages/FoodTablePage';
import { PatientReportItemPage } from 'pages/PatientReportItemPage';
import { PatientReportListPage } from 'pages/PatientReportListPage';
import { ScrapeFoodTablePage } from 'pages/ScrapeFoodTablePage';
import { TriageFoodTablePage } from 'pages/TriageFoodTablePage';
import { MealQueueNotificationService } from 'services/MealQueueNotificationService';
import { useActiveQueuePeriodRefetch } from 'services/MealQueueService';
import { SnackBarGlobalComponent } from 'services/SnackBarService';
import { useSocketIOConnect, useSocketIOPresenceIdent } from 'socketio/SocketIOService';
import { queryClientDisposeOnCacheEvict } from 'utils/queryClientDisposeOnCacheEvict';
import { useTelemetrySendMetrics_DISABLED, useTelemetryTimeOnPage_DISABLED } from 'utils/telemetry';
import { UpdateFoodPage } from './pages/UpdateFoodPage';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'always',
    },
  },
});
queryClientDisposeOnCacheEvict(queryClient);
// This appears to be cauisng problems on the food editor, and doesn't seem like
// it's providing any massive benefit, so disable for now.
// broadcastQueryClient({
//   queryClient,
//   broadcastChannel: 'rx-food-auditor-query-cache',
// });

const App = () => {
  mixpanel.init(config.MIXPANEL_KEY);
  mixpanel.set_config({
    debug: true,
    ignore_dnt: true,
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeCustomization>
        <AppContextProvider>
          <FeatureContextProvider>
            <FoodSearchProvider>
              <RxRadialMenuRootView />
              <KBarProvider>
                <LocalStoragePopup />
                <MealQueueNotificationService />
                <Editor />
                <KBar />
                <SnackBarGlobalComponent />
                <FoodDetailsPopupSingletonContainer />
              </KBarProvider>
            </FoodSearchProvider>
          </FeatureContextProvider>
        </AppContextProvider>
      </ThemeCustomization>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

const useAppSingletonServices = () => {
  useTelemetryTimeOnPage_DISABLED();
  useTelemetrySendMetrics_DISABLED();
  useSocketIOConnect();
  useSocketIOPresenceIdent();
  useActiveQueuePeriodRefetch();
};

const Editor = () => {
  const auth = useAuth();
  useAppSingletonServices();

  if (!auth.initialRes.isDone) {
    return <LoadingButton>Loading...</LoadingButton>;
  }

  return (
    <Routes>
      <Route element={<AuthedRoutes />}>
        <Route element={<Page />}>
          <Route path="/" element={<QueuePage />} />
          <Route path="review" element={<ReviewPage />} />
          <Route path="review/queue-item/:itemId" element={<LegacyReviewItem />} />
          <Route path="custom-meal-items" element={<CustomMealItems />} />
          <Route path="food-options-test" element={<FoodOptionsTest />} />
          <Route path="qa" element={<QAPage />} />
          <Route path="feedback" element={<FeedbackPage />} />
          <Route path="foods/new" element={<CreateFoodPage />} />
          <Route path="foods/*" element={<UpdateFoodPage />} />
          <Route path="foods/table" element={<FoodTablePage />} />
          <Route path="foods/table/custom" element={<TriageFoodTablePage />} />
          <Route path="foods/table/triaged" element={<FoodTablePage />} />
          <Route path="foods/table/triaged-recipes" element={<FoodTablePage />} />
          <Route path="foods/table/triaged-cpg" element={<ScrapeFoodTablePage />} />
          <Route path="foods/table/draft" element={<FoodTablePage />} />
          <Route path="foods/table/review" element={<FoodTablePage />} />
          <Route path="foods/table/my-foods" element={<MyFoodTablePage />} />
          <Route path="food" element={<UpdateFoodPage />} /> {/* legacy */}
          <Route path="foods" element={<FoodSearchPage />} />
          <Route path="food-search" element={<FoodSearchPage />} /> {/* legacy */}
          <Route path="eng" element={<EngPage />} />
          <Route path="food-search-test" element={<FoodSearchTest />} />
          <Route path="meal-entry-test" element={<MealEntryTest />} />
          <Route path="dev/replaylogs" element={<ReplayLogsPage />} />
          <Route path="qa/initial-qa" element={<InitialQAPage />} />
          <Route path="patient-reports" element={<PatientReportListPage />} />
        </Route>
        <Route element={<Page fullWidth={true} />}>
          <Route path="patient-reports/:itemId" element={<PatientReportItemPage />} />
          <Route path="queue-item/:itemId" element={<MealOrQueueItemPage />} />
          <Route path="meal/:mealId" element={<MealOrQueueItemPage />} />
        </Route>
      </Route>

      <Route path="login" element={<Login />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const AuthedRoutes = () => {
  const { authInfo } = useAuth();
  const outlet = useOutlet();
  const loc = useLocation();

  const nextUrl = loc.pathname + loc.search + loc.hash;

  const loginPath = '/login' + (nextUrl.length <= 1 ? '' : `?next=${encodeURIComponent(nextUrl)}`);
  if (!authInfo) {
    return <Navigate to={loginPath} />;
  }

  return outlet;
};

export { App };
