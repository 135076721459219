import { FoodRowMixedDishComponents } from 'api/generated/MNT';

export default (value: FoodRowMixedDishComponents) => {
  const values = Object.values(value);
  if (values.length == 0 || values.every(v => v === null)) {
    return null;
  }

  const sumFloat = values.reduce<number>((prev, v) => prev + (v ?? 0), 0);
  const sum = (sumFloat * 100).toFixed(0);
  if (sum !== '100') {
    return `Mixed dish sum is ${sum}, must be 100`;
  }

  return null;
};
