import { PlusSquareFilled } from '@ant-design/icons';
import { Add, AutoAwesome, Cancel, Flare } from '@mui/icons-material';
import {
  CardContent,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  CreateMealItemFoodMatchDetailsRequest,
  CreateMealItemRequest,
  MealPhotoQueueResponse,
  MPQImLblMatchResult,
} from 'api/generated/MNT';
import { RadialMenuItem, RadialSubMenu, useRxRadialMenu } from 'components/RxRadialMenu';
import React, { useState } from 'react';
import { useMpqImLblService } from 'services/MpqImLblService';
import { DraftItem } from 'types/DraftItem';
import { telemetrySend } from 'utils/telemetry';
import { getEmptyMealItem, useQueueItemEditor } from '../services/QueueItemEditorService';

const fmtMatchInfo = (match: MPQImLblMatchResult) => {
  return JSON.stringify(
    {
      original: {
        brand: match.raw.brand,
        food: match.raw.food,
        quantiy: match.raw.amount?.quantity,
        unit: match.raw.amount?.unit,
        comments: match.raw.comments,
      },
      info: match.debug_match_info,
    },
    null,
    2,
  );
};

const createMealItemFromMatchResultToMealItem = (item: CreateMealItemRequest) => {
  return {
    ...item,
    servings: item.servings || 0,
    custom_addons: (item.custom_addons || []).map((a) => ({
      ...a,
      food_image_url: null,
      food_name_translations: {},
      serving_unit_label_translations: {},
    })),
    custom_usda_id: null,
    percent_eaten: 1,
    custom_item_source: item.custom_item_source ?? null,
    custom_item_source_id: item.custom_item_source_id ?? null,
  };
};

export const imLblMatchToDraftItem = (opts: {
  match: MPQImLblMatchResult,
  debugContext: any,
}): DraftItem => {
  const { match } = opts;
  const item = match.meal_item;
  const foodMatchDetails = {
    source_name: 'mpq_im_lbl',
    source_details: {
      match_raw: match.raw,
      match_meal_item: match.meal_item,
      match_info: match.debug_match_info,
      ...(opts.debugContext || {}),
    },
  } satisfies CreateMealItemFoodMatchDetailsRequest;

  if (item.custom_item && !item.custom_item_source_id) {
    // When there's a custom item without a source, it's because the
    // item could not be matched, but prelabelling included some
    // amount of information. In this case, use an empty item
    // to trigger the "food has not been matched" warning.
    return {
      id: null,
      item: getEmptyMealItem(),
      searchItem: null,
      queryText: match.meal_item.food_name,
      foodMatchDetails,
      imLblMatchResult: match,
    };
  }

  return {
    id: null,
    item: createMealItemFromMatchResultToMealItem(item),
    searchItem: match.food ?? null,
    queryText: item.food_name,
    foodMatchDetails,
    imLblMatchResult: match,
  };
};

const useMealItemMatchRadialMenu = (props: {
  debugContext?: any,
}) => {
  const [match, setMatch] = useState(null as MPQImLblMatchResult | null);
  const editor = useQueueItemEditor();

  const handleAdd = () => {
    menu.close();
    if (!match) {
      return;
    }

    editor.selectedItemAddNewItem(imLblMatchToDraftItem({
      match,
      debugContext: props.debugContext,
    }));
  };

  const handleAddAddonTo = (d: DraftItem) => {
    menu.close();
    if (!match) {
      return;
    }

    const item = match.meal_item;

    editor.addItemAsAddon({
      targetItem: d,
      addonMealItem: createMealItemFromMatchResultToMealItem(item),
    });
  };

  const menu = useRxRadialMenu({
    onClose: () => {
      setMatch(null);
    },
    props: {
      innerRadius: 40,
      outerRadius: 150,
      drawBackground: true,
      children: !match ? [] : [
        <RadialMenuItem key="add" onItemClick={handleAdd}>
          <Add />
          Add
        </RadialMenuItem>,
        editor.draftItems.length > 0
          ? (
            <RadialSubMenu
              key="addon"
              displayPosition="bottom"
              itemView="Addon of..."
            >
              {editor.draftItems.length == 1 && (
                <RadialMenuItem>
                  <Typography color="text.secondary">
                    No other items
                  </Typography>
                </RadialMenuItem>
              )}
              {editor.draftItems.map((d, i) => (
                <RadialMenuItem key={i} onItemClick={() => handleAddAddonTo(d)}>
                  {d.item.food_name}
                </RadialMenuItem>
              ))}
            </RadialSubMenu>
          )
          : (
            <RadialMenuItem key="addon">
              <Typography color="text.secondary">
                Addon of...<br />(no other items)
              </Typography>
            </RadialMenuItem>
          ),
      ],
    },
  });

  return {
    show: (event: React.MouseEvent, match: MPQImLblMatchResult) => {
      setMatch(match);
      menu.show(event);
    },
  };
};

const ClickableItem = (props: {
  match: MPQImLblMatchResult,
  debugContext: any,
  isAlternative?: boolean,
  onRemove?: () => void,
}) => {
  const { match } = props;
  const item = match.meal_item;
  const theme = useTheme();
  const editor = useQueueItemEditor();
  const matchMenu = useMealItemMatchRadialMenu({
    debugContext: props.debugContext,
  });

  return (
    <Typography>
      <span
        onClick={event => {
          if (editor.selectedItem) {
            return;
          }
          matchMenu.show(event, match);
        }}
        style={{
          cursor: editor.selectedItem ? 'default' : 'pointer',
          color: (
            item.custom_item
              ? theme.palette.warning.main
              : undefined
          ),
        }}
      >
        <PlusSquareFilled size={8} style={{ color: theme.palette.primary.main, verticalAlign: 'text-top' }} />
        &nbsp;
        {item.food_name} ({item.servings || 1} {item.serving_unit_label}) {item.custom_item && '- no match'}
      </span>
      {props.onRemove && (
        <>
          &nbsp;
          <Tooltip title="Remove">
            <Cancel
              onClick={props.onRemove}
              style={{ fontSize: 16 }}
              sx={{
                color: theme.palette.text.secondary,
                cursor: 'pointer',
                '&:hover': {
                  color: theme.palette.error.main,
                },
              }}
            />
          </Tooltip>
        </>
      )}
      &nbsp;
      <ImLblMatchDetailsIcon match={match} />
    </Typography>
  );
};

export const ImLblMatchDetailsIcon = (props: {
  match: MPQImLblMatchResult,
}) => {
  const { match } = props;
  const theme = useTheme();
  return (
    <Tooltip title={<pre>{fmtMatchInfo(match)}</pre>}>
      <AutoAwesome
        style={{
          color: theme.palette.text.secondary,
          fontSize: '90%',
        }}
      />
    </Tooltip>
  );
};

const useRemoveMatchDialog = (opts: {
  onClose: (match: MPQImLblMatchResult | null) => void,
}) => {
  const [match, setMatch] = React.useState<MPQImLblMatchResult | null>(null);

  const Dialog = React.useMemo(() => {
    const handleClose = (success: boolean) => {
      opts.onClose(success ? match : null);
      setMatch(null);
    };
    return (
      <RemoveMatchDialog
        open={!!match}
        onClose={handleClose}
        match={match as MPQImLblMatchResult}
        debugContext={{}}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  return {
    show: setMatch,
    Dialog,
  };
};

const RemoveMatchDialog = (props: {
  open: boolean,
  onClose: (success: boolean) => void,
  debugContext: any,
  match: MPQImLblMatchResult,
}) => {
  const { open, onClose, match } = props;

  const handleListItemClick = (reason: { label: string, value: string }) => {
    if (reason.value == 'better_match') {
      alert(
        'If there is a more appropriate food, please add this item, then change it to the more appropriate food.\n'
          + 'This will help improve the accuracy of the automatic matching system.\n'
          + 'Thank you!',
      );
      onClose(false);
      return;
    }
    telemetrySend({
      name: 'MPQImLblMatchRemoved',
      key: new Date().toISOString(),
      value: 1,
      properties: {
        match_raw: match.raw,
        match_meal_item: match.meal_item,
        remove_reason: reason.value,
        ...props.debugContext,
      },
    });

    onClose(true);
  };

  const reasons = [
    { label: 'Not present in photo or meal note', value: 'not_present' },
    { label: 'Already included in another item', value: 'part_of_other_item' },
    { label: 'There is a more appropriate food', value: 'better_match' },
    { label: 'Other', value: 'other' },
  ];

  return (
    <Dialog onClose={() => onClose(false)} open={open}>
      <DialogTitle>Why are you removing this match?</DialogTitle>
      <List sx={{ pt: 0 }}>
        {reasons.map((r) => (
          <ListItem disableGutters key={r.value}>
            <ListItemButton onClick={() => handleListItemClick(r)}>
              <ListItemText primary={r.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

export const MealPhotoQueueImageAutomaticLabels = (props: {
  queueItem: MealPhotoQueueResponse,
}) => {
  const svc = useMpqImLblService(props);
  const editorSvc = useQueueItemEditor();
  const removeDialog = useRemoveMatchDialog({
    onClose: match => {
      if (match) {
        svc.removeMatch(match);
      }
    },
  });

  const matches = React.useMemo(() => {
    return svc.data.filter((m) => {
      return !editorSvc.draftItems.find((d) => {
        return (
          d.item.food_name == m.meal_item.food_name
          || (d.foodMatchDetails?.source_details as any)?.match_raw === m.raw
          || d.item.custom_addons?.find((a) => a.food_name == m.meal_item.food_name)
        );
      });
    });
  }, [svc.data, editorSvc.draftItems]);

  if (!svc.isAvailable || svc.prefillMeal) {
    return null;
  }

  if (svc.query.isLoading) {
    return <div>Loading autolabels...</div>;
  }

  return (
    <CardContent>
      {removeDialog.Dialog}
      <Stack spacing={1}>
        {matches.map((m, i) => (
          <div key={i}>
            <ClickableItem
              match={m}
              debugContext={svc.debugContext}
              onRemove={() => removeDialog.show(m)}
            />
            <div style={{ marginLeft: 10 }}>
              {m.alternatives.map((a, j) => (
                <ClickableItem
                  key={j}
                  match={a}
                  debugContext={svc.debugContext}
                  isAlternative
                />
              ))}
            </div>
          </div>
        ))}
      </Stack>
    </CardContent>
  );
};
