import { Typography } from '@mui/material';
import { PatientDietRestrictionResponse } from 'api/generated/MNT';
import { type PatientContext } from './usePatientContext';

export const PatientAgeValue = ({
  patientContext,
}: {
  patientContext: PatientContext,
}) => {
  const { context, loading, is_legal_alcohol_age } = patientContext;
  const color = is_legal_alcohol_age ? undefined : 'red';
  return (
    <span style={{ color }}>
      {(loading || !context)
        ? <span style={{ fontStyle: 'italic' }}>loading...</span>
        : context.age < 18
        ? '< 18'
        : context.age < 21
        ? '18 - 21'
        : '21+'}
    </span>
  );
};

export const PatientAge = ({
  patientContext,
}: {
  patientContext: PatientContext,
}) => {
  return (
    <Typography>
      <b>Patient age:</b> <PatientAgeValue patientContext={patientContext} />
    </Typography>
  );
};

export const PatientDiet = (props: {
  dietRestrictions: PatientDietRestrictionResponse[],
}) => {
  const restrictionsStr = props.dietRestrictions.map(({ name, comment }) => {
    if (!comment || name === 'everything') {
      return name;
    }
    return name + ': ' + comment;
  }).join(', ');
  if (!restrictionsStr) {
    return null;
  }
  return (
    <Typography variant="body1">
      <b>Diet restrictions:{' '}</b>
      <span style={{ color: restrictionsStr == 'everything' ? 'black' : 'red' }}>
        {restrictionsStr}
      </span>
    </Typography>
  );
};
