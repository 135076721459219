import { FoodEditorValue } from '../types';

export default (): FoodEditorValue => ({
  term: '',
  definitionType: 'atomic',
  frenchTerm: '',
  foodImageUrl: null,
  ndbNumber: '',
  nutritionSourceId: null,
  status: '',
  ontologyLevels: [],
  baseFoodName: '',
  rootFoodName: '',
  usdaNutrientName: '',
  otherNames: [],
  synonyms: [],
  singularTerm: '',
  pluralTerm: '',
  customTip: '',
  nutritionSourceUrl: '',
  fdcid: null,
  cnFoodId: null,
  notes: '',
  fruitAndVegetableType: '',
  carbohydrateType: '',
  proteinType: [],
  beverageType: '',
  fatType: '',
  grainType: '',
  processedType: '',
  preparationType: '',
  preferredAddOn: false,
  wholeFood: false,
  fried: false,
  highCalcium: false,
  highFibre: false,
  supplement: false,
  nutsOrSeeds: false,
  plantBased: false,
  plantBasedWhole: false,
  additives: false,
  glutenFree: false,
  omegaThree: false,
  suggestedItem: false,
  rankingUp: '',
  rankingPb: '',
  rankingHealthyFat: '',
  rankingUnhealthyFat: '',
  rankingBothFat: '',
  rankingWholeGrains: '',
  rankingRefinedGrains: '',
  rankingFruit: '',
  rankingVegetable: '',
  rankingLowGi: '',
  rankingHighGi: '',
  rankingLowK: '',
  rankingHighK: '',
  similarItems: [],
  connectedItems: [],
  compoundingWords: [],
  dataSource: '',
  giLevel: '',
  giComments: '',
  potassiumLevel: '',
  potassiumComments: '',
  mixedDishComponents: {},
  adultServingSizeG: '',
  suggestedServingCount: '',
  suggestedServingUnitLabel: '',
  suggestedServingAmountG: '',
  ghgEquivalentKg: '',
  manufacturer: '',
  energyKcal: null,
  alcoholG: null,
  fatG: null,
  transFatG: null,
  satFatG: null,
  polyFatG: null,
  monoFatG: null,
  proteinG: null,
  carbohydrateG: null,
  fibreG: null,
  polyolsG: null,
  netcarbG: null,
  calciumMg: null,
  ironMg: null,
  magnesiumMg: null,
  phosphorusMg: null,
  potassiumMg: null,
  sodiumMg: null,
  zincMg: null,
  thiaminMg: null,
  riboflavinMg: null,
  niacinMg: null,
  folateDfeUg: null,
  cholesterolMg: null,
  vitaminAIu: null,
  vitaminB6Mg: null,
  vitaminB12Ug: null,
  vitaminCMg: null,
  vitaminDIu: null,
  vitaminEMg: null,
  vitaminKUg: null,
  addedSugarsG: null,
  sugarsG: null,
  caffeineMg: null,
  cholineMg: null,
  insolubleDietaryFiberG: null,
  omegaThreeFattyAcidsG: null,
  pantothenicAcidMg: null,
  seleniumMcg: null,
  solubleDietaryFiberG: null,
  vitaminARaeUg: null,
  vitaminD2d3Ug: null,
  gramsPerCubicInch: null,
  waterG: null,
  measures: [],
  components: [],
  overrideValues: null,
  disabled: false,
  optionCollection: null,
  optionValuesRaw: null,
  ephemeralNutrientValueDisplayScale: '',
  listedServingCount: '',
  listedServingUnitLabel: '',
  listedServingGPerServing: '',
  alcoholPercent: null,
});
