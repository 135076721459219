import { useEffect } from 'react';

export const useSetPageTitle = (prefix: string, title?: string | null) => {
  useEffect(() => {
    if (!title) {
      return;
    }
    document.title = prefix + title;

    return () => {
      document.title = 'Auditor';
    };
  }, [title, prefix]);
};
