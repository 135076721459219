import { UsdaNutritionResponse } from 'api/generated/MNT';
import { FoodEditorValue } from './types';

export type FoodEditorNutrientValue = number | null;

export type FoodEditorNutrientFields<T = FoodEditorNutrientValue> = {
  energyKcal: T,
  fatG: T,
  transFatG: T,
  satFatG: T,
  monoFatG: T,
  polyFatG: T,
  carbohydrateG: T,
  fibreG: T,
  polyolsG: T,
  netcarbG: T,
  proteinG: T,
  calciumMg: T,
  ironMg: T,
  magnesiumMg: T,
  phosphorusMg: T,
  potassiumMg: T,
  sodiumMg: T,
  zincMg: T,
  thiaminMg: T,
  riboflavinMg: T,
  niacinMg: T,
  folateDfeUg: T,
  cholesterolMg: T,
  vitaminAIu: T,
  vitaminARaeUg: T,
  vitaminB6Mg: T,
  vitaminB12Ug: T,
  vitaminCMg: T,
  vitaminDIu: T,
  vitaminD2d3Ug: T,
  vitaminEMg: T,
  vitaminKUg: T,
  sugarsG: T,
  addedSugarsG: T,
  caffeineMg: T,
  cholineMg: T,
  gramsPerCubicInch: T,
  insolubleDietaryFiberG: T,
  solubleDietaryFiberG: T,
  omegaThreeFattyAcidsG: T,
  pantothenicAcidMg: T,
  seleniumMcg: T,
  waterG: T,
  alcoholG: T,
  alcoholPercent: T,
};

export type FoodEditorNutrientFieldDefinition = {
  label: string,
  boldLabel?: boolean,
  indentLabel?: 1 | 2,
  editorField: keyof FoodEditorNutrientFields,
  mntField: keyof UsdaNutritionResponse,
  unit: string,
  derived?: (values: FoodEditorValue) => FoodEditorNutrientValue,

  // This is used for fields that are only used in the editor and are not needed to be sent to the server
  skipConvertToRequest?: boolean,
};

export const FOOD_EDITOR_NUTRIENT_FIELDS: FoodEditorNutrientFieldDefinition[] = [
  { label: 'Energy (kcal)', editorField: 'energyKcal', mntField: 'energy_kcal', unit: 'kcal', boldLabel: true },
  {
    label: 'Vitamin A rae (ug)',
    editorField: 'vitaminARaeUg',
    mntField: 'vitamin_a_rae_ug',
    unit: 'ug',
    boldLabel: true,
  },
  { label: 'Thiamin (mg)', editorField: 'thiaminMg', mntField: 'thiamin_mg', unit: 'mg' },

  { label: 'Fat (g)', editorField: 'fatG', mntField: 'fat_g', unit: 'g', boldLabel: true },
  { label: 'Vitamin A (IU)', editorField: 'vitaminAIu', mntField: 'vitamin_a_iu', unit: 'IU', boldLabel: true },
  { label: 'Riboflavin (mg)', editorField: 'riboflavinMg', mntField: 'riboflavin_mg', unit: 'mg' },

  {
    label: 'Sat Fat (g)',
    editorField: 'satFatG',
    mntField: 'fattyacids_totalsaturated_g',
    unit: 'g',
    boldLabel: true,
    indentLabel: 1,
  },
  { label: 'Vitamin C (mg)', editorField: 'vitaminCMg', mntField: 'vitamin_c_mg', unit: 'mg', boldLabel: true },
  { label: 'Niacin (mg)', editorField: 'niacinMg', mntField: 'niacin_mg', unit: 'mg' },

  {
    label: 'Trans Fat (g)',
    editorField: 'transFatG',
    mntField: 'fattyacids_totaltrans_g',
    unit: 'g',
    boldLabel: true,
    indentLabel: 1,
  },
  {
    label: 'Vitamin D2d3 (ug)',
    editorField: 'vitaminD2d3Ug',
    mntField: 'vitamin_d2d3_ug',
    unit: 'ug',
    boldLabel: true,
  },
  { label: 'Folate (mcg DFE)', editorField: 'folateDfeUg', mntField: 'folate_dfe_ug', unit: 'mcg DFE' },

  { label: 'Cholesterol (mg)', editorField: 'cholesterolMg', mntField: 'cholesterol_mg', unit: 'mg', boldLabel: true },
  { label: 'Vitamin D (IU)', editorField: 'vitaminDIu', mntField: 'vitamin_d_iu', unit: 'IU', boldLabel: true },
  { label: 'Vitamin B6 (mg)', editorField: 'vitaminB6Mg', mntField: 'vitamin_b6_mg', unit: 'mg' },

  { label: 'Sodium (mg)', editorField: 'sodiumMg', mntField: 'sodium_mg', unit: 'mg', boldLabel: true },
  { label: 'Calcium (mg)', editorField: 'calciumMg', mntField: 'calcium_mg', unit: 'mg', boldLabel: true },
  { label: 'Vitamin B12 (ug)', editorField: 'vitaminB12Ug', mntField: 'vitamin_b12_ug', unit: 'ug' },

  { label: 'Carbohydrate (g)', editorField: 'carbohydrateG', mntField: 'carbohydrate_g', unit: 'g', boldLabel: true },
  { label: 'Iron (mg)', editorField: 'ironMg', mntField: 'iron_mg', unit: 'mg', boldLabel: true },
  { label: 'Vitamin E (mg)', editorField: 'vitaminEMg', mntField: 'vitamin_e_mg', unit: 'mg' },

  {
    label: 'Net Carb (g)',
    editorField: 'netcarbG',
    mntField: 'netcarb_g',
    derived: (values: FoodEditorValue) => {
      return (values.carbohydrateG ?? 0) - (values.polyolsG ?? 0) - (values.fibreG ?? 0);
    },
    unit: 'g',
    boldLabel: true,
    indentLabel: 1,
  },
  { label: 'Potassium (mg)', editorField: 'potassiumMg', mntField: 'potassium_mg', unit: 'mg', boldLabel: true },
  { label: 'Vitamin K (ug)', editorField: 'vitaminKUg', mntField: 'vitamin_k_ug', unit: 'ug' },

  { label: 'Fibre (g)', editorField: 'fibreG', mntField: 'fiber_g', unit: 'g', boldLabel: true, indentLabel: 1 },
  { label: 'Poly Fat (g)', editorField: 'polyFatG', mntField: 'fattyacids_totalpolyunsaturated_g', unit: 'g' },
  { label: 'Choline (mg)', editorField: 'cholineMg', mntField: 'choline_mg', unit: 'mg' },

  { label: 'Sugars (g)', editorField: 'sugarsG', mntField: 'sugars_g', unit: 'g', boldLabel: true, indentLabel: 1 },
  { label: 'Mono Fat (g)', editorField: 'monoFatG', mntField: 'fattyacids_totalmonounsaturated_g', unit: 'g' },
  { label: 'Magnesium (mg)', editorField: 'magnesiumMg', mntField: 'magnesium_mg', unit: 'mg' },

  {
    label: 'Added sugars (g)',
    editorField: 'addedSugarsG',
    mntField: 'added_sugars_g',
    unit: 'g',
    boldLabel: true,
    indentLabel: 2,
  },
  {
    label: 'Omega three fatty acids (g)',
    editorField: 'omegaThreeFattyAcidsG',
    mntField: 'omega_three_fatty_acids_g',
    unit: 'g',
  },
  { label: 'Phosphorus (mg)', editorField: 'phosphorusMg', mntField: 'phosphorus_mg', unit: 'mg' },

  { label: 'Polyols (g)', editorField: 'polyolsG', mntField: 'polyols_g', unit: 'g', boldLabel: true, indentLabel: 1 },
  {
    label: 'Soluble dietary fiber (g)',
    editorField: 'solubleDietaryFiberG',
    mntField: 'soluble_dietary_fiber_g',
    unit: 'g',
  },
  { label: 'Zinc (mg)', editorField: 'zincMg', mntField: 'zinc_mg', unit: 'mg' },

  { label: 'Protein (g)', editorField: 'proteinG', mntField: 'protein_g', unit: 'g', boldLabel: true },
  {
    label: 'Insoluble dietary fiber (g)',
    editorField: 'insolubleDietaryFiberG',
    mntField: 'insoluble_dietary_fiber_g',
    unit: 'g',
  },
  { label: 'Pantothenic acid (mg)', editorField: 'pantothenicAcidMg', mntField: 'pantothenic_acid_mg', unit: 'mg' },

  { label: 'Alcohol (g)', editorField: 'alcoholG', mntField: 'alcohol_g', unit: 'g', boldLabel: true },
  { label: 'Caffeine (mg)', editorField: 'caffeineMg', mntField: 'caffeine_mg', unit: 'mg' },
  { label: 'Selenium (mcg)', editorField: 'seleniumMcg', mntField: 'selenium_mcg', unit: 'mcg' },

  {
    label: 'Alcohol (%)',
    editorField: 'alcoholPercent',
    mntField: 'alcohol_g',
    unit: '%',
    skipConvertToRequest: true,
    boldLabel: true,
  },
  { label: 'Water (g)', editorField: 'waterG', mntField: 'water_g', unit: 'g' },
  { label: 'Grams per cubic in', editorField: 'gramsPerCubicInch', mntField: 'grams_per_cubic_inch', unit: 'g' },
];

export const FOOD_EDITOR_NUTRIENTS_BY_MNT_FIELD: Record<
  keyof UsdaNutritionResponse,
  FoodEditorNutrientFieldDefinition
> = Object.fromEntries(FOOD_EDITOR_NUTRIENT_FIELDS.map(field => [field.mntField, field])) as any;

export const FOOD_EDITOR_NUTRIENTS_BY_EDITOR_FIELD: Record<
  keyof FoodEditorValue,
  FoodEditorNutrientFieldDefinition
> = Object.fromEntries(FOOD_EDITOR_NUTRIENT_FIELDS.map(field => [field.editorField, field])) as any;
