import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getFoodDetailsQueryKey } from 'services/FoodDetailsService';
import apiClient from '../api-client';
import { FoodEditorFoodRequest, FoodEditorFoodResponse, QueryKey } from '../types';

export default (term: string) => {
  const queryClient = useQueryClient();

  return useMutation<FoodEditorFoodResponse, Error, FoodEditorFoodRequest>(
    async foodRequest => apiClient.updateFood(term, foodRequest),
    {
      onSuccess: foodResponse => {
        queryClient.setQueryData(
          [QueryKey.Food, foodResponse.term],
          foodResponse,
        );
        queryClient.resetQueries(getFoodDetailsQueryKey(term), { exact: true });
      },
    },
  );
};
