/* eslint-disable react/destructuring-assignment */
import { CloseOutlined, DeleteFilled, EditOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Add, ArrowDropDown, ContentCopy, ContentPaste, Delete, Done, Edit } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  CardActions,
  CardContent,
  ClickAwayListener,
  Drawer,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  MenuItem,
  MenuList,
  OutlinedInput,
  Paper,
  Popper,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { foodApi } from 'api';
import { CalculateCompositeFoodDetailsResponse, FoodComponentResponse } from 'api/generated/MNT';
import { getFoodByQuery, SearchItem } from 'apiClients/search';
import MainCard from 'components/MainCard';
import { SpellCheck } from 'components/spell-check';
import { useAuth } from 'context/appContext';
import { logTrackedError } from 'errorTracking';
import { ccToFoodResponse, usdaNutritionToFoodResponse } from 'food-editor/api-client';
import {
  foodResponseCcToFoodEditorValue,
  foodResponseNutrientsToFoodEditorValue,
} from 'food-editor/utils/food-response-to-food-editor-value';
import { searchItemToFoodComponent } from 'food-editor/utils/searchItemToFoodComponent';
import { formatNumber } from 'food-editor/utils/utils';
import { FoodSearchResults, FoodSearchSelection } from 'pages/QueueItem/meal-builder/FoodDrawer';
import { FoodSearchDropdownInput } from 'pages/QueueItem/meal-builder/FoodSearchComponents';
import { useAddonServings, useFoodItemServings } from 'pages/QueueItem/meal-builder/MealBuilder';
import { floatOrNull } from 'pages/QueueItem/meal-builder/MealBuilder';
import { useFoodSearch } from 'pages/QueueItem/meal-builder/useFoodSearch';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAsyncResult } from 'react-use-async-result';
import { defaultSearchItem } from '../../constants';
import { FoodComponent, FoodEditorValue } from '../types';
import { FoodEditorField, FoodEditorState } from './food-editor';

const apiCalculatedDetailsToFoodEditorValue = (
  state: FoodEditorState,
  calculatedCcAndNutrients: CalculateCompositeFoodDetailsResponse,
): Partial<FoodEditorValue> => {
  const { usda_nutrition, cc, mixed_dish_components } = calculatedCcAndNutrients;

  return {
    ...foodResponseNutrientsToFoodEditorValue(usdaNutritionToFoodResponse(usda_nutrition)),
    ...foodResponseCcToFoodEditorValue(ccToFoodResponse(cc)),
    mixedDishComponents: mixed_dish_components || {},
  };
};

const emptyAddon: FoodComponentResponse = {
  food_id: '',
  food_name: '',
  parent_food_id: '',
  parent_food_name: '',
  serving_unit_label: 'g',
  serving_unit_amount: 0,
  servings: 0,
  component_addons: [],
};

const FoodComponentAddon = (props: {
  addon: FoodComponentResponse,
  foodComponent: FoodComponent,
  state: FoodEditorState,
  quantityRef: React.RefObject<HTMLInputElement> | null,
  searchAutoFocus: boolean,
  updateAddon: (addon: FoodComponentResponse) => void,
  deleteAddon: () => void,
  setFocusOnQuantity: () => void,
}) => {
  const { authInfo } = useAuth();
  const { addon, state, quantityRef, updateAddon, setFocusOnQuantity } = props;
  const foodQuery = useQuery(['food', addon.food_name], async () => {
    if (!addon.food_name) {
      return null;
    }
    return getFoodByQuery(addon.food_name, authInfo!.access_token);
  });
  const { servingUnitOptions, servingUnit, quantityOptions } = useAddonServings(addon, foodQuery.data ?? null);
  const [quantity, setQuantity] = useState<string>('');
  const quantitySigFigs = 5;

  const handleQuantityChange = (event: React.SyntheticEvent<Element, Event>, value: string | null) => {
    if (value === null) {
      return;
    }
    if (!value.match(/^\d*\.?\d*$/)) {
      return;
    }
    const finalValue = (value).substring(0, quantitySigFigs);
    setQuantity(finalValue);
    updateAddon({
      ...addon,
      servings: parseFloat(finalValue) || 0,
    });
  };

  useEffect(() => {
    if (foodQuery?.isFetched) {
      setFocusOnQuantity();
    }
    setQuantity(addon.servings ? addon.servings.toString() : '');
  }, [foodQuery.isFetched, addon.food_name, addon.serving_unit_label]);

  return (
    <Box key={addon.food_id}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <FoodSearchDropdownInput
              context={{
                context_addon_of: props.foodComponent?.food_name,
              }}
              initialSearchText={addon.food_name}
              autoFocus={props.searchAutoFocus}
              onItemSelect={(item, index, source) => {
                const defaultServingUnit = (item.serving_units && item.serving_units.length > 0)
                  ? item.serving_units[0]
                  : { label: 'g', amount: 1, default_label_qty: 1 };
                updateAddon({
                  ...addon,
                  food_name: item.name,
                  food_id: item.rxfood_id ?? undefined,
                  parent_food_id: state.value.ndbNumber,
                  parent_food_name: state.value.term,
                  serving_unit_label: defaultServingUnit.label,
                  serving_unit_amount: defaultServingUnit.amount,
                  servings: defaultServingUnit.default_label_qty ?? 1,
                });
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack spacing={0.5}>
            <Select
              disabled={!foodQuery.data?.serving_units}
              value={addon.serving_unit_label}
              autoFocus={props.searchAutoFocus}
              onChange={(evt) => {
                if (!foodQuery?.data) {
                  return;
                }
                const servingUnit = foodQuery.data.serving_units?.find(v => v.label === evt.target.value);
                if (servingUnit) {
                  updateAddon({
                    ...addon,
                    serving_unit_label: servingUnit.label,
                    serving_unit_amount: servingUnit.amount,
                    servings: servingUnit.default_label_qty ?? 1,
                  });
                }
              }}
            >
              {servingUnitOptions.map((v, i) => (
                <MenuItem
                  key={v.label + '-' + i}
                  disabled={!!v.label.match(/^--------/)}
                  value={v.label}
                >
                  {v.label}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Stack spacing={0.5}>
            <Autocomplete
              disabled={!quantityOptions.length}
              freeSolo
              openOnFocus
              options={quantityOptions}
              value={quantity}
              inputValue={quantity}
              onChange={handleQuantityChange}
              onInputChange={handleQuantityChange}
              onBlur={(event) => {
                if (quantity === '' || parseFloat(quantity) === 0) {
                  handleQuantityChange(event, servingUnit?.default_label_qty?.toString() ?? null);
                }
              }}
              renderInput={(params) => <TextField {...params} inputRef={quantityRef} />}
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  placement="right"
                >
                  {props.children}
                </Popper>
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={1}>
          {addon.food_name !== '' && (
            <Tooltip title="Delete add on" placement="top">
              <IconButton
                size="large"
                color="primary"
                onClick={() => {
                  props.deleteAddon();
                  setQuantity('');
                }}
              >
                <DeleteFilled />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const FoodComponentEditor = (props: {
  foodComponent: FoodComponent,
  copiedComponent: FoodComponent | null,
  state: FoodEditorState,
  selectedComponent: FoodComponent | null,
  setSelectedComponent: (component: FoodComponent | null) => void,
  openFoodSearchDrawer: () => void,
  onPaste: () => void,
}) => {
  const { foodComponent, copiedComponent, state, selectedComponent } = props;
  const { servingUnitOptions, servingUnit, quantityOptions, quantity } = useFoodItemServings(foodComponent);
  const [quantityInput, setQuantityInput] = useState<string>('');
  const [lastUpdated, setLastUpdated] = useState(null as null | FoodComponentResponse);
  const quantityRef = useRef<HTMLInputElement>(null);
  const addons = foodComponent.component_addons?.length
    ? foodComponent.component_addons.concat(emptyAddon)
    : [emptyAddon];

  useEffect(() => {
    if (selectedComponent && selectedComponent != foodComponent) {
      state.onFieldChange({
        components: state.value.components.map(c => {
          if (c === foodComponent) {
            return { ...foodComponent, ...selectedComponent, component_addons: foodComponent.component_addons };
          }
          return c;
        }),
      });
    }
  }, [selectedComponent]);

  useEffect(() => {
    setQuantityInput(quantity?.toString() || '');
  }, [quantityOptions]);

  const handleQuantityChange = (event: React.SyntheticEvent<Element, Event>, value: string | null) => {
    if (!foodComponent) {
      return;
    }
    setQuantityInput(value || '');
    state.onFieldChange({
      components: state.value.components.map(c => {
        if (c === foodComponent) {
          return {
            ...foodComponent,
            servings: Math.max(parseFloat(value || '0'), 0.1),
          };
        }
        return c;
      }),
    });
  };

  const removePlaceholderAddon = (addons: FoodComponentResponse[]) => addons.filter(a => a.food_name);

  const deleteAddon = (addon: FoodComponentResponse) => {
    setLastUpdated(null);
    state.onFieldChange({
      components: state.value.components.map(c => {
        if (c === foodComponent) {
          return {
            ...foodComponent,
            component_addons: removePlaceholderAddon(addons.filter(a => a !== addon)),
          };
        }
        return c;
      }),
    });
  };

  const updateAddon = (oldAddon: FoodComponentResponse, newAddon: FoodComponentResponse) => {
    setLastUpdated(newAddon);
    state.onFieldChange({
      components: state.value.components.map(c => {
        if (c === foodComponent) {
          return {
            ...foodComponent,
            component_addons: removePlaceholderAddon(
              addons.map(a => a === oldAddon ? newAddon : a),
            ),
          };
        }
        return c;
      }),
    });
  };

  const setFocusOnQuantity = () => {
    if (quantityRef?.current) {
      quantityRef.current.focus();
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Typography>
              Food Component
            </Typography>
            <OutlinedInput
              fullWidth
              placeholder="Choose a food"
              value={foodComponent?.food_name || ''}
              onClick={props.openFoodSearchDrawer}
              onKeyDown={props.openFoodSearchDrawer}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    color="secondary"
                    edge="end"
                    onClick={props.openFoodSearchDrawer}
                  >
                    <EditOutlined />
                  </IconButton>
                </InputAdornment>
              }
            />
            <Stack>
              {foodComponent?.searchItem?.adult_serving_size_g && (
                <Typography color="grey">
                  Adult Serving Size: {formatNumber(foodComponent?.searchItem.adult_serving_size_g, 1)}g
                </Typography>
              )}
              {foodComponent.searchItem?.custom_tip
                ? <Typography color="error">Tip: {foodComponent.searchItem?.custom_tip}</Typography>
                : foodComponent.custom_tip && <Typography color="error">Tip: {foodComponent.custom_tip}</Typography>}
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack spacing={0.5}>
            <Typography>Serving Unit</Typography>
            <Select
              disabled={!servingUnitOptions.length}
              value={servingUnit || ''}
              onChange={(evt) => {
                if (!foodComponent) {
                  return;
                }
                const servingUnit = servingUnitOptions.find(v => v.label === evt.target.value);
                const newComponent: any = {
                  ...foodComponent,
                  serving_unit_label: evt.target.value as string,
                };
                if (servingUnit) {
                  newComponent.serving_unit_amount = servingUnit.amount;
                  newComponent.servings = servingUnit.default_label_qty || 1;
                  setQuantityInput(newComponent.servings.toString());
                }
                state.onFieldChange({
                  components: state.value.components.map(c => {
                    if (c === foodComponent) {
                      return {
                        ...foodComponent,
                        ...newComponent,
                      };
                    }
                    return c;
                  }),
                });
              }}
            >
              {servingUnitOptions.map((v, i) => (
                <MenuItem
                  key={v.label + '-' + i}
                  disabled={!!v.label.match(/^--------/)}
                  value={v.label}
                >
                  {v.label + ' (' + formatNumber(v.amount, 1) + 'g)'}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack spacing={0.5}>
            <Typography>Quantity</Typography>
            <Autocomplete
              disabled={!quantityOptions.length}
              freeSolo
              options={quantityOptions}
              inputValue={quantityInput}
              filterOptions={x => x}
              onChange={handleQuantityChange}
              onInputChange={handleQuantityChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </Grid>
      </Grid>
      <Stack spacing={1}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>Add Ons</Typography>
          {!!copiedComponent && (
            <Button
              variant="outlined"
              size="small"
              disabled={!copiedComponent}
              onClick={props.onPaste}
              startIcon={<ContentPaste />}
            >
              Paste {copiedComponent.food_name}
            </Button>
          )}
        </Stack>
        {addons.map((addon, i) => (
          <FoodComponentAddon
            key={i}
            addon={addon}
            foodComponent={foodComponent}
            state={state}
            quantityRef={addon === lastUpdated ? quantityRef : null}
            searchAutoFocus={!lastUpdated && i === addons.length - 1}
            updateAddon={update => updateAddon(addon, update)}
            deleteAddon={() => deleteAddon(addon)}
            setFocusOnQuantity={setFocusOnQuantity}
          />
        ))}
      </Stack>
    </>
  );
};

const FoodComponentSummary = (props: {
  foodComponent: FoodComponent,
  selectedComponent: FoodComponent | null,
  copiedComponent: FoodComponent | null,
  viewOnly: boolean,
  state: FoodEditorState,
  setSelectedComponent: (component: FoodComponent | null) => void,
  openFoodSearchDrawer: () => void,
  onCopy: () => void,
  onPaste: () => void,
}) => {
  const { foodComponent, selectedComponent, copiedComponent, viewOnly, state } = props;
  const [editMode, setEditMode] = useState(false);
  const { authInfo } = useAuth();
  const foodQuery = useQuery(['food', foodComponent.food_name], async () => {
    if (!foodComponent.food_name) {
      return null;
    }
    return getFoodByQuery(foodComponent.food_name, authInfo!.access_token);
  });
  const [showCopiedMessagePopup, setShowCopiedMessagePopup] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [copyMenuOpen, setCopyMenuOpen] = useState(false);

  const handleCopyMenuClose = (event: Event) => {
    if (
      anchorRef.current
      && anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setCopyMenuOpen(false);
  };

  useEffect(() => {
    if (foodQuery.isSuccess) {
      foodComponent.searchItem = foodQuery.data ?? defaultSearchItem;
    }
  }, [foodQuery.isSuccess]);

  useEffect(() => {
    if (!editMode) {
      setEditMode(foodComponent === selectedComponent);
    }
  }, [editMode, foodComponent, selectedComponent]);

  const handleRemoveFoodComponent = () => {
    state.onFieldChange({
      components: state.value.components?.filter(c => c !== foodComponent),
    });
  };

  const handleEditFoodComponent = () => {
    props.setSelectedComponent(foodComponent);
  };

  function formatFoodComponentSizing(component: FoodComponent) {
    if (!component.servings && !component.serving_unit_amount) {
      return <span />;
    }

    return (
      <span>
        <span
          style={{ whiteSpace: 'nowrap', float: 'left' }}
        >
          {formatNumber(component.servings)}&times;{formatNumber(component.serving_unit_amount)}g&nbsp;
        </span>
        <span
          style={{ whiteSpace: 'nowrap' }}
        >
          ({component.serving_unit_label}) = {formatNumber(component.servings! * component.serving_unit_amount!)}g
        </span>
        {component.searchItem?.adult_serving_size_g && (
          <span>
            &nbsp;= {formatNumber(
              component.servings! * component.serving_unit_amount! / component.searchItem.adult_serving_size_g,
              1,
            )} adult servings
          </span>
        )}
      </span>
    );
  }

  return editMode
    ? (
      <>
        <CardContent
          onCopy={() => {
            const selection = document.activeElement;
            if (selection?.tagName == 'INPUT') {
              return;
            }
            setShowCopiedMessagePopup(true);
            setTimeout(() => {
              setShowCopiedMessagePopup(false);
            }, 800);
            props.onCopy();
          }}
          onPaste={() => {
            const selection = document.activeElement;
            if (!copiedComponent || selection?.tagName == 'INPUT') {
              return;
            }
            props.onPaste();
          }}
        >
          <Stack spacing={2}>
            <FoodComponentEditor
              foodComponent={foodComponent}
              copiedComponent={copiedComponent}
              state={state}
              selectedComponent={props.selectedComponent}
              openFoodSearchDrawer={props.openFoodSearchDrawer}
              setSelectedComponent={props.setSelectedComponent}
              onPaste={props.onPaste}
            />
          </Stack>
        </CardContent>
        <CardActions>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: 1 }}>
            <Stack direction="row" spacing={1} sx={{ px: 1.5, py: 0.75 }}>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  props.setSelectedComponent(null);
                  setEditMode(false);
                }}
                startIcon={<Done />}
              >
                Done
              </Button>
              <Tooltip
                placement="top"
                title="Copied!"
                arrow
                open={showCopiedMessagePopup}
                disableHoverListener
                disableTouchListener
                disableFocusListener
              >
                <span>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setShowCopiedMessagePopup(true);
                      setTimeout(() => {
                        setShowCopiedMessagePopup(false);
                      }, 800);
                      props.onCopy();
                    }}
                    startIcon={<ContentCopy />}
                  >
                    Copy
                  </Button>
                </span>
              </Tooltip>
            </Stack>
          </Stack>
        </CardActions>
      </>
    )
    : (
      <Box
        sx={{ backgroundColor: '#f5f5f5', border: '1px solid #d9d9d9', padding: 2 }}
        onCopy={() => {
          const selection = document.activeElement;
          if (selection?.tagName == 'INPUT') {
            return;
          }
          setShowCopiedMessagePopup(true);
          setTimeout(() => {
            setShowCopiedMessagePopup(false);
          }, 800);
          props.onCopy();
        }}
      >
        <Stack spacing={1}>
          <Stack direction="row" alignItems="start" justifyContent="space-between" sx={{ width: 1 }}>
            <Stack>
              <Typography variant="h4">
                {foodComponent?.food_name}
              </Typography>
              <Typography variant="body1" sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                {formatFoodComponentSizing(foodComponent)}
              </Typography>
              {foodComponent.searchItem?.custom_tip
                ? (
                  <Typography variant="body1" sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                    Tip: {foodComponent.searchItem?.custom_tip}
                  </Typography>
                )
                : foodComponent.custom_tip && (
                  <Typography variant="body1" sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                    Tip: {foodComponent.custom_tip}
                  </Typography>
                )}
            </Stack>
            {!viewOnly && (
              <Stack direction="row" spacing={1} sx={{ px: 1.5, py: 0.75 }}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleEditFoodComponent}
                  disabled={!!selectedComponent}
                  startIcon={<Edit />}
                >
                  Edit
                </Button>
                <ButtonGroup variant="contained" ref={anchorRef} disableElevation>
                  <Tooltip
                    placement="top"
                    title="Copied!"
                    arrow
                    open={showCopiedMessagePopup}
                    disableHoverListener
                    disableTouchListener
                    disableFocusListener
                  >
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setShowCopiedMessagePopup(true);
                        setTimeout(() => {
                          setShowCopiedMessagePopup(false);
                        }, 800);
                        props.onCopy();
                      }}
                      startIcon={<ContentCopy />}
                    >
                      Copy
                    </Button>
                  </Tooltip>
                  <Button
                    size="extraSmall"
                    onClick={() => setCopyMenuOpen(!copyMenuOpen)}
                  >
                    <ArrowDropDown />
                  </Button>
                </ButtonGroup>
                <Popper
                  sx={{
                    zIndex: 1,
                  }}
                  placement="bottom-end"
                  open={copyMenuOpen}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCopyMenuClose}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            <MenuItem
                              onClick={() => {
                                setCopyMenuOpen(false);
                                props.onCopy();
                                handleRemoveFoodComponent();
                              }}
                            >
                              Copy and Remove
                            </MenuItem>
                            {!!copiedComponent && (
                              <MenuItem
                                onClick={() => {
                                  setCopyMenuOpen(false);
                                  props.onPaste();
                                }}
                              >
                                Paste Addon {`(${copiedComponent.food_name})`}
                              </MenuItem>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={handleRemoveFoodComponent}
                  startIcon={<Delete />}
                >
                  Remove
                </Button>
              </Stack>
            )}
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {foodComponent?.component_addons && foodComponent?.component_addons.length > 0
              ? (
                <Stack spacing={0.25}>
                  <Typography variant="h5">Add ons</Typography>
                  {foodComponent.component_addons.map(addon => (
                    <Typography key={addon.food_id} variant="body1">
                      {addon.food_name} ({addon.servings} {addon.serving_unit_label})
                    </Typography>
                  ))}
                </Stack>
              )
              : <Typography variant="body1" sx={{ fontStyle: 'italic' }}>This item has no add-ons</Typography>}
          </Stack>
        </Stack>
      </Box>
    );
};

const FoodSearchDrawer = (props: {
  open: boolean,
  state: FoodEditorState,
  setSelectedComponent: (component: FoodComponent) => void,
  onClose: () => void,
  initialSearch?: string,
}) => {
  const { open, state, initialSearch } = props;
  const foodLoadReq = useAsyncResult<unknown>();

  const foodSearch = useFoodSearch({ context: {}, limit: 30 });

  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const addFoodReq = useAsyncResult<unknown>();

  useEffect(() => {
    const initialText = initialSearch || '';
    if (initialText) {
      foodSearch.setActiveSearch({
        type: 'db',
        text: initialText,
      });
    } else {
      foodSearch.clearActiveSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSearch]);

  useEffect(() => {
    if (open) {
      searchInputRef.current?.focus();
    }
  }, [open]);

  const loadFood = async (
    foodName: string,
  ) => {
    if (foodLoadReq.isPending) {
      return;
    }
    const foodQuery = foodApi.appApiFoodFoodSearchGetFoodQuery({
      food_name: foodName,
    });
    foodLoadReq.bind(foodQuery);
    foodQuery.catch(err => {
      logTrackedError({
        sourceName: 'FoodSearchDrawer.loadFood',
        origin: err,
        stackError: new Error(),
        context: { foodName },
        userMessage: `Error loading '${foodName}'.`,
      });
    });
    return foodQuery.then(foodResp => foodResp.data);
  };

  const addItemFromFoodSearch = (selection: FoodSearchSelection, idx: number) => {
    if (addFoodReq.isPending) {
      return;
    }
    addFoodReq.bind(_addItemFromFoodSearch(selection, idx));
  };

  const _addItemFromFoodSearch = async (selection: FoodSearchSelection, idx: number) => {
    const food = await loadFood(selection.name);
    if (!food) {
      return;
    }

    // shouldn't be needed after switching to elasticsearch, but can leave in just in case
    if (!food.rxfood_id) {
      window.alert(
        'This item is from USDA database and does not exist in RxFood database. Please select another item and try again',
      );
      return;
    }

    const foodComponent = searchItemToFoodComponent(food, state.value);
    if (state.value.components.filter(c => c === foodComponent).length === 0) {
      props.setSelectedComponent(foodComponent);
    } else {
      alert('This Food Component has already been added!');
      return;
    }
    foodSearch.clearActiveSearch();
    props.onClose();
  };

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      onClose={props.onClose}
      hideBackdrop={false}
      ModalProps={{ keepMounted: true }}
      transitionDuration={100}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: 600,
          borderRight: `1px solid #e0e0e0`,
          backgroundImage: 'none',
          borderLeft: `1px solid #e0e0e0`,
          boxShadow: '8',
        },
        '.MuiBackdrop-root': {
          opacity: '0 !important',
        },
      }}
    >
      <Stack spacing={1} sx={{ padding: '1rem', height: '100%' }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4">
            Add Food Component
          </Typography>
          <IconButton onClick={props.onClose} color="error">
            <CloseOutlined />
          </IconButton>
        </Stack>

        <OutlinedInput
          autoComplete="off"
          inputRef={searchInputRef}
          value={foodSearch.activeSearch.text}
          onChange={(e) => {
            foodSearch.setActiveSearch({
              type: 'db',
              text: e.target.value,
            });
          }}
          placeholder="Search for food"
          id="history-filter"
          autoFocus={true}
          // startAdornment={<SearchOutlined />}
          onKeyUp={(evt: React.KeyboardEvent<any>) => {
            if (evt.code == 'Enter') {
              foodSearch.setActiveSearch({
                type: 'external',
                text: foodSearch.activeSearch.text,
              });
            }
          }}
          endAdornment={
            <InputAdornment position="end">
              {foodSearch.indexStatusStr}
              <IconButton
                color="secondary"
                edge="end"
                disabled={foodSearch.loading}
                onClick={() => {
                  foodSearch.setActiveSearch({
                    type: 'external',
                    text: foodSearch.activeSearch.text,
                  });
                }}
              >
                {foodSearch.loading ? <LoadingOutlined /> : <SearchOutlined />}
              </IconButton>
            </InputAdornment>
          }
        />

        {foodSearch.activeSearch.type === 'external' && (
          <SpellCheck
            term={foodSearch.activeSearch.text}
            updateSpelling={(correctSpelling) => {
              foodSearch.setActiveSearch({
                type: 'external',
                text: correctSpelling,
              });
            }}
          />
        )}

        <Box sx={{ overflowY: 'scroll', flex: 1 }}>
          <FoodSearchResults
            foodSearch={foodSearch}
            saveReq={addFoodReq}
            onFoodSelect={addItemFromFoodSearch}
          />
        </Box>
      </Stack>
    </Drawer>
  );
};

export let _compositeFoodLastCalculatedDetailsHack: Partial<FoodEditorValue> = {};
export const useRecalculateCompositeDetails = (state: FoodEditorState) => {
  const updateCompositeDetails = async () => {
    const res = await foodApi.appApiFoodFoodDetailsCalculateFoodCompositeDetails({
      CalculateCompositeFoodDetailsRequest: {
        components: (state.value.components as FoodComponentResponse[]),
      },
    }).then(res => res.data);

    _compositeFoodLastCalculatedDetailsHack = apiCalculatedDetailsToFoodEditorValue(state, res);
    state.onFieldChange({
      ..._compositeFoodLastCalculatedDetailsHack,
      ...state.value.overrideValues,
    });
  };

  useEffect(() => {
    updateCompositeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.value.components]);
};

export const FoodComponentBuilder = (props: {
  state: FoodEditorState,
  field: FoodEditorField,
  isCompareTo: boolean,
}) => {
  const { state, field, isCompareTo } = props;
  const [showDrawer, setShowDrawer] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState<FoodComponent | null>(null);
  const [copiedComponent, setCopiedComponent] = useState<FoodComponent | null>(null);
  const updateCompositeDetails = useRecalculateCompositeDetails(state);

  useEffect(() => {
    if (createNew && selectedComponent) {
      state.onFieldChange({
        components: [selectedComponent, ...state.value.components!],
      });
      setCreateNew(false);
    }

    if (!showDrawer) {
      setCreateNew(false);
    }
  }, [selectedComponent, createNew, showDrawer]);

  const secondaryActions = (
    <Button
      onClick={() => {
        setCreateNew(true);
        setShowDrawer(true);
      }}
      variant="contained"
      disabled={!!selectedComponent || isCompareTo}
      style={{ display: isCompareTo ? 'none' : 'unset' }}
      startIcon={<Add />}
    >
      Add a food component
    </Button>
  );
  const componentsTotalWeight = getComponentsTotalWeight(state.value.components);
  const listedServings = floatOrNull(state.value.listedServingCount);

  const subHeader = (
    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
      Total Weight: {formatNumber(componentsTotalWeight)}g
      {!!listedServings && !!componentsTotalWeight && ' = ' + formatNumber(componentsTotalWeight / listedServings, 4)
          + ' g/serving'}
    </Typography>
  );

  const handleCopy = (component: FoodComponent) => {
    setCopiedComponent(component);
  };

  const handlePaste = (component: FoodComponent) => {
    if (!copiedComponent) {
      return;
    }

    const addons = component.component_addons || [];
    state.onFieldChange({
      components: state.value.components.map(c => {
        if (c === component) {
          return {
            ...component,
            component_addons: [
              ...addons,
              {
                ...copiedComponent,
                component_addons: [],
              },
            ],
          };
        }
        return c;
      }),
    });
  };

  return (
    <MainCard
      title="Food Components"
      content={false}
      subheader={subHeader}
      secondary={secondaryActions}
      sx={{ overflow: 'unset' }}
    >
      <FoodSearchDrawer
        open={showDrawer}
        state={state}
        setSelectedComponent={setSelectedComponent}
        onClose={() => {
          setShowDrawer(false);
        }}
      />
      <CardContent>
        <Stack spacing={2}>
          {state.value.components?.map((component, idx) => (
            <FoodComponentSummary
              key={idx}
              state={state}
              viewOnly={field.viewOnly ?? isCompareTo}
              foodComponent={component}
              selectedComponent={selectedComponent}
              copiedComponent={copiedComponent}
              setSelectedComponent={setSelectedComponent}
              openFoodSearchDrawer={() => setShowDrawer(true)}
              onCopy={() => handleCopy(component)}
              onPaste={() => handlePaste(component)}
            />
          ))}
        </Stack>
      </CardContent>
    </MainCard>
  );
};

export const getComponentsTotalWeight = (components: FoodComponent[]): number => {
  return components.reduce((acc, component) => {
    const addOnsWeight = component.component_addons ? getComponentsTotalWeight(component.component_addons) : 0;

    return acc + (component.servings! * component.serving_unit_amount!) + addOnsWeight;
  }, 0);
};
