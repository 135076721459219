import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  ImageDetectionResultOcrMatch,
  ImageDetectionResultOcrNftParsedResultNftParsedNutrient,
  ImageDetectionResultOcrNftParsedResultNftParsedServing,
  ImageDetectionResultOcrPoint,
} from 'api/generated/MNT';
import {
  FOOD_EDITOR_NUTRIENT_FIELDS,
  FOOD_EDITOR_NUTRIENTS_BY_EDITOR_FIELD,
  FOOD_EDITOR_NUTRIENTS_BY_MNT_FIELD,
  FoodEditorNutrientFieldDefinition,
  FoodEditorNutrientFields,
  FoodEditorNutrientValue,
} from 'food-editor/food-editor-nutrient-fields';
import {
  nutrientRdaPctToValue,
  nutrientValueIsRdaPct,
  nutrientValueToRdaPct,
} from 'food-editor/utils/NutrientRDAValues';
import { formatPct } from 'food-editor/utils/utils';
import { floatOrNull } from 'pages/QueueItem/meal-builder/MealBuilder';
import { useEffect, useMemo, useState } from 'react';
import { FormControl, Modal } from 'react-bootstrap';
import { coalesceNaN } from 'utils/numerical';
import {
  FoodEditorState,
  NUTRIENT_100G,
  nutrientScale,
  nutrientValueToStrOrEmpty,
  OcrImageValue,
  WhiteboardItemData,
} from './food-editor';

let _imgNewWindowRef: Window | null = null;
export const showImgNewWindow = (src: string) => {
  // Note: google cloud storage forces downloading images instead of displaying them in the browser.
  // This is a workaround to display the image in a new window.
  if (!_imgNewWindowRef || _imgNewWindowRef.closed) {
    _imgNewWindowRef = window.open(
      '',
      'show-img-new-window',
      'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=1000,top='
        + (screen.height - 100) + ',left=' + (screen.width - 840),
    );
  }
  const win = _imgNewWindowRef;
  if (!win) {
    return;
  }
  const srcEscaped = src.replace(/"/g, '\\"');
  win.document.body.innerHTML = `<img src="${srcEscaped}" style="max-width: 100%; max-height: 100%;" />`;
  win.focus();
};
const ocrMatchToBbox = (match: ImageDetectionResultOcrMatch) => {
  const vertices: ImageDetectionResultOcrPoint[] = match.regions?.reduce((acc, region) => {
    return [
      ...acc,
      ...region.vertices,
    ];
  }, [] as ImageDetectionResultOcrPoint[]) || [];

  const region = vertices.reduce((acc, vert) => {
    return {
      x1: Math.min(acc.x1, vert.x),
      y1: Math.min(acc.y1, vert.y),
      x2: Math.max(acc.x2, vert.x),
      y2: Math.max(acc.y2, vert.y),
    };
  }, {
    x1: Infinity,
    y1: Infinity,
    x2: -Infinity,
    y2: -Infinity,
  });

  return {
    x: region.x1,
    y: region.y1,
    width: region.x2 - region.x1,
    height: region.y2 - region.y1,
  };
};

export const OcrImageValueView = (props: {
  value:
    | OcrImageValue<{
      match: ImageDetectionResultOcrMatch,
    }>
    | null,
  maxWidth?: number,
}) => {
  const { value } = props;
  const bbox = useMemo(() => {
    if (!value?.match) {
      return {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      };
    }
    return ocrMatchToBbox(value?.match);
  }, [value?.match]);

  const pad = bbox.width * 0.05;

  const scale = props.maxWidth
    ? Math.min(1, (props.maxWidth - 9) / (bbox.width + pad * 2))
    : 1;

  const containerStyle: React.CSSProperties = {
    width: `${bbox.width + pad * 2}px`,
    height: `${bbox.height + pad * 2}px`,
    overflow: 'hidden',
    position: 'relative',
    transform: `scale(${scale})`,
    transformOrigin: '0px top 0px',
  };

  const imageStyle: React.CSSProperties = {
    position: 'absolute',
    top: -bbox.y + pad,
    left: -bbox.x + pad,
    cursor: 'pointer',
  };

  if (!value) {
    return null;
  }

  return (
    <div
      style={{
        border: '1px solid #949494',
        borderRadius: '5px',
        width: (bbox.width + pad * 2) * scale,
        height: (bbox.height + pad * 2) * scale,
        overflow: 'hidden',
      }}
    >
      <div style={containerStyle}>
        <img
          src={value.image.image_url}
          style={imageStyle}
          onClick={() => showImgNewWindow(value.image.image_url!)}
        />
      </div>
    </div>
  );
};

const NFTFieldHeading = (props: { children: React.ReactNode }) => {
  return <Typography variant="subtitle1">{props.children}</Typography>;
};

export const FoodEditorNFTIngestionModal = (props: {
  state: FoodEditorState,
  nfts: WhiteboardItemData[],
  suggestedServing: OcrImageValue<ImageDetectionResultOcrNftParsedResultNftParsedServing>,
  nutrients: Record<string, OcrImageValue<ImageDetectionResultOcrNftParsedResultNftParsedNutrient>>,
  open: boolean,
  onClose: () => void,
  onSubmit: (value: Partial<FoodEditorState['value']>) => void,
}) => {
  const nutrientFieldDefs = useMemo(() => {
    return FOOD_EDITOR_NUTRIENT_FIELDS.filter(field => !!props.nutrients[field.mntField]);
  }, [props.nutrients]);

  const [formValues, setFormValues] = useState<
    Partial<
      {
        suggestedServingCount: string,
        suggestedServingUnitLabel: string,
        suggestedServingAmountG: string,
      } & FoodEditorNutrientFields<string>
    >
  >({});
  useEffect(() => {
    const nutrientNftValue = (field: FoodEditorNutrientFieldDefinition) => {
      const nftNutrient = props.nutrients[field.mntField]?.value;
      if (!nftNutrient) {
        return '';
      }
      if (typeof nftNutrient.amount == 'number') {
        return nutrientValueToStrOrEmpty(nftNutrient.amount);
      }
      if (typeof nftNutrient.rdi_pct == 'number') {
        return formatPct(nftNutrient.rdi_pct);
      }
      return '';
    };
    setFormValues(
      {
        suggestedServingCount: nutrientValueToStrOrEmpty(
          props.suggestedServing?.value?.serving_count
            ?? props.state.value.suggestedServingCount,
        ),
        suggestedServingUnitLabel: (
          props.suggestedServing?.value?.serving_unit
            ?? props.state.value.suggestedServingUnitLabel
            ?? ''
        ),
        suggestedServingAmountG: nutrientValueToStrOrEmpty(
          props.suggestedServing?.value?.serving_amount_g
            ?? props.state.value.suggestedServingAmountG,
        ),
        ...Object.fromEntries(
          nutrientFieldDefs.map(field => [
            field.editorField,
            nutrientNftValue(field),
          ]),
        ),
      } satisfies Partial<FoodEditorState['value']>,
    );
  }, [props.suggestedServing, props.nutrients, nutrientFieldDefs]);

  const formField = (name: keyof typeof formValues) => {
    return {
      value: formValues[name],
      onChange: (evt: React.ChangeEvent<HTMLInputElement>) => {
        setFormValues(prev => ({
          ...prev,
          [name]: evt.target.value,
        }));
      },
    };
  };

  const currentValue = (name: keyof typeof formValues) => {
    const field = FOOD_EDITOR_NUTRIENTS_BY_EDITOR_FIELD[name];
    const value = field
      ? nutrientScale({
        value: props.state.value[name] as any,
        from: NUTRIENT_100G,
        to: formValues.suggestedServingAmountG,
      })
      : props.state.value[name];

    const valueStr = nutrientValueToStrOrEmpty(value);

    const rdaPct = nutrientValueToRdaPct(name, value as string);

    return (
      <FormHelperText>
        Current: {valueStr || 'n/a'}
        {!!valueStr && field?.unit}
        {rdaPct && ` (${rdaPct})`}
      </FormHelperText>
    );
  };

  const resultValuesPer100G = useMemo(() => {
    const servingAmountFloat = floatOrNull(formValues.suggestedServingAmountG!);
    if (!servingAmountFloat) {
      return {
        error: 'Serving Amount is required',
        values: {},
      };
    }

    const errors: string[] = [];
    const nutrientValues = Object.fromEntries(
      nutrientFieldDefs
        .map(field => {
          let formVal = formValues[field.editorField];
          if (!formVal) {
            return [field.editorField, null] as const;
          }

          if (formVal?.includes('%')) {
            const val = nutrientRdaPctToValue(field.editorField, formVal);
            if (typeof val !== 'number') {
              errors.push(`Invalid value for ${field.label}: ${formVal}`);
              return [field.editorField, null] as const;
            }

            formVal = val.toString();
          }

          const val = nutrientScale({
            value: formVal,
            from: servingAmountFloat,
            to: NUTRIENT_100G,
          });

          if (typeof val !== 'number') {
            errors.push(`Invalid value for ${field.label}: ${formVal}`);
          }

          return [
            field.editorField,
            val,
          ] as const;
        })
        .filter(([_, val]) => typeof val === 'number'),
    );

    if (errors.length) {
      return {
        error: errors.join(', '),
        values: {},
      };
    }

    return {
      error: null,
      values: {
        suggestedServingCount: nutrientValueToStrOrEmpty(formValues.suggestedServingCount),
        suggestedServingUnitLabel: formValues.suggestedServingUnitLabel,
        suggestedServingAmountG: nutrientValueToStrOrEmpty(formValues.suggestedServingAmountG),
        ...nutrientValues,
      },
    };
  }, [formValues, nutrientFieldDefs]);

  return (
    <Dialog open={props.open} onClose={props.onClose} scroll="paper">
      <DialogTitle>Import Values from NFT</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={1}>
          <Stack spacing={1}>
            <OcrImageValueView value={props.suggestedServing} maxWidth={400} />
            <Stack direction="row" spacing={1}>
              <Stack>
                <NFTFieldHeading>Serving Count</NFTFieldHeading>
                <OutlinedInput
                  style={{ width: 200 - 10 }}
                  id="nft-sug-serving-count"
                  inputProps={{ step: 'any', min: 0 }}
                  placeholder="Serving Count"
                  type="number"
                  {...formField('suggestedServingCount')}
                />
                {currentValue('suggestedServingCount')}
              </Stack>

              <Stack>
                <NFTFieldHeading>Serving Unit</NFTFieldHeading>
                <OutlinedInput
                  style={{ width: 200 - 10 }}
                  id="nft-sug-serving-unit"
                  inputProps={{ step: 'any', min: 0 }}
                  placeholder="Serving Unit (cups, oz, etc)"
                  type="text"
                  {...formField('suggestedServingUnitLabel')}
                />
                {currentValue('suggestedServingUnitLabel')}
              </Stack>

              <Stack>
                <NFTFieldHeading>Serving Amount</NFTFieldHeading>
                <OutlinedInput
                  style={{ width: 200 - 10 }}
                  id="nft-sug-serving-amt"
                  inputProps={{ step: 'any', min: 0 }}
                  placeholder="Serving Amount (g)"
                  type="number"
                  endAdornment="g"
                  {...formField('suggestedServingAmountG')}
                />
                {currentValue('suggestedServingAmountG')}
              </Stack>
            </Stack>
          </Stack>

          <Grid container spacing={1} style={{ marginLeft: -8 }}>
            {nutrientFieldDefs.map(field => {
              const val = props.nutrients[field.mntField];
              const rdiValStr = nutrientValueToStrOrEmpty(nutrientRdaPctToValue(
                field.editorField,
                formValues[field.editorField] as any,
              ));
              return (
                <Grid key={field.mntField} item style={{ width: 200 }}>
                  <Stack>
                    <NFTFieldHeading>{field.label}</NFTFieldHeading>
                    <OcrImageValueView value={val} maxWidth={200} />
                    <div style={{ height: 5 }} />
                    <OutlinedInput
                      id={`nft-${field.mntField}-amt`}
                      inputProps={{ step: 'any', min: 0 }}
                      endAdornment={
                        <span style={{ color: 'grey' }}>
                          {rdiValStr
                            ? rdiValStr + field.unit
                            : field.unit}
                        </span>
                      }
                      {...formField(field.editorField as any)}
                    />
                    {currentValue(field.editorField)}
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        {resultValuesPer100G.error && <Typography color="error">{resultValuesPer100G.error}</Typography>}
        <Button onClick={props.onClose} color="secondary">
          Cancel
        </Button>
        <Button
          disabled={!!resultValuesPer100G.error}
          onClick={() => props.onSubmit(resultValuesPer100G.values)}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
