import { Stack, Typography } from '@mui/material';
import moment from 'moment';

import type { MealResponse } from 'api/generated/MNT';
import { BlobImageHistory } from 'components/BlobImageHistory';
import MainCard from 'components/MainCard';
import { useAuth } from 'context/appContext';
import _ from 'lodash';
import { MixpanelMealItemSource } from 'types/DraftItem';

export const RecommendedMeals = (props: {
  title: string,
  mealHistory: MealResponse[],
  mealTitles?: string[],
  addItemSource: MixpanelMealItemSource,
  handleClick?: (mealResponse: MealResponse, index: number, source?: string) => void,
}) => {
  const { mealHistory } = props;
  const { authInfo } = useAuth();

  return (
    <Stack>
      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
        {props.title}
      </Typography>
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          overflowX: 'auto',
          overflowY: 'hidden',
          maxHeight: 400,
        }}
      >
        {mealHistory.map((mealResponse, index) => {
          if (!mealResponse?.meal_items?.length) {
            return null;
          }

          // Find the first meal item with a photo
          const mealItemWithPhoto = mealResponse.meal_items.find((mealItem) => Number.isFinite(mealItem.meal_photo_id));
          const mealTime = moment.utc(mealResponse.meal_items[0].created_time).fromNow();

          return (
            <MainCard
              key={mealResponse.id}
              sx={{
                minWidth: 200,
                maxWidth: 200,
                padding: 0,
              }}
              contentSX={{
                padding: 1,
                paddingBottom: '10px !important',
              }}
              className="click"
              onClick={() => props.handleClick?.(mealResponse, index, props.addItemSource)}
              onContextMenu={() => {
                window.open(`${window.location.origin}/meal/${mealResponse.id}`, '_blank');
              }}
            >
              <Stack
                spacing={0.5}
              >
                {authInfo && mealItemWithPhoto?.meal_photo_id && (
                  <BlobImageHistory
                    photo_id={mealItemWithPhoto.meal_photo_id}
                    access_token={authInfo.access_token}
                    style={{
                      maxHeight: 200,
                      objectFit: 'cover',
                    }}
                  />
                )}
                <Typography sx={{ padding: 0.5 }}>
                  {mealResponse.meal_items.map(m => m.food_name_alias ?? m.food_name).join(', ')}
                  <br />
                  {mealTime}
                  {props.mealTitles?.[index] && (
                    <>
                      <br />
                      {props.mealTitles[index]}
                    </>
                  )}
                </Typography>
              </Stack>
            </MainCard>
          );
        })}
      </Stack>
    </Stack>
  );
};
