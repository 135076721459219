import { ClickAwayListener, List, ListItem, ListItemButton, ListItemText, Popper, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import apiClient from '../api-client';
import useErrorState from '../hooks/use-error-state';
import { QueryKey } from '../types';
import ontologyRequired from '../validations/ontology-required';

type Ontologies = string[];

export const useOntologiesQuery = () => {
  return useQuery([QueryKey.Ontologies], async () => {
    return apiClient.getOntologies();
  });
};

export const useOntology = (o: Ontologies) => {
  const ontologies = useOntologiesQuery();
  if (!ontologies.data) {
    return null;
  }
  return ontologies.data.find(ontology => (
    ontology.levels.join('::') === o.join('::')
  ));
};

export default function OntologyPicker({
  onChangeValue,
  showErrors,
  value,
  reducePopperHeight,
}: {
  onChangeValue: (o: Ontologies) => void,
  showErrors?: boolean,
  value: Ontologies,
  reducePopperHeight?: boolean,
}) {
  const ontologiesQuery = useOntologiesQuery();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const textFieldRef = useRef<HTMLDivElement | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [options, setOptions] = useState<{ ids: string[], name: string }[]>([]);

  const debouncedSearchOntologies = useCallback(
    _.debounce((query: string) => {
      if (!ontologiesQuery.data) {
        return;
      }
      setOptions(
        ontologiesQuery.data
          .filter(o => o.levels.join(' ').toLowerCase().includes(query.toLowerCase()))
          .map(o => ({ ids: o.levels, name: o.levels.filter(x => !!x).join(' > ') })),
      );
    }, 250),
    [setOptions, ontologiesQuery.data],
  );

  useEffect(() => {
    debouncedSearchOntologies(searchQuery);
    return () => {
      debouncedSearchOntologies.cancel();
    };
  }, [searchQuery, ontologiesQuery.data]);

  const errorState = useErrorState(value, showErrors, ontologyRequired);

  const handleTextFieldClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    errorState.markDirty();
    setAnchorEl(null);
  };

  const handleOptionClick = (option: { ids: string[], name: string }) => {
    onChangeValue(option.ids);
    errorState.markDirty();
    setAnchorEl(null);
  };

  const ontology = value.filter(x => !!x);
  const listMinHeight = '350px';
  const listMaxHeight = !reducePopperHeight ? '70vh' : '30vh';

  return (
    <>
      <TextField
        ref={textFieldRef}
        error={errorState.showError}
        helperText={errorState.error}
        label="Ontology Classification"
        variant="standard"
        onClick={handleTextFieldClick}
        fullWidth
        value={ontology.join(' > ')}
        style={{ paddingBottom: 20 }}
      />
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{
          width: textFieldRef.current?.clientWidth ?? 0,
          backgroundColor: '#fff',
          borderColor: '#ccc',
          borderWidth: 1,
          borderStyle: 'solid',
          borderRadius: 5,
          padding: 10,
          zIndex: 9999,
        }}
        modifiers={[{
          name: 'flip',
          enabled: !reducePopperHeight,
          options: {
            fallbackPlacements: ['top'],
          },
        }]}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <TextField
              type="search"
              fullWidth
              autoFocus
              placeholder="Search"
              size="small"
              onChange={evt => setSearchQuery(evt.target.value)}
              value={searchQuery}
            />
            <List dense style={{ maxHeight: listMaxHeight, minHeight: listMinHeight, overflow: 'auto' }}>
              {!!options.length
                && options.map(option => (
                  <ListItemButton
                    key={option.name}
                    onClick={() => handleOptionClick(option)}
                  >
                    <ListItemText primary={option.name} />
                  </ListItemButton>
                ))}
              {!options.length && (
                <ListItem>
                  <ListItemText
                    style={{ fontStyle: 'italic' }}
                    primary="No matching ontologies"
                  />
                </ListItem>
              )}
            </List>
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
}
