/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppApiDataReviewerGetMealPhotoQueueId200Response } from '../models';
// @ts-ignore
import { CreateDataReviewerGroupRequest } from '../models';
// @ts-ignore
import { CreateDataReviewerRequest } from '../models';
// @ts-ignore
import { CreateMealItemFromQueueRequest } from '../models';
// @ts-ignore
import { CreateMealRequest } from '../models';
// @ts-ignore
import { CreateQualityAssuranceLogRequest } from '../models';
// @ts-ignore
import { CreateQueueFeedbackRequest } from '../models';
// @ts-ignore
import { DataReviewerClaimQueueResponse } from '../models';
// @ts-ignore
import { DataReviewerGroupResponse } from '../models';
// @ts-ignore
import { DataReviewerGroupSettingsResponse } from '../models';
// @ts-ignore
import { DataReviewerHelpRequest } from '../models';
// @ts-ignore
import { DataReviewerResponse } from '../models';
// @ts-ignore
import { MealItemResponse } from '../models';
// @ts-ignore
import { MealPhotoQueueQoSDetailsResponse } from '../models';
// @ts-ignore
import { MealPhotoQueueQualityAssuranceResponse } from '../models';
// @ts-ignore
import { MealPhotoQueueResponse } from '../models';
// @ts-ignore
import { PatientContextQueueResponse } from '../models';
// @ts-ignore
import { PatientHistoryQueueResponse } from '../models';
// @ts-ignore
import { QualityAssuranceLogResponse } from '../models';
// @ts-ignore
import { RecentMealItemResponse } from '../models';
// @ts-ignore
import { RecommendedMeal } from '../models';
// @ts-ignore
import { RegisterDataReviewerToGroupRequest } from '../models';
// @ts-ignore
import { UpdateDataReviewerGroupRequest } from '../models';
// @ts-ignore
import { UpdateDataReviewerRequest } from '../models';
// @ts-ignore
import { UpdateQualityAssuranceLogRequest } from '../models';
/**
 * DataReviewApi - axios parameter creator
 * @export
 */
export const DataReviewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete the data reviewer
         * @param {number} data_reviewer_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerDeleteDataReviewer: async (data_reviewer_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiDataReviewerDeleteDataReviewer', 'data_reviewer_id', data_reviewer_id)
            const localVarPath = `/data_reviewer/{data_reviewer_id}`
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the data reviewer group
         * @param {number} data_reviewer_group_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerDeleteDataReviewerGroup: async (data_reviewer_group_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_group_id' is not null or undefined
            assertParamExists('appApiDataReviewerDeleteDataReviewerGroup', 'data_reviewer_group_id', data_reviewer_group_id)
            const localVarPath = `/data_reviewer_group/{data_reviewer_group_id}`
                .replace(`{${"data_reviewer_group_id"}}`, encodeURIComponent(String(data_reviewer_group_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete meal items association to MPQ deemed to have no viable meal items. Meal is deleted if no other meal items exists.
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {'inappropriate' | 'no_food_in_photo' | 'duplicate' | 'testing'} reason 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerDeleteMealItemsFromQueue: async (data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, reason: 'inappropriate' | 'no_food_in_photo' | 'duplicate' | 'testing', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_group_id' is not null or undefined
            assertParamExists('appApiDataReviewerDeleteMealItemsFromQueue', 'data_reviewer_group_id', data_reviewer_group_id)
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiDataReviewerDeleteMealItemsFromQueue', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiDataReviewerDeleteMealItemsFromQueue', 'meal_photo_queue_id', meal_photo_queue_id)
            // verify required parameter 'reason' is not null or undefined
            assertParamExists('appApiDataReviewerDeleteMealItemsFromQueue', 'reason', reason)
            const localVarPath = `/data_reviewer_group/{data_reviewer_group_id}/data_reviewer/{data_reviewer_id}/meal_photo_queue/{meal_photo_queue_id}/meal_items`
                .replace(`{${"data_reviewer_group_id"}}`, encodeURIComponent(String(data_reviewer_group_id)))
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reason !== undefined) {
                localVarQueryParameter['reason'] = reason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the data reviewer from a group group
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerDeregisterDataReviewerFromGroup: async (data_reviewer_group_id: number, data_reviewer_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_group_id' is not null or undefined
            assertParamExists('appApiDataReviewerDeregisterDataReviewerFromGroup', 'data_reviewer_group_id', data_reviewer_group_id)
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiDataReviewerDeregisterDataReviewerFromGroup', 'data_reviewer_id', data_reviewer_id)
            const localVarPath = `/data_reviewer_group/{data_reviewer_group_id}/deregister_data_reviewer`
                .replace(`{${"data_reviewer_group_id"}}`, encodeURIComponent(String(data_reviewer_group_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (data_reviewer_id !== undefined) {
                localVarQueryParameter['data_reviewer_id'] = data_reviewer_id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Escalate a queue item to a higher review level
         * @param {number} meal_photo_queue_id 
         * @param {string} [reason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerEscalateMealPhotoQueueItem: async (meal_photo_queue_id: number, reason?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiDataReviewerEscalateMealPhotoQueueItem', 'meal_photo_queue_id', meal_photo_queue_id)
            const localVarPath = `/meal_photo_queue/{meal_photo_queue_id}/escalate`
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reason !== undefined) {
                localVarQueryParameter['reason'] = reason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get currently logged in data reviewer. This is for restricted use case, use for only DR information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetCurrentDataReviewer: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/data_reviewer/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get details of data reviewer
         * @param {number} data_reviewer_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetDataReviewer: async (data_reviewer_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetDataReviewer', 'data_reviewer_id', data_reviewer_id)
            const localVarPath = `/data_reviewer/{data_reviewer_id}`
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all data reviewer group members
         * @param {number} data_reviewer_group_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetDataReviewerGroup: async (data_reviewer_group_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_group_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetDataReviewerGroup', 'data_reviewer_group_id', data_reviewer_group_id)
            const localVarPath = `/data_reviewer_group/{data_reviewer_group_id}`
                .replace(`{${"data_reviewer_group_id"}}`, encodeURIComponent(String(data_reviewer_group_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all meals queued for review for a specific data_reviewer_group
         * @param {number} data_reviewer_group_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetDataReviewerGroupMealPhotoQueuesProcessed: async (data_reviewer_group_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_group_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetDataReviewerGroupMealPhotoQueuesProcessed', 'data_reviewer_group_id', data_reviewer_group_id)
            const localVarPath = `/data_reviewer_group/{data_reviewer_group_id}/meal_photo_queue/processed`
                .replace(`{${"data_reviewer_group_id"}}`, encodeURIComponent(String(data_reviewer_group_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all data reviewer groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetDataReviewerGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/data_reviewer_groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a copy of the original photo submitted for an MPQ
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetDataReviewerMealPhotoQueueOriginalPhoto: async (data_reviewer_id: number, meal_photo_queue_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetDataReviewerMealPhotoQueueOriginalPhoto', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetDataReviewerMealPhotoQueueOriginalPhoto', 'meal_photo_queue_id', meal_photo_queue_id)
            const localVarPath = `/data_reviewer/{data_reviewer_id}/meal_photo_queue/{meal_photo_queue_id}/original_photo`
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a copy of the resized photo submitted for an MPQ
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetDataReviewerMealPhotoQueuePhoto: async (data_reviewer_id: number, meal_photo_queue_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetDataReviewerMealPhotoQueuePhoto', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetDataReviewerMealPhotoQueuePhoto', 'meal_photo_queue_id', meal_photo_queue_id)
            const localVarPath = `/data_reviewer/{data_reviewer_id}/meal_photo_queue/{meal_photo_queue_id}/photo`
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all meals processed by a data reviewer
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetDataReviewerMealPhotoQueueProcessed: async (data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_group_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetDataReviewerMealPhotoQueueProcessed', 'data_reviewer_group_id', data_reviewer_group_id)
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetDataReviewerMealPhotoQueueProcessed', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetDataReviewerMealPhotoQueueProcessed', 'meal_photo_queue_id', meal_photo_queue_id)
            const localVarPath = `/data_reviewer_group/{data_reviewer_group_id}/data_reviewer/{data_reviewer_id}/meal_photo_queue/{meal_photo_queue_id}/processed`
                .replace(`{${"data_reviewer_group_id"}}`, encodeURIComponent(String(data_reviewer_group_id)))
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all meals queued for review for a specific data_reviewer
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetDataReviewerMealPhotoQueuesProcessed: async (data_reviewer_group_id: number, data_reviewer_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_group_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetDataReviewerMealPhotoQueuesProcessed', 'data_reviewer_group_id', data_reviewer_group_id)
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetDataReviewerMealPhotoQueuesProcessed', 'data_reviewer_id', data_reviewer_id)
            const localVarPath = `/data_reviewer_group/{data_reviewer_group_id}/data_reviewer/{data_reviewer_id}/meal_photo_queue/processed`
                .replace(`{${"data_reviewer_group_id"}}`, encodeURIComponent(String(data_reviewer_group_id)))
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all meals queued for a data reviewer
         * @param {number} data_reviewer_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetDataReviewerMealPhotoQueuesToBeProcessed: async (data_reviewer_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetDataReviewerMealPhotoQueuesToBeProcessed', 'data_reviewer_id', data_reviewer_id)
            const localVarPath = `/data_reviewer/{data_reviewer_id}/meal_photo_queue/to_be_processed`
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all meals queued for a data reviewer
         * @param {number} data_reviewer_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetDataReviewerNextQueue: async (data_reviewer_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetDataReviewerNextQueue', 'data_reviewer_id', data_reviewer_id)
            const localVarPath = `/data_reviewer/{data_reviewer_id}/meal_photo_queue/next_queue`
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get data reviewer settings for the given group
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetDataReviewerSettings: async (data_reviewer_group_id: number, data_reviewer_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_group_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetDataReviewerSettings', 'data_reviewer_group_id', data_reviewer_group_id)
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetDataReviewerSettings', 'data_reviewer_id', data_reviewer_id)
            const localVarPath = `/data_reviewer_group/{data_reviewer_group_id}/data_reviewer/{data_reviewer_id}/settings`
                .replace(`{${"data_reviewer_group_id"}}`, encodeURIComponent(String(data_reviewer_group_id)))
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all data reviewers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetDataReviewers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/data_reviewer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the food data, for a string of foods, from the server in the MPQ format
         * @param {string} food_names list of food names
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetFormattedFoodStringWithUnits: async (food_names: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_names' is not null or undefined
            assertParamExists('appApiDataReviewerGetFormattedFoodStringWithUnits', 'food_names', food_names)
            const localVarPath = `/foods/formatted_food_string`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_names !== undefined) {
                localVarQueryParameter['food_names'] = food_names;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the food data, for a string of foods, from the server in the MPQ format
         * @param {string} food_names list of food names
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetFormattedMpqFoods: async (food_names: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_names' is not null or undefined
            assertParamExists('appApiDataReviewerGetFormattedMpqFoods', 'food_names', food_names)
            const localVarPath = `/foods/formatted_mpq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_names !== undefined) {
                localVarQueryParameter['food_names'] = food_names;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve previous meal item details for the patient for the current MPQ id
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {number} num_days 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetMealItemsHistory: async (data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, num_days: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_group_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetMealItemsHistory', 'data_reviewer_group_id', data_reviewer_group_id)
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetMealItemsHistory', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetMealItemsHistory', 'meal_photo_queue_id', meal_photo_queue_id)
            // verify required parameter 'num_days' is not null or undefined
            assertParamExists('appApiDataReviewerGetMealItemsHistory', 'num_days', num_days)
            const localVarPath = `/data_reviewer_group/{data_reviewer_group_id}/data_reviewer/{data_reviewer_id}/meal_photo_queue/{meal_photo_queue_id}/meal_item_history`
                .replace(`{${"data_reviewer_group_id"}}`, encodeURIComponent(String(data_reviewer_group_id)))
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (num_days !== undefined) {
                localVarQueryParameter['num_days'] = num_days;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Look up a meal photo queue ID from a meal ID
         * @param {number} meal_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetMealPhotoQueueId: async (meal_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meal_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetMealPhotoQueueId', 'meal_id', meal_id)
            const localVarPath = `/meal/{meal_id}/queue_id`
                .replace(`{${"meal_id"}}`, encodeURIComponent(String(meal_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an individual meal photo queue item
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetMealPhotoQueueItem: async (meal_photo_queue_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetMealPhotoQueueItem', 'meal_photo_queue_id', meal_photo_queue_id)
            const localVarPath = `/meal_photo_queue/{meal_photo_queue_id}`
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the QOS details for a meal photo queue item, which includes all the queues which \"overlap\" with the meal photo queue item.
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetMealPhotoQueueItemQosDetails: async (meal_photo_queue_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetMealPhotoQueueItemQosDetails', 'meal_photo_queue_id', meal_photo_queue_id)
            const localVarPath = `/meal_photo_queue/{meal_photo_queue_id}/qos-details`
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a copy of the audio submitted for an MPQ
         * @param {number} data_reviewer_group_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetMealPhotoQueueOriginalAudio: async (data_reviewer_group_id: number, meal_photo_queue_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_group_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetMealPhotoQueueOriginalAudio', 'data_reviewer_group_id', data_reviewer_group_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetMealPhotoQueueOriginalAudio', 'meal_photo_queue_id', meal_photo_queue_id)
            const localVarPath = `/data_reviewer_group/{data_reviewer_group_id}/meal_photo_queue/{meal_photo_queue_id}/original_audio`
                .replace(`{${"data_reviewer_group_id"}}`, encodeURIComponent(String(data_reviewer_group_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a copy of the photo submitted for an MPQ
         * @param {number} data_reviewer_group_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetMealPhotoQueueOriginalPhoto: async (data_reviewer_group_id: number, meal_photo_queue_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_group_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetMealPhotoQueueOriginalPhoto', 'data_reviewer_group_id', data_reviewer_group_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetMealPhotoQueueOriginalPhoto', 'meal_photo_queue_id', meal_photo_queue_id)
            const localVarPath = `/data_reviewer_group/{data_reviewer_group_id}/meal_photo_queue/{meal_photo_queue_id}/original_photo`
                .replace(`{${"data_reviewer_group_id"}}`, encodeURIComponent(String(data_reviewer_group_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a copy of the resized photo submitted for an MPQ
         * @param {number} data_reviewer_group_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetMealPhotoQueuePhoto: async (data_reviewer_group_id: number, meal_photo_queue_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_group_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetMealPhotoQueuePhoto', 'data_reviewer_group_id', data_reviewer_group_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetMealPhotoQueuePhoto', 'meal_photo_queue_id', meal_photo_queue_id)
            const localVarPath = `/data_reviewer_group/{data_reviewer_group_id}/meal_photo_queue/{meal_photo_queue_id}/photo`
                .replace(`{${"data_reviewer_group_id"}}`, encodeURIComponent(String(data_reviewer_group_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a copy of the original photo submitted for an MPQ
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewer: async (data_reviewer_id: number, meal_photo_queue_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewer', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewer', 'meal_photo_queue_id', meal_photo_queue_id)
            const localVarPath = `/data_reviewer/{data_reviewer_id}/meal_photo_queue/{meal_photo_queue_id}/recent_items`
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a copy of the original photo submitted for an MPQ
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewerGroup: async (data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_group_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewerGroup', 'data_reviewer_group_id', data_reviewer_group_id)
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewerGroup', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewerGroup', 'meal_photo_queue_id', meal_photo_queue_id)
            const localVarPath = `/data_reviewer_group/{data_reviewer_group_id}/data_reviewer/{data_reviewer_id}/meal_photo_queue/{meal_photo_queue_id}/recent_items`
                .replace(`{${"data_reviewer_group_id"}}`, encodeURIComponent(String(data_reviewer_group_id)))
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all meals queued for review for a specific data_reviewer_group
         * @param {number} data_reviewer_group_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetMealPhotoQueueToBeProcessed: async (data_reviewer_group_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_group_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetMealPhotoQueueToBeProcessed', 'data_reviewer_group_id', data_reviewer_group_id)
            const localVarPath = `/data_reviewer_group/{data_reviewer_group_id}/meal_photo_queue/to_be_processed`
                .replace(`{${"data_reviewer_group_id"}}`, encodeURIComponent(String(data_reviewer_group_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a smart list of suggestions to use for labeling a meal
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetMealRecommendedMeals: async (data_reviewer_id: number, meal_photo_queue_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetMealRecommendedMeals', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetMealRecommendedMeals', 'meal_photo_queue_id', meal_photo_queue_id)
            const localVarPath = `/data_reviewer/{data_reviewer_id}/meal_photo_queue/{meal_photo_queue_id}/recommended_meals`
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of similar meals previously logged by the patient
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetMealSimilarMeals: async (data_reviewer_id: number, meal_photo_queue_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetMealSimilarMeals', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetMealSimilarMeals', 'meal_photo_queue_id', meal_photo_queue_id)
            const localVarPath = `/data_reviewer/{data_reviewer_id}/meal_photo_queue/{meal_photo_queue_id}/similar_meals`
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all data reviewer groups assigned to the patient
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetPatientDataReviewerGroups: async (patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiDataReviewerGetPatientDataReviewerGroups', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/data_reviewer_group`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the data reviewer details
         * @param {number} data_reviewer_id 
         * @param {UpdateDataReviewerRequest} UpdateDataReviewerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerPatchDataReviewer: async (data_reviewer_id: number, UpdateDataReviewerRequest: UpdateDataReviewerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiDataReviewerPatchDataReviewer', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'UpdateDataReviewerRequest' is not null or undefined
            assertParamExists('appApiDataReviewerPatchDataReviewer', 'UpdateDataReviewerRequest', UpdateDataReviewerRequest)
            const localVarPath = `/data_reviewer/{data_reviewer_id}`
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateDataReviewerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the data reviewer settings for the given group
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {boolean} is_active 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerPatchDataReviewerSettings: async (data_reviewer_group_id: number, data_reviewer_id: number, is_active: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_group_id' is not null or undefined
            assertParamExists('appApiDataReviewerPatchDataReviewerSettings', 'data_reviewer_group_id', data_reviewer_group_id)
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiDataReviewerPatchDataReviewerSettings', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'is_active' is not null or undefined
            assertParamExists('appApiDataReviewerPatchDataReviewerSettings', 'is_active', is_active)
            const localVarPath = `/data_reviewer_group/{data_reviewer_group_id}/data_reviewer/{data_reviewer_id}/settings`
                .replace(`{${"data_reviewer_group_id"}}`, encodeURIComponent(String(data_reviewer_group_id)))
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (is_active !== undefined) {
                localVarQueryParameter['is_active'] = is_active;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new data reviewer
         * @param {CreateDataReviewerRequest} CreateDataReviewerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerPostDataReviewer: async (CreateDataReviewerRequest: CreateDataReviewerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateDataReviewerRequest' is not null or undefined
            assertParamExists('appApiDataReviewerPostDataReviewer', 'CreateDataReviewerRequest', CreateDataReviewerRequest)
            const localVarPath = `/data_reviewer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateDataReviewerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all meals queued for a data reviewer
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerPostDataReviewerClaimQueue: async (data_reviewer_id: number, meal_photo_queue_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiDataReviewerPostDataReviewerClaimQueue', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiDataReviewerPostDataReviewerClaimQueue', 'meal_photo_queue_id', meal_photo_queue_id)
            const localVarPath = `/data_reviewer/{data_reviewer_id}/meal_photo_queue/claim/{meal_photo_queue_id}`
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post confidence score of labeled items
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {number} [confidence_sizing] 
         * @param {number} [confidence_identification] 
         * @param {CreateQueueFeedbackRequest} [CreateQueueFeedbackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerPostDataReviewerConfidence: async (data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, confidence_sizing?: number, confidence_identification?: number, CreateQueueFeedbackRequest?: CreateQueueFeedbackRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_group_id' is not null or undefined
            assertParamExists('appApiDataReviewerPostDataReviewerConfidence', 'data_reviewer_group_id', data_reviewer_group_id)
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiDataReviewerPostDataReviewerConfidence', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiDataReviewerPostDataReviewerConfidence', 'meal_photo_queue_id', meal_photo_queue_id)
            const localVarPath = `/data_reviewer_group/{data_reviewer_group_id}/data_reviewer/{data_reviewer_id}/meal_photo_queue/{meal_photo_queue_id}/confidence`
                .replace(`{${"data_reviewer_group_id"}}`, encodeURIComponent(String(data_reviewer_group_id)))
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (confidence_sizing !== undefined) {
                localVarQueryParameter['confidence_sizing'] = confidence_sizing;
            }

            if (confidence_identification !== undefined) {
                localVarQueryParameter['confidence_identification'] = confidence_identification;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateQueueFeedbackRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new data reviewer group
         * @param {CreateDataReviewerGroupRequest} CreateDataReviewerGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerPostDataReviewerGroup: async (CreateDataReviewerGroupRequest: CreateDataReviewerGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateDataReviewerGroupRequest' is not null or undefined
            assertParamExists('appApiDataReviewerPostDataReviewerGroup', 'CreateDataReviewerGroupRequest', CreateDataReviewerGroupRequest)
            const localVarPath = `/data_reviewer_groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateDataReviewerGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a test queue meal
         * @param {number} data_reviewer_id 
         * @param {number} patient_id 
         * @param {CreateMealRequest} CreateMealRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerPostMealPhotoQueueEmptyMeal: async (data_reviewer_id: number, patient_id: number, CreateMealRequest: CreateMealRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiDataReviewerPostMealPhotoQueueEmptyMeal', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiDataReviewerPostMealPhotoQueueEmptyMeal', 'patient_id', patient_id)
            // verify required parameter 'CreateMealRequest' is not null or undefined
            assertParamExists('appApiDataReviewerPostMealPhotoQueueEmptyMeal', 'CreateMealRequest', CreateMealRequest)
            const localVarPath = `/data_reviewer/{data_reviewer_id}/meal_photo_queue/empty_meal`
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (patient_id !== undefined) {
                localVarQueryParameter['patient_id'] = patient_id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateMealRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the data reviewer group
         * @param {number} data_reviewer_group_id 
         * @param {UpdateDataReviewerGroupRequest} UpdateDataReviewerGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerPutDataReviewerGroup: async (data_reviewer_group_id: number, UpdateDataReviewerGroupRequest: UpdateDataReviewerGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_group_id' is not null or undefined
            assertParamExists('appApiDataReviewerPutDataReviewerGroup', 'data_reviewer_group_id', data_reviewer_group_id)
            // verify required parameter 'UpdateDataReviewerGroupRequest' is not null or undefined
            assertParamExists('appApiDataReviewerPutDataReviewerGroup', 'UpdateDataReviewerGroupRequest', UpdateDataReviewerGroupRequest)
            const localVarPath = `/data_reviewer_group/{data_reviewer_group_id}`
                .replace(`{${"data_reviewer_group_id"}}`, encodeURIComponent(String(data_reviewer_group_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateDataReviewerGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the data reviewer group
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {RegisterDataReviewerToGroupRequest} RegisterDataReviewerToGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerRegisterDataReviewerToGroup: async (data_reviewer_group_id: number, data_reviewer_id: number, RegisterDataReviewerToGroupRequest: RegisterDataReviewerToGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_group_id' is not null or undefined
            assertParamExists('appApiDataReviewerRegisterDataReviewerToGroup', 'data_reviewer_group_id', data_reviewer_group_id)
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiDataReviewerRegisterDataReviewerToGroup', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'RegisterDataReviewerToGroupRequest' is not null or undefined
            assertParamExists('appApiDataReviewerRegisterDataReviewerToGroup', 'RegisterDataReviewerToGroupRequest', RegisterDataReviewerToGroupRequest)
            const localVarPath = `/data_reviewer_group/{data_reviewer_group_id}/register_data_reviewer`
                .replace(`{${"data_reviewer_group_id"}}`, encodeURIComponent(String(data_reviewer_group_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (data_reviewer_id !== undefined) {
                localVarQueryParameter['data_reviewer_id'] = data_reviewer_id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(RegisterDataReviewerToGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post correct meal items to a patient meal via the meal_photo_queue
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {Array<CreateMealItemFromQueueRequest>} CreateMealItemFromQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPostMealItemsFromDataReviewerGroupQueue: async (data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, CreateMealItemFromQueueRequest: Array<CreateMealItemFromQueueRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_group_id' is not null or undefined
            assertParamExists('appApiMealPostMealItemsFromDataReviewerGroupQueue', 'data_reviewer_group_id', data_reviewer_group_id)
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiMealPostMealItemsFromDataReviewerGroupQueue', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiMealPostMealItemsFromDataReviewerGroupQueue', 'meal_photo_queue_id', meal_photo_queue_id)
            // verify required parameter 'CreateMealItemFromQueueRequest' is not null or undefined
            assertParamExists('appApiMealPostMealItemsFromDataReviewerGroupQueue', 'CreateMealItemFromQueueRequest', CreateMealItemFromQueueRequest)
            const localVarPath = `/data_reviewer_group/{data_reviewer_group_id}/data_reviewer/{data_reviewer_id}/meal_photo_queue/{meal_photo_queue_id}/meal_items`
                .replace(`{${"data_reviewer_group_id"}}`, encodeURIComponent(String(data_reviewer_group_id)))
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateMealItemFromQueueRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post correct meal items to a patient meal via the meal_photo_queue
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {string} food_names 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPostMealItemsFromDataReviewerGroupQueueString: async (data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, food_names: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_group_id' is not null or undefined
            assertParamExists('appApiMealPostMealItemsFromDataReviewerGroupQueueString', 'data_reviewer_group_id', data_reviewer_group_id)
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiMealPostMealItemsFromDataReviewerGroupQueueString', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiMealPostMealItemsFromDataReviewerGroupQueueString', 'meal_photo_queue_id', meal_photo_queue_id)
            // verify required parameter 'food_names' is not null or undefined
            assertParamExists('appApiMealPostMealItemsFromDataReviewerGroupQueueString', 'food_names', food_names)
            const localVarPath = `/data_reviewer_group/{data_reviewer_group_id}/data_reviewer/{data_reviewer_id}/meal_photo_queue/{meal_photo_queue_id}/meal_items_from_string`
                .replace(`{${"data_reviewer_group_id"}}`, encodeURIComponent(String(data_reviewer_group_id)))
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_names !== undefined) {
                localVarQueryParameter['food_names'] = food_names;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post correct meal items to a patient meal via the meal_photo_queue
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {Array<CreateMealItemFromQueueRequest>} CreateMealItemFromQueueRequest 
         * @param {string} [note] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPostMealItemsFromDataReviewerQueue: async (data_reviewer_id: number, meal_photo_queue_id: number, CreateMealItemFromQueueRequest: Array<CreateMealItemFromQueueRequest>, note?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiMealPostMealItemsFromDataReviewerQueue', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiMealPostMealItemsFromDataReviewerQueue', 'meal_photo_queue_id', meal_photo_queue_id)
            // verify required parameter 'CreateMealItemFromQueueRequest' is not null or undefined
            assertParamExists('appApiMealPostMealItemsFromDataReviewerQueue', 'CreateMealItemFromQueueRequest', CreateMealItemFromQueueRequest)
            const localVarPath = `/data_reviewer/{data_reviewer_id}/meal_photo_queue/{meal_photo_queue_id}/meal_items`
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (note !== undefined) {
                localVarQueryParameter['note'] = note;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateMealItemFromQueueRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post correct meal items to a patient meal via the meal_photo_queue
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {string} food_names 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPostMealItemsFromDataReviewerQueueString: async (data_reviewer_id: number, meal_photo_queue_id: number, food_names: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiMealPostMealItemsFromDataReviewerQueueString', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiMealPostMealItemsFromDataReviewerQueueString', 'meal_photo_queue_id', meal_photo_queue_id)
            // verify required parameter 'food_names' is not null or undefined
            assertParamExists('appApiMealPostMealItemsFromDataReviewerQueueString', 'food_names', food_names)
            const localVarPath = `/data_reviewer/{data_reviewer_id}/meal_photo_queue/{meal_photo_queue_id}/meal_items_from_string`
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_names !== undefined) {
                localVarQueryParameter['food_names'] = food_names;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get patient context.
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiQueuePatientContextGetPatientContext: async (data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_group_id' is not null or undefined
            assertParamExists('appApiQueuePatientContextGetPatientContext', 'data_reviewer_group_id', data_reviewer_group_id)
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiQueuePatientContextGetPatientContext', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiQueuePatientContextGetPatientContext', 'meal_photo_queue_id', meal_photo_queue_id)
            const localVarPath = `/data_reviewer_group/{data_reviewer_group_id}/data_reviewer/{data_reviewer_id}/meal_photo_queue/{meal_photo_queue_id}/patient_context`
                .replace(`{${"data_reviewer_group_id"}}`, encodeURIComponent(String(data_reviewer_group_id)))
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all meals processed by a data reviewer
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {DataReviewerHelpRequest} DataReviewerHelpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiQueuePatientContextPostMpqHelp: async (data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, DataReviewerHelpRequest: DataReviewerHelpRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_group_id' is not null or undefined
            assertParamExists('appApiQueuePatientContextPostMpqHelp', 'data_reviewer_group_id', data_reviewer_group_id)
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiQueuePatientContextPostMpqHelp', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiQueuePatientContextPostMpqHelp', 'meal_photo_queue_id', meal_photo_queue_id)
            // verify required parameter 'DataReviewerHelpRequest' is not null or undefined
            assertParamExists('appApiQueuePatientContextPostMpqHelp', 'DataReviewerHelpRequest', DataReviewerHelpRequest)
            const localVarPath = `/data_reviewer_group/{data_reviewer_group_id}/data_reviewer/{data_reviewer_id}/meal_photo_queue/{meal_photo_queue_id}/help`
                .replace(`{${"data_reviewer_group_id"}}`, encodeURIComponent(String(data_reviewer_group_id)))
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(DataReviewerHelpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all meals queued for a data reviewer
         * @param {number} data_reviewer_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {number} [clinic_id] 
         * @param {number} [patient_id] 
         * @param {Array<number>} [meal_ids] 
         * @param {Array<number>} [meal_item_ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiQueueReviewGetMealPhotoQueueToBeReviewed: async (data_reviewer_id: number, date_since: string, date_until: string, clinic_id?: number, patient_id?: number, meal_ids?: Array<number>, meal_item_ids?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiQueueReviewGetMealPhotoQueueToBeReviewed', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiQueueReviewGetMealPhotoQueueToBeReviewed', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiQueueReviewGetMealPhotoQueueToBeReviewed', 'date_until', date_until)
            const localVarPath = `/data_reviewer/{data_reviewer_id}/meal_photo_queue/to_be_reviewed`
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }

            if (clinic_id !== undefined) {
                localVarQueryParameter['clinic_id'] = clinic_id;
            }

            if (patient_id !== undefined) {
                localVarQueryParameter['patient_id'] = patient_id;
            }

            if (meal_ids) {
                localVarQueryParameter['meal_ids'] = meal_ids;
            }

            if (meal_item_ids) {
                localVarQueryParameter['meal_item_ids'] = meal_item_ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all meals queued for a data reviewer given a list of queue ids
         * @param {number} data_reviewer_id 
         * @param {Array<number>} queue_ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiQueueReviewGetMealPhotoQueueToBeReviewedFromQueueIds: async (data_reviewer_id: number, queue_ids: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiQueueReviewGetMealPhotoQueueToBeReviewedFromQueueIds', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'queue_ids' is not null or undefined
            assertParamExists('appApiQueueReviewGetMealPhotoQueueToBeReviewedFromQueueIds', 'queue_ids', queue_ids)
            const localVarPath = `/data_reviewer/{data_reviewer_id}/meal_photo_queue/to_be_reviewed_from_queue_ids`
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (queue_ids) {
                localVarQueryParameter['queue_ids'] = queue_ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all QA logs available to the user
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiQueueReviewGetQualityAssuranceLogs: async (data_reviewer_id: number, meal_photo_queue_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiQueueReviewGetQualityAssuranceLogs', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiQueueReviewGetQualityAssuranceLogs', 'meal_photo_queue_id', meal_photo_queue_id)
            const localVarPath = `/data_reviewer/{data_reviewer_id}/meal_photo_queue/{meal_photo_queue_id}/quality_assurance_log`
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get MPQs for QA
         * @param {number} data_reviewer_id 
         * @param {number} [n_days] 
         * @param {string} [date_oldest] 
         * @param {string} [date_newest] 
         * @param {boolean} [is_reviewed] 
         * @param {boolean} [is_approved] 
         * @param {boolean} [is_escalated] 
         * @param {boolean} [is_auto_logged] 
         * @param {boolean} [is_initial] 
         * @param {boolean} [is_archived] 
         * @param {boolean} [is_priority] 
         * @param {boolean} [has_custom_items] 
         * @param {number} [patient_id] 
         * @param {number} [limit] 
         * @param {boolean} [is_from_recent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiQueueReviewGetQueuesForQa: async (data_reviewer_id: number, n_days?: number, date_oldest?: string, date_newest?: string, is_reviewed?: boolean, is_approved?: boolean, is_escalated?: boolean, is_auto_logged?: boolean, is_initial?: boolean, is_archived?: boolean, is_priority?: boolean, has_custom_items?: boolean, patient_id?: number, limit?: number, is_from_recent?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiQueueReviewGetQueuesForQa', 'data_reviewer_id', data_reviewer_id)
            const localVarPath = `/data_reviewer/{data_reviewer_id}/quality_assurance`
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (n_days !== undefined) {
                localVarQueryParameter['n_days'] = n_days;
            }

            if (date_oldest !== undefined) {
                localVarQueryParameter['date_oldest'] = date_oldest;
            }

            if (date_newest !== undefined) {
                localVarQueryParameter['date_newest'] = date_newest;
            }

            if (is_reviewed !== undefined) {
                localVarQueryParameter['is_reviewed'] = is_reviewed;
            }

            if (is_approved !== undefined) {
                localVarQueryParameter['is_approved'] = is_approved;
            }

            if (is_escalated !== undefined) {
                localVarQueryParameter['is_escalated'] = is_escalated;
            }

            if (is_auto_logged !== undefined) {
                localVarQueryParameter['is_auto_logged'] = is_auto_logged;
            }

            if (is_initial !== undefined) {
                localVarQueryParameter['is_initial'] = is_initial;
            }

            if (is_archived !== undefined) {
                localVarQueryParameter['is_archived'] = is_archived;
            }

            if (is_priority !== undefined) {
                localVarQueryParameter['is_priority'] = is_priority;
            }

            if (has_custom_items !== undefined) {
                localVarQueryParameter['has_custom_items'] = has_custom_items;
            }

            if (patient_id !== undefined) {
                localVarQueryParameter['patient_id'] = patient_id;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (is_from_recent !== undefined) {
                localVarQueryParameter['is_from_recent'] = is_from_recent;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post a QA comment
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {CreateQualityAssuranceLogRequest} CreateQualityAssuranceLogRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiQueueReviewPostQualityAssuranceLog: async (data_reviewer_id: number, meal_photo_queue_id: number, CreateQualityAssuranceLogRequest: CreateQualityAssuranceLogRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiQueueReviewPostQualityAssuranceLog', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiQueueReviewPostQualityAssuranceLog', 'meal_photo_queue_id', meal_photo_queue_id)
            // verify required parameter 'CreateQualityAssuranceLogRequest' is not null or undefined
            assertParamExists('appApiQueueReviewPostQualityAssuranceLog', 'CreateQualityAssuranceLogRequest', CreateQualityAssuranceLogRequest)
            const localVarPath = `/data_reviewer/{data_reviewer_id}/meal_photo_queue/{meal_photo_queue_id}/quality_assurance_log`
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateQualityAssuranceLogRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a meal log comment
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {number} qa_log_id 
         * @param {UpdateQualityAssuranceLogRequest} UpdateQualityAssuranceLogRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiQueueReviewPutQualityAssuranceLog: async (data_reviewer_id: number, meal_photo_queue_id: number, qa_log_id: number, UpdateQualityAssuranceLogRequest: UpdateQualityAssuranceLogRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiQueueReviewPutQualityAssuranceLog', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiQueueReviewPutQualityAssuranceLog', 'meal_photo_queue_id', meal_photo_queue_id)
            // verify required parameter 'qa_log_id' is not null or undefined
            assertParamExists('appApiQueueReviewPutQualityAssuranceLog', 'qa_log_id', qa_log_id)
            // verify required parameter 'UpdateQualityAssuranceLogRequest' is not null or undefined
            assertParamExists('appApiQueueReviewPutQualityAssuranceLog', 'UpdateQualityAssuranceLogRequest', UpdateQualityAssuranceLogRequest)
            const localVarPath = `/data_reviewer/{data_reviewer_id}/meal_photo_queue/{meal_photo_queue_id}/quality_assurance_log/{qa_log_id}`
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)))
                .replace(`{${"qa_log_id"}}`, encodeURIComponent(String(qa_log_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateQualityAssuranceLogRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataReviewApi - functional programming interface
 * @export
 */
export const DataReviewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataReviewApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete the data reviewer
         * @param {number} data_reviewer_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerDeleteDataReviewer(data_reviewer_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerDeleteDataReviewer(data_reviewer_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete the data reviewer group
         * @param {number} data_reviewer_group_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerDeleteDataReviewerGroup(data_reviewer_group_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerDeleteDataReviewerGroup(data_reviewer_group_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete meal items association to MPQ deemed to have no viable meal items. Meal is deleted if no other meal items exists.
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {'inappropriate' | 'no_food_in_photo' | 'duplicate' | 'testing'} reason 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerDeleteMealItemsFromQueue(data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, reason: 'inappropriate' | 'no_food_in_photo' | 'duplicate' | 'testing', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerDeleteMealItemsFromQueue(data_reviewer_group_id, data_reviewer_id, meal_photo_queue_id, reason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete the data reviewer from a group group
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerDeregisterDataReviewerFromGroup(data_reviewer_group_id: number, data_reviewer_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerDeregisterDataReviewerFromGroup(data_reviewer_group_id, data_reviewer_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Escalate a queue item to a higher review level
         * @param {number} meal_photo_queue_id 
         * @param {string} [reason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerEscalateMealPhotoQueueItem(meal_photo_queue_id: number, reason?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerEscalateMealPhotoQueueItem(meal_photo_queue_id, reason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get currently logged in data reviewer. This is for restricted use case, use for only DR information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetCurrentDataReviewer(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataReviewerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetCurrentDataReviewer(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get details of data reviewer
         * @param {number} data_reviewer_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetDataReviewer(data_reviewer_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataReviewerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetDataReviewer(data_reviewer_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of all data reviewer group members
         * @param {number} data_reviewer_group_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetDataReviewerGroup(data_reviewer_group_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataReviewerGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetDataReviewerGroup(data_reviewer_group_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of all meals queued for review for a specific data_reviewer_group
         * @param {number} data_reviewer_group_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetDataReviewerGroupMealPhotoQueuesProcessed(data_reviewer_group_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MealPhotoQueueResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetDataReviewerGroupMealPhotoQueuesProcessed(data_reviewer_group_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of all data reviewer groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetDataReviewerGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataReviewerGroupResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetDataReviewerGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a copy of the original photo submitted for an MPQ
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetDataReviewerMealPhotoQueueOriginalPhoto(data_reviewer_id: number, meal_photo_queue_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetDataReviewerMealPhotoQueueOriginalPhoto(data_reviewer_id, meal_photo_queue_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a copy of the resized photo submitted for an MPQ
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetDataReviewerMealPhotoQueuePhoto(data_reviewer_id: number, meal_photo_queue_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetDataReviewerMealPhotoQueuePhoto(data_reviewer_id, meal_photo_queue_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of all meals processed by a data reviewer
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetDataReviewerMealPhotoQueueProcessed(data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealPhotoQueueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetDataReviewerMealPhotoQueueProcessed(data_reviewer_group_id, data_reviewer_id, meal_photo_queue_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of all meals queued for review for a specific data_reviewer
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetDataReviewerMealPhotoQueuesProcessed(data_reviewer_group_id: number, data_reviewer_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MealPhotoQueueResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetDataReviewerMealPhotoQueuesProcessed(data_reviewer_group_id, data_reviewer_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of all meals queued for a data reviewer
         * @param {number} data_reviewer_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetDataReviewerMealPhotoQueuesToBeProcessed(data_reviewer_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MealPhotoQueueResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetDataReviewerMealPhotoQueuesToBeProcessed(data_reviewer_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of all meals queued for a data reviewer
         * @param {number} data_reviewer_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetDataReviewerNextQueue(data_reviewer_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealPhotoQueueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetDataReviewerNextQueue(data_reviewer_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get data reviewer settings for the given group
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetDataReviewerSettings(data_reviewer_group_id: number, data_reviewer_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataReviewerGroupSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetDataReviewerSettings(data_reviewer_group_id, data_reviewer_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of all data reviewers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetDataReviewers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataReviewerResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetDataReviewers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the food data, for a string of foods, from the server in the MPQ format
         * @param {string} food_names list of food names
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetFormattedFoodStringWithUnits(food_names: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetFormattedFoodStringWithUnits(food_names, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the food data, for a string of foods, from the server in the MPQ format
         * @param {string} food_names list of food names
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetFormattedMpqFoods(food_names: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreateMealItemFromQueueRequest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetFormattedMpqFoods(food_names, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve previous meal item details for the patient for the current MPQ id
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {number} num_days 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetMealItemsHistory(data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, num_days: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientHistoryQueueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetMealItemsHistory(data_reviewer_group_id, data_reviewer_id, meal_photo_queue_id, num_days, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Look up a meal photo queue ID from a meal ID
         * @param {number} meal_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetMealPhotoQueueId(meal_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppApiDataReviewerGetMealPhotoQueueId200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetMealPhotoQueueId(meal_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an individual meal photo queue item
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetMealPhotoQueueItem(meal_photo_queue_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealPhotoQueueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetMealPhotoQueueItem(meal_photo_queue_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the QOS details for a meal photo queue item, which includes all the queues which \"overlap\" with the meal photo queue item.
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetMealPhotoQueueItemQosDetails(meal_photo_queue_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealPhotoQueueQoSDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetMealPhotoQueueItemQosDetails(meal_photo_queue_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a copy of the audio submitted for an MPQ
         * @param {number} data_reviewer_group_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetMealPhotoQueueOriginalAudio(data_reviewer_group_id: number, meal_photo_queue_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetMealPhotoQueueOriginalAudio(data_reviewer_group_id, meal_photo_queue_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a copy of the photo submitted for an MPQ
         * @param {number} data_reviewer_group_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetMealPhotoQueueOriginalPhoto(data_reviewer_group_id: number, meal_photo_queue_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetMealPhotoQueueOriginalPhoto(data_reviewer_group_id, meal_photo_queue_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a copy of the resized photo submitted for an MPQ
         * @param {number} data_reviewer_group_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetMealPhotoQueuePhoto(data_reviewer_group_id: number, meal_photo_queue_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetMealPhotoQueuePhoto(data_reviewer_group_id, meal_photo_queue_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a copy of the original photo submitted for an MPQ
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewer(data_reviewer_id: number, meal_photo_queue_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecentMealItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewer(data_reviewer_id, meal_photo_queue_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a copy of the original photo submitted for an MPQ
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewerGroup(data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecentMealItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewerGroup(data_reviewer_group_id, data_reviewer_id, meal_photo_queue_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of all meals queued for review for a specific data_reviewer_group
         * @param {number} data_reviewer_group_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetMealPhotoQueueToBeProcessed(data_reviewer_group_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MealPhotoQueueResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetMealPhotoQueueToBeProcessed(data_reviewer_group_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a smart list of suggestions to use for labeling a meal
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetMealRecommendedMeals(data_reviewer_id: number, meal_photo_queue_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecommendedMeal>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetMealRecommendedMeals(data_reviewer_id, meal_photo_queue_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of similar meals previously logged by the patient
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetMealSimilarMeals(data_reviewer_id: number, meal_photo_queue_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientHistoryQueueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetMealSimilarMeals(data_reviewer_id, meal_photo_queue_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of all data reviewer groups assigned to the patient
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerGetPatientDataReviewerGroups(patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataReviewerGroupResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerGetPatientDataReviewerGroups(patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the data reviewer details
         * @param {number} data_reviewer_id 
         * @param {UpdateDataReviewerRequest} UpdateDataReviewerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerPatchDataReviewer(data_reviewer_id: number, UpdateDataReviewerRequest: UpdateDataReviewerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataReviewerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerPatchDataReviewer(data_reviewer_id, UpdateDataReviewerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the data reviewer settings for the given group
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {boolean} is_active 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerPatchDataReviewerSettings(data_reviewer_group_id: number, data_reviewer_id: number, is_active: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataReviewerGroupSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerPatchDataReviewerSettings(data_reviewer_group_id, data_reviewer_id, is_active, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new data reviewer
         * @param {CreateDataReviewerRequest} CreateDataReviewerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerPostDataReviewer(CreateDataReviewerRequest: CreateDataReviewerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataReviewerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerPostDataReviewer(CreateDataReviewerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of all meals queued for a data reviewer
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerPostDataReviewerClaimQueue(data_reviewer_id: number, meal_photo_queue_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataReviewerClaimQueueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerPostDataReviewerClaimQueue(data_reviewer_id, meal_photo_queue_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post confidence score of labeled items
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {number} [confidence_sizing] 
         * @param {number} [confidence_identification] 
         * @param {CreateQueueFeedbackRequest} [CreateQueueFeedbackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerPostDataReviewerConfidence(data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, confidence_sizing?: number, confidence_identification?: number, CreateQueueFeedbackRequest?: CreateQueueFeedbackRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerPostDataReviewerConfidence(data_reviewer_group_id, data_reviewer_id, meal_photo_queue_id, confidence_sizing, confidence_identification, CreateQueueFeedbackRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new data reviewer group
         * @param {CreateDataReviewerGroupRequest} CreateDataReviewerGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerPostDataReviewerGroup(CreateDataReviewerGroupRequest: CreateDataReviewerGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataReviewerGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerPostDataReviewerGroup(CreateDataReviewerGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a test queue meal
         * @param {number} data_reviewer_id 
         * @param {number} patient_id 
         * @param {CreateMealRequest} CreateMealRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerPostMealPhotoQueueEmptyMeal(data_reviewer_id: number, patient_id: number, CreateMealRequest: CreateMealRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealPhotoQueueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerPostMealPhotoQueueEmptyMeal(data_reviewer_id, patient_id, CreateMealRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the data reviewer group
         * @param {number} data_reviewer_group_id 
         * @param {UpdateDataReviewerGroupRequest} UpdateDataReviewerGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerPutDataReviewerGroup(data_reviewer_group_id: number, UpdateDataReviewerGroupRequest: UpdateDataReviewerGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataReviewerGroupResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerPutDataReviewerGroup(data_reviewer_group_id, UpdateDataReviewerGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the data reviewer group
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {RegisterDataReviewerToGroupRequest} RegisterDataReviewerToGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerRegisterDataReviewerToGroup(data_reviewer_group_id: number, data_reviewer_id: number, RegisterDataReviewerToGroupRequest: RegisterDataReviewerToGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataReviewerGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerRegisterDataReviewerToGroup(data_reviewer_group_id, data_reviewer_id, RegisterDataReviewerToGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post correct meal items to a patient meal via the meal_photo_queue
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {Array<CreateMealItemFromQueueRequest>} CreateMealItemFromQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealPostMealItemsFromDataReviewerGroupQueue(data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, CreateMealItemFromQueueRequest: Array<CreateMealItemFromQueueRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealPhotoQueueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealPostMealItemsFromDataReviewerGroupQueue(data_reviewer_group_id, data_reviewer_id, meal_photo_queue_id, CreateMealItemFromQueueRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post correct meal items to a patient meal via the meal_photo_queue
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {string} food_names 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealPostMealItemsFromDataReviewerGroupQueueString(data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, food_names: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MealItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealPostMealItemsFromDataReviewerGroupQueueString(data_reviewer_group_id, data_reviewer_id, meal_photo_queue_id, food_names, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post correct meal items to a patient meal via the meal_photo_queue
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {Array<CreateMealItemFromQueueRequest>} CreateMealItemFromQueueRequest 
         * @param {string} [note] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealPostMealItemsFromDataReviewerQueue(data_reviewer_id: number, meal_photo_queue_id: number, CreateMealItemFromQueueRequest: Array<CreateMealItemFromQueueRequest>, note?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MealItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealPostMealItemsFromDataReviewerQueue(data_reviewer_id, meal_photo_queue_id, CreateMealItemFromQueueRequest, note, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post correct meal items to a patient meal via the meal_photo_queue
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {string} food_names 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealPostMealItemsFromDataReviewerQueueString(data_reviewer_id: number, meal_photo_queue_id: number, food_names: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealPhotoQueueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealPostMealItemsFromDataReviewerQueueString(data_reviewer_id, meal_photo_queue_id, food_names, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get patient context.
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiQueuePatientContextGetPatientContext(data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientContextQueueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiQueuePatientContextGetPatientContext(data_reviewer_group_id, data_reviewer_id, meal_photo_queue_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of all meals processed by a data reviewer
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {DataReviewerHelpRequest} DataReviewerHelpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiQueuePatientContextPostMpqHelp(data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, DataReviewerHelpRequest: DataReviewerHelpRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiQueuePatientContextPostMpqHelp(data_reviewer_group_id, data_reviewer_id, meal_photo_queue_id, DataReviewerHelpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of all meals queued for a data reviewer
         * @param {number} data_reviewer_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {number} [clinic_id] 
         * @param {number} [patient_id] 
         * @param {Array<number>} [meal_ids] 
         * @param {Array<number>} [meal_item_ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiQueueReviewGetMealPhotoQueueToBeReviewed(data_reviewer_id: number, date_since: string, date_until: string, clinic_id?: number, patient_id?: number, meal_ids?: Array<number>, meal_item_ids?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MealPhotoQueueResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiQueueReviewGetMealPhotoQueueToBeReviewed(data_reviewer_id, date_since, date_until, clinic_id, patient_id, meal_ids, meal_item_ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of all meals queued for a data reviewer given a list of queue ids
         * @param {number} data_reviewer_id 
         * @param {Array<number>} queue_ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiQueueReviewGetMealPhotoQueueToBeReviewedFromQueueIds(data_reviewer_id: number, queue_ids: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MealPhotoQueueResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiQueueReviewGetMealPhotoQueueToBeReviewedFromQueueIds(data_reviewer_id, queue_ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all QA logs available to the user
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiQueueReviewGetQualityAssuranceLogs(data_reviewer_id: number, meal_photo_queue_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QualityAssuranceLogResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiQueueReviewGetQualityAssuranceLogs(data_reviewer_id, meal_photo_queue_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get MPQs for QA
         * @param {number} data_reviewer_id 
         * @param {number} [n_days] 
         * @param {string} [date_oldest] 
         * @param {string} [date_newest] 
         * @param {boolean} [is_reviewed] 
         * @param {boolean} [is_approved] 
         * @param {boolean} [is_escalated] 
         * @param {boolean} [is_auto_logged] 
         * @param {boolean} [is_initial] 
         * @param {boolean} [is_archived] 
         * @param {boolean} [is_priority] 
         * @param {boolean} [has_custom_items] 
         * @param {number} [patient_id] 
         * @param {number} [limit] 
         * @param {boolean} [is_from_recent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiQueueReviewGetQueuesForQa(data_reviewer_id: number, n_days?: number, date_oldest?: string, date_newest?: string, is_reviewed?: boolean, is_approved?: boolean, is_escalated?: boolean, is_auto_logged?: boolean, is_initial?: boolean, is_archived?: boolean, is_priority?: boolean, has_custom_items?: boolean, patient_id?: number, limit?: number, is_from_recent?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MealPhotoQueueQualityAssuranceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiQueueReviewGetQueuesForQa(data_reviewer_id, n_days, date_oldest, date_newest, is_reviewed, is_approved, is_escalated, is_auto_logged, is_initial, is_archived, is_priority, has_custom_items, patient_id, limit, is_from_recent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post a QA comment
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {CreateQualityAssuranceLogRequest} CreateQualityAssuranceLogRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiQueueReviewPostQualityAssuranceLog(data_reviewer_id: number, meal_photo_queue_id: number, CreateQualityAssuranceLogRequest: CreateQualityAssuranceLogRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualityAssuranceLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiQueueReviewPostQualityAssuranceLog(data_reviewer_id, meal_photo_queue_id, CreateQualityAssuranceLogRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a meal log comment
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {number} qa_log_id 
         * @param {UpdateQualityAssuranceLogRequest} UpdateQualityAssuranceLogRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiQueueReviewPutQualityAssuranceLog(data_reviewer_id: number, meal_photo_queue_id: number, qa_log_id: number, UpdateQualityAssuranceLogRequest: UpdateQualityAssuranceLogRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualityAssuranceLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiQueueReviewPutQualityAssuranceLog(data_reviewer_id, meal_photo_queue_id, qa_log_id, UpdateQualityAssuranceLogRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataReviewApi - factory interface
 * @export
 */
export const DataReviewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataReviewApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete the data reviewer
         * @param {number} data_reviewer_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerDeleteDataReviewer(data_reviewer_id: number, options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataReviewerDeleteDataReviewer(data_reviewer_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the data reviewer group
         * @param {number} data_reviewer_group_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerDeleteDataReviewerGroup(data_reviewer_group_id: number, options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataReviewerDeleteDataReviewerGroup(data_reviewer_group_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete meal items association to MPQ deemed to have no viable meal items. Meal is deleted if no other meal items exists.
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {'inappropriate' | 'no_food_in_photo' | 'duplicate' | 'testing'} reason 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerDeleteMealItemsFromQueue(data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, reason: 'inappropriate' | 'no_food_in_photo' | 'duplicate' | 'testing', options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataReviewerDeleteMealItemsFromQueue(data_reviewer_group_id, data_reviewer_id, meal_photo_queue_id, reason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the data reviewer from a group group
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerDeregisterDataReviewerFromGroup(data_reviewer_group_id: number, data_reviewer_id: number, options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataReviewerDeregisterDataReviewerFromGroup(data_reviewer_group_id, data_reviewer_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Escalate a queue item to a higher review level
         * @param {number} meal_photo_queue_id 
         * @param {string} [reason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerEscalateMealPhotoQueueItem(meal_photo_queue_id: number, reason?: string, options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataReviewerEscalateMealPhotoQueueItem(meal_photo_queue_id, reason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get currently logged in data reviewer. This is for restricted use case, use for only DR information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetCurrentDataReviewer(options?: any): AxiosPromise<DataReviewerResponse> {
            return localVarFp.appApiDataReviewerGetCurrentDataReviewer(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get details of data reviewer
         * @param {number} data_reviewer_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetDataReviewer(data_reviewer_id: number, options?: any): AxiosPromise<DataReviewerResponse> {
            return localVarFp.appApiDataReviewerGetDataReviewer(data_reviewer_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of all data reviewer group members
         * @param {number} data_reviewer_group_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetDataReviewerGroup(data_reviewer_group_id: number, options?: any): AxiosPromise<DataReviewerGroupResponse> {
            return localVarFp.appApiDataReviewerGetDataReviewerGroup(data_reviewer_group_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of all meals queued for review for a specific data_reviewer_group
         * @param {number} data_reviewer_group_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetDataReviewerGroupMealPhotoQueuesProcessed(data_reviewer_group_id: number, options?: any): AxiosPromise<Array<MealPhotoQueueResponse>> {
            return localVarFp.appApiDataReviewerGetDataReviewerGroupMealPhotoQueuesProcessed(data_reviewer_group_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of all data reviewer groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetDataReviewerGroups(options?: any): AxiosPromise<Array<DataReviewerGroupResponse>> {
            return localVarFp.appApiDataReviewerGetDataReviewerGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a copy of the original photo submitted for an MPQ
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetDataReviewerMealPhotoQueueOriginalPhoto(data_reviewer_id: number, meal_photo_queue_id: number, options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataReviewerGetDataReviewerMealPhotoQueueOriginalPhoto(data_reviewer_id, meal_photo_queue_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a copy of the resized photo submitted for an MPQ
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetDataReviewerMealPhotoQueuePhoto(data_reviewer_id: number, meal_photo_queue_id: number, options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataReviewerGetDataReviewerMealPhotoQueuePhoto(data_reviewer_id, meal_photo_queue_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of all meals processed by a data reviewer
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetDataReviewerMealPhotoQueueProcessed(data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, options?: any): AxiosPromise<MealPhotoQueueResponse> {
            return localVarFp.appApiDataReviewerGetDataReviewerMealPhotoQueueProcessed(data_reviewer_group_id, data_reviewer_id, meal_photo_queue_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of all meals queued for review for a specific data_reviewer
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetDataReviewerMealPhotoQueuesProcessed(data_reviewer_group_id: number, data_reviewer_id: number, options?: any): AxiosPromise<Array<MealPhotoQueueResponse>> {
            return localVarFp.appApiDataReviewerGetDataReviewerMealPhotoQueuesProcessed(data_reviewer_group_id, data_reviewer_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of all meals queued for a data reviewer
         * @param {number} data_reviewer_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetDataReviewerMealPhotoQueuesToBeProcessed(data_reviewer_id: number, options?: any): AxiosPromise<Array<MealPhotoQueueResponse>> {
            return localVarFp.appApiDataReviewerGetDataReviewerMealPhotoQueuesToBeProcessed(data_reviewer_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of all meals queued for a data reviewer
         * @param {number} data_reviewer_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetDataReviewerNextQueue(data_reviewer_id: number, options?: any): AxiosPromise<MealPhotoQueueResponse> {
            return localVarFp.appApiDataReviewerGetDataReviewerNextQueue(data_reviewer_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get data reviewer settings for the given group
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetDataReviewerSettings(data_reviewer_group_id: number, data_reviewer_id: number, options?: any): AxiosPromise<DataReviewerGroupSettingsResponse> {
            return localVarFp.appApiDataReviewerGetDataReviewerSettings(data_reviewer_group_id, data_reviewer_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of all data reviewers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetDataReviewers(options?: any): AxiosPromise<Array<DataReviewerResponse>> {
            return localVarFp.appApiDataReviewerGetDataReviewers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the food data, for a string of foods, from the server in the MPQ format
         * @param {string} food_names list of food names
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetFormattedFoodStringWithUnits(food_names: string, options?: any): AxiosPromise<string> {
            return localVarFp.appApiDataReviewerGetFormattedFoodStringWithUnits(food_names, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the food data, for a string of foods, from the server in the MPQ format
         * @param {string} food_names list of food names
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetFormattedMpqFoods(food_names: string, options?: any): AxiosPromise<Array<CreateMealItemFromQueueRequest>> {
            return localVarFp.appApiDataReviewerGetFormattedMpqFoods(food_names, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve previous meal item details for the patient for the current MPQ id
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {number} num_days 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetMealItemsHistory(data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, num_days: number, options?: any): AxiosPromise<PatientHistoryQueueResponse> {
            return localVarFp.appApiDataReviewerGetMealItemsHistory(data_reviewer_group_id, data_reviewer_id, meal_photo_queue_id, num_days, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Look up a meal photo queue ID from a meal ID
         * @param {number} meal_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetMealPhotoQueueId(meal_id: number, options?: any): AxiosPromise<AppApiDataReviewerGetMealPhotoQueueId200Response> {
            return localVarFp.appApiDataReviewerGetMealPhotoQueueId(meal_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an individual meal photo queue item
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetMealPhotoQueueItem(meal_photo_queue_id: number, options?: any): AxiosPromise<MealPhotoQueueResponse> {
            return localVarFp.appApiDataReviewerGetMealPhotoQueueItem(meal_photo_queue_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the QOS details for a meal photo queue item, which includes all the queues which \"overlap\" with the meal photo queue item.
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetMealPhotoQueueItemQosDetails(meal_photo_queue_id: number, options?: any): AxiosPromise<MealPhotoQueueQoSDetailsResponse> {
            return localVarFp.appApiDataReviewerGetMealPhotoQueueItemQosDetails(meal_photo_queue_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a copy of the audio submitted for an MPQ
         * @param {number} data_reviewer_group_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetMealPhotoQueueOriginalAudio(data_reviewer_group_id: number, meal_photo_queue_id: number, options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataReviewerGetMealPhotoQueueOriginalAudio(data_reviewer_group_id, meal_photo_queue_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a copy of the photo submitted for an MPQ
         * @param {number} data_reviewer_group_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetMealPhotoQueueOriginalPhoto(data_reviewer_group_id: number, meal_photo_queue_id: number, options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataReviewerGetMealPhotoQueueOriginalPhoto(data_reviewer_group_id, meal_photo_queue_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a copy of the resized photo submitted for an MPQ
         * @param {number} data_reviewer_group_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetMealPhotoQueuePhoto(data_reviewer_group_id: number, meal_photo_queue_id: number, options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataReviewerGetMealPhotoQueuePhoto(data_reviewer_group_id, meal_photo_queue_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a copy of the original photo submitted for an MPQ
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewer(data_reviewer_id: number, meal_photo_queue_id: number, options?: any): AxiosPromise<RecentMealItemResponse> {
            return localVarFp.appApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewer(data_reviewer_id, meal_photo_queue_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a copy of the original photo submitted for an MPQ
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewerGroup(data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, options?: any): AxiosPromise<RecentMealItemResponse> {
            return localVarFp.appApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewerGroup(data_reviewer_group_id, data_reviewer_id, meal_photo_queue_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of all meals queued for review for a specific data_reviewer_group
         * @param {number} data_reviewer_group_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetMealPhotoQueueToBeProcessed(data_reviewer_group_id: number, options?: any): AxiosPromise<Array<MealPhotoQueueResponse>> {
            return localVarFp.appApiDataReviewerGetMealPhotoQueueToBeProcessed(data_reviewer_group_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a smart list of suggestions to use for labeling a meal
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetMealRecommendedMeals(data_reviewer_id: number, meal_photo_queue_id: number, options?: any): AxiosPromise<Array<RecommendedMeal>> {
            return localVarFp.appApiDataReviewerGetMealRecommendedMeals(data_reviewer_id, meal_photo_queue_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of similar meals previously logged by the patient
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetMealSimilarMeals(data_reviewer_id: number, meal_photo_queue_id: number, options?: any): AxiosPromise<PatientHistoryQueueResponse> {
            return localVarFp.appApiDataReviewerGetMealSimilarMeals(data_reviewer_id, meal_photo_queue_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of all data reviewer groups assigned to the patient
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerGetPatientDataReviewerGroups(patient_id: number, options?: any): AxiosPromise<Array<DataReviewerGroupResponse>> {
            return localVarFp.appApiDataReviewerGetPatientDataReviewerGroups(patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the data reviewer details
         * @param {number} data_reviewer_id 
         * @param {UpdateDataReviewerRequest} UpdateDataReviewerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerPatchDataReviewer(data_reviewer_id: number, UpdateDataReviewerRequest: UpdateDataReviewerRequest, options?: any): AxiosPromise<DataReviewerResponse> {
            return localVarFp.appApiDataReviewerPatchDataReviewer(data_reviewer_id, UpdateDataReviewerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the data reviewer settings for the given group
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {boolean} is_active 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerPatchDataReviewerSettings(data_reviewer_group_id: number, data_reviewer_id: number, is_active: boolean, options?: any): AxiosPromise<DataReviewerGroupSettingsResponse> {
            return localVarFp.appApiDataReviewerPatchDataReviewerSettings(data_reviewer_group_id, data_reviewer_id, is_active, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new data reviewer
         * @param {CreateDataReviewerRequest} CreateDataReviewerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerPostDataReviewer(CreateDataReviewerRequest: CreateDataReviewerRequest, options?: any): AxiosPromise<DataReviewerResponse> {
            return localVarFp.appApiDataReviewerPostDataReviewer(CreateDataReviewerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of all meals queued for a data reviewer
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerPostDataReviewerClaimQueue(data_reviewer_id: number, meal_photo_queue_id: number, options?: any): AxiosPromise<DataReviewerClaimQueueResponse> {
            return localVarFp.appApiDataReviewerPostDataReviewerClaimQueue(data_reviewer_id, meal_photo_queue_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post confidence score of labeled items
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {number} [confidence_sizing] 
         * @param {number} [confidence_identification] 
         * @param {CreateQueueFeedbackRequest} [CreateQueueFeedbackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerPostDataReviewerConfidence(data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, confidence_sizing?: number, confidence_identification?: number, CreateQueueFeedbackRequest?: CreateQueueFeedbackRequest, options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataReviewerPostDataReviewerConfidence(data_reviewer_group_id, data_reviewer_id, meal_photo_queue_id, confidence_sizing, confidence_identification, CreateQueueFeedbackRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new data reviewer group
         * @param {CreateDataReviewerGroupRequest} CreateDataReviewerGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerPostDataReviewerGroup(CreateDataReviewerGroupRequest: CreateDataReviewerGroupRequest, options?: any): AxiosPromise<DataReviewerGroupResponse> {
            return localVarFp.appApiDataReviewerPostDataReviewerGroup(CreateDataReviewerGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a test queue meal
         * @param {number} data_reviewer_id 
         * @param {number} patient_id 
         * @param {CreateMealRequest} CreateMealRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerPostMealPhotoQueueEmptyMeal(data_reviewer_id: number, patient_id: number, CreateMealRequest: CreateMealRequest, options?: any): AxiosPromise<MealPhotoQueueResponse> {
            return localVarFp.appApiDataReviewerPostMealPhotoQueueEmptyMeal(data_reviewer_id, patient_id, CreateMealRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the data reviewer group
         * @param {number} data_reviewer_group_id 
         * @param {UpdateDataReviewerGroupRequest} UpdateDataReviewerGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerPutDataReviewerGroup(data_reviewer_group_id: number, UpdateDataReviewerGroupRequest: UpdateDataReviewerGroupRequest, options?: any): AxiosPromise<Array<DataReviewerGroupResponse>> {
            return localVarFp.appApiDataReviewerPutDataReviewerGroup(data_reviewer_group_id, UpdateDataReviewerGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the data reviewer group
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {RegisterDataReviewerToGroupRequest} RegisterDataReviewerToGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerRegisterDataReviewerToGroup(data_reviewer_group_id: number, data_reviewer_id: number, RegisterDataReviewerToGroupRequest: RegisterDataReviewerToGroupRequest, options?: any): AxiosPromise<DataReviewerGroupResponse> {
            return localVarFp.appApiDataReviewerRegisterDataReviewerToGroup(data_reviewer_group_id, data_reviewer_id, RegisterDataReviewerToGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post correct meal items to a patient meal via the meal_photo_queue
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {Array<CreateMealItemFromQueueRequest>} CreateMealItemFromQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPostMealItemsFromDataReviewerGroupQueue(data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, CreateMealItemFromQueueRequest: Array<CreateMealItemFromQueueRequest>, options?: any): AxiosPromise<MealPhotoQueueResponse> {
            return localVarFp.appApiMealPostMealItemsFromDataReviewerGroupQueue(data_reviewer_group_id, data_reviewer_id, meal_photo_queue_id, CreateMealItemFromQueueRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post correct meal items to a patient meal via the meal_photo_queue
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {string} food_names 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPostMealItemsFromDataReviewerGroupQueueString(data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, food_names: string, options?: any): AxiosPromise<Array<MealItemResponse>> {
            return localVarFp.appApiMealPostMealItemsFromDataReviewerGroupQueueString(data_reviewer_group_id, data_reviewer_id, meal_photo_queue_id, food_names, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post correct meal items to a patient meal via the meal_photo_queue
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {Array<CreateMealItemFromQueueRequest>} CreateMealItemFromQueueRequest 
         * @param {string} [note] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPostMealItemsFromDataReviewerQueue(data_reviewer_id: number, meal_photo_queue_id: number, CreateMealItemFromQueueRequest: Array<CreateMealItemFromQueueRequest>, note?: string, options?: any): AxiosPromise<Array<MealItemResponse>> {
            return localVarFp.appApiMealPostMealItemsFromDataReviewerQueue(data_reviewer_id, meal_photo_queue_id, CreateMealItemFromQueueRequest, note, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post correct meal items to a patient meal via the meal_photo_queue
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {string} food_names 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPostMealItemsFromDataReviewerQueueString(data_reviewer_id: number, meal_photo_queue_id: number, food_names: string, options?: any): AxiosPromise<MealPhotoQueueResponse> {
            return localVarFp.appApiMealPostMealItemsFromDataReviewerQueueString(data_reviewer_id, meal_photo_queue_id, food_names, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get patient context.
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiQueuePatientContextGetPatientContext(data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, options?: any): AxiosPromise<PatientContextQueueResponse> {
            return localVarFp.appApiQueuePatientContextGetPatientContext(data_reviewer_group_id, data_reviewer_id, meal_photo_queue_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of all meals processed by a data reviewer
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {DataReviewerHelpRequest} DataReviewerHelpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiQueuePatientContextPostMpqHelp(data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, DataReviewerHelpRequest: DataReviewerHelpRequest, options?: any): AxiosPromise<void> {
            return localVarFp.appApiQueuePatientContextPostMpqHelp(data_reviewer_group_id, data_reviewer_id, meal_photo_queue_id, DataReviewerHelpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of all meals queued for a data reviewer
         * @param {number} data_reviewer_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {number} [clinic_id] 
         * @param {number} [patient_id] 
         * @param {Array<number>} [meal_ids] 
         * @param {Array<number>} [meal_item_ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiQueueReviewGetMealPhotoQueueToBeReviewed(data_reviewer_id: number, date_since: string, date_until: string, clinic_id?: number, patient_id?: number, meal_ids?: Array<number>, meal_item_ids?: Array<number>, options?: any): AxiosPromise<Array<MealPhotoQueueResponse>> {
            return localVarFp.appApiQueueReviewGetMealPhotoQueueToBeReviewed(data_reviewer_id, date_since, date_until, clinic_id, patient_id, meal_ids, meal_item_ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of all meals queued for a data reviewer given a list of queue ids
         * @param {number} data_reviewer_id 
         * @param {Array<number>} queue_ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiQueueReviewGetMealPhotoQueueToBeReviewedFromQueueIds(data_reviewer_id: number, queue_ids: Array<number>, options?: any): AxiosPromise<Array<MealPhotoQueueResponse>> {
            return localVarFp.appApiQueueReviewGetMealPhotoQueueToBeReviewedFromQueueIds(data_reviewer_id, queue_ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all QA logs available to the user
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiQueueReviewGetQualityAssuranceLogs(data_reviewer_id: number, meal_photo_queue_id: number, options?: any): AxiosPromise<Array<QualityAssuranceLogResponse>> {
            return localVarFp.appApiQueueReviewGetQualityAssuranceLogs(data_reviewer_id, meal_photo_queue_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get MPQs for QA
         * @param {number} data_reviewer_id 
         * @param {number} [n_days] 
         * @param {string} [date_oldest] 
         * @param {string} [date_newest] 
         * @param {boolean} [is_reviewed] 
         * @param {boolean} [is_approved] 
         * @param {boolean} [is_escalated] 
         * @param {boolean} [is_auto_logged] 
         * @param {boolean} [is_initial] 
         * @param {boolean} [is_archived] 
         * @param {boolean} [is_priority] 
         * @param {boolean} [has_custom_items] 
         * @param {number} [patient_id] 
         * @param {number} [limit] 
         * @param {boolean} [is_from_recent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiQueueReviewGetQueuesForQa(data_reviewer_id: number, n_days?: number, date_oldest?: string, date_newest?: string, is_reviewed?: boolean, is_approved?: boolean, is_escalated?: boolean, is_auto_logged?: boolean, is_initial?: boolean, is_archived?: boolean, is_priority?: boolean, has_custom_items?: boolean, patient_id?: number, limit?: number, is_from_recent?: boolean, options?: any): AxiosPromise<Array<MealPhotoQueueQualityAssuranceResponse>> {
            return localVarFp.appApiQueueReviewGetQueuesForQa(data_reviewer_id, n_days, date_oldest, date_newest, is_reviewed, is_approved, is_escalated, is_auto_logged, is_initial, is_archived, is_priority, has_custom_items, patient_id, limit, is_from_recent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post a QA comment
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {CreateQualityAssuranceLogRequest} CreateQualityAssuranceLogRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiQueueReviewPostQualityAssuranceLog(data_reviewer_id: number, meal_photo_queue_id: number, CreateQualityAssuranceLogRequest: CreateQualityAssuranceLogRequest, options?: any): AxiosPromise<QualityAssuranceLogResponse> {
            return localVarFp.appApiQueueReviewPostQualityAssuranceLog(data_reviewer_id, meal_photo_queue_id, CreateQualityAssuranceLogRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a meal log comment
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {number} qa_log_id 
         * @param {UpdateQualityAssuranceLogRequest} UpdateQualityAssuranceLogRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiQueueReviewPutQualityAssuranceLog(data_reviewer_id: number, meal_photo_queue_id: number, qa_log_id: number, UpdateQualityAssuranceLogRequest: UpdateQualityAssuranceLogRequest, options?: any): AxiosPromise<QualityAssuranceLogResponse> {
            return localVarFp.appApiQueueReviewPutQualityAssuranceLog(data_reviewer_id, meal_photo_queue_id, qa_log_id, UpdateQualityAssuranceLogRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiDataReviewerDeleteDataReviewer operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerDeleteDataReviewerRequest
 */
export interface DataReviewApiAppApiDataReviewerDeleteDataReviewerRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerDeleteDataReviewer
     */
    readonly data_reviewer_id: number
}

/**
 * Request parameters for appApiDataReviewerDeleteDataReviewerGroup operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerDeleteDataReviewerGroupRequest
 */
export interface DataReviewApiAppApiDataReviewerDeleteDataReviewerGroupRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerDeleteDataReviewerGroup
     */
    readonly data_reviewer_group_id: number
}

/**
 * Request parameters for appApiDataReviewerDeleteMealItemsFromQueue operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerDeleteMealItemsFromQueueRequest
 */
export interface DataReviewApiAppApiDataReviewerDeleteMealItemsFromQueueRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerDeleteMealItemsFromQueue
     */
    readonly data_reviewer_group_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerDeleteMealItemsFromQueue
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerDeleteMealItemsFromQueue
     */
    readonly meal_photo_queue_id: number

    /**
     * 
     * @type {'inappropriate' | 'no_food_in_photo' | 'duplicate' | 'testing'}
     * @memberof DataReviewApiAppApiDataReviewerDeleteMealItemsFromQueue
     */
    readonly reason: 'inappropriate' | 'no_food_in_photo' | 'duplicate' | 'testing'
}

/**
 * Request parameters for appApiDataReviewerDeregisterDataReviewerFromGroup operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerDeregisterDataReviewerFromGroupRequest
 */
export interface DataReviewApiAppApiDataReviewerDeregisterDataReviewerFromGroupRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerDeregisterDataReviewerFromGroup
     */
    readonly data_reviewer_group_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerDeregisterDataReviewerFromGroup
     */
    readonly data_reviewer_id: number
}

/**
 * Request parameters for appApiDataReviewerEscalateMealPhotoQueueItem operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerEscalateMealPhotoQueueItemRequest
 */
export interface DataReviewApiAppApiDataReviewerEscalateMealPhotoQueueItemRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerEscalateMealPhotoQueueItem
     */
    readonly meal_photo_queue_id: number

    /**
     * 
     * @type {string}
     * @memberof DataReviewApiAppApiDataReviewerEscalateMealPhotoQueueItem
     */
    readonly reason?: string
}

/**
 * Request parameters for appApiDataReviewerGetDataReviewer operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetDataReviewerRequest
 */
export interface DataReviewApiAppApiDataReviewerGetDataReviewerRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetDataReviewer
     */
    readonly data_reviewer_id: number
}

/**
 * Request parameters for appApiDataReviewerGetDataReviewerGroup operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetDataReviewerGroupRequest
 */
export interface DataReviewApiAppApiDataReviewerGetDataReviewerGroupRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetDataReviewerGroup
     */
    readonly data_reviewer_group_id: number
}

/**
 * Request parameters for appApiDataReviewerGetDataReviewerGroupMealPhotoQueuesProcessed operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetDataReviewerGroupMealPhotoQueuesProcessedRequest
 */
export interface DataReviewApiAppApiDataReviewerGetDataReviewerGroupMealPhotoQueuesProcessedRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetDataReviewerGroupMealPhotoQueuesProcessed
     */
    readonly data_reviewer_group_id: number
}

/**
 * Request parameters for appApiDataReviewerGetDataReviewerMealPhotoQueueOriginalPhoto operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueueOriginalPhotoRequest
 */
export interface DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueueOriginalPhotoRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueueOriginalPhoto
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueueOriginalPhoto
     */
    readonly meal_photo_queue_id: number
}

/**
 * Request parameters for appApiDataReviewerGetDataReviewerMealPhotoQueuePhoto operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueuePhotoRequest
 */
export interface DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueuePhotoRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueuePhoto
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueuePhoto
     */
    readonly meal_photo_queue_id: number
}

/**
 * Request parameters for appApiDataReviewerGetDataReviewerMealPhotoQueueProcessed operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueueProcessedRequest
 */
export interface DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueueProcessedRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueueProcessed
     */
    readonly data_reviewer_group_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueueProcessed
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueueProcessed
     */
    readonly meal_photo_queue_id: number
}

/**
 * Request parameters for appApiDataReviewerGetDataReviewerMealPhotoQueuesProcessed operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueuesProcessedRequest
 */
export interface DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueuesProcessedRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueuesProcessed
     */
    readonly data_reviewer_group_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueuesProcessed
     */
    readonly data_reviewer_id: number
}

/**
 * Request parameters for appApiDataReviewerGetDataReviewerMealPhotoQueuesToBeProcessed operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueuesToBeProcessedRequest
 */
export interface DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueuesToBeProcessedRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueuesToBeProcessed
     */
    readonly data_reviewer_id: number
}

/**
 * Request parameters for appApiDataReviewerGetDataReviewerNextQueue operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetDataReviewerNextQueueRequest
 */
export interface DataReviewApiAppApiDataReviewerGetDataReviewerNextQueueRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetDataReviewerNextQueue
     */
    readonly data_reviewer_id: number
}

/**
 * Request parameters for appApiDataReviewerGetDataReviewerSettings operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetDataReviewerSettingsRequest
 */
export interface DataReviewApiAppApiDataReviewerGetDataReviewerSettingsRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetDataReviewerSettings
     */
    readonly data_reviewer_group_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetDataReviewerSettings
     */
    readonly data_reviewer_id: number
}

/**
 * Request parameters for appApiDataReviewerGetFormattedFoodStringWithUnits operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetFormattedFoodStringWithUnitsRequest
 */
export interface DataReviewApiAppApiDataReviewerGetFormattedFoodStringWithUnitsRequest {
    /**
     * list of food names
     * @type {string}
     * @memberof DataReviewApiAppApiDataReviewerGetFormattedFoodStringWithUnits
     */
    readonly food_names: string
}

/**
 * Request parameters for appApiDataReviewerGetFormattedMpqFoods operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetFormattedMpqFoodsRequest
 */
export interface DataReviewApiAppApiDataReviewerGetFormattedMpqFoodsRequest {
    /**
     * list of food names
     * @type {string}
     * @memberof DataReviewApiAppApiDataReviewerGetFormattedMpqFoods
     */
    readonly food_names: string
}

/**
 * Request parameters for appApiDataReviewerGetMealItemsHistory operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetMealItemsHistoryRequest
 */
export interface DataReviewApiAppApiDataReviewerGetMealItemsHistoryRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetMealItemsHistory
     */
    readonly data_reviewer_group_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetMealItemsHistory
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetMealItemsHistory
     */
    readonly meal_photo_queue_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetMealItemsHistory
     */
    readonly num_days: number
}

/**
 * Request parameters for appApiDataReviewerGetMealPhotoQueueId operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetMealPhotoQueueIdRequest
 */
export interface DataReviewApiAppApiDataReviewerGetMealPhotoQueueIdRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetMealPhotoQueueId
     */
    readonly meal_id: number
}

/**
 * Request parameters for appApiDataReviewerGetMealPhotoQueueItem operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetMealPhotoQueueItemRequest
 */
export interface DataReviewApiAppApiDataReviewerGetMealPhotoQueueItemRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetMealPhotoQueueItem
     */
    readonly meal_photo_queue_id: number
}

/**
 * Request parameters for appApiDataReviewerGetMealPhotoQueueItemQosDetails operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetMealPhotoQueueItemQosDetailsRequest
 */
export interface DataReviewApiAppApiDataReviewerGetMealPhotoQueueItemQosDetailsRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetMealPhotoQueueItemQosDetails
     */
    readonly meal_photo_queue_id: number
}

/**
 * Request parameters for appApiDataReviewerGetMealPhotoQueueOriginalAudio operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetMealPhotoQueueOriginalAudioRequest
 */
export interface DataReviewApiAppApiDataReviewerGetMealPhotoQueueOriginalAudioRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetMealPhotoQueueOriginalAudio
     */
    readonly data_reviewer_group_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetMealPhotoQueueOriginalAudio
     */
    readonly meal_photo_queue_id: number
}

/**
 * Request parameters for appApiDataReviewerGetMealPhotoQueueOriginalPhoto operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetMealPhotoQueueOriginalPhotoRequest
 */
export interface DataReviewApiAppApiDataReviewerGetMealPhotoQueueOriginalPhotoRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetMealPhotoQueueOriginalPhoto
     */
    readonly data_reviewer_group_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetMealPhotoQueueOriginalPhoto
     */
    readonly meal_photo_queue_id: number
}

/**
 * Request parameters for appApiDataReviewerGetMealPhotoQueuePhoto operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetMealPhotoQueuePhotoRequest
 */
export interface DataReviewApiAppApiDataReviewerGetMealPhotoQueuePhotoRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetMealPhotoQueuePhoto
     */
    readonly data_reviewer_group_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetMealPhotoQueuePhoto
     */
    readonly meal_photo_queue_id: number
}

/**
 * Request parameters for appApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewer operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewerRequest
 */
export interface DataReviewApiAppApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewerRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewer
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewer
     */
    readonly meal_photo_queue_id: number
}

/**
 * Request parameters for appApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewerGroup operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewerGroupRequest
 */
export interface DataReviewApiAppApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewerGroupRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewerGroup
     */
    readonly data_reviewer_group_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewerGroup
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewerGroup
     */
    readonly meal_photo_queue_id: number
}

/**
 * Request parameters for appApiDataReviewerGetMealPhotoQueueToBeProcessed operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetMealPhotoQueueToBeProcessedRequest
 */
export interface DataReviewApiAppApiDataReviewerGetMealPhotoQueueToBeProcessedRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetMealPhotoQueueToBeProcessed
     */
    readonly data_reviewer_group_id: number
}

/**
 * Request parameters for appApiDataReviewerGetMealRecommendedMeals operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetMealRecommendedMealsRequest
 */
export interface DataReviewApiAppApiDataReviewerGetMealRecommendedMealsRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetMealRecommendedMeals
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetMealRecommendedMeals
     */
    readonly meal_photo_queue_id: number
}

/**
 * Request parameters for appApiDataReviewerGetMealSimilarMeals operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetMealSimilarMealsRequest
 */
export interface DataReviewApiAppApiDataReviewerGetMealSimilarMealsRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetMealSimilarMeals
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetMealSimilarMeals
     */
    readonly meal_photo_queue_id: number
}

/**
 * Request parameters for appApiDataReviewerGetPatientDataReviewerGroups operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerGetPatientDataReviewerGroupsRequest
 */
export interface DataReviewApiAppApiDataReviewerGetPatientDataReviewerGroupsRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerGetPatientDataReviewerGroups
     */
    readonly patient_id: number
}

/**
 * Request parameters for appApiDataReviewerPatchDataReviewer operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerPatchDataReviewerRequest
 */
export interface DataReviewApiAppApiDataReviewerPatchDataReviewerRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerPatchDataReviewer
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {UpdateDataReviewerRequest}
     * @memberof DataReviewApiAppApiDataReviewerPatchDataReviewer
     */
    readonly UpdateDataReviewerRequest: UpdateDataReviewerRequest
}

/**
 * Request parameters for appApiDataReviewerPatchDataReviewerSettings operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerPatchDataReviewerSettingsRequest
 */
export interface DataReviewApiAppApiDataReviewerPatchDataReviewerSettingsRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerPatchDataReviewerSettings
     */
    readonly data_reviewer_group_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerPatchDataReviewerSettings
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {boolean}
     * @memberof DataReviewApiAppApiDataReviewerPatchDataReviewerSettings
     */
    readonly is_active: boolean
}

/**
 * Request parameters for appApiDataReviewerPostDataReviewer operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerPostDataReviewerRequest
 */
export interface DataReviewApiAppApiDataReviewerPostDataReviewerRequest {
    /**
     * 
     * @type {CreateDataReviewerRequest}
     * @memberof DataReviewApiAppApiDataReviewerPostDataReviewer
     */
    readonly CreateDataReviewerRequest: CreateDataReviewerRequest
}

/**
 * Request parameters for appApiDataReviewerPostDataReviewerClaimQueue operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerPostDataReviewerClaimQueueRequest
 */
export interface DataReviewApiAppApiDataReviewerPostDataReviewerClaimQueueRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerPostDataReviewerClaimQueue
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerPostDataReviewerClaimQueue
     */
    readonly meal_photo_queue_id: number
}

/**
 * Request parameters for appApiDataReviewerPostDataReviewerConfidence operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerPostDataReviewerConfidenceRequest
 */
export interface DataReviewApiAppApiDataReviewerPostDataReviewerConfidenceRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerPostDataReviewerConfidence
     */
    readonly data_reviewer_group_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerPostDataReviewerConfidence
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerPostDataReviewerConfidence
     */
    readonly meal_photo_queue_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerPostDataReviewerConfidence
     */
    readonly confidence_sizing?: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerPostDataReviewerConfidence
     */
    readonly confidence_identification?: number

    /**
     * 
     * @type {CreateQueueFeedbackRequest}
     * @memberof DataReviewApiAppApiDataReviewerPostDataReviewerConfidence
     */
    readonly CreateQueueFeedbackRequest?: CreateQueueFeedbackRequest
}

/**
 * Request parameters for appApiDataReviewerPostDataReviewerGroup operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerPostDataReviewerGroupRequest
 */
export interface DataReviewApiAppApiDataReviewerPostDataReviewerGroupRequest {
    /**
     * 
     * @type {CreateDataReviewerGroupRequest}
     * @memberof DataReviewApiAppApiDataReviewerPostDataReviewerGroup
     */
    readonly CreateDataReviewerGroupRequest: CreateDataReviewerGroupRequest
}

/**
 * Request parameters for appApiDataReviewerPostMealPhotoQueueEmptyMeal operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerPostMealPhotoQueueEmptyMealRequest
 */
export interface DataReviewApiAppApiDataReviewerPostMealPhotoQueueEmptyMealRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerPostMealPhotoQueueEmptyMeal
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerPostMealPhotoQueueEmptyMeal
     */
    readonly patient_id: number

    /**
     * 
     * @type {CreateMealRequest}
     * @memberof DataReviewApiAppApiDataReviewerPostMealPhotoQueueEmptyMeal
     */
    readonly CreateMealRequest: CreateMealRequest
}

/**
 * Request parameters for appApiDataReviewerPutDataReviewerGroup operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerPutDataReviewerGroupRequest
 */
export interface DataReviewApiAppApiDataReviewerPutDataReviewerGroupRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerPutDataReviewerGroup
     */
    readonly data_reviewer_group_id: number

    /**
     * 
     * @type {UpdateDataReviewerGroupRequest}
     * @memberof DataReviewApiAppApiDataReviewerPutDataReviewerGroup
     */
    readonly UpdateDataReviewerGroupRequest: UpdateDataReviewerGroupRequest
}

/**
 * Request parameters for appApiDataReviewerRegisterDataReviewerToGroup operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiDataReviewerRegisterDataReviewerToGroupRequest
 */
export interface DataReviewApiAppApiDataReviewerRegisterDataReviewerToGroupRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerRegisterDataReviewerToGroup
     */
    readonly data_reviewer_group_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiDataReviewerRegisterDataReviewerToGroup
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {RegisterDataReviewerToGroupRequest}
     * @memberof DataReviewApiAppApiDataReviewerRegisterDataReviewerToGroup
     */
    readonly RegisterDataReviewerToGroupRequest: RegisterDataReviewerToGroupRequest
}

/**
 * Request parameters for appApiMealPostMealItemsFromDataReviewerGroupQueue operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiMealPostMealItemsFromDataReviewerGroupQueueRequest
 */
export interface DataReviewApiAppApiMealPostMealItemsFromDataReviewerGroupQueueRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiMealPostMealItemsFromDataReviewerGroupQueue
     */
    readonly data_reviewer_group_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiMealPostMealItemsFromDataReviewerGroupQueue
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiMealPostMealItemsFromDataReviewerGroupQueue
     */
    readonly meal_photo_queue_id: number

    /**
     * 
     * @type {Array<CreateMealItemFromQueueRequest>}
     * @memberof DataReviewApiAppApiMealPostMealItemsFromDataReviewerGroupQueue
     */
    readonly CreateMealItemFromQueueRequest: Array<CreateMealItemFromQueueRequest>
}

/**
 * Request parameters for appApiMealPostMealItemsFromDataReviewerGroupQueueString operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiMealPostMealItemsFromDataReviewerGroupQueueStringRequest
 */
export interface DataReviewApiAppApiMealPostMealItemsFromDataReviewerGroupQueueStringRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiMealPostMealItemsFromDataReviewerGroupQueueString
     */
    readonly data_reviewer_group_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiMealPostMealItemsFromDataReviewerGroupQueueString
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiMealPostMealItemsFromDataReviewerGroupQueueString
     */
    readonly meal_photo_queue_id: number

    /**
     * 
     * @type {string}
     * @memberof DataReviewApiAppApiMealPostMealItemsFromDataReviewerGroupQueueString
     */
    readonly food_names: string
}

/**
 * Request parameters for appApiMealPostMealItemsFromDataReviewerQueue operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiMealPostMealItemsFromDataReviewerQueueRequest
 */
export interface DataReviewApiAppApiMealPostMealItemsFromDataReviewerQueueRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiMealPostMealItemsFromDataReviewerQueue
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiMealPostMealItemsFromDataReviewerQueue
     */
    readonly meal_photo_queue_id: number

    /**
     * 
     * @type {Array<CreateMealItemFromQueueRequest>}
     * @memberof DataReviewApiAppApiMealPostMealItemsFromDataReviewerQueue
     */
    readonly CreateMealItemFromQueueRequest: Array<CreateMealItemFromQueueRequest>

    /**
     * 
     * @type {string}
     * @memberof DataReviewApiAppApiMealPostMealItemsFromDataReviewerQueue
     */
    readonly note?: string
}

/**
 * Request parameters for appApiMealPostMealItemsFromDataReviewerQueueString operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiMealPostMealItemsFromDataReviewerQueueStringRequest
 */
export interface DataReviewApiAppApiMealPostMealItemsFromDataReviewerQueueStringRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiMealPostMealItemsFromDataReviewerQueueString
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiMealPostMealItemsFromDataReviewerQueueString
     */
    readonly meal_photo_queue_id: number

    /**
     * 
     * @type {string}
     * @memberof DataReviewApiAppApiMealPostMealItemsFromDataReviewerQueueString
     */
    readonly food_names: string
}

/**
 * Request parameters for appApiQueuePatientContextGetPatientContext operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiQueuePatientContextGetPatientContextRequest
 */
export interface DataReviewApiAppApiQueuePatientContextGetPatientContextRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiQueuePatientContextGetPatientContext
     */
    readonly data_reviewer_group_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiQueuePatientContextGetPatientContext
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiQueuePatientContextGetPatientContext
     */
    readonly meal_photo_queue_id: number
}

/**
 * Request parameters for appApiQueuePatientContextPostMpqHelp operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiQueuePatientContextPostMpqHelpRequest
 */
export interface DataReviewApiAppApiQueuePatientContextPostMpqHelpRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiQueuePatientContextPostMpqHelp
     */
    readonly data_reviewer_group_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiQueuePatientContextPostMpqHelp
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiQueuePatientContextPostMpqHelp
     */
    readonly meal_photo_queue_id: number

    /**
     * 
     * @type {DataReviewerHelpRequest}
     * @memberof DataReviewApiAppApiQueuePatientContextPostMpqHelp
     */
    readonly DataReviewerHelpRequest: DataReviewerHelpRequest
}

/**
 * Request parameters for appApiQueueReviewGetMealPhotoQueueToBeReviewed operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiQueueReviewGetMealPhotoQueueToBeReviewedRequest
 */
export interface DataReviewApiAppApiQueueReviewGetMealPhotoQueueToBeReviewedRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiQueueReviewGetMealPhotoQueueToBeReviewed
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {string}
     * @memberof DataReviewApiAppApiQueueReviewGetMealPhotoQueueToBeReviewed
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof DataReviewApiAppApiQueueReviewGetMealPhotoQueueToBeReviewed
     */
    readonly date_until: string

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiQueueReviewGetMealPhotoQueueToBeReviewed
     */
    readonly clinic_id?: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiQueueReviewGetMealPhotoQueueToBeReviewed
     */
    readonly patient_id?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof DataReviewApiAppApiQueueReviewGetMealPhotoQueueToBeReviewed
     */
    readonly meal_ids?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof DataReviewApiAppApiQueueReviewGetMealPhotoQueueToBeReviewed
     */
    readonly meal_item_ids?: Array<number>
}

/**
 * Request parameters for appApiQueueReviewGetMealPhotoQueueToBeReviewedFromQueueIds operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiQueueReviewGetMealPhotoQueueToBeReviewedFromQueueIdsRequest
 */
export interface DataReviewApiAppApiQueueReviewGetMealPhotoQueueToBeReviewedFromQueueIdsRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiQueueReviewGetMealPhotoQueueToBeReviewedFromQueueIds
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {Array<number>}
     * @memberof DataReviewApiAppApiQueueReviewGetMealPhotoQueueToBeReviewedFromQueueIds
     */
    readonly queue_ids: Array<number>
}

/**
 * Request parameters for appApiQueueReviewGetQualityAssuranceLogs operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiQueueReviewGetQualityAssuranceLogsRequest
 */
export interface DataReviewApiAppApiQueueReviewGetQualityAssuranceLogsRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiQueueReviewGetQualityAssuranceLogs
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiQueueReviewGetQualityAssuranceLogs
     */
    readonly meal_photo_queue_id: number
}

/**
 * Request parameters for appApiQueueReviewGetQueuesForQa operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiQueueReviewGetQueuesForQaRequest
 */
export interface DataReviewApiAppApiQueueReviewGetQueuesForQaRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiQueueReviewGetQueuesForQa
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiQueueReviewGetQueuesForQa
     */
    readonly n_days?: number

    /**
     * 
     * @type {string}
     * @memberof DataReviewApiAppApiQueueReviewGetQueuesForQa
     */
    readonly date_oldest?: string

    /**
     * 
     * @type {string}
     * @memberof DataReviewApiAppApiQueueReviewGetQueuesForQa
     */
    readonly date_newest?: string

    /**
     * 
     * @type {boolean}
     * @memberof DataReviewApiAppApiQueueReviewGetQueuesForQa
     */
    readonly is_reviewed?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DataReviewApiAppApiQueueReviewGetQueuesForQa
     */
    readonly is_approved?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DataReviewApiAppApiQueueReviewGetQueuesForQa
     */
    readonly is_escalated?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DataReviewApiAppApiQueueReviewGetQueuesForQa
     */
    readonly is_auto_logged?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DataReviewApiAppApiQueueReviewGetQueuesForQa
     */
    readonly is_initial?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DataReviewApiAppApiQueueReviewGetQueuesForQa
     */
    readonly is_archived?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DataReviewApiAppApiQueueReviewGetQueuesForQa
     */
    readonly is_priority?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DataReviewApiAppApiQueueReviewGetQueuesForQa
     */
    readonly has_custom_items?: boolean

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiQueueReviewGetQueuesForQa
     */
    readonly patient_id?: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiQueueReviewGetQueuesForQa
     */
    readonly limit?: number

    /**
     * 
     * @type {boolean}
     * @memberof DataReviewApiAppApiQueueReviewGetQueuesForQa
     */
    readonly is_from_recent?: boolean
}

/**
 * Request parameters for appApiQueueReviewPostQualityAssuranceLog operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiQueueReviewPostQualityAssuranceLogRequest
 */
export interface DataReviewApiAppApiQueueReviewPostQualityAssuranceLogRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiQueueReviewPostQualityAssuranceLog
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiQueueReviewPostQualityAssuranceLog
     */
    readonly meal_photo_queue_id: number

    /**
     * 
     * @type {CreateQualityAssuranceLogRequest}
     * @memberof DataReviewApiAppApiQueueReviewPostQualityAssuranceLog
     */
    readonly CreateQualityAssuranceLogRequest: CreateQualityAssuranceLogRequest
}

/**
 * Request parameters for appApiQueueReviewPutQualityAssuranceLog operation in DataReviewApi.
 * @export
 * @interface DataReviewApiAppApiQueueReviewPutQualityAssuranceLogRequest
 */
export interface DataReviewApiAppApiQueueReviewPutQualityAssuranceLogRequest {
    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiQueueReviewPutQualityAssuranceLog
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiQueueReviewPutQualityAssuranceLog
     */
    readonly meal_photo_queue_id: number

    /**
     * 
     * @type {number}
     * @memberof DataReviewApiAppApiQueueReviewPutQualityAssuranceLog
     */
    readonly qa_log_id: number

    /**
     * 
     * @type {UpdateQualityAssuranceLogRequest}
     * @memberof DataReviewApiAppApiQueueReviewPutQualityAssuranceLog
     */
    readonly UpdateQualityAssuranceLogRequest: UpdateQualityAssuranceLogRequest
}

/**
 * DataReviewApi - object-oriented interface
 * @export
 * @class DataReviewApi
 * @extends {BaseAPI}
 */
export class DataReviewApi extends BaseAPI {
    /**
     * 
     * @summary Delete the data reviewer
     * @param {DataReviewApiAppApiDataReviewerDeleteDataReviewerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerDeleteDataReviewer(requestParameters: DataReviewApiAppApiDataReviewerDeleteDataReviewerRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerDeleteDataReviewer(requestParameters.data_reviewer_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the data reviewer group
     * @param {DataReviewApiAppApiDataReviewerDeleteDataReviewerGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerDeleteDataReviewerGroup(requestParameters: DataReviewApiAppApiDataReviewerDeleteDataReviewerGroupRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerDeleteDataReviewerGroup(requestParameters.data_reviewer_group_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete meal items association to MPQ deemed to have no viable meal items. Meal is deleted if no other meal items exists.
     * @param {DataReviewApiAppApiDataReviewerDeleteMealItemsFromQueueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerDeleteMealItemsFromQueue(requestParameters: DataReviewApiAppApiDataReviewerDeleteMealItemsFromQueueRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerDeleteMealItemsFromQueue(requestParameters.data_reviewer_group_id, requestParameters.data_reviewer_id, requestParameters.meal_photo_queue_id, requestParameters.reason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the data reviewer from a group group
     * @param {DataReviewApiAppApiDataReviewerDeregisterDataReviewerFromGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerDeregisterDataReviewerFromGroup(requestParameters: DataReviewApiAppApiDataReviewerDeregisterDataReviewerFromGroupRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerDeregisterDataReviewerFromGroup(requestParameters.data_reviewer_group_id, requestParameters.data_reviewer_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Escalate a queue item to a higher review level
     * @param {DataReviewApiAppApiDataReviewerEscalateMealPhotoQueueItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerEscalateMealPhotoQueueItem(requestParameters: DataReviewApiAppApiDataReviewerEscalateMealPhotoQueueItemRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerEscalateMealPhotoQueueItem(requestParameters.meal_photo_queue_id, requestParameters.reason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get currently logged in data reviewer. This is for restricted use case, use for only DR information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetCurrentDataReviewer(options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetCurrentDataReviewer(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get details of data reviewer
     * @param {DataReviewApiAppApiDataReviewerGetDataReviewerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetDataReviewer(requestParameters: DataReviewApiAppApiDataReviewerGetDataReviewerRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetDataReviewer(requestParameters.data_reviewer_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of all data reviewer group members
     * @param {DataReviewApiAppApiDataReviewerGetDataReviewerGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetDataReviewerGroup(requestParameters: DataReviewApiAppApiDataReviewerGetDataReviewerGroupRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetDataReviewerGroup(requestParameters.data_reviewer_group_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of all meals queued for review for a specific data_reviewer_group
     * @param {DataReviewApiAppApiDataReviewerGetDataReviewerGroupMealPhotoQueuesProcessedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetDataReviewerGroupMealPhotoQueuesProcessed(requestParameters: DataReviewApiAppApiDataReviewerGetDataReviewerGroupMealPhotoQueuesProcessedRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetDataReviewerGroupMealPhotoQueuesProcessed(requestParameters.data_reviewer_group_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of all data reviewer groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetDataReviewerGroups(options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetDataReviewerGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a copy of the original photo submitted for an MPQ
     * @param {DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueueOriginalPhotoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetDataReviewerMealPhotoQueueOriginalPhoto(requestParameters: DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueueOriginalPhotoRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetDataReviewerMealPhotoQueueOriginalPhoto(requestParameters.data_reviewer_id, requestParameters.meal_photo_queue_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a copy of the resized photo submitted for an MPQ
     * @param {DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueuePhotoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetDataReviewerMealPhotoQueuePhoto(requestParameters: DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueuePhotoRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetDataReviewerMealPhotoQueuePhoto(requestParameters.data_reviewer_id, requestParameters.meal_photo_queue_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of all meals processed by a data reviewer
     * @param {DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueueProcessedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetDataReviewerMealPhotoQueueProcessed(requestParameters: DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueueProcessedRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetDataReviewerMealPhotoQueueProcessed(requestParameters.data_reviewer_group_id, requestParameters.data_reviewer_id, requestParameters.meal_photo_queue_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of all meals queued for review for a specific data_reviewer
     * @param {DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueuesProcessedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetDataReviewerMealPhotoQueuesProcessed(requestParameters: DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueuesProcessedRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetDataReviewerMealPhotoQueuesProcessed(requestParameters.data_reviewer_group_id, requestParameters.data_reviewer_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of all meals queued for a data reviewer
     * @param {DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueuesToBeProcessedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetDataReviewerMealPhotoQueuesToBeProcessed(requestParameters: DataReviewApiAppApiDataReviewerGetDataReviewerMealPhotoQueuesToBeProcessedRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetDataReviewerMealPhotoQueuesToBeProcessed(requestParameters.data_reviewer_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of all meals queued for a data reviewer
     * @param {DataReviewApiAppApiDataReviewerGetDataReviewerNextQueueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetDataReviewerNextQueue(requestParameters: DataReviewApiAppApiDataReviewerGetDataReviewerNextQueueRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetDataReviewerNextQueue(requestParameters.data_reviewer_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get data reviewer settings for the given group
     * @param {DataReviewApiAppApiDataReviewerGetDataReviewerSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetDataReviewerSettings(requestParameters: DataReviewApiAppApiDataReviewerGetDataReviewerSettingsRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetDataReviewerSettings(requestParameters.data_reviewer_group_id, requestParameters.data_reviewer_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of all data reviewers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetDataReviewers(options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetDataReviewers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the food data, for a string of foods, from the server in the MPQ format
     * @param {DataReviewApiAppApiDataReviewerGetFormattedFoodStringWithUnitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetFormattedFoodStringWithUnits(requestParameters: DataReviewApiAppApiDataReviewerGetFormattedFoodStringWithUnitsRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetFormattedFoodStringWithUnits(requestParameters.food_names, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the food data, for a string of foods, from the server in the MPQ format
     * @param {DataReviewApiAppApiDataReviewerGetFormattedMpqFoodsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetFormattedMpqFoods(requestParameters: DataReviewApiAppApiDataReviewerGetFormattedMpqFoodsRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetFormattedMpqFoods(requestParameters.food_names, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve previous meal item details for the patient for the current MPQ id
     * @param {DataReviewApiAppApiDataReviewerGetMealItemsHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetMealItemsHistory(requestParameters: DataReviewApiAppApiDataReviewerGetMealItemsHistoryRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetMealItemsHistory(requestParameters.data_reviewer_group_id, requestParameters.data_reviewer_id, requestParameters.meal_photo_queue_id, requestParameters.num_days, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Look up a meal photo queue ID from a meal ID
     * @param {DataReviewApiAppApiDataReviewerGetMealPhotoQueueIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetMealPhotoQueueId(requestParameters: DataReviewApiAppApiDataReviewerGetMealPhotoQueueIdRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetMealPhotoQueueId(requestParameters.meal_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an individual meal photo queue item
     * @param {DataReviewApiAppApiDataReviewerGetMealPhotoQueueItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetMealPhotoQueueItem(requestParameters: DataReviewApiAppApiDataReviewerGetMealPhotoQueueItemRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetMealPhotoQueueItem(requestParameters.meal_photo_queue_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the QOS details for a meal photo queue item, which includes all the queues which \"overlap\" with the meal photo queue item.
     * @param {DataReviewApiAppApiDataReviewerGetMealPhotoQueueItemQosDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetMealPhotoQueueItemQosDetails(requestParameters: DataReviewApiAppApiDataReviewerGetMealPhotoQueueItemQosDetailsRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetMealPhotoQueueItemQosDetails(requestParameters.meal_photo_queue_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a copy of the audio submitted for an MPQ
     * @param {DataReviewApiAppApiDataReviewerGetMealPhotoQueueOriginalAudioRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetMealPhotoQueueOriginalAudio(requestParameters: DataReviewApiAppApiDataReviewerGetMealPhotoQueueOriginalAudioRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetMealPhotoQueueOriginalAudio(requestParameters.data_reviewer_group_id, requestParameters.meal_photo_queue_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a copy of the photo submitted for an MPQ
     * @param {DataReviewApiAppApiDataReviewerGetMealPhotoQueueOriginalPhotoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetMealPhotoQueueOriginalPhoto(requestParameters: DataReviewApiAppApiDataReviewerGetMealPhotoQueueOriginalPhotoRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetMealPhotoQueueOriginalPhoto(requestParameters.data_reviewer_group_id, requestParameters.meal_photo_queue_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a copy of the resized photo submitted for an MPQ
     * @param {DataReviewApiAppApiDataReviewerGetMealPhotoQueuePhotoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetMealPhotoQueuePhoto(requestParameters: DataReviewApiAppApiDataReviewerGetMealPhotoQueuePhotoRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetMealPhotoQueuePhoto(requestParameters.data_reviewer_group_id, requestParameters.meal_photo_queue_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a copy of the original photo submitted for an MPQ
     * @param {DataReviewApiAppApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewer(requestParameters: DataReviewApiAppApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewerRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewer(requestParameters.data_reviewer_id, requestParameters.meal_photo_queue_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a copy of the original photo submitted for an MPQ
     * @param {DataReviewApiAppApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewerGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewerGroup(requestParameters: DataReviewApiAppApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewerGroupRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetMealPhotoQueueRecentItemsDataReviewerGroup(requestParameters.data_reviewer_group_id, requestParameters.data_reviewer_id, requestParameters.meal_photo_queue_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of all meals queued for review for a specific data_reviewer_group
     * @param {DataReviewApiAppApiDataReviewerGetMealPhotoQueueToBeProcessedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetMealPhotoQueueToBeProcessed(requestParameters: DataReviewApiAppApiDataReviewerGetMealPhotoQueueToBeProcessedRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetMealPhotoQueueToBeProcessed(requestParameters.data_reviewer_group_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a smart list of suggestions to use for labeling a meal
     * @param {DataReviewApiAppApiDataReviewerGetMealRecommendedMealsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetMealRecommendedMeals(requestParameters: DataReviewApiAppApiDataReviewerGetMealRecommendedMealsRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetMealRecommendedMeals(requestParameters.data_reviewer_id, requestParameters.meal_photo_queue_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of similar meals previously logged by the patient
     * @param {DataReviewApiAppApiDataReviewerGetMealSimilarMealsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetMealSimilarMeals(requestParameters: DataReviewApiAppApiDataReviewerGetMealSimilarMealsRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetMealSimilarMeals(requestParameters.data_reviewer_id, requestParameters.meal_photo_queue_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of all data reviewer groups assigned to the patient
     * @param {DataReviewApiAppApiDataReviewerGetPatientDataReviewerGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerGetPatientDataReviewerGroups(requestParameters: DataReviewApiAppApiDataReviewerGetPatientDataReviewerGroupsRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerGetPatientDataReviewerGroups(requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the data reviewer details
     * @param {DataReviewApiAppApiDataReviewerPatchDataReviewerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerPatchDataReviewer(requestParameters: DataReviewApiAppApiDataReviewerPatchDataReviewerRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerPatchDataReviewer(requestParameters.data_reviewer_id, requestParameters.UpdateDataReviewerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the data reviewer settings for the given group
     * @param {DataReviewApiAppApiDataReviewerPatchDataReviewerSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerPatchDataReviewerSettings(requestParameters: DataReviewApiAppApiDataReviewerPatchDataReviewerSettingsRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerPatchDataReviewerSettings(requestParameters.data_reviewer_group_id, requestParameters.data_reviewer_id, requestParameters.is_active, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new data reviewer
     * @param {DataReviewApiAppApiDataReviewerPostDataReviewerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerPostDataReviewer(requestParameters: DataReviewApiAppApiDataReviewerPostDataReviewerRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerPostDataReviewer(requestParameters.CreateDataReviewerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of all meals queued for a data reviewer
     * @param {DataReviewApiAppApiDataReviewerPostDataReviewerClaimQueueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerPostDataReviewerClaimQueue(requestParameters: DataReviewApiAppApiDataReviewerPostDataReviewerClaimQueueRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerPostDataReviewerClaimQueue(requestParameters.data_reviewer_id, requestParameters.meal_photo_queue_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post confidence score of labeled items
     * @param {DataReviewApiAppApiDataReviewerPostDataReviewerConfidenceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerPostDataReviewerConfidence(requestParameters: DataReviewApiAppApiDataReviewerPostDataReviewerConfidenceRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerPostDataReviewerConfidence(requestParameters.data_reviewer_group_id, requestParameters.data_reviewer_id, requestParameters.meal_photo_queue_id, requestParameters.confidence_sizing, requestParameters.confidence_identification, requestParameters.CreateQueueFeedbackRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new data reviewer group
     * @param {DataReviewApiAppApiDataReviewerPostDataReviewerGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerPostDataReviewerGroup(requestParameters: DataReviewApiAppApiDataReviewerPostDataReviewerGroupRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerPostDataReviewerGroup(requestParameters.CreateDataReviewerGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a test queue meal
     * @param {DataReviewApiAppApiDataReviewerPostMealPhotoQueueEmptyMealRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerPostMealPhotoQueueEmptyMeal(requestParameters: DataReviewApiAppApiDataReviewerPostMealPhotoQueueEmptyMealRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerPostMealPhotoQueueEmptyMeal(requestParameters.data_reviewer_id, requestParameters.patient_id, requestParameters.CreateMealRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the data reviewer group
     * @param {DataReviewApiAppApiDataReviewerPutDataReviewerGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerPutDataReviewerGroup(requestParameters: DataReviewApiAppApiDataReviewerPutDataReviewerGroupRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerPutDataReviewerGroup(requestParameters.data_reviewer_group_id, requestParameters.UpdateDataReviewerGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the data reviewer group
     * @param {DataReviewApiAppApiDataReviewerRegisterDataReviewerToGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiDataReviewerRegisterDataReviewerToGroup(requestParameters: DataReviewApiAppApiDataReviewerRegisterDataReviewerToGroupRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiDataReviewerRegisterDataReviewerToGroup(requestParameters.data_reviewer_group_id, requestParameters.data_reviewer_id, requestParameters.RegisterDataReviewerToGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post correct meal items to a patient meal via the meal_photo_queue
     * @param {DataReviewApiAppApiMealPostMealItemsFromDataReviewerGroupQueueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiMealPostMealItemsFromDataReviewerGroupQueue(requestParameters: DataReviewApiAppApiMealPostMealItemsFromDataReviewerGroupQueueRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiMealPostMealItemsFromDataReviewerGroupQueue(requestParameters.data_reviewer_group_id, requestParameters.data_reviewer_id, requestParameters.meal_photo_queue_id, requestParameters.CreateMealItemFromQueueRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post correct meal items to a patient meal via the meal_photo_queue
     * @param {DataReviewApiAppApiMealPostMealItemsFromDataReviewerGroupQueueStringRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiMealPostMealItemsFromDataReviewerGroupQueueString(requestParameters: DataReviewApiAppApiMealPostMealItemsFromDataReviewerGroupQueueStringRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiMealPostMealItemsFromDataReviewerGroupQueueString(requestParameters.data_reviewer_group_id, requestParameters.data_reviewer_id, requestParameters.meal_photo_queue_id, requestParameters.food_names, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post correct meal items to a patient meal via the meal_photo_queue
     * @param {DataReviewApiAppApiMealPostMealItemsFromDataReviewerQueueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiMealPostMealItemsFromDataReviewerQueue(requestParameters: DataReviewApiAppApiMealPostMealItemsFromDataReviewerQueueRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiMealPostMealItemsFromDataReviewerQueue(requestParameters.data_reviewer_id, requestParameters.meal_photo_queue_id, requestParameters.CreateMealItemFromQueueRequest, requestParameters.note, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post correct meal items to a patient meal via the meal_photo_queue
     * @param {DataReviewApiAppApiMealPostMealItemsFromDataReviewerQueueStringRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiMealPostMealItemsFromDataReviewerQueueString(requestParameters: DataReviewApiAppApiMealPostMealItemsFromDataReviewerQueueStringRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiMealPostMealItemsFromDataReviewerQueueString(requestParameters.data_reviewer_id, requestParameters.meal_photo_queue_id, requestParameters.food_names, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get patient context.
     * @param {DataReviewApiAppApiQueuePatientContextGetPatientContextRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiQueuePatientContextGetPatientContext(requestParameters: DataReviewApiAppApiQueuePatientContextGetPatientContextRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiQueuePatientContextGetPatientContext(requestParameters.data_reviewer_group_id, requestParameters.data_reviewer_id, requestParameters.meal_photo_queue_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of all meals processed by a data reviewer
     * @param {DataReviewApiAppApiQueuePatientContextPostMpqHelpRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiQueuePatientContextPostMpqHelp(requestParameters: DataReviewApiAppApiQueuePatientContextPostMpqHelpRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiQueuePatientContextPostMpqHelp(requestParameters.data_reviewer_group_id, requestParameters.data_reviewer_id, requestParameters.meal_photo_queue_id, requestParameters.DataReviewerHelpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of all meals queued for a data reviewer
     * @param {DataReviewApiAppApiQueueReviewGetMealPhotoQueueToBeReviewedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiQueueReviewGetMealPhotoQueueToBeReviewed(requestParameters: DataReviewApiAppApiQueueReviewGetMealPhotoQueueToBeReviewedRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiQueueReviewGetMealPhotoQueueToBeReviewed(requestParameters.data_reviewer_id, requestParameters.date_since, requestParameters.date_until, requestParameters.clinic_id, requestParameters.patient_id, requestParameters.meal_ids, requestParameters.meal_item_ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of all meals queued for a data reviewer given a list of queue ids
     * @param {DataReviewApiAppApiQueueReviewGetMealPhotoQueueToBeReviewedFromQueueIdsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiQueueReviewGetMealPhotoQueueToBeReviewedFromQueueIds(requestParameters: DataReviewApiAppApiQueueReviewGetMealPhotoQueueToBeReviewedFromQueueIdsRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiQueueReviewGetMealPhotoQueueToBeReviewedFromQueueIds(requestParameters.data_reviewer_id, requestParameters.queue_ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all QA logs available to the user
     * @param {DataReviewApiAppApiQueueReviewGetQualityAssuranceLogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiQueueReviewGetQualityAssuranceLogs(requestParameters: DataReviewApiAppApiQueueReviewGetQualityAssuranceLogsRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiQueueReviewGetQualityAssuranceLogs(requestParameters.data_reviewer_id, requestParameters.meal_photo_queue_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get MPQs for QA
     * @param {DataReviewApiAppApiQueueReviewGetQueuesForQaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiQueueReviewGetQueuesForQa(requestParameters: DataReviewApiAppApiQueueReviewGetQueuesForQaRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiQueueReviewGetQueuesForQa(requestParameters.data_reviewer_id, requestParameters.n_days, requestParameters.date_oldest, requestParameters.date_newest, requestParameters.is_reviewed, requestParameters.is_approved, requestParameters.is_escalated, requestParameters.is_auto_logged, requestParameters.is_initial, requestParameters.is_archived, requestParameters.is_priority, requestParameters.has_custom_items, requestParameters.patient_id, requestParameters.limit, requestParameters.is_from_recent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post a QA comment
     * @param {DataReviewApiAppApiQueueReviewPostQualityAssuranceLogRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiQueueReviewPostQualityAssuranceLog(requestParameters: DataReviewApiAppApiQueueReviewPostQualityAssuranceLogRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiQueueReviewPostQualityAssuranceLog(requestParameters.data_reviewer_id, requestParameters.meal_photo_queue_id, requestParameters.CreateQualityAssuranceLogRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a meal log comment
     * @param {DataReviewApiAppApiQueueReviewPutQualityAssuranceLogRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataReviewApi
     */
    public appApiQueueReviewPutQualityAssuranceLog(requestParameters: DataReviewApiAppApiQueueReviewPutQualityAssuranceLogRequest, options?: AxiosRequestConfig) {
        return DataReviewApiFp(this.configuration).appApiQueueReviewPutQualityAssuranceLog(requestParameters.data_reviewer_id, requestParameters.meal_photo_queue_id, requestParameters.qa_log_id, requestParameters.UpdateQualityAssuranceLogRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
