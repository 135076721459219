/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const ImageDetectionTypeEnum = {
    OcrRaw: 'ocr_raw',
    OcrNft: 'ocr_nft',
    MpqImLblRaw: 'mpq_im_lbl_raw',
    MpqImLblMatch: 'mpq_im_lbl_match',
    MpqQaAi: 'mpq_qa_ai'
} as const;

export type ImageDetectionTypeEnum = typeof ImageDetectionTypeEnum[keyof typeof ImageDetectionTypeEnum];



