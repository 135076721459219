/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const MealPushQuestionStatusEnum = {
    Pendingpatient: 'pending:patient',
    Pendingsystem: 'pending:system',
    Resolved: 'resolved',
    Deleted: 'deleted'
} as const;

export type MealPushQuestionStatusEnum = typeof MealPushQuestionStatusEnum[keyof typeof MealPushQuestionStatusEnum];



