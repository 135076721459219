import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  CardContent,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';

import { MealPhotoQueueResponse } from 'api/generated/MNT';
import { type MealQueueItem } from 'apiClients/mpq';
import { FilterSortTable } from 'components/FilterSortTable';
import MainCard from 'components/MainCard';
import { PatientID } from 'components/PatientID';
import { QueueSummaryIcons } from 'components/QueueSummaryIcons';
import { HumanTime } from 'food-editor/components/HumanTime';

export const ItemTable = (props: {
  items: MealQueueItem[],
  loading: boolean,
}) => {
  const { items } = props;
  const theme = useTheme();
  const navigate = useNavigate();

  const columns = useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Priority',
      accessor: 'is_priority_patient',
      Cell: ({ row }: any) => {
        const queue: MealPhotoQueueResponse = row?.original;
        return (
          <div style={{ width: 50 }}>
            <QueueSummaryIcons queue={queue} />
          </div>
        );
      },
    },
    {
      Header: 'Patient',
      accessor: 'patient_id',
      Cell: ({ row }: any) => {
        const item: MealQueueItem = row?.original;
        return <PatientID userId={item.patient_id} hideOffline isPriority={!!item.is_priority_patient} />;
      },
    },
    {
      Header: 'Age',
      accessor: 'created_time',
      Cell: ({ value }: any) => (
        <Typography>
          <HumanTime value={value} />
        </Typography>
      ),
    },
    {
      Header: 'Type',
      accessor: 'queue_type',
      Cell: ({ value }: any) => (
        <Typography>
          {value === 'nlp_queue' ? 'nlp item' : 'photo item'}
        </Typography>
      ),
    },
    {
      Header: 'Clinic Type',
      accessor: 'clinic_type',
    },
    {
      Header: 'Review Group',
      accessor: 'data_reviewer_group_id',
    },
    {
      Header: 'Reviewer',
      accessor: 'first_reviewer_user_id',
    },
  ], []);

  const handleRowClick = (row: any) => {
    mixpanel.track('Clicked queued meal item', { ...row });
    navigate(`/queue-item/${row.id}?utm_source=all-queue-table`);
  };

  return (
    <MainCard title="All items" content={false}>
      {items.length === 0
        ? (
          <CardContent sx={{ backgroundColor: theme.palette.grey[100] }}>
            <Grid container spacing={3} alignItems="center" justifyContent="center">
              <Grid item xs={3} justifyContent="center" sx={{ display: 'grid' }}>
                <Box>
                  <Typography variant="body1" sx={{ color: theme.palette.grey[500], fontStyle: 'italic' }}>
                    {props.loading ? 'Loading...' : "There's nothing in the queue"}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        )
        : (
          <FilterSortTable
            columns={columns}
            data={items}
            showPagination={true}
            rowClickHandler={handleRowClick}
            numRows={25}
          />
        )}
    </MainCard>
  );
};
