import { Box } from '@mui/material';
import { SxProps } from '@mui/system';

export const ImageError = ({ sx }: { sx?: SxProps }) => (
  <Box
    sx={{
      backgroundColor: 'grey.300',
      ...sx,
    }}
  />
);
