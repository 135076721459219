import { useNavigate } from 'react-router-dom';

import { EyeOutlined, FileSearchOutlined, InboxOutlined, LikeOutlined } from '@ant-design/icons';
import { useRegisterActions } from 'kbar';

import { dataReviewApi } from 'api';
import { useBrowserNotifications } from 'services/BrowserNotificationService';
import { useMenuBar } from 'services/MenuBarService';

const icons = {
  InboxOutlined,
  EyeOutlined,
  FileSearchOutlined,
  LikeOutlined,
};

export const useActions = () => {
  const navigate = useNavigate();
  const { setDrawerOpen } = useMenuBar();
  const { notify } = useBrowserNotifications();

  useRegisterActions([
    {
      id: 'nav-queue',
      name: 'Queue',
      shortcut: ['g', 'q'],
      keywords: 'home queue back',
      section: 'Navigation',
      perform: () => navigate('/'),
      subtitle: 'Go to queue page',
      icon: icons.InboxOutlined,
    },
    {
      id: 'nav-qa',
      name: 'QA',
      shortcut: ['g', 'a'],
      keywords: 'qa',
      section: 'Navigation',
      perform: () => navigate('/qa'),
      subtitle: 'Go to QA page',
      icon: icons.EyeOutlined,
    },
    {
      id: 'nav-feedback',
      name: 'Feedback',
      shortcut: ['g', 'f'],
      keywords: 'qa',
      section: 'Navigation',
      perform: () => navigate('/feedback'),
      subtitle: 'Go to Feedback page',
      icon: icons.EyeOutlined,
    },
    {
      id: 'test-notification',
      name: 'Send test desktop notification',
      // shortcut: ["n"],
      keywords: 'notification',
      section: 'Testing',
      subtitle: 'Send a test desktop notification',
      perform: () => notify('Test notification'),
    },
    {
      id: 'min-nav',
      name: 'Minimize navigation',
      shortcut: ['m'],
      keywords: 'minimize nav navigation menu',
      section: 'Navigation',
      subtitle: 'Minimize the navigation menu',
      perform: () => setDrawerOpen(false),
    },
    {
      id: 'nav-to-meal',
      name: 'Jump to meal',
      shortcut: ['j', 'q'],
      section: 'Navigation',
      subtitle: 'Jump to a queue item by queue ID',
      perform: () => {
        const mealId = prompt('Enter queue ID');
        if (mealId) {
          navigate(`/queue-item/${mealId}?utm_source=jump-to-meal`);
        }
      },
    },
    {
      id: 'nav-to-qa',
      name: 'Jump to QA',
      shortcut: ['j', 'a'],
      section: 'Navigation',
      subtitle: 'Jump to QA for a meal by meal ID',
      perform: async () => {
        const mealId = prompt('Enter meal ID');
        if (mealId) {
          const mealUrlResp = await dataReviewApi.appApiDataReviewerGetMealPhotoQueueId({
            meal_id: parseInt(mealId, 10),
          });
          if (!mealUrlResp.data.queueId) {
            return;
          }
          navigate(`/queue-item/${mealUrlResp.data.queueId}`);
        }
      },
    },
    {
      id: 'replay-user-logs',
      name: 'Replay User Logs',
      shortcut: [],
      section: 'Development',
      subtitle: 'Replay user logs to specified user ID',
      perform: async () => {
        navigate(`/dev/replaylogs`);
      },
    },
  ]);
};
