import * as React from 'react';
import EdiText from 'react-editext';

import { getPatientNote, postPatientNote } from 'apiClients/patientDetails';
import { AppCtx } from 'context/appContext';

// This component displays any notes attached to a patient id
// Used in review and Queue item pages
// Uses editext to show and edit patient note
// Styling on buttons can be customized using patientNoteButton class in App.css

export const ShowPatientNote = (props: {
  queueItem: any,
}) => {
  const { authInfo } = React.useContext(AppCtx);
  const { queueItem } = props;
  const [patientNote, setPatientNote] = React.useState('');
  React.useEffect(() => {
    const obtainNote = async () => {
      if (queueItem && authInfo) {
        try {
          const pNote = await getPatientNote(
            queueItem.data_reviewer_group_id,
            authInfo.reviewer_id,
            queueItem.id,
            authInfo.access_token,
          );
          setPatientNote(pNote);
        } catch (err: any) {
          console.log('Error ' + err.message + ' retrieving patient note, adding empty value to edit');
          setPatientNote('');
        }
      }
    };
    obtainNote();
  });
  const postNote = (text: string) => {
    if (queueItem && authInfo) {
      postPatientNote(
        queueItem.data_reviewer_group_id,
        authInfo.reviewer_id,
        queueItem.id,
        authInfo.access_token,
        text,
      );
    }
  };
  return (
    <div>
      <b>Patient Note:</b>
      <div className="patientNoteField">
        <div className="container">
          <EdiText
            type="textarea"
            viewProps={{
              className: 'patientNoteTextArea',
            }}
            inputProps={{
              className: 'patientNoteTextArea',
            }}
            saveButtonClassName="patientNoteButton apply"
            editButtonClassName="patientNoteButton"
            cancelButtonClassName="patientNoteButton revert"
            editButtonContent="Edit"
            saveButtonContent="Apply"
            cancelButtonContent="Revert"
            hideIcons={true}
            submitOnEnter
            cancelOnEscape
            hint="Edit the patient note value above. When finished editing, click Apply or Revert."
            value={patientNote}
            onSave={postNote}
          />
        </div>
      </div>
    </div>
  );
};
