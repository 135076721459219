
// material-ui
import { Box, Typography } from '@mui/material';

// types
import { NavItemType } from 'types/menu';

// project import
import { HasAuthFunc } from 'auth-capabilities';
import { useNavbarMenuItems } from 'layout/NavbarMenuItems';
import { useMenuBar } from 'services/MenuBarService';
import NavGroup from './NavGroup';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

export const Navigation = () => {
  const { drawerOpen } = useMenuBar();
  const navbarMenuItems = useNavbarMenuItems();

  const navGroups = navbarMenuItems.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: drawerOpen ? 2 : 0, '& > ul:first-of-type': { mt: 0 } }}>{navGroups}</Box>;
};

export function navItemCheckAuth(
  item: NavItemType,
  hasAuth: HasAuthFunc,
): boolean {
  if (!item.auth) {
    return true;
  }
  return hasAuth(item.auth);
}
