import { Container, Stack, Tooltip, Typography } from '@mui/material';
import { FoodOptionCollection } from 'api/generated/MNT';
import { FoodOptionCategoryEditor, FoodOptionEditor, InfoIcon } from 'components/FoodOptions';
import React from 'react';

const OPTIONS_SCHEMA: FoodOptionCollection = require('./FoodOptionsTestData.json');

const listToggle = (list: string[], value: string) => {
  if (list.includes(value)) {
    return list.filter(v => v != value);
  }
  return [...list, value];
};

export const FoodOptionsTest = () => {
  const [selected, _setSelected] = React.useState<Record<string, string | string[]>>({});
  const setValue = React.useCallback((optionId: string, value: string) => {
    const option = OPTIONS_SCHEMA.options.find((o) => o.id === optionId);
    if (!option) {
      return;
    }
    if (option.cardinality === 'single') {
      _setSelected(s => {
        if (s[optionId] === value) {
          const newS = { ...s };
          delete newS[optionId];
          return newS;
        }
        return {
          ...s,
          [optionId]: value,
        };
      });
    } else {
      _setSelected(s => ({
        ...s,
        [optionId]: listToggle(s[optionId] as string[] || [], value),
      }));
    }
  }, [_setSelected]);

  React.useEffect(() => {
    console.log('Selected values:', selected);
  }, [selected]);

  return (
    <Container style={{ marginTop: 20 }}>
      <FoodOptionEditor schema={OPTIONS_SCHEMA} values={selected} onChange={setValue} />
    </Container>
  );
};
