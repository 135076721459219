import {
  Button,
  Chip,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { MealItemCustomResponse } from 'api/generated/MNT';
import { getRecentCustomItems, refreshMeals } from 'apiClients/customItems';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAsyncResult } from 'react-use-async-result';

import MainCard from 'components/MainCard';
import { useAuth } from 'context/appContext';

import { CheckCircleOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { dataManagementApi } from 'api';
import { logTrackedError } from 'errorTracking';
import { HumanTime } from 'food-editor/components/HumanTime';
import mixpanel from 'mixpanel-browser';

type CustomMealItemRow = {
  foodName: string,
  item: MealItemCustomResponse,
  isRemoved: boolean,
  isUpdating: 'refresh' | 'rename' | '',
  isUpdated: 'refresh' | 'rename' | '',
};

type CustomMealItemRowProps = {
  row: CustomMealItemRow,
  onRefreshMeals: (
    mealIds: number[] | null | undefined,
    foodName: string,
  ) => void,
  handleRenameFood: (oldFoodName: string) => void,
};

const CustomMealItemRow = (props: CustomMealItemRowProps) => {
  const { row, onRefreshMeals } = props;
  const item = row.item;

  return (
    <TableRow key={item.food_name}>
      <TableCell>
        <Stack direction="row" spacing={1}>
          {item.food_status
            ? (
              <>
                <Link to={`/foods/${encodeURIComponent(item.food_name)}`}>{item.food_name}</Link>
                <Chip
                  label={item.food_status}
                  variant="outlined"
                  size="small"
                  color={item.food_status === 'active' ? 'success' : 'info'}
                />
              </>
            )
            : <span>{item.food_name}</span>}
          {row.isRemoved && (
            <Chip
              label="removed"
              variant="outlined"
              size="small"
              color="error"
            />
          )}
        </Stack>
      </TableCell>
      <TableCell>
        {item.meal_photo_queue_ids?.map((meal_photo_queue_id, index) => [
          index > 0 && ', ',
          <Link key={`mpq-${meal_photo_queue_id}`} to={`/queue-item/${meal_photo_queue_id}`}>
            {meal_photo_queue_id}
          </Link>,
        ])}
      </TableCell>
      <TableCell>
        {item.meal_ids?.join(', ')}
      </TableCell>
      <TableCell>
        {item.patient_ids?.join(', ')}{' '}
        {item.patient_is_priority && <Chip label="P" size="small" color="error" title="Priority patient" />}
      </TableCell>
      <TableCell>
        <HumanTime value={item.created_time} />
      </TableCell>
      <TableCell>
        <HumanTime value={item.most_recently_used} />
      </TableCell>
      <TableCell>
        {!row.isRemoved && (
          <Stack direction="column" spacing={1}>
            {!item.food_status && (
              <Button
                component={Link}
                to={`/foods/new?initial-name=${encodeURIComponent(item.food_name)}&utm_source=customs-list`}
                onClick={() => {
                  mixpanel.track('Custom item: add food clicked', {
                    'Food name': item.food_name,
                  });
                }}
                variant="outlined"
              >
                Add food
              </Button>
            )}
            <Button
              onClick={() => {
                onRefreshMeals(item.meal_ids, item.food_name);
                mixpanel.track('Custom item: refresh meals clicked', {
                  'Food name': item.food_name,
                });
              }}
              variant="outlined"
            >
              Refresh Meals
              {row.isUpdating == 'refresh' && <CircularProgress size="0.8rem" style={{ marginLeft: '0.3rem' }} />}
              {row.isUpdated == 'refresh' && <CheckCircleOutlined style={{ marginLeft: '0.3rem' }} />}
            </Button>
            {item.food_status && (
              <Button
                onClick={() => props.handleRenameFood(item.food_name)}
                variant="outlined"
              >
                Rename Food
                {row.isUpdating == 'rename' && <CircularProgress size="0.8rem" style={{ marginLeft: '0.3rem' }} />}
                {row.isUpdated == 'rename' && <CheckCircleOutlined style={{ marginLeft: '0.3rem' }} />}
              </Button>
            )}
          </Stack>
        )}
      </TableCell>
    </TableRow>
  );
};

function replaceItemAtIndex(items: CustomMealItemRow[], replacement: CustomMealItemRow, index: number) {
  return [
    ...items.slice(0, index),
    replacement,
    ...items.slice(index + 1),
  ];
}

export const CustomMealItems = () => {
  const { authInfo } = useAuth();
  const [customItems, setCustomItems] = useState<CustomMealItemRow[]>([]);
  const customMealItemsReq = useAsyncResult<MealItemCustomResponse[]>();

  useEffect(() => {
    if (!authInfo) {
      return;
    }

    customMealItemsReq.bind(getRecentCustomItems(authInfo.access_token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authInfo]);

  const refreshFoodMeals = async (
    mealIds: number[] | null | undefined,
    foodName: string,
  ) => {
    if (!authInfo) {
      return;
    }
    if (!mealIds) {
      return;
    }

    try {
      const index = customItems.findIndex(item => item.foodName === foodName);
      let updatedItem: CustomMealItemRow;
      updatedItem = {
        foodName: foodName,
        isUpdating: 'refresh',
        isUpdated: '',
        isRemoved: false,
        item: customItems[index].item,
      };
      setCustomItems(replaceItemAtIndex(customItems, updatedItem, index));

      // we refresh the meals and then check the custom items with the given
      // food name to see if anything changed
      await refreshMeals(authInfo.access_token, mealIds, true);
      const refreshedFood = await getRecentCustomItems(authInfo.access_token, foodName);

      // let updatedItem: CustomMealItemRow;

      if (refreshedFood.length > 0) {
        updatedItem = {
          foodName: refreshedFood[0].food_name,
          isUpdating: '',
          isUpdated: 'refresh',
          isRemoved: false,
          item: refreshedFood[0],
        };
      } else {
        // this is the case where refreshing the meals removed the custom
        // food item and the call to getRecentCustomItems returns nothing for
        // that food name
        updatedItem = {
          foodName: foodName,
          isUpdating: '',
          isUpdated: 'refresh',
          isRemoved: true,
          item: customItems[index].item,
        };
      }

      setCustomItems(replaceItemAtIndex(customItems, updatedItem, index));
    } catch (e) {
      logTrackedError({
        sourceName: 'CustomMealItems.refreshFoodMeals',
        origin: e as any,
        stackError: new Error(),
        context: { foodName, mealIds },
        userMessage: `Error refreshing meals for '${foodName}'. Please try again.`,
      });
    }
  };

  const handleRenameFood = async (oldFoodName: string) => {
    const newFoodName = prompt(`Please enter the new name of '${oldFoodName}'.`);
    if (!newFoodName || !confirm(`Are you sure you want to change: \n\n'${oldFoodName}' -> '${newFoodName}?`)) {
      return;
    }
    let updatedItem: CustomMealItemRow;
    const index = customItems.findIndex(item => item.foodName === oldFoodName);
    try {
      updatedItem = {
        foodName: oldFoodName,
        isUpdating: 'rename',
        isUpdated: '',
        isRemoved: false,
        item: customItems[index].item,
      };
      setCustomItems(prev => replaceItemAtIndex(prev, updatedItem, index));
      await dataManagementApi.appApiDataManagementPutRenameFood({
        old_food_name: oldFoodName,
        new_food_name: newFoodName,
      });
    } catch (error) {
      logTrackedError({
        sourceName: 'CustomMealItems.handleRenameFood',
        origin: error as any,
        stackError: new Error(),
        context: { oldFoodName, newFoodName },
        userMessage: `Error renaming '${oldFoodName}' to '${newFoodName}'. Please try again.`,
      });
      return;
    }
    updatedItem = {
      foodName: newFoodName,
      isUpdating: '',
      isUpdated: 'rename',
      isRemoved: false,
      item: { ...customItems[index].item, food_name: newFoodName },
    };
    setCustomItems(prev => replaceItemAtIndex(prev, updatedItem, index));
    mixpanel.track('Custom item: food renamed', {
      'Old Food name': oldFoodName,
      'New Food name': newFoodName,
    });
  };

  useEffect(() => {
    if (customMealItemsReq.isDone) {
      setCustomItems(customMealItemsReq.result.map((item) => {
        return {
          item,
          foodName: item.food_name,
          isRemoved: false,
          isUpdating: '',
          isUpdated: '',
        };
      }));
    }
  }, [customMealItemsReq]);

  if (customMealItemsReq.isError) {
    return <div>Error loading items: {'' + customMealItemsReq.error}</div>;
  }

  if (!customMealItemsReq.isDone) {
    return <div>Loading...</div>;
  }

  return (
    <MainCard>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item Name</TableCell>
              <TableCell>Queue IDs</TableCell>
              <TableCell>Meal IDs</TableCell>
              <TableCell>Patient IDs</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Updated</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customItems.map(item => (
              <CustomMealItemRow
                key={item.foodName}
                row={item}
                onRefreshMeals={refreshFoodMeals}
                handleRenameFood={handleRenameFood}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};
