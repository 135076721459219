import { useDraggable, useDroppable } from '@dnd-kit/core';
import { DragIndicator } from '@mui/icons-material';
import { Box, Paper, Stack } from '@mui/material';

export const Draggable = (
  props: { children: React.ReactNode, id: string, data?: Record<string, any>, disabled?: boolean },
) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: props.id,
    data: props.data,
    disabled: props.disabled,
  });

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : 'inherit',
    width: '100%',
    opacity: isDragging ? '0.5' : '1',
  };

  return (
    <Stack ref={setNodeRef} style={style} direction="row">
      {!props.disabled && (
        <Paper elevation={0} square {...listeners} {...attributes} sx={{ border: '1px solid #d9d9d9' }}>
          <DragIndicator sx={{ display: 'flex', alignItems: 'center', height: '100%' }} />
        </Paper>
      )}
      <Box sx={{ flexGrow: 1, alignSelf: 'center' }}>
        {props.children}
      </Box>
    </Stack>
  );
};

export const Droppable = (
  props: {
    id: string,
    data?: Record<string, any>,
    disabled?: boolean,
  },
) => {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
    data: props.data,
    disabled: props.disabled,
  });

  return !props.disabled
    ? (
      <Stack
        ref={setNodeRef}
        style={{
          width: '100%',
          border: `1px ${isOver ? 'solid green' : 'dotted grey'}`,
          marginTop: 7,
          marginBottom: -9,
        }}
      />
    )
    : null;
};
