import { Button, Grid } from '@mui/material';

import { FilterSortTable } from 'components/FilterSortTable';
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import { config } from 'config';

export const EngPage = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={6}>
        <AppConfig />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Env />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Sentry />
      </Grid>
    </Grid>
  );
};

// Display a table of the values of IAFoodAuditorConfig
const AppConfig = () => {
  const columns = [
    {
      Header: 'Variable',
      accessor: 'variable',
    },
    {
      Header: 'Value',
      accessor: 'value',
    },
  ];
  const data = Object.entries(config || {}).map(([key, value]) => ({ variable: key, value: '' + value }));

  return (
    <MainCard content={false} title="Config values">
      <ScrollX>
        <FilterSortTable
          columns={columns}
          data={data}
          showGlobalFilter={true}
          showPagination={true}
          showColumnSort={true}
          striped={true}
        />
      </ScrollX>
    </MainCard>
  );
};

const Env = () => {
  const env = process.env;
  const columns = [
    {
      Header: 'Variable',
      accessor: 'variable',
    },
    {
      Header: 'Value',
      accessor: 'value',
    },
  ];
  const data = Object.entries(env || {}).map(([key, value]) => ({ variable: key, value }));

  return (
    <MainCard content={false} title="Environment variables">
      <ScrollX>
        <FilterSortTable
          columns={columns}
          data={data}
          showGlobalFilter={true}
          showPagination={true}
          showColumnSort={true}
          striped={true}
        />
      </ScrollX>
    </MainCard>
  );
};

const Sentry = () => {
  return (
    <MainCard title="Sentry">
      <Button
        variant="contained"
        color="error"
        size="large"
        onClick={() => {
          throw new Error('Test error');
        }}
      >
        Throw error
      </Button>
    </MainCard>
  );
};
