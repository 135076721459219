/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppApiMealPhotoPostMealPhotosByIdZipRequest } from '../models';
// @ts-ignore
import { AppApiPatientReportsApiGetPatientNutritionDetailsTable200Response } from '../models';
// @ts-ignore
import { AppApiPatientReportsApiGetPatientReportTable200Response } from '../models';
// @ts-ignore
import { DailyReportResponse } from '../models';
// @ts-ignore
import { DetailedPatientUsageLogResponse } from '../models';
// @ts-ignore
import { FastingReportResponse } from '../models';
// @ts-ignore
import { FatResponse } from '../models';
// @ts-ignore
import { PatientLongevityResponse } from '../models';
// @ts-ignore
import { PatientReportDataResponse } from '../models';
// @ts-ignore
import { PatientReportImageResponse } from '../models';
// @ts-ignore
import { PatientReportResponse } from '../models';
// @ts-ignore
import { PatientReportSecureLinkResponse } from '../models';
// @ts-ignore
import { PatientResponse } from '../models';
// @ts-ignore
import { PatientSubscriptionResponse } from '../models';
// @ts-ignore
import { TaskResultResponse } from '../models';
// @ts-ignore
import { UpdatePatientReportDataRequest } from '../models';
// @ts-ignore
import { UpdatePatientReportRequest } from '../models';
/**
 * ReportingApi - axios parameter creator
 * @export
 */
export const ReportingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Query the patients detailed usage between the specified dates
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} [ignore_fasting_entries] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealFastingGetFastingData: async (patient_id: number, date_since: string, date_until: string, ignore_fasting_entries?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMealFastingGetFastingData', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiMealFastingGetFastingData', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiMealFastingGetFastingData', 'date_until', date_until)
            const localVarPath = `/reporting/patient/{patient_id}/fasting_report`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }

            if (ignore_fasting_entries !== undefined) {
                localVarQueryParameter['ignore_fasting_entries'] = ignore_fasting_entries;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get patient meal images for queues on the given date. Max 50 photos returned.
         * @param {string} reference_date 
         * @param {boolean} [hi_res] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPhotoGetQueuePhotosZip: async (reference_date: string, hi_res?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reference_date' is not null or undefined
            assertParamExists('appApiMealPhotoGetQueuePhotosZip', 'reference_date', reference_date)
            const localVarPath = `/reporting/queue/meal_photos_zip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reference_date !== undefined) {
                localVarQueryParameter['reference_date'] = reference_date;
            }

            if (hi_res !== undefined) {
                localVarQueryParameter['hi_res'] = hi_res;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get meal images indicated.
         * @param {AppApiMealPhotoPostMealPhotosByIdZipRequest} AppApiMealPhotoPostMealPhotosByIdZipRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPhotoPostMealPhotosByIdZip: async (AppApiMealPhotoPostMealPhotosByIdZipRequest: AppApiMealPhotoPostMealPhotosByIdZipRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'AppApiMealPhotoPostMealPhotosByIdZipRequest' is not null or undefined
            assertParamExists('appApiMealPhotoPostMealPhotosByIdZip', 'AppApiMealPhotoPostMealPhotosByIdZipRequest', AppApiMealPhotoPostMealPhotosByIdZipRequest)
            const localVarPath = `/reporting/meal_photos_zip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(AppApiMealPhotoPostMealPhotosByIdZipRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Query the patients detailed usage between the specified dates
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMetricsLongevityScoreGetLongevityScore: async (patient_id: number, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMetricsLongevityScoreGetLongevityScore', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiMetricsLongevityScoreGetLongevityScore', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiMetricsLongevityScoreGetLongevityScore', 'date_until', date_until)
            const localVarPath = `/reporting/patient/{patient_id}/longevity_score`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of patients who are registered but not active (ie no meals)
         * @param {string} reference_date 
         * @param {number} days_since_registration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientGetRegisteredNonLoggingPatients: async (reference_date: string, days_since_registration: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reference_date' is not null or undefined
            assertParamExists('appApiPatientGetRegisteredNonLoggingPatients', 'reference_date', reference_date)
            // verify required parameter 'days_since_registration' is not null or undefined
            assertParamExists('appApiPatientGetRegisteredNonLoggingPatients', 'days_since_registration', days_since_registration)
            const localVarPath = `/reporting/registered_non_logging_patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reference_date !== undefined) {
                localVarQueryParameter['reference_date'] = reference_date;
            }

            if (days_since_registration !== undefined) {
                localVarQueryParameter['days_since_registration'] = days_since_registration;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patient invitation report
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientPostPatientInvitationReport: async (date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientPostPatientInvitationReport', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientPostPatientInvitationReport', 'date_until', date_until)
            const localVarPath = `/reporting/patient_invitation_report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get currently logged in patient info
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientPostPatientReportMetrics: async (date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientPostPatientReportMetrics', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientPostPatientReportMetrics', 'date_until', date_until)
            const localVarPath = `/reporting/patient_report_metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a patient report.
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiDeleteFoodReport: async (patient_id: number, hospital_id: number, clinic_id: number, patient_report_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiDeleteFoodReport', 'patient_id', patient_id)
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiDeleteFoodReport', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiDeleteFoodReport', 'clinic_id', clinic_id)
            // verify required parameter 'patient_report_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiDeleteFoodReport', 'patient_report_id', patient_report_id)
            const localVarPath = `/hospital/{hospital_id}/clinic/{clinic_id}/patient/{patient_id}/food_report/{patient_report_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"patient_report_id"}}`, encodeURIComponent(String(patient_report_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of the draft patients reports for review
         * @param {number} clinician_id 
         * @param {string} [date_since] 
         * @param {string} [date_until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetClinicianFoodReportsDraft: async (clinician_id: number, date_since?: string, date_until?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clinician_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetClinicianFoodReportsDraft', 'clinician_id', clinician_id)
            const localVarPath = `/reporting/clinician/{clinician_id}/food_reports/draft`
                .replace(`{${"clinician_id"}}`, encodeURIComponent(String(clinician_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the patient report details
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetFoodReport: async (patient_id: number, hospital_id: number, clinic_id: number, patient_report_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetFoodReport', 'patient_id', patient_id)
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetFoodReport', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetFoodReport', 'clinic_id', clinic_id)
            // verify required parameter 'patient_report_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetFoodReport', 'patient_report_id', patient_report_id)
            const localVarPath = `/hospital/{hospital_id}/clinic/{clinic_id}/patient/{patient_id}/food_report/{patient_report_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"patient_report_id"}}`, encodeURIComponent(String(patient_report_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Query the patients detailed usage between the specified dates
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {number} [comparison_report_id] 
         * @param {boolean} [use_multipliers] 
         * @param {boolean} [override_cache] 
         * @param {Array<string>} [sections] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetFoodReportDataPreview: async (patient_id: number, hospital_id: number, clinic_id: number, date_since: string, date_until: string, comparison_report_id?: number, use_multipliers?: boolean, override_cache?: boolean, sections?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetFoodReportDataPreview', 'patient_id', patient_id)
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetFoodReportDataPreview', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetFoodReportDataPreview', 'clinic_id', clinic_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetFoodReportDataPreview', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetFoodReportDataPreview', 'date_until', date_until)
            const localVarPath = `/hospital/{hospital_id}/clinic/{clinic_id}/patient/{patient_id}/food_report/data`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }

            if (comparison_report_id !== undefined) {
                localVarQueryParameter['comparison_report_id'] = comparison_report_id;
            }

            if (use_multipliers !== undefined) {
                localVarQueryParameter['use_multipliers'] = use_multipliers;
            }

            if (override_cache !== undefined) {
                localVarQueryParameter['override_cache'] = override_cache;
            }

            if (sections) {
                localVarQueryParameter['sections'] = sections;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtain payload for a report given parameters provided.
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {number} [comparison_report_id] 
         * @param {boolean} [use_multipliers] 
         * @param {Array<string>} [sections] 
         * @param {boolean} [anonymize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetFoodReportPayload: async (patient_id: number, hospital_id: number, clinic_id: number, date_since: string, date_until: string, comparison_report_id?: number, use_multipliers?: boolean, sections?: Array<string>, anonymize?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetFoodReportPayload', 'patient_id', patient_id)
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetFoodReportPayload', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetFoodReportPayload', 'clinic_id', clinic_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetFoodReportPayload', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetFoodReportPayload', 'date_until', date_until)
            const localVarPath = `/hospital/{hospital_id}/clinic/{clinic_id}/patient/{patient_id}/food_report/payload`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }

            if (comparison_report_id !== undefined) {
                localVarQueryParameter['comparison_report_id'] = comparison_report_id;
            }

            if (use_multipliers !== undefined) {
                localVarQueryParameter['use_multipliers'] = use_multipliers;
            }

            if (sections) {
                localVarQueryParameter['sections'] = sections;
            }

            if (anonymize !== undefined) {
                localVarQueryParameter['anonymize'] = anonymize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve all food reports in a clinic for a specified time period
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetFoodReports: async (hospital_id: number, clinic_id: number, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetFoodReports', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetFoodReports', 'clinic_id', clinic_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetFoodReports', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetFoodReports', 'date_until', date_until)
            const localVarPath = `/reporting/hospital/{hospital_id}/clinic/{clinic_id}/food_reports`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the PatientReportDataResponse used to show nutrition insights in the mobile app.
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetPatientMobileNutritionInsights: async (patient_id: number, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetPatientMobileNutritionInsights', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetPatientMobileNutritionInsights', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetPatientMobileNutritionInsights', 'date_until', date_until)
            const localVarPath = `/reporting/patients/{patient_id}/mobile_nutrition_insights`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generates an ApiTable report of nutrition details for a patient
         * @param {number} patient_id 
         * @param {string} [group_by] 
         * @param {string} [filter] 
         * @param {number} [limit] 
         * @param {string} [offset] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetPatientNutritionDetailsTable: async (patient_id: number, group_by?: string, filter?: string, limit?: number, offset?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetPatientNutritionDetailsTable', 'patient_id', patient_id)
            const localVarPath = `/reporting/patients/{patient_id}/nutrition_details_table`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (group_by !== undefined) {
                localVarQueryParameter['group_by'] = group_by;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a patient report by id
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetPatientReport: async (patient_report_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_report_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetPatientReport', 'patient_report_id', patient_report_id)
            const localVarPath = `/reporting/patient_report/{patient_report_id}`
                .replace(`{${"patient_report_id"}}`, encodeURIComponent(String(patient_report_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get stored patient report data for specified report.
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetPatientReportData: async (patient_id: number, hospital_id: number, clinic_id: number, patient_report_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetPatientReportData', 'patient_id', patient_id)
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetPatientReportData', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetPatientReportData', 'clinic_id', clinic_id)
            // verify required parameter 'patient_report_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetPatientReportData', 'patient_report_id', patient_report_id)
            const localVarPath = `/hospital/{hospital_id}/clinic/{clinic_id}/patient/{patient_id}/food_report/{patient_report_id}/data`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"patient_report_id"}}`, encodeURIComponent(String(patient_report_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Query the patients detailed usage between the specified dates
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {Array<string>} [dates_exclude] 
         * @param {boolean} [is_comparison_report] 
         * @param {number} [comparison_report_id] 
         * @param {Array<string>} [sections] 
         * @param {boolean} [use_multipliers] 
         * @param {boolean} [anonymize] 
         * @param {boolean} [override_cache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetPatientReportDraftPdf: async (patient_id: number, hospital_id: number, clinic_id: number, date_since: string, date_until: string, dates_exclude?: Array<string>, is_comparison_report?: boolean, comparison_report_id?: number, sections?: Array<string>, use_multipliers?: boolean, anonymize?: boolean, override_cache?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetPatientReportDraftPdf', 'patient_id', patient_id)
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetPatientReportDraftPdf', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetPatientReportDraftPdf', 'clinic_id', clinic_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetPatientReportDraftPdf', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetPatientReportDraftPdf', 'date_until', date_until)
            const localVarPath = `/hospital/{hospital_id}/clinic/{clinic_id}/patient/{patient_id}/food_report/draft_pdf`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }

            if (dates_exclude) {
                localVarQueryParameter['dates_exclude'] = dates_exclude;
            }

            if (is_comparison_report !== undefined) {
                localVarQueryParameter['is_comparison_report'] = is_comparison_report;
            }

            if (comparison_report_id !== undefined) {
                localVarQueryParameter['comparison_report_id'] = comparison_report_id;
            }

            if (sections) {
                localVarQueryParameter['sections'] = sections;
            }

            if (use_multipliers !== undefined) {
                localVarQueryParameter['use_multipliers'] = use_multipliers;
            }

            if (anonymize !== undefined) {
                localVarQueryParameter['anonymize'] = anonymize;
            }

            if (override_cache !== undefined) {
                localVarQueryParameter['override_cache'] = override_cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Query the patients detailed usage between the specified dates
         * @param {string} [filter] 
         * @param {number} [limit] 
         * @param {string} [offset] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetPatientReportTable: async (filter?: string, limit?: number, offset?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reporting/patient-report-table`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a secure link for a patient report
         * @param {number} patient_id 
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetPatientRxfoodReportSecureLink: async (patient_id: number, patient_report_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetPatientRxfoodReportSecureLink', 'patient_id', patient_id)
            // verify required parameter 'patient_report_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetPatientRxfoodReportSecureLink', 'patient_report_id', patient_report_id)
            const localVarPath = `/reporting/patients/{patient_id}/food_reports/{patient_report_id}/secure_link`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"patient_report_id"}}`, encodeURIComponent(String(patient_report_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return secure report links
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetPatientRxfoodReportSecureLinks: async (patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetPatientRxfoodReportSecureLinks', 'patient_id', patient_id)
            const localVarPath = `/reporting/patients/{patient_id}/food_reports/secure_links`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get currently secure copy of patient rxfood report. Time restricted.
         * @param {number} user_id 
         * @param {number} patient_report_id 
         * @param {string} secure_payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetPublicPatientPrescriptionReport: async (user_id: number, patient_report_id: number, secure_payload: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetPublicPatientPrescriptionReport', 'user_id', user_id)
            // verify required parameter 'patient_report_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetPublicPatientPrescriptionReport', 'patient_report_id', patient_report_id)
            // verify required parameter 'secure_payload' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetPublicPatientPrescriptionReport', 'secure_payload', secure_payload)
            const localVarPath = `/reporting/user/{user_id}/patient_report_prescription/{patient_report_id}/secure_download`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)))
                .replace(`{${"patient_report_id"}}`, encodeURIComponent(String(patient_report_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (secure_payload !== undefined) {
                localVarQueryParameter['secure_payload'] = secure_payload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of updated report sections from V1 to latest version of the report engine
         * @param {Array<string>} sections 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetReportEngineSectionMappings: async (sections: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sections' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetReportEngineSectionMappings', 'sections', sections)
            const localVarPath = `/reporting/report_engine_section_mappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sections) {
                localVarQueryParameter['sections'] = sections;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get currently secure copy of patient rxfood report. Time restricted.
         * @param {number} user_id 
         * @param {number} patient_report_id 
         * @param {string} secure_payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetRxfoodReportSecureDownload: async (user_id: number, patient_report_id: number, secure_payload: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetRxfoodReportSecureDownload', 'user_id', user_id)
            // verify required parameter 'patient_report_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetRxfoodReportSecureDownload', 'patient_report_id', patient_report_id)
            // verify required parameter 'secure_payload' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetRxfoodReportSecureDownload', 'secure_payload', secure_payload)
            const localVarPath = `/reporting/user/{user_id}/patient_report/{patient_report_id}/secure_download`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)))
                .replace(`{${"patient_report_id"}}`, encodeURIComponent(String(patient_report_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (secure_payload !== undefined) {
                localVarQueryParameter['secure_payload'] = secure_payload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Query the patients detailed usage between the specified dates
         * @param {number} patient_report_id 
         * @param {boolean} to_patient 
         * @param {boolean} to_admin 
         * @param {boolean} [is_demo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetSendPatientPublicReportLink: async (patient_report_id: number, to_patient: boolean, to_admin: boolean, is_demo?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_report_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetSendPatientPublicReportLink', 'patient_report_id', patient_report_id)
            // verify required parameter 'to_patient' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetSendPatientPublicReportLink', 'to_patient', to_patient)
            // verify required parameter 'to_admin' is not null or undefined
            assertParamExists('appApiPatientReportsApiGetSendPatientPublicReportLink', 'to_admin', to_admin)
            const localVarPath = `/reporting/patient_report/{patient_report_id}/send_patient_public_report_link`
                .replace(`{${"patient_report_id"}}`, encodeURIComponent(String(patient_report_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (to_patient !== undefined) {
                localVarQueryParameter['to_patient'] = to_patient;
            }

            if (to_admin !== undefined) {
                localVarQueryParameter['to_admin'] = to_admin;
            }

            if (is_demo !== undefined) {
                localVarQueryParameter['is_demo'] = is_demo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Approve a patient report (permits the error flags to be ignored)
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiPostApproveFoodReport: async (patient_id: number, hospital_id: number, clinic_id: number, patient_report_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostApproveFoodReport', 'patient_id', patient_id)
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostApproveFoodReport', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostApproveFoodReport', 'clinic_id', clinic_id)
            // verify required parameter 'patient_report_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostApproveFoodReport', 'patient_report_id', patient_report_id)
            const localVarPath = `/hospital/{hospital_id}/clinic/{clinic_id}/patient/{patient_id}/food_report/{patient_report_id}/approve`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"patient_report_id"}}`, encodeURIComponent(String(patient_report_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate and persist PDF report.
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {'draft' | 'sent'} status 
         * @param {Array<string>} [dates_exclude] 
         * @param {boolean} [is_comparison_report] 
         * @param {number} [comparison_report_id] 
         * @param {number} [patient_subscription_id] 
         * @param {Array<string>} [sections] 
         * @param {boolean} [as_background_task] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiPostFoodReport: async (patient_id: number, hospital_id: number, clinic_id: number, date_since: string, date_until: string, status: 'draft' | 'sent', dates_exclude?: Array<string>, is_comparison_report?: boolean, comparison_report_id?: number, patient_subscription_id?: number, sections?: Array<string>, as_background_task?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostFoodReport', 'patient_id', patient_id)
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostFoodReport', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostFoodReport', 'clinic_id', clinic_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostFoodReport', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostFoodReport', 'date_until', date_until)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostFoodReport', 'status', status)
            const localVarPath = `/hospital/{hospital_id}/clinic/{clinic_id}/patient/{patient_id}/food_report`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }

            if (dates_exclude) {
                localVarQueryParameter['dates_exclude'] = dates_exclude;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (is_comparison_report !== undefined) {
                localVarQueryParameter['is_comparison_report'] = is_comparison_report;
            }

            if (comparison_report_id !== undefined) {
                localVarQueryParameter['comparison_report_id'] = comparison_report_id;
            }

            if (patient_subscription_id !== undefined) {
                localVarQueryParameter['patient_subscription_id'] = patient_subscription_id;
            }

            if (sections) {
                localVarQueryParameter['sections'] = sections;
            }

            if (as_background_task !== undefined) {
                localVarQueryParameter['as_background_task'] = as_background_task;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deliver a copy of the patient report by email
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_report_id 
         * @param {boolean} to_patient 
         * @param {boolean} to_clinician 
         * @param {boolean} bcc_admin 
         * @param {boolean} attach_report_pdf 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiPostFoodReportSendByEmail: async (patient_id: number, hospital_id: number, clinic_id: number, patient_report_id: number, to_patient: boolean, to_clinician: boolean, bcc_admin: boolean, attach_report_pdf: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostFoodReportSendByEmail', 'patient_id', patient_id)
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostFoodReportSendByEmail', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostFoodReportSendByEmail', 'clinic_id', clinic_id)
            // verify required parameter 'patient_report_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostFoodReportSendByEmail', 'patient_report_id', patient_report_id)
            // verify required parameter 'to_patient' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostFoodReportSendByEmail', 'to_patient', to_patient)
            // verify required parameter 'to_clinician' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostFoodReportSendByEmail', 'to_clinician', to_clinician)
            // verify required parameter 'bcc_admin' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostFoodReportSendByEmail', 'bcc_admin', bcc_admin)
            // verify required parameter 'attach_report_pdf' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostFoodReportSendByEmail', 'attach_report_pdf', attach_report_pdf)
            const localVarPath = `/hospital/{hospital_id}/clinic/{clinic_id}/patient/{patient_id}/food_report/{patient_report_id}/send_by_email`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"patient_report_id"}}`, encodeURIComponent(String(patient_report_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (to_patient !== undefined) {
                localVarQueryParameter['to_patient'] = to_patient;
            }

            if (to_clinician !== undefined) {
                localVarQueryParameter['to_clinician'] = to_clinician;
            }

            if (bcc_admin !== undefined) {
                localVarQueryParameter['bcc_admin'] = bcc_admin;
            }

            if (attach_report_pdf !== undefined) {
                localVarQueryParameter['attach_report_pdf'] = attach_report_pdf;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get patient report images with patient_report_id by report_section
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiPostRefreshPdf: async (patient_report_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_report_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostRefreshPdf', 'patient_report_id', patient_report_id)
            const localVarPath = `/reporting/patient_report/{patient_report_id}/refresh_pdf`
                .replace(`{${"patient_report_id"}}`, encodeURIComponent(String(patient_report_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get patient report images with patient_report_id by report_section
         * @param {number} patient_report_id 
         * @param {boolean} [filter_meal_dates] 
         * @param {boolean} [as_background_task] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiPostRegenerate: async (patient_report_id: number, filter_meal_dates?: boolean, as_background_task?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_report_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostRegenerate', 'patient_report_id', patient_report_id)
            const localVarPath = `/reporting/patient_report/{patient_report_id}/regenerate`
                .replace(`{${"patient_report_id"}}`, encodeURIComponent(String(patient_report_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter_meal_dates !== undefined) {
                localVarQueryParameter['filter_meal_dates'] = filter_meal_dates;
            }

            if (as_background_task !== undefined) {
                localVarQueryParameter['as_background_task'] = as_background_task;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the patient report.
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_report_id 
         * @param {'draft' | 'sent'} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiPutFoodReport: async (patient_id: number, hospital_id: number, clinic_id: number, patient_report_id: number, status: 'draft' | 'sent', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPutFoodReport', 'patient_id', patient_id)
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPutFoodReport', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPutFoodReport', 'clinic_id', clinic_id)
            // verify required parameter 'patient_report_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPutFoodReport', 'patient_report_id', patient_report_id)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('appApiPatientReportsApiPutFoodReport', 'status', status)
            const localVarPath = `/hospital/{hospital_id}/clinic/{clinic_id}/patient/{patient_id}/food_report/{patient_report_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"patient_report_id"}}`, encodeURIComponent(String(patient_report_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modify patient report data for specified report.
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_report_id 
         * @param {UpdatePatientReportDataRequest} UpdatePatientReportDataRequest 
         * @param {boolean} [restrict_sections_when_insufficient_meals] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiPutPatientReportData: async (patient_id: number, hospital_id: number, clinic_id: number, patient_report_id: number, UpdatePatientReportDataRequest: UpdatePatientReportDataRequest, restrict_sections_when_insufficient_meals?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPutPatientReportData', 'patient_id', patient_id)
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPutPatientReportData', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPutPatientReportData', 'clinic_id', clinic_id)
            // verify required parameter 'patient_report_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPutPatientReportData', 'patient_report_id', patient_report_id)
            // verify required parameter 'UpdatePatientReportDataRequest' is not null or undefined
            assertParamExists('appApiPatientReportsApiPutPatientReportData', 'UpdatePatientReportDataRequest', UpdatePatientReportDataRequest)
            const localVarPath = `/hospital/{hospital_id}/clinic/{clinic_id}/patient/{patient_id}/food_report/{patient_report_id}/data`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"patient_report_id"}}`, encodeURIComponent(String(patient_report_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (restrict_sections_when_insufficient_meals !== undefined) {
                localVarQueryParameter['restrict_sections_when_insufficient_meals'] = restrict_sections_when_insufficient_meals;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdatePatientReportDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all active subscriptions
         * @param {string} expiration_date 
         * @param {'single' | 'weekly' | 'monthly'} subscription_type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsAutomationGetReportableSubscriptionsByType: async (expiration_date: string, subscription_type: 'single' | 'weekly' | 'monthly', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expiration_date' is not null or undefined
            assertParamExists('appApiPatientReportsAutomationGetReportableSubscriptionsByType', 'expiration_date', expiration_date)
            // verify required parameter 'subscription_type' is not null or undefined
            assertParamExists('appApiPatientReportsAutomationGetReportableSubscriptionsByType', 'subscription_type', subscription_type)
            const localVarPath = `/subscriptions/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (expiration_date !== undefined) {
                localVarQueryParameter['expiration_date'] = expiration_date;
            }

            if (subscription_type !== undefined) {
                localVarQueryParameter['subscription_type'] = subscription_type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Query the patients detailed usage between the specified dates
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsDetailedPatientUsageLogGetDetailedPatientUsageLog: async (patient_id: number, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsDetailedPatientUsageLogGetDetailedPatientUsageLog', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientReportsDetailedPatientUsageLogGetDetailedPatientUsageLog', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientReportsDetailedPatientUsageLogGetDetailedPatientUsageLog', 'date_until', date_until)
            const localVarPath = `/reporting/{patient_id}/detailed_patient_usage_log`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Print foods that are highest in healthy vs unhealthy fats
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientEmailReportFatsReport: async (patient_id: number, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientEmailReportFatsReport', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientReportsPatientEmailReportFatsReport', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientReportsPatientEmailReportFatsReport', 'date_until', date_until)
            const localVarPath = `/reporting/fat_analysis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (patient_id !== undefined) {
                localVarQueryParameter['patient_id'] = patient_id;
            }

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete patient report with patient_report_id
         * @param {number} patient_id 
         * @param {number} patient_report_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportDeletePatientReport: async (patient_id: number, patient_report_id: number, hospital_id: number, clinic_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportDeletePatientReport', 'patient_id', patient_id)
            // verify required parameter 'patient_report_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportDeletePatientReport', 'patient_report_id', patient_report_id)
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportDeletePatientReport', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportDeletePatientReport', 'clinic_id', clinic_id)
            const localVarPath = `/reporting/hospital/{hospital_id}/clinic/{clinic_id}/patient/{patient_id}/patient_report/{patient_report_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"patient_report_id"}}`, encodeURIComponent(String(patient_report_id)))
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all active patient reports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportGetActivePatientsReports: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reporting/patient_reports/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get patient report with patient_report_id
         * @param {number} patient_id 
         * @param {number} patient_report_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportGetPatientReport: async (patient_id: number, patient_report_id: number, hospital_id: number, clinic_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportGetPatientReport', 'patient_id', patient_id)
            // verify required parameter 'patient_report_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportGetPatientReport', 'patient_report_id', patient_report_id)
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportGetPatientReport', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportGetPatientReport', 'clinic_id', clinic_id)
            const localVarPath = `/reporting/hospital/{hospital_id}/clinic/{clinic_id}/patient/{patient_id}/patient_report/{patient_report_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"patient_report_id"}}`, encodeURIComponent(String(patient_report_id)))
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get patient report data
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportGetPatientReportData: async (patient_id: number, hospital_id: number, clinic_id: number, patient_report_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportGetPatientReportData', 'patient_id', patient_id)
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportGetPatientReportData', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportGetPatientReportData', 'clinic_id', clinic_id)
            // verify required parameter 'patient_report_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportGetPatientReportData', 'patient_report_id', patient_report_id)
            const localVarPath = `/reporting/hospital/{hospital_id}/clinic/{clinic_id}/patient/{patient_id}/patient_report/{patient_report_id}/report_data`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"patient_report_id"}}`, encodeURIComponent(String(patient_report_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get patient report html with patient_report_id by report_section
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportGetPatientReportHtml: async (patient_report_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_report_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportGetPatientReportHtml', 'patient_report_id', patient_report_id)
            const localVarPath = `/reporting/patient_report/{patient_report_id}/report_html`
                .replace(`{${"patient_report_id"}}`, encodeURIComponent(String(patient_report_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user patient report plots contents by id
         * @param {number} patient_report_id 
         * @param {number} patient_report_image_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportGetPatientReportImageById: async (patient_report_id: number, patient_report_image_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_report_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportGetPatientReportImageById', 'patient_report_id', patient_report_id)
            // verify required parameter 'patient_report_image_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportGetPatientReportImageById', 'patient_report_image_id', patient_report_image_id)
            const localVarPath = `/reporting/patient_report/{patient_report_id}/patient_report_image/{patient_report_image_id}`
                .replace(`{${"patient_report_id"}}`, encodeURIComponent(String(patient_report_id)))
                .replace(`{${"patient_report_image_id"}}`, encodeURIComponent(String(patient_report_image_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get patient report image with patient_report_id by image name
         * @param {number} patient_report_id 
         * @param {string} image_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportGetPatientReportImageByName: async (patient_report_id: number, image_name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_report_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportGetPatientReportImageByName', 'patient_report_id', patient_report_id)
            // verify required parameter 'image_name' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportGetPatientReportImageByName', 'image_name', image_name)
            const localVarPath = `/reporting/patient_report/{patient_report_id}/photo_by_name`
                .replace(`{${"patient_report_id"}}`, encodeURIComponent(String(patient_report_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (image_name !== undefined) {
                localVarQueryParameter['image_name'] = image_name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the patients report in PDF
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportGetPatientReportPdf: async (patient_report_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_report_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportGetPatientReportPdf', 'patient_report_id', patient_report_id)
            const localVarPath = `/reporting/patient_report/{patient_report_id}/pdf`
                .replace(`{${"patient_report_id"}}`, encodeURIComponent(String(patient_report_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get patient report images with patient_report_id by report_section
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportGetPatientReportPhotoHistory: async (patient_report_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_report_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportGetPatientReportPhotoHistory', 'patient_report_id', patient_report_id)
            const localVarPath = `/reporting/patient_report/{patient_report_id}/report_images`
                .replace(`{${"patient_report_id"}}`, encodeURIComponent(String(patient_report_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of the patient reports from the date range provided
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportGetPatientReports: async (patient_id: number, hospital_id: number, clinic_id: number, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportGetPatientReports', 'patient_id', patient_id)
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportGetPatientReports', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportGetPatientReports', 'clinic_id', clinic_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportGetPatientReports', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportGetPatientReports', 'date_until', date_until)
            const localVarPath = `/reporting/hospital/{hospital_id}/clinic/{clinic_id}/patient/{patient_id}/patient_report`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get patient report data
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportGetReportData: async (patient_report_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_report_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportGetReportData', 'patient_report_id', patient_report_id)
            const localVarPath = `/reporting/patient_report/{patient_report_id}/report_data`
                .replace(`{${"patient_report_id"}}`, encodeURIComponent(String(patient_report_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new daily report for an active patient if they logged a meal
         * @param {number} patient_id 
         * @param {string} report_date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportPostDailyPatientReport: async (patient_id: number, report_date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportPostDailyPatientReport', 'patient_id', patient_id)
            // verify required parameter 'report_date' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportPostDailyPatientReport', 'report_date', report_date)
            const localVarPath = `/reporting/daily_patient_reports/patient/{patient_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (report_date !== undefined) {
                localVarQueryParameter['report_date'] = report_date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new daily report for all active patients that logged a meal
         * @param {string} report_date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportPostDailyPatientReports: async (report_date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'report_date' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportPostDailyPatientReports', 'report_date', report_date)
            const localVarPath = `/reporting/daily_patient_reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (report_date !== undefined) {
                localVarQueryParameter['report_date'] = report_date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update patient report with patient_report_id
         * @param {number} patient_id 
         * @param {number} patient_report_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {UpdatePatientReportRequest} UpdatePatientReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportPutPatientReport: async (patient_id: number, patient_report_id: number, hospital_id: number, clinic_id: number, UpdatePatientReportRequest: UpdatePatientReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportPutPatientReport', 'patient_id', patient_id)
            // verify required parameter 'patient_report_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportPutPatientReport', 'patient_report_id', patient_report_id)
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportPutPatientReport', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportPutPatientReport', 'clinic_id', clinic_id)
            // verify required parameter 'UpdatePatientReportRequest' is not null or undefined
            assertParamExists('appApiPatientReportsPatientReportPutPatientReport', 'UpdatePatientReportRequest', UpdatePatientReportRequest)
            const localVarPath = `/reporting/hospital/{hospital_id}/clinic/{clinic_id}/patient/{patient_id}/patient_report/{patient_report_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"patient_report_id"}}`, encodeURIComponent(String(patient_report_id)))
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdatePatientReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send daily patient report for individual patient
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {Array<string>} [nutrients] 
         * @param {Array<string>} [cc_columns] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPreReportGetPatientPreReportDataReview: async (patient_id: number, date_since: string, date_until: string, nutrients?: Array<string>, cc_columns?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsPreReportGetPatientPreReportDataReview', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientReportsPreReportGetPatientPreReportDataReview', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientReportsPreReportGetPatientPreReportDataReview', 'date_until', date_until)
            const localVarPath = `/reporting/patient/{patient_id}/pre_report_data_review`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }

            if (nutrients) {
                localVarQueryParameter['nutrients'] = nutrients;
            }

            if (cc_columns) {
                localVarQueryParameter['cc_columns'] = cc_columns;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patient report report.
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {Array<number>} [patient_ids] 
         * @param {Array<number>} [report_ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsReportMetricsGetPatientOutcomeTrackingReport: async (date_since: string, date_until: string, patient_ids?: Array<number>, report_ids?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientReportsReportMetricsGetPatientOutcomeTrackingReport', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientReportsReportMetricsGetPatientOutcomeTrackingReport', 'date_until', date_until)
            const localVarPath = `/reporting/patient_outcome_tracking_report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }

            if (patient_ids) {
                localVarQueryParameter['patient_ids'] = patient_ids;
            }

            if (report_ids) {
                localVarQueryParameter['report_ids'] = report_ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary generate pdf report from html zip file
         * @param {any} file zip file containing report data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPdfGeneratePdf: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('appApiPdfGeneratePdf', 'file', file)
            const localVarPath = `/reporting/generate_pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send daily patient report for individual patient
         * @param {number} patient_id 
         * @param {string} report_date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportingSendDailyReportEmail: async (patient_id: number, report_date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiReportingSendDailyReportEmail', 'patient_id', patient_id)
            // verify required parameter 'report_date' is not null or undefined
            assertParamExists('appApiReportingSendDailyReportEmail', 'report_date', report_date)
            const localVarPath = `/reporting/patient/{patient_id}/send_daily_report`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (report_date !== undefined) {
                localVarQueryParameter['report_date'] = report_date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send daily patient report
         * @param {string} report_date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportingSendDailyReportEmails: async (report_date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'report_date' is not null or undefined
            assertParamExists('appApiReportingSendDailyReportEmails', 'report_date', report_date)
            const localVarPath = `/reporting/patient_reports/send_all_daily_reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (report_date !== undefined) {
                localVarQueryParameter['report_date'] = report_date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get meal report for a patient
         * @param {number} data_reviewer_id 
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} [disaggregated] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsDataReviewerMealReportGetDataReviewerPatientMealReport: async (data_reviewer_id: number, patient_id: number, date_since: string, date_until: string, disaggregated?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiReportsDataReviewerMealReportGetDataReviewerPatientMealReport', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiReportsDataReviewerMealReportGetDataReviewerPatientMealReport', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiReportsDataReviewerMealReportGetDataReviewerPatientMealReport', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiReportsDataReviewerMealReportGetDataReviewerPatientMealReport', 'date_until', date_until)
            const localVarPath = `/reporting/data_reviewer/{data_reviewer_id}/patient/{patient_id}/meal_report`
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }

            if (disaggregated !== undefined) {
                localVarQueryParameter['disaggregated'] = disaggregated;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patient section export report (MIND, Med, DASH, Diet Quality).
         * @param {Array<number>} patient_ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsReportMetricsGetCustomReportSectionsExport: async (patient_ids: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_ids' is not null or undefined
            assertParamExists('appApiReportsReportMetricsGetCustomReportSectionsExport', 'patient_ids', patient_ids)
            const localVarPath = `/reporting/custom_report_sections_export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (patient_ids) {
                localVarQueryParameter['patient_ids'] = patient_ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patient section export report (MIND, Med, DASH, Diet Quality).
         * @param {Array<number>} patient_ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsReportMetricsGetCustomReportSectionsExportCsv: async (patient_ids: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_ids' is not null or undefined
            assertParamExists('appApiReportsReportMetricsGetCustomReportSectionsExportCsv', 'patient_ids', patient_ids)
            const localVarPath = `/reporting/custom_report_sections_export/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (patient_ids) {
                localVarQueryParameter['patient_ids'] = patient_ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patient section export weekly report (MIND, Med, DASH, Diet Quality).
         * @param {Array<number>} patient_ids 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsReportMetricsGetWeeklyCustomReportSectionsExportCsv: async (patient_ids: Array<number>, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_ids' is not null or undefined
            assertParamExists('appApiReportsReportMetricsGetWeeklyCustomReportSectionsExportCsv', 'patient_ids', patient_ids)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiReportsReportMetricsGetWeeklyCustomReportSectionsExportCsv', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiReportsReportMetricsGetWeeklyCustomReportSectionsExportCsv', 'date_until', date_until)
            const localVarPath = `/reporting/weekly_custom_report_sections_export/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (patient_ids) {
                localVarQueryParameter['patient_ids'] = patient_ids;
            }

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Report email for nlp
         * @param {string} meal_ids 
         * @param {string} [user_id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsSpacyDailyReportGetMealItemInfo: async (meal_ids: string, user_id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meal_ids' is not null or undefined
            assertParamExists('appApiReportsSpacyDailyReportGetMealItemInfo', 'meal_ids', meal_ids)
            const localVarPath = `/reporting/nlp_report_meal_id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (meal_ids !== undefined) {
                localVarQueryParameter['meal_ids'] = meal_ids;
            }

            if (user_id !== undefined) {
                localVarQueryParameter['user_id'] = user_id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate all reports to be generated for the reference date. Reference date normally would be be today + 1 (days in advance) by default. Use report_dates_only to consider ONLY report dates.
         * @param {string} reference_date 
         * @param {number} [days_in_advance] 
         * @param {boolean} [queue_for_generation] 
         * @param {boolean} [report_dates_only] 
         * @param {Array<number>} [patient_ids_to_ignore] list of patient_ids to ignore
         * @param {boolean} [send_email_report] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionReportPostPatientReportAutoGeneration: async (reference_date: string, days_in_advance?: number, queue_for_generation?: boolean, report_dates_only?: boolean, patient_ids_to_ignore?: Array<number>, send_email_report?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reference_date' is not null or undefined
            assertParamExists('appApiSubscriptionReportPostPatientReportAutoGeneration', 'reference_date', reference_date)
            const localVarPath = `/reporting/patient_report_auto_generation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reference_date !== undefined) {
                localVarQueryParameter['reference_date'] = reference_date;
            }

            if (days_in_advance !== undefined) {
                localVarQueryParameter['days_in_advance'] = days_in_advance;
            }

            if (queue_for_generation !== undefined) {
                localVarQueryParameter['queue_for_generation'] = queue_for_generation;
            }

            if (report_dates_only !== undefined) {
                localVarQueryParameter['report_dates_only'] = report_dates_only;
            }

            if (patient_ids_to_ignore) {
                localVarQueryParameter['patient_ids_to_ignore'] = patient_ids_to_ignore;
            }

            if (send_email_report !== undefined) {
                localVarQueryParameter['send_email_report'] = send_email_report;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get report on subscriptions due a report. Reference date is report_date minus one. Date since default is 21 days prior and date until 7 days after.
         * @param {string} reference_date 
         * @param {string} [date_since] 
         * @param {string} [date_until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionReportPostSubscriptionReport: async (reference_date: string, date_since?: string, date_until?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reference_date' is not null or undefined
            assertParamExists('appApiSubscriptionReportPostSubscriptionReport', 'reference_date', reference_date)
            const localVarPath = `/reporting/subscription_report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reference_date !== undefined) {
                localVarQueryParameter['reference_date'] = reference_date;
            }

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportingApi - functional programming interface
 * @export
 */
export const ReportingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Query the patients detailed usage between the specified dates
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} [ignore_fasting_entries] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealFastingGetFastingData(patient_id: number, date_since: string, date_until: string, ignore_fasting_entries?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FastingReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealFastingGetFastingData(patient_id, date_since, date_until, ignore_fasting_entries, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get patient meal images for queues on the given date. Max 50 photos returned.
         * @param {string} reference_date 
         * @param {boolean} [hi_res] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealPhotoGetQueuePhotosZip(reference_date: string, hi_res?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealPhotoGetQueuePhotosZip(reference_date, hi_res, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get meal images indicated.
         * @param {AppApiMealPhotoPostMealPhotosByIdZipRequest} AppApiMealPhotoPostMealPhotosByIdZipRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealPhotoPostMealPhotosByIdZip(AppApiMealPhotoPostMealPhotosByIdZipRequest: AppApiMealPhotoPostMealPhotosByIdZipRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealPhotoPostMealPhotosByIdZip(AppApiMealPhotoPostMealPhotosByIdZipRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Query the patients detailed usage between the specified dates
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMetricsLongevityScoreGetLongevityScore(patient_id: number, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientLongevityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMetricsLongevityScoreGetLongevityScore(patient_id, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of patients who are registered but not active (ie no meals)
         * @param {string} reference_date 
         * @param {number} days_since_registration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientGetRegisteredNonLoggingPatients(reference_date: string, days_since_registration: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientGetRegisteredNonLoggingPatients(reference_date, days_since_registration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patient invitation report
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientPostPatientInvitationReport(date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientPostPatientInvitationReport(date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get currently logged in patient info
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientPostPatientReportMetrics(date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientPostPatientReportMetrics(date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a patient report.
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiDeleteFoodReport(patient_id: number, hospital_id: number, clinic_id: number, patient_report_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiDeleteFoodReport(patient_id, hospital_id, clinic_id, patient_report_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of the draft patients reports for review
         * @param {number} clinician_id 
         * @param {string} [date_since] 
         * @param {string} [date_until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiGetClinicianFoodReportsDraft(clinician_id: number, date_since?: string, date_until?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiGetClinicianFoodReportsDraft(clinician_id, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the patient report details
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiGetFoodReport(patient_id: number, hospital_id: number, clinic_id: number, patient_report_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiGetFoodReport(patient_id, hospital_id, clinic_id, patient_report_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Query the patients detailed usage between the specified dates
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {number} [comparison_report_id] 
         * @param {boolean} [use_multipliers] 
         * @param {boolean} [override_cache] 
         * @param {Array<string>} [sections] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiGetFoodReportDataPreview(patient_id: number, hospital_id: number, clinic_id: number, date_since: string, date_until: string, comparison_report_id?: number, use_multipliers?: boolean, override_cache?: boolean, sections?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientReportDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiGetFoodReportDataPreview(patient_id, hospital_id, clinic_id, date_since, date_until, comparison_report_id, use_multipliers, override_cache, sections, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Obtain payload for a report given parameters provided.
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {number} [comparison_report_id] 
         * @param {boolean} [use_multipliers] 
         * @param {Array<string>} [sections] 
         * @param {boolean} [anonymize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiGetFoodReportPayload(patient_id: number, hospital_id: number, clinic_id: number, date_since: string, date_until: string, comparison_report_id?: number, use_multipliers?: boolean, sections?: Array<string>, anonymize?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiGetFoodReportPayload(patient_id, hospital_id, clinic_id, date_since, date_until, comparison_report_id, use_multipliers, sections, anonymize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve all food reports in a clinic for a specified time period
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiGetFoodReports(hospital_id: number, clinic_id: number, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientReportDataResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiGetFoodReports(hospital_id, clinic_id, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the PatientReportDataResponse used to show nutrition insights in the mobile app.
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiGetPatientMobileNutritionInsights(patient_id: number, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientReportDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiGetPatientMobileNutritionInsights(patient_id, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generates an ApiTable report of nutrition details for a patient
         * @param {number} patient_id 
         * @param {string} [group_by] 
         * @param {string} [filter] 
         * @param {number} [limit] 
         * @param {string} [offset] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiGetPatientNutritionDetailsTable(patient_id: number, group_by?: string, filter?: string, limit?: number, offset?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppApiPatientReportsApiGetPatientNutritionDetailsTable200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiGetPatientNutritionDetailsTable(patient_id, group_by, filter, limit, offset, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a patient report by id
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiGetPatientReport(patient_report_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiGetPatientReport(patient_report_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get stored patient report data for specified report.
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiGetPatientReportData(patient_id: number, hospital_id: number, clinic_id: number, patient_report_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientReportDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiGetPatientReportData(patient_id, hospital_id, clinic_id, patient_report_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Query the patients detailed usage between the specified dates
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {Array<string>} [dates_exclude] 
         * @param {boolean} [is_comparison_report] 
         * @param {number} [comparison_report_id] 
         * @param {Array<string>} [sections] 
         * @param {boolean} [use_multipliers] 
         * @param {boolean} [anonymize] 
         * @param {boolean} [override_cache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiGetPatientReportDraftPdf(patient_id: number, hospital_id: number, clinic_id: number, date_since: string, date_until: string, dates_exclude?: Array<string>, is_comparison_report?: boolean, comparison_report_id?: number, sections?: Array<string>, use_multipliers?: boolean, anonymize?: boolean, override_cache?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiGetPatientReportDraftPdf(patient_id, hospital_id, clinic_id, date_since, date_until, dates_exclude, is_comparison_report, comparison_report_id, sections, use_multipliers, anonymize, override_cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Query the patients detailed usage between the specified dates
         * @param {string} [filter] 
         * @param {number} [limit] 
         * @param {string} [offset] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiGetPatientReportTable(filter?: string, limit?: number, offset?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppApiPatientReportsApiGetPatientReportTable200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiGetPatientReportTable(filter, limit, offset, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a secure link for a patient report
         * @param {number} patient_id 
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiGetPatientRxfoodReportSecureLink(patient_id: number, patient_report_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientReportSecureLinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiGetPatientRxfoodReportSecureLink(patient_id, patient_report_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return secure report links
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiGetPatientRxfoodReportSecureLinks(patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientReportSecureLinkResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiGetPatientRxfoodReportSecureLinks(patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get currently secure copy of patient rxfood report. Time restricted.
         * @param {number} user_id 
         * @param {number} patient_report_id 
         * @param {string} secure_payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiGetPublicPatientPrescriptionReport(user_id: number, patient_report_id: number, secure_payload: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiGetPublicPatientPrescriptionReport(user_id, patient_report_id, secure_payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of updated report sections from V1 to latest version of the report engine
         * @param {Array<string>} sections 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiGetReportEngineSectionMappings(sections: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiGetReportEngineSectionMappings(sections, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get currently secure copy of patient rxfood report. Time restricted.
         * @param {number} user_id 
         * @param {number} patient_report_id 
         * @param {string} secure_payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiGetRxfoodReportSecureDownload(user_id: number, patient_report_id: number, secure_payload: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiGetRxfoodReportSecureDownload(user_id, patient_report_id, secure_payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Query the patients detailed usage between the specified dates
         * @param {number} patient_report_id 
         * @param {boolean} to_patient 
         * @param {boolean} to_admin 
         * @param {boolean} [is_demo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiGetSendPatientPublicReportLink(patient_report_id: number, to_patient: boolean, to_admin: boolean, is_demo?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiGetSendPatientPublicReportLink(patient_report_id, to_patient, to_admin, is_demo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Approve a patient report (permits the error flags to be ignored)
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiPostApproveFoodReport(patient_id: number, hospital_id: number, clinic_id: number, patient_report_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiPostApproveFoodReport(patient_id, hospital_id, clinic_id, patient_report_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate and persist PDF report.
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {'draft' | 'sent'} status 
         * @param {Array<string>} [dates_exclude] 
         * @param {boolean} [is_comparison_report] 
         * @param {number} [comparison_report_id] 
         * @param {number} [patient_subscription_id] 
         * @param {Array<string>} [sections] 
         * @param {boolean} [as_background_task] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiPostFoodReport(patient_id: number, hospital_id: number, clinic_id: number, date_since: string, date_until: string, status: 'draft' | 'sent', dates_exclude?: Array<string>, is_comparison_report?: boolean, comparison_report_id?: number, patient_subscription_id?: number, sections?: Array<string>, as_background_task?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiPostFoodReport(patient_id, hospital_id, clinic_id, date_since, date_until, status, dates_exclude, is_comparison_report, comparison_report_id, patient_subscription_id, sections, as_background_task, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deliver a copy of the patient report by email
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_report_id 
         * @param {boolean} to_patient 
         * @param {boolean} to_clinician 
         * @param {boolean} bcc_admin 
         * @param {boolean} attach_report_pdf 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiPostFoodReportSendByEmail(patient_id: number, hospital_id: number, clinic_id: number, patient_report_id: number, to_patient: boolean, to_clinician: boolean, bcc_admin: boolean, attach_report_pdf: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiPostFoodReportSendByEmail(patient_id, hospital_id, clinic_id, patient_report_id, to_patient, to_clinician, bcc_admin, attach_report_pdf, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get patient report images with patient_report_id by report_section
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiPostRefreshPdf(patient_report_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiPostRefreshPdf(patient_report_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get patient report images with patient_report_id by report_section
         * @param {number} patient_report_id 
         * @param {boolean} [filter_meal_dates] 
         * @param {boolean} [as_background_task] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiPostRegenerate(patient_report_id: number, filter_meal_dates?: boolean, as_background_task?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiPostRegenerate(patient_report_id, filter_meal_dates, as_background_task, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the patient report.
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_report_id 
         * @param {'draft' | 'sent'} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiPutFoodReport(patient_id: number, hospital_id: number, clinic_id: number, patient_report_id: number, status: 'draft' | 'sent', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiPutFoodReport(patient_id, hospital_id, clinic_id, patient_report_id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Modify patient report data for specified report.
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_report_id 
         * @param {UpdatePatientReportDataRequest} UpdatePatientReportDataRequest 
         * @param {boolean} [restrict_sections_when_insufficient_meals] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiPutPatientReportData(patient_id: number, hospital_id: number, clinic_id: number, patient_report_id: number, UpdatePatientReportDataRequest: UpdatePatientReportDataRequest, restrict_sections_when_insufficient_meals?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientReportDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiPutPatientReportData(patient_id, hospital_id, clinic_id, patient_report_id, UpdatePatientReportDataRequest, restrict_sections_when_insufficient_meals, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all active subscriptions
         * @param {string} expiration_date 
         * @param {'single' | 'weekly' | 'monthly'} subscription_type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsAutomationGetReportableSubscriptionsByType(expiration_date: string, subscription_type: 'single' | 'weekly' | 'monthly', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientSubscriptionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsAutomationGetReportableSubscriptionsByType(expiration_date, subscription_type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Query the patients detailed usage between the specified dates
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsDetailedPatientUsageLogGetDetailedPatientUsageLog(patient_id: number, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedPatientUsageLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsDetailedPatientUsageLogGetDetailedPatientUsageLog(patient_id, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Print foods that are highest in healthy vs unhealthy fats
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsPatientEmailReportFatsReport(patient_id: number, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FatResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsPatientEmailReportFatsReport(patient_id, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete patient report with patient_report_id
         * @param {number} patient_id 
         * @param {number} patient_report_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsPatientReportDeletePatientReport(patient_id: number, patient_report_id: number, hospital_id: number, clinic_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsPatientReportDeletePatientReport(patient_id, patient_report_id, hospital_id, clinic_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all active patient reports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsPatientReportGetActivePatientsReports(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsPatientReportGetActivePatientsReports(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get patient report with patient_report_id
         * @param {number} patient_id 
         * @param {number} patient_report_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsPatientReportGetPatientReport(patient_id: number, patient_report_id: number, hospital_id: number, clinic_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsPatientReportGetPatientReport(patient_id, patient_report_id, hospital_id, clinic_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get patient report data
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsPatientReportGetPatientReportData(patient_id: number, hospital_id: number, clinic_id: number, patient_report_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientReportDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsPatientReportGetPatientReportData(patient_id, hospital_id, clinic_id, patient_report_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get patient report html with patient_report_id by report_section
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsPatientReportGetPatientReportHtml(patient_report_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsPatientReportGetPatientReportHtml(patient_report_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user patient report plots contents by id
         * @param {number} patient_report_id 
         * @param {number} patient_report_image_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsPatientReportGetPatientReportImageById(patient_report_id: number, patient_report_image_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsPatientReportGetPatientReportImageById(patient_report_id, patient_report_image_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get patient report image with patient_report_id by image name
         * @param {number} patient_report_id 
         * @param {string} image_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsPatientReportGetPatientReportImageByName(patient_report_id: number, image_name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsPatientReportGetPatientReportImageByName(patient_report_id, image_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve the patients report in PDF
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsPatientReportGetPatientReportPdf(patient_report_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsPatientReportGetPatientReportPdf(patient_report_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get patient report images with patient_report_id by report_section
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsPatientReportGetPatientReportPhotoHistory(patient_report_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientReportImageResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsPatientReportGetPatientReportPhotoHistory(patient_report_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of the patient reports from the date range provided
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsPatientReportGetPatientReports(patient_id: number, hospital_id: number, clinic_id: number, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsPatientReportGetPatientReports(patient_id, hospital_id, clinic_id, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get patient report data
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsPatientReportGetReportData(patient_report_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsPatientReportGetReportData(patient_report_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new daily report for an active patient if they logged a meal
         * @param {number} patient_id 
         * @param {string} report_date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsPatientReportPostDailyPatientReport(patient_id: number, report_date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DailyReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsPatientReportPostDailyPatientReport(patient_id, report_date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new daily report for all active patients that logged a meal
         * @param {string} report_date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsPatientReportPostDailyPatientReports(report_date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsPatientReportPostDailyPatientReports(report_date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update patient report with patient_report_id
         * @param {number} patient_id 
         * @param {number} patient_report_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {UpdatePatientReportRequest} UpdatePatientReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsPatientReportPutPatientReport(patient_id: number, patient_report_id: number, hospital_id: number, clinic_id: number, UpdatePatientReportRequest: UpdatePatientReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsPatientReportPutPatientReport(patient_id, patient_report_id, hospital_id, clinic_id, UpdatePatientReportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send daily patient report for individual patient
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {Array<string>} [nutrients] 
         * @param {Array<string>} [cc_columns] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsPreReportGetPatientPreReportDataReview(patient_id: number, date_since: string, date_until: string, nutrients?: Array<string>, cc_columns?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsPreReportGetPatientPreReportDataReview(patient_id, date_since, date_until, nutrients, cc_columns, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patient report report.
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {Array<number>} [patient_ids] 
         * @param {Array<number>} [report_ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsReportMetricsGetPatientOutcomeTrackingReport(date_since: string, date_until: string, patient_ids?: Array<number>, report_ids?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsReportMetricsGetPatientOutcomeTrackingReport(date_since, date_until, patient_ids, report_ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary generate pdf report from html zip file
         * @param {any} file zip file containing report data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPdfGeneratePdf(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPdfGeneratePdf(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send daily patient report for individual patient
         * @param {number} patient_id 
         * @param {string} report_date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportingSendDailyReportEmail(patient_id: number, report_date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportingSendDailyReportEmail(patient_id, report_date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send daily patient report
         * @param {string} report_date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportingSendDailyReportEmails(report_date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportingSendDailyReportEmails(report_date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get meal report for a patient
         * @param {number} data_reviewer_id 
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} [disaggregated] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportsDataReviewerMealReportGetDataReviewerPatientMealReport(data_reviewer_id: number, patient_id: number, date_since: string, date_until: string, disaggregated?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportsDataReviewerMealReportGetDataReviewerPatientMealReport(data_reviewer_id, patient_id, date_since, date_until, disaggregated, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patient section export report (MIND, Med, DASH, Diet Quality).
         * @param {Array<number>} patient_ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportsReportMetricsGetCustomReportSectionsExport(patient_ids: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportsReportMetricsGetCustomReportSectionsExport(patient_ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patient section export report (MIND, Med, DASH, Diet Quality).
         * @param {Array<number>} patient_ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportsReportMetricsGetCustomReportSectionsExportCsv(patient_ids: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportsReportMetricsGetCustomReportSectionsExportCsv(patient_ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patient section export weekly report (MIND, Med, DASH, Diet Quality).
         * @param {Array<number>} patient_ids 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportsReportMetricsGetWeeklyCustomReportSectionsExportCsv(patient_ids: Array<number>, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportsReportMetricsGetWeeklyCustomReportSectionsExportCsv(patient_ids, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Report email for nlp
         * @param {string} meal_ids 
         * @param {string} [user_id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportsSpacyDailyReportGetMealItemInfo(meal_ids: string, user_id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportsSpacyDailyReportGetMealItemInfo(meal_ids, user_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate all reports to be generated for the reference date. Reference date normally would be be today + 1 (days in advance) by default. Use report_dates_only to consider ONLY report dates.
         * @param {string} reference_date 
         * @param {number} [days_in_advance] 
         * @param {boolean} [queue_for_generation] 
         * @param {boolean} [report_dates_only] 
         * @param {Array<number>} [patient_ids_to_ignore] list of patient_ids to ignore
         * @param {boolean} [send_email_report] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiSubscriptionReportPostPatientReportAutoGeneration(reference_date: string, days_in_advance?: number, queue_for_generation?: boolean, report_dates_only?: boolean, patient_ids_to_ignore?: Array<number>, send_email_report?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiSubscriptionReportPostPatientReportAutoGeneration(reference_date, days_in_advance, queue_for_generation, report_dates_only, patient_ids_to_ignore, send_email_report, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get report on subscriptions due a report. Reference date is report_date minus one. Date since default is 21 days prior and date until 7 days after.
         * @param {string} reference_date 
         * @param {string} [date_since] 
         * @param {string} [date_until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiSubscriptionReportPostSubscriptionReport(reference_date: string, date_since?: string, date_until?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiSubscriptionReportPostSubscriptionReport(reference_date, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportingApi - factory interface
 * @export
 */
export const ReportingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportingApiFp(configuration)
    return {
        /**
         * 
         * @summary Query the patients detailed usage between the specified dates
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} [ignore_fasting_entries] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealFastingGetFastingData(patient_id: number, date_since: string, date_until: string, ignore_fasting_entries?: boolean, options?: any): AxiosPromise<FastingReportResponse> {
            return localVarFp.appApiMealFastingGetFastingData(patient_id, date_since, date_until, ignore_fasting_entries, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get patient meal images for queues on the given date. Max 50 photos returned.
         * @param {string} reference_date 
         * @param {boolean} [hi_res] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPhotoGetQueuePhotosZip(reference_date: string, hi_res?: boolean, options?: any): AxiosPromise<any> {
            return localVarFp.appApiMealPhotoGetQueuePhotosZip(reference_date, hi_res, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get meal images indicated.
         * @param {AppApiMealPhotoPostMealPhotosByIdZipRequest} AppApiMealPhotoPostMealPhotosByIdZipRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPhotoPostMealPhotosByIdZip(AppApiMealPhotoPostMealPhotosByIdZipRequest: AppApiMealPhotoPostMealPhotosByIdZipRequest, options?: any): AxiosPromise<any> {
            return localVarFp.appApiMealPhotoPostMealPhotosByIdZip(AppApiMealPhotoPostMealPhotosByIdZipRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Query the patients detailed usage between the specified dates
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMetricsLongevityScoreGetLongevityScore(patient_id: number, date_since: string, date_until: string, options?: any): AxiosPromise<PatientLongevityResponse> {
            return localVarFp.appApiMetricsLongevityScoreGetLongevityScore(patient_id, date_since, date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of patients who are registered but not active (ie no meals)
         * @param {string} reference_date 
         * @param {number} days_since_registration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientGetRegisteredNonLoggingPatients(reference_date: string, days_since_registration: number, options?: any): AxiosPromise<Array<PatientResponse>> {
            return localVarFp.appApiPatientGetRegisteredNonLoggingPatients(reference_date, days_since_registration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patient invitation report
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientPostPatientInvitationReport(date_since: string, date_until: string, options?: any): AxiosPromise<void> {
            return localVarFp.appApiPatientPostPatientInvitationReport(date_since, date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get currently logged in patient info
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientPostPatientReportMetrics(date_since: string, date_until: string, options?: any): AxiosPromise<void> {
            return localVarFp.appApiPatientPostPatientReportMetrics(date_since, date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a patient report.
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiDeleteFoodReport(patient_id: number, hospital_id: number, clinic_id: number, patient_report_id: number, options?: any): AxiosPromise<Array<PatientReportResponse>> {
            return localVarFp.appApiPatientReportsApiDeleteFoodReport(patient_id, hospital_id, clinic_id, patient_report_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of the draft patients reports for review
         * @param {number} clinician_id 
         * @param {string} [date_since] 
         * @param {string} [date_until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetClinicianFoodReportsDraft(clinician_id: number, date_since?: string, date_until?: string, options?: any): AxiosPromise<Array<PatientReportResponse>> {
            return localVarFp.appApiPatientReportsApiGetClinicianFoodReportsDraft(clinician_id, date_since, date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the patient report details
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetFoodReport(patient_id: number, hospital_id: number, clinic_id: number, patient_report_id: number, options?: any): AxiosPromise<Array<PatientReportResponse>> {
            return localVarFp.appApiPatientReportsApiGetFoodReport(patient_id, hospital_id, clinic_id, patient_report_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Query the patients detailed usage between the specified dates
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {number} [comparison_report_id] 
         * @param {boolean} [use_multipliers] 
         * @param {boolean} [override_cache] 
         * @param {Array<string>} [sections] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetFoodReportDataPreview(patient_id: number, hospital_id: number, clinic_id: number, date_since: string, date_until: string, comparison_report_id?: number, use_multipliers?: boolean, override_cache?: boolean, sections?: Array<string>, options?: any): AxiosPromise<PatientReportDataResponse> {
            return localVarFp.appApiPatientReportsApiGetFoodReportDataPreview(patient_id, hospital_id, clinic_id, date_since, date_until, comparison_report_id, use_multipliers, override_cache, sections, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtain payload for a report given parameters provided.
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {number} [comparison_report_id] 
         * @param {boolean} [use_multipliers] 
         * @param {Array<string>} [sections] 
         * @param {boolean} [anonymize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetFoodReportPayload(patient_id: number, hospital_id: number, clinic_id: number, date_since: string, date_until: string, comparison_report_id?: number, use_multipliers?: boolean, sections?: Array<string>, anonymize?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.appApiPatientReportsApiGetFoodReportPayload(patient_id, hospital_id, clinic_id, date_since, date_until, comparison_report_id, use_multipliers, sections, anonymize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve all food reports in a clinic for a specified time period
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetFoodReports(hospital_id: number, clinic_id: number, date_since: string, date_until: string, options?: any): AxiosPromise<Array<PatientReportDataResponse>> {
            return localVarFp.appApiPatientReportsApiGetFoodReports(hospital_id, clinic_id, date_since, date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the PatientReportDataResponse used to show nutrition insights in the mobile app.
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetPatientMobileNutritionInsights(patient_id: number, date_since: string, date_until: string, options?: any): AxiosPromise<PatientReportDataResponse> {
            return localVarFp.appApiPatientReportsApiGetPatientMobileNutritionInsights(patient_id, date_since, date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generates an ApiTable report of nutrition details for a patient
         * @param {number} patient_id 
         * @param {string} [group_by] 
         * @param {string} [filter] 
         * @param {number} [limit] 
         * @param {string} [offset] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetPatientNutritionDetailsTable(patient_id: number, group_by?: string, filter?: string, limit?: number, offset?: string, order?: string, options?: any): AxiosPromise<AppApiPatientReportsApiGetPatientNutritionDetailsTable200Response> {
            return localVarFp.appApiPatientReportsApiGetPatientNutritionDetailsTable(patient_id, group_by, filter, limit, offset, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a patient report by id
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetPatientReport(patient_report_id: number, options?: any): AxiosPromise<PatientReportResponse> {
            return localVarFp.appApiPatientReportsApiGetPatientReport(patient_report_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get stored patient report data for specified report.
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetPatientReportData(patient_id: number, hospital_id: number, clinic_id: number, patient_report_id: number, options?: any): AxiosPromise<PatientReportDataResponse> {
            return localVarFp.appApiPatientReportsApiGetPatientReportData(patient_id, hospital_id, clinic_id, patient_report_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Query the patients detailed usage between the specified dates
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {Array<string>} [dates_exclude] 
         * @param {boolean} [is_comparison_report] 
         * @param {number} [comparison_report_id] 
         * @param {Array<string>} [sections] 
         * @param {boolean} [use_multipliers] 
         * @param {boolean} [anonymize] 
         * @param {boolean} [override_cache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetPatientReportDraftPdf(patient_id: number, hospital_id: number, clinic_id: number, date_since: string, date_until: string, dates_exclude?: Array<string>, is_comparison_report?: boolean, comparison_report_id?: number, sections?: Array<string>, use_multipliers?: boolean, anonymize?: boolean, override_cache?: boolean, options?: any): AxiosPromise<any> {
            return localVarFp.appApiPatientReportsApiGetPatientReportDraftPdf(patient_id, hospital_id, clinic_id, date_since, date_until, dates_exclude, is_comparison_report, comparison_report_id, sections, use_multipliers, anonymize, override_cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Query the patients detailed usage between the specified dates
         * @param {string} [filter] 
         * @param {number} [limit] 
         * @param {string} [offset] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetPatientReportTable(filter?: string, limit?: number, offset?: string, order?: string, options?: any): AxiosPromise<AppApiPatientReportsApiGetPatientReportTable200Response> {
            return localVarFp.appApiPatientReportsApiGetPatientReportTable(filter, limit, offset, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a secure link for a patient report
         * @param {number} patient_id 
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetPatientRxfoodReportSecureLink(patient_id: number, patient_report_id: number, options?: any): AxiosPromise<PatientReportSecureLinkResponse> {
            return localVarFp.appApiPatientReportsApiGetPatientRxfoodReportSecureLink(patient_id, patient_report_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return secure report links
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetPatientRxfoodReportSecureLinks(patient_id: number, options?: any): AxiosPromise<Array<PatientReportSecureLinkResponse>> {
            return localVarFp.appApiPatientReportsApiGetPatientRxfoodReportSecureLinks(patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get currently secure copy of patient rxfood report. Time restricted.
         * @param {number} user_id 
         * @param {number} patient_report_id 
         * @param {string} secure_payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetPublicPatientPrescriptionReport(user_id: number, patient_report_id: number, secure_payload: string, options?: any): AxiosPromise<void> {
            return localVarFp.appApiPatientReportsApiGetPublicPatientPrescriptionReport(user_id, patient_report_id, secure_payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of updated report sections from V1 to latest version of the report engine
         * @param {Array<string>} sections 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetReportEngineSectionMappings(sections: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.appApiPatientReportsApiGetReportEngineSectionMappings(sections, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get currently secure copy of patient rxfood report. Time restricted.
         * @param {number} user_id 
         * @param {number} patient_report_id 
         * @param {string} secure_payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetRxfoodReportSecureDownload(user_id: number, patient_report_id: number, secure_payload: string, options?: any): AxiosPromise<void> {
            return localVarFp.appApiPatientReportsApiGetRxfoodReportSecureDownload(user_id, patient_report_id, secure_payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Query the patients detailed usage between the specified dates
         * @param {number} patient_report_id 
         * @param {boolean} to_patient 
         * @param {boolean} to_admin 
         * @param {boolean} [is_demo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiGetSendPatientPublicReportLink(patient_report_id: number, to_patient: boolean, to_admin: boolean, is_demo?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.appApiPatientReportsApiGetSendPatientPublicReportLink(patient_report_id, to_patient, to_admin, is_demo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Approve a patient report (permits the error flags to be ignored)
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiPostApproveFoodReport(patient_id: number, hospital_id: number, clinic_id: number, patient_report_id: number, options?: any): AxiosPromise<Array<PatientReportResponse>> {
            return localVarFp.appApiPatientReportsApiPostApproveFoodReport(patient_id, hospital_id, clinic_id, patient_report_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate and persist PDF report.
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {'draft' | 'sent'} status 
         * @param {Array<string>} [dates_exclude] 
         * @param {boolean} [is_comparison_report] 
         * @param {number} [comparison_report_id] 
         * @param {number} [patient_subscription_id] 
         * @param {Array<string>} [sections] 
         * @param {boolean} [as_background_task] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiPostFoodReport(patient_id: number, hospital_id: number, clinic_id: number, date_since: string, date_until: string, status: 'draft' | 'sent', dates_exclude?: Array<string>, is_comparison_report?: boolean, comparison_report_id?: number, patient_subscription_id?: number, sections?: Array<string>, as_background_task?: boolean, options?: any): AxiosPromise<Array<PatientReportResponse>> {
            return localVarFp.appApiPatientReportsApiPostFoodReport(patient_id, hospital_id, clinic_id, date_since, date_until, status, dates_exclude, is_comparison_report, comparison_report_id, patient_subscription_id, sections, as_background_task, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deliver a copy of the patient report by email
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_report_id 
         * @param {boolean} to_patient 
         * @param {boolean} to_clinician 
         * @param {boolean} bcc_admin 
         * @param {boolean} attach_report_pdf 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiPostFoodReportSendByEmail(patient_id: number, hospital_id: number, clinic_id: number, patient_report_id: number, to_patient: boolean, to_clinician: boolean, bcc_admin: boolean, attach_report_pdf: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.appApiPatientReportsApiPostFoodReportSendByEmail(patient_id, hospital_id, clinic_id, patient_report_id, to_patient, to_clinician, bcc_admin, attach_report_pdf, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get patient report images with patient_report_id by report_section
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiPostRefreshPdf(patient_report_id: number, options?: any): AxiosPromise<void> {
            return localVarFp.appApiPatientReportsApiPostRefreshPdf(patient_report_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get patient report images with patient_report_id by report_section
         * @param {number} patient_report_id 
         * @param {boolean} [filter_meal_dates] 
         * @param {boolean} [as_background_task] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiPostRegenerate(patient_report_id: number, filter_meal_dates?: boolean, as_background_task?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.appApiPatientReportsApiPostRegenerate(patient_report_id, filter_meal_dates, as_background_task, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the patient report.
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_report_id 
         * @param {'draft' | 'sent'} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiPutFoodReport(patient_id: number, hospital_id: number, clinic_id: number, patient_report_id: number, status: 'draft' | 'sent', options?: any): AxiosPromise<Array<PatientReportResponse>> {
            return localVarFp.appApiPatientReportsApiPutFoodReport(patient_id, hospital_id, clinic_id, patient_report_id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Modify patient report data for specified report.
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_report_id 
         * @param {UpdatePatientReportDataRequest} UpdatePatientReportDataRequest 
         * @param {boolean} [restrict_sections_when_insufficient_meals] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiPutPatientReportData(patient_id: number, hospital_id: number, clinic_id: number, patient_report_id: number, UpdatePatientReportDataRequest: UpdatePatientReportDataRequest, restrict_sections_when_insufficient_meals?: boolean, options?: any): AxiosPromise<PatientReportDataResponse> {
            return localVarFp.appApiPatientReportsApiPutPatientReportData(patient_id, hospital_id, clinic_id, patient_report_id, UpdatePatientReportDataRequest, restrict_sections_when_insufficient_meals, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all active subscriptions
         * @param {string} expiration_date 
         * @param {'single' | 'weekly' | 'monthly'} subscription_type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsAutomationGetReportableSubscriptionsByType(expiration_date: string, subscription_type: 'single' | 'weekly' | 'monthly', options?: any): AxiosPromise<Array<PatientSubscriptionResponse>> {
            return localVarFp.appApiPatientReportsAutomationGetReportableSubscriptionsByType(expiration_date, subscription_type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Query the patients detailed usage between the specified dates
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsDetailedPatientUsageLogGetDetailedPatientUsageLog(patient_id: number, date_since: string, date_until: string, options?: any): AxiosPromise<DetailedPatientUsageLogResponse> {
            return localVarFp.appApiPatientReportsDetailedPatientUsageLogGetDetailedPatientUsageLog(patient_id, date_since, date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Print foods that are highest in healthy vs unhealthy fats
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientEmailReportFatsReport(patient_id: number, date_since: string, date_until: string, options?: any): AxiosPromise<FatResponse> {
            return localVarFp.appApiPatientReportsPatientEmailReportFatsReport(patient_id, date_since, date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete patient report with patient_report_id
         * @param {number} patient_id 
         * @param {number} patient_report_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportDeletePatientReport(patient_id: number, patient_report_id: number, hospital_id: number, clinic_id: number, options?: any): AxiosPromise<void> {
            return localVarFp.appApiPatientReportsPatientReportDeletePatientReport(patient_id, patient_report_id, hospital_id, clinic_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all active patient reports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportGetActivePatientsReports(options?: any): AxiosPromise<Array<PatientReportResponse>> {
            return localVarFp.appApiPatientReportsPatientReportGetActivePatientsReports(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get patient report with patient_report_id
         * @param {number} patient_id 
         * @param {number} patient_report_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportGetPatientReport(patient_id: number, patient_report_id: number, hospital_id: number, clinic_id: number, options?: any): AxiosPromise<PatientReportResponse> {
            return localVarFp.appApiPatientReportsPatientReportGetPatientReport(patient_id, patient_report_id, hospital_id, clinic_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get patient report data
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportGetPatientReportData(patient_id: number, hospital_id: number, clinic_id: number, patient_report_id: number, options?: any): AxiosPromise<PatientReportDataResponse> {
            return localVarFp.appApiPatientReportsPatientReportGetPatientReportData(patient_id, hospital_id, clinic_id, patient_report_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get patient report html with patient_report_id by report_section
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportGetPatientReportHtml(patient_report_id: number, options?: any): AxiosPromise<string> {
            return localVarFp.appApiPatientReportsPatientReportGetPatientReportHtml(patient_report_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user patient report plots contents by id
         * @param {number} patient_report_id 
         * @param {number} patient_report_image_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportGetPatientReportImageById(patient_report_id: number, patient_report_image_id: number, options?: any): AxiosPromise<void> {
            return localVarFp.appApiPatientReportsPatientReportGetPatientReportImageById(patient_report_id, patient_report_image_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get patient report image with patient_report_id by image name
         * @param {number} patient_report_id 
         * @param {string} image_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportGetPatientReportImageByName(patient_report_id: number, image_name: string, options?: any): AxiosPromise<any> {
            return localVarFp.appApiPatientReportsPatientReportGetPatientReportImageByName(patient_report_id, image_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the patients report in PDF
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportGetPatientReportPdf(patient_report_id: number, options?: any): AxiosPromise<any> {
            return localVarFp.appApiPatientReportsPatientReportGetPatientReportPdf(patient_report_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get patient report images with patient_report_id by report_section
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportGetPatientReportPhotoHistory(patient_report_id: number, options?: any): AxiosPromise<Array<PatientReportImageResponse>> {
            return localVarFp.appApiPatientReportsPatientReportGetPatientReportPhotoHistory(patient_report_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of the patient reports from the date range provided
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportGetPatientReports(patient_id: number, hospital_id: number, clinic_id: number, date_since: string, date_until: string, options?: any): AxiosPromise<Array<PatientReportResponse>> {
            return localVarFp.appApiPatientReportsPatientReportGetPatientReports(patient_id, hospital_id, clinic_id, date_since, date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get patient report data
         * @param {number} patient_report_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportGetReportData(patient_report_id: number, options?: any): AxiosPromise<any> {
            return localVarFp.appApiPatientReportsPatientReportGetReportData(patient_report_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new daily report for an active patient if they logged a meal
         * @param {number} patient_id 
         * @param {string} report_date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportPostDailyPatientReport(patient_id: number, report_date: string, options?: any): AxiosPromise<DailyReportResponse> {
            return localVarFp.appApiPatientReportsPatientReportPostDailyPatientReport(patient_id, report_date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new daily report for all active patients that logged a meal
         * @param {string} report_date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportPostDailyPatientReports(report_date: string, options?: any): AxiosPromise<void> {
            return localVarFp.appApiPatientReportsPatientReportPostDailyPatientReports(report_date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update patient report with patient_report_id
         * @param {number} patient_id 
         * @param {number} patient_report_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {UpdatePatientReportRequest} UpdatePatientReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientReportPutPatientReport(patient_id: number, patient_report_id: number, hospital_id: number, clinic_id: number, UpdatePatientReportRequest: UpdatePatientReportRequest, options?: any): AxiosPromise<PatientReportResponse> {
            return localVarFp.appApiPatientReportsPatientReportPutPatientReport(patient_id, patient_report_id, hospital_id, clinic_id, UpdatePatientReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send daily patient report for individual patient
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {Array<string>} [nutrients] 
         * @param {Array<string>} [cc_columns] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPreReportGetPatientPreReportDataReview(patient_id: number, date_since: string, date_until: string, nutrients?: Array<string>, cc_columns?: Array<string>, options?: any): AxiosPromise<any> {
            return localVarFp.appApiPatientReportsPreReportGetPatientPreReportDataReview(patient_id, date_since, date_until, nutrients, cc_columns, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patient report report.
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {Array<number>} [patient_ids] 
         * @param {Array<number>} [report_ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsReportMetricsGetPatientOutcomeTrackingReport(date_since: string, date_until: string, patient_ids?: Array<number>, report_ids?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.appApiPatientReportsReportMetricsGetPatientOutcomeTrackingReport(date_since, date_until, patient_ids, report_ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary generate pdf report from html zip file
         * @param {any} file zip file containing report data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPdfGeneratePdf(file: any, options?: any): AxiosPromise<any> {
            return localVarFp.appApiPdfGeneratePdf(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send daily patient report for individual patient
         * @param {number} patient_id 
         * @param {string} report_date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportingSendDailyReportEmail(patient_id: number, report_date: string, options?: any): AxiosPromise<void> {
            return localVarFp.appApiReportingSendDailyReportEmail(patient_id, report_date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send daily patient report
         * @param {string} report_date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportingSendDailyReportEmails(report_date: string, options?: any): AxiosPromise<void> {
            return localVarFp.appApiReportingSendDailyReportEmails(report_date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get meal report for a patient
         * @param {number} data_reviewer_id 
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} [disaggregated] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsDataReviewerMealReportGetDataReviewerPatientMealReport(data_reviewer_id: number, patient_id: number, date_since: string, date_until: string, disaggregated?: boolean, options?: any): AxiosPromise<any> {
            return localVarFp.appApiReportsDataReviewerMealReportGetDataReviewerPatientMealReport(data_reviewer_id, patient_id, date_since, date_until, disaggregated, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patient section export report (MIND, Med, DASH, Diet Quality).
         * @param {Array<number>} patient_ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsReportMetricsGetCustomReportSectionsExport(patient_ids: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.appApiReportsReportMetricsGetCustomReportSectionsExport(patient_ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patient section export report (MIND, Med, DASH, Diet Quality).
         * @param {Array<number>} patient_ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsReportMetricsGetCustomReportSectionsExportCsv(patient_ids: Array<number>, options?: any): AxiosPromise<string> {
            return localVarFp.appApiReportsReportMetricsGetCustomReportSectionsExportCsv(patient_ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patient section export weekly report (MIND, Med, DASH, Diet Quality).
         * @param {Array<number>} patient_ids 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsReportMetricsGetWeeklyCustomReportSectionsExportCsv(patient_ids: Array<number>, date_since: string, date_until: string, options?: any): AxiosPromise<string> {
            return localVarFp.appApiReportsReportMetricsGetWeeklyCustomReportSectionsExportCsv(patient_ids, date_since, date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Report email for nlp
         * @param {string} meal_ids 
         * @param {string} [user_id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsSpacyDailyReportGetMealItemInfo(meal_ids: string, user_id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.appApiReportsSpacyDailyReportGetMealItemInfo(meal_ids, user_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate all reports to be generated for the reference date. Reference date normally would be be today + 1 (days in advance) by default. Use report_dates_only to consider ONLY report dates.
         * @param {string} reference_date 
         * @param {number} [days_in_advance] 
         * @param {boolean} [queue_for_generation] 
         * @param {boolean} [report_dates_only] 
         * @param {Array<number>} [patient_ids_to_ignore] list of patient_ids to ignore
         * @param {boolean} [send_email_report] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionReportPostPatientReportAutoGeneration(reference_date: string, days_in_advance?: number, queue_for_generation?: boolean, report_dates_only?: boolean, patient_ids_to_ignore?: Array<number>, send_email_report?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.appApiSubscriptionReportPostPatientReportAutoGeneration(reference_date, days_in_advance, queue_for_generation, report_dates_only, patient_ids_to_ignore, send_email_report, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get report on subscriptions due a report. Reference date is report_date minus one. Date since default is 21 days prior and date until 7 days after.
         * @param {string} reference_date 
         * @param {string} [date_since] 
         * @param {string} [date_until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionReportPostSubscriptionReport(reference_date: string, date_since?: string, date_until?: string, options?: any): AxiosPromise<void> {
            return localVarFp.appApiSubscriptionReportPostSubscriptionReport(reference_date, date_since, date_until, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiMealFastingGetFastingData operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiMealFastingGetFastingDataRequest
 */
export interface ReportingApiAppApiMealFastingGetFastingDataRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiMealFastingGetFastingData
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiMealFastingGetFastingData
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiMealFastingGetFastingData
     */
    readonly date_until: string

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiMealFastingGetFastingData
     */
    readonly ignore_fasting_entries?: boolean
}

/**
 * Request parameters for appApiMealPhotoGetQueuePhotosZip operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiMealPhotoGetQueuePhotosZipRequest
 */
export interface ReportingApiAppApiMealPhotoGetQueuePhotosZipRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiMealPhotoGetQueuePhotosZip
     */
    readonly reference_date: string

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiMealPhotoGetQueuePhotosZip
     */
    readonly hi_res?: boolean
}

/**
 * Request parameters for appApiMealPhotoPostMealPhotosByIdZip operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiMealPhotoPostMealPhotosByIdZipRequest
 */
export interface ReportingApiAppApiMealPhotoPostMealPhotosByIdZipRequest {
    /**
     * 
     * @type {AppApiMealPhotoPostMealPhotosByIdZipRequest}
     * @memberof ReportingApiAppApiMealPhotoPostMealPhotosByIdZip
     */
    readonly AppApiMealPhotoPostMealPhotosByIdZipRequest: AppApiMealPhotoPostMealPhotosByIdZipRequest
}

/**
 * Request parameters for appApiMetricsLongevityScoreGetLongevityScore operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiMetricsLongevityScoreGetLongevityScoreRequest
 */
export interface ReportingApiAppApiMetricsLongevityScoreGetLongevityScoreRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiMetricsLongevityScoreGetLongevityScore
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiMetricsLongevityScoreGetLongevityScore
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiMetricsLongevityScoreGetLongevityScore
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiPatientGetRegisteredNonLoggingPatients operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientGetRegisteredNonLoggingPatientsRequest
 */
export interface ReportingApiAppApiPatientGetRegisteredNonLoggingPatientsRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientGetRegisteredNonLoggingPatients
     */
    readonly reference_date: string

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientGetRegisteredNonLoggingPatients
     */
    readonly days_since_registration: number
}

/**
 * Request parameters for appApiPatientPostPatientInvitationReport operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientPostPatientInvitationReportRequest
 */
export interface ReportingApiAppApiPatientPostPatientInvitationReportRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientPostPatientInvitationReport
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientPostPatientInvitationReport
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiPatientPostPatientReportMetrics operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientPostPatientReportMetricsRequest
 */
export interface ReportingApiAppApiPatientPostPatientReportMetricsRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientPostPatientReportMetrics
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientPostPatientReportMetrics
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiPatientReportsApiDeleteFoodReport operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiDeleteFoodReportRequest
 */
export interface ReportingApiAppApiPatientReportsApiDeleteFoodReportRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiDeleteFoodReport
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiDeleteFoodReport
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiDeleteFoodReport
     */
    readonly clinic_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiDeleteFoodReport
     */
    readonly patient_report_id: number
}

/**
 * Request parameters for appApiPatientReportsApiGetClinicianFoodReportsDraft operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiGetClinicianFoodReportsDraftRequest
 */
export interface ReportingApiAppApiPatientReportsApiGetClinicianFoodReportsDraftRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetClinicianFoodReportsDraft
     */
    readonly clinician_id: number

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsApiGetClinicianFoodReportsDraft
     */
    readonly date_since?: string

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsApiGetClinicianFoodReportsDraft
     */
    readonly date_until?: string
}

/**
 * Request parameters for appApiPatientReportsApiGetFoodReport operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiGetFoodReportRequest
 */
export interface ReportingApiAppApiPatientReportsApiGetFoodReportRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReport
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReport
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReport
     */
    readonly clinic_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReport
     */
    readonly patient_report_id: number
}

/**
 * Request parameters for appApiPatientReportsApiGetFoodReportDataPreview operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiGetFoodReportDataPreviewRequest
 */
export interface ReportingApiAppApiPatientReportsApiGetFoodReportDataPreviewRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReportDataPreview
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReportDataPreview
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReportDataPreview
     */
    readonly clinic_id: number

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReportDataPreview
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReportDataPreview
     */
    readonly date_until: string

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReportDataPreview
     */
    readonly comparison_report_id?: number

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReportDataPreview
     */
    readonly use_multipliers?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReportDataPreview
     */
    readonly override_cache?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReportDataPreview
     */
    readonly sections?: Array<string>
}

/**
 * Request parameters for appApiPatientReportsApiGetFoodReportPayload operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiGetFoodReportPayloadRequest
 */
export interface ReportingApiAppApiPatientReportsApiGetFoodReportPayloadRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReportPayload
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReportPayload
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReportPayload
     */
    readonly clinic_id: number

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReportPayload
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReportPayload
     */
    readonly date_until: string

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReportPayload
     */
    readonly comparison_report_id?: number

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReportPayload
     */
    readonly use_multipliers?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReportPayload
     */
    readonly sections?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReportPayload
     */
    readonly anonymize?: boolean
}

/**
 * Request parameters for appApiPatientReportsApiGetFoodReports operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiGetFoodReportsRequest
 */
export interface ReportingApiAppApiPatientReportsApiGetFoodReportsRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReports
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReports
     */
    readonly clinic_id: number

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReports
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsApiGetFoodReports
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiPatientReportsApiGetPatientMobileNutritionInsights operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiGetPatientMobileNutritionInsightsRequest
 */
export interface ReportingApiAppApiPatientReportsApiGetPatientMobileNutritionInsightsRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientMobileNutritionInsights
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientMobileNutritionInsights
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientMobileNutritionInsights
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiPatientReportsApiGetPatientNutritionDetailsTable operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiGetPatientNutritionDetailsTableRequest
 */
export interface ReportingApiAppApiPatientReportsApiGetPatientNutritionDetailsTableRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientNutritionDetailsTable
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientNutritionDetailsTable
     */
    readonly group_by?: string

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientNutritionDetailsTable
     */
    readonly filter?: string

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientNutritionDetailsTable
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientNutritionDetailsTable
     */
    readonly offset?: string

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientNutritionDetailsTable
     */
    readonly order?: string
}

/**
 * Request parameters for appApiPatientReportsApiGetPatientReport operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiGetPatientReportRequest
 */
export interface ReportingApiAppApiPatientReportsApiGetPatientReportRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientReport
     */
    readonly patient_report_id: number
}

/**
 * Request parameters for appApiPatientReportsApiGetPatientReportData operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiGetPatientReportDataRequest
 */
export interface ReportingApiAppApiPatientReportsApiGetPatientReportDataRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientReportData
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientReportData
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientReportData
     */
    readonly clinic_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientReportData
     */
    readonly patient_report_id: number
}

/**
 * Request parameters for appApiPatientReportsApiGetPatientReportDraftPdf operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiGetPatientReportDraftPdfRequest
 */
export interface ReportingApiAppApiPatientReportsApiGetPatientReportDraftPdfRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientReportDraftPdf
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientReportDraftPdf
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientReportDraftPdf
     */
    readonly clinic_id: number

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientReportDraftPdf
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientReportDraftPdf
     */
    readonly date_until: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientReportDraftPdf
     */
    readonly dates_exclude?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientReportDraftPdf
     */
    readonly is_comparison_report?: boolean

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientReportDraftPdf
     */
    readonly comparison_report_id?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientReportDraftPdf
     */
    readonly sections?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientReportDraftPdf
     */
    readonly use_multipliers?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientReportDraftPdf
     */
    readonly anonymize?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientReportDraftPdf
     */
    readonly override_cache?: boolean
}

/**
 * Request parameters for appApiPatientReportsApiGetPatientReportTable operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiGetPatientReportTableRequest
 */
export interface ReportingApiAppApiPatientReportsApiGetPatientReportTableRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientReportTable
     */
    readonly filter?: string

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientReportTable
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientReportTable
     */
    readonly offset?: string

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientReportTable
     */
    readonly order?: string
}

/**
 * Request parameters for appApiPatientReportsApiGetPatientRxfoodReportSecureLink operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiGetPatientRxfoodReportSecureLinkRequest
 */
export interface ReportingApiAppApiPatientReportsApiGetPatientRxfoodReportSecureLinkRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientRxfoodReportSecureLink
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientRxfoodReportSecureLink
     */
    readonly patient_report_id: number
}

/**
 * Request parameters for appApiPatientReportsApiGetPatientRxfoodReportSecureLinks operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiGetPatientRxfoodReportSecureLinksRequest
 */
export interface ReportingApiAppApiPatientReportsApiGetPatientRxfoodReportSecureLinksRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetPatientRxfoodReportSecureLinks
     */
    readonly patient_id: number
}

/**
 * Request parameters for appApiPatientReportsApiGetPublicPatientPrescriptionReport operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiGetPublicPatientPrescriptionReportRequest
 */
export interface ReportingApiAppApiPatientReportsApiGetPublicPatientPrescriptionReportRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetPublicPatientPrescriptionReport
     */
    readonly user_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetPublicPatientPrescriptionReport
     */
    readonly patient_report_id: number

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsApiGetPublicPatientPrescriptionReport
     */
    readonly secure_payload: string
}

/**
 * Request parameters for appApiPatientReportsApiGetReportEngineSectionMappings operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiGetReportEngineSectionMappingsRequest
 */
export interface ReportingApiAppApiPatientReportsApiGetReportEngineSectionMappingsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportingApiAppApiPatientReportsApiGetReportEngineSectionMappings
     */
    readonly sections: Array<string>
}

/**
 * Request parameters for appApiPatientReportsApiGetRxfoodReportSecureDownload operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiGetRxfoodReportSecureDownloadRequest
 */
export interface ReportingApiAppApiPatientReportsApiGetRxfoodReportSecureDownloadRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetRxfoodReportSecureDownload
     */
    readonly user_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetRxfoodReportSecureDownload
     */
    readonly patient_report_id: number

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsApiGetRxfoodReportSecureDownload
     */
    readonly secure_payload: string
}

/**
 * Request parameters for appApiPatientReportsApiGetSendPatientPublicReportLink operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiGetSendPatientPublicReportLinkRequest
 */
export interface ReportingApiAppApiPatientReportsApiGetSendPatientPublicReportLinkRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiGetSendPatientPublicReportLink
     */
    readonly patient_report_id: number

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiPatientReportsApiGetSendPatientPublicReportLink
     */
    readonly to_patient: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiPatientReportsApiGetSendPatientPublicReportLink
     */
    readonly to_admin: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiPatientReportsApiGetSendPatientPublicReportLink
     */
    readonly is_demo?: boolean
}

/**
 * Request parameters for appApiPatientReportsApiPostApproveFoodReport operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiPostApproveFoodReportRequest
 */
export interface ReportingApiAppApiPatientReportsApiPostApproveFoodReportRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiPostApproveFoodReport
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiPostApproveFoodReport
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiPostApproveFoodReport
     */
    readonly clinic_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiPostApproveFoodReport
     */
    readonly patient_report_id: number
}

/**
 * Request parameters for appApiPatientReportsApiPostFoodReport operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiPostFoodReportRequest
 */
export interface ReportingApiAppApiPatientReportsApiPostFoodReportRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiPostFoodReport
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiPostFoodReport
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiPostFoodReport
     */
    readonly clinic_id: number

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsApiPostFoodReport
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsApiPostFoodReport
     */
    readonly date_until: string

    /**
     * 
     * @type {'draft' | 'sent'}
     * @memberof ReportingApiAppApiPatientReportsApiPostFoodReport
     */
    readonly status: 'draft' | 'sent'

    /**
     * 
     * @type {Array<string>}
     * @memberof ReportingApiAppApiPatientReportsApiPostFoodReport
     */
    readonly dates_exclude?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiPatientReportsApiPostFoodReport
     */
    readonly is_comparison_report?: boolean

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiPostFoodReport
     */
    readonly comparison_report_id?: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiPostFoodReport
     */
    readonly patient_subscription_id?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof ReportingApiAppApiPatientReportsApiPostFoodReport
     */
    readonly sections?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiPatientReportsApiPostFoodReport
     */
    readonly as_background_task?: boolean
}

/**
 * Request parameters for appApiPatientReportsApiPostFoodReportSendByEmail operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiPostFoodReportSendByEmailRequest
 */
export interface ReportingApiAppApiPatientReportsApiPostFoodReportSendByEmailRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiPostFoodReportSendByEmail
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiPostFoodReportSendByEmail
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiPostFoodReportSendByEmail
     */
    readonly clinic_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiPostFoodReportSendByEmail
     */
    readonly patient_report_id: number

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiPatientReportsApiPostFoodReportSendByEmail
     */
    readonly to_patient: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiPatientReportsApiPostFoodReportSendByEmail
     */
    readonly to_clinician: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiPatientReportsApiPostFoodReportSendByEmail
     */
    readonly bcc_admin: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiPatientReportsApiPostFoodReportSendByEmail
     */
    readonly attach_report_pdf: boolean
}

/**
 * Request parameters for appApiPatientReportsApiPostRefreshPdf operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiPostRefreshPdfRequest
 */
export interface ReportingApiAppApiPatientReportsApiPostRefreshPdfRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiPostRefreshPdf
     */
    readonly patient_report_id: number
}

/**
 * Request parameters for appApiPatientReportsApiPostRegenerate operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiPostRegenerateRequest
 */
export interface ReportingApiAppApiPatientReportsApiPostRegenerateRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiPostRegenerate
     */
    readonly patient_report_id: number

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiPatientReportsApiPostRegenerate
     */
    readonly filter_meal_dates?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiPatientReportsApiPostRegenerate
     */
    readonly as_background_task?: boolean
}

/**
 * Request parameters for appApiPatientReportsApiPutFoodReport operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiPutFoodReportRequest
 */
export interface ReportingApiAppApiPatientReportsApiPutFoodReportRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiPutFoodReport
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiPutFoodReport
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiPutFoodReport
     */
    readonly clinic_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiPutFoodReport
     */
    readonly patient_report_id: number

    /**
     * 
     * @type {'draft' | 'sent'}
     * @memberof ReportingApiAppApiPatientReportsApiPutFoodReport
     */
    readonly status: 'draft' | 'sent'
}

/**
 * Request parameters for appApiPatientReportsApiPutPatientReportData operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsApiPutPatientReportDataRequest
 */
export interface ReportingApiAppApiPatientReportsApiPutPatientReportDataRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiPutPatientReportData
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiPutPatientReportData
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiPutPatientReportData
     */
    readonly clinic_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsApiPutPatientReportData
     */
    readonly patient_report_id: number

    /**
     * 
     * @type {UpdatePatientReportDataRequest}
     * @memberof ReportingApiAppApiPatientReportsApiPutPatientReportData
     */
    readonly UpdatePatientReportDataRequest: UpdatePatientReportDataRequest

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiPatientReportsApiPutPatientReportData
     */
    readonly restrict_sections_when_insufficient_meals?: boolean
}

/**
 * Request parameters for appApiPatientReportsAutomationGetReportableSubscriptionsByType operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsAutomationGetReportableSubscriptionsByTypeRequest
 */
export interface ReportingApiAppApiPatientReportsAutomationGetReportableSubscriptionsByTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsAutomationGetReportableSubscriptionsByType
     */
    readonly expiration_date: string

    /**
     * 
     * @type {'single' | 'weekly' | 'monthly'}
     * @memberof ReportingApiAppApiPatientReportsAutomationGetReportableSubscriptionsByType
     */
    readonly subscription_type: 'single' | 'weekly' | 'monthly'
}

/**
 * Request parameters for appApiPatientReportsDetailedPatientUsageLogGetDetailedPatientUsageLog operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsDetailedPatientUsageLogGetDetailedPatientUsageLogRequest
 */
export interface ReportingApiAppApiPatientReportsDetailedPatientUsageLogGetDetailedPatientUsageLogRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsDetailedPatientUsageLogGetDetailedPatientUsageLog
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsDetailedPatientUsageLogGetDetailedPatientUsageLog
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsDetailedPatientUsageLogGetDetailedPatientUsageLog
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiPatientReportsPatientEmailReportFatsReport operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsPatientEmailReportFatsReportRequest
 */
export interface ReportingApiAppApiPatientReportsPatientEmailReportFatsReportRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientEmailReportFatsReport
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsPatientEmailReportFatsReport
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsPatientEmailReportFatsReport
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiPatientReportsPatientReportDeletePatientReport operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsPatientReportDeletePatientReportRequest
 */
export interface ReportingApiAppApiPatientReportsPatientReportDeletePatientReportRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportDeletePatientReport
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportDeletePatientReport
     */
    readonly patient_report_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportDeletePatientReport
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportDeletePatientReport
     */
    readonly clinic_id: number
}

/**
 * Request parameters for appApiPatientReportsPatientReportGetPatientReport operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsPatientReportGetPatientReportRequest
 */
export interface ReportingApiAppApiPatientReportsPatientReportGetPatientReportRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportGetPatientReport
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportGetPatientReport
     */
    readonly patient_report_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportGetPatientReport
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportGetPatientReport
     */
    readonly clinic_id: number
}

/**
 * Request parameters for appApiPatientReportsPatientReportGetPatientReportData operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsPatientReportGetPatientReportDataRequest
 */
export interface ReportingApiAppApiPatientReportsPatientReportGetPatientReportDataRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportGetPatientReportData
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportGetPatientReportData
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportGetPatientReportData
     */
    readonly clinic_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportGetPatientReportData
     */
    readonly patient_report_id: number
}

/**
 * Request parameters for appApiPatientReportsPatientReportGetPatientReportHtml operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsPatientReportGetPatientReportHtmlRequest
 */
export interface ReportingApiAppApiPatientReportsPatientReportGetPatientReportHtmlRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportGetPatientReportHtml
     */
    readonly patient_report_id: number
}

/**
 * Request parameters for appApiPatientReportsPatientReportGetPatientReportImageById operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsPatientReportGetPatientReportImageByIdRequest
 */
export interface ReportingApiAppApiPatientReportsPatientReportGetPatientReportImageByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportGetPatientReportImageById
     */
    readonly patient_report_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportGetPatientReportImageById
     */
    readonly patient_report_image_id: number
}

/**
 * Request parameters for appApiPatientReportsPatientReportGetPatientReportImageByName operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsPatientReportGetPatientReportImageByNameRequest
 */
export interface ReportingApiAppApiPatientReportsPatientReportGetPatientReportImageByNameRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportGetPatientReportImageByName
     */
    readonly patient_report_id: number

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsPatientReportGetPatientReportImageByName
     */
    readonly image_name: string
}

/**
 * Request parameters for appApiPatientReportsPatientReportGetPatientReportPdf operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsPatientReportGetPatientReportPdfRequest
 */
export interface ReportingApiAppApiPatientReportsPatientReportGetPatientReportPdfRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportGetPatientReportPdf
     */
    readonly patient_report_id: number
}

/**
 * Request parameters for appApiPatientReportsPatientReportGetPatientReportPhotoHistory operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsPatientReportGetPatientReportPhotoHistoryRequest
 */
export interface ReportingApiAppApiPatientReportsPatientReportGetPatientReportPhotoHistoryRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportGetPatientReportPhotoHistory
     */
    readonly patient_report_id: number
}

/**
 * Request parameters for appApiPatientReportsPatientReportGetPatientReports operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsPatientReportGetPatientReportsRequest
 */
export interface ReportingApiAppApiPatientReportsPatientReportGetPatientReportsRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportGetPatientReports
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportGetPatientReports
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportGetPatientReports
     */
    readonly clinic_id: number

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsPatientReportGetPatientReports
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsPatientReportGetPatientReports
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiPatientReportsPatientReportGetReportData operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsPatientReportGetReportDataRequest
 */
export interface ReportingApiAppApiPatientReportsPatientReportGetReportDataRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportGetReportData
     */
    readonly patient_report_id: number
}

/**
 * Request parameters for appApiPatientReportsPatientReportPostDailyPatientReport operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsPatientReportPostDailyPatientReportRequest
 */
export interface ReportingApiAppApiPatientReportsPatientReportPostDailyPatientReportRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportPostDailyPatientReport
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsPatientReportPostDailyPatientReport
     */
    readonly report_date: string
}

/**
 * Request parameters for appApiPatientReportsPatientReportPostDailyPatientReports operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsPatientReportPostDailyPatientReportsRequest
 */
export interface ReportingApiAppApiPatientReportsPatientReportPostDailyPatientReportsRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsPatientReportPostDailyPatientReports
     */
    readonly report_date: string
}

/**
 * Request parameters for appApiPatientReportsPatientReportPutPatientReport operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsPatientReportPutPatientReportRequest
 */
export interface ReportingApiAppApiPatientReportsPatientReportPutPatientReportRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportPutPatientReport
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportPutPatientReport
     */
    readonly patient_report_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportPutPatientReport
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPatientReportPutPatientReport
     */
    readonly clinic_id: number

    /**
     * 
     * @type {UpdatePatientReportRequest}
     * @memberof ReportingApiAppApiPatientReportsPatientReportPutPatientReport
     */
    readonly UpdatePatientReportRequest: UpdatePatientReportRequest
}

/**
 * Request parameters for appApiPatientReportsPreReportGetPatientPreReportDataReview operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsPreReportGetPatientPreReportDataReviewRequest
 */
export interface ReportingApiAppApiPatientReportsPreReportGetPatientPreReportDataReviewRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiPatientReportsPreReportGetPatientPreReportDataReview
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsPreReportGetPatientPreReportDataReview
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsPreReportGetPatientPreReportDataReview
     */
    readonly date_until: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ReportingApiAppApiPatientReportsPreReportGetPatientPreReportDataReview
     */
    readonly nutrients?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ReportingApiAppApiPatientReportsPreReportGetPatientPreReportDataReview
     */
    readonly cc_columns?: Array<string>
}

/**
 * Request parameters for appApiPatientReportsReportMetricsGetPatientOutcomeTrackingReport operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPatientReportsReportMetricsGetPatientOutcomeTrackingReportRequest
 */
export interface ReportingApiAppApiPatientReportsReportMetricsGetPatientOutcomeTrackingReportRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsReportMetricsGetPatientOutcomeTrackingReport
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiPatientReportsReportMetricsGetPatientOutcomeTrackingReport
     */
    readonly date_until: string

    /**
     * 
     * @type {Array<number>}
     * @memberof ReportingApiAppApiPatientReportsReportMetricsGetPatientOutcomeTrackingReport
     */
    readonly patient_ids?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ReportingApiAppApiPatientReportsReportMetricsGetPatientOutcomeTrackingReport
     */
    readonly report_ids?: Array<number>
}

/**
 * Request parameters for appApiPdfGeneratePdf operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiPdfGeneratePdfRequest
 */
export interface ReportingApiAppApiPdfGeneratePdfRequest {
    /**
     * zip file containing report data
     * @type {any}
     * @memberof ReportingApiAppApiPdfGeneratePdf
     */
    readonly file: any
}

/**
 * Request parameters for appApiReportingSendDailyReportEmail operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiReportingSendDailyReportEmailRequest
 */
export interface ReportingApiAppApiReportingSendDailyReportEmailRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiReportingSendDailyReportEmail
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiReportingSendDailyReportEmail
     */
    readonly report_date: string
}

/**
 * Request parameters for appApiReportingSendDailyReportEmails operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiReportingSendDailyReportEmailsRequest
 */
export interface ReportingApiAppApiReportingSendDailyReportEmailsRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiReportingSendDailyReportEmails
     */
    readonly report_date: string
}

/**
 * Request parameters for appApiReportsDataReviewerMealReportGetDataReviewerPatientMealReport operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiReportsDataReviewerMealReportGetDataReviewerPatientMealReportRequest
 */
export interface ReportingApiAppApiReportsDataReviewerMealReportGetDataReviewerPatientMealReportRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiReportsDataReviewerMealReportGetDataReviewerPatientMealReport
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiReportsDataReviewerMealReportGetDataReviewerPatientMealReport
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiReportsDataReviewerMealReportGetDataReviewerPatientMealReport
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiReportsDataReviewerMealReportGetDataReviewerPatientMealReport
     */
    readonly date_until: string

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiReportsDataReviewerMealReportGetDataReviewerPatientMealReport
     */
    readonly disaggregated?: boolean
}

/**
 * Request parameters for appApiReportsReportMetricsGetCustomReportSectionsExport operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiReportsReportMetricsGetCustomReportSectionsExportRequest
 */
export interface ReportingApiAppApiReportsReportMetricsGetCustomReportSectionsExportRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportingApiAppApiReportsReportMetricsGetCustomReportSectionsExport
     */
    readonly patient_ids: Array<number>
}

/**
 * Request parameters for appApiReportsReportMetricsGetCustomReportSectionsExportCsv operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiReportsReportMetricsGetCustomReportSectionsExportCsvRequest
 */
export interface ReportingApiAppApiReportsReportMetricsGetCustomReportSectionsExportCsvRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportingApiAppApiReportsReportMetricsGetCustomReportSectionsExportCsv
     */
    readonly patient_ids: Array<number>
}

/**
 * Request parameters for appApiReportsReportMetricsGetWeeklyCustomReportSectionsExportCsv operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiReportsReportMetricsGetWeeklyCustomReportSectionsExportCsvRequest
 */
export interface ReportingApiAppApiReportsReportMetricsGetWeeklyCustomReportSectionsExportCsvRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportingApiAppApiReportsReportMetricsGetWeeklyCustomReportSectionsExportCsv
     */
    readonly patient_ids: Array<number>

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiReportsReportMetricsGetWeeklyCustomReportSectionsExportCsv
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiReportsReportMetricsGetWeeklyCustomReportSectionsExportCsv
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiReportsSpacyDailyReportGetMealItemInfo operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiReportsSpacyDailyReportGetMealItemInfoRequest
 */
export interface ReportingApiAppApiReportsSpacyDailyReportGetMealItemInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiReportsSpacyDailyReportGetMealItemInfo
     */
    readonly meal_ids: string

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiReportsSpacyDailyReportGetMealItemInfo
     */
    readonly user_id?: string
}

/**
 * Request parameters for appApiSubscriptionReportPostPatientReportAutoGeneration operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiSubscriptionReportPostPatientReportAutoGenerationRequest
 */
export interface ReportingApiAppApiSubscriptionReportPostPatientReportAutoGenerationRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiSubscriptionReportPostPatientReportAutoGeneration
     */
    readonly reference_date: string

    /**
     * 
     * @type {number}
     * @memberof ReportingApiAppApiSubscriptionReportPostPatientReportAutoGeneration
     */
    readonly days_in_advance?: number

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiSubscriptionReportPostPatientReportAutoGeneration
     */
    readonly queue_for_generation?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiSubscriptionReportPostPatientReportAutoGeneration
     */
    readonly report_dates_only?: boolean

    /**
     * list of patient_ids to ignore
     * @type {Array<number>}
     * @memberof ReportingApiAppApiSubscriptionReportPostPatientReportAutoGeneration
     */
    readonly patient_ids_to_ignore?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof ReportingApiAppApiSubscriptionReportPostPatientReportAutoGeneration
     */
    readonly send_email_report?: boolean
}

/**
 * Request parameters for appApiSubscriptionReportPostSubscriptionReport operation in ReportingApi.
 * @export
 * @interface ReportingApiAppApiSubscriptionReportPostSubscriptionReportRequest
 */
export interface ReportingApiAppApiSubscriptionReportPostSubscriptionReportRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiSubscriptionReportPostSubscriptionReport
     */
    readonly reference_date: string

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiSubscriptionReportPostSubscriptionReport
     */
    readonly date_since?: string

    /**
     * 
     * @type {string}
     * @memberof ReportingApiAppApiSubscriptionReportPostSubscriptionReport
     */
    readonly date_until?: string
}

/**
 * ReportingApi - object-oriented interface
 * @export
 * @class ReportingApi
 * @extends {BaseAPI}
 */
export class ReportingApi extends BaseAPI {
    /**
     * 
     * @summary Query the patients detailed usage between the specified dates
     * @param {ReportingApiAppApiMealFastingGetFastingDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiMealFastingGetFastingData(requestParameters: ReportingApiAppApiMealFastingGetFastingDataRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiMealFastingGetFastingData(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.ignore_fasting_entries, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get patient meal images for queues on the given date. Max 50 photos returned.
     * @param {ReportingApiAppApiMealPhotoGetQueuePhotosZipRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiMealPhotoGetQueuePhotosZip(requestParameters: ReportingApiAppApiMealPhotoGetQueuePhotosZipRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiMealPhotoGetQueuePhotosZip(requestParameters.reference_date, requestParameters.hi_res, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get meal images indicated.
     * @param {ReportingApiAppApiMealPhotoPostMealPhotosByIdZipRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiMealPhotoPostMealPhotosByIdZip(requestParameters: ReportingApiAppApiMealPhotoPostMealPhotosByIdZipRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiMealPhotoPostMealPhotosByIdZip(requestParameters.AppApiMealPhotoPostMealPhotosByIdZipRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Query the patients detailed usage between the specified dates
     * @param {ReportingApiAppApiMetricsLongevityScoreGetLongevityScoreRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiMetricsLongevityScoreGetLongevityScore(requestParameters: ReportingApiAppApiMetricsLongevityScoreGetLongevityScoreRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiMetricsLongevityScoreGetLongevityScore(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of patients who are registered but not active (ie no meals)
     * @param {ReportingApiAppApiPatientGetRegisteredNonLoggingPatientsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientGetRegisteredNonLoggingPatients(requestParameters: ReportingApiAppApiPatientGetRegisteredNonLoggingPatientsRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientGetRegisteredNonLoggingPatients(requestParameters.reference_date, requestParameters.days_since_registration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patient invitation report
     * @param {ReportingApiAppApiPatientPostPatientInvitationReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientPostPatientInvitationReport(requestParameters: ReportingApiAppApiPatientPostPatientInvitationReportRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientPostPatientInvitationReport(requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get currently logged in patient info
     * @param {ReportingApiAppApiPatientPostPatientReportMetricsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientPostPatientReportMetrics(requestParameters: ReportingApiAppApiPatientPostPatientReportMetricsRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientPostPatientReportMetrics(requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a patient report.
     * @param {ReportingApiAppApiPatientReportsApiDeleteFoodReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiDeleteFoodReport(requestParameters: ReportingApiAppApiPatientReportsApiDeleteFoodReportRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiDeleteFoodReport(requestParameters.patient_id, requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.patient_report_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of the draft patients reports for review
     * @param {ReportingApiAppApiPatientReportsApiGetClinicianFoodReportsDraftRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiGetClinicianFoodReportsDraft(requestParameters: ReportingApiAppApiPatientReportsApiGetClinicianFoodReportsDraftRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiGetClinicianFoodReportsDraft(requestParameters.clinician_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the patient report details
     * @param {ReportingApiAppApiPatientReportsApiGetFoodReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiGetFoodReport(requestParameters: ReportingApiAppApiPatientReportsApiGetFoodReportRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiGetFoodReport(requestParameters.patient_id, requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.patient_report_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Query the patients detailed usage between the specified dates
     * @param {ReportingApiAppApiPatientReportsApiGetFoodReportDataPreviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiGetFoodReportDataPreview(requestParameters: ReportingApiAppApiPatientReportsApiGetFoodReportDataPreviewRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiGetFoodReportDataPreview(requestParameters.patient_id, requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.date_since, requestParameters.date_until, requestParameters.comparison_report_id, requestParameters.use_multipliers, requestParameters.override_cache, requestParameters.sections, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtain payload for a report given parameters provided.
     * @param {ReportingApiAppApiPatientReportsApiGetFoodReportPayloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiGetFoodReportPayload(requestParameters: ReportingApiAppApiPatientReportsApiGetFoodReportPayloadRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiGetFoodReportPayload(requestParameters.patient_id, requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.date_since, requestParameters.date_until, requestParameters.comparison_report_id, requestParameters.use_multipliers, requestParameters.sections, requestParameters.anonymize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve all food reports in a clinic for a specified time period
     * @param {ReportingApiAppApiPatientReportsApiGetFoodReportsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiGetFoodReports(requestParameters: ReportingApiAppApiPatientReportsApiGetFoodReportsRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiGetFoodReports(requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the PatientReportDataResponse used to show nutrition insights in the mobile app.
     * @param {ReportingApiAppApiPatientReportsApiGetPatientMobileNutritionInsightsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiGetPatientMobileNutritionInsights(requestParameters: ReportingApiAppApiPatientReportsApiGetPatientMobileNutritionInsightsRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiGetPatientMobileNutritionInsights(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generates an ApiTable report of nutrition details for a patient
     * @param {ReportingApiAppApiPatientReportsApiGetPatientNutritionDetailsTableRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiGetPatientNutritionDetailsTable(requestParameters: ReportingApiAppApiPatientReportsApiGetPatientNutritionDetailsTableRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiGetPatientNutritionDetailsTable(requestParameters.patient_id, requestParameters.group_by, requestParameters.filter, requestParameters.limit, requestParameters.offset, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a patient report by id
     * @param {ReportingApiAppApiPatientReportsApiGetPatientReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiGetPatientReport(requestParameters: ReportingApiAppApiPatientReportsApiGetPatientReportRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiGetPatientReport(requestParameters.patient_report_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get stored patient report data for specified report.
     * @param {ReportingApiAppApiPatientReportsApiGetPatientReportDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiGetPatientReportData(requestParameters: ReportingApiAppApiPatientReportsApiGetPatientReportDataRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiGetPatientReportData(requestParameters.patient_id, requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.patient_report_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Query the patients detailed usage between the specified dates
     * @param {ReportingApiAppApiPatientReportsApiGetPatientReportDraftPdfRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiGetPatientReportDraftPdf(requestParameters: ReportingApiAppApiPatientReportsApiGetPatientReportDraftPdfRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiGetPatientReportDraftPdf(requestParameters.patient_id, requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.date_since, requestParameters.date_until, requestParameters.dates_exclude, requestParameters.is_comparison_report, requestParameters.comparison_report_id, requestParameters.sections, requestParameters.use_multipliers, requestParameters.anonymize, requestParameters.override_cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Query the patients detailed usage between the specified dates
     * @param {ReportingApiAppApiPatientReportsApiGetPatientReportTableRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiGetPatientReportTable(requestParameters: ReportingApiAppApiPatientReportsApiGetPatientReportTableRequest = {}, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiGetPatientReportTable(requestParameters.filter, requestParameters.limit, requestParameters.offset, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a secure link for a patient report
     * @param {ReportingApiAppApiPatientReportsApiGetPatientRxfoodReportSecureLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiGetPatientRxfoodReportSecureLink(requestParameters: ReportingApiAppApiPatientReportsApiGetPatientRxfoodReportSecureLinkRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiGetPatientRxfoodReportSecureLink(requestParameters.patient_id, requestParameters.patient_report_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return secure report links
     * @param {ReportingApiAppApiPatientReportsApiGetPatientRxfoodReportSecureLinksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiGetPatientRxfoodReportSecureLinks(requestParameters: ReportingApiAppApiPatientReportsApiGetPatientRxfoodReportSecureLinksRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiGetPatientRxfoodReportSecureLinks(requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get currently secure copy of patient rxfood report. Time restricted.
     * @param {ReportingApiAppApiPatientReportsApiGetPublicPatientPrescriptionReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiGetPublicPatientPrescriptionReport(requestParameters: ReportingApiAppApiPatientReportsApiGetPublicPatientPrescriptionReportRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiGetPublicPatientPrescriptionReport(requestParameters.user_id, requestParameters.patient_report_id, requestParameters.secure_payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of updated report sections from V1 to latest version of the report engine
     * @param {ReportingApiAppApiPatientReportsApiGetReportEngineSectionMappingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiGetReportEngineSectionMappings(requestParameters: ReportingApiAppApiPatientReportsApiGetReportEngineSectionMappingsRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiGetReportEngineSectionMappings(requestParameters.sections, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get currently secure copy of patient rxfood report. Time restricted.
     * @param {ReportingApiAppApiPatientReportsApiGetRxfoodReportSecureDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiGetRxfoodReportSecureDownload(requestParameters: ReportingApiAppApiPatientReportsApiGetRxfoodReportSecureDownloadRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiGetRxfoodReportSecureDownload(requestParameters.user_id, requestParameters.patient_report_id, requestParameters.secure_payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Query the patients detailed usage between the specified dates
     * @param {ReportingApiAppApiPatientReportsApiGetSendPatientPublicReportLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiGetSendPatientPublicReportLink(requestParameters: ReportingApiAppApiPatientReportsApiGetSendPatientPublicReportLinkRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiGetSendPatientPublicReportLink(requestParameters.patient_report_id, requestParameters.to_patient, requestParameters.to_admin, requestParameters.is_demo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Approve a patient report (permits the error flags to be ignored)
     * @param {ReportingApiAppApiPatientReportsApiPostApproveFoodReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiPostApproveFoodReport(requestParameters: ReportingApiAppApiPatientReportsApiPostApproveFoodReportRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiPostApproveFoodReport(requestParameters.patient_id, requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.patient_report_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate and persist PDF report.
     * @param {ReportingApiAppApiPatientReportsApiPostFoodReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiPostFoodReport(requestParameters: ReportingApiAppApiPatientReportsApiPostFoodReportRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiPostFoodReport(requestParameters.patient_id, requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.date_since, requestParameters.date_until, requestParameters.status, requestParameters.dates_exclude, requestParameters.is_comparison_report, requestParameters.comparison_report_id, requestParameters.patient_subscription_id, requestParameters.sections, requestParameters.as_background_task, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deliver a copy of the patient report by email
     * @param {ReportingApiAppApiPatientReportsApiPostFoodReportSendByEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiPostFoodReportSendByEmail(requestParameters: ReportingApiAppApiPatientReportsApiPostFoodReportSendByEmailRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiPostFoodReportSendByEmail(requestParameters.patient_id, requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.patient_report_id, requestParameters.to_patient, requestParameters.to_clinician, requestParameters.bcc_admin, requestParameters.attach_report_pdf, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get patient report images with patient_report_id by report_section
     * @param {ReportingApiAppApiPatientReportsApiPostRefreshPdfRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiPostRefreshPdf(requestParameters: ReportingApiAppApiPatientReportsApiPostRefreshPdfRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiPostRefreshPdf(requestParameters.patient_report_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get patient report images with patient_report_id by report_section
     * @param {ReportingApiAppApiPatientReportsApiPostRegenerateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiPostRegenerate(requestParameters: ReportingApiAppApiPatientReportsApiPostRegenerateRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiPostRegenerate(requestParameters.patient_report_id, requestParameters.filter_meal_dates, requestParameters.as_background_task, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the patient report.
     * @param {ReportingApiAppApiPatientReportsApiPutFoodReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiPutFoodReport(requestParameters: ReportingApiAppApiPatientReportsApiPutFoodReportRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiPutFoodReport(requestParameters.patient_id, requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.patient_report_id, requestParameters.status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Modify patient report data for specified report.
     * @param {ReportingApiAppApiPatientReportsApiPutPatientReportDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsApiPutPatientReportData(requestParameters: ReportingApiAppApiPatientReportsApiPutPatientReportDataRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsApiPutPatientReportData(requestParameters.patient_id, requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.patient_report_id, requestParameters.UpdatePatientReportDataRequest, requestParameters.restrict_sections_when_insufficient_meals, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all active subscriptions
     * @param {ReportingApiAppApiPatientReportsAutomationGetReportableSubscriptionsByTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsAutomationGetReportableSubscriptionsByType(requestParameters: ReportingApiAppApiPatientReportsAutomationGetReportableSubscriptionsByTypeRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsAutomationGetReportableSubscriptionsByType(requestParameters.expiration_date, requestParameters.subscription_type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Query the patients detailed usage between the specified dates
     * @param {ReportingApiAppApiPatientReportsDetailedPatientUsageLogGetDetailedPatientUsageLogRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsDetailedPatientUsageLogGetDetailedPatientUsageLog(requestParameters: ReportingApiAppApiPatientReportsDetailedPatientUsageLogGetDetailedPatientUsageLogRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsDetailedPatientUsageLogGetDetailedPatientUsageLog(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Print foods that are highest in healthy vs unhealthy fats
     * @param {ReportingApiAppApiPatientReportsPatientEmailReportFatsReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsPatientEmailReportFatsReport(requestParameters: ReportingApiAppApiPatientReportsPatientEmailReportFatsReportRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsPatientEmailReportFatsReport(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete patient report with patient_report_id
     * @param {ReportingApiAppApiPatientReportsPatientReportDeletePatientReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsPatientReportDeletePatientReport(requestParameters: ReportingApiAppApiPatientReportsPatientReportDeletePatientReportRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsPatientReportDeletePatientReport(requestParameters.patient_id, requestParameters.patient_report_id, requestParameters.hospital_id, requestParameters.clinic_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all active patient reports
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsPatientReportGetActivePatientsReports(options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsPatientReportGetActivePatientsReports(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get patient report with patient_report_id
     * @param {ReportingApiAppApiPatientReportsPatientReportGetPatientReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsPatientReportGetPatientReport(requestParameters: ReportingApiAppApiPatientReportsPatientReportGetPatientReportRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsPatientReportGetPatientReport(requestParameters.patient_id, requestParameters.patient_report_id, requestParameters.hospital_id, requestParameters.clinic_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get patient report data
     * @param {ReportingApiAppApiPatientReportsPatientReportGetPatientReportDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsPatientReportGetPatientReportData(requestParameters: ReportingApiAppApiPatientReportsPatientReportGetPatientReportDataRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsPatientReportGetPatientReportData(requestParameters.patient_id, requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.patient_report_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get patient report html with patient_report_id by report_section
     * @param {ReportingApiAppApiPatientReportsPatientReportGetPatientReportHtmlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsPatientReportGetPatientReportHtml(requestParameters: ReportingApiAppApiPatientReportsPatientReportGetPatientReportHtmlRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsPatientReportGetPatientReportHtml(requestParameters.patient_report_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user patient report plots contents by id
     * @param {ReportingApiAppApiPatientReportsPatientReportGetPatientReportImageByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsPatientReportGetPatientReportImageById(requestParameters: ReportingApiAppApiPatientReportsPatientReportGetPatientReportImageByIdRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsPatientReportGetPatientReportImageById(requestParameters.patient_report_id, requestParameters.patient_report_image_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get patient report image with patient_report_id by image name
     * @param {ReportingApiAppApiPatientReportsPatientReportGetPatientReportImageByNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsPatientReportGetPatientReportImageByName(requestParameters: ReportingApiAppApiPatientReportsPatientReportGetPatientReportImageByNameRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsPatientReportGetPatientReportImageByName(requestParameters.patient_report_id, requestParameters.image_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve the patients report in PDF
     * @param {ReportingApiAppApiPatientReportsPatientReportGetPatientReportPdfRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsPatientReportGetPatientReportPdf(requestParameters: ReportingApiAppApiPatientReportsPatientReportGetPatientReportPdfRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsPatientReportGetPatientReportPdf(requestParameters.patient_report_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get patient report images with patient_report_id by report_section
     * @param {ReportingApiAppApiPatientReportsPatientReportGetPatientReportPhotoHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsPatientReportGetPatientReportPhotoHistory(requestParameters: ReportingApiAppApiPatientReportsPatientReportGetPatientReportPhotoHistoryRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsPatientReportGetPatientReportPhotoHistory(requestParameters.patient_report_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of the patient reports from the date range provided
     * @param {ReportingApiAppApiPatientReportsPatientReportGetPatientReportsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsPatientReportGetPatientReports(requestParameters: ReportingApiAppApiPatientReportsPatientReportGetPatientReportsRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsPatientReportGetPatientReports(requestParameters.patient_id, requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get patient report data
     * @param {ReportingApiAppApiPatientReportsPatientReportGetReportDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsPatientReportGetReportData(requestParameters: ReportingApiAppApiPatientReportsPatientReportGetReportDataRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsPatientReportGetReportData(requestParameters.patient_report_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new daily report for an active patient if they logged a meal
     * @param {ReportingApiAppApiPatientReportsPatientReportPostDailyPatientReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsPatientReportPostDailyPatientReport(requestParameters: ReportingApiAppApiPatientReportsPatientReportPostDailyPatientReportRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsPatientReportPostDailyPatientReport(requestParameters.patient_id, requestParameters.report_date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new daily report for all active patients that logged a meal
     * @param {ReportingApiAppApiPatientReportsPatientReportPostDailyPatientReportsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsPatientReportPostDailyPatientReports(requestParameters: ReportingApiAppApiPatientReportsPatientReportPostDailyPatientReportsRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsPatientReportPostDailyPatientReports(requestParameters.report_date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update patient report with patient_report_id
     * @param {ReportingApiAppApiPatientReportsPatientReportPutPatientReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsPatientReportPutPatientReport(requestParameters: ReportingApiAppApiPatientReportsPatientReportPutPatientReportRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsPatientReportPutPatientReport(requestParameters.patient_id, requestParameters.patient_report_id, requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.UpdatePatientReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send daily patient report for individual patient
     * @param {ReportingApiAppApiPatientReportsPreReportGetPatientPreReportDataReviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsPreReportGetPatientPreReportDataReview(requestParameters: ReportingApiAppApiPatientReportsPreReportGetPatientPreReportDataReviewRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsPreReportGetPatientPreReportDataReview(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.nutrients, requestParameters.cc_columns, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patient report report.
     * @param {ReportingApiAppApiPatientReportsReportMetricsGetPatientOutcomeTrackingReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPatientReportsReportMetricsGetPatientOutcomeTrackingReport(requestParameters: ReportingApiAppApiPatientReportsReportMetricsGetPatientOutcomeTrackingReportRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPatientReportsReportMetricsGetPatientOutcomeTrackingReport(requestParameters.date_since, requestParameters.date_until, requestParameters.patient_ids, requestParameters.report_ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary generate pdf report from html zip file
     * @param {ReportingApiAppApiPdfGeneratePdfRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiPdfGeneratePdf(requestParameters: ReportingApiAppApiPdfGeneratePdfRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiPdfGeneratePdf(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send daily patient report for individual patient
     * @param {ReportingApiAppApiReportingSendDailyReportEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiReportingSendDailyReportEmail(requestParameters: ReportingApiAppApiReportingSendDailyReportEmailRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiReportingSendDailyReportEmail(requestParameters.patient_id, requestParameters.report_date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send daily patient report
     * @param {ReportingApiAppApiReportingSendDailyReportEmailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiReportingSendDailyReportEmails(requestParameters: ReportingApiAppApiReportingSendDailyReportEmailsRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiReportingSendDailyReportEmails(requestParameters.report_date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get meal report for a patient
     * @param {ReportingApiAppApiReportsDataReviewerMealReportGetDataReviewerPatientMealReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiReportsDataReviewerMealReportGetDataReviewerPatientMealReport(requestParameters: ReportingApiAppApiReportsDataReviewerMealReportGetDataReviewerPatientMealReportRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiReportsDataReviewerMealReportGetDataReviewerPatientMealReport(requestParameters.data_reviewer_id, requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.disaggregated, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patient section export report (MIND, Med, DASH, Diet Quality).
     * @param {ReportingApiAppApiReportsReportMetricsGetCustomReportSectionsExportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiReportsReportMetricsGetCustomReportSectionsExport(requestParameters: ReportingApiAppApiReportsReportMetricsGetCustomReportSectionsExportRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiReportsReportMetricsGetCustomReportSectionsExport(requestParameters.patient_ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patient section export report (MIND, Med, DASH, Diet Quality).
     * @param {ReportingApiAppApiReportsReportMetricsGetCustomReportSectionsExportCsvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiReportsReportMetricsGetCustomReportSectionsExportCsv(requestParameters: ReportingApiAppApiReportsReportMetricsGetCustomReportSectionsExportCsvRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiReportsReportMetricsGetCustomReportSectionsExportCsv(requestParameters.patient_ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patient section export weekly report (MIND, Med, DASH, Diet Quality).
     * @param {ReportingApiAppApiReportsReportMetricsGetWeeklyCustomReportSectionsExportCsvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiReportsReportMetricsGetWeeklyCustomReportSectionsExportCsv(requestParameters: ReportingApiAppApiReportsReportMetricsGetWeeklyCustomReportSectionsExportCsvRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiReportsReportMetricsGetWeeklyCustomReportSectionsExportCsv(requestParameters.patient_ids, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Report email for nlp
     * @param {ReportingApiAppApiReportsSpacyDailyReportGetMealItemInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiReportsSpacyDailyReportGetMealItemInfo(requestParameters: ReportingApiAppApiReportsSpacyDailyReportGetMealItemInfoRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiReportsSpacyDailyReportGetMealItemInfo(requestParameters.meal_ids, requestParameters.user_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate all reports to be generated for the reference date. Reference date normally would be be today + 1 (days in advance) by default. Use report_dates_only to consider ONLY report dates.
     * @param {ReportingApiAppApiSubscriptionReportPostPatientReportAutoGenerationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiSubscriptionReportPostPatientReportAutoGeneration(requestParameters: ReportingApiAppApiSubscriptionReportPostPatientReportAutoGenerationRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiSubscriptionReportPostPatientReportAutoGeneration(requestParameters.reference_date, requestParameters.days_in_advance, requestParameters.queue_for_generation, requestParameters.report_dates_only, requestParameters.patient_ids_to_ignore, requestParameters.send_email_report, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get report on subscriptions due a report. Reference date is report_date minus one. Date since default is 21 days prior and date until 7 days after.
     * @param {ReportingApiAppApiSubscriptionReportPostSubscriptionReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appApiSubscriptionReportPostSubscriptionReport(requestParameters: ReportingApiAppApiSubscriptionReportPostSubscriptionReportRequest, options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).appApiSubscriptionReportPostSubscriptionReport(requestParameters.reference_date, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }
}
