import * as Sentry from '@sentry/react';
import { safeJson } from 'async-result/utils';
import axios, { AxiosResponse } from 'axios';
import { maybe, shorten } from 'food-editor/utils/utils';
import mixpanel from 'mixpanel-browser';
import { mkRandId } from 'utils/telemetry';

type ErrorOriginType = Error | AxiosResponse | Response;

function errorGetDescription(error: ErrorOriginType) {
  if (!error) {
    return `Unknown error: ${'' + error}`;
  }

  if (error instanceof Response) {
    return `HTTP: '${error.url}' failed with ${error.status}`;
  }

  if (axios.isAxiosError(error)) {
    const { config } = error;
    return `HTTP: '${config.method?.toUpperCase()} ${config.url}' failed with ${error.response?.status}`;
  }

  if (error instanceof Error) {
    return '' + error;
  }

  return `Unknown error (${(error as any)?.constructor?.name}): ${'' + error}`;
}

function errorGetMessage(error: ErrorOriginType) {
  if (error instanceof Response) {
    return error.statusText;
  }

  if (axios.isAxiosError(error)) {
    return (error.response?.data as any)?.message;
  }

  if (error instanceof Error) {
    return error.message;
  }

  return null;
}

function isSuspectedNetworkError(error: ErrorOriginType) {
  if (error instanceof Response) {
    // Network errors have status 0
    return !error.status;
  }

  if (axios.isAxiosError(error)) {
    // Network errors have status 0
    return !error.response?.status;
  }

  return false;
}

export function logTrackedError(opts: {
  sourceName: string,
  origin: ErrorOriginType,
  stackError: Error,
  context: Record<string, any>,
  userMessage: string,
  skipWindowAlert?: boolean,
}) {
  const { sourceName, origin, context, userMessage } = opts;
  const errId = mkRandId(6);
  asyncLogTrackedError({
    errId,
    ...opts,
  });

  if (!opts.skipWindowAlert) {
    window.alert(
      [
        userMessage,
        errorGetMessage(origin),
        isSuspectedNetworkError(origin) ? '\nTHIS MAY BE A PROBLEM WITH YOUR INTERNET CONNECTION.' : '',
        '-------------------------------------',
        `Error ID: ${errId} @ ${new Date().toISOString()}`,
        `Source: ${sourceName}`,
        `Origin: ${errorGetDescription(origin)}`,
        `(error has been tracked)`,
      ].filter(x => !!x).join('\n'),
    );
  }
}

const errorReadBody = async (origin: ErrorOriginType) => {
  if (origin instanceof Response) {
    return await origin.text();
  }

  if (axios.isAxiosError(origin)) {
    return JSON.stringify(origin.response?.data);
  }

  return null;
};

async function asyncLogTrackedError(opts: {
  errId: string,
  sourceName: string,
  origin: ErrorOriginType,
  stackError: Error,
  context: Record<string, any>,
  userMessage: string,
}) {
  const { sourceName, origin, stackError, context } = opts;
  const originDesc = errorGetDescription(origin);
  console.error(`Error '${opts.errId}' in ${sourceName}: ${originDesc}`);
  const message = errorGetMessage(origin);
  if (message) {
    console.error(`Message: ${message}`);
  }
  const [body, bodyErr] = await maybe(errorReadBody(origin));
  if (body) {
    console.error(`Body: ${shorten(body, 1000)}`);
  }
  if (bodyErr) {
    console.error(`Body error: ${bodyErr}`);
  }
  console.error(`Origin:`, origin);
  console.error('Context:', safeJson(context));
  if (origin && 'stack' in origin) {
    console.error('Origin stack:', origin.stack);
  }
  console.error('Socket telemery:', safeJson(window.RX_SOCKET_TELEMETRY_LAST_RESULT));

  const statusCode = origin instanceof Response
    ? origin.status
    : axios.isAxiosError(origin)
    ? origin.response?.status
    : undefined;

  const axiosCode = axios.isAxiosError(origin) ? origin.code : undefined;

  if (!isSuspectedNetworkError(origin)) {
    stackError.message = `Error in ${sourceName}: ${originDesc}`;
    Sentry.captureException(stackError, {
      extra: {
        errorId: opts.errId,
        context,
        sourceName,
        origin: originDesc,
        statusCode,
        axiosCode,
      },
    });
  }

  mixpanel.track('Auditor Tracked Error', {
    'Error ID': opts.errId,
    Source: sourceName,
    Origin: originDesc,
    Context: context,
    'Error class': origin?.constructor?.name,
    'Suspected network error': isSuspectedNetworkError(origin),
    'Status code': statusCode,
    'Axios code': axiosCode,
    'Network status': window.RX_SOCKET_TELEMETRY_LAST_RESULT,
  });
}
