import { CardMedia } from '@mui/material';
import { MealPhotoQueueResponse } from 'api/generated/MNT';
import { useAuthenticatedBlobQuery } from 'components/AuthenticatedImage';

import { ImageError } from 'components/ImageError';
import { getMealPhotoUrl } from 'pages/QueueItem/meal-builder/MealPhoto';

const mediaSX = {
  width: 90,
  height: 80,
  borderRadius: 1,
};

export const PreviewImage = (props: {
  queueItem: MealPhotoQueueResponse,
  size?: 'resized' | 'original',
}) => {
  const img = useAuthenticatedBlobQuery(getMealPhotoUrl(props.queueItem, props.size ?? 'original'));

  return (!img.src
    ? <ImageError sx={mediaSX} />
    : <CardMedia component="img" src={img.src} sx={mediaSX} />);
};
