import { overrideValuesToFoodEditorValue } from 'food-editor/api-client';
import { ALCOHOL_PERCENT_TO_G_RATIO, nutrientValueToStrOrEmpty } from 'food-editor/components/food-editor';
import { getComponentsTotalWeight } from 'food-editor/components/FoodComponentBuilder';
import _ from 'lodash';
import { FoodEditorFoodResponse, FoodEditorValue, ProteinTypeName } from '../types';
import { formatNumber } from './utils';

export const getFruitAndVegetableType = (
  foodResponse: Partial<FoodEditorFoodResponse>,
): 'fruit' | 'vegetable' | 'both' | '' => {
  if (foodResponse.fruit && foodResponse.vegetable) {
    return 'both';
  }
  if (foodResponse.fruit) {
    return 'fruit';
  }
  if (foodResponse.vegetable) {
    return 'vegetable';
  }
  return '';
};

export const getCarbohydrateType = (foodResponse: Partial<FoodEditorFoodResponse>): 'complex' | 'simple' | '' => {
  if (foodResponse.complexCarbs) {
    return 'complex';
  }
  if (foodResponse.simpleCarbs) {
    return 'simple';
  }
  return '';
};

const proteinAttrMap: { [K in keyof FoodEditorFoodResponse]?: ProteinTypeName } = {
  'redMeat': 'red_meat',
  'poultry': 'poultry',
  'pork': 'pork',
  'nonMeatAnimalProtein': 'non_meat_animal',
  'fish': 'fish',
  'shellfish': 'shellfish',
  'legume': 'legume',
  'plantProtein': 'plant',
  'meat': 'meat',
};

export const getProteinType = (foodResponse: Partial<FoodEditorFoodResponse>): ProteinTypeName[] => {
  return Object.entries(proteinAttrMap)
    .filter(([attr, _]) => !!(foodResponse as any)[attr])
    .map(([_, proteinType]) => proteinType as ProteinTypeName);
};

export const getBeverageType = (foodResponse: Partial<FoodEditorFoodResponse>): 'sugary' | 'non_sugary' | '' => {
  if (foodResponse.sugaryBeverage) {
    return 'sugary';
  }
  if (foodResponse.beverage) {
    return 'non_sugary';
  }
  return '';
};

export const getFatType = (foodResponse: Partial<FoodEditorFoodResponse>): 'healthy' | 'unhealthy' | 'both' | '' => {
  if (foodResponse.unhealthyFat) {
    return foodResponse.healthyFat ? 'both' : 'unhealthy';
  }
  if (foodResponse.healthyFat) {
    return 'healthy';
  }
  return '';
};

export const getGrainType = (foodResponse: Partial<FoodEditorFoodResponse>): 'whole' | 'refined' | '' => {
  if (foodResponse.wholeGrains) {
    return 'whole';
  }
  if (foodResponse.grains) {
    return 'refined';
  }
  return '';
};

export const getProcessedType = (
  foodResponse: Partial<FoodEditorFoodResponse>,
): 'whole_food' | 'processed' | 'ultra_processed' | '' => {
  if (foodResponse.wholeFood) {
    return 'whole_food';
  }
  if (foodResponse.ultraProcessed) {
    return 'ultra_processed';
  }
  if (foodResponse.processed) {
    return 'processed';
  }
  return '';
};

export const getPreparationType = (foodResponse: Partial<FoodEditorFoodResponse>): 'raw' | 'cooked' | '' => {
  if (foodResponse.cooked) {
    return 'cooked';
  }
  if (foodResponse.raw) {
    return 'raw';
  }
  return '';
};

const strOrEmpty = (n: number | null) => {
  if (n === null || n === undefined) {
    return '';
  }
  return '' + n;
};

export const foodResponseNutrientsToFoodEditorValue = (foodResponse: Partial<FoodEditorFoodResponse>) => {
  const nutrientValue = (n: number | null | undefined) => {
    return n ?? null;
  };

  return {
    energyKcal: nutrientValue(foodResponse.energyKcal),
    alcoholG: nutrientValue(foodResponse.alcoholG),
    fatG: nutrientValue(foodResponse.fatG),
    transFatG: nutrientValue(foodResponse.transFatG),
    satFatG: nutrientValue(foodResponse.satFatG),
    monoFatG: nutrientValue(foodResponse.monoFatG),
    polyFatG: nutrientValue(foodResponse.polyFatG),
    proteinG: nutrientValue(foodResponse.proteinG),
    carbohydrateG: nutrientValue(foodResponse.carbohydrateG),
    fibreG: nutrientValue(foodResponse.fibreG),
    polyolsG: nutrientValue(foodResponse.polyolsG),
    netcarbG: nutrientValue(foodResponse.netcarbG),
    calciumMg: nutrientValue(foodResponse.calciumMg),
    ironMg: nutrientValue(foodResponse.ironMg),
    magnesiumMg: nutrientValue(foodResponse.magnesiumMg),
    phosphorusMg: nutrientValue(foodResponse.phosphorusMg),
    potassiumMg: nutrientValue(foodResponse.potassiumMg),
    sodiumMg: nutrientValue(foodResponse.sodiumMg),
    zincMg: nutrientValue(foodResponse.zincMg),
    thiaminMg: nutrientValue(foodResponse.thiaminMg),
    riboflavinMg: nutrientValue(foodResponse.riboflavinMg),
    niacinMg: nutrientValue(foodResponse.niacinMg),
    folateDfeUg: nutrientValue(foodResponse.folateDfeUg),
    cholesterolMg: nutrientValue(foodResponse.cholesterolMg),
    vitaminAIu: nutrientValue(foodResponse.vitaminAIu),
    vitaminB6Mg: nutrientValue(foodResponse.vitaminB6Mg),
    vitaminB12Ug: nutrientValue(foodResponse.vitaminB12Ug),
    vitaminCMg: nutrientValue(foodResponse.vitaminCMg),
    vitaminDIu: nutrientValue(foodResponse.vitaminDIu),
    vitaminEMg: nutrientValue(foodResponse.vitaminEMg),
    vitaminKUg: nutrientValue(foodResponse.vitaminKUg),
    addedSugarsG: nutrientValue(foodResponse.addedSugarsG),
    sugarsG: nutrientValue(foodResponse.sugarsG),
    caffeineMg: nutrientValue(foodResponse.caffeineMg),
    cholineMg: nutrientValue(foodResponse.cholineMg),
    gramsPerCubicInch: nutrientValue(foodResponse.gramsPerCubicInch),
    insolubleDietaryFiberG: nutrientValue(foodResponse.insolubleDietaryFiberG),
    omegaThreeFattyAcidsG: nutrientValue(foodResponse.omegaThreeFattyAcidsG),
    pantothenicAcidMg: nutrientValue(foodResponse.pantothenicAcidMg),
    seleniumMcg: nutrientValue(foodResponse.seleniumMcg),
    solubleDietaryFiberG: nutrientValue(foodResponse.solubleDietaryFiberG),
    vitaminARaeUg: nutrientValue(foodResponse.vitaminARaeUg),
    vitaminD2d3Ug: nutrientValue(foodResponse.vitaminD2d3Ug),
    waterG: nutrientValue(foodResponse.waterG),
    alcoholPercent: nutrientValue((foodResponse.alcoholG ?? 0) / ALCOHOL_PERCENT_TO_G_RATIO),
  };
};

export const foodResponseCcToFoodEditorValue = (foodResponse: Partial<FoodEditorFoodResponse>) => {
  return {
    fruitAndVegetableType: getFruitAndVegetableType(foodResponse),
    carbohydrateType: getCarbohydrateType(foodResponse),
    proteinType: getProteinType(foodResponse),
    beverageType: getBeverageType(foodResponse),
    fatType: getFatType(foodResponse),
    grainType: getGrainType(foodResponse),
    processedType: getProcessedType(foodResponse),
    preparationType: getPreparationType(foodResponse),
    wholeFood: foodResponse.wholeFood || false,
    fried: foodResponse.fried || false,
    highCalcium: foodResponse.highCalcium || false,
    highFibre: foodResponse.highFibre || false,
    supplement: foodResponse.supplement || false,
    nutsOrSeeds: foodResponse.nutsOrSeeds || false,
    plantBased: foodResponse.plantBased || false,
    plantBasedWhole: foodResponse.plantBasedWhole || false,
    additives: foodResponse.additives || false,
    glutenFree: foodResponse.glutenFree || false,
    omegaThree: foodResponse.omegaThree || false,
  };
};

export default (foodResponse: FoodEditorFoodResponse): FoodEditorValue => {
  return {
    definitionType: foodResponse.definitionType,
    term: foodResponse.term,
    frenchTerm: foodResponse.frenchTerm || '',
    ndbNumber: foodResponse.ndbNumber || '',
    nutritionSourceId: foodResponse.nutritionSourceId!,
    status: foodResponse.status || '',
    foodImageUrl: foodResponse.foodImageUrl,
    ontologyLevels: foodResponse.ontologyLevels,
    baseFoodName: foodResponse.baseFoodName ?? '',
    rootFoodName: foodResponse.rootFoodName ?? '',
    usdaNutrientName: foodResponse.usdaNutrientName ?? '',
    otherNames: foodResponse.otherNames,
    synonyms: foodResponse.synonyms,
    singularTerm: foodResponse.singularTerm ?? '',
    pluralTerm: foodResponse.pluralTerm ?? '',
    customTip: foodResponse.customTip ?? '',
    nutritionSourceUrl: foodResponse.nutritionSourceUrl ?? null,
    fdcid: foodResponse.fdcid?.toString() ?? null,
    cnFoodId: foodResponse.cnFoodId?.toString() ?? null,
    notes: foodResponse.notes ?? null,
    preferredAddOn: foodResponse.preferredAddOn,

    ...foodResponseCcToFoodEditorValue(foodResponse),

    suggestedItem: foodResponse.suggestedItem,
    rankingUp: foodResponse.rankingUp == null
      ? ''
      : (foodResponse.rankingUp.toString() as any),
    rankingPb: foodResponse.rankingPb == null
      ? ''
      : (foodResponse.rankingPb.toString() as any),
    rankingHealthyFat: foodResponse.rankingHealthyFat == null
      ? ''
      : (foodResponse.rankingHealthyFat.toString() as any),
    rankingUnhealthyFat: foodResponse.rankingUnhealthyFat == null
      ? ''
      : (foodResponse.rankingUnhealthyFat.toString() as any),
    rankingBothFat: foodResponse.rankingBothFat == null
      ? ''
      : (foodResponse.rankingBothFat.toString() as any),
    rankingWholeGrains: foodResponse.rankingWholeGrains == null
      ? ''
      : (foodResponse.rankingWholeGrains.toString() as any),
    rankingRefinedGrains: foodResponse.rankingRefinedGrains == null
      ? ''
      : (foodResponse.rankingRefinedGrains.toString() as any),
    rankingFruit: foodResponse.rankingFruit == null
      ? ''
      : (foodResponse.rankingFruit.toString() as any),
    rankingVegetable: foodResponse.rankingVegetable == null
      ? ''
      : (foodResponse.rankingVegetable.toString() as any),
    rankingLowGi: foodResponse.rankingLowGi == null
      ? ''
      : (foodResponse.rankingLowGi.toString() as any),
    rankingHighGi: foodResponse.rankingHighGi == null
      ? ''
      : (foodResponse.rankingHighGi.toString() as any),
    rankingLowK: foodResponse.rankingLowK == null
      ? ''
      : (foodResponse.rankingLowK.toString() as any),
    rankingHighK: foodResponse.rankingHighK == null
      ? ''
      : (foodResponse.rankingHighK.toString() as any),
    similarItems: foodResponse.similarItems,
    connectedItems: foodResponse.connectedItems,
    compoundingWords: foodResponse.compoundingWords,
    dataSource: foodResponse.dataSource ?? '',
    giLevel: foodResponse.giLevel ?? '',
    giComments: foodResponse.giComments ?? '',
    potassiumLevel: foodResponse.potassiumLevel ?? '',
    potassiumComments: foodResponse.potassiumComments ?? '',
    mixedDishComponents: foodResponse.mixedDishComponents ?? {},
    adultServingSizeG: strOrEmpty(foodResponse.adultServingSizeG),
    suggestedServingCount: strOrEmpty(foodResponse.suggestedServingCount),
    suggestedServingUnitLabel: foodResponse.suggestedServingUnitLabel ?? '',
    suggestedServingAmountG: strOrEmpty(foodResponse.suggestedServingAmountG),
    ghgEquivalentKg: strOrEmpty(foodResponse.ghgEquivalentKg),
    manufacturer: foodResponse.manufacturer ?? '',

    ...foodResponseNutrientsToFoodEditorValue(foodResponse),

    measures: foodResponse.measures,
    components: foodResponse.components || [],
    overrideValues: overrideValuesToFoodEditorValue(foodResponse.overrideValues),
    disabled: foodResponse.disabled,
    optionCollection: foodResponse.optionCollection,
    optionValuesRaw: foodResponse.optionValuesRaw,

    ephemeralNutrientValueDisplayScale: '',

    listedServingCount: strOrEmpty(foodResponse.listedServingCount),
    listedServingUnitLabel: foodResponse.listedServingUnitLabel ?? '',
    listedServingGPerServing: !!foodResponse.listedServingCount && !!foodResponse.components
      ? formatNumber(getComponentsTotalWeight(foodResponse.components) / foodResponse.listedServingCount, 4)
      : '',
  };
};
