import React, { createContext, useCallback, useMemo, useState } from 'react';

import { config } from 'config';
import { mapValues } from 'lodash';
import mixpanel from 'mixpanel-browser';

const FEATURE_FLAG_KEY = 'feature_flags';

type FeatureFlagDefinition<T> = {
  name: FeatureName,
  description: string,
  default: T,
  setDefaultAt?: string,
  callback?: (value: T) => void,
  hidden?: boolean,
  tab: string,
};

interface FeatureFlagValue {
  name: FeatureName;
  value: boolean;
  timestamp: string;
}

type FeatureFlag<T> = FeatureFlagDefinition<T> & FeatureFlagValue;

function flagDef<T>(name: string, value: Omit<FeatureFlagDefinition<T>, 'name'>): FeatureFlagDefinition<T> {
  return {
    name: name as any,
    ...value,
  };
}

export const TABS_MAPPING = {
  settings: 'settings',
  features: 'features',
};

export const APPLICATION_FEATURES = {
  notification_favicon_blink: flagDef('notification_favicon_blink', {
    description: 'Notifications: blink favicon',
    default: true,
    tab: TABS_MAPPING.settings,
  }),

  notification_sound: flagDef('notification_sound', {
    description: 'Notifications: play sound',
    default: true,
    tab: TABS_MAPPING.settings,
  }),

  notification_UHP_only: flagDef('notification_UHP_only', {
    description: 'Notifications: UHP only',
    default: false,
    tab: TABS_MAPPING.settings,
  }),

  food_details_popup: flagDef('food_details_popup', {
    description: 'Food details popup: show',
    default: config.IS_PREVIEW,
    tab: TABS_MAPPING.settings,
  }),

  im_lbl_all_meals: flagDef('im_lbl_all_meals', {
    description: 'Image detect: show on all meals',
    default: false,
    tab: TABS_MAPPING.features,
  }),

  meal_editor_push_questions: flagDef('meal_editor_push_questions', {
    description: 'Meal editor: push questions',
    default: false,
    tab: TABS_MAPPING.features,
  }),

  auth_capabilities_override: flagDef('auth_capabilities_override', {
    description: 'Auth: show capabilities override',
    default: false,
    tab: TABS_MAPPING.features,
  }),

  food_editor_estimates: flagDef('food_editor_estimates', {
    description: 'Food editor: show nutrient estimates',
    default: false,
    tab: TABS_MAPPING.features,
  }),

  food_engine_sources: flagDef('food_engine_sources', {
    description: 'Food engine: sources',
    default: 'kr,lobl,nutrx,usda',
    tab: TABS_MAPPING.features,
  }),

  food_patient_history_suggestions: flagDef('food_patient_history_suggestions', {
    description: 'Food patient history suggestions: show',
    default: false,
    tab: TABS_MAPPING.features,
  }),

  // Old, hidden, values

  /**
   * @deprecated
   */
  food_search_use_external: flagDef('food_search_use_external', {
    description: 'Food search: external',
    default: true,
    setDefaultAt: '2023-11-28',
    hidden: true,
    tab: TABS_MAPPING.features,
  }),

  food_search_use_external_usda: flagDef('food_search_use_external_usda', {
    description: 'Food search: external USDA',
    default: true,
    setDefaultAt: '2024-01-01',
    hidden: true,
    tab: TABS_MAPPING.features,
  }),

  food_editor_nft_parser: flagDef('food_editor_nft_parser', {
    description: 'Food editor: NFT parser',
    default: true,
    hidden: true,
    tab: TABS_MAPPING.features,
  }),

  food_editor_composite_foods: flagDef('food_editor_composite_foods', {
    description: 'Food editor: composite foods',
    default: true,
    setDefaultAt: '2024-04-12',
    hidden: true,
    tab: TABS_MAPPING.features,
  }),

  labeling_use_food_name_alias: flagDef('labeling_use_food_name_alias', {
    description: 'Use food name alias',
    default: true,
    setDefaultAt: '2023-11-28',
    hidden: true,
    tab: TABS_MAPPING.features,
  }),

  labeling_use_nlp_phrase_parser: flagDef('labeling_use_nlp_phrase_parser', {
    description: 'Use NLP meal note parser',
    default: true,
    setDefaultAt: '2023-11-28',
    hidden: true,
    tab: TABS_MAPPING.features,
  }),

  show_vdb_suggestions: flagDef('show_vdb_suggestions', {
    description: 'Show VDB suggestions',
    default: false,
    hidden: true,
    tab: TABS_MAPPING.features,
  }),
};

export type FeatureName = keyof typeof APPLICATION_FEATURES;
type SavedFeatures = Record<FeatureName, FeatureFlagValue>;
type FeatureTypes = typeof APPLICATION_FEATURES;
type Features = {
  readonly [Property in keyof FeatureTypes]: FeatureFlag<FeatureTypes[Property]['default']>;
};

type FeatureFlagValues = {
  readonly [Property in keyof FeatureTypes]: FeatureTypes[Property]['default'];
};

type FeatureFlagContext = FeatureFlagValues & {
  all: () => Features,
  set: <F extends FeatureName>(name: F, value: FeatureTypes[F]['default']) => void,
};

export const FeatureContext = createContext<FeatureFlagContext>(null as any as FeatureFlagContext);

export const useFeatures = () => React.useContext(FeatureContext);

export let UNSAFE_FEATURES: FeatureFlagValues = {} as any;

export const FeatureContextProvider: React.FC<{}> = ({ children }) => {
  const initialFeatures = useMemo(() => {
    const savedJson = localStorage.getItem(FEATURE_FLAG_KEY);
    const savedFeatures: SavedFeatures = savedJson ? JSON.parse(savedJson) : {};

    return mapValues(APPLICATION_FEATURES, (feature) => {
      const saved = savedFeatures[feature.name] as any;
      const overrideDefault = !!feature.setDefaultAt
        && (feature.setDefaultAt < new Date().toISOString())
        && (feature.setDefaultAt > (saved?.timestamp || 0));

      return {
        name: feature.name,
        value: (
          overrideDefault
            ? feature.default
            : saved?.value !== undefined
            ? saved.value
            : feature.default
        ),
        timestamp: saved?.timestamp || '',
      };
    });
  }, []);
  const [features, setFeatures] = useState<SavedFeatures>(initialFeatures);

  const all = useCallback(() => (
    mapValues(APPLICATION_FEATURES, (feature) => ({ ...feature, ...features[feature.name] }))
  ), [features]);

  const set = useCallback(<F extends FeatureName>(name: F, value: FeatureTypes[F]['default']) => {
    setFeatures(oldFeatures => {
      const newFeatures = {
        ...oldFeatures,
        [name]: { name, value, timestamp: new Date().toISOString() },
      };
      mixpanel.track('Feature flag changed', {
        'Flag': name,
        'Old value': oldFeatures[name]?.value,
        'New value': value,
      });
      localStorage.setItem(FEATURE_FLAG_KEY, JSON.stringify(newFeatures));
      (APPLICATION_FEATURES[name].callback as any)?.(value);
      return newFeatures;
    });
  }, []);

  const featureValues = useMemo(() => mapValues(features, v => v.value), [features]);
  UNSAFE_FEATURES = featureValues as any;

  const value = useMemo(() => ({
    ...featureValues,
    all,
    set,
  }), [featureValues, all, set]);

  return (
    <FeatureContext.Provider value={value as any}>
      {children}
    </FeatureContext.Provider>
  );
};
