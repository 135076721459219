/**
 * Used to assert at compile time that a statement is unreachable.
 *
 * For example:
 *
 *  type Option = 'a' | 'b'
 *
 *  function handleOption(o: Option) {
 *    if (o == 'a')
 *      return handleA()
 *    if (o == 'b')
 *      return handleB()
 *    assertUnreachable(o)
 *  }
 */
export function assertUnreachable(x: never, message?: string): never {
  throw new Error(
    [
      message ? `${message} ` : '',
      'Reached unreachable statement: ',
      JSON.stringify(x),
    ].join(''),
  );
}
