import { Box, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import Avatar from 'components/@extended/Avatar';
import { useAuth } from 'context/appContext';
import _ from 'lodash';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useSocketIOPresenceActiveUsers } from 'socketio/SocketIOService';
import { cmpCustomFoodQueryParams, parseQueryParams } from 'utils';

import Profile from './Profile';

const UsersOnCurrentPage = () => {
  const location = useLocation();
  const usersOnCurrentPage = useUsersOnPage(location.pathname + location.search);

  if (!usersOnCurrentPage.length) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1}>
      {usersOnCurrentPage.map((u) => (
        <Avatar key={u.uid}>
          <Tooltip key={u.uid} title={`${u.uid} is also viewing this page`}>
            <span>{u.uid}</span>
          </Tooltip>
        </Avatar>
      ))}
    </Stack>
  );
};

const HeaderContent = () => {
  return (
    <Stack direction="row" spacing={1} flex={1} alignItems="center">
      <Box sx={{ flexShrink: 0, ml: 1, mt: 0.25 }}>
        <UsersOnCurrentPage />
      </Box>
      <Box sx={{ width: '100%', ml: 1 }} />
      <Profile />
    </Stack>
  );
};

export const useUsersOnFood = (
  opts: {
    url?: string,
    matchParams?: { [key: string]: string },
  },
) => {
  const { matchParams } = opts;
  const usersOnPage = useUsersOnPage(opts.url || '');
  const { authInfo } = useAuth();
  const { allActiveUsers } = useSocketIOPresenceActiveUsers();

  const usersOnFood = useMemo(() => {
    if (!matchParams) {
      return [];
    }

    return _(allActiveUsers)
      .filter((u) => {
        const uLoc = u.ident?.location;
        if (!uLoc) {
          return false;
        }

        const [uLocPath, uLocParam] = uLoc.split('?');
        const parsedParams = parseQueryParams(uLocParam);

        for (const param in matchParams) {
          if (parsedParams[param] == matchParams[param]) {
            return true;
          }
        }

        return false;
      })
      .filter(u => u.uid != authInfo?.reviewer_id)
      .sort()
      .uniqBy('uid')
      .value();
  }, [allActiveUsers, opts, authInfo?.reviewer_id]);

  return [...usersOnPage, ...usersOnFood];
};

export const useUsersOnPage = (
  url: string,
) => {
  const { authInfo } = useAuth();
  const { allActiveUsers } = useSocketIOPresenceActiveUsers();
  const [urlPath, urlParams] = url.split('?');

  const usersOnCurrentPage = useMemo(() => {
    const users = _(allActiveUsers)
      .filter((u) => {
        const uLoc = u.ident?.location;
        if (!uLoc) {
          return false;
        }
        const [uLocPath, uLocParam] = uLoc.split('?');

        if (urlPath === '/foods/new') {
          return cmpCustomFoodQueryParams(urlParams, uLocParam);
        }

        return uLocPath === urlPath;
      })
      .filter(u => u.uid != authInfo?.reviewer_id)
      .sort()
      .uniqBy('uid')
      .value();
    return users;
  }, [
    allActiveUsers,
    url,
    authInfo?.reviewer_id,
  ]);

  return usersOnCurrentPage;
};

export default HeaderContent;
