import { forwardRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Avatar, Badge, Chip, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useAuth } from 'context/appContext';
import { LinkTarget, NavItemType } from 'types/menu';

import { useMenuBar } from 'services/MenuBarService';
import { navItemCheckAuth } from './index';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

export interface NavItemProps {
  item: NavItemType;
  level: number;
}

export const NavItem = ({ item, level }: NavItemProps) => {
  const theme = useTheme();
  const { hasAuth } = useAuth();
  const { openItem, setActiveItem, drawerOpen, chips } = useMenuBar();

  let itemTarget: LinkTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  const listItemProps = item.onClick
    ? { onClick: item.onClick }
    : item.external
    ? { component: 'a', href: item.url, target: itemTarget }
    : item.url
    ? { component: forwardRef((props, ref) => <Link {...props} to={item.url!} target={itemTarget} />) }
    : {};

  const Icon = item.icon!;
  const itemIcon = item.icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : false;

  const isSelected = openItem.findIndex((id) => id === item.id) > -1;

  const { pathname } = useLocation();

  // active menu item on page load

  useEffect(() => {
    if (item && item.id && pathname && pathname.includes('product-details')) {
      if (item.url && item.url.includes('product-details')) {
        setActiveItem([item.id]);
      }
    }

    if (item && item.id && pathname && pathname.includes('kanban')) {
      if (item.url && item.url.includes('kanban')) {
        setActiveItem([item.id]);
      }
    }

    if (item && item.id && pathname === item.url?.replace(/\?.*$/, '').replace(/#.*/, '')) {
      setActiveItem([item.id]);
    }
    // eslint-disable-next-line
  }, [pathname]);

  const textColor = theme.palette.mode === 'dark' ? 'grey.400' : 'text.primary';
  const iconSelectedColor = theme.palette.mode === 'dark' && drawerOpen ? 'text.primary' : 'primary.main';
  const itemChip = item.chip
    ? item.chip
    : item.id !== undefined
    ? chips[item.id]
    : null;
  const listItemIcon = (
    <ListItemIcon
      sx={{
        minWidth: 28,
        color: isSelected ? iconSelectedColor : textColor,
        ...(!drawerOpen && {
          borderRadius: 1.5,
          width: 36,
          height: 36,
          alignItems: 'center',
          justifyContent: 'center',
          '&:hover': {
            bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter',
          },
        }),
        ...(!drawerOpen
          && isSelected && {
          bgcolor: theme.palette.mode === 'dark' ? 'primary.900' : 'primary.lighter',
          '&:hover': {
            bgcolor: theme.palette.mode === 'dark' ? 'primary.darker' : 'primary.lighter',
          },
        }),
      }}
    >
      {itemIcon}
    </ListItemIcon>
  );

  if (!navItemCheckAuth(item, hasAuth)) {
    return null;
  }

  if (item.render) {
    return item.render({ item, level });
  }

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      selected={isSelected}
      sx={{
        zIndex: 1201,
        pl: drawerOpen ? `${level * 28}px` : 1.5,
        py: !drawerOpen && level === 1 ? 1.25 : 1,
        ...(drawerOpen && {
          '&:hover': {
            bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'primary.lighter',
          },
          '&': {
            borderRight: `2px solid transparent`,
          },
          '&.Mui-selected': {
            bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'primary.lighter',
            borderRight: `2px solid ${theme.palette.primary.main}`,
            color: iconSelectedColor,
            '&:hover': {
              color: iconSelectedColor,
              bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'primary.lighter',
            },
          },
        }),
        ...(!drawerOpen && {
          '&:hover': {
            bgcolor: 'transparent',
          },
          '&.Mui-selected': {
            '&:hover': {
              bgcolor: 'transparent',
            },
            bgcolor: 'transparent',
          },
        }),
      }}
    >
      {itemIcon && !drawerOpen && (itemChip
        ? (
          <Badge
            badgeContent={itemChip.label}
            color={itemChip.color}
          >
            {listItemIcon}
          </Badge>
        )
        : listItemIcon)}
      {itemIcon && drawerOpen && listItemIcon}
      {(drawerOpen || (!drawerOpen && level !== 1)) && (
        <ListItemText
          primary={
            <Typography variant="h6" sx={{ color: isSelected ? iconSelectedColor : textColor }}>
              {item.title}
            </Typography>
          }
        />
      )}
      <Stack direction="row" spacing={1}>
        {item.iconRight}
        {(drawerOpen || (!drawerOpen && level !== 1)) && itemChip && (
          <Chip
            color={itemChip.color}
            variant={itemChip.variant}
            size={itemChip.size}
            label={itemChip.label}
            avatar={itemChip.avatar && <Avatar>{itemChip.avatar}</Avatar>}
          />
        )}
      </Stack>
    </ListItemButton>
  );
};
