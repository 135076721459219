/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ImageDetectionResultResponse } from '../models';
// @ts-ignore
import { ImageDetectionTypeEnum } from '../models';
/**
 * ImageDetectionApi - axios parameter creator
 * @export
 */
export const ImageDetectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the result of processing an image
         * @param {ImageDetectionTypeEnum} detect_type 
         * @param {string} image_source 
         * @param {string} image_id 
         * @param {'check' | 'ignore' | 'require'} [cache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiImageDetectionGetImageDetectionResult: async (detect_type: ImageDetectionTypeEnum, image_source: string, image_id: string, cache?: 'check' | 'ignore' | 'require', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'detect_type' is not null or undefined
            assertParamExists('appApiImageDetectionGetImageDetectionResult', 'detect_type', detect_type)
            // verify required parameter 'image_source' is not null or undefined
            assertParamExists('appApiImageDetectionGetImageDetectionResult', 'image_source', image_source)
            // verify required parameter 'image_id' is not null or undefined
            assertParamExists('appApiImageDetectionGetImageDetectionResult', 'image_id', image_id)
            const localVarPath = `/images/detection/{detect_type}/{image_source}/{image_id}`
                .replace(`{${"detect_type"}}`, encodeURIComponent(String(detect_type)))
                .replace(`{${"image_source"}}`, encodeURIComponent(String(image_source)))
                .replace(`{${"image_id"}}`, encodeURIComponent(String(image_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageDetectionApi - functional programming interface
 * @export
 */
export const ImageDetectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImageDetectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the result of processing an image
         * @param {ImageDetectionTypeEnum} detect_type 
         * @param {string} image_source 
         * @param {string} image_id 
         * @param {'check' | 'ignore' | 'require'} [cache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiImageDetectionGetImageDetectionResult(detect_type: ImageDetectionTypeEnum, image_source: string, image_id: string, cache?: 'check' | 'ignore' | 'require', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageDetectionResultResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiImageDetectionGetImageDetectionResult(detect_type, image_source, image_id, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImageDetectionApi - factory interface
 * @export
 */
export const ImageDetectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImageDetectionApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the result of processing an image
         * @param {ImageDetectionTypeEnum} detect_type 
         * @param {string} image_source 
         * @param {string} image_id 
         * @param {'check' | 'ignore' | 'require'} [cache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiImageDetectionGetImageDetectionResult(detect_type: ImageDetectionTypeEnum, image_source: string, image_id: string, cache?: 'check' | 'ignore' | 'require', options?: any): AxiosPromise<ImageDetectionResultResponse> {
            return localVarFp.appApiImageDetectionGetImageDetectionResult(detect_type, image_source, image_id, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiImageDetectionGetImageDetectionResult operation in ImageDetectionApi.
 * @export
 * @interface ImageDetectionApiAppApiImageDetectionGetImageDetectionResultRequest
 */
export interface ImageDetectionApiAppApiImageDetectionGetImageDetectionResultRequest {
    /**
     * 
     * @type {ImageDetectionTypeEnum}
     * @memberof ImageDetectionApiAppApiImageDetectionGetImageDetectionResult
     */
    readonly detect_type: ImageDetectionTypeEnum

    /**
     * 
     * @type {string}
     * @memberof ImageDetectionApiAppApiImageDetectionGetImageDetectionResult
     */
    readonly image_source: string

    /**
     * 
     * @type {string}
     * @memberof ImageDetectionApiAppApiImageDetectionGetImageDetectionResult
     */
    readonly image_id: string

    /**
     * 
     * @type {'check' | 'ignore' | 'require'}
     * @memberof ImageDetectionApiAppApiImageDetectionGetImageDetectionResult
     */
    readonly cache?: 'check' | 'ignore' | 'require'
}

/**
 * ImageDetectionApi - object-oriented interface
 * @export
 * @class ImageDetectionApi
 * @extends {BaseAPI}
 */
export class ImageDetectionApi extends BaseAPI {
    /**
     * 
     * @summary Get the result of processing an image
     * @param {ImageDetectionApiAppApiImageDetectionGetImageDetectionResultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageDetectionApi
     */
    public appApiImageDetectionGetImageDetectionResult(requestParameters: ImageDetectionApiAppApiImageDetectionGetImageDetectionResultRequest, options?: AxiosRequestConfig) {
        return ImageDetectionApiFp(this.configuration).appApiImageDetectionGetImageDetectionResult(requestParameters.detect_type, requestParameters.image_source, requestParameters.image_id, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }
}
