import { Box, Card, CardContent, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { KBarAnimator, KBarPortal, KBarPositioner, KBarResults, KBarSearch, useMatches } from 'kbar';

import { useActions } from 'lib/useActions';

export const KBar = () => {
  const theme = useTheme();
  useActions();

  return (
    <KBarPortal>
      <KBarPositioner>
        <KBarAnimator style={{ maxWidth: '400px', width: '100%' }}>
          <Card sx={{ backgroundColor: theme.palette.background.default }}>
            <CardContent sx={{ backgroundColor: theme.palette.background.paper }}>
              <KBarSearch style={{ width: '100%' }} />
              <RenderResults />
            </CardContent>
          </Card>
        </KBarAnimator>
      </KBarPositioner>
    </KBarPortal>
  );
};

function RenderResults() {
  const theme = useTheme();
  const { results } = useMatches();

  return (
    <Box>
      <KBarResults
        items={results}
        onRender={({ item, active }) =>
          typeof item === 'string'
            ? (
              <Typography variant="h5">
                {item}
              </Typography>
            )
            : (
              <Typography
                variant="body1"
                sx={{
                  '&:hover': {
                    bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'primary.lighter',
                  },
                }}
              >
                {item.name}
              </Typography>
            )}
      />
    </Box>
  );
}
