import { Input, List, ListItemButton, ListItemText } from '@mui/material';
import Switch from '@mui/material/Switch';

import { FeatureName, useFeatures } from 'context/FeatureContext';

export const FeatureFlagTab = (props: { tab?: string }) => {
  const features = useFeatures();

  const filteredFeatures = Object.values(features.all()).filter((feature) => {
    if (!props.tab) {
      return true;
    }

    return feature.tab == props.tab;
  });

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      {filteredFeatures.map((feature) => (
        !feature.hidden && (
          <ListItemButton
            key={feature.name}
            onClick={() => {
              if (typeof feature.value === 'boolean') {
                features.set(feature.name, !feature.value);
              }
            }}
          >
            <ListItemText primary={feature.description} />
            {typeof feature.value === 'boolean'
              ? <Switch checked={feature.value} />
              : typeof feature.value === 'string'
              ? (
                <Input
                  value={feature.value}
                  onChange={(event) => features.set(feature.name, event.target.value as any)}
                />
              )
              : `Unknown value type: ${typeof feature.value}`}
          </ListItemButton>
        )
      ))}
    </List>
  );
};
