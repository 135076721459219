// project import
// import Logo from './LogoMain';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
// ==============================|| MAIN LOGO ||============================== //

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
}

const LogoSection = ({ reverse, isIcon, sx }: Props) => (
  isIcon
    ? <Box component="img" src="/app-logo.png" alt="app logo" sx={sx} />
    : <img src="/app-logo.png" alt="app logo" width="60" />
);

export default LogoSection;
