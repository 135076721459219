import { CheckCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';

import type { MealHistoryResponse, MealPhotoQueueResponse } from 'api/generated/MNT';
import { getMealHistory, MealHistory } from 'apiClients/mealHistory';
import { MealResponse } from 'apiClients/review';
import { useAuth } from 'context/appContext';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import type { DraftItem } from 'types/DraftItem';
import { useQueryNeverRefetch } from 'utils';

/*
    List Meal History view shows the meal history of the user who submitted the meal in a table
    along with the number of times each food has been logged
    It checks against the current meal(draftItems) and adds an icon next to foods which are in the current meal

    Shows previous food logged (from up to 7 days from current date)
    Used in Review and QueueItem
*/
export const awaitingVerificationString = 'awaiting verification...';

export const ListMealHistoryView = (props: {
  queueItem: MealPhotoQueueResponse,
  draftItems: DraftItem[],
}) => {
  const { draftItems, queueItem } = props;
  const [filter, setFilter] = useState('');
  const { authInfo } = useAuth();

  const historyQuery = useQuery(['mealHistory', queueItem.id, authInfo?.reviewer_id], async () => {
    if (!authInfo?.reviewer_id) {
      return null;
    }
    return getMealHistory(queueItem.data_reviewer_group_id, authInfo?.reviewer_id, queueItem.id);
  }, {
    ...useQueryNeverRefetch,
    cacheTime: 0,
  });
  const mealHistory = historyQuery.data;

  const addFoodToList = (foodName: string, foodDate: any, foodCounts: any, foodLatestDate: any) => {
    if (foodName in foodCounts) {
      foodCounts[foodName] += 1;
    } else {
      foodCounts[foodName] = 1;
    }
    foodLatestDate[foodName] = foodDate > foodLatestDate[foodName] || ''
      ? foodDate
      : foodLatestDate[foodName];
  };

  const groupedFoods = useMemo(() => {
    const draftItemFoods = new Set((draftItems || []).map(draftItem => draftItem.item.food_name));
    const foodCounts: { [foodName: string]: number } = {};
    const foodLatestDate: { [foodName: string]: string } = {};
    mealHistory?.meals.forEach(meal => {
      meal.meal_items.forEach(mealItem => {
        const foodName = mealItem.food_name;
        addFoodToList(foodName, meal.meal_date, foodCounts, foodLatestDate);

        mealItem.addons.forEach(addon => {
          const addonName = addon + ' (A)';
          addFoodToList(addonName, meal.meal_date, foodCounts, foodLatestDate);
        });

        mealItem.extra_addons?.forEach(addon => {
          const addonName = addon + ' (A)';
          addFoodToList(addonName, meal.meal_date, foodCounts, foodLatestDate);
        });

        mealItem.custom_addons?.forEach(addon => {
          const addonName = addon.food_name + ' (A)';
          addFoodToList(addonName, meal.meal_date, foodCounts, foodLatestDate);
        });
      });
    });

    return _(Object.entries(foodCounts))
      .map(([foodName, count]) => ({
        foodName,
        count,
        latestDate: foodLatestDate[foodName],
        inDrafts: draftItemFoods.has(foodName),
      }))
      .sortBy(['-latestDate', '-count', 'foodName'])
      .value();
  }, [mealHistory, draftItems]);

  const filteredFoods = useMemo(() => {
    if (!filter) {
      return groupedFoods;
    }

    return groupedFoods.filter(f => f.foodName.toLowerCase().includes(filter.toLowerCase()));
  }, [groupedFoods, filter]);

  return (
    <div className="padded">
      {historyQuery.isLoading && <div>Loading...</div>}
      {historyQuery.isError && <div>Error loading meal history: {'' + historyQuery.error}</div>}
      {mealHistory && (
        <table className="table-black-border">
          <tr>
            <th style={{ paddingLeft: 0, paddingRight: 0 }}>
              <input
                placeholder="Item Name"
                onChange={e => setFilter(e.target.value)}
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                  paddingLeft: 8,
                  paddingRight: 6,
                  width: 'calc(100% - 10px)',
                }}
              />
            </th>
            <th>Logs</th>
          </tr>
          {filteredFoods.map((f, idx) => (
            <tr key={idx}>
              <td>
                {f.foodName}
                {f.inDrafts && <CheckCircleOutlined style={{ verticalAlign: 'middle', marginLeft: 5 }} />}
              </td>
              <td>
                {f.count}
              </td>
            </tr>
          ))}
        </table>
      )}
    </div>
  );
};
