import { FoodEditorValue } from './types';

interface FoodEditorFieldRelationship {
  field: keyof FoodEditorValue;
  index?: number;
  value: string;
}

const FOOD_EDITOR_FIELD_RELATIONSHIPS: Partial<Record<keyof FoodEditorValue, FoodEditorFieldRelationship[]>> = {
  rankingBothFat: [{
    field: 'fatType',
    value: 'both',
  }],
  rankingHealthyFat: [{
    field: 'fatType',
    value: 'healthy',
  }, {
    field: 'fatType',
    value: 'both',
  }],
  rankingUnhealthyFat: [{
    field: 'fatType',
    value: 'unhealthy',
  }, {
    field: 'fatType',
    value: 'both',
  }],
  rankingVegetable: [{
    field: 'fruitAndVegetableType',
    value: 'vegetable',
  }, {
    field: 'fruitAndVegetableType',
    value: 'both',
  }],
  rankingFruit: [{
    field: 'fruitAndVegetableType',
    value: 'fruit',
  }, {
    field: 'fruitAndVegetableType',
    value: 'both',
  }],
  rankingWholeGrains: [{
    field: 'grainType',
    value: 'whole',
  }],
  rankingRefinedGrains: [{
    field: 'grainType',
    value: 'refined',
  }],
  rankingUp: [{
    field: 'processedType',
    value: 'ultra_processed',
  }],
  rankingPb: [{
    field: 'plantBased',
    value: 'true',
  }],
  rankingLowGi: [{
    field: 'giLevel',
    value: 'low',
  }],
  rankingHighGi: [{
    field: 'giLevel',
    value: 'high',
  }],
  rankingHighK: [{
    field: 'potassiumLevel',
    value: 'high',
  }],
  rankingLowK: [{
    field: 'potassiumLevel',
    value: 'low',
  }],
  beverageType: [{
    field: 'ontologyLevels',
    index: 0,
    value: 'beverage',
  }],
};

export const useFoodEditorFieldRelationship = (
  field: keyof FoodEditorValue,
  foodEditorValue: FoodEditorValue,
): boolean => {
  return checkFoodEditorFieldRelationship(field, foodEditorValue);
};

export const checkFoodEditorFieldRelationship = (
  field: keyof FoodEditorValue,
  foodEditorValue: FoodEditorValue,
): boolean => {
  if (!FOOD_EDITOR_FIELD_RELATIONSHIPS[field]) {
    return true;
  }

  const relationship = FOOD_EDITOR_FIELD_RELATIONSHIPS[field];
  return relationship.some(r => {
    if (r.index === undefined) {
      const value = foodEditorValue[r.field];
      return r.value.toString() == value?.toString();
    }

    const value = (foodEditorValue[r.field] as any[])[r.index];
    return r.value.toString() == value?.toString();
  });
};
