import { Button, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Stack } from '@mui/material';
import { Form } from 'react-bootstrap';
import { useForm, UseFormRegisterReturn } from 'react-hook-form';
import { useAsyncResult } from 'react-use-async-result';

import { groupBy } from 'lodash';
import mixpanel from 'mixpanel-browser';

import { QA_CONFIDENCE_LABELS, QA_QUESTIONNAIRE_QUESTIONS } from 'common/qa';
import { HelpModal } from 'components/HelpModal';
import MainCard from 'components/MainCard';
import { useAuth } from 'context/appContext';

import { CreateQualityAssuranceLogRequest } from 'api/generated/MNT';
import type { QualityAssuranceLogResponse } from 'api/generated/MNT';
import type { MealQueueItem } from 'apiClients/mpq';
import { Capabilities } from 'auth-capabilities';

const QUESTIONNAIRE_SECTION_COLORS = {
  'ident': '#ffe0e0',
  'sizing': '#fbe1ff',
  'nutrients': '#e2e1ff',
  'customs': '#fffda1',
  'context': '#e2fedf',
};

function objStripNulls(obj: any): any {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== null));
}

export const MealQAForm = (props: {
  item: MealQueueItem,
  qaLogs: QualityAssuranceLogResponse[],
  postMealQA: (data: CreateQualityAssuranceLogRequest) => Promise<QualityAssuranceLogResponse>,
}) => {
  const { authInfo, hasAuth } = useAuth();
  const { qaLogs, postMealQA } = props;
  const qa: Omit<QualityAssuranceLogResponse, 'meal_photo_queue_item'> | undefined = qaLogs?.find(v =>
    '' + v.user_id == '' + authInfo?.reviewer_id
  );
  const form = useForm({
    defaultValues: {
      questionnaire: {},
      ...(qa || {}),
      // confidence_identification: qa?.confidence_identification && '' + qa.confidence_identification,
      // confidence_sizing: qa?.confidence_sizing && '' + qa.confidence_sizing,
      level_of_difficulty: qa?.level_of_difficulty && '' + qa.level_of_difficulty,
    },
    mode: 'onChange',
  });
  const { isValid, isDirty } = form.formState;
  const saveRes = useAsyncResult<unknown>();

  const onSubmit = (values: any) => {
    values.questionnaire = objStripNulls(values.questionnaire);

    const submission = {
      is_reviewed: true,
      is_escalated: values.is_escalated,
      is_approved: hasAuth(Capabilities.commonDataReviewerAdmin) ? values.is_approved : false,
      // confidence_identification: +values.confidence_identification,
      // confidence_sizing: +values.confidence_sizing,
      level_of_difficulty: +values.level_of_difficulty,
      data_reviewer_id: authInfo!.reviewer_id,
      meal_photo_queue_id: props.item.id,
      note: values.note,
      questionnaire: values.questionnaire,
    };

    mixpanel.track('QA Submitted', submission);
    console.log('Submitting:', values);
    const req = postMealQA(submission);
    // req.then(res => props.onSave(res));
    req.then(qa =>
      form.reset({
        ...(qa || {}),
        // confidence_identification: qa?.confidence_identification && '' + qa.confidence_identification,
        // confidence_sizing: qa?.confidence_sizing && '' + qa.confidence_sizing,
        level_of_difficulty: qa?.level_of_difficulty && '' + qa.level_of_difficulty,
        questionnaire: qa?.questionnaire || {},
      })
    );
    saveRes.bind(req);
  };

  QA_QUESTIONNAIRE_QUESTIONS.forEach(q => {
    form.register(`questionnaire.${q.id}` as any);
  });

  const onQuestionnaireQuestionChange = (e: any) => {
    form.setValue(
      `questionnaire.${e.target.name}` as any,
      e.target.checked,
      { shouldDirty: true },
    );
  };

  const groupedQuestions = groupBy(QA_QUESTIONNAIRE_QUESTIONS, q => q.id.split(':')[0]);

  return (
    <MainCard title="Meal QA">
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <div style={{ marginBottom: 10 }}>
          {
            /*
          <Form.Group as={Col} controlId="confidence_identification" style={{ flex: "1.1 0" }}>
            <Form.Label>
              <strong>Identification Accuracy</strong>
              <HelpModal title="Identification Accuracy">
                <p>
                  How accurately were the items in the meal identified?
                </p>

                <p>
                  If the meal identification is not accurate because the photo or
                  description do not provide enough context to identify the content,
                  then the identification accuracy is <b>low</b> and the Difficulty
                  should be <b>medium</b> or <b>high</b>.
                </p>

                <p>
                  <b>High</b> - the meal was identified correctly; the main foods
                  are correct, and all appropriate addons are selected.<br />
                  <b>Medium</b> - most of the meal was identified correctly, but
                  there were minor errors in main food identificaiton or selection
                  of addons; these errors do not contribute significantly to the
                  nutritional content of the meal.<br />
                  <b>Low</b> - the meal was not identified correctly, or errors in
                  identification have a significant effect on the nutritional
                  content of the meal.
                </p>
              </HelpModal>
            </Form.Label>
            <QAHighMedLowInput
              register={form.register("confidence_identification", {
                required: true,
              })} />
          </Form.Group>

          <Form.Group as={Col} controlId="confidence_sizing">
            <Form.Label>
              <strong>Sizing Accuracy</strong>
              <HelpModal title="Sizing Accuracy">
                <p>
                  How accurate is the sizing of the meal?
                </p>

                <p>
                  If the sizing is not accurate because the photo or description do not
                  provide enough context to identify the size, then the sizing accuracy
                  is <b>low</b> and the Difficulty should be <b>medium</b> or <b>high</b>.
                </p>

                <p>
                  <b>High</b> - the sizing is very accurate, within 5% of the actual size.<br />
                  <b>Medium</b> - the sizing is moderately accuracy, within 10% of the actual size;
                  sizing errors do not contribute singificantly to the nutritaional
                  content of the meal.<br />
                  <b>Low</b> - the sizing is not accurate, more than 10% different
                  from the actual size; sizing errors have a significant impact on
                  the nutritaion content of the meal.
                </p>
              </HelpModal>
            </Form.Label>
            <QAHighMedLowInput register={form.register("confidence_sizing", {
              required: true,
            })} />
          </Form.Group>
          */
          }

          <FormLabel>
            <strong>Difficulty</strong>
            <HelpModal title="Overall Difficulty">
              <p>
                Overall, how difficult was this meal to identify and size?
              </p>

              <p>
                In situations where some aspects are more difficult than others, the difficulty should be rated
                according to the most difficult aspect (for example, if the meal content is "low" difficulty but the
                sizing is "medium", the difficulty should be rated "medium").
              </p>

              <p>
                <b>High</b>{' '}
                - this meal is very difficult to identify and/or size; even with experience it would be difficult to
                identify with any degree of accuracy. For example:
                <ul>
                  <li>The meal contains ingredients that are obscure, hiddent, or otherwise difficult to identify.</li>
                  <li>The photo which not provide any reference for portion sizing.</li>
                  <li>The text description does not provide enough information.</li>
                </ul>
              </p>

              <p>
                <b>Medium</b>{' '}
                - this meal is moderately difficult to identify and/or size, but with experience it would be possible to
                identify. For example:
                <ul>
                  <li>
                    The meal contains uncommon ingredients, but there is enough context to identify them.
                  </li>
                  <li>
                    The photo contains some sizing reference, but knowledge or experience is required to accurately
                    identify the portion size.
                  </li>
                </ul>
              </p>

              <p>
                <b>Low</b> - the meal is easy to identify and/or size. For example:
                <ul>
                  <li>The photo contains a product or brand name.</li>
                  <li>The text description describes the meal.</li>
                  <li>The photo contains a known size reference.</li>
                </ul>
              </p>
            </HelpModal>
          </FormLabel>
          <QAHighMedLowInput
            register={form.register('level_of_difficulty', {
              required: true,
            })}
          />
        </div>

        <div>
          <FormLabel>
            <strong>Questionnaire</strong>
          </FormLabel>
          <Grid container spacing={1}>
            {Object.entries(groupedQuestions).map(([groupId, questions]) => (
              <Grid item xs={12} key={groupId}>
                <div
                  style={{
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 5,
                    paddingRight: 5,
                    backgroundColor: QUESTIONNAIRE_SECTION_COLORS[groupId as keyof typeof QUESTIONNAIRE_SECTION_COLORS],
                  }}
                >
                  <div style={{ float: 'right' }}>
                    <strong>{groupId}</strong>
                  </div>

                  {questions.map((question, idx) => (
                    <Form.Group controlId={question.id} key={question.id}>
                      <Form.Check>
                        <Form.Check.Input
                          name={question.id}
                          onChange={onQuestionnaireQuestionChange}
                          defaultChecked={form.getValues(`questionnaire.${question.id}` as any)}
                        />
                        <Form.Check.Label
                          style={{
                            maxWidth: idx == 0 ? 'calc(100% - 110px)' : '100%',
                          }}
                        >
                          {question.text}
                        </Form.Check.Label>
                      </Form.Check>
                    </Form.Group>
                  ))}
                </div>
              </Grid>
            ))}

            <Grid item xs={6}>
              <Form.Control {...form.register('note')} as="textarea" rows={3} placeholder="Notes" />
            </Grid>
          </Grid>
        </div>

        <div>
          <Stack direction="row" spacing={1}>
            {(hasAuth(Capabilities.commonDataReviewerAdmin) || hasAuth(Capabilities.patientReportView)) && (
              <Button
                disabled={!isValid
                  || !isDirty
                  || saveRes.isPending}
                style={{ marginTop: 20 }}
                type="submit"
                // value="approve"
                variant="contained"
                color="success"
                onClick={() => {
                  form.setValue('is_approved', true, { shouldValidate: true });
                  form.setValue('is_escalated', false, { shouldValidate: true });
                }}
              >
                Approve
              </Button>
            )}
            <Stack direction="row" alignItems="baseline">
              <Button
                disabled={!isValid
                  || !isDirty
                  || saveRes.isPending}
                style={{ marginTop: 20 }}
                type="submit"
                value="escalate"
                variant="contained"
                color="error"
                onClick={() => {
                  form.setValue('is_approved', false, { shouldValidate: true });
                  form.setValue('is_escalated', true, { shouldValidate: true });
                }}
              >
                Escalate
              </Button>
              <HelpModal title="Escalate Review">
                <p>
                  The "Escalate Review" checkbox will flag the meal for review by a more senior reviewer.
                </p>
              </HelpModal>
            </Stack>
          </Stack>
          {saveRes.isError && <div>Error: {'' + saveRes.error}</div>}
        </div>
      </Form>
    </MainCard>
  );
};

const QAHighMedLowInput = (props: {
  register: UseFormRegisterReturn<any>,
}) => {
  const { register } = props;

  return (
    <RadioGroup row sx={{ justifyContent: 'space-between' }}>
      {QA_CONFIDENCE_LABELS.map(({ label, value }) => (
        <FormControlLabel
          {...register}
          key={label}
          control={<Radio />}
          label={label}
          value={value}
          id={register.name + ':' + value}
        />
      ))}
    </RadioGroup>
  );
};
