import { Grid, Input, Slider, styled, Typography } from '@mui/material';
import { SyntheticEvent } from 'react';

const StyledSlider = styled(Slider)({
  '&.Mui-disabled': {
    '.MuiSlider-track': {
      color: '#bfbfbf',
    },
  },
});

export default function MixedDishComponentEditor({
  color,
  label,
  onChangeValue,
  value,
  onBlur,
  disabled,
}: {
  color?: string,
  label: string,
  onChangeValue: (newValue: number | null) => void,
  value: number | null,
  onBlur?: () => void,
  disabled?: boolean,
}) {
  const handleInputChange = (
    event: SyntheticEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const inputValue = event.currentTarget.value;
    const newValue = inputValue ? parseInt(inputValue, 10) : 0;
    onChangeValue(newValue / 100);
  };

  return (
    <Grid container alignItems="center">
      <Grid item xs={3}>
        <Typography id="label" color={disabled ? '#bfbfbf' : 'unset'}>{label}</Typography>
      </Grid>
      <Grid item xs={7} style={{ paddingRight: 20 }}>
        <StyledSlider
          aria-label={label}
          aria-labelledby="label"
          classes={{ colorPrimary: '#FF0000' }}
          disabled={disabled ?? false}
          min={0}
          max={100}
          onChange={(_, newValue) => onChangeValue((newValue as number) / 100)}
          onBlur={onBlur}
          step={1}
          sx={color !== undefined ? { color } : undefined}
          value={typeof value === 'number' ? value * 100 : 0}
        />
      </Grid>
      <Grid item container xs={2} justifyContent="flex-end">
        <Input
          aria-labelledby="label"
          value={((value || 0) * 100).toFixed(0)}
          size="small"
          disabled={disabled ?? false}
          onChange={handleInputChange}
          onBlur={onBlur}
          type="number"
          inputProps={{
            step: 1,
            min: 0,
            max: 100,
          }}
          renderSuffix={() => '%'}
        />
      </Grid>
    </Grid>
  );
}
