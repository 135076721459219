/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppApiDataManagementIncrementalImportFood200Response } from '../models';
// @ts-ignore
import { RdiResponse } from '../models';
// @ts-ignore
import { RdiTestRequest } from '../models';
/**
 * DataManagementApi - axios parameter creator
 * @export
 */
export const DataManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary validation food database fields
         * @param {any} file excel spreadsheet with xlsx (Excel 2010+) format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementFieldValidation: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('appApiDataManagementFieldValidation', 'file', file)
            const localVarPath = `/data_management/field_validation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Incrementally import food data
         * @param {any} file xlsx
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementIncrementalImportFood: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('appApiDataManagementIncrementalImportFood', 'file', file)
            const localVarPath = `/data_management/food/incremental_import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary load custom addons based in input CSV. All categories included in the CSV will be wiped and re-created if they don\'t exist
         * @param {any} file csv format - utf-8-sig
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadCustomAddons: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('appApiDataManagementLoadCustomAddons', 'file', file)
            const localVarPath = `/data_management/load_custom_addons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary load daily upload files
         * @param {any} file_01 01 excel spreadsheet with xlsx (Excel 2010+) format
         * @param {any} file_03 03 excel spreadsheet with xlsx (Excel 2010+) format
         * @param {any} file_04 04 excel spreadsheet with xlsx (Excel 2010+) format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadDailyUploadFiles: async (file_01: any, file_03: any, file_04: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file_01' is not null or undefined
            assertParamExists('appApiDataManagementLoadDailyUploadFiles', 'file_01', file_01)
            // verify required parameter 'file_03' is not null or undefined
            assertParamExists('appApiDataManagementLoadDailyUploadFiles', 'file_03', file_03)
            // verify required parameter 'file_04' is not null or undefined
            assertParamExists('appApiDataManagementLoadDailyUploadFiles', 'file_04', file_04)
            const localVarPath = `/data_management/load_daily_upload_files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file_01 !== undefined) { 
                localVarFormParams.append('file_01', file_01 as any);
            }
    
            if (file_03 !== undefined) { 
                localVarFormParams.append('file_03', file_03 as any);
            }
    
            if (file_04 !== undefined) { 
                localVarFormParams.append('file_04', file_04 as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary load food ontology and foods
         * @param {any} file excel spreadsheet with xlsx (Excel 2010+) format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadFoodAndOntologies: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('appApiDataManagementLoadFoodAndOntologies', 'file', file)
            const localVarPath = `/data_management/load_food_and_ontologies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary load food photos
         * @param {any} file image file in JPG or PNG format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadFoodPhoto: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('appApiDataManagementLoadFoodPhoto', 'file', file)
            const localVarPath = `/data_management/load_food_photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary load food photos
         * @param {any} file zip file containing food images
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadFoodPhotos: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('appApiDataManagementLoadFoodPhotos', 'file', file)
            const localVarPath = `/data_management/load_food_photos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary load usda measures info
         * @param {boolean} test_mode 
         * @param {any} file excel spreadsheet with xlsx (Excel 2010+) format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadFoodRankings: async (test_mode: boolean, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'test_mode' is not null or undefined
            assertParamExists('appApiDataManagementLoadFoodRankings', 'test_mode', test_mode)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('appApiDataManagementLoadFoodRankings', 'file', file)
            const localVarPath = `/data_management/load_rankings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (test_mode !== undefined) {
                localVarQueryParameter['test_mode'] = test_mode;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary load reference daily intake (rdi) values
         * @param {any} file excel spreadsheet with xlsx (Excel 2010+) format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadRdi: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('appApiDataManagementLoadRdi', 'file', file)
            const localVarPath = `/data_management/load_rdi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary load food substitution suggestions and resources
         * @param {any} file excel spreadsheet with xlsx (Excel 2010+) format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadSubstitutionsAndTips: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('appApiDataManagementLoadSubstitutionsAndTips', 'file', file)
            const localVarPath = `/data_management/load_substitutions_and_tips`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary load default custom addons based on usage. Includes popularity score.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadUsageBasedCustomAddons: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/data_management/load_usage_based_custom_addons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary load food entire usda food list
         * @param {any} file csv format - utf-8-sig
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadUsdaFood: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('appApiDataManagementLoadUsdaFood', 'file', file)
            const localVarPath = `/data_management/load_usda_food`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary load usda measures info
         * @param {any} file excel spreadsheet with xlsx (Excel 2010+) format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadUsdaMeasures: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('appApiDataManagementLoadUsdaMeasures', 'file', file)
            const localVarPath = `/data_management/load_usda_measures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary load usda nutrients info
         * @param {any} file csv format - utf-8-sig
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadUsdaNutritions: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('appApiDataManagementLoadUsdaNutritions', 'file', file)
            const localVarPath = `/data_management/load_usda_nutritions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary load usda nutrients info
         * @param {any} file excel spreadsheet with xlsx (Excel 2010+) format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadUsdaNutritionsXlsx: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('appApiDataManagementLoadUsdaNutritionsXlsx', 'file', file)
            const localVarPath = `/data_management/load_usda_nutritions_xlsx`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieve json formatted food options
         * @param {any} file csv format - utf-8-sig
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementPostFoodOptions: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('appApiDataManagementPostFoodOptions', 'file', file)
            const localVarPath = `/data_management/foods/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary rename food
         * @param {string} old_food_name 
         * @param {string} new_food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementPutRenameFood: async (old_food_name: string, new_food_name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'old_food_name' is not null or undefined
            assertParamExists('appApiDataManagementPutRenameFood', 'old_food_name', old_food_name)
            // verify required parameter 'new_food_name' is not null or undefined
            assertParamExists('appApiDataManagementPutRenameFood', 'new_food_name', new_food_name)
            const localVarPath = `/data_management/foods/rename`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (old_food_name !== undefined) {
                localVarQueryParameter['old_food_name'] = old_food_name;
            }

            if (new_food_name !== undefined) {
                localVarQueryParameter['new_food_name'] = new_food_name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary streaming test
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementStreamingTest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/data_management/streaming_test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary test rdi values
         * @param {RdiTestRequest} RdiTestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReferenceDailyIntakeGetCustomRdi: async (RdiTestRequest: RdiTestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'RdiTestRequest' is not null or undefined
            assertParamExists('appApiReferenceDailyIntakeGetCustomRdi', 'RdiTestRequest', RdiTestRequest)
            const localVarPath = `/data_management/rdi_test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(RdiTestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary verify all replacement items have a matching size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReplacementItemVerifyReplacementItemSizing: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/data_management/verify_replacement_item_sizing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary rebuild the cognitive search index using the rotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appUtilAzureSearchApiGetRefreshCognitiveSearchIndexByName: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/data_management/refresh_cognitive_search_index_by_name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary rebuild the cognitive search index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appUtilAzureSearchApiRefreshCognitiveSearchIndex: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/data_management/refresh_cognitive_search_index`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update the cognitive search index stats document count and storage size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appUtilAzureSearchApiUpdateDbtableIndexStats: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/data_management/update_dbtable_index_stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataManagementApi - functional programming interface
 * @export
 */
export const DataManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary validation food database fields
         * @param {any} file excel spreadsheet with xlsx (Excel 2010+) format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataManagementFieldValidation(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<{ [key: string]: string; }>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataManagementFieldValidation(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Incrementally import food data
         * @param {any} file xlsx
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataManagementIncrementalImportFood(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppApiDataManagementIncrementalImportFood200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataManagementIncrementalImportFood(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary load custom addons based in input CSV. All categories included in the CSV will be wiped and re-created if they don\'t exist
         * @param {any} file csv format - utf-8-sig
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataManagementLoadCustomAddons(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataManagementLoadCustomAddons(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary load daily upload files
         * @param {any} file_01 01 excel spreadsheet with xlsx (Excel 2010+) format
         * @param {any} file_03 03 excel spreadsheet with xlsx (Excel 2010+) format
         * @param {any} file_04 04 excel spreadsheet with xlsx (Excel 2010+) format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataManagementLoadDailyUploadFiles(file_01: any, file_03: any, file_04: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataManagementLoadDailyUploadFiles(file_01, file_03, file_04, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary load food ontology and foods
         * @param {any} file excel spreadsheet with xlsx (Excel 2010+) format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataManagementLoadFoodAndOntologies(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataManagementLoadFoodAndOntologies(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary load food photos
         * @param {any} file image file in JPG or PNG format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataManagementLoadFoodPhoto(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataManagementLoadFoodPhoto(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary load food photos
         * @param {any} file zip file containing food images
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataManagementLoadFoodPhotos(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataManagementLoadFoodPhotos(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary load usda measures info
         * @param {boolean} test_mode 
         * @param {any} file excel spreadsheet with xlsx (Excel 2010+) format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataManagementLoadFoodRankings(test_mode: boolean, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataManagementLoadFoodRankings(test_mode, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary load reference daily intake (rdi) values
         * @param {any} file excel spreadsheet with xlsx (Excel 2010+) format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataManagementLoadRdi(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataManagementLoadRdi(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary load food substitution suggestions and resources
         * @param {any} file excel spreadsheet with xlsx (Excel 2010+) format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataManagementLoadSubstitutionsAndTips(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataManagementLoadSubstitutionsAndTips(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary load default custom addons based on usage. Includes popularity score.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataManagementLoadUsageBasedCustomAddons(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataManagementLoadUsageBasedCustomAddons(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary load food entire usda food list
         * @param {any} file csv format - utf-8-sig
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataManagementLoadUsdaFood(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataManagementLoadUsdaFood(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary load usda measures info
         * @param {any} file excel spreadsheet with xlsx (Excel 2010+) format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataManagementLoadUsdaMeasures(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataManagementLoadUsdaMeasures(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary load usda nutrients info
         * @param {any} file csv format - utf-8-sig
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataManagementLoadUsdaNutritions(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataManagementLoadUsdaNutritions(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary load usda nutrients info
         * @param {any} file excel spreadsheet with xlsx (Excel 2010+) format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataManagementLoadUsdaNutritionsXlsx(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataManagementLoadUsdaNutritionsXlsx(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary retrieve json formatted food options
         * @param {any} file csv format - utf-8-sig
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataManagementPostFoodOptions(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataManagementPostFoodOptions(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary rename food
         * @param {string} old_food_name 
         * @param {string} new_food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataManagementPutRenameFood(old_food_name: string, new_food_name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataManagementPutRenameFood(old_food_name, new_food_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary streaming test
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataManagementStreamingTest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataManagementStreamingTest(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary test rdi values
         * @param {RdiTestRequest} RdiTestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReferenceDailyIntakeGetCustomRdi(RdiTestRequest: RdiTestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RdiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReferenceDailyIntakeGetCustomRdi(RdiTestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary verify all replacement items have a matching size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReplacementItemVerifyReplacementItemSizing(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReplacementItemVerifyReplacementItemSizing(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary rebuild the cognitive search index using the rotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appUtilAzureSearchApiGetRefreshCognitiveSearchIndexByName(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appUtilAzureSearchApiGetRefreshCognitiveSearchIndexByName(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary rebuild the cognitive search index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appUtilAzureSearchApiRefreshCognitiveSearchIndex(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appUtilAzureSearchApiRefreshCognitiveSearchIndex(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update the cognitive search index stats document count and storage size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appUtilAzureSearchApiUpdateDbtableIndexStats(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appUtilAzureSearchApiUpdateDbtableIndexStats(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataManagementApi - factory interface
 * @export
 */
export const DataManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataManagementApiFp(configuration)
    return {
        /**
         * 
         * @summary validation food database fields
         * @param {any} file excel spreadsheet with xlsx (Excel 2010+) format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementFieldValidation(file: any, options?: any): AxiosPromise<Array<{ [key: string]: string; }>> {
            return localVarFp.appApiDataManagementFieldValidation(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Incrementally import food data
         * @param {any} file xlsx
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementIncrementalImportFood(file: any, options?: any): AxiosPromise<AppApiDataManagementIncrementalImportFood200Response> {
            return localVarFp.appApiDataManagementIncrementalImportFood(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary load custom addons based in input CSV. All categories included in the CSV will be wiped and re-created if they don\'t exist
         * @param {any} file csv format - utf-8-sig
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadCustomAddons(file: any, options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataManagementLoadCustomAddons(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary load daily upload files
         * @param {any} file_01 01 excel spreadsheet with xlsx (Excel 2010+) format
         * @param {any} file_03 03 excel spreadsheet with xlsx (Excel 2010+) format
         * @param {any} file_04 04 excel spreadsheet with xlsx (Excel 2010+) format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadDailyUploadFiles(file_01: any, file_03: any, file_04: any, options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataManagementLoadDailyUploadFiles(file_01, file_03, file_04, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary load food ontology and foods
         * @param {any} file excel spreadsheet with xlsx (Excel 2010+) format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadFoodAndOntologies(file: any, options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataManagementLoadFoodAndOntologies(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary load food photos
         * @param {any} file image file in JPG or PNG format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadFoodPhoto(file: any, options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataManagementLoadFoodPhoto(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary load food photos
         * @param {any} file zip file containing food images
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadFoodPhotos(file: any, options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataManagementLoadFoodPhotos(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary load usda measures info
         * @param {boolean} test_mode 
         * @param {any} file excel spreadsheet with xlsx (Excel 2010+) format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadFoodRankings(test_mode: boolean, file: any, options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataManagementLoadFoodRankings(test_mode, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary load reference daily intake (rdi) values
         * @param {any} file excel spreadsheet with xlsx (Excel 2010+) format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadRdi(file: any, options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataManagementLoadRdi(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary load food substitution suggestions and resources
         * @param {any} file excel spreadsheet with xlsx (Excel 2010+) format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadSubstitutionsAndTips(file: any, options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataManagementLoadSubstitutionsAndTips(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary load default custom addons based on usage. Includes popularity score.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadUsageBasedCustomAddons(options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataManagementLoadUsageBasedCustomAddons(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary load food entire usda food list
         * @param {any} file csv format - utf-8-sig
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadUsdaFood(file: any, options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataManagementLoadUsdaFood(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary load usda measures info
         * @param {any} file excel spreadsheet with xlsx (Excel 2010+) format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadUsdaMeasures(file: any, options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataManagementLoadUsdaMeasures(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary load usda nutrients info
         * @param {any} file csv format - utf-8-sig
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadUsdaNutritions(file: any, options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataManagementLoadUsdaNutritions(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary load usda nutrients info
         * @param {any} file excel spreadsheet with xlsx (Excel 2010+) format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementLoadUsdaNutritionsXlsx(file: any, options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataManagementLoadUsdaNutritionsXlsx(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary retrieve json formatted food options
         * @param {any} file csv format - utf-8-sig
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementPostFoodOptions(file: any, options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataManagementPostFoodOptions(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary rename food
         * @param {string} old_food_name 
         * @param {string} new_food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementPutRenameFood(old_food_name: string, new_food_name: string, options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataManagementPutRenameFood(old_food_name, new_food_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary streaming test
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataManagementStreamingTest(options?: any): AxiosPromise<void> {
            return localVarFp.appApiDataManagementStreamingTest(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary test rdi values
         * @param {RdiTestRequest} RdiTestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReferenceDailyIntakeGetCustomRdi(RdiTestRequest: RdiTestRequest, options?: any): AxiosPromise<RdiResponse> {
            return localVarFp.appApiReferenceDailyIntakeGetCustomRdi(RdiTestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary verify all replacement items have a matching size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReplacementItemVerifyReplacementItemSizing(options?: any): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.appApiReplacementItemVerifyReplacementItemSizing(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary rebuild the cognitive search index using the rotation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appUtilAzureSearchApiGetRefreshCognitiveSearchIndexByName(options?: any): AxiosPromise<void> {
            return localVarFp.appUtilAzureSearchApiGetRefreshCognitiveSearchIndexByName(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary rebuild the cognitive search index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appUtilAzureSearchApiRefreshCognitiveSearchIndex(options?: any): AxiosPromise<void> {
            return localVarFp.appUtilAzureSearchApiRefreshCognitiveSearchIndex(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update the cognitive search index stats document count and storage size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appUtilAzureSearchApiUpdateDbtableIndexStats(options?: any): AxiosPromise<void> {
            return localVarFp.appUtilAzureSearchApiUpdateDbtableIndexStats(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiDataManagementFieldValidation operation in DataManagementApi.
 * @export
 * @interface DataManagementApiAppApiDataManagementFieldValidationRequest
 */
export interface DataManagementApiAppApiDataManagementFieldValidationRequest {
    /**
     * excel spreadsheet with xlsx (Excel 2010+) format
     * @type {any}
     * @memberof DataManagementApiAppApiDataManagementFieldValidation
     */
    readonly file: any
}

/**
 * Request parameters for appApiDataManagementIncrementalImportFood operation in DataManagementApi.
 * @export
 * @interface DataManagementApiAppApiDataManagementIncrementalImportFoodRequest
 */
export interface DataManagementApiAppApiDataManagementIncrementalImportFoodRequest {
    /**
     * xlsx
     * @type {any}
     * @memberof DataManagementApiAppApiDataManagementIncrementalImportFood
     */
    readonly file: any
}

/**
 * Request parameters for appApiDataManagementLoadCustomAddons operation in DataManagementApi.
 * @export
 * @interface DataManagementApiAppApiDataManagementLoadCustomAddonsRequest
 */
export interface DataManagementApiAppApiDataManagementLoadCustomAddonsRequest {
    /**
     * csv format - utf-8-sig
     * @type {any}
     * @memberof DataManagementApiAppApiDataManagementLoadCustomAddons
     */
    readonly file: any
}

/**
 * Request parameters for appApiDataManagementLoadDailyUploadFiles operation in DataManagementApi.
 * @export
 * @interface DataManagementApiAppApiDataManagementLoadDailyUploadFilesRequest
 */
export interface DataManagementApiAppApiDataManagementLoadDailyUploadFilesRequest {
    /**
     * 01 excel spreadsheet with xlsx (Excel 2010+) format
     * @type {any}
     * @memberof DataManagementApiAppApiDataManagementLoadDailyUploadFiles
     */
    readonly file_01: any

    /**
     * 03 excel spreadsheet with xlsx (Excel 2010+) format
     * @type {any}
     * @memberof DataManagementApiAppApiDataManagementLoadDailyUploadFiles
     */
    readonly file_03: any

    /**
     * 04 excel spreadsheet with xlsx (Excel 2010+) format
     * @type {any}
     * @memberof DataManagementApiAppApiDataManagementLoadDailyUploadFiles
     */
    readonly file_04: any
}

/**
 * Request parameters for appApiDataManagementLoadFoodAndOntologies operation in DataManagementApi.
 * @export
 * @interface DataManagementApiAppApiDataManagementLoadFoodAndOntologiesRequest
 */
export interface DataManagementApiAppApiDataManagementLoadFoodAndOntologiesRequest {
    /**
     * excel spreadsheet with xlsx (Excel 2010+) format
     * @type {any}
     * @memberof DataManagementApiAppApiDataManagementLoadFoodAndOntologies
     */
    readonly file: any
}

/**
 * Request parameters for appApiDataManagementLoadFoodPhoto operation in DataManagementApi.
 * @export
 * @interface DataManagementApiAppApiDataManagementLoadFoodPhotoRequest
 */
export interface DataManagementApiAppApiDataManagementLoadFoodPhotoRequest {
    /**
     * image file in JPG or PNG format
     * @type {any}
     * @memberof DataManagementApiAppApiDataManagementLoadFoodPhoto
     */
    readonly file: any
}

/**
 * Request parameters for appApiDataManagementLoadFoodPhotos operation in DataManagementApi.
 * @export
 * @interface DataManagementApiAppApiDataManagementLoadFoodPhotosRequest
 */
export interface DataManagementApiAppApiDataManagementLoadFoodPhotosRequest {
    /**
     * zip file containing food images
     * @type {any}
     * @memberof DataManagementApiAppApiDataManagementLoadFoodPhotos
     */
    readonly file: any
}

/**
 * Request parameters for appApiDataManagementLoadFoodRankings operation in DataManagementApi.
 * @export
 * @interface DataManagementApiAppApiDataManagementLoadFoodRankingsRequest
 */
export interface DataManagementApiAppApiDataManagementLoadFoodRankingsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof DataManagementApiAppApiDataManagementLoadFoodRankings
     */
    readonly test_mode: boolean

    /**
     * excel spreadsheet with xlsx (Excel 2010+) format
     * @type {any}
     * @memberof DataManagementApiAppApiDataManagementLoadFoodRankings
     */
    readonly file: any
}

/**
 * Request parameters for appApiDataManagementLoadRdi operation in DataManagementApi.
 * @export
 * @interface DataManagementApiAppApiDataManagementLoadRdiRequest
 */
export interface DataManagementApiAppApiDataManagementLoadRdiRequest {
    /**
     * excel spreadsheet with xlsx (Excel 2010+) format
     * @type {any}
     * @memberof DataManagementApiAppApiDataManagementLoadRdi
     */
    readonly file: any
}

/**
 * Request parameters for appApiDataManagementLoadSubstitutionsAndTips operation in DataManagementApi.
 * @export
 * @interface DataManagementApiAppApiDataManagementLoadSubstitutionsAndTipsRequest
 */
export interface DataManagementApiAppApiDataManagementLoadSubstitutionsAndTipsRequest {
    /**
     * excel spreadsheet with xlsx (Excel 2010+) format
     * @type {any}
     * @memberof DataManagementApiAppApiDataManagementLoadSubstitutionsAndTips
     */
    readonly file: any
}

/**
 * Request parameters for appApiDataManagementLoadUsdaFood operation in DataManagementApi.
 * @export
 * @interface DataManagementApiAppApiDataManagementLoadUsdaFoodRequest
 */
export interface DataManagementApiAppApiDataManagementLoadUsdaFoodRequest {
    /**
     * csv format - utf-8-sig
     * @type {any}
     * @memberof DataManagementApiAppApiDataManagementLoadUsdaFood
     */
    readonly file: any
}

/**
 * Request parameters for appApiDataManagementLoadUsdaMeasures operation in DataManagementApi.
 * @export
 * @interface DataManagementApiAppApiDataManagementLoadUsdaMeasuresRequest
 */
export interface DataManagementApiAppApiDataManagementLoadUsdaMeasuresRequest {
    /**
     * excel spreadsheet with xlsx (Excel 2010+) format
     * @type {any}
     * @memberof DataManagementApiAppApiDataManagementLoadUsdaMeasures
     */
    readonly file: any
}

/**
 * Request parameters for appApiDataManagementLoadUsdaNutritions operation in DataManagementApi.
 * @export
 * @interface DataManagementApiAppApiDataManagementLoadUsdaNutritionsRequest
 */
export interface DataManagementApiAppApiDataManagementLoadUsdaNutritionsRequest {
    /**
     * csv format - utf-8-sig
     * @type {any}
     * @memberof DataManagementApiAppApiDataManagementLoadUsdaNutritions
     */
    readonly file: any
}

/**
 * Request parameters for appApiDataManagementLoadUsdaNutritionsXlsx operation in DataManagementApi.
 * @export
 * @interface DataManagementApiAppApiDataManagementLoadUsdaNutritionsXlsxRequest
 */
export interface DataManagementApiAppApiDataManagementLoadUsdaNutritionsXlsxRequest {
    /**
     * excel spreadsheet with xlsx (Excel 2010+) format
     * @type {any}
     * @memberof DataManagementApiAppApiDataManagementLoadUsdaNutritionsXlsx
     */
    readonly file: any
}

/**
 * Request parameters for appApiDataManagementPostFoodOptions operation in DataManagementApi.
 * @export
 * @interface DataManagementApiAppApiDataManagementPostFoodOptionsRequest
 */
export interface DataManagementApiAppApiDataManagementPostFoodOptionsRequest {
    /**
     * csv format - utf-8-sig
     * @type {any}
     * @memberof DataManagementApiAppApiDataManagementPostFoodOptions
     */
    readonly file: any
}

/**
 * Request parameters for appApiDataManagementPutRenameFood operation in DataManagementApi.
 * @export
 * @interface DataManagementApiAppApiDataManagementPutRenameFoodRequest
 */
export interface DataManagementApiAppApiDataManagementPutRenameFoodRequest {
    /**
     * 
     * @type {string}
     * @memberof DataManagementApiAppApiDataManagementPutRenameFood
     */
    readonly old_food_name: string

    /**
     * 
     * @type {string}
     * @memberof DataManagementApiAppApiDataManagementPutRenameFood
     */
    readonly new_food_name: string
}

/**
 * Request parameters for appApiReferenceDailyIntakeGetCustomRdi operation in DataManagementApi.
 * @export
 * @interface DataManagementApiAppApiReferenceDailyIntakeGetCustomRdiRequest
 */
export interface DataManagementApiAppApiReferenceDailyIntakeGetCustomRdiRequest {
    /**
     * 
     * @type {RdiTestRequest}
     * @memberof DataManagementApiAppApiReferenceDailyIntakeGetCustomRdi
     */
    readonly RdiTestRequest: RdiTestRequest
}

/**
 * DataManagementApi - object-oriented interface
 * @export
 * @class DataManagementApi
 * @extends {BaseAPI}
 */
export class DataManagementApi extends BaseAPI {
    /**
     * 
     * @summary validation food database fields
     * @param {DataManagementApiAppApiDataManagementFieldValidationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataManagementApi
     */
    public appApiDataManagementFieldValidation(requestParameters: DataManagementApiAppApiDataManagementFieldValidationRequest, options?: AxiosRequestConfig) {
        return DataManagementApiFp(this.configuration).appApiDataManagementFieldValidation(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Incrementally import food data
     * @param {DataManagementApiAppApiDataManagementIncrementalImportFoodRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataManagementApi
     */
    public appApiDataManagementIncrementalImportFood(requestParameters: DataManagementApiAppApiDataManagementIncrementalImportFoodRequest, options?: AxiosRequestConfig) {
        return DataManagementApiFp(this.configuration).appApiDataManagementIncrementalImportFood(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary load custom addons based in input CSV. All categories included in the CSV will be wiped and re-created if they don\'t exist
     * @param {DataManagementApiAppApiDataManagementLoadCustomAddonsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataManagementApi
     */
    public appApiDataManagementLoadCustomAddons(requestParameters: DataManagementApiAppApiDataManagementLoadCustomAddonsRequest, options?: AxiosRequestConfig) {
        return DataManagementApiFp(this.configuration).appApiDataManagementLoadCustomAddons(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary load daily upload files
     * @param {DataManagementApiAppApiDataManagementLoadDailyUploadFilesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataManagementApi
     */
    public appApiDataManagementLoadDailyUploadFiles(requestParameters: DataManagementApiAppApiDataManagementLoadDailyUploadFilesRequest, options?: AxiosRequestConfig) {
        return DataManagementApiFp(this.configuration).appApiDataManagementLoadDailyUploadFiles(requestParameters.file_01, requestParameters.file_03, requestParameters.file_04, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary load food ontology and foods
     * @param {DataManagementApiAppApiDataManagementLoadFoodAndOntologiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataManagementApi
     */
    public appApiDataManagementLoadFoodAndOntologies(requestParameters: DataManagementApiAppApiDataManagementLoadFoodAndOntologiesRequest, options?: AxiosRequestConfig) {
        return DataManagementApiFp(this.configuration).appApiDataManagementLoadFoodAndOntologies(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary load food photos
     * @param {DataManagementApiAppApiDataManagementLoadFoodPhotoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataManagementApi
     */
    public appApiDataManagementLoadFoodPhoto(requestParameters: DataManagementApiAppApiDataManagementLoadFoodPhotoRequest, options?: AxiosRequestConfig) {
        return DataManagementApiFp(this.configuration).appApiDataManagementLoadFoodPhoto(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary load food photos
     * @param {DataManagementApiAppApiDataManagementLoadFoodPhotosRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataManagementApi
     */
    public appApiDataManagementLoadFoodPhotos(requestParameters: DataManagementApiAppApiDataManagementLoadFoodPhotosRequest, options?: AxiosRequestConfig) {
        return DataManagementApiFp(this.configuration).appApiDataManagementLoadFoodPhotos(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary load usda measures info
     * @param {DataManagementApiAppApiDataManagementLoadFoodRankingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataManagementApi
     */
    public appApiDataManagementLoadFoodRankings(requestParameters: DataManagementApiAppApiDataManagementLoadFoodRankingsRequest, options?: AxiosRequestConfig) {
        return DataManagementApiFp(this.configuration).appApiDataManagementLoadFoodRankings(requestParameters.test_mode, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary load reference daily intake (rdi) values
     * @param {DataManagementApiAppApiDataManagementLoadRdiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataManagementApi
     */
    public appApiDataManagementLoadRdi(requestParameters: DataManagementApiAppApiDataManagementLoadRdiRequest, options?: AxiosRequestConfig) {
        return DataManagementApiFp(this.configuration).appApiDataManagementLoadRdi(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary load food substitution suggestions and resources
     * @param {DataManagementApiAppApiDataManagementLoadSubstitutionsAndTipsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataManagementApi
     */
    public appApiDataManagementLoadSubstitutionsAndTips(requestParameters: DataManagementApiAppApiDataManagementLoadSubstitutionsAndTipsRequest, options?: AxiosRequestConfig) {
        return DataManagementApiFp(this.configuration).appApiDataManagementLoadSubstitutionsAndTips(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary load default custom addons based on usage. Includes popularity score.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataManagementApi
     */
    public appApiDataManagementLoadUsageBasedCustomAddons(options?: AxiosRequestConfig) {
        return DataManagementApiFp(this.configuration).appApiDataManagementLoadUsageBasedCustomAddons(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary load food entire usda food list
     * @param {DataManagementApiAppApiDataManagementLoadUsdaFoodRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataManagementApi
     */
    public appApiDataManagementLoadUsdaFood(requestParameters: DataManagementApiAppApiDataManagementLoadUsdaFoodRequest, options?: AxiosRequestConfig) {
        return DataManagementApiFp(this.configuration).appApiDataManagementLoadUsdaFood(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary load usda measures info
     * @param {DataManagementApiAppApiDataManagementLoadUsdaMeasuresRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataManagementApi
     */
    public appApiDataManagementLoadUsdaMeasures(requestParameters: DataManagementApiAppApiDataManagementLoadUsdaMeasuresRequest, options?: AxiosRequestConfig) {
        return DataManagementApiFp(this.configuration).appApiDataManagementLoadUsdaMeasures(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary load usda nutrients info
     * @param {DataManagementApiAppApiDataManagementLoadUsdaNutritionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataManagementApi
     */
    public appApiDataManagementLoadUsdaNutritions(requestParameters: DataManagementApiAppApiDataManagementLoadUsdaNutritionsRequest, options?: AxiosRequestConfig) {
        return DataManagementApiFp(this.configuration).appApiDataManagementLoadUsdaNutritions(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary load usda nutrients info
     * @param {DataManagementApiAppApiDataManagementLoadUsdaNutritionsXlsxRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataManagementApi
     */
    public appApiDataManagementLoadUsdaNutritionsXlsx(requestParameters: DataManagementApiAppApiDataManagementLoadUsdaNutritionsXlsxRequest, options?: AxiosRequestConfig) {
        return DataManagementApiFp(this.configuration).appApiDataManagementLoadUsdaNutritionsXlsx(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary retrieve json formatted food options
     * @param {DataManagementApiAppApiDataManagementPostFoodOptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataManagementApi
     */
    public appApiDataManagementPostFoodOptions(requestParameters: DataManagementApiAppApiDataManagementPostFoodOptionsRequest, options?: AxiosRequestConfig) {
        return DataManagementApiFp(this.configuration).appApiDataManagementPostFoodOptions(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary rename food
     * @param {DataManagementApiAppApiDataManagementPutRenameFoodRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataManagementApi
     */
    public appApiDataManagementPutRenameFood(requestParameters: DataManagementApiAppApiDataManagementPutRenameFoodRequest, options?: AxiosRequestConfig) {
        return DataManagementApiFp(this.configuration).appApiDataManagementPutRenameFood(requestParameters.old_food_name, requestParameters.new_food_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary streaming test
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataManagementApi
     */
    public appApiDataManagementStreamingTest(options?: AxiosRequestConfig) {
        return DataManagementApiFp(this.configuration).appApiDataManagementStreamingTest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary test rdi values
     * @param {DataManagementApiAppApiReferenceDailyIntakeGetCustomRdiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataManagementApi
     */
    public appApiReferenceDailyIntakeGetCustomRdi(requestParameters: DataManagementApiAppApiReferenceDailyIntakeGetCustomRdiRequest, options?: AxiosRequestConfig) {
        return DataManagementApiFp(this.configuration).appApiReferenceDailyIntakeGetCustomRdi(requestParameters.RdiTestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary verify all replacement items have a matching size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataManagementApi
     */
    public appApiReplacementItemVerifyReplacementItemSizing(options?: AxiosRequestConfig) {
        return DataManagementApiFp(this.configuration).appApiReplacementItemVerifyReplacementItemSizing(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary rebuild the cognitive search index using the rotation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataManagementApi
     */
    public appUtilAzureSearchApiGetRefreshCognitiveSearchIndexByName(options?: AxiosRequestConfig) {
        return DataManagementApiFp(this.configuration).appUtilAzureSearchApiGetRefreshCognitiveSearchIndexByName(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary rebuild the cognitive search index
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataManagementApi
     */
    public appUtilAzureSearchApiRefreshCognitiveSearchIndex(options?: AxiosRequestConfig) {
        return DataManagementApiFp(this.configuration).appUtilAzureSearchApiRefreshCognitiveSearchIndex(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update the cognitive search index stats document count and storage size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataManagementApi
     */
    public appUtilAzureSearchApiUpdateDbtableIndexStats(options?: AxiosRequestConfig) {
        return DataManagementApiFp(this.configuration).appUtilAzureSearchApiUpdateDbtableIndexStats(options).then((request) => request(this.axios, this.basePath));
    }
}
