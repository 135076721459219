import { Check, InfoOutlined } from '@mui/icons-material';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { StackProps } from '@mui/system';
import {
  FoodOption,
  FoodOptionCategory,
  FoodOptionCollection,
  FoodOptionNodeType,
  FoodOptionRestriction,
  FoodOptionRestrictionTargetType,
  FoodOptionRestrictionType,
  FoodOptionValue,
} from 'api/generated/MNT';
import useLocalStorage from 'hooks/useLocalStorage';
import * as React from 'react';

const HoverStack = styled(Stack)<StackProps>(({ theme }) => ({
  '&:nth-child(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const restrictionStyles = (restrictions: RestrictionResolution[]) => {
  return {
    backgroundColor: restrictions.find((r) => r.type == 'ui-change' && r.highlighted) ? '#e1f5fe' : undefined,
    opacity: restrictions.find((r) => r.type == 'ui-change' && r.hidden) ? 0.5 : undefined,
  };
};

export const InfoIcon = (props: { marginTop?: number }) => {
  return (
    <InfoOutlined
      fontSize="small"
      color="secondary"
      style={{
        marginLeft: 4,
        marginTop: props.marginTop || 0,
      }}
    />
  );
};

type OptionValueType = string | string[];
type FoodOptionSelectedValues = Record<string, string | string[]>;

const FoodOptionValueItem = (props: {
  optionValue: FoodOptionValue,
  onClick: () => void,
  control: React.ReactElement,
  value?: OptionValueType,
  checked: boolean,
  restrictionResolutions: RestrictionResolutionDB,
}) => {
  const { optionValue } = props;
  const { debug } = useFoodOptionsContext();
  const appliedRestrictionResolutions = props.restrictionResolutions.byTarget[optionValue.id] || [];
  const highlight = appliedRestrictionResolutions.find((r) => r.type == 'ui-change' && r.highlighted);
  const hidden = appliedRestrictionResolutions.find((r) => r.type == 'ui-change' && r.hidden);
  const style = restrictionStyles(appliedRestrictionResolutions);
  if (hidden && props.checked) {
    style.backgroundColor = '#ef9a9a';
    style.opacity = 1;
  }

  const onClick = (event: React.MouseEvent) => {
    if ((event.target as any).tagName != 'INPUT') {
      // When the label is clicked, two events are fired: one for the label and one for the input.
      // We only want to handle the input event.
      return;
    }
    props.onClick();
  };

  return (
    <Tooltip title={optionValue.description}>
      <Stack>
        <FormControlLabel
          checked={props.checked}
          value={props.value}
          onClick={onClick}
          control={props.control}
          style={style}
          label={
            <>
              {optionValue.label}
              {optionValue.description && <InfoOutlined />}
            </>
          }
        />
        {hidden && (
          <Typography variant="caption" color="text.secondary">
            Hidden: {hidden.sources.join(', ')}
          </Typography>
        )}
        {debug && highlight && (
          <Typography variant="caption" color="text.secondary">
            HL: {highlight.sources.join(', ')}
          </Typography>
        )}
        {!hidden && !highlight && <RestrictionsDisplay restrictions={optionValue.restrictions} />}
      </Stack>
    </Tooltip>
  );
};

export const FoodOptionValueEditor = (props: {
  schema: FoodOptionCollection,
  option: FoodOption,
  onChange: (value: string) => void,
  value: OptionValueType,
  restrictionResolutions: RestrictionResolutionDB,
}) => {
  const { schema, option, value } = props;

  return (
    <Grid container key={option.id} spacing={1}>
      {option.cardinality == 'single' && (
        <Grid item>
          <RadioGroup
            value={value || ''}
            row
          >
            {schema.values
              .filter((optionValue) => optionValue.option_id === option.id)
              .map((optionValue) => (
                <FoodOptionValueItem
                  key={optionValue.id}
                  optionValue={optionValue}
                  onClick={() => {
                    console.log('click', optionValue.value);
                    props.onChange(optionValue.value || '');
                  }}
                  control={<Radio color="primary" />}
                  value={optionValue.value || ''}
                  checked={optionValue.value === value}
                  restrictionResolutions={props.restrictionResolutions}
                />
              ))}
          </RadioGroup>
        </Grid>
      )}
      {option.cardinality == 'multiple' && (
        schema.values
          .filter((optionValue) => optionValue.option_id === option.id)
          .map((optionValue) => {
            const isSelected = (value as string[] || []).includes(optionValue.value || '');
            return (
              <Grid item key={optionValue.id}>
                <FoodOptionValueItem
                  optionValue={optionValue}
                  onClick={() => {
                    console.log('click', optionValue.value);
                    props.onChange(optionValue.value || '');
                  }}
                  control={<Checkbox color="primary" />}
                  checked={isSelected}
                  restrictionResolutions={props.restrictionResolutions}
                />
              </Grid>
            );
          })
      )}
    </Grid>
  );
};

const useDebugLogValueChange = (name: string, value: any) => {
  React.useEffect(() => {
    console.log(`${name} changed to ${value}`);
  }, [name, value]);
};

export const FoodOptionCategoryEditor = (props: {
  schema: FoodOptionCollection,
  category: FoodOptionCategory,
  values: FoodOptionSelectedValues,
  restrictionResolutions: RestrictionResolutionDB,
  onChange: (optionId: string, value: string) => void,
}) => {
  const options = React.useMemo(() => {
    return props.schema.options.filter((option) => option.category_id === props.category.id);
  }, [props.schema.options, props.category.id]);

  const values = React.useMemo(() => {
    const res = {} as FoodOptionSelectedValues;
    options.forEach((option) => {
      res[option.id] = props.values[option.id];
    });
    return res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, options.map(option => props.values[option.id]));

  const restrictionResolutions = React.useMemo(() => {
    const res: RestrictionResolutionDB = {
      byTarget: {},
      byOption: {},
    };
    options.forEach((option) => {
      props.restrictionResolutions.byOption[option.id]?.forEach((resolution) => {
        res.byTarget[resolution.targetId] = res.byTarget[resolution.targetId] || [];
        res.byTarget[resolution.targetId].push(resolution);
        res.byOption[resolution.optionId] = res.byOption[resolution.optionId] || [];
        res.byOption[resolution.optionId].push(resolution);
      });
    });
    return res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, options.map(option => props.restrictionResolutions.byOption[option.id]));

  return (
    <FoodOptionCategoryEditorMemoized
      schema={props.schema}
      options={options}
      category={props.category}
      values={values}
      restrictionResolutions={restrictionResolutions}
      onChange={props.onChange}
    />
  );
};

const RestrictionsDisplay = (props: { restrictions: FoodOptionRestriction[] }) => {
  const { debug } = useFoodOptionsContext();
  if (!debug) {
    return null;
  }

  return !!props.restrictions && (
    <Stack>
      {props.restrictions.map((r, idx) => (
        <Typography key={idx} variant="caption" color="text.secondary">
          {r.source_string}
        </Typography>
      ))}
    </Stack>
  );
};

export const FoodOptionCategoryEditorMemoized = React.memo((props: {
  schema: FoodOptionCollection,
  category: FoodOptionCategory,
  options: FoodOption[],
  onChange: (optionId: string, value: string) => void,
  values: FoodOptionSelectedValues,
  restrictionResolutions: RestrictionResolutionDB,
}) => {
  const { schema, options, category, values } = props;
  const { debug } = useFoodOptionsContext();

  return (
    <Stack spacing={1}>
      {options.map((option) => {
        const appliedRestrictionResolutions = props.restrictionResolutions.byTarget[option.id] || [];
        const highlight = appliedRestrictionResolutions.find((r) => r.type == 'ui-change' && r.highlighted);
        const hidden = appliedRestrictionResolutions.find((r) => r.type == 'ui-change' && r.hidden);
        const hasValue = Array.isArray(values[option.id]) ? values[option.id].length > 0 : !!values[option.id];

        return (
          <HoverStack
            key={option.id}
            direction="row"
            sx={{
              m: 0,
              pt: 1,
              pb: 1,
              pl: 1,
            }}
            style={restrictionStyles(appliedRestrictionResolutions)}
          >
            <Tooltip title={option.description}>
              <Stack>
                <Stack
                  direction="row"
                  alignContent="center"
                  sx={{ alignContent: 'center', width: 150, pr: 1 }}
                >
                  <Typography>
                    {option.label}
                  </Typography>
                  {option.description && <InfoIcon />}
                </Stack>
                <RestrictionsDisplay restrictions={option.restrictions} />
              </Stack>
            </Tooltip>
            <Stack>
              {hidden && (
                <Typography variant="body1" color="text.primary">
                  Hidden because: {hidden.sources.join(', ')}
                </Typography>
              )}
              {debug && highlight && (
                <Typography variant="body1" color="text.primary">
                  Highlighted because: {highlight.sources.join(', ')}
                </Typography>
              )}
              {(!hidden || hasValue) && (
                <FoodOptionValueEditor
                  schema={schema}
                  option={option}
                  value={values[option.id]}
                  onChange={(value) => props.onChange(option.id, value)}
                  restrictionResolutions={props.restrictionResolutions}
                />
              )}
            </Stack>
          </HoverStack>
        );
      })}
    </Stack>
  );
});

type RestrictionResolutionBase<Name, Props> = {
  type: Name,
  targetId: string,
  optionId: string,
  messages: string[],
  sources: string[],
} & Props;

type RestrictionResolutionUIChange = RestrictionResolutionBase<'ui-change', {
  hidden?: boolean,
  highlighted?: boolean,
}>;

type RestrictionResolutionOptionValueChange = RestrictionResolutionBase<'value-change', {
  includeValueIds: string[],
  excludeValueIds: string[],
}>;

type RestrictionResolution = RestrictionResolutionUIChange | RestrictionResolutionOptionValueChange;

/**
 * Determines the state of the option based on its restrictions.
 */
const foodOptionsResolveRestrictions = (
  schema: FoodOptionCollection,
  values: FoodOptionSelectedValues,
): RestrictionResolution[] => {
  const res: RestrictionResolution[] = [];

  schema.options.forEach((option) => {
    const optionRes = resolveRestrictions(schema, values, option);
    res.push(...optionRes);
  });

  schema.values.forEach((optionValue) => {
    const optionRes = resolveRestrictions(schema, values, optionValue);
    res.push(...optionRes);
  });

  return res;
};

const resolveRestrictions = (
  schema: FoodOptionCollection,
  values: FoodOptionSelectedValues,
  node: FoodOption | FoodOptionValue,
): RestrictionResolution[] => {
  const res: RestrictionResolution[] = [];

  const nodeOptionId = node.type == 'FoodOptionValue'
    ? node.option_id!
    : node.type == 'FoodOption'
    ? node.id
    : null;

  if (!nodeOptionId) {
    console.warn(`Node type '${node.type}' not supported`);
    return [];
  }

  const nodeOptionValue = values[nodeOptionId];
  const nodeValueIsSelected = node.type == 'FoodOptionValue' && (
    Array.isArray(nodeOptionValue)
      ? nodeOptionValue.includes(node.value!)
      : nodeOptionValue == node.value
  );

  node.restrictions.forEach((restriction) => {
    if (restriction.target_type != FoodOptionRestrictionTargetType.FoodOption) {
      console.warn(`Restriction type '${restriction.type}' not supported: ${restriction.source_string}`);
      return;
    }

    if (!restriction.target_value) {
      console.warn(`Restriction target value not set: ${restriction.source_string}`);
      return;
    }

    const restrictionTargetOptionId = restriction.target_id;
    const restrictionTargetValueId = `${restriction.target_id}:${restriction.target_value}`;
    const restrictionTargetOptionValue = values[restrictionTargetOptionId];
    const restrictionTargetOptionValueMatchesRestriction = !!restrictionTargetOptionValue && (
      typeof restrictionTargetOptionValue === 'string'
        ? restrictionTargetOptionValue === restriction.target_value
        : restrictionTargetOptionValue.includes(restriction.target_value)
    );

    switch (restriction.type) {
      case FoodOptionRestrictionType.ExcludedBy:
        restrictionTargetOptionValueMatchesRestriction && res.push({
          type: 'ui-change',
          targetId: node.id,
          optionId: nodeOptionId,
          hidden: true,
          messages: [`Excluded by ${restriction.source_string}`],
          sources: [`${restriction.target_id} = ${restriction.target_value}`],
        });
        break;

      case FoodOptionRestrictionType.Suggests:
      case FoodOptionRestrictionType.Implies:
        // For implies, the "source" will be a value, and the "target" will be
        // either an option or a value.
        if (node.type != 'FoodOptionValue') {
          console.warn(
            `Restriction type '${restriction.type}' not supported for non-value nodes: ${restriction.source_string}`,
          );
          break;
        }
        res.push({
          type: 'ui-change',
          targetId: restrictionTargetValueId || restrictionTargetOptionId,
          optionId: restrictionTargetOptionId,
          highlighted: nodeValueIsSelected,
          messages: [`Suggested by ${restriction.source_string}`],
          sources: [`${nodeOptionId} = ${node.value}`],
        });
        break;

      case FoodOptionRestrictionType.SuggestedBy:
      case FoodOptionRestrictionType.ImpliedBy:
        const cmp = restrictionTargetOptionValueMatchesRestriction ? '=' : '!=';
        res.push({
          type: 'ui-change',
          targetId: node.id,
          optionId: nodeOptionId,
          highlighted: restrictionTargetOptionValueMatchesRestriction,
          hidden: (
            restriction.type == FoodOptionRestrictionType.ImpliedBy
            && !restrictionTargetOptionValueMatchesRestriction
          ),
          messages: [`Suggested by ${restriction.source_string}`],
          sources: [`${restriction.target_id} ${cmp} ${restriction.target_value}`],
        });
        break;

      default:
        console.warn(`Restriction type '${restriction.type}' not supported: ${restriction.source_string}`);
    }
  });

  return res;
};

interface RestrictionResolutionDB {
  byTarget: Record<string, RestrictionResolution[]>;
  byOption: Record<string, RestrictionResolution[]>;
}

const FoodOptionsContext = React.createContext<{
  debug: boolean,
}>({
  debug: false,
});

const useFoodOptionsContext = () => React.useContext(FoodOptionsContext);

export const FoodOptionEditor = (props: {
  schema: FoodOptionCollection,
  onChange: (optionId: string, value: string) => void,
  values: FoodOptionSelectedValues,
  hideDebugOption?: boolean,
}) => {
  const [debug, setDebug] = useLocalStorage('food-options-debug', false);
  const debugContext = React.useMemo(() => ({ debug }), [debug]);

  const schema: FoodOptionCollection = React.useMemo(() => {
    // For backwards compatibility, we need to add the type to the schema
    return {
      ...props.schema,
      categories: props.schema.categories.map((c) => ({
        ...c,
        type: 'FoodOptionCategory' as const,
      })),
      options: props.schema.options.map((o) => ({
        ...o,
        type: 'FoodOption' as const,
      })),
      values: props.schema.values.map((v) => ({
        ...v,
        type: 'FoodOptionValue' as const,
      })),
    };
  }, [props.schema]);

  const restrictionResolutions = React.useMemo(() => {
    const res: RestrictionResolutionDB = {
      byTarget: {},
      byOption: {},
    };
    foodOptionsResolveRestrictions(schema, props.values).forEach((r) => {
      res.byTarget[r.targetId] = res.byTarget[r.targetId] || [];
      res.byTarget[r.targetId].push(r);
      res.byOption[r.optionId] = res.byOption[r.optionId] || [];
      res.byOption[r.optionId].push(r);
    });
    console.log('Restrictions:', res.byTarget);
    return res;
  }, [schema, props.values]);

  return (
    <FoodOptionsContext.Provider value={debugContext}>
      <Stack spacing={4}>
        <FormGroup>
          {!props.hideDebugOption && (
            <FormControlLabel
              control={<Checkbox />}
              label="Show debug inclusions/exclusions"
              checked={debug}
              onChange={(e) => setDebug(!debug)}
            />
          )}
        </FormGroup>
        {schema.categories.map((category) => (
          <div key={category.id}>
            <Typography variant="h4">
              <Tooltip title={category.description}>
                <span>
                  {category.label}
                  {category.description && <InfoIcon marginTop={-4} />}
                </span>
              </Tooltip>
            </Typography>
            <FoodOptionCategoryEditor
              schema={schema}
              category={category}
              values={props.values}
              restrictionResolutions={restrictionResolutions}
              onChange={props.onChange}
            />
          </div>
        ))}
      </Stack>
    </FoodOptionsContext.Provider>
  );
};
