import { useState } from 'react';
import { useTheme } from '@mui/material/styles';

export const ClickablePatientNote = (props: {
  value: string,
  onClick: (value: string) => void,
}) => {
  const [clickedNotes, setClickedNotes] = useState<string[]>([]);
  const theme = useTheme();
  const { value } = props;
  const noteSplit = value.split(/(\s*(?:\.|\n|,|;|\bwith\b|\band\b)\s*)/);
  return (
    <>
      {noteSplit.map((x, i) => (
        i % 2 == 1
          ? <span key={i}>{x}</span>
          : (
            <span
              key={i}
              style={{
                textDecoration: clickedNotes.includes(x) ? 'underline dotted' : 'underline',
                cursor: 'pointer',
                color: clickedNotes.includes(x) ? theme.palette.success.dark : theme.palette.primary.main,
              }}
              onClick={() => {
                if (!clickedNotes.includes(x)) {
                  setClickedNotes([...clickedNotes, x]);
                }
                return props.onClick(x);
              }}
            >
              {x}
            </span>
          )
      ))}
    </>
  );
};
