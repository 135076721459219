import { Button, FormHelperText, Stack, Switch, Typography } from '@mui/material';
import { FoodRowMixedDishComponents } from 'api/generated/MNT';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import useErrorState from '../hooks/use-error-state';
import mixedDishMustTotalOneHundred from '../validations/mixed-dish-must-total-one-hundred';
import { FoodEditorState } from './food-editor';
import MixedDishComponentEditor from './mixed-dish-component-editor';

type MixedDishOption = {
  label: string,
  color: string,
  key: keyof FoodRowMixedDishComponents,
};

const mixedDishComponentsOptions: MixedDishOption[] = [
  {
    color: '#FFDC00',
    label: 'Grains',
    key: 'cc_grains',
  },
  {
    color: '#e35015',
    label: 'Fruit',
    key: 'cc_fruit',
  },
  {
    color: '#9ddb99',
    label: 'Vegetable',
    key: 'cc_vegetable',
  },
  {
    color: '#FF9024',
    label: 'Protein',
    key: 'cc_protein',
  },
  {
    color: '#ba9443',
    label: 'Nuts & seeds',
    key: 'cc_nuts_seeds',
  },
  {
    color: '#e8c9c7',
    label: 'Poultry',
    key: 'cc_poultry',
  },
  {
    color: '#9E79D2',
    label: 'Non-meat animal protein',
    key: 'cc_non_meat_animal_protein',
  },
  {
    color: '#7DD279',
    label: 'Legume',
    key: 'cc_legume',
  },
  {
    color: '#79B5D2',
    label: 'Fish',
    key: 'cc_fish',
  },
  {
    color: '#ff92bf',
    label: 'Pork',
    key: 'cc_pork',
  },
  {
    color: '#e0003a',
    label: 'Redmeat',
    key: 'cc_redmeat',
  },
  {
    color: '#805c0f',
    label: 'Meat',
    key: 'cc_meat',
  },
  {
    color: '#f6c8a1',
    label: 'Shellfish',
    key: 'cc_shellfish',
  },
];

export default function MixedDishComponentsEditor(props: {
  onChangeValue: (newValue: FoodRowMixedDishComponents) => void,
  showErrors?: boolean,
  value: FoodRowMixedDishComponents,
  onBlur?: () => void,
  state: FoodEditorState,
}) {
  const { onChangeValue, showErrors, value, onBlur, state } = props;
  const dirtyComponents = useRef(new Set<string>());
  const errorState = useErrorState(
    value,
    showErrors,
    mixedDishMustTotalOneHundred,
  );
  const [hasScaled, setHasScaled] = useState<boolean>(false);
  const hasBeenOverridden = 'mixedDishComponents' in (state.value.overrideValues || {});
  const isCompositeFood = state.value.definitionType == 'composite';

  useEffect(() => {
    if (hasScaled && onBlur) {
      onBlur();
    }
  }, [hasScaled]);

  const handleOverrideValues = (value: FoodRowMixedDishComponents) => {
    if (hasBeenOverridden) {
      state.onFieldChange({
        overrideValues: { ...state.value.overrideValues, mixedDishComponents: value },
      });
    }
  };

  const handleOverrideSwitch = () => {
    if (!hasBeenOverridden) {
      state.onFieldChange({
        overrideValues: { ...state.value.overrideValues, mixedDishComponents: state.value.mixedDishComponents },
      });
    } else {
      const newOverrideValues = Object.fromEntries(
        Object.entries(state.value.overrideValues || {}).filter(([key, value]) => key != 'mixedDishComponents'),
      );
      state.onFieldChange({
        overrideValues: _.isEmpty(newOverrideValues) ? null : newOverrideValues,
      });
    }
  };

  const handleComponentChange = (
    type: keyof FoodRowMixedDishComponents,
    partialNewValue: Partial<FoodRowMixedDishComponents>,
  ) => {
    dirtyComponents.current.add(type);
    errorState.markDirty();
    const newValue = {
      ...value,
      ...partialNewValue,
    };
    const newValueNoZeros = Object.fromEntries(
      Object.entries(newValue)
        .filter(([, value]) => value !== 0),
    );
    setHasScaled(false);
    handleOverrideValues(newValueNoZeros);
    onChangeValue(newValueNoZeros);
  };

  const scaleValues = () => {
    const total = Object.values(value).reduce((acc, val) => acc + val, 0);
    const scale = 1 / total;
    const newValue = Object.fromEntries(
      Object.entries(value).map(([key, val]) => [key, val * scale]),
    );
    handleOverrideValues(newValue);
    onChangeValue(newValue);
    setHasScaled(true);
  };

  return (
    <Stack spacing={1}>
      {isCompositeFood && (
        <Stack direction="row" justifyContent="space-between" spacing={1} sx={{ paddingBottom: 1 }}>
          <Typography variant="h5" fontWeight="normal">Override Values?</Typography>
          <Switch
            checked={hasBeenOverridden}
            onChange={() => {
              handleOverrideSwitch();
            }}
          />
        </Stack>
      )}
      {mixedDishComponentsOptions.map(o => (
        <MixedDishComponentEditor
          key={o.key}
          color={o.color}
          label={o.label}
          value={value[o.key] || null}
          onChangeValue={newValue => {
            handleComponentChange(o.key, { [o.key]: newValue });
          }}
          onBlur={onBlur}
          disabled={isCompositeFood ? !hasBeenOverridden : false}
        />
      ))}
      {errorState.showError && (
        <FormHelperText>
          {'' + errorState.error}
          <br />
          <Button variant="outlined" onClick={scaleValues}>Scale values to 100%</Button>
        </FormHelperText>
      )}
    </Stack>
  );
}
