import { Alert, AlertTitle, Button, Container, Stack, Typography, useTheme } from '@mui/material';
import { FormEvent, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FoodEditor, { FoodResponseCallbackRef } from '../food-editor/components/food-editor';
import useRenameFoodMutation from '../food-editor/hooks/use-rename-food-mutation';
import emptyFoodEditorValue from '../food-editor/utils/empty-food-editor-value';
import foodEditorValueToFoodRequest from '../food-editor/utils/food-editor-value-to-food-request';

import * as Sentry from '@sentry/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { sentryReplay, useSentryReplay } from 'analytics';
import { foodApi, mealApi } from 'api/index';
import MainCard from 'components/MainCard';
import { logTrackedError } from 'errorTracking';
import apiClient from 'food-editor/api-client';
import { FoodEditorFoodRequest, FoodEditorFoodResponse, QueryKey } from 'food-editor/types';
import { foodEditorGetErrors } from 'food-editor/utils/is-food-editor-valid';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import { getFoodDetailsQueryKey } from 'services/FoodDetailsService';
import { parseQueryError } from 'utils';
import { getFoodEditorUrl } from './QueueItem/meal-builder/MealSummary';

export const CreateFoodPage = () => {
  useSentryReplay();

  return <CreateFoodForm />;
};

const CreateFoodForm = () => {
  useSetPageTitle('', 'new food');

  const navigate = useNavigate();

  const theme = useTheme();
  const queryParams = new URLSearchParams(window.location.search);
  const [editorValue, setEditorValue] = useState({
    ...emptyFoodEditorValue(),
    status: 'triaged',
    term: queryParams.get('initial-name') ?? '',
  });
  const [nextAction, setNextAction] = useState<'return' | 'edit'>('return');
  const editorErrors = foodEditorGetErrors(editorValue);

  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();
  const originalName = searchParams.get('initial-name');

  const createFoodMutation = useMutation({
    mutationFn: async (args: {
      term: string,
      foodRequest: FoodEditorFoodRequest,
    }) => {
      return apiClient.updateFood(args.term, args.foodRequest);
    },
    onSuccess: foodResponse => {
      queryClient.setQueryData([QueryKey.Food, foodResponse.term], foodResponse);
      queryClient.resetQueries(getFoodDetailsQueryKey(foodResponse.term), { exact: true });
    },
  });

  const mealItemsQuery = useQuery(['meal_items', originalName], async () => {
    if (!originalName) {
      return {
        items: [],
      };
    }

    const itemRes = await mealApi.appApiMealGetMealItemsByFoodName({
      food_name: originalName,
    }).then(res => res.data);

    return {
      items: itemRes || [],
    };
  });

  const renameFoodMutation = useRenameFoodMutation();

  const onCompletion = useCallback(() => {
    window.alert('Food created!');

    navigate(
      nextAction === 'edit'
        ? getFoodEditorUrl(editorValue.term)
        : '/custom-meal-items',
    );
  }, [navigate, editorValue.term, nextAction]);

  const getFoodOptionsCollection = async () => {
    const res = await foodApi.appApiFoodFoodDetailsGetOptionsCollection({ scope: 'food' });
    setEditorValue(prev => ({ ...prev, optionCollection: res.data }));
  };

  useEffect(() => {
    if (!editorValue.optionCollection) {
      getFoodOptionsCollection();
    }
  }, []);

  const numMealItems = mealItemsQuery.data?.items?.length;

  const foodDidRename = originalName != editorValue.term;

  const foodResponseCallbackRef = React.useRef<() => Promise<void>>(null);

  useEffect(() => {
    const onMutationSuccess = async () => {
      await foodResponseCallbackRef.current?.();
      if (originalName !== null && originalName !== editorValue.term) {
        renameFoodMutation.mutate({ oldFoodName: originalName, newFoodName: editorValue.term });
      } else {
        onCompletion();
      }
    };
    if (createFoodMutation.isSuccess) {
      onMutationSuccess();
    }
  }, [createFoodMutation.isSuccess]);

  useEffect(() => {
    if (renameFoodMutation.isSuccess) {
      onCompletion();
    }
  }, [renameFoodMutation.isSuccess]);

  useEffect(() => {
    if (renameFoodMutation.isError) {
      logTrackedError({
        sourceName: 'CreateFoodForm.renameFoodMutation',
        origin: renameFoodMutation.error as any,
        stackError: new Error(),
        context: { originalName, newFoodName: editorValue.term },
        userMessage: `Error renaming custom items from ${originalName} to ${editorValue.term}.`,
      });
      onCompletion();
    }
  }, [renameFoodMutation.isError]);

  useEffect(() => {
    if (createFoodMutation.isError) {
      logTrackedError({
        sourceName: 'CreateFoodForm.createFoodMutation',
        origin: createFoodMutation.error as any,
        stackError: new Error(),
        context: { originalName, newFoodName: editorValue.term },
        userMessage: `Error creating food ${editorValue.term}. `,
      });
    }
  }, [createFoodMutation.isError]);

  const handleSubmit = (evt: MouseEvent, nextAction: 'return' | 'edit') => {
    evt.preventDefault();

    if (editorErrors.length > 0) {
      return;
    }

    mixpanel.track('Food editor: create food', {
      'Food name': editorValue.term,
    });

    setNextAction(nextAction);

    createFoodMutation.mutate({
      term: originalName ? originalName : editorValue.term,
      foodRequest: foodEditorValueToFoodRequest(editorValue),
    });
  };

  return (
    <MainCard sx={{ overflow: 'visible' }} style={{ minWidth: 920 }}>
      <FoodEditor
        mode="create"
        onChangeValue={setEditorValue}
        showErrors={createFoodMutation.isSuccess || createFoodMutation.isError}
        value={editorValue}
        initialName={searchParams.get('initialName')}
        foodResponseCallbackRef={foodResponseCallbackRef}
      />
      <Stack style={{ paddingTop: 50 }} spacing={2}>
        {foodDidRename && (
          <Alert severity="error" style={{ maxWidth: '50%' }}>
            <AlertTitle>Warning</AlertTitle>
            This will rename {numMealItems} meal item(s) from <strong>{originalName}</strong> to{' '}
            <strong>{editorValue.term}</strong>
          </Alert>
        )}
        {createFoodMutation.isLoading && (
          <Alert severity="info" style={{ maxWidth: '50%' }}>
            Saving...
          </Alert>
        )}
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            type="submit"
            disabled={createFoodMutation.isLoading}
            onClick={(evt) => handleSubmit(evt, 'return')}
          >
            Save and return
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={createFoodMutation.isLoading}
            onClick={(evt) => handleSubmit(evt, 'edit')}
          >
            Save and edit
          </Button>
        </Stack>
        {
          /* {createFoodMutation.isError && (
          <Alert severity="error" style={{ maxWidth: '50%' }}>
            {parseQueryError(createFoodMutation.error)}
          </Alert>
        )} */
        }
        {editorErrors.length > 0 && (
          <Alert severity="error" style={{ maxWidth: '50%' }}>
            Editor error: {editorErrors.join(', ')}
          </Alert>
        )}
      </Stack>
    </MainCard>
  );
};
