import { FoodDatabaseTable } from 'components/FoodDatabaseTable';
import MainCard from 'components/MainCard';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const FoodTablePage = () => {
  return (
    <MainCard>
      <Link to="/foods/new?utm_source=food-table">
        <Button style={{ marginBottom: 15 }}>Add new food</Button>
      </Link>
      <FoodDatabaseTable />
    </MainCard>
  );
};
