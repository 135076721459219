// material-ui
import { Box } from '@mui/material';

const AuthBackground = () => {
  return (
    <Box sx={{ position: 'absolute', width: 1 / 2, zIndex: -1, top: 0 }}>
      <img src="/loginBackground.jpg" alt="login background" />
    </Box>
  );
};

export default AuthBackground;
