import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { Box, Container, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Breadcrumbs from 'components/@extended/Breadcrumbs';
import Drawer from 'layout/MainLayout/Drawer';
import Header from 'layout/MainLayout/Header';

import { useMenuBar } from 'services/MenuBarService';
import { CapabilitiesOverrideDrawer } from './MainLayout/Header/HeaderContent/Profile/CapabilitiesOverrideDrawer';
import { useNavbarMenuItems } from './NavbarMenuItems';

export const Page = ({ fullWidth = false }: { fullWidth?: boolean }) => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
  const { drawerOpen, setDrawerOpen } = useMenuBar();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    setDrawerOpen(!matchDownLG);
  }, [matchDownLG, setDrawerOpen]);

  const navbarMenuItems = useNavbarMenuItems();

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
      <Box component="main" sx={{ width: 'calc(100% - 260px)', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
        <Container
          maxWidth={fullWidth ? false : 'xl'}
          sx={{
            px: { xs: 0, sm: 2 },
            py: { xs: 6 },
            position: 'relative',
            minHeight: 'calc(100vh - 110px)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Breadcrumbs navigation={navbarMenuItems} title titleBottom card={false} divider={false} />
          <Outlet />
        </Container>
      </Box>
      <CapabilitiesOverrideDrawer open={drawerOpen} />
    </Box>
  );
};
