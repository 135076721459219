// Enums that outline the different Authurization levels for various features/roles
//  Each Enum is ordered from highest level of permissons to lowest
//  Enums with direct string assignemnts are unique and do not have explict ordering between each level

export type UserRoles = {
  dataReviewerAuthLevel: AuthRoles,
  mealAuthLevel: AuthRoles,
  hospitalAuthLevel: AuthRoles,
  reportAuthLevel: AuthRoles,
  reviewAuthRole: ReviewAuthRoles,
  userOrigin: UserOrigin,
};

export enum AuthRoles {
  admin = 2,
  viewer = 1,
  none = 0,
}

export enum ReviewAuthRoles {
  reviewer = 'reviewer',
  report_reviewer = 'report_reviewer',
  analyst = 'analyst',
}

export enum UserOrigin {
  internal = 'internal',
  external = 'external',
}
