import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { MealChangeLogResponse } from 'api/generated/MNT';
import { MealQueueItem } from 'apiClients/mpq';
import { getMealChangeLogs } from 'apiClients/review';
import { useAuth } from 'context/appContext';

export const useMealChangeLogs = (queueItem: MealQueueItem) => {
  const { authInfo } = useAuth();
  const query = useQuery(['meal-change-logs', queueItem.id], async () => {
    if (!authInfo?.access_token) {
      // Don't load meal change logs unless the user has permission to view them
      return null;
    }
    const res = await getMealChangeLogs(queueItem.patient_id, queueItem.created_meal_id, authInfo.access_token);
    if (res == null) {
      return null;
    }
    return res;
  });

  return {
    query: query as UseQueryResult<unknown>,
    data: query.data || null,
  };
};
