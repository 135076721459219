import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';

import moment from 'moment';

import { MealQueueItem } from 'apiClients/mpq';
import { getReviewList } from 'apiClients/review';
import MainCard from 'components/MainCard';
import { PatientID } from 'components/PatientID';
import { useAuth } from 'context/appContext';

export const ReviewPage = () => {
  return (
    <MainCard>
      <Review />
    </MainCard>
  );
};

export const Review = () => {
  const { authInfo } = useAuth();
  const navigate = useNavigate();
  const [_q, setQ] = useSearchParams();
  const q = Object.fromEntries(_q.entries()) as any;
  const location = useLocation();
  const [queue, setQueue] = useState<MealQueueItem[]>([]);
  const [timestamp, setTimestamp] = useState(moment().format('LTS'));

  useEffect(() => {
    if (!q.dateSince) {
      clearReviewParams();
    } else {
      reloadQueueList(q);
    }
  }, []);

  const reloadQueueList = async (params: any) => {
    setTimestamp(moment().format('LTS'));
    if (!authInfo) {
      return;
    }

    const { access_token, reviewer_id } = authInfo;
    const mpq = params.dateSince && params.dateUntil
      ? await getReviewList({
        reviewer_id: reviewer_id,
        date_since: params.dateSince,
        date_until: params.dateUntil,
        clinic_id: params.clinicId,
        patient_id: params.patientId,
        meal_id: params.mealId,
        meal_item_id: params.mealItemId,
        access_token: access_token,
      })
      : [];
    setQueue(mpq);
  };

  const showQueueItem = (item: MealQueueItem, event: any) => {
    if (event.target.tagName == 'A') {
      return;
    }
    navigate('/queue-item/' + item.id, {
      state: {
        queueItem: item,
        prevUrl: location.pathname + location.search,
      },
    });
  };

  const renderQueueItem = (item: MealQueueItem) => (
    <React.Fragment key={item.id}>
      <div className="view queueTableRowReview" onClick={event => showQueueItem(item, event)}>
        <div className="queueNum">
          <Link
            to={`/queue-item/${item.id}`}
            state={{
              queueItem: item,
              prevUrl: location.pathname + location.search,
            }}
          >
            {item.created_meal_id}
          </Link>
        </div>
        <div>{item.is_priority_patient && ' (P)'}</div>
        <div>{item.clinic_type}</div>
        <div>
          <PatientID userId={item.patient_id} isPriority={!!item.is_priority_patient} />
        </div>
        <div>{item.created_time}</div>
      </div>
    </React.Fragment>
  );

  const clearReviewParams = () => {
    const newParams = {
      dateSince: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      dateUntil: moment().format('YYYY-MM-DD'),
    };
    setQ(newParams);
    reloadQueueList(newParams);
  };

  const inputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQ({
      ...q,
      [e.target.id]: e.target.value,
    });
  };

  return (
    <>
      <h2>Meal Review and Edit</h2>
      <div>
        Date range:{'  '}<input type="text" id="dateSince" onChange={inputChanged} value={q.dateSince || ''} />{'  '}-
        {'  '}<input type="text" id="dateUntil" onChange={inputChanged} value={q.dateUntil || ''} />
      </div>
      <div>
        Clinic ID:{'  '}<input type="text" id="clinicId" onChange={inputChanged} value={q.clinicId || ''} />
      </div>
      <div>
        Patient ID:{'  '}<input type="text" id="patientId" onChange={inputChanged} value={q.patientId || ''} />
      </div>
      <div>
        Meal ID:{'  '}<input type="text" id="mealId" onChange={inputChanged} value={q.mealId || ''} />
      </div>
      <div>
        Meal Item ID:{'  '}<input type="text" id="mealItemId" onChange={inputChanged} value={q.mealItemId || ''} />
      </div>
      <div style={{ paddingTop: '15px' }}>
        <Button onClick={() => reloadQueueList(q)}>Reload</Button>
        &nbsp;
        <Button onClick={() => clearReviewParams()}>Reset</Button>
      </div>

      <div className="queueTableHeaderReview">
        <div />
        <div>Meal ID</div>
        <div>Clinic Type</div>
        <div>Patient ID</div>
        <div>Created Time</div>
      </div>
      <div className="queueTableReview">
        {queue.map(item => renderQueueItem(item))}
      </div>
    </>
  );
};
