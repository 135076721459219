import * as Sentry from '@sentry/browser';
import React, { createContext, useEffect, useState } from 'react';

import mixpanel from 'mixpanel-browser';

import { apiConfig } from 'api';

import { authenticate, authenticateToken, ReviewerAuthInfo } from 'apiClients/auth';
import { CapabilityOverrides, getHasAuthFromAppContext, HasAuthFunc } from 'auth-capabilities';
import { AsyncResult, useAsyncResult } from 'react-use-async-result';

export interface AppContext {
  // ``initialRes`` describes the auth's initialization state; it will be "pending" until
  // the auth info is first loaded, then remain ``done``. It will never be ``error``.
  initialRes: AsyncResult<unknown>;
  // ``authRes`` describes the auth's current state. It will be "pending" while
  // an authentication request is in progres, and ``done`` or ``error`` when the
  // request is complete.
  authRes: AsyncResult<unknown>;
  authInfo: ReviewerAuthInfo | null;
  login: (opts: {
    email: string,
    password: string,
  }) => void;
  logout: () => void;
  hasAuth: HasAuthFunc;
  _authCapabilityOverrides: CapabilityOverrides;
  _setAuthCapabilityOverrides: React.Dispatch<React.SetStateAction<CapabilityOverrides>>;
}

type AuthSession = {
  authInfo: ReviewerAuthInfo,
  expiry: number,
};

export const AppCtx = createContext<AppContext>({
  initialRes: AsyncResult.pending(),
  authRes: AsyncResult.empty(),
  authInfo: null,
  login: () => {},
  logout: () => {},
  hasAuth: () => false,
  _authCapabilityOverrides: [],
  _setAuthCapabilityOverrides: () => {},
});

export const useAuth = () => React.useContext(AppCtx);

async function loadInitialAuthInfo(): Promise<ReviewerAuthInfo | null> {
  // This token is set by the clinician portal when a clinician clicks a meal
  // to open it in the reviewer.
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');
  if (token) {
    const tokenAauthRes = authenticateToken(token);
    tokenAauthRes.finally(() => {
      const newPath = window.location.pathname
        + window.location.search.replace(/token=[^&]*&?/, '');
      window.history.replaceState(null, '', newPath);
    });
    return tokenAauthRes;
  }

  // If there is no clinician portal token, try to load the auth info from local
  // storage.
  try {
    const savedAuth = localStorage.getItem('auth_info');
    const { expiry, authInfo } = JSON.parse(savedAuth || '{}') as AuthSession;
    const now = new Date().getTime();
    return expiry && now < expiry ? authInfo : null;
  } catch (e) {
    console.error('Error loading auth:', e);
  }
  return null;
}

export const globalLogout = {
  logout: () => {},
};

export const AppContextProvider: React.FC<{}> = ({ children }) => {
  const [_authCapabilityOverrides, _setAuthCapabilityOverrides] = useState<CapabilityOverrides>([]);
  const [authInfo, _setAuthInfo] = useState<ReviewerAuthInfo | null>(null);
  const initialRes = useAsyncResult(() => AsyncResult.pending());
  const authRes = useAsyncResult<ReviewerAuthInfo | null>(loadInitialAuthInfo);

  const setAuthInfo = (authInfo: ReviewerAuthInfo | null) => {
    if (!authInfo) {
      localStorage.removeItem('auth_info');
      apiConfig.accessToken = undefined;
      _setAuthInfo(null);
      return;
    }

    const now = new Date().getTime();
    localStorage.setItem('auth_info', JSON.stringify({ authInfo, expiry: now + 60 * 60 * 24 * 1000 }));
    apiConfig.accessToken = authInfo.access_token;
    _setAuthInfo(authInfo);

    mixpanel.identify('' + authInfo.reviewer_id);
    mixpanel.people.set_once({
      '$name': `User ${authInfo.reviewer_id}`,
      'reviewer_id': authInfo.reviewer_id,
    });
    Sentry.setUser({
      id: '' + authInfo.reviewer_id,
      username: `User ${authInfo.reviewer_id}`,
    });
  };

  globalLogout.logout = () => {
    setAuthInfo(null);
    authRes.bind(Promise.resolve(null));
  };

  useEffect(() => {
    if (!initialRes.isDone && (authRes.isDone || authRes.isError)) {
      initialRes.bind(Promise.resolve(null));
    }

    if (!authRes.isDone) {
      return;
    }

    setAuthInfo(authRes.result);
  }, [initialRes, authRes]);

  const value = React.useMemo(() => {
    const res = {
      initialRes,
      authRes,
      authInfo,
      login: (opts: {
        email: string,
        password: string,
      }) => {
        authRes.bind(authenticate(opts.email, opts.password));
      },
      logout: () => authRes.bind(Promise.resolve(null)),
      _authCapabilityOverrides,
      _setAuthCapabilityOverrides,
      hasAuth: (null as any) as ReturnType<typeof getHasAuthFromAppContext>,
    };
    res.hasAuth = getHasAuthFromAppContext(res);
    return res;
  }, [initialRes, authRes, authInfo, _authCapabilityOverrides]);

  return (
    <AppCtx.Provider value={value}>
      {children}
    </AppCtx.Provider>
  );
};
