/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { TranslatedStringResponse } from '../models';
/**
 * TranslationsApi - axios parameter creator
 * @export
 */
export const TranslationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Translate text
         * @param {string} scope 
         * @param {string} key 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiTranslationGetTranslation: async (scope: string, key: string, lang: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scope' is not null or undefined
            assertParamExists('appApiTranslationGetTranslation', 'scope', scope)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('appApiTranslationGetTranslation', 'key', key)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('appApiTranslationGetTranslation', 'lang', lang)
            const localVarPath = `/translation/translate/{scope}`
                .replace(`{${"scope"}}`, encodeURIComponent(String(scope)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TranslationsApi - functional programming interface
 * @export
 */
export const TranslationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TranslationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Translate text
         * @param {string} scope 
         * @param {string} key 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiTranslationGetTranslation(scope: string, key: string, lang: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranslatedStringResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiTranslationGetTranslation(scope, key, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TranslationsApi - factory interface
 * @export
 */
export const TranslationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TranslationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Translate text
         * @param {string} scope 
         * @param {string} key 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiTranslationGetTranslation(scope: string, key: string, lang: string, options?: any): AxiosPromise<TranslatedStringResponse> {
            return localVarFp.appApiTranslationGetTranslation(scope, key, lang, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiTranslationGetTranslation operation in TranslationsApi.
 * @export
 * @interface TranslationsApiAppApiTranslationGetTranslationRequest
 */
export interface TranslationsApiAppApiTranslationGetTranslationRequest {
    /**
     * 
     * @type {string}
     * @memberof TranslationsApiAppApiTranslationGetTranslation
     */
    readonly scope: string

    /**
     * 
     * @type {string}
     * @memberof TranslationsApiAppApiTranslationGetTranslation
     */
    readonly key: string

    /**
     * 
     * @type {string}
     * @memberof TranslationsApiAppApiTranslationGetTranslation
     */
    readonly lang: string
}

/**
 * TranslationsApi - object-oriented interface
 * @export
 * @class TranslationsApi
 * @extends {BaseAPI}
 */
export class TranslationsApi extends BaseAPI {
    /**
     * 
     * @summary Translate text
     * @param {TranslationsApiAppApiTranslationGetTranslationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsApi
     */
    public appApiTranslationGetTranslation(requestParameters: TranslationsApiAppApiTranslationGetTranslationRequest, options?: AxiosRequestConfig) {
        return TranslationsApiFp(this.configuration).appApiTranslationGetTranslation(requestParameters.scope, requestParameters.key, requestParameters.lang, options).then((request) => request(this.axios, this.basePath));
    }
}
