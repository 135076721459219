import * as Sentry from '@sentry/browser';
import { foodApi } from 'api';
import mixpanel from 'mixpanel-browser';
import * as React from 'react';
import { useAsyncResult } from 'react-use-async-result';
import { AppCtx } from '../context/appContext';
import { FoodSearchIndex, time } from './food-search-index';

const INDEXDB_DB_NAME = 'food_search_db';
const LOCAL_STORAGE_LAST_LOAD_TIMESTAMP_KEY = 'food-search-index:last-load-timestamp';
const DB_MUTEX = 'food-search-index:db-mutex';

function mutex<T>(name: string, fn: () => Promise<T>): Promise<T> {
  if (!navigator?.locks) {
    console.warn(`Locks API not supported; not using mutex '${name}'`);
    return fn();
  }

  return navigator.locks.request(name, { ifAvailable: true }, async lock => {
    if (!lock) {
      console.warn(`Lock '${name}' unavailable; blocking...`);
      return navigator.locks.request(name, () => {
        console.warn(`Lock '${name}' acquired; continuing...`);
        return fn();
      });
    }

    return fn();
  });
}

function sharedFoodSearchIndexLoader() {
  const events = new EventTarget();

  function withMutex<T>(fn: () => Promise<T>): () => Promise<T> {
    return () => mutex(DB_MUTEX, fn);
  }

  let lastIndex: FoodSearchIndex | null = null;
  let checkInterval: any = null;
  const withDbRes = (fn: () => Promise<FoodSearchIndex>) => {
    return () => {
      checkInterval && clearInterval(checkInterval);
      const p = fn();
      events.dispatchEvent(new CustomEvent('index-promise', { detail: { promise: p } }));
      p.then(index => {
        if (lastIndex) {
          lastIndex.close();
        }
        lastIndex = index;
        checkInterval = setInterval(() => dbCheckRefetch(), 1000 * 60 + (Math.random() * 5000));
      });
      p.catch(err => {
        console.error('ClientSearch: error loading index:', err);
        Sentry.captureException(new Error('Error in withDbRes'));
        mixpanel.track('ClientSearch: error loading index', {
          error: '' + err,
          errorType: err?.constructor?.name,
        });
      });
      return p;
    };
  };

  const dbCheckNeedsRefetchInternal = () => {
    const lastLoaded = localStorage.getItem(LOCAL_STORAGE_LAST_LOAD_TIMESTAMP_KEY) || '0';
    const reloadInterval = 1000 * 60 * 60 * 8; // 8 hours
    const dbAge = Date.now() - +lastLoaded;
    const needsReload = dbAge > reloadInterval;
    console.log(
      'ClientSearch: Search index age:',
      dbAge / 1000 / 60 / 60,
      'hrs;',
      needsReload ? 'refetching' : 'not refetching',
    );
    return needsReload;
  };

  const dbReinit = withMutex(withDbRes(async () => {
    if (dbCheckNeedsRefetchInternal()) {
      return dbRefetchInternal();
    }

    const index = await FoodSearchIndex.loadFromIndexDB(INDEXDB_DB_NAME);
    if (index.itemCount > 0) {
      return index;
    }

    console.log('ClientSearch: Search index empty; refetching...');
    return dbRefetchInternal();
  }));

  console.log('ClientSearch: Listening for storage events...');
  const onStorage = (evt: StorageEvent) => {
    if (evt.key == LOCAL_STORAGE_LAST_LOAD_TIMESTAMP_KEY && evt.newValue != '0') {
      console.log('ClientSearch: Search index updated externally; reloading...', evt);
      dbReinit();
    }
  };
  window.addEventListener('storage', onStorage);

  async function dbRefetchInternal() {
    const getNextBatch = async (cursor: string) => {
      const res = await foodApi.appApiFoodFoodSearchGetClientSearchIndex({ cursor });
      return res.data;
    };

    const _refetchDb = async (index: FoodSearchIndex, db: IDBDatabase) => {
      events.dispatchEvent(new CustomEvent('index-update', { detail: { newItemCount: 0 } }));
      let newItems = 0;
      let reqCount = 0;
      while (true) {
        reqCount += 1;
        if (reqCount > 60) {
          throw new Error(`Giving up after too many requets (${reqCount}; ${newItems} loaded so far)`);
        }

        const res = await getNextBatch(index.itemMaxCursor || '').catch(err => {
          console.error('ClientSearch: error loading chunk:', err);
          return new Promise(res => setTimeout(() => res(null), 100)) as Promise<null>;
        });
        if (!res || !res.items) {
          continue;
        }

        await index.addItems(
          db,
          res.items.map(item => ({
            ...item,
            cursor: `${item.updated_time}:${item.id}`,
            rxfood_id: null,
            name_translations: null,
            serving_units: [],
            food_image_url: null,
          })),
        );
        newItems += res.items.length;
        events.dispatchEvent(new CustomEvent('index-update', { detail: { newItemCount: newItems } }));
        if (!res.has_more) {
          break;
        }
      }
      console.log(`ClientSearch: Search index updated (${newItems} new items)!`);
    };

    console.log('ClientSearch: dropping old index...');
    localStorage.setItem(LOCAL_STORAGE_LAST_LOAD_TIMESTAMP_KEY, '0');
    await FoodSearchIndex.dropIndexDB(INDEXDB_DB_NAME);

    console.log('ClientSearch: loading new index...');
    const index = await FoodSearchIndex.loadFromIndexDB(INDEXDB_DB_NAME);
    const db = await FoodSearchIndex.openIndexDB(INDEXDB_DB_NAME);
    try {
      await time(
        () => _refetchDb(index, db),
        t => {
          console.log(`ClientSearch: index downloaded in ${t}ms!`);
          mixpanel.track('ClientSearch: index downloaded', {
            'Load time': t,
            'Item count': index.itemCount,
            'IndexDB load time': index.metrics.indexDBLoadTime,
            'IndexDB write time': index.metrics.indexDBWriteTime,
            'Flexi CPU Time': index.metrics.flexi.indexTime,
          });
        },
      );
    } finally {
      db.close();
    }
    localStorage.setItem(LOCAL_STORAGE_LAST_LOAD_TIMESTAMP_KEY, '' + Date.now());
    return index;
  }

  const dbCheckRefetch = withMutex(async () => {
    if (dbCheckNeedsRefetchInternal()) {
      withDbRes(() => dbRefetchInternal())();
    }
  });

  return {
    events,
    refetch: withMutex(withDbRes(() => dbRefetchInternal())),
    init: () => {
      console.log('ClientSearch: initializing...');
      return dbReinit();
    },
    shutdown: () => {
      console.log('ClientSearch: shutting down...');
      checkInterval && clearInterval(checkInterval);
      window.removeEventListener('storage', onStorage);
      lastIndex?.close();
    },
  };
}

const useFreshFoodSearch = () => {
  const indexRes = useAsyncResult<FoodSearchIndex>();
  const [updateItemCount, setUpdateItemCount] = React.useState(0);
  const ixLoaderRef = React.useRef<ReturnType<typeof sharedFoodSearchIndexLoader> | null>(null);
  React.useEffect(() => {
    const ixLoader = sharedFoodSearchIndexLoader();
    const onPromise = (e: any) => indexRes.bind(e.detail.promise);
    const onIndexUpdate = (e: any) => setUpdateItemCount(e.detail.newItemCount);
    ixLoader.events.addEventListener('index-promise', onPromise);
    ixLoader.events.addEventListener('index-update', onIndexUpdate);
    ixLoaderRef.current = ixLoader;
    return () => {
      ixLoader.events.removeEventListener('index-promise', onPromise);
      ixLoader.events.removeEventListener('index-update', onIndexUpdate);
      ixLoader.shutdown();
      ixLoaderRef.current = null;
    };
  }, []);

  (window as any).debugFoodSearchIndexRes = indexRes;
  (window as any).debugFoodSearchIndex = indexRes.isDone ? indexRes.result : null;

  const { authInfo } = React.useContext(AppCtx);
  React.useEffect(() => {
    if (!authInfo?.access_token) {
      return;
    }
    ixLoaderRef.current?.init();
  }, [authInfo?.access_token]);

  const reload = async (automatic?: boolean) => {
    ixLoaderRef.current?.refetch();
  };

  return {
    indexSize: indexRes.isDone ? indexRes.result.itemCount : updateItemCount,
    loadState: indexRes,
    ...indexRes,
    reload,
  };
};

export const FoodSearchCtx = React.createContext<ReturnType<typeof useFreshFoodSearch>>(null as any);

export const FoodSearchProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const value = useFreshFoodSearch();
  return <FoodSearchCtx.Provider value={value}>{children}</FoodSearchCtx.Provider>;
};

export const useFoodSearchIndex = () => {
  const ctx = React.useContext(FoodSearchCtx);
  if (!ctx) {
    throw new Error('useFoodSearchIndex must be used within a FoodSearchProvider');
  }
  return ctx;
};
