import { reportingApi } from 'api';
import { PatientReportTableRow, ReportingApi } from 'api/generated/MNT';
import MainCard from 'components/MainCard';
import { PatientID } from 'components/PatientID';
import { RxTable, RxTableColumn, useRxTableData } from 'components/RxTable';
import { AppCtx } from 'context/appContext';
import { DateTime } from 'luxon';
import React, { useContext, useEffect } from 'react';

export const PatientReportListPage = () => {
  return (
    <MainCard>
      <PatientReportsListView />
    </MainCard>
  );
};

export const PatientReportsListView = () => {
  const { authInfo } = useContext(AppCtx);
  const tableData = useRxTableData<PatientReportTableRow>({
    queryKey: 'patient-reports',
    apiEndpoint: reportingApi.appApiPatientReportsApiGetPatientReportTable.bind(reportingApi),
    useSearchParams: true,
    initialFilter: {
      status: 'draft',
      is_auto_generated: true,
    },
    initialSort: [{
      field: 'report_date',
      direction: 'asc',
    }],
  });

  const columns: RxTableColumn<PatientReportTableRow>[] = [
    {
      field: 'patient_report_id',
      header: 'Report ID',
      sortable: true,
      filter: 'numeric',
      href: (row) => `/patient-reports/${row.patient_report_id}`,
    },

    {
      field: 'patient_id',
      header: 'Patient',
      cellRenderer: ({ row }) => <PatientID userId={row.patient_id} isPriority={row.patient_is_priority} />,
      sortable: true,
      filter: 'numeric',
    },

    {
      field: 'patient_is_priority',
      header: 'Priority',
      hidden: true,
      filter: 'boolean',
    },

    {
      field: 'patient_locale',
      header: 'Lang',
      filter: {
        type: 'enum',
        values: [
          'en',
          'fr',
        ],
      },
    },

    {
      field: 'status',
      header: 'Status',
      sortable: true,
      filter: {
        type: 'enum',
        values: [
          'draft',
          'sent',
          'ready_to_send',
        ],
      },
    },

    {
      field: 'report_date',
      header: 'Report Date',
      sortable: true,
      filter: 'date',
    },

    {
      id: 'flags',
      header: 'Flags',
      getValue: (row) => row.error_flags?.length || 0,
    },

    {
      id: 'mic',
      header: <span title="Meals / Items / Customs">M / I / C</span>,
      getValue: (row) => `${row.meal_count} / ${row.meal_item_count} / ${row.custom_item_count}`,
    },

    {
      field: 'date_since',
      header: 'Date Since',
      sortable: true,
      filter: 'date',
    },

    {
      field: 'date_until',
      header: 'Date Until',
      sortable: true,
      filter: 'date',
    },

    {
      field: 'is_auto_generated',
      header: 'Autogenerated?',
      hidden: true,
      filter: 'boolean',
    },
  ];

  if (authInfo == null) {
    return (null);
  }

  return (
    <RxTable
      columns={columns}
      data={tableData}
      allowColumnSelect
      allowFilters
      allowDownload
    />
  );
};
