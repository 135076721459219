import { DefaultConfigProps } from './types/config';

const env = process.env.REACT_APP_IA_ENV || 'local';

export function envswitch<T>(vals: { local?: T, stg?: T, prod?: T, test?: T }): T {
  const envName = {
    staging: 'stg',
    production: 'prod',
  }[env] || env;
  const res = (vals as any)[envName];
  if (res === undefined) {
    throw new Error(`No value specified for env '${env}' in ${JSON.stringify(vals)}`);
  }
  return res;
}

export class ConfigBase {
  ENV = env;

  COMMIT_SHA = process.env.REACT_APP_COMMIT_SHA || 'local';
  BUILD_DATE = process.env.REACT_APP_BUILD_DATE || 'local';
  RELEASE_LABEL = (
    this.BUILD_DATE == 'local'
      ? 'local'
      : `${this.BUILD_DATE}/${this.COMMIT_SHA.slice(0, 7)}`
  );
  COMMIT_SHA_URL = envswitch({
    local: null,
    // local: 'https://storage.googleapis.com/storage/v1/b/stg-food-auditor.rxdev.co/o/commit-sha.txt?alt=media',
    stg: 'https://storage.googleapis.com/storage/v1/b/stg-food-auditor.rxdev.co/o/commit-sha.txt?alt=media',
    prod: 'https://storage.googleapis.com/storage/v1/b/food-auditor.rxfoodapp.com/o/commit-sha.txt?alt=media',
  });

  IS_LOCAL = envswitch({
    local: true,
    stg: false,
    prod: false,
    test: false,
  });

  IS_DEV = envswitch({
    local: true,
    stg: true,
    prod: false,
    test: false,
  });

  IS_PROD = envswitch({
    local: false,
    stg: false,
    prod: true,
    test: false,
  });

  IS_TEST = envswitch({
    local: false,
    stg: false,
    prod: false,
    test: true,
  });

  IS_PREVIEW = window.location.host.includes('preview-');
}

export class IAFoodAuditorConfig extends ConfigBase {
  API_URL = process.env.REACT_APP_API_URL || envswitch({
    local: 'http://localhost:1480',
    stg: 'https://stg-api.rxdev.co',
    prod: 'https://api.rxfoodapp.com',
  });

  NOTIFICATION_ENGINE_URL = process.env.REACT_APP_NOTIFICATION_ENGINE_URL || envswitch({
    local: 'http://localhost:1489',
    stg: 'https://stg-notification-engine.rxdev.co',
    prod: 'https://notification-engine.rxfoodapp.com',
  });

  SENTRY_DSN = envswitch({
    local: 'https://4fe72352bf28444ca79d7c45e16fff30@o1345580.ingest.sentry.io/4504449337917440',
    stg: 'https://4fe72352bf28444ca79d7c45e16fff30@o1345580.ingest.sentry.io/4504449337917440',
    prod: 'https://745ee8a40ba34009b255e6ccdafb00aa@o1345580.ingest.sentry.io/4504442590396416',
  });

  MIXPANEL_KEY = envswitch({
    local: '8b80795a9ef53f7396620ec63cced81a',
    stg: '8b80795a9ef53f7396620ec63cced81a',
    prod: '57e41a1241fb48186fefccf581e20c78',
  });

  EXTERNAL_FOOD_SEARCH_URL = envswitch({
    local: 'http://localhost:3000',
    stg: 'https://food-external-search-api-zt4ajolhua-nn.a.run.app',
    prod: 'https://food-external-search-api-em3etxqkxa-nn.a.run.app',
  });

  FOOD_ENGINE_URL = envswitch({
    local: 'http://localhost:1430',
    stg: 'https://food-engine-zt4ajolhua-nn.a.run.app',
    prod: 'https://food-engine-em3etxqkxa-nn.a.run.app',
  });

  // Note: until the performance of the QA endpoint is improved, add this emergency
  // throttle switch to reduce the number of requests.
  EMERGENCY_THROTTLE_QA = true;
}

export const config = new IAFoodAuditorConfig();

export const drawerWidth = 260;

export const ThemeConfig: DefaultConfigProps = {
  defaultPath: '/',
  fontFamily: `'Public Sans', sans-serif`,
  i18n: 'en',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr',
};
