import { MealItemCustomResponse } from 'api/generated/MNT';
import { config } from '../config';

export const getRecentCustomItems = async (
  access_token: string,
  food_name: string | null = null,
): Promise<MealItemCustomResponse[]> => {
  let url = `${config.API_URL}/api/reporting/custom_meal_items/recent_by_name`;

  if (food_name) {
    url += `?food_name=${encodeURIComponent(food_name)}`;
  }

  const response = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${access_token}`,
    },
  });

  if (response.status !== 200) {
    throw new Error(`${response.status}`);
  }

  const items = await response.json();
  return items;
};

export const refreshMeals = async (access_token: string, meal_ids: number[], auto_escalate: boolean = false) => {
  const url = `${config.API_URL}/api/patients/refresh_meals?meal_ids=${
    meal_ids.join(',')
  }&auto_escalate=${auto_escalate}`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${access_token}`,
    },
  });

  if (response.status !== 200) {
    throw new Error(`${response.status}`);
  }
};
