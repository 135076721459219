import * as React from 'react';
import { Button, Form } from 'react-bootstrap';

import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';

import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type MealItemCustomAddonResponse } from 'api/generated/MNT';
import { type MealItem } from 'apiClients/mpq';
import { getFoodByQuery, SearchItem } from 'apiClients/search';
import { FoodSearchTypeahead } from 'client-search/FoodSearchInput';
import { useAuth } from 'context/appContext';
import { Link } from 'react-router-dom';
import { encodeQueryParams, useUpdatingState } from 'utils';

const CustomAddonEditor = (props: {
  patient_id?: number,
  addon: MealItemCustomAddonResponse | null,
  pendingAddonName?: string | null,
  suggestions: string[],
  existingAddons: MealItemCustomAddonResponse[],
  onSubmit: (addon: MealItemCustomAddonResponse) => void,
  onRemove?: (addon: MealItemCustomAddonResponse) => void,
  onCancel?: () => void,
}) => {
  const { authInfo } = useAuth();
  const servingInputRef = React.useRef<HTMLInputElement>(null);
  const [addon, setAddon] = useUpdatingState(props.addon, {
    food_name: props.pendingAddonName || '',
    food_name_translations: {},
    food_image_url: null,
    serving_unit_label: 'g',
    serving_unit_label_translations: {},
    serving_unit_amount: 1,
    servings: 1,
  }, [props.pendingAddonName]);

  React.useEffect(() => {
    if (props.pendingAddonName) {
      servingInputRef.current?.focus();
    }
  }, [props.pendingAddonName]);

  const foodQuery = useQuery(['foods', addon.food_name], async () => {
    if (!addon.food_name) {
      return null;
    }
    return getFoodByQuery(addon.food_name, authInfo!.access_token);
  }, {
    cacheTime: 1000 * 60 * 60 * 24,
    staleTime: 1000 * 60 * 60 * 24,
    onSuccess: data => {
      if (props.addon || !data) {
        return;
      }

      const defaultUnit = data.serving_units![0];
      setAddon({
        ...addon,
        food_image_url: data.food_image_url ?? null,
        serving_unit_label: defaultUnit.label,
        serving_unit_amount: defaultUnit.amount,
      });
    },
  });

  const showSave = (!props.addon && addon.food_name)
    || (props.addon && JSON.stringify(addon) != JSON.stringify(props.addon));

  const canRemove = !!props.onRemove;

  const canCancel = (props.addon && JSON.stringify(addon) != JSON.stringify(props.addon))
    || (!props.addon && addon.food_name);

  const isExisting = props.addon?.food_name
    && props.existingAddons.findIndex(addon => addon.food_name === props.addon?.food_name) !== -1;

  const onSubmit = () => {
    if (!showSave) {
      return;
    }

    props.onSubmit(addon);
    if (!props.addon) {
      setAddon(null);
    }
  };

  const getFoodEditorUrl = (foodName: string) => {
    return `/foods/${encodeURIComponent(foodName)}`;
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <Form>
        <div>
          {props.addon
            ? (
              <Form.Control
                type="text"
                value={addon.food_name}
                disabled={true}
              />
            )
            : (
              <FoodSearchTypeahead
                placeholder="New addon"
                value={addon.food_name}
                defaultOptions={props.suggestions}
                exclude={props.existingAddons.map(a => a.food_name)}
                onSelect={name => {
                  setAddon({
                    ...addon,
                    food_name: name,
                  });
                }}
              />
            )}
        </div>
        <div style={{ display: 'flex' }}>
          <Form.Control
            type="number"
            placeholder="Servings"
            value={addon.servings}
            style={{ width: 90 }}
            ref={servingInputRef}
            onKeyPress={(e: React.KeyboardEvent) => {
              if (e.key == 'Enter') {
                onSubmit();
              }
            }}
            onChange={e => {
              setAddon({
                ...addon,
                servings: +e.target.value,
              });
            }}
          />
          <Form.Control
            as="select"
            defaultValue={addon.serving_unit_label}
            onChange={e => {
              const servingUnit = foodQuery.data?.serving_units?.find(u => u.label == e.target.value);
              if (!servingUnit) {
                return;
              }
              setAddon({
                ...addon,
                serving_unit_label: servingUnit.label,
                serving_unit_amount: servingUnit.amount,
              });
            }}
            style={{ width: 200 }}
          >
            <option>{addon.serving_unit_label}</option>
            <option disabled>---------</option>
            {foodQuery.isLoading && <option disabled>Loading...</option>}
            {_.uniqBy(foodQuery.data?.serving_units || [], 'label').map(serving => (
              <option key={serving.label}>{serving.label}</option>
            ))}
          </Form.Control>

          {showSave && (
            <Button
              variant="primary"
              onClick={onSubmit}
            >
              {props.addon ? 'Save' : 'Add'}
            </Button>
          )}

          {canRemove && !showSave && (
            <Button
              variant="warning"
              onClick={() => props.onRemove!(addon)}
            >
              Remove
            </Button>
          )}
          {isExisting && (
            <Link
              to={getFoodEditorUrl(addon.food_name)}
              target="_blank"
            >
              <FontAwesomeIcon
                className="qaActionButton qaAddonFoodEditorLink"
                icon={faExternalLinkSquareAlt}
                color="black"
                size="2x"
              />
            </Link>
          )}
          {canCancel && (
            <Button
              variant="secondary"
              onClick={() => {
                props.onCancel && props.onCancel();
                setAddon(props.addon);
              }}
            >
              Cancel
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export const AddonEditor = (props: {
  patient_id?: number,
  item: MealItem,
  searchItem: SearchItem,
  onAddonsChange: (customAddons: MealItemCustomAddonResponse[], legacyAddons: string[]) => void,
}) => {
  const [addonGroupsVisible, setAddonGroupsVisible] = React.useState({}) as any;
  const [pendingAddonName, setPendingAddonName] = React.useState<string | null>(null);

  const { item, searchItem } = props;

  const toggleDetails = (name: string) => {
    setAddonGroupsVisible({
      ...addonGroupsVisible,
      [name]: !addonGroupsVisible[name],
    });
  };

  const setAllDetails = (val: boolean) => {
    setAddonGroupsVisible(Object.fromEntries(
      (searchItem.addons || []).map(addon => [addon.addon_group_name, val]),
    ));
  };

  const addonSuggestionNames = React.useMemo(() => {
    let names = [] as string[];
    searchItem.addons?.forEach(addon => {
      names = [...names, ...(addon.addon_names || [])];
    });
    names.sort();
    return names;
  }, [searchItem.addons]);

  const onAddonSubmit = (addon: MealItemCustomAddonResponse) => {
    setPendingAddonName(null);
    const exists = props.item.custom_addons?.find(a => a.food_name == addon.food_name);

    mixpanel.track('Meal addon ' + exists ? 'edited' : 'added', {
      'Food name': addon.food_name,
      'Servings': addon.servings,
      'Serving unit': addon.serving_unit_label,
      'Serving unit amount': addon.serving_unit_amount,
      'Meal item name': item.food_name,
    });

    const newAddons = exists
      ? props.item.custom_addons!.map(a => a.food_name == addon.food_name ? addon : a)
      : [...(props.item.custom_addons || []), addon];
    props.onAddonsChange(
      newAddons,
      item.addons.filter(a => a != addon.food_name),
    );
  };

  const onAddonRemove = (addon: { food_name: string }) => {
    mixpanel.track('Meal addon removed', {
      'Food name': addon.food_name,
      'Meal item name': item.food_name,
    });

    props.onAddonsChange(
      props.item.custom_addons!.filter(a => a.food_name != addon.food_name) || [],
      item.addons,
    );
  };

  return (
    <div>
      <div className="twoColTable">
        <b>Addons:</b>
      </div>
      <div>
        {item.custom_addons?.map((addon, idx) => (
          <CustomAddonEditor
            key={addon.food_name}
            addon={addon}
            patient_id={props.patient_id}
            suggestions={addonSuggestionNames}
            existingAddons={item.custom_addons || []}
            onSubmit={onAddonSubmit}
            onRemove={onAddonRemove}
          />
        ))}
        <CustomAddonEditor
          key="new-addon"
          addon={null}
          pendingAddonName={pendingAddonName}
          patient_id={props.patient_id}
          suggestions={addonSuggestionNames}
          existingAddons={item.custom_addons || []}
          onSubmit={onAddonSubmit}
          onCancel={() => setPendingAddonName(null)}
        />
      </div>

      <div className="twoColTable">
        <b>Addon Item Categories:</b>
        <div>
          <Button
            variant="link"
            onClick={() => {
              setAllDetails(true);
            }}
          >
            expand all
          </Button>
          <Button
            variant="link"
            onClick={() => {
              setAllDetails(false);
            }}
          >
            collapse all
          </Button>
        </div>
      </div>
      <div>
        {searchItem.addons?.map((addonList, addonListIdx) => (
          <details
            key={addonListIdx}
            open={!!addonGroupsVisible[addonList.addon_group_name]}
          >
            <summary style={{ textTransform: 'capitalize' }} onClick={() => toggleDetails(addonList.addon_group_name)}>
              {addonList.addon_group_name}
            </summary>
            <div className="addOnTable">
              {addonList.addon_names?.map((v, vIdx) => (
                <div key={`${addonListIdx}-${vIdx}`}>
                  <Form.Check
                    style={{ fontSize: 12 }}
                    type="checkbox"
                    id={v}
                    label={v}
                    checked={!!item.custom_addons?.find(a => a.food_name == v)}
                    onChange={(evt) => {
                      if (item.custom_addons?.find(a => a.food_name == v)) {
                        onAddonRemove({ food_name: v });
                      } else {
                        setPendingAddonName(v);
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          </details>
        ))}
      </div>
    </div>
  );
};
