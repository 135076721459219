/* eslint-disable react/destructuring-assignment */
import { Add as AddIcon, Search as SearchIcon } from '@mui/icons-material';
import {
  Container,
  Divider,
  Fab,
  IconButton,
  InputBase,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  TablePagination,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { foodApi } from 'api';
import { FormEvent, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useFoodSearchIndex } from '../../client-search/useFoodSearchIndex';
import apiClient from '../api-client';
import FoodSearchResult from '../components/food-search-result';
import { QueryKey } from '../types';
import { formatNumber } from '../utils/utils';

export function FoodSearchView(props: {
  queryParamName: string,
  onFoodSelect?: (foodName: string) => void,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [liveQuery, _setLiveQuery] = useState('');
  const [searchState, setSearchState] = useState({
    query: '',
    offset: 0,
    limit: 50,
  });

  const serverSearchQuery = useQuery(
    [QueryKey.FoodSearch, searchState],
    async () => {
      return apiClient.searchFoods(
        searchState.query,
        searchState.offset,
        searchState.limit,
      );
    },
    { staleTime: 0 },
  );

  const recentFoodQuery = useQuery(
    [QueryKey.RecentFoods],
    async () => {
      const res = await foodApi.appApiFoodFoodDetailsGetRecentFoods({
        limit: searchState.limit,
      });
      return {
        items: res.data.map(d => d.food),
        hasNextPage: false,
      };
    },
  );

  const clientSearch = useFoodSearchIndex();
  const clientSearchQuery = useQuery(
    [QueryKey.ClientSearch, liveQuery, clientSearch.isDone],
    async () => {
      if (!clientSearch.isDone) {
        return { items: [], hasNextPage: false };
      }
      const res = await clientSearch.result.search('flexi', liveQuery);
      return {
        items: res.items.map(i => ({
          ...i,
          // For now, clear the updated-time so as not to confuse client
          // search results with "recently updated meals".
          updated_time: '',
        })),
        hasNextPage: false,
      };
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
    },
  );

  const foodListQuery = searchState.query
    ? serverSearchQuery
    : liveQuery
    ? clientSearchQuery
    : recentFoodQuery;

  const setLiveQuery = (query: string) => {
    _setLiveQuery(query);
    if (query.length == 0) {
      setSearchState({
        ...searchState,
        query: '',
      });
    }
  };

  useEffect(() => {
    const queryParam = searchParams.get(props.queryParamName);
    const offsetParam = searchParams.get(`${props.queryParamName}-offset`);
    const limitParam = searchParams.get(`${props.queryParamName}-limit`);

    const offset = parseInt(offsetParam ?? '0');
    const limit = parseInt(limitParam ?? '50');

    setLiveQuery((queryParam ?? '').trim());
    setSearchState({
      query: (queryParam ?? '').trim(),
      offset: Number.isNaN(offset) || offset < 0 ? 0 : offset,
      limit: Number.isNaN(limit) || limit < 1 ? 10 : limit,
    });
  }, [searchParams, props.queryParamName]);

  const handleSearchSubmit = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setSearchParams({
      ...(liveQuery ? { [props.queryParamName]: liveQuery } : {}),
      ...(
        searchState.offset > 0
          ? { offset: searchState.offset.toString() }
          : {}
      ),
      ...(
        searchState.limit != 50
          ? { limit: searchState.limit.toString() }
          : {}
      ),
    });
  };

  const handlePaginationClick = (page: number) => {
    const offset = searchState.limit * Math.max(0, page);
    setSearchParams({
      ...(searchState.query ? { [props.queryParamName]: searchState.query } : {}),
      ...(offset !== 0 ? { offset: offset.toString() } : {}),
      ...(searchState.limit !== 10
        ? { limit: searchState.limit.toString() }
        : {}),
    });
  };

  return (
    <>
      <Paper
        component="form"
        onSubmit={handleSearchSubmit}
        elevation={3}
        sx={{
          display: 'flex',
          alignItems: 'center',
          mt: '20px',
          mb: '20px',
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search foods"
          inputProps={{ 'aria-label': 'search foods' }}
          onChange={evt => setLiveQuery(evt.target.value)}
          value={liveQuery}
        />
        <small style={{ marginRight: 10 }}>
          {clientSearch.loadState.state} / {formatNumber(clientSearch.indexSize)} items{' '}
          {clientSearch.loadState.isError && ' ' + clientSearch.loadState.error}
          <span onClick={() => clientSearch.reload()} style={{ cursor: 'pointer' }}>(rebuild)</span>
        </small>
        <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
      {foodListQuery.isLoading && <div>Loading...</div>}
      {!!foodListQuery.data && (
        <List>
          {liveQuery && (
            <>
              <ListItemButton
                component="a"
                href={`https://google.ca/search?q=${liveQuery}`}
                target="_blank"
              >
                <ListItemText primary={`Search google for: ${liveQuery}`} />
              </ListItemButton>
              <Divider />
            </>
          )}
          {foodListQuery.data.items.map((item, index) => (
            <FoodSearchResult
              key={index}
              value={item!}
              last={index === foodListQuery.data.items.length - 1}
              onFoodSelect={props.onFoodSelect}
            />
          ))}
        </List>
      )}
      {foodListQuery == serverSearchQuery && (
        <TablePagination
          component="div"
          count={serverSearchQuery.data?.hasNextPage
            ? -1
            : searchState.offset + (serverSearchQuery.data?.items.length ?? 0)}
          onPageChange={(_, page) => {
            handlePaginationClick(page);
          }}
          page={Math.floor(searchState.offset / searchState.limit)}
          rowsPerPage={searchState.limit}
          rowsPerPageOptions={[]}
        />
      )}
    </>
  );
}

export default function FoodSearchScreen() {
  return (
    <Container maxWidth="lg">
      <FoodSearchView queryParamName="query" />
    </Container>
  );
}
