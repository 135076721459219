import { Alert, Button, Container, Stack, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import { FormEvent, MouseEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FoodEditor from '../food-editor/components/food-editor';
import useUpdateFoodMutation from '../food-editor/hooks/use-update-food-mutation';
import emptyFoodEditorValue from '../food-editor/utils/empty-food-editor-value';
import foodEditorValueToFoodRequest from '../food-editor/utils/food-editor-value-to-food-request';
import foodResponseToFoodEditorValue from '../food-editor/utils/food-response-to-food-editor-value';

import { useSentryReplay } from 'analytics';
import { useAuth } from 'context/appContext';
import { logTrackedError } from 'errorTracking';
import { apiFoodResponseToFoodResponse } from 'food-editor/api-client';
import { FoodEditorAttributions, FoodEditorFoodResponse } from 'food-editor/types';
import { foodEditorGetErrors, foodEditorGetWarnings } from 'food-editor/utils/is-food-editor-valid';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import { useFoodDetails } from 'services/FoodDetailsService';
import { parseQueryError } from 'utils';
import { FOOD_STATUS_OPTIONS, FOOD_STATUS_TRANSITIONS, FoodStatusOption } from '../food-editor/components/food-editor';

export const UpdateFoodPage = () => {
  useSentryReplay();

  return <UpdateFoodForm />;
};

const UpdateFoodForm = () => {
  const navigate = useNavigate();
  const foodNameFromPath = decodeURIComponent(window.location.pathname.replace('/foods/', ''));
  const queryParams = new URLSearchParams(window.location.search);
  const foodName = foodNameFromPath || queryParams.get('food_name');
  const getFoodQuery = useFoodDetails(foodName, {
    refetchOnMount: true,
  });
  const [editorValue, setEditorValue] = useState(emptyFoodEditorValue());
  const updateFoodMutation = useUpdateFoodMutation(foodName!);
  const [nextAction, setNextAction] = useState<'return' | 'edit'>('return');
  const editorErrors = foodEditorGetErrors(editorValue);
  const { authInfo } = useAuth();

  useSetPageTitle('', foodName);

  useEffect(() => {
    if (getFoodQuery.query.isSuccess) {
      setEditorValue(foodResponseToFoodEditorValue(apiFoodResponseToFoodResponse(getFoodQuery)));
    }
  }, [getFoodQuery.query.isSuccess]);

  const foodResponseCallbackRef = React.useRef<() => Promise<void>>(null);
  useEffect(() => {
    if (updateFoodMutation.isSuccess) {
      window.alert('Changes saved!');

      foodResponseCallbackRef.current?.();
      if (nextAction === 'return') {
        navigate('/foods');
      }
    }
  }, [updateFoodMutation.isSuccess, nextAction]);

  useEffect(() => {
    if (updateFoodMutation.isError) {
      logTrackedError({
        sourceName: 'UpdateFoodForm.updateFoodMutation',
        origin: updateFoodMutation.error as any,
        stackError: new Error(),
        context: { foodName: editorValue.term },
        userMessage: `Error updating food ${editorValue.term}.`,
      });
    }
  }, [updateFoodMutation.isError]);

  const handleSubmit = (evt: MouseEvent, nextAction: 'return' | 'edit', opts?: { setNextStatus?: boolean }) => {
    evt.preventDefault();
    setNextAction(nextAction);

    if (editorErrors.length > 0) {
      return;
    }

    if (editorWarnings.length > 0) {
      const warningMessage = 'Please check the warnings and confirm you are submitting:\n' + editorWarnings.join('\n');
      const mixpanelProps = {
        'Food name': editorValue.term,
        'Warnings': editorWarnings.join(', '),
      };

      mixpanel.track('Food editor: warning confirmation displayed', mixpanelProps);

      if (!window.confirm(`${warningMessage}`)) {
        mixpanel.track('Food editor: warning confirmation canceled', mixpanelProps);
        return;
      }

      mixpanel.track('Food editor: warning confirmation result', mixpanelProps);
    }

    mixpanel.track('Food editor: update food', {
      'Food name': editorValue.term,
    });

    if (opts?.setNextStatus) {
      updateFoodMutation.mutate(
        foodEditorValueToFoodRequest({ ...editorValue, status: nextStatus?.value ?? editorValue.status }),
      );
    } else {
      updateFoodMutation.mutate(foodEditorValueToFoodRequest(editorValue));
    }
  };

  if (getFoodQuery.query.isError) {
    return <div>Not Found</div>;
  }

  if (getFoodQuery.query.isLoading) {
    return <Typography>Loading...</Typography>;
  }

  const foodEditorAttributions: FoodEditorAttributions = {
    createdTime: getFoodQuery.food?.created_time,
    createdByUserId: getFoodQuery.food?.created_by_user_id,
    updatedTime: getFoodQuery.food?.updated_time,
    lastUpdatedByUserId: getFoodQuery.food?.last_updated_by_user_id,
    triagedTime: getFoodQuery.food?.triaged_time,
    triagedByUserId: getFoodQuery.food?.triaged_by_user_id,
    scrapedTime: getFoodQuery.food?.scraped_time,
    scrapedByUserId: getFoodQuery.food?.scraped_by_user_id,
    reviewedTime: getFoodQuery.food?.reviewed_time,
    reviewedByUserId: getFoodQuery.food?.reviewed_by_user_id,
  };

  const currStatus = getFoodQuery.food?.status ?? '';
  const currStatusTransition = FOOD_STATUS_TRANSITIONS[currStatus as keyof typeof FOOD_STATUS_TRANSITIONS]
    ?? FOOD_STATUS_TRANSITIONS.created;
  const nextStatus = FOOD_STATUS_OPTIONS.find(f => f.value === currStatusTransition[0]);
  const editorWarnings = foodEditorGetWarnings(
    foodEditorAttributions,
    currStatus,
    authInfo?.reviewer_id,
    editorValue.ontologyLevels,
  );

  return (
    <MainCard sx={{ overflow: 'visible' }} style={{ minWidth: 920 }}>
      <Stack spacing={2}>
        <EditorWarnings warnings={editorWarnings} />
        <div>
          {!!nextStatus && nextStatus.value == 'draft' && (
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                type="submit"
                disabled={updateFoodMutation.isLoading}
                onClick={(evt) => handleSubmit(evt, 'edit', { setNextStatus: true })}
              >
                Set to {nextStatus.label}
              </Button>
              <Alert severity="info">Set to Draft before scraping</Alert>
            </Stack>
          )}
        </div>
        <FoodEditor
          attributions={foodEditorAttributions}
          mode="update"
          onChangeValue={setEditorValue}
          showErrors={updateFoodMutation.isSuccess || updateFoodMutation.isError}
          value={editorValue}
          initialName={null}
          foodResponseCallbackRef={foodResponseCallbackRef}
        />
      </Stack>
      <Stack direction="column" spacing={2} style={{ paddingTop: 50 }}>
        {updateFoodMutation.isLoading && (
          <Alert severity="info" style={{ maxWidth: '50%' }}>
            Saving...
          </Alert>
        )}
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            type="submit"
            disabled={updateFoodMutation.isLoading}
            onClick={(evt) => handleSubmit(evt, 'return')}
          >
            Save and return
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={updateFoodMutation.isLoading}
            onClick={(evt) => handleSubmit(evt, 'edit')}
          >
            Save and edit
          </Button>
          {!!nextStatus && nextStatus.value != 'inactive' && (
            <Button
              id="nextStatusButton"
              variant="contained"
              type="submit"
              disabled={updateFoodMutation.isLoading}
              onClick={(evt) => handleSubmit(evt, 'edit', { setNextStatus: true })}
            >
              Save to {nextStatus.label}
            </Button>
          )}
        </Stack>
        {
          /* {updateFoodMutation.isError && (
          <Alert severity="error" style={{ maxWidth: '50%' }}>
            {parseQueryError(updateFoodMutation.error)}
          </Alert>
        )} */
        }
        {editorErrors.length > 0 && (
          <Alert severity="error" style={{ maxWidth: '50%' }}>
            Editor error: {editorErrors.join(', ')}
          </Alert>
        )}
        <EditorWarnings warnings={editorWarnings} />
      </Stack>
    </MainCard>
  );
};

export const EditorWarnings = (props: { warnings: string[] }) => {
  const { warnings } = props;
  if (warnings.length === 0) {
    return null;
  }

  return (
    <Stack spacing={2}>
      {warnings.map((w, idx) => (
        <Alert key={idx} variant="filled" severity="warning" style={{ maxWidth: '50%' }}>
          Warning: {w}
        </Alert>
      ))}
    </Stack>
  );
};
