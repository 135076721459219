import * as Sentry from '@sentry/browser';
import { dataReviewApi } from 'api';
import { Capabilities } from 'auth-capabilities';
import { useAuth } from 'context/appContext';
import { useFeatures } from 'context/FeatureContext';
import { logTrackedError } from 'errorTracking';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSocketEvent, useSocketIOClient, useSocketOnConnect } from 'socketio/SocketIOService';
import { encodeQueryParams } from 'utils';
import { telemetrySend } from 'utils/telemetry';
import { useBrowserNotifications } from './BrowserNotificationService';
import { useMealQueueService } from './MealQueueService';

let normalFavicon: string = '';

/**
 * Internal hook responsible for blinking the favicon while `alerting` is true.
 */
const useNewQueueFaviconAlert = (opts: { alerting: boolean }) => {
  const flags = useFeatures();

  const alertIcon = '/favicon-alert.png';
  useEffect(() => {
    const linkElement = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
    if (!linkElement) {
      return;
    }

    if (!normalFavicon) {
      normalFavicon = linkElement.href;
    }

    if (!opts.alerting || !flags.notification_favicon_blink) {
      linkElement.href = normalFavicon;
      return;
    }

    const interval = setInterval(() => {
      linkElement.href = (linkElement.href == normalFavicon) ? alertIcon : normalFavicon;
    }, 350);

    return () => clearInterval(interval);
  }, [opts.alerting, flags]);
};

const getNextQueueClickedEmitter = new EventTarget();

/**
 * Provides a `getNextQueue` function that will navigate to the next queue item
 * which needs labeling.
 */
export const useGetNextQueue = () => {
  const navigate = useNavigate();
  const { authInfo } = useAuth();
  const { ownedQueues } = useMealQueueService();

  const getNextQueue = React.useCallback(async (opts: {
    source: string,
    medium?: string,
  }) => {
    mixpanel.track('Get Next meal queue item');
    getNextQueueClickedEmitter.dispatchEvent(new Event('click'));
    const queryParams = encodeQueryParams({
      utm_source: opts.source,
      utm_medium: opts.medium,
    });
    const firstOwnedQueue = ownedQueues[0];
    if (firstOwnedQueue) {
      navigate(`/queue-item/${firstOwnedQueue.id}?${queryParams}`, {
        state: {
          queueItem: firstOwnedQueue,
        },
      });
      return;
    }

    if (!authInfo) {
      return;
    }

    try {
      const nextItemRes = await dataReviewApi.appApiDataReviewerGetDataReviewerNextQueue({
        data_reviewer_id: authInfo.reviewer_id,
      });
      const nextItem = nextItemRes.status == 204 ? null : nextItemRes.data;
      if (!nextItem) {
        console.log('Get Next: no next item');
        return;
      }
      navigate(`/queue-item/${nextItem.id}?${queryParams}`, {
        state: {
          queueItem: nextItem,
        },
      });
    } catch (e) {
      logTrackedError({
        sourceName: 'useGetNextQueue.getNextQueue',
        origin: e as any,
        stackError: new Error(),
        context: {},
        userMessage: `Unable to get next queue. Please try again.`,
      });
    }
  }, [navigate, authInfo, ownedQueues]);

  return { getNextQueue };
};

// Used by the `MealQueueNotificationServiceInner`
let lastNotificationTimestamp: string | null = null;
const getNextClickedStats = {
  count: 0,
  firstTimestamp: 0,
};

type NotificationEngineQueueState = 'unassigned' | 'assigned' | 'complete';

// Singleton component that provides notifications when the user has work to do. Used
// only by the `MealQueueNotificationService`, which should only be mounted once in the
// top level of the app.
const MealQueueNotificationServiceInner = () => {
  const { authInfo } = useAuth();
  const { notify } = useBrowserNotifications();
  const { getNextQueue } = useGetNextQueue();
  const mealQueueService = useMealQueueService();
  const [currentAlertId, setCurrentAlertId] = useState<number | null>(null);
  const flags = useFeatures();

  const io = useSocketIOClient();
  useSocketOnConnect(
    io,
    useCallback(socket => {
      socket.emit('queues:join');
    }, []),
  );

  useSocketEvent(
    io,
    'queues:refetch',
    useCallback((events: Array<{ queueId: number, newState: NotificationEngineQueueState }>) => {
      const activeQueueStates = Object.fromEntries(
        mealQueueService.allActiveQueues.map(q => [
          q.id,
          q.is_processed ? 'complete' : q.first_reviewer_user_id ? 'assigned' : 'unassigned',
        ]),
      );
      console.log('queues:refetch: got events:', events, 'with queues:', activeQueueStates);
      const shouldRefetch = events.some(e => {
        return activeQueueStates[e.queueId] != e.newState;
      });
      console.log('queues:refetch: should refresh:', shouldRefetch);
    }, [mealQueueService]),
  );

  // When "get next" is clicked, track the number of counts, and the timestamp
  // of the first count.
  // Note: the "alerting" hook, below, will reset this state when alerting starts.
  useEffect(() => {
    const handleGetNextClicked = () => {
      getNextClickedStats.count += 1;
      if (!getNextClickedStats.firstTimestamp) {
        getNextClickedStats.firstTimestamp = Date.now();
      }
    };
    getNextQueueClickedEmitter.addEventListener('click', handleGetNextClicked);
    return () => getNextQueueClickedEmitter.removeEventListener('click', handleGetNextClicked);
  }, []);

  // Determine whether the application should be in an "alerting" state (ie,
  // there is work for the user to do)
  const isAlerting = !mealQueueService.currentlyLabellingOwnQueue && (
    mealQueueService.ownedCount > 0
    || mealQueueService.availableCount > 0
  ) && (
    !flags.notification_UHP_only || mealQueueService.allActiveQueues.some(q => q.is_ultra_priority_patient)
  );

  // Blink the favicon when alerting
  useNewQueueFaviconAlert({ alerting: isAlerting });

  // Log telemetry events when alrting starts/finishes
  useEffect(() => {
    console.log(
      'Alerting:',
      isAlerting,
      'currentAlertId:',
      currentAlertId,
      'isLoading:',
      mealQueueService.query.isLoading,
    );

    if (!!isAlerting == !!currentAlertId || mealQueueService.query.isLoading) {
      return;
    }

    if (isAlerting) {
      const alertId = Date.now();
      setCurrentAlertId(alertId);
      getNextClickedStats.count = 0;
      getNextClickedStats.firstTimestamp = 0;
      /*
      disabling for now, because this is not used
      telemetrySend({
        name: 'QueueAlertResponseTime',
        key: '' + alertId,
        value: 0,
        properties: {
          startUrl: window.location.pathname,
        },
      });
      */
    } else {
      /*
      disabling for now, because this is not used
      telemetrySend({
        name: 'QueueAlertResponseTime',
        key: '' + currentAlertId,
        value: (Date.now() - currentAlertId!) / 1000,
        properties: {
          getNextClickCount: getNextClickedStats.count,
          getNextClickDelaySeconds: getNextClickedStats.firstTimestamp
            ? (getNextClickedStats.firstTimestamp - currentAlertId!) / 1000
            : null,
          endUrl: window.location.pathname,
          endCurrentlyLabeling: mealQueueService.currentlyLabellingOwnQueue,
          endOwnCount: mealQueueService.ownedCount,
          endAvailableCount: mealQueueService.availableCount,
        },
      });
      */
      setCurrentAlertId(null);
    }
  }, [isAlerting, currentAlertId, mealQueueService, setCurrentAlertId]);

  // Send a browser notification each time a new queue arives
  useEffect(() => {
    if (!isAlerting) {
      return;
    }

    // Determine whether a new queue has arrived
    const nextActionableQueue = _.maxBy(
      mealQueueService.allActiveQueues.filter(q => (
        (!q.first_reviewer_user_id)
        || (q.first_reviewer_user_id == authInfo?.reviewer_id)
      )),
      q => q.created_time,
    );
    if (!nextActionableQueue) {
      return;
    }

    if (flags.notification_UHP_only && !nextActionableQueue.is_ultra_priority_patient) {
      return;
    }

    if (!lastNotificationTimestamp) {
      lastNotificationTimestamp = nextActionableQueue.created_time;
    }

    if (nextActionableQueue.created_time <= lastNotificationTimestamp) {
      return;
    }

    // Send a browser notification
    lastNotificationTimestamp = nextActionableQueue.created_time;
    notify('New MPQ: ' + nextActionableQueue.id, {
      onClick: () => {
        if (!authInfo) {
          return;
        }
        getNextQueue({
          source: 'notification',
          medium: 'browser-native',
        });
      },
    });
  }, [isAlerting, mealQueueService.allActiveQueues, authInfo, notify, getNextQueue]);
  return null;
};

export const MealQueueNotificationService = () => {
  const { hasAuth } = useAuth();
  const notificationsEnabled = hasAuth(Capabilities.mpqItemLabel);
  return notificationsEnabled ? <MealQueueNotificationServiceInner /> : null;
};
