import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

export default function useLocalStorage<ValueType,>(
  key: string,
  defaultValue: ValueType,
): [ValueType, (newValue: ValueType | ((prevValue: ValueType) => ValueType)) => void] {
  const [value, setValue] = useState<ValueType>(() => {
    const storedValue = typeof window !== 'undefined' ? localStorage.getItem(key) : null;
    return storedValue === null ? defaultValue : JSON.parse(storedValue);
  });

  useEffect(() => {
    const listener = (e: StorageEvent) => {
      if (typeof window !== 'undefined' && e.storageArea === localStorage && e.key === key) {
        setValue(e.newValue ? JSON.parse(e.newValue) : e.newValue);
      }
    };
    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, [key, defaultValue]);

  function isFunction(value: unknown): value is (prevValue: ValueType) => ValueType {
    return typeof value === 'function';
  }

  const setValueInLocalStorage = (newValue: ValueType | ((prevValue: ValueType) => ValueType)) => {
    setValue((currentValue: any) => {
      const result = isFunction(newValue) ? newValue(currentValue) : newValue;
      if (typeof window !== 'undefined') {
        try {
          localStorage.setItem(key, JSON.stringify(result));
        } catch (e) {
          console.error(`Error setting localStorage key “${key}”:`, e);
        }
      }
      return result;
    });
  };

  return [value, setValueInLocalStorage];
}
