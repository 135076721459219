/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MealItemCustomResponse } from '../models';
// @ts-ignore
import { MealResponse } from '../models';
// @ts-ignore
import { PatientResponse } from '../models';
// @ts-ignore
import { UserResponse } from '../models';
/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary run validation of food items for review
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerValidateFoodDatabase: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/foods/food_database_validation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Spell check a food item
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchPostSpellCheck: async (food_name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodSearchPostSpellCheck', 'food_name', food_name)
            const localVarPath = `/foods/spell_check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_name !== undefined) {
                localVarQueryParameter['food_name'] = food_name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Rebuild the FoodResponse Cache. Runs as a background process.
         * @param {boolean} [build_usda_table] include USDA list in refresh
         * @param {boolean} [threaded] multi-threaded approach
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodResponseCacheBuildFoodResponseCache: async (build_usda_table?: boolean, threaded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/foods/build_food_response_cache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (build_usda_table !== undefined) {
                localVarQueryParameter['build_usda_table'] = build_usda_table;
            }

            if (threaded !== undefined) {
                localVarQueryParameter['threaded'] = threaded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a reminder email to patients who are registered but not active
         * @param {string} reference_date 
         * @param {number} days_since_registration 
         * @param {boolean} [with_valid_phone_number] 
         * @param {boolean} [test_mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientPostEmailRegisteredNonLoggingPatients: async (reference_date: string, days_since_registration: number, with_valid_phone_number?: boolean, test_mode?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reference_date' is not null or undefined
            assertParamExists('appApiPatientPostEmailRegisteredNonLoggingPatients', 'reference_date', reference_date)
            // verify required parameter 'days_since_registration' is not null or undefined
            assertParamExists('appApiPatientPostEmailRegisteredNonLoggingPatients', 'days_since_registration', days_since_registration)
            const localVarPath = `/admin/email_registered_non_logging_patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reference_date !== undefined) {
                localVarQueryParameter['reference_date'] = reference_date;
            }

            if (days_since_registration !== undefined) {
                localVarQueryParameter['days_since_registration'] = days_since_registration;
            }

            if (with_valid_phone_number !== undefined) {
                localVarQueryParameter['with_valid_phone_number'] = with_valid_phone_number;
            }

            if (test_mode !== undefined) {
                localVarQueryParameter['test_mode'] = test_mode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current email admin report
         * @param {number} user_id 
         * @param {string} [reference_datetime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportingGetAfternoonDieteticStatsEmail: async (user_id: number, reference_datetime?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('appApiReportingGetAfternoonDieteticStatsEmail', 'user_id', user_id)
            const localVarPath = `/reporting/afternoon_dietetic_stats_email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (user_id !== undefined) {
                localVarQueryParameter['user_id'] = user_id;
            }

            if (reference_datetime !== undefined) {
                localVarQueryParameter['reference_datetime'] = reference_datetime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current archived email report
         * @param {number} user_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportingGetArchivedMealItemReport: async (user_id: number, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('appApiReportingGetArchivedMealItemReport', 'user_id', user_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiReportingGetArchivedMealItemReport', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiReportingGetArchivedMealItemReport', 'date_until', date_until)
            const localVarPath = `/reporting/get_archived_meal_item_report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (user_id !== undefined) {
                localVarQueryParameter['user_id'] = user_id;
            }

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all custom items between two dates
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportingGetCustomItems: async (date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiReportingGetCustomItems', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiReportingGetCustomItems', 'date_until', date_until)
            const localVarPath = `/reporting/custom_meal_items/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all custom items, grouped by name
         * @param {string} [food_name] 
         * @param {string} [date_since] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportingGetCustomItemsByName: async (food_name?: string, date_since?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reporting/custom_meal_items/recent_by_name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_name !== undefined) {
                localVarQueryParameter['food_name'] = food_name;
            }

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all custom items tied specifically to an MPQ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportingGetCustomItemsMpq: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reporting/custom_meal_items/mpq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current email admin report
         * @param {number} user_id 
         * @param {string} [reference_date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportingGetDailyAdminStatsEmail: async (user_id: number, reference_date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('appApiReportingGetDailyAdminStatsEmail', 'user_id', user_id)
            const localVarPath = `/reporting/daily_admin_stats_email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (user_id !== undefined) {
                localVarQueryParameter['user_id'] = user_id;
            }

            if (reference_date !== undefined) {
                localVarQueryParameter['reference_date'] = reference_date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get missing food photos
         * @param {number} user_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportingGetMissingFoodPhotoReport: async (user_id: number, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('appApiReportingGetMissingFoodPhotoReport', 'user_id', user_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiReportingGetMissingFoodPhotoReport', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiReportingGetMissingFoodPhotoReport', 'date_until', date_until)
            const localVarPath = `/reporting/missing_food_photo_report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (user_id !== undefined) {
                localVarQueryParameter['user_id'] = user_id;
            }

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get food items updated between two dates
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} send_results_as_attachment 
         * @param {boolean} [send_to_me_only] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsFoodGetUpdatedFoodReport: async (date_since: string, date_until: string, send_results_as_attachment: boolean, send_to_me_only?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiReportsFoodGetUpdatedFoodReport', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiReportsFoodGetUpdatedFoodReport', 'date_until', date_until)
            // verify required parameter 'send_results_as_attachment' is not null or undefined
            assertParamExists('appApiReportsFoodGetUpdatedFoodReport', 'send_results_as_attachment', send_results_as_attachment)
            const localVarPath = `/food/updated_food_report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }

            if (send_results_as_attachment !== undefined) {
                localVarQueryParameter['send_results_as_attachment'] = send_results_as_attachment;
            }

            if (send_to_me_only !== undefined) {
                localVarQueryParameter['send_to_me_only'] = send_to_me_only;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get quality assurance data
         * @param {string} reference_date 
         * @param {number} threshold_min 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsLabelingGetLabelingReportData: async (reference_date: string, threshold_min: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reference_date' is not null or undefined
            assertParamExists('appApiReportsLabelingGetLabelingReportData', 'reference_date', reference_date)
            // verify required parameter 'threshold_min' is not null or undefined
            assertParamExists('appApiReportsLabelingGetLabelingReportData', 'threshold_min', threshold_min)
            const localVarPath = `/reporting/labeling_report/data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reference_date !== undefined) {
                localVarQueryParameter['reference_date'] = reference_date;
            }

            if (threshold_min !== undefined) {
                localVarQueryParameter['threshold_min'] = threshold_min;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve food log report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsOperationsReportsGetAppointments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/operations_reports/appointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve daily sheets report
         * @param {Array<number>} [clinic_ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsOperationsReportsGetDailySheets: async (clinic_ids?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/operations_reports/daily_sheets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clinic_ids) {
                localVarQueryParameter['clinic_ids'] = clinic_ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve food log report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsOperationsReportsGetEmailHistories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/operations_reports/email_histories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve food log report
         * @param {string} min_created_date 
         * @param {number} min_meal_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsOperationsReportsGetFoodLogs: async (min_created_date: string, min_meal_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'min_created_date' is not null or undefined
            assertParamExists('appApiReportsOperationsReportsGetFoodLogs', 'min_created_date', min_created_date)
            // verify required parameter 'min_meal_id' is not null or undefined
            assertParamExists('appApiReportsOperationsReportsGetFoodLogs', 'min_meal_id', min_meal_id)
            const localVarPath = `/admin/operations_reports/food_logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (min_created_date !== undefined) {
                localVarQueryParameter['min_created_date'] = min_created_date;
            }

            if (min_meal_id !== undefined) {
                localVarQueryParameter['min_meal_id'] = min_meal_id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve detailed subscription data with additional metrics
         * @param {string} min_meal_date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsOperationsReportsGetSubscriptionDetails: async (min_meal_date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'min_meal_date' is not null or undefined
            assertParamExists('appApiReportsOperationsReportsGetSubscriptionDetails', 'min_meal_date', min_meal_date)
            const localVarPath = `/admin/operations_reports/subscription_details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (min_meal_date !== undefined) {
                localVarQueryParameter['min_meal_date'] = min_meal_date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve subscription data
         * @param {string} min_expiration_date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsOperationsReportsGetSubscriptions: async (min_expiration_date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'min_expiration_date' is not null or undefined
            assertParamExists('appApiReportsOperationsReportsGetSubscriptions', 'min_expiration_date', min_expiration_date)
            const localVarPath = `/admin/operations_reports/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (min_expiration_date !== undefined) {
                localVarQueryParameter['min_expiration_date'] = min_expiration_date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get quality assurance data
         * @param {number} min_meal_id 
         * @param {number} max_meal_id 
         * @param {Array<number>} [clinic_ids] array of clinic ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsQualityAssuranceGetQaReportData: async (min_meal_id: number, max_meal_id: number, clinic_ids?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'min_meal_id' is not null or undefined
            assertParamExists('appApiReportsQualityAssuranceGetQaReportData', 'min_meal_id', min_meal_id)
            // verify required parameter 'max_meal_id' is not null or undefined
            assertParamExists('appApiReportsQualityAssuranceGetQaReportData', 'max_meal_id', max_meal_id)
            const localVarPath = `/reporting/qa_report/data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (min_meal_id !== undefined) {
                localVarQueryParameter['min_meal_id'] = min_meal_id;
            }

            if (max_meal_id !== undefined) {
                localVarQueryParameter['max_meal_id'] = max_meal_id;
            }

            if (clinic_ids) {
                localVarQueryParameter['clinic_ids'] = clinic_ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get quality assurance data
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {Array<number>} [clinic_ids] array of clinic ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsQualityAssuranceGetQaReportDataFromDates: async (date_since: string, date_until: string, clinic_ids?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiReportsQualityAssuranceGetQaReportDataFromDates', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiReportsQualityAssuranceGetQaReportDataFromDates', 'date_until', date_until)
            const localVarPath = `/reporting/qa_report/data_from_dates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }

            if (clinic_ids) {
                localVarQueryParameter['clinic_ids'] = clinic_ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patient Report metrics to summarize reports
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {Array<number>} patient_ids 232,323,643
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsReportMetricsGetReportMetricsData: async (date_since: string, date_until: string, patient_ids: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiReportsReportMetricsGetReportMetricsData', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiReportsReportMetricsGetReportMetricsData', 'date_until', date_until)
            // verify required parameter 'patient_ids' is not null or undefined
            assertParamExists('appApiReportsReportMetricsGetReportMetricsData', 'patient_ids', patient_ids)
            const localVarPath = `/reporting/report_metrics/data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }

            if (patient_ids) {
                localVarQueryParameter['patient_ids'] = patient_ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get summary of patient nutrient intake, aggregated by date
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionReportGetPatientAggregatedDailyNutrientSummary: async (patient_id: number, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiSubscriptionReportGetPatientAggregatedDailyNutrientSummary', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiSubscriptionReportGetPatientAggregatedDailyNutrientSummary', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiSubscriptionReportGetPatientAggregatedDailyNutrientSummary', 'date_until', date_until)
            const localVarPath = `/reporting/patients/{patient_id}/aggregated_daily_nutrient_summary`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current email admin report
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionReportGetPatientDailyMealItemIssueData: async (patient_id: number, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiSubscriptionReportGetPatientDailyMealItemIssueData', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiSubscriptionReportGetPatientDailyMealItemIssueData', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiSubscriptionReportGetPatientDailyMealItemIssueData', 'date_until', date_until)
            const localVarPath = `/reporting/patients/{patient_id}/daily_meal_item_issue_data`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user information by user_id. Do NOT use this api to fetch current user, use /users/current instead.
         * @param {number} user_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserGetUser: async (user_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('appApiUserGetUser', 'user_id', user_id)
            const localVarPath = `/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate a user\'s account
         * @param {number} user_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserPostAdminDeactivateUser: async (user_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('appApiUserPostAdminDeactivateUser', 'user_id', user_id)
            const localVarPath = `/admin/deactivate-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (user_id !== undefined) {
                localVarQueryParameter['user_id'] = user_id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary run validation of food items for review
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDataReviewerValidateFoodDatabase(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDataReviewerValidateFoodDatabase(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Spell check a food item
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchPostSpellCheck(food_name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchPostSpellCheck(food_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Rebuild the FoodResponse Cache. Runs as a background process.
         * @param {boolean} [build_usda_table] include USDA list in refresh
         * @param {boolean} [threaded] multi-threaded approach
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodResponseCacheBuildFoodResponseCache(build_usda_table?: boolean, threaded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodResponseCacheBuildFoodResponseCache(build_usda_table, threaded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a reminder email to patients who are registered but not active
         * @param {string} reference_date 
         * @param {number} days_since_registration 
         * @param {boolean} [with_valid_phone_number] 
         * @param {boolean} [test_mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientPostEmailRegisteredNonLoggingPatients(reference_date: string, days_since_registration: number, with_valid_phone_number?: boolean, test_mode?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientPostEmailRegisteredNonLoggingPatients(reference_date, days_since_registration, with_valid_phone_number, test_mode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current email admin report
         * @param {number} user_id 
         * @param {string} [reference_datetime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportingGetAfternoonDieteticStatsEmail(user_id: number, reference_datetime?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportingGetAfternoonDieteticStatsEmail(user_id, reference_datetime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current archived email report
         * @param {number} user_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportingGetArchivedMealItemReport(user_id: number, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportingGetArchivedMealItemReport(user_id, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all custom items between two dates
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportingGetCustomItems(date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportingGetCustomItems(date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all custom items, grouped by name
         * @param {string} [food_name] 
         * @param {string} [date_since] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportingGetCustomItemsByName(food_name?: string, date_since?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealItemCustomResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportingGetCustomItemsByName(food_name, date_since, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all custom items tied specifically to an MPQ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportingGetCustomItemsMpq(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportingGetCustomItemsMpq(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current email admin report
         * @param {number} user_id 
         * @param {string} [reference_date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportingGetDailyAdminStatsEmail(user_id: number, reference_date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportingGetDailyAdminStatsEmail(user_id, reference_date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get missing food photos
         * @param {number} user_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportingGetMissingFoodPhotoReport(user_id: number, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportingGetMissingFoodPhotoReport(user_id, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get food items updated between two dates
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} send_results_as_attachment 
         * @param {boolean} [send_to_me_only] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportsFoodGetUpdatedFoodReport(date_since: string, date_until: string, send_results_as_attachment: boolean, send_to_me_only?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportsFoodGetUpdatedFoodReport(date_since, date_until, send_results_as_attachment, send_to_me_only, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get quality assurance data
         * @param {string} reference_date 
         * @param {number} threshold_min 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportsLabelingGetLabelingReportData(reference_date: string, threshold_min: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportsLabelingGetLabelingReportData(reference_date, threshold_min, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve food log report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportsOperationsReportsGetAppointments(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportsOperationsReportsGetAppointments(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve daily sheets report
         * @param {Array<number>} [clinic_ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportsOperationsReportsGetDailySheets(clinic_ids?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportsOperationsReportsGetDailySheets(clinic_ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve food log report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportsOperationsReportsGetEmailHistories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportsOperationsReportsGetEmailHistories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve food log report
         * @param {string} min_created_date 
         * @param {number} min_meal_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportsOperationsReportsGetFoodLogs(min_created_date: string, min_meal_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportsOperationsReportsGetFoodLogs(min_created_date, min_meal_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve detailed subscription data with additional metrics
         * @param {string} min_meal_date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportsOperationsReportsGetSubscriptionDetails(min_meal_date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportsOperationsReportsGetSubscriptionDetails(min_meal_date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve subscription data
         * @param {string} min_expiration_date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportsOperationsReportsGetSubscriptions(min_expiration_date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportsOperationsReportsGetSubscriptions(min_expiration_date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get quality assurance data
         * @param {number} min_meal_id 
         * @param {number} max_meal_id 
         * @param {Array<number>} [clinic_ids] array of clinic ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportsQualityAssuranceGetQaReportData(min_meal_id: number, max_meal_id: number, clinic_ids?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportsQualityAssuranceGetQaReportData(min_meal_id, max_meal_id, clinic_ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get quality assurance data
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {Array<number>} [clinic_ids] array of clinic ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportsQualityAssuranceGetQaReportDataFromDates(date_since: string, date_until: string, clinic_ids?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportsQualityAssuranceGetQaReportDataFromDates(date_since, date_until, clinic_ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patient Report metrics to summarize reports
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {Array<number>} patient_ids 232,323,643
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReportsReportMetricsGetReportMetricsData(date_since: string, date_until: string, patient_ids: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReportsReportMetricsGetReportMetricsData(date_since, date_until, patient_ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get summary of patient nutrient intake, aggregated by date
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiSubscriptionReportGetPatientAggregatedDailyNutrientSummary(patient_id: number, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiSubscriptionReportGetPatientAggregatedDailyNutrientSummary(patient_id, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current email admin report
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiSubscriptionReportGetPatientDailyMealItemIssueData(patient_id: number, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiSubscriptionReportGetPatientDailyMealItemIssueData(patient_id, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user information by user_id. Do NOT use this api to fetch current user, use /users/current instead.
         * @param {number} user_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiUserGetUser(user_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiUserGetUser(user_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deactivate a user\'s account
         * @param {number} user_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiUserPostAdminDeactivateUser(user_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiUserPostAdminDeactivateUser(user_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @summary run validation of food items for review
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDataReviewerValidateFoodDatabase(options?: any): AxiosPromise<any> {
            return localVarFp.appApiDataReviewerValidateFoodDatabase(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Spell check a food item
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchPostSpellCheck(food_name: string, options?: any): AxiosPromise<string> {
            return localVarFp.appApiFoodFoodSearchPostSpellCheck(food_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Rebuild the FoodResponse Cache. Runs as a background process.
         * @param {boolean} [build_usda_table] include USDA list in refresh
         * @param {boolean} [threaded] multi-threaded approach
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodResponseCacheBuildFoodResponseCache(build_usda_table?: boolean, threaded?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.appApiFoodResponseCacheBuildFoodResponseCache(build_usda_table, threaded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a reminder email to patients who are registered but not active
         * @param {string} reference_date 
         * @param {number} days_since_registration 
         * @param {boolean} [with_valid_phone_number] 
         * @param {boolean} [test_mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientPostEmailRegisteredNonLoggingPatients(reference_date: string, days_since_registration: number, with_valid_phone_number?: boolean, test_mode?: boolean, options?: any): AxiosPromise<Array<PatientResponse>> {
            return localVarFp.appApiPatientPostEmailRegisteredNonLoggingPatients(reference_date, days_since_registration, with_valid_phone_number, test_mode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current email admin report
         * @param {number} user_id 
         * @param {string} [reference_datetime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportingGetAfternoonDieteticStatsEmail(user_id: number, reference_datetime?: string, options?: any): AxiosPromise<void> {
            return localVarFp.appApiReportingGetAfternoonDieteticStatsEmail(user_id, reference_datetime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current archived email report
         * @param {number} user_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportingGetArchivedMealItemReport(user_id: number, date_since: string, date_until: string, options?: any): AxiosPromise<void> {
            return localVarFp.appApiReportingGetArchivedMealItemReport(user_id, date_since, date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all custom items between two dates
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportingGetCustomItems(date_since: string, date_until: string, options?: any): AxiosPromise<MealResponse> {
            return localVarFp.appApiReportingGetCustomItems(date_since, date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all custom items, grouped by name
         * @param {string} [food_name] 
         * @param {string} [date_since] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportingGetCustomItemsByName(food_name?: string, date_since?: string, options?: any): AxiosPromise<MealItemCustomResponse> {
            return localVarFp.appApiReportingGetCustomItemsByName(food_name, date_since, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all custom items tied specifically to an MPQ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportingGetCustomItemsMpq(options?: any): AxiosPromise<MealResponse> {
            return localVarFp.appApiReportingGetCustomItemsMpq(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current email admin report
         * @param {number} user_id 
         * @param {string} [reference_date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportingGetDailyAdminStatsEmail(user_id: number, reference_date?: string, options?: any): AxiosPromise<void> {
            return localVarFp.appApiReportingGetDailyAdminStatsEmail(user_id, reference_date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get missing food photos
         * @param {number} user_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportingGetMissingFoodPhotoReport(user_id: number, date_since: string, date_until: string, options?: any): AxiosPromise<void> {
            return localVarFp.appApiReportingGetMissingFoodPhotoReport(user_id, date_since, date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get food items updated between two dates
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} send_results_as_attachment 
         * @param {boolean} [send_to_me_only] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsFoodGetUpdatedFoodReport(date_since: string, date_until: string, send_results_as_attachment: boolean, send_to_me_only?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.appApiReportsFoodGetUpdatedFoodReport(date_since, date_until, send_results_as_attachment, send_to_me_only, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get quality assurance data
         * @param {string} reference_date 
         * @param {number} threshold_min 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsLabelingGetLabelingReportData(reference_date: string, threshold_min: number, options?: any): AxiosPromise<string> {
            return localVarFp.appApiReportsLabelingGetLabelingReportData(reference_date, threshold_min, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve food log report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsOperationsReportsGetAppointments(options?: any): AxiosPromise<string> {
            return localVarFp.appApiReportsOperationsReportsGetAppointments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve daily sheets report
         * @param {Array<number>} [clinic_ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsOperationsReportsGetDailySheets(clinic_ids?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.appApiReportsOperationsReportsGetDailySheets(clinic_ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve food log report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsOperationsReportsGetEmailHistories(options?: any): AxiosPromise<string> {
            return localVarFp.appApiReportsOperationsReportsGetEmailHistories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve food log report
         * @param {string} min_created_date 
         * @param {number} min_meal_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsOperationsReportsGetFoodLogs(min_created_date: string, min_meal_id: number, options?: any): AxiosPromise<string> {
            return localVarFp.appApiReportsOperationsReportsGetFoodLogs(min_created_date, min_meal_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve detailed subscription data with additional metrics
         * @param {string} min_meal_date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsOperationsReportsGetSubscriptionDetails(min_meal_date: string, options?: any): AxiosPromise<string> {
            return localVarFp.appApiReportsOperationsReportsGetSubscriptionDetails(min_meal_date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve subscription data
         * @param {string} min_expiration_date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsOperationsReportsGetSubscriptions(min_expiration_date: string, options?: any): AxiosPromise<string> {
            return localVarFp.appApiReportsOperationsReportsGetSubscriptions(min_expiration_date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get quality assurance data
         * @param {number} min_meal_id 
         * @param {number} max_meal_id 
         * @param {Array<number>} [clinic_ids] array of clinic ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsQualityAssuranceGetQaReportData(min_meal_id: number, max_meal_id: number, clinic_ids?: Array<number>, options?: any): AxiosPromise<string> {
            return localVarFp.appApiReportsQualityAssuranceGetQaReportData(min_meal_id, max_meal_id, clinic_ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get quality assurance data
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {Array<number>} [clinic_ids] array of clinic ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsQualityAssuranceGetQaReportDataFromDates(date_since: string, date_until: string, clinic_ids?: Array<number>, options?: any): AxiosPromise<string> {
            return localVarFp.appApiReportsQualityAssuranceGetQaReportDataFromDates(date_since, date_until, clinic_ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patient Report metrics to summarize reports
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {Array<number>} patient_ids 232,323,643
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReportsReportMetricsGetReportMetricsData(date_since: string, date_until: string, patient_ids: Array<number>, options?: any): AxiosPromise<string> {
            return localVarFp.appApiReportsReportMetricsGetReportMetricsData(date_since, date_until, patient_ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get summary of patient nutrient intake, aggregated by date
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionReportGetPatientAggregatedDailyNutrientSummary(patient_id: number, date_since: string, date_until: string, options?: any): AxiosPromise<void> {
            return localVarFp.appApiSubscriptionReportGetPatientAggregatedDailyNutrientSummary(patient_id, date_since, date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current email admin report
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionReportGetPatientDailyMealItemIssueData(patient_id: number, date_since: string, date_until: string, options?: any): AxiosPromise<void> {
            return localVarFp.appApiSubscriptionReportGetPatientDailyMealItemIssueData(patient_id, date_since, date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user information by user_id. Do NOT use this api to fetch current user, use /users/current instead.
         * @param {number} user_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserGetUser(user_id: number, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.appApiUserGetUser(user_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivate a user\'s account
         * @param {number} user_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserPostAdminDeactivateUser(user_id: number, options?: any): AxiosPromise<void> {
            return localVarFp.appApiUserPostAdminDeactivateUser(user_id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiFoodFoodSearchPostSpellCheck operation in AdminApi.
 * @export
 * @interface AdminApiAppApiFoodFoodSearchPostSpellCheckRequest
 */
export interface AdminApiAppApiFoodFoodSearchPostSpellCheckRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiFoodFoodSearchPostSpellCheck
     */
    readonly food_name: string
}

/**
 * Request parameters for appApiFoodResponseCacheBuildFoodResponseCache operation in AdminApi.
 * @export
 * @interface AdminApiAppApiFoodResponseCacheBuildFoodResponseCacheRequest
 */
export interface AdminApiAppApiFoodResponseCacheBuildFoodResponseCacheRequest {
    /**
     * include USDA list in refresh
     * @type {boolean}
     * @memberof AdminApiAppApiFoodResponseCacheBuildFoodResponseCache
     */
    readonly build_usda_table?: boolean

    /**
     * multi-threaded approach
     * @type {boolean}
     * @memberof AdminApiAppApiFoodResponseCacheBuildFoodResponseCache
     */
    readonly threaded?: boolean
}

/**
 * Request parameters for appApiPatientPostEmailRegisteredNonLoggingPatients operation in AdminApi.
 * @export
 * @interface AdminApiAppApiPatientPostEmailRegisteredNonLoggingPatientsRequest
 */
export interface AdminApiAppApiPatientPostEmailRegisteredNonLoggingPatientsRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiPatientPostEmailRegisteredNonLoggingPatients
     */
    readonly reference_date: string

    /**
     * 
     * @type {number}
     * @memberof AdminApiAppApiPatientPostEmailRegisteredNonLoggingPatients
     */
    readonly days_since_registration: number

    /**
     * 
     * @type {boolean}
     * @memberof AdminApiAppApiPatientPostEmailRegisteredNonLoggingPatients
     */
    readonly with_valid_phone_number?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AdminApiAppApiPatientPostEmailRegisteredNonLoggingPatients
     */
    readonly test_mode?: boolean
}

/**
 * Request parameters for appApiReportingGetAfternoonDieteticStatsEmail operation in AdminApi.
 * @export
 * @interface AdminApiAppApiReportingGetAfternoonDieteticStatsEmailRequest
 */
export interface AdminApiAppApiReportingGetAfternoonDieteticStatsEmailRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminApiAppApiReportingGetAfternoonDieteticStatsEmail
     */
    readonly user_id: number

    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiReportingGetAfternoonDieteticStatsEmail
     */
    readonly reference_datetime?: string
}

/**
 * Request parameters for appApiReportingGetArchivedMealItemReport operation in AdminApi.
 * @export
 * @interface AdminApiAppApiReportingGetArchivedMealItemReportRequest
 */
export interface AdminApiAppApiReportingGetArchivedMealItemReportRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminApiAppApiReportingGetArchivedMealItemReport
     */
    readonly user_id: number

    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiReportingGetArchivedMealItemReport
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiReportingGetArchivedMealItemReport
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiReportingGetCustomItems operation in AdminApi.
 * @export
 * @interface AdminApiAppApiReportingGetCustomItemsRequest
 */
export interface AdminApiAppApiReportingGetCustomItemsRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiReportingGetCustomItems
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiReportingGetCustomItems
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiReportingGetCustomItemsByName operation in AdminApi.
 * @export
 * @interface AdminApiAppApiReportingGetCustomItemsByNameRequest
 */
export interface AdminApiAppApiReportingGetCustomItemsByNameRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiReportingGetCustomItemsByName
     */
    readonly food_name?: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiReportingGetCustomItemsByName
     */
    readonly date_since?: string
}

/**
 * Request parameters for appApiReportingGetDailyAdminStatsEmail operation in AdminApi.
 * @export
 * @interface AdminApiAppApiReportingGetDailyAdminStatsEmailRequest
 */
export interface AdminApiAppApiReportingGetDailyAdminStatsEmailRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminApiAppApiReportingGetDailyAdminStatsEmail
     */
    readonly user_id: number

    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiReportingGetDailyAdminStatsEmail
     */
    readonly reference_date?: string
}

/**
 * Request parameters for appApiReportingGetMissingFoodPhotoReport operation in AdminApi.
 * @export
 * @interface AdminApiAppApiReportingGetMissingFoodPhotoReportRequest
 */
export interface AdminApiAppApiReportingGetMissingFoodPhotoReportRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminApiAppApiReportingGetMissingFoodPhotoReport
     */
    readonly user_id: number

    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiReportingGetMissingFoodPhotoReport
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiReportingGetMissingFoodPhotoReport
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiReportsFoodGetUpdatedFoodReport operation in AdminApi.
 * @export
 * @interface AdminApiAppApiReportsFoodGetUpdatedFoodReportRequest
 */
export interface AdminApiAppApiReportsFoodGetUpdatedFoodReportRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiReportsFoodGetUpdatedFoodReport
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiReportsFoodGetUpdatedFoodReport
     */
    readonly date_until: string

    /**
     * 
     * @type {boolean}
     * @memberof AdminApiAppApiReportsFoodGetUpdatedFoodReport
     */
    readonly send_results_as_attachment: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AdminApiAppApiReportsFoodGetUpdatedFoodReport
     */
    readonly send_to_me_only?: boolean
}

/**
 * Request parameters for appApiReportsLabelingGetLabelingReportData operation in AdminApi.
 * @export
 * @interface AdminApiAppApiReportsLabelingGetLabelingReportDataRequest
 */
export interface AdminApiAppApiReportsLabelingGetLabelingReportDataRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiReportsLabelingGetLabelingReportData
     */
    readonly reference_date: string

    /**
     * 
     * @type {number}
     * @memberof AdminApiAppApiReportsLabelingGetLabelingReportData
     */
    readonly threshold_min: number
}

/**
 * Request parameters for appApiReportsOperationsReportsGetDailySheets operation in AdminApi.
 * @export
 * @interface AdminApiAppApiReportsOperationsReportsGetDailySheetsRequest
 */
export interface AdminApiAppApiReportsOperationsReportsGetDailySheetsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof AdminApiAppApiReportsOperationsReportsGetDailySheets
     */
    readonly clinic_ids?: Array<number>
}

/**
 * Request parameters for appApiReportsOperationsReportsGetFoodLogs operation in AdminApi.
 * @export
 * @interface AdminApiAppApiReportsOperationsReportsGetFoodLogsRequest
 */
export interface AdminApiAppApiReportsOperationsReportsGetFoodLogsRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiReportsOperationsReportsGetFoodLogs
     */
    readonly min_created_date: string

    /**
     * 
     * @type {number}
     * @memberof AdminApiAppApiReportsOperationsReportsGetFoodLogs
     */
    readonly min_meal_id: number
}

/**
 * Request parameters for appApiReportsOperationsReportsGetSubscriptionDetails operation in AdminApi.
 * @export
 * @interface AdminApiAppApiReportsOperationsReportsGetSubscriptionDetailsRequest
 */
export interface AdminApiAppApiReportsOperationsReportsGetSubscriptionDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiReportsOperationsReportsGetSubscriptionDetails
     */
    readonly min_meal_date: string
}

/**
 * Request parameters for appApiReportsOperationsReportsGetSubscriptions operation in AdminApi.
 * @export
 * @interface AdminApiAppApiReportsOperationsReportsGetSubscriptionsRequest
 */
export interface AdminApiAppApiReportsOperationsReportsGetSubscriptionsRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiReportsOperationsReportsGetSubscriptions
     */
    readonly min_expiration_date: string
}

/**
 * Request parameters for appApiReportsQualityAssuranceGetQaReportData operation in AdminApi.
 * @export
 * @interface AdminApiAppApiReportsQualityAssuranceGetQaReportDataRequest
 */
export interface AdminApiAppApiReportsQualityAssuranceGetQaReportDataRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminApiAppApiReportsQualityAssuranceGetQaReportData
     */
    readonly min_meal_id: number

    /**
     * 
     * @type {number}
     * @memberof AdminApiAppApiReportsQualityAssuranceGetQaReportData
     */
    readonly max_meal_id: number

    /**
     * array of clinic ids
     * @type {Array<number>}
     * @memberof AdminApiAppApiReportsQualityAssuranceGetQaReportData
     */
    readonly clinic_ids?: Array<number>
}

/**
 * Request parameters for appApiReportsQualityAssuranceGetQaReportDataFromDates operation in AdminApi.
 * @export
 * @interface AdminApiAppApiReportsQualityAssuranceGetQaReportDataFromDatesRequest
 */
export interface AdminApiAppApiReportsQualityAssuranceGetQaReportDataFromDatesRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiReportsQualityAssuranceGetQaReportDataFromDates
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiReportsQualityAssuranceGetQaReportDataFromDates
     */
    readonly date_until: string

    /**
     * array of clinic ids
     * @type {Array<number>}
     * @memberof AdminApiAppApiReportsQualityAssuranceGetQaReportDataFromDates
     */
    readonly clinic_ids?: Array<number>
}

/**
 * Request parameters for appApiReportsReportMetricsGetReportMetricsData operation in AdminApi.
 * @export
 * @interface AdminApiAppApiReportsReportMetricsGetReportMetricsDataRequest
 */
export interface AdminApiAppApiReportsReportMetricsGetReportMetricsDataRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiReportsReportMetricsGetReportMetricsData
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiReportsReportMetricsGetReportMetricsData
     */
    readonly date_until: string

    /**
     * 232,323,643
     * @type {Array<number>}
     * @memberof AdminApiAppApiReportsReportMetricsGetReportMetricsData
     */
    readonly patient_ids: Array<number>
}

/**
 * Request parameters for appApiSubscriptionReportGetPatientAggregatedDailyNutrientSummary operation in AdminApi.
 * @export
 * @interface AdminApiAppApiSubscriptionReportGetPatientAggregatedDailyNutrientSummaryRequest
 */
export interface AdminApiAppApiSubscriptionReportGetPatientAggregatedDailyNutrientSummaryRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminApiAppApiSubscriptionReportGetPatientAggregatedDailyNutrientSummary
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiSubscriptionReportGetPatientAggregatedDailyNutrientSummary
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiSubscriptionReportGetPatientAggregatedDailyNutrientSummary
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiSubscriptionReportGetPatientDailyMealItemIssueData operation in AdminApi.
 * @export
 * @interface AdminApiAppApiSubscriptionReportGetPatientDailyMealItemIssueDataRequest
 */
export interface AdminApiAppApiSubscriptionReportGetPatientDailyMealItemIssueDataRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminApiAppApiSubscriptionReportGetPatientDailyMealItemIssueData
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiSubscriptionReportGetPatientDailyMealItemIssueData
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiAppApiSubscriptionReportGetPatientDailyMealItemIssueData
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiUserGetUser operation in AdminApi.
 * @export
 * @interface AdminApiAppApiUserGetUserRequest
 */
export interface AdminApiAppApiUserGetUserRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminApiAppApiUserGetUser
     */
    readonly user_id: number
}

/**
 * Request parameters for appApiUserPostAdminDeactivateUser operation in AdminApi.
 * @export
 * @interface AdminApiAppApiUserPostAdminDeactivateUserRequest
 */
export interface AdminApiAppApiUserPostAdminDeactivateUserRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminApiAppApiUserPostAdminDeactivateUser
     */
    readonly user_id: number
}

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @summary run validation of food items for review
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiDataReviewerValidateFoodDatabase(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiDataReviewerValidateFoodDatabase(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Spell check a food item
     * @param {AdminApiAppApiFoodFoodSearchPostSpellCheckRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiFoodFoodSearchPostSpellCheck(requestParameters: AdminApiAppApiFoodFoodSearchPostSpellCheckRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiFoodFoodSearchPostSpellCheck(requestParameters.food_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Rebuild the FoodResponse Cache. Runs as a background process.
     * @param {AdminApiAppApiFoodResponseCacheBuildFoodResponseCacheRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiFoodResponseCacheBuildFoodResponseCache(requestParameters: AdminApiAppApiFoodResponseCacheBuildFoodResponseCacheRequest = {}, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiFoodResponseCacheBuildFoodResponseCache(requestParameters.build_usda_table, requestParameters.threaded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a reminder email to patients who are registered but not active
     * @param {AdminApiAppApiPatientPostEmailRegisteredNonLoggingPatientsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiPatientPostEmailRegisteredNonLoggingPatients(requestParameters: AdminApiAppApiPatientPostEmailRegisteredNonLoggingPatientsRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiPatientPostEmailRegisteredNonLoggingPatients(requestParameters.reference_date, requestParameters.days_since_registration, requestParameters.with_valid_phone_number, requestParameters.test_mode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current email admin report
     * @param {AdminApiAppApiReportingGetAfternoonDieteticStatsEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiReportingGetAfternoonDieteticStatsEmail(requestParameters: AdminApiAppApiReportingGetAfternoonDieteticStatsEmailRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiReportingGetAfternoonDieteticStatsEmail(requestParameters.user_id, requestParameters.reference_datetime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current archived email report
     * @param {AdminApiAppApiReportingGetArchivedMealItemReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiReportingGetArchivedMealItemReport(requestParameters: AdminApiAppApiReportingGetArchivedMealItemReportRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiReportingGetArchivedMealItemReport(requestParameters.user_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all custom items between two dates
     * @param {AdminApiAppApiReportingGetCustomItemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiReportingGetCustomItems(requestParameters: AdminApiAppApiReportingGetCustomItemsRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiReportingGetCustomItems(requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all custom items, grouped by name
     * @param {AdminApiAppApiReportingGetCustomItemsByNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiReportingGetCustomItemsByName(requestParameters: AdminApiAppApiReportingGetCustomItemsByNameRequest = {}, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiReportingGetCustomItemsByName(requestParameters.food_name, requestParameters.date_since, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all custom items tied specifically to an MPQ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiReportingGetCustomItemsMpq(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiReportingGetCustomItemsMpq(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current email admin report
     * @param {AdminApiAppApiReportingGetDailyAdminStatsEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiReportingGetDailyAdminStatsEmail(requestParameters: AdminApiAppApiReportingGetDailyAdminStatsEmailRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiReportingGetDailyAdminStatsEmail(requestParameters.user_id, requestParameters.reference_date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get missing food photos
     * @param {AdminApiAppApiReportingGetMissingFoodPhotoReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiReportingGetMissingFoodPhotoReport(requestParameters: AdminApiAppApiReportingGetMissingFoodPhotoReportRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiReportingGetMissingFoodPhotoReport(requestParameters.user_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get food items updated between two dates
     * @param {AdminApiAppApiReportsFoodGetUpdatedFoodReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiReportsFoodGetUpdatedFoodReport(requestParameters: AdminApiAppApiReportsFoodGetUpdatedFoodReportRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiReportsFoodGetUpdatedFoodReport(requestParameters.date_since, requestParameters.date_until, requestParameters.send_results_as_attachment, requestParameters.send_to_me_only, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get quality assurance data
     * @param {AdminApiAppApiReportsLabelingGetLabelingReportDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiReportsLabelingGetLabelingReportData(requestParameters: AdminApiAppApiReportsLabelingGetLabelingReportDataRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiReportsLabelingGetLabelingReportData(requestParameters.reference_date, requestParameters.threshold_min, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve food log report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiReportsOperationsReportsGetAppointments(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiReportsOperationsReportsGetAppointments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve daily sheets report
     * @param {AdminApiAppApiReportsOperationsReportsGetDailySheetsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiReportsOperationsReportsGetDailySheets(requestParameters: AdminApiAppApiReportsOperationsReportsGetDailySheetsRequest = {}, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiReportsOperationsReportsGetDailySheets(requestParameters.clinic_ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve food log report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiReportsOperationsReportsGetEmailHistories(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiReportsOperationsReportsGetEmailHistories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve food log report
     * @param {AdminApiAppApiReportsOperationsReportsGetFoodLogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiReportsOperationsReportsGetFoodLogs(requestParameters: AdminApiAppApiReportsOperationsReportsGetFoodLogsRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiReportsOperationsReportsGetFoodLogs(requestParameters.min_created_date, requestParameters.min_meal_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve detailed subscription data with additional metrics
     * @param {AdminApiAppApiReportsOperationsReportsGetSubscriptionDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiReportsOperationsReportsGetSubscriptionDetails(requestParameters: AdminApiAppApiReportsOperationsReportsGetSubscriptionDetailsRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiReportsOperationsReportsGetSubscriptionDetails(requestParameters.min_meal_date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve subscription data
     * @param {AdminApiAppApiReportsOperationsReportsGetSubscriptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiReportsOperationsReportsGetSubscriptions(requestParameters: AdminApiAppApiReportsOperationsReportsGetSubscriptionsRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiReportsOperationsReportsGetSubscriptions(requestParameters.min_expiration_date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get quality assurance data
     * @param {AdminApiAppApiReportsQualityAssuranceGetQaReportDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiReportsQualityAssuranceGetQaReportData(requestParameters: AdminApiAppApiReportsQualityAssuranceGetQaReportDataRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiReportsQualityAssuranceGetQaReportData(requestParameters.min_meal_id, requestParameters.max_meal_id, requestParameters.clinic_ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get quality assurance data
     * @param {AdminApiAppApiReportsQualityAssuranceGetQaReportDataFromDatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiReportsQualityAssuranceGetQaReportDataFromDates(requestParameters: AdminApiAppApiReportsQualityAssuranceGetQaReportDataFromDatesRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiReportsQualityAssuranceGetQaReportDataFromDates(requestParameters.date_since, requestParameters.date_until, requestParameters.clinic_ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patient Report metrics to summarize reports
     * @param {AdminApiAppApiReportsReportMetricsGetReportMetricsDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiReportsReportMetricsGetReportMetricsData(requestParameters: AdminApiAppApiReportsReportMetricsGetReportMetricsDataRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiReportsReportMetricsGetReportMetricsData(requestParameters.date_since, requestParameters.date_until, requestParameters.patient_ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get summary of patient nutrient intake, aggregated by date
     * @param {AdminApiAppApiSubscriptionReportGetPatientAggregatedDailyNutrientSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiSubscriptionReportGetPatientAggregatedDailyNutrientSummary(requestParameters: AdminApiAppApiSubscriptionReportGetPatientAggregatedDailyNutrientSummaryRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiSubscriptionReportGetPatientAggregatedDailyNutrientSummary(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current email admin report
     * @param {AdminApiAppApiSubscriptionReportGetPatientDailyMealItemIssueDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiSubscriptionReportGetPatientDailyMealItemIssueData(requestParameters: AdminApiAppApiSubscriptionReportGetPatientDailyMealItemIssueDataRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiSubscriptionReportGetPatientDailyMealItemIssueData(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user information by user_id. Do NOT use this api to fetch current user, use /users/current instead.
     * @param {AdminApiAppApiUserGetUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiUserGetUser(requestParameters: AdminApiAppApiUserGetUserRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiUserGetUser(requestParameters.user_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivate a user\'s account
     * @param {AdminApiAppApiUserPostAdminDeactivateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public appApiUserPostAdminDeactivateUser(requestParameters: AdminApiAppApiUserPostAdminDeactivateUserRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).appApiUserPostAdminDeactivateUser(requestParameters.user_id, options).then((request) => request(this.axios, this.basePath));
    }
}
