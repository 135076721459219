import * as React from 'react';

export function useUpdatingState<T>(
  value: T,
  nullDefault: NonNullable<T>,
  useEffectChangeList?: any[],
): [NonNullable<T>, (newValue: T) => void] {
  const [state, setState] = React.useState<T>(value);
  React.useEffect(() => {
    setState(value ?? nullDefault);
  }, [value, ...(useEffectChangeList || [])]);

  return [state ?? nullDefault, setState];
}
