import { FocusEvent, FocusEventHandler, useState } from 'react';

export default <TValue>(
  value: TValue,
  forceDirty = false,
  ...validations: ((value: TValue) => string | null)[]
) => {
  const [dirty, setDirty] = useState(false);

  const showError = forceDirty || dirty;
  const error = showError
    ? validations.reduce<string | null>((prev, fn) => prev ?? fn(value), null)
    : null;

  return {
    blurred: dirty,
    error: error ?? undefined,
    markDirty: () => setDirty(true),
    onBlur: <TElement>(
      handler?: FocusEventHandler<TElement>,
    ): FocusEventHandler<TElement> =>
    (evt: FocusEvent<TElement>) => {
      setDirty(true);
      if (handler) {
        handler(evt);
      }
    },
    showError: error !== null,
  };
};
