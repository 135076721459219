import { useMutation, useQueryClient } from '@tanstack/react-query';
import apiClient from '../api-client';

export const apiRenameFood = async (opts: {
  oldFoodName: string,
  newFoodName: string,
}): Promise<void> => {
  await apiClient.renameFood(opts.oldFoodName, opts.newFoodName);
};

const useRenameFoodMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: apiRenameFood,
    onSuccess: () => {
      // queryClient.setQueryData([QueryKey.Food, foodResponse.term], foodResponse)
    },
  });
};

export default useRenameFoodMutation;
