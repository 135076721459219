import styled from '@emotion/styled';
import EdiText from 'react-editext';

export const StyledTextBox = styled(EdiText)`
button {
  width: 40px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

button[editext="save-button"] {
  width: 40px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: green;
    color: white;
  }
}

button[editext="cancel-button"] {
  width: 40px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: crimson;
    color: white;
  }
}

input {
  background: white;
  color: black;
  fontWeight: 200,
  border-radius: 5px;
  padding: 0px 5px 0px 5px;
  width: 23rem;
}

textarea {
  background: white;
  color: black;
  fontWeight: 200,
  border-radius: 5px;
  padding: 0px 5px 0px 5px;
  width: 23rem;
}

div[editext="view-container"], div[editext="edit-container"] {
  background: white;
  padding: 5px 5px 5px 15px;
  border-radius: 5px;
  color: black;
  width: 23rem;
  border: 1px solid #c7ced4;
}
`;

StyledTextBox.displayName = 'StyledTextBox';