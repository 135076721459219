import { Close } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ZoomablePhoto } from 'pages/QueueItem/meal-builder/MealPhoto';
import { create } from 'zustand';

type PopoverImageStore = {
  src: string | null | undefined,
  show: (opts: { src: string | null | undefined }) => void,
};

export const usePopoverImage = create<PopoverImageStore>((set) => ({
  src: null,
  show: (opts) => set({ src: opts.src }),
}));

export const PopoverImageViewer = () => {
  const { src, show } = usePopoverImage();

  if (!src) {
    return null;
  }
  return <PopoverImage src={src} onClose={() => show({ src: null })} />;
};

const PopoverImage = (props: {
  src: string | null | undefined,
  onClose: () => void,
}) => {
  return (
    <Stack
      sx={{
        width: '100%',
        height: 'auto',
        maxWidth: '28vw',
        maxHeight: 'calc(100% - 70px)',
        position: 'fixed',
        zIndex: 1000000,
        left: 0,
        bottom: 0,
      }}
    >
      <ZoomablePhoto
        src={props.src}
        styles={{
          background: 'none',
          border: 'none',
          overflow: 'scroll',
        }}
      />
      <IconButton
        sx={{ position: 'absolute', right: 0, top: 0 }}
        onClick={() => props.onClose()}
      >
        <div style={{ backgroundColor: grey[200] }}>
          <Close />
        </div>
      </IconButton>
    </Stack>
  );
};
