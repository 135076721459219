import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';

import { dataReviewApi } from 'api';
import { MealPhotoQueueResponse } from 'api/generated/MNT';
import { useAuth } from 'context/appContext';
import { useInterval } from 'hooks/useInterval';
import mixpanel from 'mixpanel-browser';
import { useSocketIOClient } from 'socketio/SocketIOService';
import { useDocumentVisibility } from 'utils';
import { mkRandId } from 'utils/telemetry';

const filterRecentlyLabelled = (
  recentlyLabelledQueueIDs: number[],
  queues: MealPhotoQueueResponse[],
) => {
  return queues.filter(q => !recentlyLabelledQueueIDs.includes(q.id));
};

const globalRefetchThrottle = {
  call: (func: () => void) => {
    globalRefetchThrottle._lastFunc = func;
    globalRefetchThrottle._run();
  },
  _lastFunc: null as (() => void) | null,
  _run: _.throttle(() => {
    globalRefetchThrottle._lastFunc?.();
  }, 500),
};

const useAvailableMqpsQuery = () => {
  const { authInfo } = useAuth();

  const lastRefetchReasonRef = React.useRef<string | null>(null);
  const isDocumentVisible = useDocumentVisibility();
  const query = useQuery(['available-mpqs', authInfo?.reviewer_id], async () => {
    if (!authInfo) {
      return {
        queues: [],
        ownedQueueCount: 0,
        ownedQueues: [],
        availableQueueCount: 0,
      };
    }

    const refetchId = mkRandId(16);
    const reason = lastRefetchReasonRef.current;
    const startTime = Date.now();
    const res = await dataReviewApi.appApiDataReviewerGetDataReviewerMealPhotoQueuesToBeProcessed({
      data_reviewer_id: authInfo.reviewer_id,
    }, {
      params: {
        'refetch-reason': reason,
        'refetch-id': refetchId,
      },
    });
    const activeMpqs = res.data;
    const ownedQueues = activeMpqs.filter(i => i.first_reviewer_user_id === authInfo.reviewer_id);
    const availableQueues = activeMpqs.filter(i => !i.first_reviewer_user_id);

    mixpanel.track('Active MPQ Refetch', {
      Count: activeMpqs.length,
      OwnedCount: ownedQueues.length,
      AvailableCount: availableQueues.length,
      'Duration (s)': (Date.now() - startTime) / 1000,
      Reason: reason,
      'Refetch ID': refetchId,
    });

    return {
      queues: activeMpqs || [],
      ownedQueueCount: ownedQueues.length,
      ownedQueues: ownedQueues,
      availableQueueCount: availableQueues.length,
    };
  }, {
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return {
    query,
    refetch: (reason: string) => {
      // If tab is not active and it's been less than 15s since the last refetch, skip refetch.
      if (!isDocumentVisible) {
        const lastRefetch = localStorage.getItem('mpq-refetch:last-refetch');
        const lastRefetchTimestamp = lastRefetch ? +lastRefetch : null;
        if (lastRefetchTimestamp && Date.now() - lastRefetchTimestamp < 15 * 1000) {
          return;
        }
      }
      localStorage.setItem('mpq-refetch:last-refetch', Date.now().toString());
      globalRefetchThrottle.call(() => {
        lastRefetchReasonRef.current = reason;
        query.refetch();
      });
    },
  };
};

export const useActiveQueuePeriodRefetch = () => {
  const io = useSocketIOClient();
  const { refetch } = useAvailableMqpsQuery();
  const refetchInterval = io?.connected ? 16 * 1000 : 6 * 1000;
  useInterval(() => {
    refetch('interval:' + String(refetchInterval / 1000));
  }, refetchInterval);
};

export const useMealQueueService = () => {
  const { authInfo } = useAuth();
  const routerLocation = useLocation();
  const [recentlyLabelledQueueIDs, setRecentlyLabelledQueueIDs] = useState<number[]>([]);

  const { query, refetch } = useAvailableMqpsQuery();
  useEffect(() => {
    refetch('initial');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getIsCurrentlyLabellingOwnQueue = React.useCallback((location: { pathname: string }) => {
    return !!query.data?.queues.some(q => (
      q.first_reviewer_user_id == authInfo?.reviewer_id
      && (location.pathname == `/queue-item/${q.id}`)
    ));
  }, [query, authInfo]);

  return React.useMemo(() => ({
    query,
    allActiveQueues: filterRecentlyLabelled(recentlyLabelledQueueIDs, query.data?.queues || []),
    ownedCount: query.data?.ownedQueueCount || 0,
    ownedQueues: filterRecentlyLabelled(recentlyLabelledQueueIDs, query.data?.ownedQueues || []),
    availableCount: query.data?.availableQueueCount || 0,
    currentlyLabellingOwnQueue: getIsCurrentlyLabellingOwnQueue(routerLocation),
    addRecentlyLabelledQueueId: (queueId: number) => {
      setRecentlyLabelledQueueIDs([queueId, ...recentlyLabelledQueueIDs].slice(0, 5));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [query, getIsCurrentlyLabellingOwnQueue, routerLocation, recentlyLabelledQueueIDs]);
};
