import { useEffect, useState } from 'react';

export const usePromptBeforePageUnload = (opts: {
  show: boolean,
  // onUnload?: (e: Event) => void,
}) => {
  const { show } = opts;

  useEffect(() => {
    const showPrompt = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      window.confirm();
    };
    if (show) {
      window.addEventListener('beforeunload', showPrompt);
      // if (props.onUnload) {
      //   window.addEventListener('unload', props.onUnload);
      // }
    }
    return () => {
      window.removeEventListener('beforeunload', showPrompt);
      // if (props.onUnload) {
      //   window.removeEventListener('unload', props.onUnload);
      // }
    };
  }, [show]);
};
