import { ReviewerAuthInfo } from 'apiClients/auth';
import { AuthRoles as AuthLevels, ReviewAuthRoles, UserOrigin, UserRoles } from 'authRoles';
import { config } from 'config';
import { AppContext, useAuth } from 'context/appContext';
import _ from 'lodash';
import { useEffect } from 'react';

type EnumDictionary<T extends string | symbol | number, U> = {
  [K in T]: U;
};

type PartialAppContext = Exclude<AppContext, 'hasAuth'>;

export type CapabilityOverrides = Array<{
  capability: Capabilities,
  enabled: boolean,
}>;

export type UserAuthState = {
  user: ReviewerAuthInfo,
  roles: UserRoles,
  overrides: CapabilityOverrides | null,
};

type CapabilityCheckFunc = (authState: UserAuthState) => boolean;

export type Capability = {
  label: Capabilities,
  help: string,
  checkAll: CapabilityCheckFunc[],
};

const checks = {
  isDataReviewerAdmin: ({ roles }) => {
    return roles.dataReviewerAuthLevel >= AuthLevels.admin;
  },
  isAuditorReviewer: ({ roles }) => {
    return roles.reviewAuthRole == ReviewAuthRoles.reviewer;
  },
  isAuditorReviewerOrAnalyst: ({ roles }) => {
    return (
      roles.reviewAuthRole == ReviewAuthRoles.reviewer
      || roles.reviewAuthRole == ReviewAuthRoles.analyst
    );
  },
  isAuditorReportReviewer: ({ roles }) => {
    return roles.reviewAuthRole == ReviewAuthRoles.report_reviewer;
  },
  isMealAdmin: ({ roles }) => {
    return roles.mealAuthLevel >= AuthLevels.admin;
  },
  isHospitalAdmin: ({ roles }) => {
    return roles.hospitalAuthLevel >= AuthLevels.admin;
  },
  isReportAdmin: ({ roles }) => {
    return roles.reportAuthLevel >= AuthLevels.admin;
  },
  isReportPreviewer: ({ roles }) => {
    return roles.reportAuthLevel >= AuthLevels.viewer;
  },
  isInternal: ({ roles }) => {
    return roles.userOrigin == UserOrigin.internal;
  },
  isGlobalAdmin: ({ user }) => {
    return !!user.is_admin;
  },
  everyone: () => true,
} satisfies Record<string, (authState: UserAuthState) => boolean>;

export enum Capabilities {
  overrideCapabilities = 'capabilities:override',

  mpqItemView = 'mpq.item:view',
  mpqItemEdit = 'mpq.item:edit',
  mpqItemLabel = 'mpq.item:label',

  mpqQAView = 'mpq.QA:view',

  mpqChangeLogsFullAttributionView = 'mpq.change_logs.full_attribution:view',
  mpqChangeLogsPartAttributionView = 'mpq.change_logs.part_attribution:view',

  foodDbView = 'foodDb:view',
  foodDbAdmin = 'foodDb:admin',

  patientReportView = 'patientReport:view',
  patientReportEdit = 'patientReport:edit',

  commonDataReviewerAdmin = 'common:DataReviewerAdmin',
  trainingLogReplay = 'training:logReplay',
  commonInternal = 'common:Internal',
}

const capabilities: EnumDictionary<Capabilities, Capability> = {
  [Capabilities.overrideCapabilities]: {
    label: Capabilities.overrideCapabilities,
    help: 'Allows the user to edit and override which capabilities they have authorization for',
    checkAll: [
      checks.isDataReviewerAdmin,
      checks.isInternal,
    ],
  },

  [Capabilities.mpqItemView]: {
    label: Capabilities.mpqItemView,
    help: 'Allows the user to view queue items on the queue item page',
    checkAll: [
      checks.isAuditorReviewerOrAnalyst,
      checks.isInternal,
    ],
  },

  [Capabilities.mpqItemEdit]: {
    label: Capabilities.mpqItemEdit,
    help: 'Allows the user to edit queue items on the queue item page',
    checkAll: [
      checks.isAuditorReviewerOrAnalyst,
      checks.isInternal,
    ],
  },

  [Capabilities.mpqItemLabel]: {
    label: Capabilities.mpqItemLabel,
    help: 'Allows the user to label new queues',
    checkAll: [
      checks.isAuditorReviewerOrAnalyst,
      checks.isInternal,
    ],
  },

  [Capabilities.mpqQAView]: {
    label: Capabilities.mpqQAView,
    help: 'Allows the user to view QA logs, QA form and QoS details on the queue item page',
    checkAll: [
      checks.isAuditorReviewerOrAnalyst,
      checks.isInternal,
    ],
  },

  [Capabilities.mpqChangeLogsFullAttributionView]: {
    label: Capabilities.mpqChangeLogsFullAttributionView,
    help:
      'Allows the user to view all the details of changes made to a queue, including change logs and queue attribution badge',
    checkAll: [
      checks.isDataReviewerAdmin,
      checks.isInternal,
    ],
  },

  [Capabilities.mpqChangeLogsPartAttributionView]: {
    label: Capabilities.mpqChangeLogsPartAttributionView,
    help: 'Allows the user to view a partial user attribution for changes in meal change logs',
    checkAll: [
      checks.isAuditorReviewerOrAnalyst,
      checks.isInternal,
    ],
  },

  [Capabilities.foodDbView]: {
    label: Capabilities.foodDbView,
    help: 'Allows the user to view food database items',
    checkAll: [
      checks.isInternal,
    ],
  },

  [Capabilities.foodDbAdmin]: {
    label: Capabilities.foodDbAdmin,
    help: 'Full administrative access to the food editor',
    checkAll: [
      checks.isGlobalAdmin,
    ],
  },

  [Capabilities.trainingLogReplay]: {
    label: Capabilities.trainingLogReplay,
    help: 'Allows the user to replay training logs',
    checkAll: [
      checks.isDataReviewerAdmin,
      checks.isInternal,
    ],
  },

  [Capabilities.patientReportView]: {
    label: Capabilities.patientReportView,
    help: 'Allows the user to view patient reports',
    checkAll: [
      checks.isReportPreviewer,
      checks.isInternal,
    ],
  },

  [Capabilities.patientReportEdit]: {
    label: Capabilities.patientReportEdit,
    help: 'Allows the user to edit patient reports',
    checkAll: [
      checks.isReportAdmin,
      checks.isInternal,
    ],
  },

  [Capabilities.commonDataReviewerAdmin]: {
    label: Capabilities.commonDataReviewerAdmin,
    help: 'Used in place of is_data_reviewer_admin checks',
    checkAll: [
      checks.isDataReviewerAdmin,
      checks.isInternal,
    ],
  },

  [Capabilities.commonInternal]: {
    label: Capabilities.commonInternal,
    help: 'Used in place of is_internal checks',
    checkAll: [
      checks.isInternal,
    ],
  },
};

export const getAllCapabilities = () => {
  return capabilities;
};

const hasCapability = (opts: {
  userAuth: UserAuthState | null,
  capability: Capabilities,
  ignoreOverrides?: boolean,
}) => {
  const { userAuth, capability, ignoreOverrides } = opts;
  if (!userAuth) {
    return false;
  }

  const override = userAuth.overrides?.find(o => o.capability == capability);
  if (!ignoreOverrides && override) {
    return override.enabled;
  }

  if (userAuth.user.is_admin) {
    return true;
  }

  const cap = capabilities[capability];
  if (!cap) {
    const err = !cap ? `Capability '${capability}' not found` : 'User not found';
    if (config.IS_DEV || config.IS_LOCAL) {
      throw new Error(err);
    }
    console.error(err);
    return false;
  }

  // Temporary, to make sure we don't accidentally break things
  if ((window as any).rxAuthOverride) {
    return true;
  }

  return cap.checkAll.every(check => check(userAuth));
};

const getUserRolesFromAuthInfo = (authInfo: ReviewerAuthInfo): UserRoles => {
  return {
    dataReviewerAuthLevel: authInfo.is_data_reviewer_admin ? AuthLevels.admin : AuthLevels.none,
    mealAuthLevel: authInfo.is_meal_admin ? AuthLevels.admin : AuthLevels.none,
    hospitalAuthLevel: authInfo.is_hospital_admin ? AuthLevels.admin : AuthLevels.none,
    reportAuthLevel: (
      authInfo.is_report_admin || authInfo.is_data_reviewer_admin
        ? AuthLevels.admin
        : authInfo.is_report_previewer
        ? AuthLevels.viewer
        : AuthLevels.none
    ),
    userOrigin: authInfo.is_internal ? UserOrigin.internal : UserOrigin.external,
    reviewAuthRole: authInfo.review_level == 'reviewer'
      ? ReviewAuthRoles.reviewer
      : authInfo.review_level == 'report_reviewer'
      ? ReviewAuthRoles.report_reviewer
      : ReviewAuthRoles.analyst,
  };
};

export const appContextToUserAuthState = (appContext: PartialAppContext): UserAuthState | null => {
  if (!appContext.authInfo) {
    return null;
  }

  return {
    user: appContext.authInfo,
    roles: getUserRolesFromAuthInfo(appContext.authInfo),
    overrides: appContext._authCapabilityOverrides,
  };
};

export type HasAuthFunc = (capability: Capabilities, opts?: { ignoreOverrides?: boolean }) => boolean;

export const getHasAuthFromAppContext = (
  appContext: PartialAppContext,
): HasAuthFunc => {
  const userAuth = appContextToUserAuthState(appContext);
  return (capability, opts) => {
    return hasCapability({
      userAuth,
      capability,
      ignoreOverrides: opts?.ignoreOverrides,
    });
  };
};

export const useCapabilityOverrides = () => {
  const { hasAuth, _authCapabilityOverrides, _setAuthCapabilityOverrides } = useAuth();
  useEffect(() => {
    console.log('Overrides:', _authCapabilityOverrides);
  });
  return {
    overrides: _authCapabilityOverrides,
    clearAllOverrides: () => {
      _setAuthCapabilityOverrides([]);
    },
    setOverrides: (capability: Capabilities, enabled: boolean) => {
      if (enabled == hasAuth(capability, { ignoreOverrides: true })) {
        _setAuthCapabilityOverrides(old => old.filter(cap => cap.capability != capability));
        return;
      }

      _setAuthCapabilityOverrides(old => (
        old.filter(cap => cap.capability != capability)
          .concat({
            capability: capability,
            enabled: enabled,
          })
      ));
    },
  };
};
