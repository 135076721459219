import { useMutation } from '@tanstack/react-query';
import apiClient from '../api-client';
import { FoodEditorFoodRequest } from '../types';

const useUpdateWarnings = () => {
  return useMutation<
    any,
    Error,
    { term: string, foodRequest: FoodEditorFoodRequest }
  >(async ({ term, foodRequest }) => apiClient.applyWarnings(term, foodRequest));
};

export default useUpdateWarnings;
