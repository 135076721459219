import { UNSAFE_FEATURES, useFeatures } from 'context/FeatureContext';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import { useEffect } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { AsyncResult, useAsyncResult } from 'react-use-async-result';

const Notification = window.Notification || {
  permission: 'unavailable',
  requestPermission: async () => 'unavailable',
};

type PermissionType = NotificationPermission | 'unavailable' | 'unknown';

type RxNotificationOptions = NotificationOptions & { onClick?: () => void };

const loadNotificationSound = () => {
  return new Promise<HTMLAudioElement>((resolve, reject) => {
    const audio = new Audio('/sounds/double_ping.mp3');
    audio.volume = 1.0;
    audio.addEventListener('canplaythrough', () => {
      resolve(audio);
    });
    audio.addEventListener('error', (err) => {
      reject(err);
    });
  });
};

let permissionStatus = 'unknown' as PermissionType;
const requestNotificationPermission = () => {
  console.log('Browser notifications: requesting permission...');
  if (!Notification?.requestPermission) {
    console.log('Browser notifications: unavailable');
    permissionStatus = 'unavailable';
    window.dispatchEvent(new Event('notificationPermissionStatusChanged'));
    return;
  }
  Notification.requestPermission().then((permission) => {
    console.log('Browser notifications: permission:', permission);
    permissionStatus = permission;
    window.dispatchEvent(new Event('notificationPermissionStatusChanged'));
  });
};
document.addEventListener('DOMContentLoaded', () => {
  if (Notification.permission !== 'granted') {
    requestNotificationPermission();
  }
});

export const useBrowserNotifications = singletonHook(
  {
    requestPermission: () => {},
    permission: 'unknown' as PermissionType,
    notify: (() => {}) as any as (title: string, options?: RxNotificationOptions) => null,
    lastTitle: null as string | null,
    sound: AsyncResult.empty() as AsyncResult<HTMLAudioElement>,
  },
  () => {
    const [permission, setPermission] = React.useState<PermissionType>(Notification.permission);
    const [lastTitle, setLastTitle] = React.useState<string | null>(null);
    const notificationSound = useAsyncResult(loadNotificationSound);
    useEffect(() => {
      setPermission(permissionStatus);
      const handler = () => setPermission(permissionStatus);
      window.addEventListener('notificationPermissionStatusChanged', handler);
      return () => {
        window.removeEventListener('notificationPermissionStatusChanged', handler);
      };
    }, []);

    useEffect(() => {
      mixpanel.track('Browser notifications permissions', {
        Permission: permission,
      });
    }, [permission]);

    const notify = React.useCallback((title: string, options?: RxNotificationOptions) => {
      if (permission != 'granted') {
        setLastTitle(`Error: no permission`);
        console.error(
          `Browser notifications: permission '${permission}' != 'granted'; not sending:`,
          title,
        );
        return;
      }

      const opts: RxNotificationOptions = {
        silent: true,
        icon: '/favicon.png',
        ...(options || {}),
      };

      const callback = opts.onClick;
      delete opts.onClick;

      try {
        const notif = new Notification(title, opts);
        notif.onclick = () => {
          callback && callback();
        };
        notif.onerror = () => {
          setLastTitle(`Error sending: ${title}`);
          console.error('Browser notifications: error sending:', title);
        };
        setLastTitle(title);
        if (UNSAFE_FEATURES.notification_sound && notificationSound.isDone) {
          notificationSound.result?.play();
        }
        console.log('Browser notifications: sent:', title);
      } catch (e) {
        setLastTitle(`Error: ${e}: ${(e as any)?.message}`);
        console.error('Browser notifications: error sending:', title, e);
      }
    }, [permission, notificationSound]);

    return React.useMemo(() => ({
      permission,
      notify,
      requestPermission: requestNotificationPermission,
      lastTitle,
      sound: notificationSound as AsyncResult<HTMLAudioElement>,
    }), [permission, notify, lastTitle, notificationSound]);
  },
);
