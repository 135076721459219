import { Tooltip } from '@mui/material';
import { NutrientValue } from 'utils/mealItems';

/**
 * Displays the value of a nutrient, with a warning tooltip if there are any warnings.
 *
 * For use with `mealItemGetNutrientValue`.
 *
 * Example::
 *
 *   const ShowNutrientValue = (props: {
 *     item: MealItem | MealItemCustomAddonResponse,
 *     nutrient: keyof UsdaNutritionResponse,
 *   }) => {
 *     const details = useFoodDetails(props.item.food_name);
 *     const value = details.isLoading ? LOADING_NUTRIENT_VALUE : mealItemGetNutrientValue({
 *       item: props.item,
 *       foodNutrients: details.usda_nutrition,
 *       nutrient: props.nutrient,
 *     });
 *
 *     console.log(`The value of ${value.def.label}: is ${value.valueStr} (warnings: ${value.warnings.join(', ')})`);
 *
 *     return <MealItemNutrientValue value={value} />;
 *   };
 */
export const MealItemNutrientValue = (props: {
  value: NutrientValue,
}) => {
  const { value } = props;
  if (!value.warnings.length) {
    return <span>{value.valueStr ?? '–'}</span>;
  }

  return (
    <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{value.warnings.join('\n')}</span>}>
      <span style={{ whiteSpace: 'nowrap' }}>
        {value?.valueStr ?? '–'} ⚠️
      </span>
    </Tooltip>
  );
};
