import { ReactNode, SyntheticEvent, useRef, useState } from 'react';

import { ExperimentOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import {
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  Paper,
  Popper,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useGravatar } from 'use-gravatar';

import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';
import MainCard from 'components/MainCard';
import { useAuth } from 'context/appContext';

import SettingsIcon from '@mui/icons-material/Settings';
import SignalWifi1BarIcon from '@mui/icons-material/SignalWifi1Bar';
import SignalWifi3BarIcon from '@mui/icons-material/SignalWifi3Bar';
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar';
import SignalWifiBadIcon from '@mui/icons-material/SignalWifiBad';
import { TABS_MAPPING } from 'context/FeatureContext';
import { useSocketTelemetryData } from 'socketio/SocketIOService';
import { FeatureFlagTab } from './FeatureFlagTab';
import ProfileTab from './ProfileTab';

interface TabPanelProps {
  children?: ReactNode;
  dir?: string;
  index: number;
  value: number;
}

// tab panel wrapper
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`,
  };
}

const NetworkIcon = () => {
  const networkStatus = useSocketTelemetryData();
  const GOOD_NETWORK = 500;
  const BAD_NETWORK = 2000;

  if (!networkStatus.rttTotal) {
    return <SignalWifiBadIcon sx={{ width: 32, height: 32, color: '#D3D3D3' }} />;
  }

  if (!networkStatus.rttLatest) {
    return networkStatus.rttLatest === null
      ? <SignalWifi4BarIcon sx={{ width: 32, height: 32, color: '#96e9ac' }} />
      : <SignalWifiBadIcon sx={{ width: 32, height: 32 }} color="error" />;
  }

  if (networkStatus.rttLatest <= GOOD_NETWORK) {
    return <SignalWifi4BarIcon sx={{ width: 32, height: 32, color: '#96e9ac' }} />;
  }

  if (networkStatus.rttLatest > BAD_NETWORK) {
    return <SignalWifi1BarIcon sx={{ width: 32, height: 32 }} color="error" />;
  }

  return <SignalWifi3BarIcon sx={{ width: 32, height: 32 }} color="warning" />;
};

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const theme = useTheme();

  const { logout, authInfo } = useAuth();
  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }
  };
  const avatarUrl = useGravatar(authInfo?.email || '', 32);

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter' },
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2,
          },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <NetworkIcon />
          <Typography variant="subtitle1">{authInfo?.email}</Typography>
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 310,
                  minWidth: 240,
                  maxWidth: 310,
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    <CardContent sx={{ px: 2.5, pt: 3 }}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Stack direction="row" spacing={1.25} alignItems="center">
                            <Avatar alt="profile user" src={avatarUrl} sx={{ width: 32, height: 32 }} />
                            <Stack>
                              <Typography variant="h6">{authInfo?.email}</Typography>
                              {authInfo?.review_level && (
                                <Typography variant="body2" color="textSecondary" sx={{ textTransform: 'capitalize' }}>
                                  {authInfo?.review_level}
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item>
                          <Tooltip title="Logout">
                            <IconButton size="large" sx={{ color: 'text.primary' }} onClick={handleLogout}>
                              <LogoutOutlined />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </CardContent>
                    {open && (
                      <>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="profile tabs">
                            <Tab
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textTransform: 'capitalize',
                              }}
                              icon={<UserOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                              label="Profile"
                              {...a11yProps(0)}
                            />
                            <Tab
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textTransform: 'capitalize',
                              }}
                              icon={<SettingsIcon style={{ marginBottom: 0, marginRight: '10px' }} />}
                              label="Settings"
                              {...a11yProps(1)}
                            />
                            <Tab
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textTransform: 'capitalize',
                              }}
                              icon={<ExperimentOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                              label="Debug"
                              {...a11yProps(2)}
                            />
                          </Tabs>
                        </Box>
                        <TabPanel value={value} index={0} dir={theme.direction}>
                          <ProfileTab handleLogout={handleLogout} />
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                          <FeatureFlagTab tab={TABS_MAPPING.settings} />
                        </TabPanel>
                        <TabPanel value={value} index={2} dir={theme.direction}>
                          <FeatureFlagTab tab={TABS_MAPPING.features} />
                        </TabPanel>
                      </>
                    )}
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
