import { faEdit, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import { logTrackedError } from 'errorTracking';
import { formatNumber } from 'food-editor/utils/utils';
import { escape } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { FOOD_ENGINE_MAX_RESULTS } from 'pages/QueueItem/meal-builder/useFoodSearch';
import React, { useContext, useEffect } from 'react';
import { Button, ButtonGroup, Form, InputGroup } from 'react-bootstrap';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import { useAsyncResult } from 'react-use-async-result';
import { ReviewerAuthInfo } from '../apiClients/auth';
import { getFoodByQuery, searchByTextWithContext, SearchItem } from '../apiClients/search';
import { AppCtx } from '../context/appContext';
import { FoodSearchItem, FoodSearchResult } from './food-search-index';
import { useFoodSearchIndex } from './useFoodSearchIndex';

export const FoodSearchTypeahead = (props: {
  value: string,
  onSelect: (item: string) => void,
  defaultOptions?: string[],
  exclude?: string[],
  placeholder: string,
}) => {
  const foodIndex = useFoodSearchIndex();
  const searchIndexRes = useAsyncResult<FoodSearchResult>();
  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const searchInputRef = React.useRef<any>();

  React.useEffect(() => {
    const handler = (e: any) => {
      if (e.target != searchInputRef.current?.inputNode) {
        setOpen(false);
      }
    };
    document.addEventListener('click', handler, false);
    return () => document.removeEventListener('click', handler, false);
  }, []);

  React.useEffect(() => {
    if (!foodIndex.isDone) {
      return;
    }
    const req = foodIndex.result.search('flexi', searchText);
    searchIndexRes.bind(req);
  }, [foodIndex, searchText]);

  React.useEffect(() => {
    if (!props.value) {
      searchInputRef.current?.clear();
    }
  }, [props.value]);

  const dedupe = (xs: { name: string }[]) => {
    const seen = {} as any;
    return xs.filter(x => {
      if (seen[x.name]) {
        return false;
      }
      seen[x.name] = true;
      return true;
    });
  };

  const options = React.useMemo(() => {
    return (
      dedupe(
        (props.defaultOptions || [])
          .filter(name => !searchText || name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0)
          .map(name => ({ name }))
          .concat(searchIndexRes.isDone ? searchIndexRes.result.items : [])
          .filter(item => !(props.exclude || []).includes(item.name))
          .slice(0, FOOD_ENGINE_MAX_RESULTS),
      )
    ).map(item => ({ ...item, id: item.name }));
  }, [props.defaultOptions, props.exclude, searchIndexRes, searchText]);

  return (
    <Typeahead
      id="addon-search-typeahead"
      ref={searchInputRef}
      labelKey="name"
      selected={props.value ? [{ name: props.value }] : []}
      filterBy={['name']}
      onFocus={() => setOpen(true)}
      open={open}
      onChange={s => {
        props.onSelect(s[0] ? (s[0] as FoodSearchItem).name : '');
      }}
      options={options}
      isLoading={searchIndexRes.isPending}
      onInputChange={value => {
        setOpen(true);
        setSearchText(value);
      }}
      onKeyDown={() => setOpen(true)}
      placeholder={props.placeholder}
    />
  );
};

export const FoodSearchInput = (props: {
  patient_id?: number,
  initialSearchText?: string,
  onCancel?: () => void,
  onItemSelect: (item: SearchItem, index: number, source: 'Search' | 'ClientSearch') => void,
  onItemEdit?: (item: SearchItem, index: number, source: 'Search' | 'ClientSearch') => void,
  onSubmitSearch?: (searchText: string, promise: Promise<unknown>) => void,
}) => {
  const { authInfo } = useContext(AppCtx);
  const foodIndex = useFoodSearchIndex();
  const foodLoadReq = useAsyncResult<unknown>();
  const searchIndexRes = useAsyncResult<FoodSearchResult>();
  const searchQueryRes = useAsyncResult<SearchItem[]>();
  const [searchEfficiencyMetrics, setSearchEfficiencyMetrics] = React.useState({
    firstKeypressTimestamp: 0,
    keyPressCount: 0,
    allKeyPresses: '',
    searchCount: 0,
  });

  const [searchText, _setSearchText] = React.useState('');
  useEffect(() => {
    if (props.initialSearchText) {
      _setSearchText(props.initialSearchText);
    }
  }, [props.initialSearchText]);

  const setSearchText = (text: string) => {
    if (!searchEfficiencyMetrics.firstKeypressTimestamp) {
      setSearchEfficiencyMetrics({
        ...searchEfficiencyMetrics,
        firstKeypressTimestamp: Date.now(),
      });
    }
    _setSearchText(text);
    searchQueryRes.clear();
  };

  React.useEffect((): void => {
    if (!foodIndex.isDone) {
      return;
    }

    if (!searchText) {
      searchIndexRes.clear();
      return;
    }

    const req = foodIndex.result.search('flexi', searchText);
    searchIndexRes.bind(req);
  }, [searchText, foodIndex.isDone]);

  const search = async () => {
    if (!authInfo || !props.patient_id) {
      throw new Error('not logged in');
    }

    if (!searchText) {
      return [];
    }

    return searchByTextWithContext(searchText, props.patient_id, authInfo.access_token);
  };

  const submitSearch = () => {
    setSearchEfficiencyMetrics({
      ...searchEfficiencyMetrics,
      searchCount: searchEfficiencyMetrics.searchCount + 1,
    });
    const req = search();
    props.onSubmitSearch && props.onSubmitSearch(searchText, req);
    searchQueryRes.bind(req);
  };

  const logEfficiencyMetrics = (
    source: 'Search' | 'ClientSearch',
    index: number,
    resultName: string,
    f: any,
  ) => {
    mixpanel.track('Food search: efficiency', {
      Source: source,
      'Time to select': searchEfficiencyMetrics.firstKeypressTimestamp
        ? Date.now() - searchEfficiencyMetrics.firstKeypressTimestamp
        : null,
      'Keypress count': searchEfficiencyMetrics.keyPressCount,
      'All keypresses': searchEfficiencyMetrics.allKeyPresses,
      'Search count': searchEfficiencyMetrics.searchCount,
      'Query length': searchText.length,
      'Result length': resultName.length,
      'Query': searchText,
      'Result': resultName,
      Index: index,
    });
    setSearchEfficiencyMetrics({
      firstKeypressTimestamp: 0,
      keyPressCount: 0,
      allKeyPresses: '',
      searchCount: 0,
    });
    return f();
  };

  const loadAndSubmitFood = async (
    callback: (item: SearchItem, index: number, source: 'Search' | 'ClientSearch') => void,
    foodName: string,
    index: number,
  ) => {
    if (foodLoadReq.isPending) {
      return;
    }
    const req = getFoodByQuery(foodName, authInfo!.access_token);
    foodLoadReq.bind(req);
    req.then(food => callback(food, index, 'ClientSearch'));
    req.catch(err => {
      logTrackedError({
        sourceName: 'FoodSearchInput.getFoodByQuery',
        origin: err,
        stackError: new Error(),
        context: { foodName },
        userMessage: 'Error loading food details. Please try again.',
      });
    });
  };

  const renderIndexSearchResults = () => {
    if (!foodIndex.isDone || searchIndexRes.isEmpty) {
      return (
        <div>
          <i>
            Quick search: {foodIndex.loadState.state} / {formatNumber(foodIndex.indexSize)} items{' '}
            {foodIndex.loadState.isError && ' ' + foodIndex.loadState.error}
            <span onClick={() => foodIndex.reload()}>(rebuild)</span>
          </i>
        </div>
      );
    }

    if (searchIndexRes.isError) {
      return <div>Error: {'' + searchIndexRes.error}</div>;
    }

    if (searchIndexRes.isPending) {
      return (
        <div>
          <i>Loading...</i>
        </div>
      );
    }

    if (!searchIndexRes.result.items.length) {
      return (
        <div>
          <i>
            No quick results found; <a href="javascript:void(0)" onClick={submitSearch}>Search Everything</a>{' '}
            to get more results.
          </i>
        </div>
      );
    }

    return (
      <div>
        {searchIndexRes.result.items.slice(0, FOOD_ENGINE_MAX_RESULTS).map((item, idx) => (
          <div key={idx} className="searchOption">
            <FontAwesomeIcon
              icon={faPlusSquare}
              size="sm"
              color={foodLoadReq.isPending ? 'gray' : 'dodgerblue'}
              style={{ marginRight: '0.5em', cursor: foodLoadReq.isPending ? 'default' : 'pointer' }}
              onClick={() =>
                logEfficiencyMetrics(
                  'ClientSearch',
                  idx,
                  item.name,
                  () => {
                    searchIndexRes.clear();
                    loadAndSubmitFood(props.onItemSelect, item.name, idx);
                  },
                )}
            />
            {item.name}
            {!!props.onItemEdit && (
              <FontAwesomeIcon
                icon={faEdit}
                size="sm"
                color={foodLoadReq.isPending ? 'gray' : 'dodgerblue'}
                style={{ marginLeft: '0.5em', cursor: foodLoadReq.isPending ? 'default' : 'pointer' }}
                onClick={() =>
                  logEfficiencyMetrics(
                    'ClientSearch',
                    idx,
                    item.name,
                    () => loadAndSubmitFood(props.onItemEdit!, item.name, idx),
                  )}
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderSearchResults = () => {
    if (searchQueryRes.isEmpty) {
      return renderIndexSearchResults();
    }

    if (searchQueryRes.isError) {
      return <div>Error: {'' + searchQueryRes.error}</div>;
    }

    if (searchQueryRes.isPending) {
      return (
        <div>
          <i>Loading...</i>
        </div>
      );
    }

    if (!searchQueryRes.result.length) {
      return (
        <div>
          <i>No foods found matching: {searchText}</i>
        </div>
      );
    }

    return searchQueryRes.result.map((option, idx) => (
      <div key={idx} className="searchOption">
        <FontAwesomeIcon
          icon={faPlusSquare}
          size="sm"
          color="dodgerblue"
          style={{ marginRight: '0.5em', cursor: 'pointer' }}
          onClick={() =>
            logEfficiencyMetrics(
              'Search',
              idx,
              option.name,
              () => {
                searchQueryRes.clear();
                props.onItemSelect(option, idx, 'Search');
              },
            )}
        />
        {option.name}
        ({option.nutrition_source}: {option.nutrition_source_id})
        {!!props.onItemEdit && (
          <FontAwesomeIcon
            icon={faEdit}
            size="sm"
            color="dodgerblue"
            style={{ marginLeft: '0.5em', cursor: 'pointer' }}
            onClick={() =>
              logEfficiencyMetrics(
                'Search',
                idx,
                option.name,
                () => props.onItemEdit!(option, idx, 'Search'),
              )}
          />
        )}
      </div>
    ));
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <InputGroup>
        <Form.Control
          autoFocus
          placeholder="Search"
          value={searchText}
          onKeyPress={(evt: React.KeyboardEvent<any>) => {
            if (evt.code == 'Enter') {
              submitSearch();
            }
          }}
          onKeyDown={(evt: React.KeyboardEvent<any>) => {
            try {
              let key = encodeKey(evt);
              if (searchText == '' && searchEfficiencyMetrics.allKeyPresses.length > 0) {
                key = `<Clear>${key}`;
              }
              key && setSearchEfficiencyMetrics({
                ...searchEfficiencyMetrics,
                keyPressCount: searchEfficiencyMetrics.keyPressCount + 1,
                allKeyPresses: searchEfficiencyMetrics.allKeyPresses + key,
              });
            } catch (err) {
              console.error('Error encoding keypress:', err);
              setSearchEfficiencyMetrics({
                ...searchEfficiencyMetrics,
                keyPressCount: searchEfficiencyMetrics.keyPressCount + 1,
                allKeyPresses: searchEfficiencyMetrics.allKeyPresses + '<Err>',
              });
            }
          }}
          onChange={(evt) => setSearchText(evt.target.value)}
        />
        <Button variant="primary" onClick={submitSearch}>Search Everything</Button>
      </InputGroup>
      <div style={{ marginTop: 10 }}>
        {renderSearchResults()}
      </div>
    </div>
  );
};

const encodeKey = (evt: React.KeyboardEvent<any>) => {
  const metaKeys = ['Alt', 'Control', 'Meta', 'Shift'];
  // Ignore keypresses which are only meta keys
  if (metaKeys.includes(evt.key)) {
    return null;
  }

  // Escape special characters
  let key = evt.key;
  if (key.includes('%') || key.includes('<')) {
    key = escape(key);
  }

  if (key == 'Backspace') {
    key = 'Bs';
  }

  // Add meta keys to the key string
  const metaFields = {
    'altKey': 'alt',
    'ctrlKey': 'ctrl',
    'metaKey': 'meta',
  } as const;
  const metaStr = Object.entries(metaFields)
    .filter(([key, _]) => evt[key as keyof React.KeyboardEvent<any>])
    .map(([_, val]) => val)
    .join('-');
  if (metaStr) {
    key = `${metaStr}-${key}`;
  }

  // Wrap in <...> if it's a multi-character key
  if (key.length > 1 && !key.startsWith('%')) {
    return `<${key}>`;
  }
  return key;
};
