import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardContent, Divider, Grid, InputAdornment, OutlinedInput, Stack, Tooltip, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import React, { useContext } from 'react';
import { Button, Collapse, Form, Modal } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AsyncResult, useAsyncResult } from 'react-use-async-result';

import { faCaretDown, faCaretRight, faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import InnerImageZoom from 'react-inner-image-zoom';

import { getMealHistory } from 'apiClients/mealHistory';
import { getMealQueueItem, getMealQueueItemQaLogs } from 'apiClients/mpq';
import { ContextPredictedItemsResponse, getPatientContext } from 'apiClients/patientDetails';
import { deleteMealItem, getMealChangeLogs, getMealItems, postNewFoodRequest } from 'apiClients/review';
import { getFoodByQueryWithContext } from 'apiClients/search';
import { pluralize } from 'async-result/utils';
import { FoodSearchInput } from 'client-search/FoodSearchInput';
import { AddonEditor } from 'components/AddonEditor';
import { LegacyMealSummary } from 'components/LegacyMealSummary';
import { ListMealHistoryView } from 'components/ListMealHistoryView';
import { MealQAForm } from 'components/MealQAForm';
import { MealQALogs } from 'components/MealQALogs';
// import { MealSummary } from 'components/MealSummary';
import { ShowPatientNote } from 'components/ShowPatientNote';
import { StyledTextBox } from 'components/StyledTextBox';

import type { DraftItem } from '../types/DraftItem';

import { config } from 'config';
import { AppCtx, useAuth } from 'context/appContext';
import { CUSTOM_ITEM_DEFAULT_SERVING_UNITS, CUSTOM_ITEM_SERVING_UNITS } from 'customItemServingUnits';
import { defaultSearchItem, sandwichReplacementServingUnits } from '../constants';

import type {
  CreateQualityAssuranceLogRequest,
  MealHistoryResponse,
  MealItemResponse,
  MealPhotoQueueQoSDetailsResponseOverlappingQueuesInner,
  MealPhotoQueueResponse,
  NutrientEstimatesRequest,
  PatientContextQueueResponse,
  PreparationMethodEnum,
} from 'api/generated/MNT';
import type { QualityAssuranceLogResponse } from 'api/generated/MNT';
import type { MealHistory } from 'apiClients/mealHistory';
import type { MealItem, MealQueueItem } from 'apiClients/mpq';
import type { MealResponse } from 'apiClients/review';
import type { SearchItem } from 'apiClients/search';

import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'App.css';

import { Box } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import { dataReviewApi, mealApi } from 'api';
import { ChangeLogItem, MealChangeLogResponse } from 'api/generated/MNT';
import { Capabilities } from 'auth-capabilities';
import { FilterSortTable } from 'components/FilterSortTable';
import MainCard from 'components/MainCard';
import { PatientID } from 'components/PatientID';
import ScrollX from 'components/ScrollX';
import { useMealQALogs } from 'components/useMealQALogs';
import { MealChangeLogsTable } from 'food-editor/components/FoodChangeLogs';
import { HumanTime } from 'food-editor/components/HumanTime';
import _, { over } from 'lodash';
import { useFoodDetails } from 'services/FoodDetailsService';
import { encodeQueryParams, useQueryNeverRefetch } from 'utils';
import { floatOrNull } from './QueueItem/meal-builder/MealBuilder';
import { useMealChangeLogs } from './QueueItem/meal-builder/useMealChangeLogs';
import { useRelevantNutrients } from './QueueItem/services/QueueItemEditorService';

const SERVING_TYPE_ITEMS = ['pan crust pizza', 'thin crust pizza', 'pizza'];

/** @deprecated  */
const formatNutrient = (nutrient: any, value: number | null) => {
  if (value == null || isNaN(+value)) {
    return '-';
  }
  return `${value.toFixed(nutrient.decimals)}${nutrient.suffix}`;
};

export const LegacyReviewItem = () => {
  const { authInfo, hasAuth } = useContext(AppCtx);
  const { itemId } = useParams();
  const itemIdNum = parseInt(itemId || '0', 10);
  const location = useLocation();
  const [_q, _] = useSearchParams();
  const q = Object.fromEntries(_q.entries()) as any;
  const locationState = (location.state || {}) as {
    queueItem: MealQueueItem,
    prevUrl?: string,
  };
  const navigate = useNavigate();
  const queueItemReq = useAsyncResult<MealQueueItem>();

  const prevUrl = locationState.prevUrl
    || q.back
    || '/review/';
  const goPrevUrl = () => {
    if (prevUrl.search(/^https?:/) >= 0) {
      window.location.href = prevUrl;
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (!(authInfo?.access_token && itemId)) {
      return;
    }

    const loadItem = async () => {
      let { queueItem } = locationState;
      if (!queueItem || queueItem.id !== itemIdNum) {
        queueItem = await getMealQueueItem(itemIdNum);
      }

      return queueItem;
    };

    queueItemReq.bind(loadItem());
  }, [location.state, itemIdNum, authInfo?.access_token]);

  if (queueItemReq.isEmpty || queueItemReq.isPending) {
    return <div>Loading...</div>;
  }

  if (queueItemReq.isError) {
    return <div>Load error: {'' + queueItemReq.error}</div>;
  }

  const queueReviewItem = queueItemReq.result;
  const reviewerCanSeeQaLogs = authInfo?.review_groups?.some(g => g.id == queueReviewItem.data_reviewer_group_id);

  const pageContents = (
    <div>
      <div className="reviewEditorTitle">
        <div>
          <Button
            onClick={() => {
              if (window.confirm('Are you sure you want to go back? Unsaved edits will be lost.')) {
                goPrevUrl();
              }
            }}
          >
            Go back
          </Button>
        </div>
        <div style={{ minWidth: '12%' }}>
          <div className="fieldValue">
            <b>Meal ID:</b>
            {queueReviewItem.created_meal_id}
          </div>
          <div className="fieldValue">
            <b>Queue ID:</b>
            {queueReviewItem.id}
          </div>
          <div className="fieldValue">
            <b>Labelling ID:</b>
            {queueReviewItem.id}
          </div>
        </div>
        <div style={{ minWidth: '20%' }}>
          <div className="fieldValue">
            <b>Log type:</b>
            {queueReviewItem.queue_type}
          </div>
          <div className="fieldValue">
            <b>Created Time:</b>
            <HumanTime value={queueReviewItem.created_time} />
          </div>
          <div className="fieldValue">
            <b>Patient ID:</b>{' '}
            <PatientID userId={queueReviewItem.patient_id} isPriority={!!queueReviewItem.is_priority_patient} />
          </div>
        </div>
        {hasAuth(Capabilities.mpqChangeLogsFullAttributionView) && (
          <div style={{ minWidth: '10%' }}>
            <div className="fieldValue">
              <b>Reviewer ID:</b>
              {queueReviewItem.data_reviewer_id}
            </div>
            <div className="fieldValue">
              <b>Note:</b>
              {queueReviewItem.data_reviewer_note}
            </div>
          </div>
        )}
        <ShowPatientNote queueItem={queueReviewItem} />
      </div>

      <div className="splitPane">
        <MealBuilder queueItem={queueReviewItem} />

        <div className="queueItemDetails" id="detailsReview">
          <QueueItemDetails queueItem={queueReviewItem} />
        </div>

        <div className="itemBuilder" id="assetReview">
          <AssetView item={queueReviewItem} />
        </div>
      </div>

      {reviewerCanSeeQaLogs && <QAView queueItem={queueReviewItem} />}
    </div>
  );

  return pageContents;
};

const QAView = (props: {
  queueItem: MealQueueItem,
}) => {
  const { queueItem } = props;
  const { qaLogs, postMealQA } = useMealQALogs(queueItem);
  const { authInfo } = useAuth();
  const handlePostMealQA = (data: CreateQualityAssuranceLogRequest) => {
    return postMealQA(authInfo?.access_token || '', {
      data_reviewer_id: authInfo?.reviewer_id || 0,
      meal_photo_queue_id: queueItem.id,
      ...data,
    });
  };
  return (
    <div style={{ marginBottom: 20 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <MealQAForm
            item={queueItem}
            qaLogs={qaLogs}
            postMealQA={handlePostMealQA}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <MealQALogs qaLogs={qaLogs} />
            <MealQoSDetails item={queueItem} />
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

const AssetView = (props: { item: MealQueueItem }) => {
  const { authInfo } = useAuth();
  const queueItem = props.item;

  const mealPhotoUrlReq = useAsyncResult<string>(async () => {
    if (!props.item.meal_photo_id) {
      return null;
    }

    const path = `${config.API_URL}/api/meal_photos/${props.item.meal_photo_id}/photo-url`;
    const resp = await fetch(path, {
      headers: {
        'Authorization': `Bearer ${authInfo?.access_token}`,
      },
    });
    if (resp.status !== 200) {
      throw new Error(`${resp.status}`);
    }
    const data = await resp.json();
    return data.url;
  }, [props.item, authInfo]);

  return (
    <div className="itemBuilder" id="asset">
      <div className="sectionTitle">Asset</div>
      <br />
      {queueItem.meal_photo_id && (
        <div>
          {mealPhotoUrlReq.isError
            ? <div>Image request error: {'' + mealPhotoUrlReq.error}</div>
            : mealPhotoUrlReq.isPending
            ? <div>Loading...</div>
            : mealPhotoUrlReq.isDone && mealPhotoUrlReq.result
            ? <InnerImageZoom src={mealPhotoUrlReq.result} zoomSrc={mealPhotoUrlReq.result} />
            : mealPhotoUrlReq.isDone && !mealPhotoUrlReq.result
            ? <div>No image</div>
            : <div>Unable to display image</div>}
        </div>
      )}
      {!queueItem.meal_photo_id
        && <div>No image</div>}
      {queueItem.meal_search_text && (
        <div>
          <b>NLP text:</b>
          {queueItem.meal_search_text}
        </div>
      )}
      <div>
        <b>Meal Note:</b> {queueItem.patient_note ? queueItem.patient_note : 'not provided'}
      </div>
      <div>
        <b>Preparation Method</b> {queueItem.preparation_method ? queueItem.preparation_method : 'not provided'}
      </div>
    </div>
  );
};

const QueueItemDetails = (props: { queueItem: MealQueueItem }) => {
  const { authInfo } = useContext(AppCtx);
  const { queueItem } = props;
  const [clinicName, setClinicName] = useState('');
  const [country, setCountry] = useState('');
  const [dietRestrictions, setDietRestrictions] = useState('');

  useEffect(() => {
    const getContext = async () => {
      if (queueItem && authInfo) {
        const context: PatientContextQueueResponse = await getPatientContext(
          queueItem.data_reviewer_group_id,
          authInfo.reviewer_id,
          queueItem.id,
        );

        setClinicName(context.clinics[0].name);
        setCountry(context.country);

        const restrictionsStr = context.diet_restrictions.map(({ name, comment }) => {
          if (!comment || name === 'everything') {
            return name;
          }
          return name + ': ' + comment;
        }).join(', ');
        setDietRestrictions(restrictionsStr);
      }
    };
    getContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div key={queueItem.id + Math.random()} style={{ width: '600px' }}>
      <div className="sectionTitle">Meal and User Info</div>
      <div className="fieldValue">
        <b>Clinic Name:{' '}</b>
        {clinicName}, {country}
      </div>
      <div className="fieldValue">
        <b>Diet Restrictions:{' '}</b>
        <span style={{ color: dietRestrictions == 'everything' ? 'black' : 'red' }}>
          {dietRestrictions}
        </span>
      </div>
      {queueItem.queue_type != 'nlp_queue'
        && (
          <div className="fieldValue">
            <b>Meal Note:{' '}</b>
            {queueItem.patient_note ? queueItem.patient_note : 'not provided'}
          </div>
        )}
    </div>
  );
};

export const MealChangeLogs = (props: {
  queueItem: MealQueueItem,
  draftItems: DraftItem[],
  hideTitle?: boolean,
  mainCardStyle?: React.CSSProperties,
}) => {
  const mealChangeLogsRes = useMealChangeLogs(props.queueItem);

  return (
    <MainCard
      style={{ minHeight: 200, maxHeight: 300, overflowY: 'auto', ...(props.mainCardStyle || {}) }}
      title={props.hideTitle ? null : 'Meal History'}
    >
      {mealChangeLogsRes.query.isError && <div>{'' + mealChangeLogsRes.query.error}</div>}
      {mealChangeLogsRes.query.isLoading && <div>Loading...</div>}
      {mealChangeLogsRes.query.isSuccess && (mealChangeLogsRes.data
        ? (
          <MealChangeLogsTable
            changeLogs={mealChangeLogsRes.data}
            firstReviewerID={props.queueItem.first_reviewer_user_id}
          />
        )
        : <div>No Changes</div>)}
    </MainCard>
  );
};

const MealBuilder = (props: { queueItem: MealQueueItem }) => {
  const { authInfo } = useContext(AppCtx);
  const { queueItem } = props;

  const [addonDetailsOpened, setAddonDetailsOpened] = useState<{
    searchItem: SearchItem,
    addonDetails: boolean[],
  }[]>([]);
  const [mealItemsOpened, setMealItemsOpened] = useState<{ name: string, itemOpen: boolean }[]>([]);
  const [showSearchBox, setShowSearchBox] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const busy = useRef(false);
  const [mealResponse, setMealResponse] = useState<MealResponse | null>(null);
  const [draftItems, setDraftItems] = useState<DraftItem[]>([]);

  // states for new food request modal (we should really start separating these into components)
  const [showNewFood, setShowNewFood] = useState<boolean>(false);
  const [newFoodError, setNewFoodError] = useState<string>('');
  const [newFoodName, setNewFoodName] = useState<string>('');
  const [newFoodBrand, setNewFoodBrand] = useState<string>('');
  const [newFoodManufacturer, setNewFoodManufacturer] = useState<string>('');
  const [newFoodUrl, setNewFoodUrl] = useState<string>('');

  const mealChangeLogsRes = useMealChangeLogs(queueItem);

  const mealItemResponseToMealItem = (item: MealItemResponse) => {
    const mealItem: MealItem = {
      addons: item.addons,
      custom_item: item.custom_item,
      custom_item_source: item.custom_item_source ?? null,
      custom_item_source_id: item.custom_item_source_id ?? null,
      custom_nutrient_estimates: item.custom_nutrient_estimates,
      custom_usda_id: null,
      food_name: (item.food_name == null ? 'Missing' : item.food_name),
      food_replacement_name: item.food_replacement_name,
      meal_photo_id: item.meal_photo_id,
      note: null,
      percent_eaten: item.percent_eaten,
      preparation_method: item.preparation_method,
      nutrition_source: item.nutrition_source,
      serving_type_label:
        (item.serving_type_label == 'null' || item.serving_type_label == '' ? null : item.serving_type_label),
      serving_type_multiplier: (item.serving_type_multiplier == null ? null : Number(item.serving_type_multiplier)),
      serving_unit_amount: (item.serving_unit_amount == null ? 0 : item.serving_unit_amount),
      serving_unit_label: (item.serving_unit_label == null ? 'Missing' : item.serving_unit_label),
      custom_addons: item.custom_addons || [],
      servings: (item.servings == null ? 0 : item.servings),
    };

    return mealItem;
  };

  const loadMealItems = async () => {
    if (authInfo) {
      const i = await getMealItems(queueItem.patient_id, queueItem.created_meal_id, authInfo.access_token);
      setMealResponse(i);
      let mealItems: any = [];
      let openedList: any = [];
      let addonsOpen: any = [];
      if (i && i.meal_items) {
        for (const m of i.meal_items) {
          console.log(m.food_name);
          const mi = mealItemResponseToMealItem(m);
          openedList = [...openedList, { name: mi.food_name, itemOpen: false }];

          try {
            const item: SearchItem = await getFoodByQueryWithContext(mi.food_name, m.patient_id);
            if (item) {
              mealItems = [...mealItems, { item: mi, searchItem: item, id: m.id }];
              addonsOpen = [...addonsOpen, {
                searchItem: item,
                addonDetails: (new Array(item.addons?.length || 0)).fill(false),
              }];
            } else {
              window.alert(
                `Unable to find a matching search item for food ${mi.food_name}, not displaying in the meal item review panel`,
              );
              const searchItem = null;
              mealItems = [...mealItems, { item: mi, searchItem, id: m.id }];
            }
          } catch (err) {
            window.alert(
              `Unable to find a matching search item for food ${mi.food_name}, not displaying in the meal item review panel`,
            );
            const searchItem = null;
            mealItems = [...mealItems, { item: mi, searchItem, id: m.id }];
          }
        }
        setMealItemsOpened(openedList);
        setAddonDetailsOpened(addonsOpen);
        setDraftItems(mealItems);
      }
    }
  };

  useEffect(() => {
    loadMealItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAllMealItemsOpened = (visible: boolean) => {
    const newMealItemsOpened = mealItemsOpened.map(k => {
      return { ...k, itemOpen: visible };
    });
    setMealItemsOpened(newMealItemsOpened);
  };

  const toggleMealItemOpened = (searchItem: SearchItem) => {
    const newMealItemsOpened = mealItemsOpened.map(k => {
      if (k.name != searchItem.name) {
        return k;
      }
      return { ...k, itemOpen: !(k.itemOpen) };
    });
    setMealItemsOpened(newMealItemsOpened);
  };

  const addItemOpenedOthersClosed = (searchItem: SearchItem) => {
    let newMealItemsOpened = mealItemsOpened.map(k => {
      return { ...k, itemOpen: false };
    });
    const itemOpen = true;
    const name = searchItem.name;
    newMealItemsOpened = [...newMealItemsOpened, { name, itemOpen }];
    setMealItemsOpened(newMealItemsOpened);
  };

  const addItem = async (searchItem: SearchItem) => {
    if (!queueItem || !authInfo) {
      return;
    }

    const item: MealItem = {
      addons: [],
      custom_addons: [],
      custom_item: false,
      custom_item_source: null,
      custom_item_source_id: null,
      custom_usda_id: null,
      food_name: searchItem.name,
      food_replacement_name: null,
      meal_photo_id: queueItem.meal_photo_id,
      note: null,
      nutrition_source: null,
      serving_type_label: SERVING_TYPE_ITEMS.includes(searchItem.name) ? searchItem.serving_types![0].label : null,
      serving_type_multiplier: SERVING_TYPE_ITEMS.includes(searchItem.name)
        ? searchItem.serving_types![0].multiplier
        : null,
      serving_unit_amount: searchItem.serving_units![0].amount,
      serving_unit_label: searchItem.serving_units![0].label,
      servings: searchItem.serving_units![0].default_label_qty!,
    };
    // note: default serving unit_label is the first item in the array
    // assumption: meal items added from search do not require validation

    const res = await mealApi.appApiMealPostMealItems({
      meal_id: queueItem.created_meal_id,
      patient_id: queueItem.patient_id,
      CreateMealItemRequest: [{
        extra_addons: [],
        ...item,
      }],
    });
    const id = res.data[0].id;
    if (!id) {
      return;
    }

    console.log(item);
    setDraftItems([
      ...draftItems,
      { id, item, searchItem, queryText: item.food_name, foodMatchDetails: null },
    ]);
    const addonDetails = (new Array(searchItem.addons!.length)).fill(false);
    setAddonDetailsOpened([...addonDetailsOpened, { searchItem, addonDetails }]);
    addItemOpenedOthersClosed(searchItem);

    setShowSearchBox(false);
  };

  const validateMealItem = (item: MealItem, searchItem: SearchItem) => {
    // check for sandwich replacement items uses hard coded values and can be adapted to additional replacement items if needed
    if (item.food_name == 'sandwich' && item.food_replacement_name != null) {
      return sandwichReplacementServingUnits.find(r => {
        return r.serving_units.amount == item.serving_unit_amount
          && r.food_replacement_name == item.food_replacement_name;
      })?.serving_units != undefined;
    }

    // statement checks that there is a match for both serving_unit_label and serving_unit_amount in the searchItem
    const expectedServingUnits = searchItem.serving_units!.find(i => {
      return i.label == item.serving_unit_label;
    });
    if (expectedServingUnits) {
      return expectedServingUnits.amount == item.serving_unit_amount;
    }

    return false; // if first if statement not applicable and second failed and then item contains bad data
  };

  const updateItem = (item: MealItem, newItem: MealItem) => {
    const newDraft = draftItems.map(v => {
      if (v.item !== item) {
        return v;
      }
      return { ...v, item: newItem };
    });
    setDraftItems(newDraft);
    console.log(draftItems);
  };

  const updateDraftItemMealItem = (draftItem: DraftItem, newMealItem: Partial<MealItem>) => {
    updateItem(draftItem.item, { ...draftItem.item, ...newMealItem });
  };

  const removeMealItem = async (draftItem: DraftItem) => {
    if (authInfo && queueItem && draftItem.id) {
      const itemName = draftItem.item.food_name;
      if (!window.confirm('Are you sure you want to remove ' + itemName)) {
        return;
      }

      await deleteMealItem(
        queueItem.patient_id,
        queueItem.created_meal_id,
        draftItem.id.toString(),
        authInfo.access_token,
      );

      const newDraft = draftItems.filter(i => i !== draftItem);

      console.log(newDraft);
      setDraftItems(newDraft);
    }
  };

  const saveMealItem = async (id: number | null) => {
    if (authInfo && queueItem && id) {
      const draftItem = draftItems.find(i => i.id == id);
      if (!draftItem?.id) {
        return;
      }

      await mealApi.appApiMealPutMealItem({
        meal_id: queueItem.created_meal_id,
        patient_id: queueItem.patient_id,
        meal_item_id: draftItem.id,
        UpdateMealItemRequest: {
          extra_addons: [],
          ...draftItem.item,
          food_match_details: draftItem.foodMatchDetails ?? undefined,
        },
      });
      window.alert('Saved changes for ' + draftItem.item.food_name);
    }
  };

  const validationFailed = () => {
    window.alert('Serving amount entered could not be read as a number. Please edit value or cancel');
  };

  const ServingUnitAmountEdit = (props: { item: MealItem }) => {
    return (
      <div className="patientNoteField">
        <div>
          <StyledTextBox
            type="number"
            viewProps={{
              className: 'servingUnitAmountEditField',
            }}
            startEditingOnEnter
            editOnViewClick={true}
            submitOnEnter
            cancelOnEscape
            value={props.item.serving_unit_amount.toString()}
            validation={val => !isNaN(parseFloat(val))}
            onValidationFail={() => validationFailed()}
            onSave={(evt) => {
              const newItem: MealItem = { ...props.item, serving_unit_amount: parseFloat(evt) };
              updateItem(props.item, newItem);
              console.log(newItem);
            }}
          />
        </div>
      </div>
    );
  };

  const ServingOptionsEdit = (props: { item: MealItem }) => {
    return (
      <div className="patientNoteField">
        <div>
          <StyledTextBox
            type="number"
            viewProps={{
              className: 'servingOptionsEditField',
            }}
            startEditingOnEnter
            editOnViewClick={true}
            submitOnEnter
            cancelOnEscape
            value={props.item.servings.toString()}
            validation={val => !isNaN(parseFloat(val))}
            onValidationFail={() => validationFailed()}
            onSave={(evt) => {
              const newItem: MealItem = { ...props.item, servings: parseFloat(evt) };
              updateItem(props.item, newItem);
              console.log(newItem);
            }}
          />
        </div>
      </div>
    );
  };

  const setDefaultSizing = (
    item: MealItem,
    serving_unit_label: string,
    serving_unit_amount: number,
    servings: number,
  ) => {
    const newItem: MealItem = { ...item, serving_unit_label, serving_unit_amount, servings };
    updateItem(item, newItem);
  };

  // const removeAddon = (id: number | string, addon: string, item: MealItem) => {
  //   const addons = item.addons.filter(a => a !== addon);
  //   const newItem: MealItem = { ...item, addons };
  //   updateItem(id, item, newItem);
  // };

  // const removeReplacement = (id: number | string, item: MealItem) => {
  //   const food_replacement_name = null;
  //   const newItem: MealItem = { ...item, food_replacement_name };
  //   updateItem(id, item, newItem);
  // };

  const MealItemView = (props: { draftItem: DraftItem }) => {
    const { draftItem } = props;
    const { item, searchItem, id } = draftItem;
    const isCustom = !!draftItem?.item?.custom_item;
    const selectedFoodDetails = useFoodDetails(isCustom ? draftItem?.searchItem?.name : null);
    const relevantNutrients = useRelevantNutrients({
      context: 'item',
    });

    const calculatedNutrients = useMemo(() => {
      const { item } = draftItem || {};
      if (!item || selectedFoodDetails.query.isLoading) {
        return {};
      }
      const foodNutrients = selectedFoodDetails?.usda_nutrition || {};
      const servingUnitAmount = item.serving_unit_amount || 1;
      const servingUnitQty = item.servings;
      return Object.fromEntries(
        relevantNutrients.map((n) => {
          const nutrient = n.nutrient;
          if (nutrient == 'netcarb_g') {
            return [nutrient, null];
          }

          const itemAny = item as any;
          if (typeof itemAny[nutrient] === 'number') {
            return [nutrient, formatNutrient(n, itemAny[nutrient] as number)];
          }

          if (typeof foodNutrients[nutrient] !== 'number') {
            return [nutrient, '–'];
          }

          return [
            nutrient,
            formatNutrient(
              n,
              (foodNutrients[nutrient] || 0) * servingUnitQty * servingUnitAmount / 100,
            ),
          ];
        }),
      );
    }, [draftItem, selectedFoodDetails, relevantNutrients]);

    const calcNetCarbs = (overrides: Partial<NutrientEstimatesRequest>) => {
      const { item } = draftItem || {};
      if (!item) {
        return null;
      }

      const foodNutrients = selectedFoodDetails?.usda_nutrition || {};
      const servingUnitAmount = item.serving_unit_amount || 1;
      const servingUnitQty = item.servings;
      const fixServing = (value: number | null | undefined) => {
        if (typeof value !== 'number') {
          return null;
        }
        return (value * servingUnitQty * servingUnitAmount) / 100;
      };

      if (
        typeof overrides.carbohydrate_g !== 'number'
        && typeof foodNutrients.fiber_g !== 'number'
        && typeof foodNutrients.polyols_g !== 'number'
      ) {
        return null;
      }

      const netCarbG = (overrides.carbohydrate_g ?? fixServing(foodNutrients.carbohydrate_g) ?? 0)
        - (overrides.fiber_g ?? fixServing(foodNutrients.fiber_g) ?? 0)
        - (overrides.polyols_g ?? fixServing(foodNutrients.polyols_g) ?? 0);

      return netCarbG;
    };

    const handleNutrientChange = (newNutrients: Partial<NutrientEstimatesRequest>) => {
      if (!draftItem) {
        return;
      }

      const newCustomNutrients = {
        ...((draftItem.item.custom_nutrient_estimates || {}) as any),
        ...newNutrients,
      };

      updateItem(draftItem.item, {
        ...draftItem.item,
        custom_nutrient_estimates: {
          ...newCustomNutrients,
          netcarb_g: calcNetCarbs(newCustomNutrients),
        },
      });
    };

    if (!searchItem) {
      return null;
    }

    const servingUnitOptions = [
      { label: 'Choose one...' },
      ...searchItem.serving_units!,
      ...(item.custom_item ? [{ label: '------------' }] : []),
      ...(item.custom_item ? CUSTOM_ITEM_SERVING_UNITS : []),
    ];

    /*
    const itemChanges = !mealChangeLogsRes.query.isSuccess
      ? null
      : (mealChangeLogsRes.data?.meal_item_changes || []).filter(c => {
        return (
          c.item_type == 'meal_item'
          && +c.item_id! == draftItem.id
        );
      });
    const itemChangeLogs: MealChangeLogResponse | null = itemChanges && {
      meal_changes: [],
      meal_item_changes: itemChanges,
      meal_item_custom_addon_changes: [],
    };
    const editCount = (itemChanges || []).filter(c => c.change_type != 'create').length;
    */
    const editCount = -999;
    const itemChangeLogs = null;

    const servingOptions = searchItem.serving_units!.find(v => v.label === item.serving_unit_label);
    const mealItemOpen = mealItemsOpened.find(k => k.name == searchItem.name)?.itemOpen;
    // let rand = Math.floor(Math.random() * 100) + 1

    const getFoodEditorUrl = (foodName: string) => {
      return `/foods/${encodeURIComponent(foodName)}`;
    };

    return (
      <div key={item.food_name + Math.random()} className="mealItemEditor">
        <div className="pushToRight">
          <div
            onClick={() => {
              toggleMealItemOpened(searchItem);
            }}
          >
            {mealItemOpen && <FontAwesomeIcon icon={faCaretDown} size="lg" className="mealItemExpandButton" />}
            {!mealItemOpen && <FontAwesomeIcon icon={faCaretRight} size="lg" className="mealItemExpandButton" />}
            Collapse/Expand
          </div>
          <div className="noteText">
            {item.custom_item ? <div>* custom item</div> : ''}
            {editCount > 0 && <div>{pluralize(editCount, 'edit', 'edits')}</div>}
          </div>
        </div>
        <div>
          <div>
            <b>Name:</b> {item.food_name} (Carbs/100g: {searchItem.carbohydrate_g} g){'  '}
            <Link
              to={getFoodEditorUrl(draftItem.item.food_name)}
              target="_blank"
            >
              <FontAwesomeIcon
                className="qaActionButton"
                icon={faExternalLinkSquareAlt}
                size="sm"
                color="black"
              />
            </Link>
          </div>
          <b>Meal Item ID:&nbsp;</b>
          {id} ({searchItem.nutrition_source_id}|{item.nutrition_source == null ? 'n/a' : item.nutrition_source})<br />
          <Button onClick={() => removeMealItem(draftItem)}>Remove {item.food_name}</Button>
        </div>
        <Collapse in={mealItemOpen}>
          <div>
            {searchItem.custom_tip && (
              <div>
                <b>Tip:</b> {searchItem.custom_tip}
              </div>
            )}
            <div style={{ lineHeight: '1.3', paddingBottom: '10px' }}>
              <b>Available sizes (click to set):</b>
              {searchItem.serving_units!.map((v, idx) => {
                return (
                  <>
                    {idx != 0 && <b style={{ color: 'orange', fontSize: 21 }}>|</b>}
                    <label
                      style={{ color: 'dodgerblue' }}
                      onClick={() => setDefaultSizing(item, v.label, v.amount, v.default_label_qty!)}
                    >
                      {v.label}
                    </label>{' '}
                    ({v.default_label_qty}x{v.amount}g)
                  </>
                );
              })}
            </div>
            <div style={{ width: '60%' }}>
              <div>
                <b>Preparation Method</b>
              </div>
              <Form.Control
                as="select"
                className="my-1 mr-sm-2"
                defaultValue={item.preparation_method ?? 'Choose one...'}
                key={'preparation_method_' + Math.random()}
                onChange={(evt) => {
                  const newItem: MealItem = {
                    ...item,
                    preparation_method: evt.target.value as PreparationMethodEnum,
                  };
                  updateItem(item, newItem);
                }}
              >
                {[
                  'Choose one...',
                  ...['homemade', 'restaurant', 'store_bought', null],
                ].map((v, i) => <option key={v}>{v}</option>)}
              </Form.Control>
            </div>

            {item.custom_item && (
              <div>
                {
                  /* note: this <div /> is necessary to make sure left margins are correct:
                  https://github.com/mui/material-ui/issues/29221
                */
                }
                <Grid container spacing={1}>
                  {relevantNutrients.map((n, idx) => (
                    <Grid key={n.nutrient} item xs={3}>
                      <Tooltip title="Total for meal (ignoring serving count)">
                        <Stack spacing={0.5}>
                          <Typography>
                            {n.label}
                            {calculatedNutrients[n.nutrient] != null && (
                              <Typography display="inline" sx={{ color: 'text.secondary' }}>
                                &nbsp;({calculatedNutrients[n.nutrient]})
                              </Typography>
                            )}
                          </Typography>
                          {n.nutrient == 'netcarb_g'
                            ? (
                              <OutlinedInput
                                fullWidth
                                value={calcNetCarbs(draftItem?.item.custom_nutrient_estimates || {})?.toFixed(1) ?? '-'}
                                disabled={true}
                                error={(calcNetCarbs(draftItem?.item.custom_nutrient_estimates || {}) ?? 0) < 0}
                                sx={{ backgroundColor: 'white' }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <Typography>{n.suffix}</Typography>
                                  </InputAdornment>
                                }
                              />
                            )
                            : (
                              <OutlinedInput
                                fullWidth
                                tabIndex={10 + idx}
                                placeholder={calculatedNutrients[n.nutrient] ?? '-'}
                                defaultValue={item.custom_nutrient_estimates?.[n.nutrient] ?? ''}
                                disabled={selectedFoodDetails.query.isLoading}
                                onBlur={(evt) => {
                                  evt && handleNutrientChange({ [n.nutrient]: floatOrNull(evt.target.value) });
                                }}
                                sx={{ backgroundColor: 'white' }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <Typography>{n.suffix}</Typography>
                                  </InputAdornment>
                                }
                              />
                            )}
                        </Stack>
                      </Tooltip>
                    </Grid>
                  ))}
                </Grid>
              </div>
            )}
            <AddonEditor
              item={item}
              searchItem={searchItem}
              patient_id={queueItem.patient_id}
              onAddonsChange={(customAddons, legacyAddons) => {
                updateItem(item, {
                  ...item,
                  custom_addons: customAddons,
                  addons: legacyAddons,
                });
              }}
            />
            <div style={{ width: '60%' }}>
              <div>
                <b>Serving Label</b>
              </div>
              <Form.Control
                as="select"
                className="my-1 mr-sm-2"
                defaultValue={servingUnitOptions.find(element => element.label == item.serving_unit_label)
                  ? item.serving_unit_label
                  : 'Choose one...'}
                onChange={(evt) => {
                  const servingUnit = searchItem.serving_units!.find(v => v.label === evt.target.value);
                  const newItem: MealItem = {
                    ...item,
                    serving_unit_label: evt.target.value,
                  };
                  if (servingUnit) {
                    newItem.serving_unit_amount = servingUnit.amount;
                  }
                  updateItem(item, newItem);
                }}
              >
                {servingUnitOptions.map((v, i) => (
                  <option
                    key={v.label + '-' + i}
                    disabled={!!v.label.match(/^--------/)}
                  >
                    {v.label}
                  </option>
                ))}
              </Form.Control>
              <div>
                <b>Serving Options</b>
              </div>
              <Form.Control
                as="select"
                className="my-1 mr-sm-2"
                defaultValue="Choose one..."
                key={item.serving_unit_label + Math.random()}
                onChange={(evt) => {
                  const newItem: MealItem = { ...item, servings: +evt.target.value };
                  updateItem(item, newItem);
                }}
              >
                {[
                  'Choose one...',
                  ...(servingOptions?.food_sizing_options || CUSTOM_ITEM_DEFAULT_SERVING_UNITS),
                ].map((v, i) => <option key={v + '-' + i}>{v}</option>)}
              </Form.Control>
              {item.custom_item
                && (
                  <>
                    <div>
                      <b>Serving Unit Amount</b>
                    </div>
                    <ServingUnitAmountEdit item={item} />
                  </>
                )}
              <div>
                <b>Options Edit</b>
              </div>
              <ServingOptionsEdit item={item} />
              {SERVING_TYPE_ITEMS.includes(searchItem.name) && (
                <div>
                  <b>Serving Types</b>
                  <Form.Control
                    as="select"
                    className="my-1 mr-sm-2"
                    defaultValue={searchItem.serving_types!.find(element => element.label == item.serving_type_label)
                      ? String(item.serving_type_label)
                      : 'Choose one...'} // //add label
                    key={item.serving_type_label == null
                      ? 'type label' + Math.random()
                      : item.serving_type_label + Math.random()}
                    onChange={(evt) => {
                      let servingMultiplier = null;
                      if (searchItem.serving_types!.length > 0) {
                        const type_multiplier = searchItem.serving_types!.findIndex(v => v.label === evt.target.value);
                        if (type_multiplier >= 0) {
                          console.log('types matched');
                          servingMultiplier = searchItem.serving_types![type_multiplier].multiplier;
                        }
                      }
                      console.log('show mult', servingMultiplier);
                      const newItem: MealItem = {
                        ...item,
                        serving_type_label: evt.target.value,
                        serving_type_multiplier: servingMultiplier,
                      };
                      updateItem(item, newItem);
                    }}
                  >
                    {[{ label: 'Choose one... (required)' }, ...searchItem.serving_types!].map(v => (
                      <option key={v.label + Math.random()}>{v.label}</option>
                    ))}
                  </Form.Control>
                </div>
              )}
              <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                <Button
                  onClick={() => {
                    saveMealItem(id);
                  }}
                >
                  Save edits
                </Button>
              </div>
              {/* onClick={() => removeMealItem(id) */}
            </div>
            {!servingOptions?.food_sizing_options && (
              <>
                <div>
                  <b>Serving in Grams</b>
                </div>
                <div className="patientNoteField">
                  <StyledTextBox
                    type="number"
                    viewProps={{
                      className: 'servingOptionsEditField',
                    }}
                    startEditingOnEnter
                    editOnViewClick={true}
                    submitOnEnter
                    cancelOnEscape
                    value={'' + item.serving_unit_amount}
                    validation={val => !isNaN(parseFloat(val))}
                    onValidationFail={() => validationFailed()}
                    onSave={val => {
                      const newItem: MealItem = { ...item, serving_unit_amount: parseFloat(val) };
                      updateItem(item, newItem);
                    }}
                  />
                </div>
              </>
            )}
            {
              /*itemChanges && itemChanges.length > 0 && itemChangeLogs && (
              <div>
                <div>
                  <strong>Edit History</strong>
                </div>
                <MealChangeLogsTable
                  changeLogs={itemChangeLogs}
                />
              </div>
            )*/
            }
            <div>Change logs removed from legacy interface</div>
          </div>
        </Collapse>
      </div>
    );
  };

  const renderMealItems = () => {
    return [...draftItems].reverse().map((draftItem, idx) => <MealItemView key={idx} draftItem={draftItem} />);
  };

  async function addCustomFromRequest() {
    if (authInfo) {
      const customItem: MealItem = {
        addons: [],
        custom_addons: [],
        custom_item: true,
        custom_item_source: 'baseless',
        custom_item_source_id: null,
        custom_usda_id: null,
        food_name: newFoodName,
        food_replacement_name: null,
        meal_photo_id: props.queueItem.meal_photo_id,
        note: null,
        nutrition_source: null,
        serving_type_label: null,
        serving_type_multiplier: null,
        serving_unit_amount: defaultSearchItem.serving_units![0].amount,
        serving_unit_label: defaultSearchItem.serving_units![0].label,
        servings: 1,
      };

      const res = await mealApi.appApiMealPostMealItems({
        meal_id: queueItem.created_meal_id,
        patient_id: queueItem.patient_id,
        CreateMealItemRequest: [{
          extra_addons: [],
          ...customItem,
        }],
      });
      const id = res.data[0].id;
      if (!id) {
        return;
      }

      setDraftItems([...draftItems, {
        id,
        item: customItem,
        searchItem: defaultSearchItem,
        queryText: customItem.food_name,
        foodMatchDetails: null,
      }]);
      const addonDetails = (new Array(defaultSearchItem.addons!.length)).fill(false);
      setAddonDetailsOpened([...addonDetailsOpened, { searchItem: defaultSearchItem, addonDetails }]);
      addItemOpenedOthersClosed(defaultSearchItem);

      setNewFoodName('');
    }
  }

  async function sendNewFoodRequest() {
    if (newFoodName == '') {
      setNewFoodError('Please provide a food name to submit new food request.');
      return;
    }

    if (authInfo) {
      const { access_token, reviewer_id } = authInfo;
      const success = await postNewFoodRequest(
        reviewer_id,
        newFoodName,
        newFoodBrand,
        newFoodManufacturer,
        newFoodUrl,
        access_token,
      );

      if (success) {
        setShowNewFood(false);
        setNewFoodBrand('');
        setNewFoodManufacturer('');
        setNewFoodError('');
      }
    }

    addCustomFromRequest();
  }

  const renderSearchView = () => {
    if (!showSearchBox) {
      return (
        <div>
          <Button
            variant="link"
            className="newItemBtn"
            onClick={() => {
              setShowSearchBox(true);
            }}
          >
            Add new item
          </Button>
          {(draftItems.length != 0) && (
            <div>
              <br />
              <Button
                onClick={() => {
                  loadMealItems();
                }}
              >
                Reload Meal Items
              </Button>
              <br />
              <Button
                variant="link"
                className="newItemBtn"
                onClick={() => {
                  setAllMealItemsOpened(false);
                }}
              >
                hide all
              </Button>
              <Button
                variant="link"
                className="newItemBtn"
                onClick={() => {
                  setAllMealItemsOpened(true);
                }}
              >
                show all
              </Button>
            </div>
          )}
        </div>
      );
    }

    // TODO This is where the request new meal item form is (no base)
    return (
      <div style={{ paddingRight: 15 }}>
        <FoodSearchInput
          patient_id={queueItem.patient_id}
          onCancel={() => setShowSearchBox(false)}
          onItemSelect={addItem}
        />

        <div style={{ paddingLeft: '5px', paddingTop: '20px', paddingBottom: '10px' }}>
          <Button style={{ backgroundColor: 'dodgerblue' }} onClick={() => setShowNewFood(true)}>
            Request new food item
          </Button>
          <Modal show={showNewFood} onHide={() => setShowNewFood(false)}>
            <Modal.Header closeButton>
              <Modal.Title>New Food Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Enter information for new food below
              <label style={{ paddingBottom: '10px' }}>
                <br />Food Name: <input type="text" onChange={(evt) => setNewFoodName(evt.target.value)} />
                <label style={{ color: 'red', paddingLeft: '15px' }}>* required</label>
              </label>
              <label style={{ paddingBottom: '10px' }}>
                Food Brand: <input type="text" onChange={(evt) => setNewFoodBrand(evt.target.value)} />
              </label>
              <label style={{ paddingBottom: '10px' }}>
                Manufacturer: <input type="text" onChange={(evt) => setNewFoodManufacturer(evt.target.value)} />
              </label>
              <label>
                Reference URL: <input type="text" id="clinicId" onChange={(evt) => setNewFoodUrl(evt.target.value)} />
              </label>
              {newFoodError != '' && (
                <label style={{ paddingTop: '10px', color: 'red', fontSize: '14px' }}>{newFoodError}</label>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowNewFood(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => sendNewFoodRequest()}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  };

  // const removeCustomAddon = (id: number | string, name: string, item: MealItem) => {
  //   updateItem(id, item, {
  //     ...item,
  //     custom_addons: item.custom_addons?.filter(a => a.food_name != name) || [],
  //   });
  // };

  return (
    <>
      <div id="rightPane">
        <div
          style={{
            height: props.queueItem.meal_photo_id ? '800px' : '700px',
            overflow: 'auto',
            margin: '1rem',
          }}
        >
          <div className="itemBuilder">
            <HistoryView queueItem={queueItem} draftItems={draftItems} />
          </div>
          <div className="searchItemDetails itemBuilder">
            <div className="sectionTitle">
              Meal Item Review
              {props.queueItem.queue_type == 'custom' && (
                <>
                  {' '}(<span style={{ color: 'red' }}>manual log</span>)
                </>
              )}
            </div>
            {renderSearchView()}
            {renderMealItems()}
          </div>
          <div className="itemBuilder">
            <div className="sectionTitle">Meal History</div>
            {mealChangeLogsRes.query.isError && <div>{'' + mealChangeLogsRes.query.error}</div>}
            {mealChangeLogsRes.query.isLoading && <div>Loading...</div>}
            {mealChangeLogsRes.query.isSuccess && (mealChangeLogsRes.data
              ? (
                <MealChangeLogsTable
                  changeLogs={mealChangeLogsRes.data}
                  firstReviewerID={queueItem.first_reviewer_user_id}
                />
              )
              : <div>No Changes</div>)}
          </div>
        </div>
      </div>
      <div id="summary" className="summaryView">
        <div className="sectionTitle">Summary</div>
        <LegacyMealSummary
          draftItems={draftItems}
          updateDraftItemMealItem={updateDraftItemMealItem}
          removeDraftItem={removeMealItem}
          // removeReplacement={removeReplacement}
          // removeAddon={removeAddon}
          // removeCustomAddon={removeCustomAddon}
        />
      </div>
    </>
  );
};

export const HistoryView = (props: {
  queueItem: MealPhotoQueueResponse,
  draftItems: DraftItem[],
  asCard?: boolean,
  mainCardStyle?: React.CSSProperties,
}) => {
  const { queueItem, draftItems } = props;

  return (
    <span>
      {props.asCard
        ? (
          <MainCard
            className="section"
            style={{ paddingTop: 0, height: 200, overflow: 'scroll', ...(props.mainCardStyle || {}) }}
          >
            <ListMealHistoryView
              queueItem={queueItem}
              draftItems={draftItems}
            />
          </MainCard>
        )
        : (
          <div className="section" style={{ paddingTop: 0 }}>
            <ListMealHistoryView
              queueItem={queueItem}
              draftItems={draftItems}
            />
          </div>
        )}
    </span>
  );
};

export const MealQoSDetails = (props: {
  item: MealQueueItem,
  mainCardStyle?: React.CSSProperties,
}) => {
  const qosDetailsQuery = useQuery(['mealQoSDetails', props.item.id], async () => {
    const r = await dataReviewApi.appApiDataReviewerGetMealPhotoQueueItemQosDetails({
      meal_photo_queue_id: props.item.id,
    });
    const res = {
      otherItemCount: 0,
      reviewerItems: [{
        item: props.item,
        overlap_type: 'self',
        same_reviewer: true,
      }] as MealPhotoQueueQoSDetailsResponseOverlappingQueuesInner[],
    };
    r.data.overlapping_queues.forEach(q => {
      if (!q.same_reviewer) {
        res.otherItemCount += 1;
      }
      res.reviewerItems.push(q);
    });
    res.reviewerItems = _.sortBy(res.reviewerItems, q => q.item.reviewed_time);
    return res;
  }, {
    ...useQueryNeverRefetch,
  });

  const [refTime, setRefTime] = useState(null);
  const relTime = (_target: 'created_time' | 'reviewed_time' | string, time: string | null | undefined) => {
    if (!time) {
      return '–';
    }
    const m = moment(time);
    const target = refTime ?? _target;
    const diffNeg = target != 'created_time' && target != 'reviewed_time'
      ? m.diff(target, 'seconds')
      : m.diff(props.item[target], 'seconds');
    const diff = Math.abs(diffNeg);
    const hours = Math.floor(diff / 3600);
    const minutes = Math.floor((diff - hours * 3600) / 60);
    const seconds = diff - hours * 3600 - minutes * 60;
    const before = diffNeg < 0 ? ' before' : '';
    return `${hours > 0 ? hours + 'h' : ''}${minutes > 0 ? minutes + 'm' : ''}${
      ('' + seconds).padStart(2, '0')
    }s${before}`;
  };

  const abbreviateOverlapType = {
    'created_before_finished_before': 'CBFB',
    'created_before_finished_after': 'CBFA',
    'created_after_finished_before': 'CAFB',
    'created_after_finished_after': 'CAFA',
    'other': 'other',
    'self': 'self',
  };

  const columns = [
    {
      Header: 'QID',
      accessor: (row: MealPhotoQueueQoSDetailsResponseOverlappingQueuesInner) => (
        row.overlap_type != 'self' && (
          <Link to={`/queue-item/${row.item.id}`} target="_blank">
            {row.item.id}
          </Link>
        )
      ),
    },
    {
      Header: 'UID',
      accessor: (row: MealPhotoQueueQoSDetailsResponseOverlappingQueuesInner) => row.item.patient_id,
    },
    {
      Header: 'Type',
      Cell: ({ row }: any) => {
        const overlap_type: keyof typeof abbreviateOverlapType = row?.original?.overlap_type;
        if (!overlap_type) {
          return null;
        }
        return <p title={overlap_type}>{abbreviateOverlapType[overlap_type]}</p>;
      },
    },
    {
      Header: 'Created',
      accessor: (row: MealPhotoQueueQoSDetailsResponseOverlappingQueuesInner) => {
        return row.item.created_time;
      },
      Cell: (props: any) => {
        return relTime('created_time', props.value);
      },
      _cellTimeField: 'created_time',
    },
    {
      Header: 'Accessed',
      accessor: (row: MealPhotoQueueQoSDetailsResponseOverlappingQueuesInner) => {
        return row.item.first_reviewer_access_time;
      },
      Cell: (props: any) => {
        const row = props.row.original;
        return relTime(row.item.created_time, props.value);
      },

      _cellTimeField: 'first_reviewer_access_time',
    },
    {
      Header: 'Reviewed',
      accessor: (row: MealPhotoQueueQoSDetailsResponseOverlappingQueuesInner) => {
        return row.item.reviewed_time;
      },
      Cell: (props: any) => {
        return relTime('created_time', props.value);
      },

      _cellTimeField: 'reviewed_time',
    },
    {
      Header: 'Reviewer ID',
      accessor: (row: MealPhotoQueueQoSDetailsResponseOverlappingQueuesInner) => {
        return `${row.item.data_reviewer_id} ${row.same_reviewer ? ' (Current)' : ''}`;
      },
    },
  ];

  const getCellProps = (cellInfo: any) => {
    if (!cellInfo.column._cellTimeField) {
      return {};
    }
    const timeValue = cellInfo.row.original?.item?.[cellInfo.column._cellTimeField];
    if (!timeValue) {
      return {};
    }
    return {
      style: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 125,
        minWidth: 125,
      } satisfies React.CSSProperties,
      onMouseEnter: () => {
        setRefTime(timeValue);
      },
      onMouseLeave: () => {
        setRefTime(null);
      },
    };
  };

  return (
    <MainCard
      title="QoS Details"
      content={false}
      style={props.mainCardStyle}
    >
      {qosDetailsQuery.isLoading && <CardContent>Loading...</CardContent>}
      {qosDetailsQuery.isError && <CardContent>Error: {'' + qosDetailsQuery.error}</CardContent>}
      {qosDetailsQuery.data && (
        <ScrollX>
          <Stack>
            <CardContent>
              <Typography style={{ marginBottom: 10 }}>
                While this queue was active,{' '}
                <strong>{pluralize(qosDetailsQuery.data?.otherItemCount, 'queue was', 'queues were')}</strong>{' '}
                processed by other reviewers.
              </Typography>
              <Typography>
                <strong>
                  {pluralize(
                    qosDetailsQuery.data?.reviewerItems.filter(item => item.same_reviewer).length - 1,
                    'queue was',
                    'queues were',
                  )}
                </strong>{' '}
                processed by the same reviewer (note: created/reviewed times are relative to this queue's creation time,
                a queue's accessed time is relative to its own creation time):
              </Typography>
            </CardContent>
            <FilterSortTable
              columns={columns}
              data={qosDetailsQuery.data.reviewerItems}
              striped={true}
              numRows={qosDetailsQuery.data.reviewerItems.length}
              getCellProps={getCellProps}
              showColumnSort
            />
          </Stack>
        </ScrollX>
      )}
    </MainCard>
  );
};
