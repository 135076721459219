import React, { useContext, useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import {
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { Formik, useFormik } from 'formik';
import mixpanel from 'mixpanel-browser';
import * as Yup from 'yup';

import { authenticate } from 'apiClients/auth';
import { Capabilities } from 'auth-capabilities';
import AnimateButton from 'components/@extended/AnimateButton';
import IconButton from 'components/@extended/IconButton';
import { config } from 'config';
import { AppCtx } from 'context/appContext';
import AuthWrapper from 'sections/auth/AuthWrapper';

export const Login = () => {
  // tip. using inferred typing
  const { authInfo, hasAuth } = useContext(AppCtx);
  const [searchParams] = useSearchParams();
  const defaultUrl = !!authInfo && hasAuth(Capabilities.mpqItemView) ? '/' : '/foods';
  const next = searchParams.get('next') || defaultUrl;

  useEffect(() => {
    mixpanel.track('loaded Login');
  }, []);

  return (authInfo
    ? <Navigate to={next} replace />
    : (
      <AuthWrapper>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="baseline"
              sx={{ mb: { xs: -0.5, sm: 0.5 } }}
            >
              <Typography variant="h3">Login</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <AuthLogin />
          </Grid>
        </Grid>
      </AuthWrapper>
    ));
};

const AuthLogin = () => {
  const [capsWarning, setCapsWarning] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const { login, authRes } = useContext(AppCtx);
  const form = useFormik({
    initialValues: {
      email: '',
      password: '',
      submit: null,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
      password: Yup.string().max(255).required('Password is required'),
    }),
    onSubmit: (values) => login(values),
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  const onKeyDown = (keyEvent: any) => {
    if (keyEvent.getModifierState('CapsLock')) {
      setCapsWarning(true);
    } else {
      setCapsWarning(false);
    }
  };

  useEffect(() => {
    form.setStatus({ success: authRes.isDone });
    form.setErrors({ submit: authRes.isError ? '' + authRes.error : undefined });
    form.setSubmitting(authRes.isPending);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authRes]);

  return (
    <form noValidate onSubmit={form.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <InputLabel htmlFor="email-login">Email Address</InputLabel>
            <OutlinedInput
              id="email-login"
              type="email"
              value={form.values.email}
              name="email"
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              fullWidth
              error={Boolean(form.touched.email && form.errors.email)}
            />
            {form.touched.email && form.errors.email && (
              <FormHelperText error id="standard-weight-helper-text-email-login">
                {form.errors.email}
              </FormHelperText>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <InputLabel htmlFor="password-login">Password</InputLabel>
            <OutlinedInput
              fullWidth
              color={capsWarning ? 'warning' : 'primary'}
              error={Boolean(form.touched.password && form.errors.password)}
              id="-password-login"
              type={showPassword ? 'text' : 'password'}
              value={form.values.password}
              name="password"
              onBlur={(event: React.FocusEvent) => {
                setCapsWarning(false);
                form.handleBlur(event);
              }}
              onKeyDown={onKeyDown}
              onChange={form.handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    color="secondary"
                  >
                    {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {capsWarning && (
              <Typography variant="caption" sx={{ color: 'warning.main' }} id="warning-helper-text-password-login">
                Caps lock on!
              </Typography>
            )}
            {form.touched.password && form.errors.password && (
              <FormHelperText error id="standard-weight-helper-text-password-login">
                {form.errors.password}
              </FormHelperText>
            )}
          </Stack>
        </Grid>

        {form.errors.submit && (
          <Grid item xs={12}>
            <FormHelperText error>{form.errors.submit}</FormHelperText>
          </Grid>
        )}
        <Grid item xs={12}>
          <AnimateButton>
            <Button
              disableElevation
              disabled={form.isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="primary"
            >
              Login
            </Button>
          </AnimateButton>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            {config.RELEASE_LABEL}
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};
