import { useMemo } from 'react';

import { PlusSquareFilled } from '@ant-design/icons';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import type { MealItemResponse } from 'api/generated/MNT';
import type { MealItemCustomAddonResponse } from 'api/generated/MNT';
import { MealItem } from 'apiClients/mpq';
import { MealResponse } from 'apiClients/review';
import { useFoodDetailsPopup } from 'components/FoodDetailsPopup';
import _ from 'lodash';

export const RecentItems = (props: {
  items: MealItemResponse[],
  isFiltered: boolean,
  isSavePending: boolean,
  addItemFromRecent: (item: MealItemResponse, index: number) => void,
}) => {
  const [groupedItems, ungroupedItems] = useMemo(() => {
    const grouped = _.groupBy(props.items, item => item.base_food_name || '__none__');
    const groupedItems: MealItemResponse[][] = [];
    const ungroupedItems: MealItemResponse[] = [];
    for (const key in grouped) {
      if (key === '__none__' || grouped[key].length == 1) {
        ungroupedItems.push(...grouped[key]);
      } else {
        groupedItems.push(_.sortBy(grouped[key], item => item.food_name_alias ?? item.food_name));
      }
    }
    return [
      _.sortBy(groupedItems, group => group[0].base_food_name),
      _.sortBy(ungroupedItems, item => item.food_name_alias ?? item.food_name),
    ];
  }, [props.items]);

  const foodDetailsPopup = useFoodDetailsPopup();

  return (
    <div className="padded">
      <Stack spacing={0.5}>
        {groupedItems.map((group, index) => (
          <Stack key={index}>
            <details open={props.isFiltered} style={{ breakInside: 'avoid' }}>
              <summary>
                <span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
                  {group[0].base_food_name} ({group.length})
                </span>
              </summary>
              {group.map((item, index) => (
                <div
                  {...foodDetailsPopup.getShowOnHoverProps({ mealItem: item, anchorTo: 'element' })}
                  key={item.id}
                  style={{ marginLeft: 12 }}
                >
                  <ClickableItem
                    item={item}
                    isSavePending={props.isSavePending}
                    onClick={() => props.addItemFromRecent(item, index)}
                  />
                </div>
              ))}
            </details>
          </Stack>
        ))}
        {ungroupedItems.length > 0 && (
          ungroupedItems.map((item, index) => (
            <div
              {...foodDetailsPopup.getShowOnHoverProps({ mealItem: item as MealItem, anchorTo: 'element' })}
              key={item.id}
            >
              <ClickableItem
                key={item.id}
                item={item}
                isSavePending={props.isSavePending}
                onClick={() => props.addItemFromRecent(item, index)}
              />
            </div>
          ))
        )}
      </Stack>
    </div>
  );
};

const ClickableItem = (props: {
  item: MealItemResponse,
  isSavePending: boolean,
  onClick: () => void,
}) => {
  const { item } = props;
  const theme = useTheme();

  const historyAddons = (addons: string[], customAddons: MealItemCustomAddonResponse[]) => {
    const plainAddons = addons.length > 0 ? <li>{addons.join(', ')}</li> : '';
    return (
      <div>
        <ul>
          {plainAddons}
          {customAddons.map((addon, index) => (
            <li key={addon.food_name}>{addon.food_name} ({addon.servings} {addon.serving_unit_label})</li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div
      className="click"
      onClick={props.onClick}
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: props.isSavePending ? 'progress' : undefined,
        marginLeft: -5,
        paddingLeft: 5,
      }}
    >
      <PlusSquareFilled style={{ color: theme.palette.primary.main, verticalAlign: 'text-top' }} />
      {/* <FontAwesomeIcon icon={faPlusSquare} size="sm" color="dodgerblue" /> */}{' '}
      {item.food_name_alias ?? item.food_name}
      {item.custom_item && <span style={{ color: 'red' }}>*</span>}{' '}
      <span style={{ color: 'grey' }}>(size: {item.servings} {item.serving_unit_label})</span>
      {item.custom_addons && item.custom_addons.length > 0 ? historyAddons(item.addons, item.custom_addons) : ''}
    </div>
  );
};
