/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppApiMealPushQuestionGetMealPushQuestionPatientTable200Response } from '../models';
// @ts-ignore
import { MealPushQuestionResponse } from '../models';
// @ts-ignore
import { MealPushQuestionSubmitResponseRequest } from '../models';
// @ts-ignore
import { UpdateMealPushQuestionRequest } from '../models';
/**
 * MealPushQuestionsApi - axios parameter creator
 * @export
 */
export const MealPushQuestionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a meal push questions
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {number} question_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPushQuestionGetMealPushQuestion: async (patient_id: number, meal_id: number, question_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMealPushQuestionGetMealPushQuestion', 'patient_id', patient_id)
            // verify required parameter 'meal_id' is not null or undefined
            assertParamExists('appApiMealPushQuestionGetMealPushQuestion', 'meal_id', meal_id)
            // verify required parameter 'question_id' is not null or undefined
            assertParamExists('appApiMealPushQuestionGetMealPushQuestion', 'question_id', question_id)
            const localVarPath = `/meal-push-questions/patients/{patient_id}/meals/{meal_id}/push-questions/{question_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"meal_id"}}`, encodeURIComponent(String(meal_id)))
                .replace(`{${"question_id"}}`, encodeURIComponent(String(question_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary An APITable response of meal push questions
         * @param {number} patient_id 
         * @param {string} [filter] 
         * @param {number} [limit] 
         * @param {string} [offset] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPushQuestionGetMealPushQuestionPatientTable: async (patient_id: number, filter?: string, limit?: number, offset?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMealPushQuestionGetMealPushQuestionPatientTable', 'patient_id', patient_id)
            const localVarPath = `/meal-push-questions/patients/{patient_id}/table`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all push questions for a meal, ordered with the most recent first.
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPushQuestionGetMealPushQuestions: async (patient_id: number, meal_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMealPushQuestionGetMealPushQuestions', 'patient_id', patient_id)
            // verify required parameter 'meal_id' is not null or undefined
            assertParamExists('appApiMealPushQuestionGetMealPushQuestions', 'meal_id', meal_id)
            const localVarPath = `/meal-push-questions/patients/{patient_id}/meals/{meal_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"meal_id"}}`, encodeURIComponent(String(meal_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new meal push question for the meal
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {UpdateMealPushQuestionRequest} UpdateMealPushQuestionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPushQuestionPostMealPushQuestion: async (patient_id: number, meal_id: number, UpdateMealPushQuestionRequest: UpdateMealPushQuestionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMealPushQuestionPostMealPushQuestion', 'patient_id', patient_id)
            // verify required parameter 'meal_id' is not null or undefined
            assertParamExists('appApiMealPushQuestionPostMealPushQuestion', 'meal_id', meal_id)
            // verify required parameter 'UpdateMealPushQuestionRequest' is not null or undefined
            assertParamExists('appApiMealPushQuestionPostMealPushQuestion', 'UpdateMealPushQuestionRequest', UpdateMealPushQuestionRequest)
            const localVarPath = `/meal-push-questions/patients/{patient_id}/meals/{meal_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"meal_id"}}`, encodeURIComponent(String(meal_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateMealPushQuestionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a meal\'s push questions
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {number} question_id 
         * @param {UpdateMealPushQuestionRequest} UpdateMealPushQuestionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPushQuestionPutMealPushQuestion: async (patient_id: number, meal_id: number, question_id: number, UpdateMealPushQuestionRequest: UpdateMealPushQuestionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMealPushQuestionPutMealPushQuestion', 'patient_id', patient_id)
            // verify required parameter 'meal_id' is not null or undefined
            assertParamExists('appApiMealPushQuestionPutMealPushQuestion', 'meal_id', meal_id)
            // verify required parameter 'question_id' is not null or undefined
            assertParamExists('appApiMealPushQuestionPutMealPushQuestion', 'question_id', question_id)
            // verify required parameter 'UpdateMealPushQuestionRequest' is not null or undefined
            assertParamExists('appApiMealPushQuestionPutMealPushQuestion', 'UpdateMealPushQuestionRequest', UpdateMealPushQuestionRequest)
            const localVarPath = `/meal-push-questions/patients/{patient_id}/meals/{meal_id}/push-questions/{question_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"meal_id"}}`, encodeURIComponent(String(meal_id)))
                .replace(`{${"question_id"}}`, encodeURIComponent(String(question_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateMealPushQuestionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit a response to a meal push question
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {number} question_id 
         * @param {MealPushQuestionSubmitResponseRequest} MealPushQuestionSubmitResponseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPushQuestionPutMealPushQuestionSubmitResponse: async (patient_id: number, meal_id: number, question_id: number, MealPushQuestionSubmitResponseRequest: MealPushQuestionSubmitResponseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMealPushQuestionPutMealPushQuestionSubmitResponse', 'patient_id', patient_id)
            // verify required parameter 'meal_id' is not null or undefined
            assertParamExists('appApiMealPushQuestionPutMealPushQuestionSubmitResponse', 'meal_id', meal_id)
            // verify required parameter 'question_id' is not null or undefined
            assertParamExists('appApiMealPushQuestionPutMealPushQuestionSubmitResponse', 'question_id', question_id)
            // verify required parameter 'MealPushQuestionSubmitResponseRequest' is not null or undefined
            assertParamExists('appApiMealPushQuestionPutMealPushQuestionSubmitResponse', 'MealPushQuestionSubmitResponseRequest', MealPushQuestionSubmitResponseRequest)
            const localVarPath = `/meal-push-questions/patients/{patient_id}/meals/{meal_id}/push-questions/{question_id}/response`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"meal_id"}}`, encodeURIComponent(String(meal_id)))
                .replace(`{${"question_id"}}`, encodeURIComponent(String(question_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(MealPushQuestionSubmitResponseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MealPushQuestionsApi - functional programming interface
 * @export
 */
export const MealPushQuestionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MealPushQuestionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a meal push questions
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {number} question_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealPushQuestionGetMealPushQuestion(patient_id: number, meal_id: number, question_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealPushQuestionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealPushQuestionGetMealPushQuestion(patient_id, meal_id, question_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary An APITable response of meal push questions
         * @param {number} patient_id 
         * @param {string} [filter] 
         * @param {number} [limit] 
         * @param {string} [offset] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealPushQuestionGetMealPushQuestionPatientTable(patient_id: number, filter?: string, limit?: number, offset?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppApiMealPushQuestionGetMealPushQuestionPatientTable200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealPushQuestionGetMealPushQuestionPatientTable(patient_id, filter, limit, offset, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all push questions for a meal, ordered with the most recent first.
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealPushQuestionGetMealPushQuestions(patient_id: number, meal_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MealPushQuestionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealPushQuestionGetMealPushQuestions(patient_id, meal_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new meal push question for the meal
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {UpdateMealPushQuestionRequest} UpdateMealPushQuestionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealPushQuestionPostMealPushQuestion(patient_id: number, meal_id: number, UpdateMealPushQuestionRequest: UpdateMealPushQuestionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealPushQuestionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealPushQuestionPostMealPushQuestion(patient_id, meal_id, UpdateMealPushQuestionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a meal\'s push questions
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {number} question_id 
         * @param {UpdateMealPushQuestionRequest} UpdateMealPushQuestionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealPushQuestionPutMealPushQuestion(patient_id: number, meal_id: number, question_id: number, UpdateMealPushQuestionRequest: UpdateMealPushQuestionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealPushQuestionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealPushQuestionPutMealPushQuestion(patient_id, meal_id, question_id, UpdateMealPushQuestionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Submit a response to a meal push question
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {number} question_id 
         * @param {MealPushQuestionSubmitResponseRequest} MealPushQuestionSubmitResponseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealPushQuestionPutMealPushQuestionSubmitResponse(patient_id: number, meal_id: number, question_id: number, MealPushQuestionSubmitResponseRequest: MealPushQuestionSubmitResponseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealPushQuestionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealPushQuestionPutMealPushQuestionSubmitResponse(patient_id, meal_id, question_id, MealPushQuestionSubmitResponseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MealPushQuestionsApi - factory interface
 * @export
 */
export const MealPushQuestionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MealPushQuestionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a meal push questions
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {number} question_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPushQuestionGetMealPushQuestion(patient_id: number, meal_id: number, question_id: number, options?: any): AxiosPromise<MealPushQuestionResponse> {
            return localVarFp.appApiMealPushQuestionGetMealPushQuestion(patient_id, meal_id, question_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary An APITable response of meal push questions
         * @param {number} patient_id 
         * @param {string} [filter] 
         * @param {number} [limit] 
         * @param {string} [offset] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPushQuestionGetMealPushQuestionPatientTable(patient_id: number, filter?: string, limit?: number, offset?: string, order?: string, options?: any): AxiosPromise<AppApiMealPushQuestionGetMealPushQuestionPatientTable200Response> {
            return localVarFp.appApiMealPushQuestionGetMealPushQuestionPatientTable(patient_id, filter, limit, offset, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all push questions for a meal, ordered with the most recent first.
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPushQuestionGetMealPushQuestions(patient_id: number, meal_id: number, options?: any): AxiosPromise<Array<MealPushQuestionResponse>> {
            return localVarFp.appApiMealPushQuestionGetMealPushQuestions(patient_id, meal_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new meal push question for the meal
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {UpdateMealPushQuestionRequest} UpdateMealPushQuestionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPushQuestionPostMealPushQuestion(patient_id: number, meal_id: number, UpdateMealPushQuestionRequest: UpdateMealPushQuestionRequest, options?: any): AxiosPromise<MealPushQuestionResponse> {
            return localVarFp.appApiMealPushQuestionPostMealPushQuestion(patient_id, meal_id, UpdateMealPushQuestionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a meal\'s push questions
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {number} question_id 
         * @param {UpdateMealPushQuestionRequest} UpdateMealPushQuestionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPushQuestionPutMealPushQuestion(patient_id: number, meal_id: number, question_id: number, UpdateMealPushQuestionRequest: UpdateMealPushQuestionRequest, options?: any): AxiosPromise<MealPushQuestionResponse> {
            return localVarFp.appApiMealPushQuestionPutMealPushQuestion(patient_id, meal_id, question_id, UpdateMealPushQuestionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit a response to a meal push question
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {number} question_id 
         * @param {MealPushQuestionSubmitResponseRequest} MealPushQuestionSubmitResponseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPushQuestionPutMealPushQuestionSubmitResponse(patient_id: number, meal_id: number, question_id: number, MealPushQuestionSubmitResponseRequest: MealPushQuestionSubmitResponseRequest, options?: any): AxiosPromise<MealPushQuestionResponse> {
            return localVarFp.appApiMealPushQuestionPutMealPushQuestionSubmitResponse(patient_id, meal_id, question_id, MealPushQuestionSubmitResponseRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiMealPushQuestionGetMealPushQuestion operation in MealPushQuestionsApi.
 * @export
 * @interface MealPushQuestionsApiAppApiMealPushQuestionGetMealPushQuestionRequest
 */
export interface MealPushQuestionsApiAppApiMealPushQuestionGetMealPushQuestionRequest {
    /**
     * 
     * @type {number}
     * @memberof MealPushQuestionsApiAppApiMealPushQuestionGetMealPushQuestion
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof MealPushQuestionsApiAppApiMealPushQuestionGetMealPushQuestion
     */
    readonly meal_id: number

    /**
     * 
     * @type {number}
     * @memberof MealPushQuestionsApiAppApiMealPushQuestionGetMealPushQuestion
     */
    readonly question_id: number
}

/**
 * Request parameters for appApiMealPushQuestionGetMealPushQuestionPatientTable operation in MealPushQuestionsApi.
 * @export
 * @interface MealPushQuestionsApiAppApiMealPushQuestionGetMealPushQuestionPatientTableRequest
 */
export interface MealPushQuestionsApiAppApiMealPushQuestionGetMealPushQuestionPatientTableRequest {
    /**
     * 
     * @type {number}
     * @memberof MealPushQuestionsApiAppApiMealPushQuestionGetMealPushQuestionPatientTable
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof MealPushQuestionsApiAppApiMealPushQuestionGetMealPushQuestionPatientTable
     */
    readonly filter?: string

    /**
     * 
     * @type {number}
     * @memberof MealPushQuestionsApiAppApiMealPushQuestionGetMealPushQuestionPatientTable
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof MealPushQuestionsApiAppApiMealPushQuestionGetMealPushQuestionPatientTable
     */
    readonly offset?: string

    /**
     * 
     * @type {string}
     * @memberof MealPushQuestionsApiAppApiMealPushQuestionGetMealPushQuestionPatientTable
     */
    readonly order?: string
}

/**
 * Request parameters for appApiMealPushQuestionGetMealPushQuestions operation in MealPushQuestionsApi.
 * @export
 * @interface MealPushQuestionsApiAppApiMealPushQuestionGetMealPushQuestionsRequest
 */
export interface MealPushQuestionsApiAppApiMealPushQuestionGetMealPushQuestionsRequest {
    /**
     * 
     * @type {number}
     * @memberof MealPushQuestionsApiAppApiMealPushQuestionGetMealPushQuestions
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof MealPushQuestionsApiAppApiMealPushQuestionGetMealPushQuestions
     */
    readonly meal_id: number
}

/**
 * Request parameters for appApiMealPushQuestionPostMealPushQuestion operation in MealPushQuestionsApi.
 * @export
 * @interface MealPushQuestionsApiAppApiMealPushQuestionPostMealPushQuestionRequest
 */
export interface MealPushQuestionsApiAppApiMealPushQuestionPostMealPushQuestionRequest {
    /**
     * 
     * @type {number}
     * @memberof MealPushQuestionsApiAppApiMealPushQuestionPostMealPushQuestion
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof MealPushQuestionsApiAppApiMealPushQuestionPostMealPushQuestion
     */
    readonly meal_id: number

    /**
     * 
     * @type {UpdateMealPushQuestionRequest}
     * @memberof MealPushQuestionsApiAppApiMealPushQuestionPostMealPushQuestion
     */
    readonly UpdateMealPushQuestionRequest: UpdateMealPushQuestionRequest
}

/**
 * Request parameters for appApiMealPushQuestionPutMealPushQuestion operation in MealPushQuestionsApi.
 * @export
 * @interface MealPushQuestionsApiAppApiMealPushQuestionPutMealPushQuestionRequest
 */
export interface MealPushQuestionsApiAppApiMealPushQuestionPutMealPushQuestionRequest {
    /**
     * 
     * @type {number}
     * @memberof MealPushQuestionsApiAppApiMealPushQuestionPutMealPushQuestion
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof MealPushQuestionsApiAppApiMealPushQuestionPutMealPushQuestion
     */
    readonly meal_id: number

    /**
     * 
     * @type {number}
     * @memberof MealPushQuestionsApiAppApiMealPushQuestionPutMealPushQuestion
     */
    readonly question_id: number

    /**
     * 
     * @type {UpdateMealPushQuestionRequest}
     * @memberof MealPushQuestionsApiAppApiMealPushQuestionPutMealPushQuestion
     */
    readonly UpdateMealPushQuestionRequest: UpdateMealPushQuestionRequest
}

/**
 * Request parameters for appApiMealPushQuestionPutMealPushQuestionSubmitResponse operation in MealPushQuestionsApi.
 * @export
 * @interface MealPushQuestionsApiAppApiMealPushQuestionPutMealPushQuestionSubmitResponseRequest
 */
export interface MealPushQuestionsApiAppApiMealPushQuestionPutMealPushQuestionSubmitResponseRequest {
    /**
     * 
     * @type {number}
     * @memberof MealPushQuestionsApiAppApiMealPushQuestionPutMealPushQuestionSubmitResponse
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof MealPushQuestionsApiAppApiMealPushQuestionPutMealPushQuestionSubmitResponse
     */
    readonly meal_id: number

    /**
     * 
     * @type {number}
     * @memberof MealPushQuestionsApiAppApiMealPushQuestionPutMealPushQuestionSubmitResponse
     */
    readonly question_id: number

    /**
     * 
     * @type {MealPushQuestionSubmitResponseRequest}
     * @memberof MealPushQuestionsApiAppApiMealPushQuestionPutMealPushQuestionSubmitResponse
     */
    readonly MealPushQuestionSubmitResponseRequest: MealPushQuestionSubmitResponseRequest
}

/**
 * MealPushQuestionsApi - object-oriented interface
 * @export
 * @class MealPushQuestionsApi
 * @extends {BaseAPI}
 */
export class MealPushQuestionsApi extends BaseAPI {
    /**
     * 
     * @summary Gets a meal push questions
     * @param {MealPushQuestionsApiAppApiMealPushQuestionGetMealPushQuestionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealPushQuestionsApi
     */
    public appApiMealPushQuestionGetMealPushQuestion(requestParameters: MealPushQuestionsApiAppApiMealPushQuestionGetMealPushQuestionRequest, options?: AxiosRequestConfig) {
        return MealPushQuestionsApiFp(this.configuration).appApiMealPushQuestionGetMealPushQuestion(requestParameters.patient_id, requestParameters.meal_id, requestParameters.question_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary An APITable response of meal push questions
     * @param {MealPushQuestionsApiAppApiMealPushQuestionGetMealPushQuestionPatientTableRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealPushQuestionsApi
     */
    public appApiMealPushQuestionGetMealPushQuestionPatientTable(requestParameters: MealPushQuestionsApiAppApiMealPushQuestionGetMealPushQuestionPatientTableRequest, options?: AxiosRequestConfig) {
        return MealPushQuestionsApiFp(this.configuration).appApiMealPushQuestionGetMealPushQuestionPatientTable(requestParameters.patient_id, requestParameters.filter, requestParameters.limit, requestParameters.offset, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all push questions for a meal, ordered with the most recent first.
     * @param {MealPushQuestionsApiAppApiMealPushQuestionGetMealPushQuestionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealPushQuestionsApi
     */
    public appApiMealPushQuestionGetMealPushQuestions(requestParameters: MealPushQuestionsApiAppApiMealPushQuestionGetMealPushQuestionsRequest, options?: AxiosRequestConfig) {
        return MealPushQuestionsApiFp(this.configuration).appApiMealPushQuestionGetMealPushQuestions(requestParameters.patient_id, requestParameters.meal_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new meal push question for the meal
     * @param {MealPushQuestionsApiAppApiMealPushQuestionPostMealPushQuestionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealPushQuestionsApi
     */
    public appApiMealPushQuestionPostMealPushQuestion(requestParameters: MealPushQuestionsApiAppApiMealPushQuestionPostMealPushQuestionRequest, options?: AxiosRequestConfig) {
        return MealPushQuestionsApiFp(this.configuration).appApiMealPushQuestionPostMealPushQuestion(requestParameters.patient_id, requestParameters.meal_id, requestParameters.UpdateMealPushQuestionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a meal\'s push questions
     * @param {MealPushQuestionsApiAppApiMealPushQuestionPutMealPushQuestionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealPushQuestionsApi
     */
    public appApiMealPushQuestionPutMealPushQuestion(requestParameters: MealPushQuestionsApiAppApiMealPushQuestionPutMealPushQuestionRequest, options?: AxiosRequestConfig) {
        return MealPushQuestionsApiFp(this.configuration).appApiMealPushQuestionPutMealPushQuestion(requestParameters.patient_id, requestParameters.meal_id, requestParameters.question_id, requestParameters.UpdateMealPushQuestionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submit a response to a meal push question
     * @param {MealPushQuestionsApiAppApiMealPushQuestionPutMealPushQuestionSubmitResponseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealPushQuestionsApi
     */
    public appApiMealPushQuestionPutMealPushQuestionSubmitResponse(requestParameters: MealPushQuestionsApiAppApiMealPushQuestionPutMealPushQuestionSubmitResponseRequest, options?: AxiosRequestConfig) {
        return MealPushQuestionsApiFp(this.configuration).appApiMealPushQuestionPutMealPushQuestionSubmitResponse(requestParameters.patient_id, requestParameters.meal_id, requestParameters.question_id, requestParameters.MealPushQuestionSubmitResponseRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
