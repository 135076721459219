import { formatNumber } from 'food-editor/utils/utils';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { useAsyncResult } from 'react-use-async-result';
import { FoodSearchResult } from '../client-search/food-search-index';
import { useFoodSearchIndex } from '../client-search/useFoodSearchIndex';

export const FoodSearchTest = () => {
  const searchIndexRes = useFoodSearchIndex();
  const searchResults = ['flexi', 'elastic', 'fuse'].map(engine => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return { engine, res: useAsyncResult<FoodSearchResult>() };
  });

  if (searchIndexRes.isError) {
    return <div>Error loading search index: {'' + searchIndexRes.error}</div>;
  }

  if (!searchIndexRes.isDone) {
    return <div>Loading...</div>;
  }

  const searchIndex = searchIndexRes.result;

  const doSearch = (query: string) => {
    searchResults.forEach(res => {
      if (!searchIndex.enabledEngines.includes(res.engine as any)) {
        return;
      }
      res.res.bind(searchIndex.search(res.engine as any, query));
    });
  };

  return (
    <Container style={{ marginTop: 20 }}>
      <InputGroup>
        <Form.Control
          type="text"
          autoFocus
          placeholder="Search"
          onChange={evt => doSearch(evt.target.value)}
        />
        <Button variant="danger" onClick={() => searchIndexRes.reload()}>Rebuild Index (for testing)</Button>
      </InputGroup>
      <div>
        Search index: {searchIndexRes.loadState.state} / {formatNumber(searchIndexRes.indexSize)} items<br />
        <code>{JSON.stringify(searchIndex.metrics)}</code>
        {searchIndexRes.loadState.isError && ' ' + searchIndexRes.loadState.error}
      </div>
      <Row>
        {searchResults.map(r => (
          <Col key={r.engine}>
            <h3 style={{ marginTop: 10, marginBottom: 0 }}>
              <code>{r.engine}</code> Results
            </h3>
            {r.res.isError && <div>Error searching: {'' + r.res.error}</div>}
            {r.res.isPending && <div>Loading...</div>}
            {r.res.isDone && (
              <>
                <small>
                  <code>{JSON.stringify(r.res.result.metrics)}</code>
                </small>
                <ul style={{ marginTop: 10 }}>
                  {r.res.result.items.map(item => <li key={item.id}>{item.name}</li>)}
                </ul>
              </>
            )}
          </Col>
        ))}
      </Row>
    </Container>
  );
};
