import { FoodEditorAttributions } from 'food-editor/types';

export default (
  foodEditorAttributions: FoodEditorAttributions,
  foodStatus?: string,
  reviewer_id?: number,
) => {
  if (!reviewer_id || !foodStatus) {
    return;
  }
  if (
    foodStatus === 'triaged' && foodEditorAttributions.scrapedByUserId
    && foodEditorAttributions.scrapedByUserId !== reviewer_id
    && !foodEditorAttributions.scrapedTime
  ) {
    return `${foodEditorAttributions.scrapedByUserId} was assigned to scrape this food`;
  }
  if (
    foodStatus === 'under_review' && foodEditorAttributions.reviewedByUserId
    && foodEditorAttributions.reviewedByUserId !== reviewer_id
    && !foodEditorAttributions.reviewedTime
  ) {
    return `${foodEditorAttributions.reviewedByUserId} was assigned to review this food`;
  }
};
