export const CUSTOM_ITEM_SERVING_UNITS = [
  { label: 'kg' },
  { label: 'ml' },
  { label: 'tsp' },
  { label: 'tbsp' },
  { label: 'bowl' },
  { label: 'small' },
  { label: 'medium' },
  { label: 'large' },
  { label: 'cup' },
  { label: 'pinch' },
  { label: 'bar' },
  { label: 'unit' },
  { label: 'ounce' },
  { label: 'pint' },
  { label: 'bottle' },
  { label: 'medium can' },
  { label: 'small can' },
  { label: 'medium glass' },
  { label: 'small glass' },
  { label: 'large glass' },
  { label: 'package' },
  { label: 'pill' },
  { label: 'stick' },
  { label: 'each' },
  { label: 'piece' },
];

export const CUSTOM_ITEM_DEFAULT_SERVING_UNITS = [
  '0.25',
  '0.5',
  '0.75',
  '1',
  '1.25',
  '1.5',
  '1.75',
  '2',
  '5',
  '10',
];
