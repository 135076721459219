import { useTheme } from '@mui/material';
import { useSocketIOPresenceActiveUsers } from 'socketio/SocketIOService';

export const UserPresenceStateBadge = (props: {
  userId: string | number,
  hideOffline?: boolean,
}) => {
  const theme = useTheme();
  const { activeUsersById, isConnected } = useSocketIOPresenceActiveUsers();
  const isOnline = !!activeUsersById[props.userId];

  if (!isConnected) {
    return null;
  }

  if (props.hideOffline && !isOnline) {
    return null;
  }

  return (
    <div
      style={{
        marginLeft: '0.15rem',
        display: 'inline-block',
        width: '0.5rem',
        height: '0.5rem',
        borderRadius: '50%',
        marginBottom: '0.05rem',
        backgroundColor: isOnline ? theme.palette.success.main : theme.palette.secondary.main,
      }}
      title={isOnline ? 'Online' : 'Offline'}
    />
  );
};
