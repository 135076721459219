import { UsdaMeasure } from 'api/generated/MNT';
import { ccFoodEditorValues } from 'food-editor/api-client';
import { getComponentsTotalWeight } from 'food-editor/components/FoodComponentBuilder';
import { checkFoodEditorFieldRelationship } from 'food-editor/food-editor-field-relationship';
import { FoodEditorNutrientValue } from 'food-editor/food-editor-nutrient-fields';
import { FoodEditorFoodRequest, FoodEditorValue } from '../types';

const getNonNegativeFloatOrNull = (value: string | null): number | null => {
  if (value == null) {
    return null;
  }

  const floatValue = parseFloat(value);
  if (!Number.isNaN(floatValue) && floatValue >= 0) {
    return floatValue;
  }
  return null;
};

const overrideValuesToFoodRequest = (
  overrideValues: Record<string, any> | null,
): Record<string, any> | null => {
  if (!overrideValues) {
    return null;
  }

  const tempCcOverrideRequest: Record<string, any>[] = [];
  Object.keys(overrideValues).forEach(key => {
    if (Object.keys(ccFoodEditorValues).includes(key)) {
      tempCcOverrideRequest.push(
        ccFoodEditorValues[key as keyof typeof ccFoodEditorValues].toApiRequest(overrideValues),
      );
    }
  });

  return {
    ccOverrideValues: tempCcOverrideRequest.reduce((acc, val) => {
      return { ...acc, ...val };
    }, {}),
    mdcOverrideValues: overrideValues.mixedDishComponents,
  };
};

export default (foodEditorValue: FoodEditorValue): FoodEditorFoodRequest => {
  const nutrientValue = (rawValue: FoodEditorNutrientValue): number | null => {
    return rawValue;
  };
  const stringIDToNumID = (rawValue: string | null): number | null => {
    if (!rawValue) {
      return rawValue === '0' ? 0 : null;
    }
    return +rawValue;
  };

  const checkFoodMeasures = (foodEditorValue: FoodEditorValue): UsdaMeasure[] => {
    const measures = foodEditorValue.measures;
    if (!measures.some((measure) => measure.label === 'gram' || measure.label === 'g')) {
      const adultServing = getNonNegativeFloatOrNull(foodEditorValue.adultServingSizeG);

      const gramMeasure: UsdaMeasure = {
        label: 'gram',
        eqv: (adultServing && adultServing <= 1000) ? adultServing : 1,
        qty: (adultServing && adultServing <= 1000) ? adultServing : 1,
        root_label: '',
        addon: false,
        ranking: null,
      };

      measures.push(gramMeasure);
    }

    return measures;
  };

  return {
    definitionType: foodEditorValue.definitionType,
    ndbNumber: foodEditorValue.ndbNumber,
    frenchTerm: foodEditorValue.frenchTerm,
    nutritionSourceId: foodEditorValue.nutritionSourceId,
    status: foodEditorValue.status,
    foodImageUrl: foodEditorValue.foodImageUrl,
    ontologyLevels: foodEditorValue.ontologyLevels,
    baseFoodName: foodEditorValue.baseFoodName || null,
    rootFoodName: foodEditorValue.rootFoodName || null,
    usdaNutrientName: foodEditorValue.usdaNutrientName || null,
    otherNames: foodEditorValue.otherNames,
    synonyms: foodEditorValue.synonyms,
    singularTerm: foodEditorValue.singularTerm || null,
    pluralTerm: foodEditorValue.pluralTerm || null,
    customTip: foodEditorValue.customTip || null,
    nutritionSourceUrl: foodEditorValue.nutritionSourceUrl || null,
    fdcid: stringIDToNumID(foodEditorValue.fdcid),
    cnFoodId: stringIDToNumID(foodEditorValue.cnFoodId),
    notes: foodEditorValue.notes || null,
    preferredAddOn: foodEditorValue.preferredAddOn,

    // CC
    wholeFood: foodEditorValue.processedType === 'whole_food',
    wholeGrains: foodEditorValue.grainType === 'whole',
    grains: foodEditorValue.grainType !== '',
    beverage: foodEditorValue.beverageType !== '' && checkFoodEditorFieldRelationship('beverageType', foodEditorValue),
    sugaryBeverage: foodEditorValue.beverageType === 'sugary'
      && checkFoodEditorFieldRelationship('beverageType', foodEditorValue),
    redMeat: (
      foodEditorValue.proteinType.includes('red_meat')
      || foodEditorValue.proteinType.includes('pork')
    ),
    meat: foodEditorValue.proteinType.includes('meat')
      || foodEditorValue.proteinType.includes('red_meat'),
    poultry: foodEditorValue.proteinType.includes('poultry'),
    pork: foodEditorValue.proteinType.includes('pork'),
    protein: foodEditorValue.proteinType.length > 0,
    nonMeatAnimalProtein: foodEditorValue.proteinType.includes('non_meat_animal'),
    fish: foodEditorValue.proteinType.includes('fish'),
    shellfish: foodEditorValue.proteinType.includes('shellfish'),
    processed: foodEditorValue.processedType === 'processed',
    ultraProcessed: foodEditorValue.processedType === 'ultra_processed',
    vegetable: foodEditorValue.fruitAndVegetableType === 'vegetable'
      || foodEditorValue.fruitAndVegetableType === 'both',
    fruit: foodEditorValue.fruitAndVegetableType === 'fruit'
      || foodEditorValue.fruitAndVegetableType === 'both',
    fried: foodEditorValue.fried,
    legume: foodEditorValue.proteinType.includes('legume'),
    highCalcium: foodEditorValue.highCalcium,
    highFibre: foodEditorValue.highFibre,
    complexCarbs: foodEditorValue.carbohydrateType === 'complex',
    simpleCarbs: foodEditorValue.carbohydrateType === 'simple',
    supplement: foodEditorValue.supplement,
    unhealthyFat: foodEditorValue.fatType === 'unhealthy'
      || foodEditorValue.fatType === 'both',
    healthyFat: foodEditorValue.fatType === 'healthy'
      || foodEditorValue.fatType === 'both',
    plantProtein: foodEditorValue.proteinType.includes('plant'),
    nutsOrSeeds: foodEditorValue.nutsOrSeeds,
    plantBased: foodEditorValue.plantBased,
    plantBasedWhole: foodEditorValue.plantBased && foodEditorValue.processedType === 'whole_food',
    raw: foodEditorValue.preparationType === 'raw',
    cooked: foodEditorValue.preparationType === 'cooked',
    additives: foodEditorValue.additives,
    glutenFree: foodEditorValue.glutenFree,
    omegaThree: foodEditorValue.omegaThree,

    suggestedItem: foodEditorValue.suggestedItem,
    // ultra processed
    rankingUp: foodEditorValue.rankingUp === null || !checkFoodEditorFieldRelationship('rankingUp', foodEditorValue)
      ? null
      : parseInt(foodEditorValue.rankingUp, 10),
    // plant based
    rankingPb: foodEditorValue.rankingPb === null || !checkFoodEditorFieldRelationship('rankingPb', foodEditorValue)
      ? null
      : parseInt(foodEditorValue.rankingPb, 10),
    rankingHealthyFat: foodEditorValue.rankingHealthyFat === null
        || !checkFoodEditorFieldRelationship('rankingHealthyFat', foodEditorValue)
      ? null
      : parseInt(foodEditorValue.rankingHealthyFat, 10),
    rankingUnhealthyFat: foodEditorValue.rankingUnhealthyFat === null
        || !checkFoodEditorFieldRelationship('rankingUnhealthyFat', foodEditorValue)
      ? null
      : parseInt(foodEditorValue.rankingUnhealthyFat, 10),
    rankingBothFat:
      foodEditorValue.rankingBothFat === null || !checkFoodEditorFieldRelationship('rankingBothFat', foodEditorValue)
        ? null
        : parseInt(foodEditorValue.rankingBothFat, 10),
    rankingWholeGrains: foodEditorValue.rankingWholeGrains === null
        || !checkFoodEditorFieldRelationship('rankingWholeGrains', foodEditorValue)
      ? null
      : parseInt(foodEditorValue.rankingWholeGrains, 10),
    rankingRefinedGrains: foodEditorValue.rankingRefinedGrains === null
        || !checkFoodEditorFieldRelationship('rankingRefinedGrains', foodEditorValue)
      ? null
      : parseInt(foodEditorValue.rankingRefinedGrains, 10),
    rankingFruit:
      foodEditorValue.rankingFruit === null || !checkFoodEditorFieldRelationship('rankingFruit', foodEditorValue)
        ? null
        : parseInt(foodEditorValue.rankingFruit, 10),
    rankingVegetable: foodEditorValue.rankingVegetable === null
        || !checkFoodEditorFieldRelationship('rankingVegetable', foodEditorValue)
      ? null
      : parseInt(foodEditorValue.rankingVegetable, 10),
    rankingLowGi:
      foodEditorValue.rankingLowGi === null || !checkFoodEditorFieldRelationship('rankingLowGi', foodEditorValue)
        ? null
        : parseInt(foodEditorValue.rankingLowGi, 10),
    rankingHighGi:
      foodEditorValue.rankingHighGi === null || !checkFoodEditorFieldRelationship('rankingHighGi', foodEditorValue)
        ? null
        : parseInt(foodEditorValue.rankingHighGi, 10),
    rankingLowK:
      foodEditorValue.rankingLowK === null || !checkFoodEditorFieldRelationship('rankingLowK', foodEditorValue)
        ? null
        : parseInt(foodEditorValue.rankingLowK, 10),
    rankingHighK:
      foodEditorValue.rankingHighK === null || !checkFoodEditorFieldRelationship('rankingHighK', foodEditorValue)
        ? null
        : parseInt(foodEditorValue.rankingHighK, 10),
    similarItems: foodEditorValue.similarItems,
    connectedItems: foodEditorValue.connectedItems,
    compoundingWords: foodEditorValue.compoundingWords,
    dataSource: foodEditorValue.dataSource || null,
    giLevel: foodEditorValue.giLevel || null,
    giComments: foodEditorValue.giComments || null,
    potassiumLevel: foodEditorValue.potassiumLevel || null,
    potassiumComments: foodEditorValue.potassiumComments || null,
    suggestedServingCount: getNonNegativeFloatOrNull(
      foodEditorValue.suggestedServingCount,
    ),
    suggestedServingUnitLabel: foodEditorValue.suggestedServingUnitLabel || null,
    suggestedServingAmountG: getNonNegativeFloatOrNull(
      foodEditorValue.suggestedServingAmountG,
    ),
    adultServingSizeG: getNonNegativeFloatOrNull(
      foodEditorValue.adultServingSizeG,
    ),
    ghgEquivalentKg: getNonNegativeFloatOrNull(foodEditorValue.ghgEquivalentKg),
    manufacturer: foodEditorValue.manufacturer || null,

    energyKcal: nutrientValue(foodEditorValue.energyKcal),
    alcoholG: nutrientValue(foodEditorValue.alcoholG),
    fatG: nutrientValue(foodEditorValue.fatG),
    transFatG: nutrientValue(foodEditorValue.transFatG),
    satFatG: nutrientValue(foodEditorValue.satFatG),
    monoFatG: nutrientValue(foodEditorValue.monoFatG),
    polyFatG: nutrientValue(foodEditorValue.polyFatG),
    proteinG: nutrientValue(foodEditorValue.proteinG),
    carbohydrateG: nutrientValue(foodEditorValue.carbohydrateG),
    fibreG: nutrientValue(foodEditorValue.fibreG),
    polyolsG: nutrientValue(foodEditorValue.polyolsG),
    netcarbG: nutrientValue(foodEditorValue.netcarbG),
    calciumMg: nutrientValue(foodEditorValue.calciumMg),
    ironMg: nutrientValue(foodEditorValue.ironMg),
    magnesiumMg: nutrientValue(foodEditorValue.magnesiumMg),
    phosphorusMg: nutrientValue(foodEditorValue.phosphorusMg),
    potassiumMg: nutrientValue(foodEditorValue.potassiumMg),
    sodiumMg: nutrientValue(foodEditorValue.sodiumMg),
    zincMg: nutrientValue(foodEditorValue.zincMg),
    thiaminMg: nutrientValue(foodEditorValue.thiaminMg),
    riboflavinMg: nutrientValue(foodEditorValue.riboflavinMg),
    niacinMg: nutrientValue(foodEditorValue.niacinMg),
    folateDfeUg: nutrientValue(foodEditorValue.folateDfeUg),
    cholesterolMg: nutrientValue(foodEditorValue.cholesterolMg),
    vitaminAIu: nutrientValue(foodEditorValue.vitaminAIu),
    vitaminB6Mg: nutrientValue(foodEditorValue.vitaminB6Mg),
    vitaminB12Ug: nutrientValue(foodEditorValue.vitaminB12Ug),
    vitaminCMg: nutrientValue(foodEditorValue.vitaminCMg),
    vitaminDIu: nutrientValue(foodEditorValue.vitaminDIu),
    vitaminEMg: nutrientValue(foodEditorValue.vitaminEMg),
    vitaminKUg: nutrientValue(foodEditorValue.vitaminKUg),
    addedSugarsG: nutrientValue(foodEditorValue.addedSugarsG),
    sugarsG: nutrientValue(foodEditorValue.sugarsG),
    caffeineMg: nutrientValue(foodEditorValue.caffeineMg),
    cholineMg: nutrientValue(foodEditorValue.cholineMg),
    alcoholPercent: nutrientValue(foodEditorValue.alcoholPercent),

    gramsPerCubicInch: nutrientValue(foodEditorValue.gramsPerCubicInch),
    insolubleDietaryFiberG: nutrientValue(foodEditorValue.insolubleDietaryFiberG),
    omegaThreeFattyAcidsG: nutrientValue(foodEditorValue.omegaThreeFattyAcidsG),
    pantothenicAcidMg: nutrientValue(foodEditorValue.pantothenicAcidMg),
    seleniumMcg: nutrientValue(foodEditorValue.seleniumMcg),
    solubleDietaryFiberG: nutrientValue(foodEditorValue.solubleDietaryFiberG),
    vitaminARaeUg: nutrientValue(foodEditorValue.vitaminARaeUg),
    vitaminD2d3Ug: nutrientValue(foodEditorValue.vitaminD2d3Ug),
    waterG: nutrientValue(foodEditorValue.waterG),

    mixedDishComponents: foodEditorValue.mixedDishComponents,
    measures: checkFoodMeasures(foodEditorValue),
    components: foodEditorValue.definitionType === 'composite' ? foodEditorValue.components : null,
    overrideValues: overrideValuesToFoodRequest(foodEditorValue.overrideValues),
    disabled: foodEditorValue.disabled,
    optionCollectionId: foodEditorValue.optionCollection?.id || null,
    optionValuesRaw: foodEditorValue.optionValuesRaw,

    listedServingCount: foodEditorValue.definitionType === 'composite'
      ? getNonNegativeFloatOrNull(foodEditorValue.listedServingCount)
      : null,
    listedServingUnitLabel: foodEditorValue.definitionType === 'composite'
      ? foodEditorValue.listedServingUnitLabel || null
      : null,
    listedServingAmountG: foodEditorValue.definitionType === 'composite'
      ? getComponentsTotalWeight(foodEditorValue.components) || null
      : null,
  };
};
