import { useState } from 'react';

// material-ui
import ClearIcon from '@mui/icons-material/Clear';

import { IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material';

// assets
import { LogoutOutlined } from '@ant-design/icons';
import { useFoodSearchIndex } from 'client-search/useFoodSearchIndex';
import { getLocalStorageStats } from 'components/LocalStoragePopup';
import { useFeatures } from 'context/FeatureContext';
import { formatNumber, formatPct } from 'food-editor/utils/utils';
import { features } from 'process';
import React from 'react';
import { useBrowserNotifications } from 'services/BrowserNotificationService';
import { useSocketTelemetryData } from 'socketio/SocketIOService';
import { humanFileSize } from 'utils/numerical';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

interface Props {
  handleLogout: () => void;
}

const ProfileTab = ({ handleLogout }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
    setSelectedIndex(index);
  };
  const notifications = useBrowserNotifications();
  const flags = useFeatures();

  const searchIndexRes = useFoodSearchIndex();
  const networkStatus = useSocketTelemetryData();

  const localStorageStats = React.useMemo(() => {
    return getLocalStorageStats();
  }, []);

  const getNetworkSecondaryText = () => {
    if (!networkStatus.rttTotal) {
      return null;
    }

    return (
      <span>
        {[
          ['avg', networkStatus.rttMean],
          ['p90', networkStatus.rttp90],
          ['err', networkStatus.rttErrorRate],
          ['count', networkStatus.rttTotal],
        ].map(([lbl, val]) => {
          return `${lbl}: ${lbl != 'err' ? formatNumber(val as number, 0) : formatPct(val as number, 0)}`;
        }).join(' / ')}
      </span>
    );
  };

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      <ListItemButton>
        <ListItemText
          primary={networkStatus.rttLatest
            ? `Latency: ${networkStatus.rttLatest} ms`
            : !networkStatus.rttTotal
            ? 'Connecting...'
            : networkStatus.rttLatest === null
            ? 'Connected'
            : `Disconnected`}
          secondary={getNetworkSecondaryText()}
        />
      </ListItemButton>

      <ListItemButton
        onClick={() => {
          if (notifications.permission === 'granted') {
            notifications.notify('Test notification');
          } else {
            notifications.requestPermission();
          }
        }}
      >
        <ListItemText
          primary={`Notifications: ${notifications.permission} ${!flags.notification_sound ? '(sound disabled)' : ''}`}
          secondary={notifications.sound.isError
            ? `Sound: ${notifications.sound.error}`
            : !notifications.sound.isDone
            ? `Sound: ${notifications.sound.state}`
            : notifications.lastTitle
            ? `Last: ${notifications.lastTitle}`
            : null}
        />
      </ListItemButton>

      <ListItemButton
        onClick={() => {
          alert([
            `Top keys:`,
            ...localStorageStats.keysAndSizes
              .slice(0, 10)
              .map(({ key, size }) => `${key} (${humanFileSize(size)})`),
          ].join('\n'));
        }}
      >
        <ListItemText
          primary={`Local storage: ${humanFileSize(localStorageStats.total)}`}
        />
      </ListItemButton>

      <ListItemButton
        onClick={() => {
          const res = confirm(`Reload search index?`);
          if (res) {
            searchIndexRes.reload();
          }
        }}
      >
        <ListItemText
          primary={
            <>
              Search index: {searchIndexRes.loadState.state} ({formatNumber(searchIndexRes.indexSize)} items)<br />
              {searchIndexRes.loadState.isError && ' ' + searchIndexRes.loadState.error}
            </>
          }
        />
      </ListItemButton>

      <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};

export default ProfileTab;
