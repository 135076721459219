import { SearchItem } from 'apiClients/search';
import { SERVING_TYPE_ITEMS } from '../../constants';
import { FoodComponent, FoodEditorValue } from '../types';

export const searchItemToFoodComponent = (searchItem: SearchItem, parentFood: FoodEditorValue): FoodComponent => ({
  component_addons: [],
  food_name: searchItem.name,
  food_id: searchItem.rxfood_id || '',
  parent_food_id: parentFood.ndbNumber,
  parent_food_name: parentFood.term,
  serving_unit_amount: (typeof searchItem.serving_units !== 'undefined' && searchItem?.serving_types !== null)
    ? searchItem.serving_units[0].amount
    : 1,
  serving_unit_label: (typeof searchItem.serving_units !== 'undefined' && searchItem?.serving_types !== null)
    ? searchItem.serving_units[0].label
    : 'gram',
  servings: (typeof searchItem.serving_units !== 'undefined' && searchItem?.serving_types !== null)
    ? (typeof searchItem.serving_units[0].default_label_qty !== 'undefined'
      ? searchItem.serving_units[0].default_label_qty
      : 1)
    : 1,
  searchItem: searchItem,
});
