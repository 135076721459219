import { dataReviewApi } from 'api';
import { type MealHistoryResponse, MealItemCustomAddonResponse, PreparationMethodEnum } from 'api/generated/MNT';
import { config } from 'config';

export type MealHistory = {
  meals: [
    {
      id: number,
      meal_date: string, // "2021-02-24",
      meal_items: MealHistoryItem[],
      meal_name: string,
      meal_time: string,
      patient_id: string,
    },
  ],
};

// One item from meal history object
export type MealHistoryItem = {
  addons: string[],
  audio_photo_id: string,
  carbohydrate_g: number,
  created_time: string,
  custom_item: boolean,
  food_name: string,
  food_photo_url: string | null,
  food_replacement_name: string | null,
  glycemic_load: string | null,
  grams_per_cubic_inch: number,
  id: number,
  meal_audio_url: string | null,
  meal_nlp_phrase: string | null,
  meal_photo_id: number,
  meal_photo_url: string | null,
  note: string | null,
  nutrition_response: string | null,
  original_meal_photo_url: string,
  patient_id: string,
  preparation_method?: PreparationMethodEnum,
  serving_type_label?: string | null,
  serving_type_multiplier: number | null,
  serving_unit_amount: number,
  serving_unit_label: string,
  servings: number,
  similar_items: string | null,
  custom_addons: MealItemCustomAddonResponse[] | null,
  updated_time: string,
};

export const getMealHistory = async (
  group_id: number,
  reviewer_id: number,
  mpq_id: number,
): Promise<MealHistoryResponse> => {
  const resp = await dataReviewApi.appApiDataReviewerGetMealItemsHistory({
    data_reviewer_group_id: group_id,
    data_reviewer_id: reviewer_id,
    meal_photo_queue_id: mpq_id,
    num_days: 30,
  });

  return resp.data.meal_history;
};

export const getMealHxPicture = async (photo_id: number, access_token: string) => {
  const src = `${config.API_URL}/api/meal_photos/${photo_id}/photo`;
  const response = await fetch(src, {
    headers: {
      'Authorization': `Bearer ${access_token}`,
    },
    method: 'GET',
  });

  if (response.status !== 200) {
    throw new Error(`${response.status}`);
  }
  const data = await response.blob();
  return data;
};
