/* eslint-disable react/jsx-key */
import React, { useMemo, useState } from 'react';

import { Box, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import { HeaderSort, TablePagination } from 'components/third-party/ReactTable';
import { DefaultColumnFilter, GlobalFilter, renderFilterTypes } from 'utils/react-table';

export const FilterSortTable = ({
  columns,
  data,
  striped,
  rowClickHandler,
  summaryRow,
  getRowProps,
  getCellProps,
  showGlobalFilter = false,
  showColumnFilter = false,
  showColumnSort = false,
  showPagination = false,
  numRows = 10,
  rowEnterHandler,
  rowLeaveHandler,
}: any) => {
  const filterTypes = useMemo(() => renderFilterTypes, []);
  const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);
  const initialState = useMemo(() => ({
    pageIndex: 0,
    pageSize: numRows,
    filters: [{ id: 'status', value: '' }],
  }), [numRows]);
  const getSortProps = (column: any) => column.getSortByToggleProps();
  showPagination = showPagination || data.length > numRows;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    // @ts-ignore
    page,
    prepareRow,
    // @ts-ignore
    state: { globalFilter, pageIndex, pageSize },
    // @ts-ignore
    preGlobalFilteredRows,
    // @ts-ignore
    setGlobalFilter,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      defaultColumn,
      // @ts-ignore
      initialState,
      filterTypes,
      autoResetPage: false,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
  );

  const table = (
    <Table {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup: any) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              showColumnSort
                ? (
                  <TableCell
                    {...column.getHeaderProps([
                      { className: column.className },
                      getSortProps(column),
                    ])}
                  >
                    <HeaderSort column={column} />
                  </TableCell>
                )
                : (
                  <TableCell
                    {...column.getHeaderProps([
                      { className: column.className },
                    ])}
                  >
                    {column.render('Header')}
                  </TableCell>
                )
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()} {...(striped && { className: 'striped' })}>
        {showColumnFilter && headerGroups.map((group: any) => (
          <TableRow {...group.getHeaderGroupProps()}>
            {group.headers.map((column: any) => (
              <TableCell {...column.getHeaderProps([{ className: column.className }])}>
                {column.canFilter ? column.render('Filter') : null}
              </TableCell>
            ))}
          </TableRow>
        ))}

        {page.map((row: any, i: number) => {
          prepareRow(row);
          const rowProps = getRowProps ? getRowProps(row, row.getRowProps()) : row.getRowProps();
          const _getCellProps = (cell: any, cellProps: any) => {
            return getCellProps ? getCellProps(cell, cellProps) : cellProps;
          };
          return (
            <TableRow
              {...rowProps}
              {...(rowClickHandler && { onClick: () => rowClickHandler(row.original) })}
              {...(rowEnterHandler && { onMouseEnter: () => rowEnterHandler(row.original) })}
              {...(rowLeaveHandler && { onMouseLeave: (event: React.MouseEvent) => rowLeaveHandler() })}
              sx={{ verticalAlign: 'top' }}
            >
              {row.cells.map((cell: any) => (
                <TableCell {..._getCellProps(cell, cell.getCellProps([{ className: cell.column.className }]))}>
                  {cell.render('Cell')}
                </TableCell>
              ))}
            </TableRow>
          );
        })}

        {summaryRow}

        {showPagination && (
          <TableRow>
            <TableCell sx={{ p: 2 }} colSpan={7}>
              <TablePagination
                gotoPage={gotoPage}
                rows={rows}
                setPageSize={setPageSize}
                pageIndex={pageIndex}
                pageSize={pageSize}
              />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );

  return (showGlobalFilter
    ? (
      <Stack spacing={2}>
        <Box sx={{ p: 2, pb: 0 }}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            // @ts-ignore
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </Box>
        {table}
      </Stack>
    )
    : table);
};
