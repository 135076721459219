import { useQuery } from '@tanstack/react-query';
import { maybe } from 'async-result/utils';
import { useAuth } from 'context/appContext';
import { useQueryNeverRefetch } from 'utils';
import { createDisposingObjectUrl, SymbolDispose } from 'utils/queryClientDisposeOnCacheEvict';

export type ImgProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export const useAuthenticatedBlobQuery = (src: string | null | undefined) => {
  const { authInfo } = useAuth();
  const isEnabled = !!src && !!authInfo?.access_token;

  const query = useQuery(['authenticated-blob', src, authInfo?.access_token], async () => {
    if (!src || !authInfo?.access_token) {
      return null;
    }

    const response = await fetch(src, {
      headers: {
        'Authorization': `Bearer ${authInfo?.access_token}`,
      },
    });

    if (response.status !== 200) {
      const [text, textErr] = await maybe(response.text());
      throw new Error(`HTTP status ${response.status}: ${text || textErr || 'Unknown error'}`);
    }

    const data = await response.blob();
    return createDisposingObjectUrl(data);
  }, {
    ...useQueryNeverRefetch,
    staleTime: 15 * 60 * 1000,
    enabled: isEnabled,
  });

  return {
    src: query.data?.url,
    isEnabled,
    isLoading: query.isLoading,
    isError: query.isError,
    isSuccess: query.isSuccess,
    error: query.error,
    query,
  };
};

export const ImageFromQuery = (
  props: Omit<ImgProps, 'src'> & { img: ReturnType<typeof useAuthenticatedBlobQuery> },
) => {
  const { img, ...imgProps } = props;

  if (img.isError) {
    return <div className="fieldFull">Image load error: {'' + img.error}</div>;
  }

  if (img.isLoading) {
    return <div className="fieldFull">Loading...</div>;
  }

  if (!img.src) {
    return null;
  }

  return <img {...imgProps} src={img.src} />;
};

export const AuthenticatedImage = (props: Omit<ImgProps, 'src'> & { src: string | null }) => {
  const { src, ...imgProps } = props;
  const img = useAuthenticatedBlobQuery(src);
  return <ImageFromQuery img={img} {...imgProps} />;
};
