import React from 'react';

import { apiConfig, telemetryApi } from 'api';
import { TelemetryApi } from 'api/generated/MNT';
import { config } from 'config';
import { useAuth } from 'context/appContext';
import apiClient from 'food-editor/api-client';
import { useInterval } from 'hooks/useInterval';
import { Location, useLocation } from 'react-router-dom';
import useWindowFocus from 'use-window-focus';
import { mean, quantile, std } from './stats';

const hexChars = '0123456789abcdef';
export const mkRandId = (len: number) => {
  return Array.from({ length: len }, () => hexChars[Math.floor(Math.random() * hexChars.length)]).join('');
};
const telemetryWindowId = mkRandId(8);

const telemetryMetrics = {
  requests: 0,
  rtts: [] as number[],
  errors: 0,
};

type TelemetrySendOpts = {
  name: string,
  key?: string,
  value: number,
  properties?: Record<string, any>,
};

const _telemetryCall = async (method: keyof TelemetryApi, opts: TelemetrySendOpts) => {
  if (!apiConfig.accessToken) {
    return;
  }

  try {
    telemetryMetrics.requests += 1;
    const startTime = Date.now();
    await telemetryApi[method]({
      name: opts.name,
      key_b64: btoa([opts.key, telemetryWindowId].filter(x => !!x).join(':')),
      TelemetryEventRequest: {
        value: opts.value,
        properties: {
          ...(opts.properties || {}),
          winId: telemetryWindowId,
        },
      },
    });
    telemetryMetrics.rtts[telemetryMetrics.rtts.length % 50] = Date.now() - startTime;
  } catch (err) {
    telemetryMetrics.errors += 1;
    console.error(`Error sending telemetry '${opts.key} = ${opts.value}' with`, opts.properties, ':', err);
  }
};

export const telemetryInc = async (opts: TelemetrySendOpts) => {
  console.info('TelemetryInc:', opts);
  await _telemetryCall('appApiTelemetryEventPostTelemetryEventInc', opts);
};

export const telemetrySend = async (opts: TelemetrySendOpts) => {
  console.info('TelemetrySend:', opts);
  await _telemetryCall('appApiTelemetryEventPutTelemetryEvent', opts);
};

const sendTimeOnPageTelemetry = (
  info: {
    location: Location,
    pageLoadTime: number,
    refTime: number,
    focus: string,
    focusChangeCount: number,
  },
) => {
  const path = info.location.pathname.replace(/^\/+/, '') || '/';
  const key = `${info.focus}:${path}:${info.pageLoadTime}`;

  const q = new URLSearchParams(info.location.search);
  telemetryInc({
    name: 'TimeOnPage',
    key,
    value: (Date.now() - info.refTime) / 1000,
    properties: {
      focus: info.focus,
      path: path,
      focusChangeCount: info.focusChangeCount,
      ...Object.fromEntries(Array.from(q.entries()).map(([k, v]) => ['q_' + k, v])),
    },
  });
};

export const useTelemetrySendMetrics_DISABLED = () => {
  return false; // disable this because we can get it from Mixpanel
  useInterval(() => {
    if (!telemetryMetrics.requests) {
      return;
    }
    telemetrySend({
      name: 'TelemetryRequests',
      value: telemetryMetrics.requests,
      properties: {
        errors: telemetryMetrics.errors,
        rtt_count: telemetryMetrics.rtts.length,
        rtt_mean: mean(telemetryMetrics.rtts),
        rtt_std: std(telemetryMetrics.rtts),
        rtt_p50: quantile(telemetryMetrics.rtts, 0.5),
        rtt_p90: quantile(telemetryMetrics.rtts, 0.9),
        rtt_p95: quantile(telemetryMetrics.rtts, 0.95),
        rtt_p99: quantile(telemetryMetrics.rtts, 0.99),
      },
    });
  }, 1000 * 60 * 5);
};

export const useTelemetryTimeOnPage_DISABLED = () => {
  // disable this because it wasn't turning out to be very useful
  return;
  const location = useLocation();
  const focus = useWindowFocus() ? 'fg' : 'bg';
  const { authInfo } = useAuth();
  const tickTime = 1000 * 30;
  const lastTick = React.useRef(Date.now());
  const lastInfo = React.useRef({
    location,
    pageLoadTime: Date.now(),
    refTime: Date.now(),
    focus,
    focusChangeCount: 0,
  });

  React.useEffect(() => {
    const now = Date.now();
    const last = lastInfo.current;

    const tickTooLate = (now - lastTick.current) > (tickTime * 1.5);
    lastTick.current = now;

    if (!tickTooLate) {
      sendTimeOnPageTelemetry(lastInfo.current);
    }

    const isNewPage = tickTooLate || location != last.location;
    lastInfo.current = {
      location,
      pageLoadTime: isNewPage ? now : last.pageLoadTime,
      refTime: now,
      focus,
      focusChangeCount: isNewPage ? 0 : last.focusChangeCount + (last.focus != focus ? 1 : 0),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authInfo?.access_token, location, focus]);

  useInterval(() => {
    sendTimeOnPageTelemetry(lastInfo.current);
  }, tickTime);
};
