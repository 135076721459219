import { Snackbar, SnackbarProps } from '@mui/material';
import { create } from 'zustand';

const useState = create(() => ({
  props: null as Partial<SnackbarProps> | null,
}));

/**
 * Show a global snack bar (pop-up at the top of the screen).
 *
 * Options: https://mui.com/material-ui/react-snackbar/
 */
export const snackBarShow = (opts: Partial<SnackbarProps> | null) => {
  useState.setState({ props: opts });
};

export const SnackBarGlobalComponent = () => {
  const { props } = useState();
  if (!props) {
    return null;
  }
  return (
    <Snackbar
      {...props}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      style={{ top: 6 }}
      autoHideDuration={props.autoHideDuration ?? 2500}
      onClose={(event, reason) => {
        console.log('onClose', event, reason);
        props.onClose?.(event, reason);
        useState.setState({ props: null });
      }}
      open={true}
    />
  );
};
