import { ReactNode, useMemo } from 'react';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { AppBar, AppBarProps, Badge, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import IconButton from 'components/@extended/IconButton';

import { useMenuBar } from 'services/MenuBarService';
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';

interface Props {
  open: boolean;
  handleDrawerToggle?: () => void;
}

const Header = ({ open, handleDrawerToggle }: Props) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
  const { chips } = useMenuBar();

  // header content
  const headerContent = useMemo(() => <HeaderContent />, []);

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';

  const showBadge = !open && matchDownMD && Object.keys(chips).length > 0;

  const iconButton = (
    <IconButton
      aria-label="open drawer"
      onClick={handleDrawerToggle}
      edge="start"
      color="secondary"
      variant="light"
      sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: -2 } }}
    >
      {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
    </IconButton>
  );

  // common header
  const mainHeader: ReactNode = (
    <Toolbar>
      {showBadge ? <Badge badgeContent=" " color="error">{iconButton}</Badge> : iconButton}
      {headerContent}
    </Toolbar>
  );

  // app-bar params
  const appBar: AppBarProps = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      zIndex: 1200,
      // boxShadow: theme.customShadows.z1
    },
  };

  const miniDrawerAppBar: AppBarProps = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      zIndex: 1200,
      paddingLeft: 0,
      width: `calc(100% - ${theme.spacing(7.5)})`,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  };

  return (
    !matchDownMD
      ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      )
      : <AppBar {...miniDrawerAppBar}>{mainHeader}</AppBar>
  );
};

export default Header;
