import React, { useState } from 'react';

import { Help } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import OfflinePinOutlinedIcon from '@mui/icons-material/OfflinePinOutlined';
import { CircularProgress, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import { foodApi } from 'api';
import { FoodNlpPhraseMatchResult, FoodResponse } from 'api/generated/MNT';
import { useFeatures } from 'context/FeatureContext';
import { ClickablePatientNote } from 'pages/QueueItem/meal-builder/ClickablePatientNote';
import { useFoodResponse, useFoodResponses } from 'services/FoodDetailsService';
import { DraftItem } from 'types/DraftItem';

export type MealNoteSelection = {
  match: FoodNlpPhraseMatchResult,
  food_name: string,
  relatedFoodResponse?: FoodResponse | null | undefined,
};

export const useNlpPhraseParser = (value: string | null | undefined, queueId: number) => {
  const features = useFeatures();
  const enabled = !!value && features.labeling_use_nlp_phrase_parser;

  const query = useQuery(['meal-note', value], async () => {
    if (!value) {
      return { results: [] };
    }

    const res = await foodApi.appApiFoodFoodSearchSearchByNlpPhrase({
      search_text: value,
      queue_id: queueId,
    });
    return res.data;
  }, { enabled });

  return {
    enabled,
    query,
    result: query.data?.results || [],
  };
};

export const MealNoteView = (props: {
  value: string,
  onClick?: (item: MealNoteSelection) => void,
  mealItems?: DraftItem[],
  queueId: number,
}) => {
  const features = useFeatures();
  const theme = useTheme();
  const parser = useNlpPhraseParser(props.value, props.queueId);

  const matchedFoodResponses = useFoodResponses(
    parser.result
      .map(item => item.meal_item?.food_name)
      .filter(Boolean) as string[],
  );

  const existingItemMap = React.useMemo(() => {
    const res = {} as Record<string, boolean>;
    props.mealItems?.forEach(item => {
      res[item.item.food_name] = true;
      item.item.custom_addons?.forEach(addon => {
        res[addon.food_name] = true;
      });
    });
    return res;
  }, [props.mealItems]);

  if (!features.labeling_use_nlp_phrase_parser) {
    return (
      <ClickablePatientNote
        value={props.value}
        onClick={note => {
          props.onClick?.({
            food_name: note,
            match: {
              parsed: {
                text_span: note,
                food_name: note,
              },
            },
          });
        }}
      />
    );
  }

  if (!parser.query.isSuccess) {
    return (
      <span>
        {props.value} {parser.query.isError && (
          <Tooltip
            title={
              <span>
                {'' + parser.query.error}
              </span>
            }
          >
            <span>⚠️</span>
          </Tooltip>
        )}
        {parser.query.isFetching && <CircularProgress size={16} style={{ marginLeft: 4 }} />}
      </span>
    );
  }

  const res = [] as React.ReactNode[];
  let lastStop = 0;
  const note = props.value;
  parser.result.forEach(item => {
    if (!item?.parsed?.text_span) {
      return;
    }
    const { parsed } = item;
    const spanStart = note.indexOf(parsed.text_span, lastStop);
    if (spanStart == -1) {
      return;
    }
    if (lastStop < spanStart) {
      res.push(<span key={lastStop} className="text-secondary">{note.slice(lastStop, spanStart)}</span>);
    }

    const matchedItem = item.meal_item;
    const foodName = (matchedItem?.food_name || parsed.food_name || '').toLowerCase();
    const isAddonOf = (item.fail_detail as any)?.addon_of;

    res.push(
      <span
        key={spanStart}
        style={{
          textDecoration: existingItemMap[foodName] ? 'underline dotted' : 'underline',
          cursor: 'pointer',
          color: (
            existingItemMap[foodName]
              ? theme.palette.success.dark
              : isAddonOf
              ? theme.palette.secondary.main
              : theme.palette.primary.main
          ),
        }}
        onClick={() =>
          props.onClick?.({
            food_name: foodName,
            relatedFoodResponse: matchedFoodResponses[foodName]?.query?.data,
            match: item,
          })}
      >
        {parsed.text_span}
        {!!isAddonOf && <span style={{ fontSize: 10 }}>{' '} (addon of: {'' + isAddonOf})</span>}
        {item.meal_item
          ? (
            <Tooltip
              title={
                <span>{item.meal_item.food_name} {item.meal_item.servings} {item.meal_item.serving_unit_label}</span>
              }
            >
              <CheckCircleOutlineIcon style={{ fontSize: 16, marginLeft: 2 }} />
            </Tooltip>
          )
          : isAddonOf
          ? (
            <Tooltip
              title={<span>Addon of: {'' + isAddonOf}</span>}
            >
              <OfflinePinOutlinedIcon style={{ fontSize: 16, marginLeft: 2 }} />
            </Tooltip>
          )
          : (
            <Tooltip
              title={
                <span>
                  {item.fail_reason}
                  <br />
                  <pre>{JSON.stringify(item.fail_detail, null, 2)}</pre>
                </span>
              }
            >
              <HelpOutlineIcon style={{ fontSize: 16, marginLeft: 2 }} />
            </Tooltip>
          )}
      </span>,
    );
    lastStop = spanStart + parsed.text_span.length;
  });

  if (lastStop < note.length) {
    res.push(<span key={lastStop} className="text-secondary">{note.slice(lastStop, note.length)}</span>);
  }

  return <span>{res}</span>;
};
