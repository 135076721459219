import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { createDisposingObjectUrl } from 'utils/queryClientDisposeOnCacheEvict';
import { config } from '../config';

const BlobImageHistory = (props: {
  className?: string,
  photo_id: number,
  access_token: string | undefined,
  style?: React.CSSProperties,
}) => {
  const src = `${config.API_URL}/api/meal_photos/${props.photo_id}/photo`;
  const query = useQuery(['image', props.photo_id], async () => {
    const response = await fetch(src, {
      // const response = await fetch(props.src, {
      headers: {
        'Authorization': `Bearer ${props.access_token}`,
      },
    });

    return createDisposingObjectUrl(await response.blob());
  });

  if (query.isLoading) {
    return <div className="fieldFull">Loading...</div>;
  }
  if (query.isError) {
    return <div className="fieldFull">Image load error: {'' + query.error}</div>;
  }
  return (
    <img
      className={props.className}
      src={query.data?.url}
      alt=""
      style={{
        width: '100%',
        ...(props.style || {}),
      }}
    />
  );
};

export { BlobImageHistory };
