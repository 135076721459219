import * as React from 'react';

import { QuestionCircleFilled } from '@ant-design/icons';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export const HelpModal = (
  props: React.PropsWithChildren<{
    title: string,
  }>,
) => {
  const iconRef = React.useRef(null);
  const [showing, setShowing] = React.useState(false);
  const { title, children } = props;

  return (
    <>
      &nbsp;
      <div
        style={{
          display: 'inline-block',
        }}
        ref={iconRef}
      >
        <QuestionCircleFilled
          style={{ verticalAlign: 'text-top' }}
          onClick={e => {
            setShowing(!showing);
            e.preventDefault();
          }}
        />
        <Dialog open={showing} onClose={() => setShowing(false)} fullWidth={true} maxWidth="sm">
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            {children}
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={() => setShowing(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
