import { FoodServingUnit } from 'api/generated/MNT';
import elasticlunr from 'elasticlunr';
import { Document } from 'flexsearch';
import Fuse from 'fuse.js';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { isPromise } from '../async-result/utils';

export interface FoodSearchItem {
  id: number;
  name: string;
  nameNormalized?: string;
  updated_time: string;
  cursor: string;
  rxfood_id: string | null;
  name_translations: object | null;
  serving_units: Array<FoodServingUnit>;
  food_image_url: string | null;
  is_from_user_recents?: boolean;
}

export interface FoodSearchResult {
  engine: string;
  query: string;
  items: FoodSearchItem[];
  metrics: any;
}

export type FoodSearchEngineTypes<ItemType extends object> = {
  flexi: Document<ItemType>,
  elastic: elasticlunr.Index<ItemType>,
  fuse: Fuse<ItemType>,
};
export type FoodSearchEngineName = keyof FoodSearchEngineTypes<any>;

function p<T>(name: string, x: T): Promise<T> {
  return new Promise((res, rej) => {
    (x as any).onerror = (e: any) => {
      console.error(`Error in ${name}: ${e}`);
      rej(new Error(`Error in ${name}: ${e}`));
    };
    (x as any).onsuccess = () => res(x);
  });
}

export function time<T>(p: Promise<T>, callback: (time: number) => void): Promise<T>;
export function time<T>(p: () => T, callback: (time: number) => void): T;
export function time(p: any, callback: any) {
  const start = Date.now();

  if (typeof p === 'function') {
    p = p();
  }

  if (isPromise(p)) {
    return p.finally(() => callback(Date.now() - start));
  }

  callback(Date.now() - start);
  return p;
}

export function stripPunctuation<T>(s: T): T {
  if (typeof s !== 'string') {
    return s;
  }
  return (s as any).replace(/[.,\/#!$%\^&\*;:{}=\-_`~()'"]/g, '');
}

export class FoodSearchIndex<ItemType extends FoodSearchItem = FoodSearchItem> {
  enabledEngines = ['flexi'] as FoodSearchEngineName[];

  indexes: Partial<FoodSearchEngineTypes<ItemType>> = {};

  engineFactories: { [key in FoodSearchEngineName]: () => any } = {
    flexi: () =>
      new Document({
        preset: 'memory',
        tokenize: 'forward',
        document: {
          id: 'id',
          index: ['nameNormalized'],
        },
        worker: true,
      }),

    elastic: () =>
      elasticlunr<ItemType>(function() {
        this.setRef('id');
        this.addField('nameNormalized');
      }),

    fuse: () =>
      new Fuse<ItemType>([], {
        threshold: 0.2,
        keys: ['nameNormalized'],
      }),
  };

  itemsById: { [id: number]: ItemType } = {};
  itemsByName: { [name: string]: ItemType } = {};
  itemMaxCursor: string | null = null;
  itemCount = 0;
  metrics = {
    indexDBLoadTime: 0,
    indexDBWriteTime: 0,
    flexi: {
      indexTime: 0,
    },
  };

  constructor() {
    this.enabledEngines.forEach(name => {
      this.indexes[name] = this.engineFactories[name]();
    });
  }

  close() {
  }

  static async dropIndexDB(dbname: string) {
    await p('indexdb.deleteDatabase', indexedDB.deleteDatabase(dbname));
  }

  static async loadFromIndexDB<ItemType extends FoodSearchItem>(
    dbname: string,
  ): Promise<FoodSearchIndex<ItemType>> {
    const db = await FoodSearchIndex.openIndexDB(dbname);
    try {
      const tx = db.transaction('food_item_batches', 'readonly');
      const store = tx.objectStore('food_item_batches');
      return new FoodSearchIndex<ItemType>().load(store);
    } finally {
      db.close();
    }
  }

  static async openIndexDB(dbname: string): Promise<IDBDatabase> {
    console.log(`Opening IndexDB ${dbname}...`);
    const open = indexedDB.open(dbname, 1);
    open.onupgradeneeded = (e) => {
      console.log(`Upgrading IndexDB ${dbname}...`);
      const db = open.result;
      const store = db.createObjectStore('food_item_batches', {
        autoIncrement: true,
      });
      console.log(`Upgrade done!`);
    };
    const db = (await p('indexDB.open', open)).result;
    console.log(`Loaded IndexDB ${dbname}.`);
    return db;
  }

  async load(store: IDBObjectStore): Promise<FoodSearchIndex<ItemType>> {
    await time(async () => {
      const q = await time(p('indexDB.store.getAll', store.getAll()), t => this.metrics.indexDBLoadTime += t);
      q.result.forEach(item => {
        this._internalAddItems(item.itemBatch);
      });
    }, t => {
      mixpanel.track('ClientSearch: index opened', {
        'Load time': t,
        'Item count': this.itemCount,
        'IndexDB load time': this.metrics.indexDBLoadTime,
        'IndexDB write time': this.metrics.indexDBWriteTime,
        'Flexi CPU Time': this.metrics.flexi.indexTime,
      });
      console.log(`Food search index loaded from IndexDB (${this.itemCount} items)`);
    });
    return this;
  }

  _internalAddItems(items: ItemType[]) {
    items.forEach(_item => {
      if (this.itemsByName[_item.name]) {
        // TODO: handle updating items
        return;
      }

      const nameNormalized = stripPunctuation(_item.name);
      const item = {
        ..._item,
        nameNormalized: nameNormalized !== _item.name ? nameNormalized : _item.name,
      };

      this.itemCount += 1;
      item.id = this.itemCount;
      this.itemsById[item.id] = item;
      this.itemsByName[item.name] = item;

      this.enabledEngines.forEach(name => {
        let engineMetrics = this.metrics[name as 'flexi'];
        if (!engineMetrics) {
          engineMetrics = this.metrics[name as 'flexi'] = {
            'indexTime': 0,
          };
        }

        time(() => {
          if (name == 'elastic') {
            this.indexes[name]?.addDoc(item);
          } else {
            this.indexes[name]?.add(item);
          }
        }, t => engineMetrics.indexTime += t);
      });

      if (item.cursor > (this.itemMaxCursor || '')) {
        this.itemMaxCursor = item.cursor;
      }
    });
  }

  async addItems(db: IDBDatabase, items: ItemType[]): Promise<void> {
    if (!items.length) {
      return Promise.resolve();
    }
    const tx = db.transaction('food_item_batches', 'readwrite');
    try {
      const store = tx.objectStore('food_item_batches');
      const a = await time(
        p('indexDB.store.add', store.add({ itemBatch: items })),
        t => this.metrics.indexDBWriteTime += t,
      );
      this._internalAddItems(items);
    } catch (e) {
      tx.abort();
      throw e;
    }
    (tx as any).commit();
  }

  async searchFlexi(query: string): Promise<ItemType[]> {
    const queryNorm = stripPunctuation(query);
    const index = this.indexes['flexi'];
    if (!index) {
      throw new Error('Flexi index not enabled');
    }
    const res = index.search(queryNorm, {
      limit: 1000,
    });
    if (!res.length) {
      return [];
    }
    return _(res[0].result)
      .map((id: any) => this.itemsById[id])
      .sortBy([
        x => x.name.length < 20 ? 0 : 1,
        x => {
          const name = x.nameNormalized || x.name;
          const queryIndex = name.toLocaleLowerCase().indexOf(queryNorm.toLocaleLowerCase());
          return queryIndex < 0 ? Infinity : queryIndex;
        },
        x => x.name.length,
        x => x.name,
      ])
      .slice(0, 100)
      .value();
  }

  async searchElastic(query: string): Promise<ItemType[]> {
    const index = this.indexes['elastic'];
    if (!index) {
      throw new Error('Elastic index not enabled');
    }
    return index.search(query, {}).map(r => this.itemsById[r.ref as any]);
  }

  _pendingFuseSearch: string = '';

  async searchFuse(query: string): Promise<ItemType[]> {
    const index = this.indexes['fuse'];
    if (!index) {
      throw new Error('Fuse index not enabled');
    }

    this._pendingFuseSearch = query;
    return new Promise((res, rej) => {
      setTimeout(() => {
        if (this._pendingFuseSearch != query) {
          return;
        }
        res(index.search(query).map(i => i.item));
      }, 500);
    });
  }

  _lastQuery: string = '';

  async search(engine: FoodSearchEngineName, query: string): Promise<FoodSearchResult> {
    this._lastQuery = query;
    const metrics = {
      duration: 0,
      results: 0,
    };

    const items = await time(
      engine == 'flexi'
        ? this.searchFlexi(query)
        : engine == 'elastic'
        ? this.searchElastic(query)
        : engine == 'fuse'
        ? this.searchFuse(query)
        : Promise.resolve([]),
      t => metrics.duration = t,
    );

    metrics.results = items.length;
    setTimeout(() => {
      if (this._lastQuery != query) {
        return;
      }

      if (query) {
        mixpanel.track('ClientSearch: query', {
          'Engine': engine,
          'Query': query,
          'Result count': metrics.results,
          'Load time': metrics.duration,
        });
      }
    }, 500);

    return {
      engine,
      query,
      items: items
        // .sort((a, b) => a.name.length - b.name.length)
        .slice(0, 100),
      metrics,
    };
  }
}
