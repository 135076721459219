import { SearchItem } from 'apiClients/search';

export const MEAL_PREPARATION_METHODS = {
  'homemade': 'Homemade',
  'restaurant': 'Restaurant',
  'store_bought': 'Store bought',
  'cpg': 'CPG',
};

export const SERVING_TYPE_ITEMS = ['pan crust pizza', 'thin crust pizza', 'pizza'];

export const DEFAULT_SERVING_SIZE_OPTIONS = ['0.25', '0.5', '1', '1.5', '2', '3'];

export const sandwichReplacementServingUnits = [
  {
    food_replacement_name: 'sandwich (whole-wheat bread)',
    serving_units: {
      amount: 72,
      default_label_qty: 1,
      food_sizing_options: ['0.25', '0.5', '1', '1.5', '2', '3'],
      label: 'sandwich (whole-wheat bread)',
    },
  },
  {
    food_replacement_name: 'sandwich (multigrain bread)',
    serving_units: {
      amount: 72,
      default_label_qty: 1,
      food_sizing_options: ['0.25', '0.5', '1', '1.5', '2', '3'],
      label: 'sandwich (multigrain bread)',
    },
  },
  {
    food_replacement_name: 'sandwich (rye and spelt bread)',
    serving_units: {
      amount: 144,
      default_label_qty: 1,
      food_sizing_options: ['0.25', '0.5', '1', '1.5', '2', '3'],
      label: 'sandwich (rye and spelt bread)',
    },
  },
  {
    food_replacement_name: 'sandwich (whole grain bread)',
    serving_units: {
      amount: 72,
      default_label_qty: 1,
      food_sizing_options: ['0.25', '0.5', '1', '1.5', '2', '3'],
      label: 'sandwich (whole grain bread)',
    },
  },
  {
    food_replacement_name: 'sandwich (iceberg lettuce)',
    serving_units: {
      amount: 34,
      default_label_qty: 1,
      food_sizing_options: ['0.25', '0.5', '1', '1.5', '2', '3'],
      label: 'sandwich (iceberg lettuce)',
    },
  },
  {
    food_replacement_name: 'panini',
    serving_units: {
      amount: 58,
      default_label_qty: 1,
      food_sizing_options: ['0.25', '0.5', '1', '1.5', '2', '3'],
      label: 'panini',
    },
  },
];

export const defaultSearchItem: SearchItem = {
  addons: [],
  carbohydrate_g: 0, // value not used
  custom_tip: null,
  description: null,
  favorite: false,
  food_image_url: null,
  level1: null,
  level2: null,
  level3: null,
  level4: null,
  name: 'custom',
  name_translations: {},
  note: null,
  nutrition_source: 'usda',
  nutrition_source_id: 0, // value not used
  previously_logged: false,
  diet_restriction_flags: {
    no_dairy: false,
    no_pork: false,
    no_gluten: false,
    vegan: false,
    vegetarian: false,
    pescatarian: false,
  },
  replacements: [],
  serving_types: [],
  serving_units: [
    {
      amount: 1,
      default_label_qty: 1,
      label: 'gram',
      food_sizing_options: ['5', '10', '28.35', '50', '75', '100', '125'],
      label_translations: {},
    },
    {
      amount: 29.6,
      default_label_qty: 1,
      label: 'fl oz',
      food_sizing_options: ['6', '8', '12', '16', '24', '32'],
      label_translations: {},
    },
    {
      amount: 28.35,
      default_label_qty: 1,
      label: 'ounce',
      food_sizing_options: ['0.5', '1', '1.5', '2', '3', '6', '9', '12'],
      label_translations: {},
    },
  ],
  similar_items: [],
  rxfood_id: null,
};
