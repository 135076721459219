import { adminApi, foodApi } from 'api';
import { FoodResponse } from 'api/generated/MNT';
import { config } from 'config';

export type DietRestrictionFlags = {
  no_dairy: boolean,
  no_gluten: boolean,
  no_pork: boolean,
  pescatarian: boolean,
  vegetarian: boolean,
  vegan: boolean,
};

export type SearchItem = FoodResponse;

export const searchByText = async (searchText: string, access_token: string): Promise<SearchItem[]> => {
  const getMpqUrl = `${config.API_URL}/api/foods/search_by_text?search_text=${searchText}`;
  const response = await fetch(getMpqUrl, {
    headers: {
      'Authorization': `Bearer ${access_token}`,
    },
  });

  if (response.status !== 200) {
    throw new Error(`${response.status}`);
  }

  const items = await response.json();
  return items.results;
};

export const searchByTextWithContext = async (
  searchText: string,
  patient_id: number | null,
  access_token: string,
): Promise<SearchItem[]> => {
  if (!Number.isInteger(patient_id)) {
    window.alert('Error: Cannot find patient associated with this meal');
    return [];
  }
  const getMpqUrl = `${config.API_URL}/api/foods/search_by_text?search_text=${searchText}&${patient_id}`;
  const response = await fetch(getMpqUrl, {
    headers: {
      'Authorization': `Bearer ${access_token}`,
    },
  });

  if (response.status !== 200) {
    throw new Error(`${response.status}`);
  }

  const items = await response.json();
  return items.results;
};

export const searchNdbno = async (ndbno: string, access_token: string): Promise<SearchItem> => {
  const getMpqUrl = `${config.API_URL}/api/foods/ndbno/${ndbno}`;
  const response = await fetch(getMpqUrl, {
    headers: {
      'Authorization': `Bearer ${access_token}`,
    },
  });

  if (response.status !== 200) {
    throw new Error(`${response.status}`);
  }

  const item = await response.json();
  return item;
};

export const getFoodByQuery = async (foodName: string, access_token: string): Promise<SearchItem> => {
  const encodedFoodName = encodeURIComponent(foodName);
  const url = `${config.API_URL}/api/food?food_name=${encodedFoodName}`;
  const response = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${access_token}`,
    },
  });

  if (response.status !== 200) {
    throw new Error(`${response.status}`);
  }

  const food = await response.json();
  return food;
};

export const getFoodByQueryWithContext = async (foodName: string, patient_id: number): Promise<SearchItem> => {
  const resp = await foodApi.appApiFoodFoodSearchGetPatientFoodQuery({
    patient_id: patient_id,
    food_name: foodName,
  });
  return resp.data;
};

export const spellCheckFoodTerm = async (foodName: string) => {
  const resp = await adminApi.appApiFoodFoodSearchPostSpellCheck({
    food_name: foodName,
  });
  return resp.data;
};
