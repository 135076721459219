import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  ThemeProvider,
} from '@mui/material';
import _ from 'lodash';
import { EDITOR_BUTTON_PROPS, EDITOR_BUTTON_THEME } from './food-editor';

type RankingValues = '' | '0' | '1' | '2' | '3';

export default function RankingPicker({
  label,
  onChangeValue,
  value,
  onBlur,
}: {
  label: string,
  onChangeValue: (newValue: RankingValues) => void,
  value: RankingValues,
  onBlur?: () => void,
}) {
  const labelId = _.uniqueId();

  const RANKING_OPTIONS: { value: RankingValues, label: string }[] = [
    { value: '0', label: 'N/A' },
    { value: '3', label: '3 - Low' },
    { value: '2', label: '2 - Medium' },
    { value: '1', label: '1 - High' },
  ];

  return (
    <>
      <FormLabel id={labelId}>{label}</FormLabel>
      <ThemeProvider theme={EDITOR_BUTTON_THEME}>
        <Grid container spacing={2}>
          {RANKING_OPTIONS.map(option => {
            return (
              <Grid item key={option.value} xs={3}>
                <Button
                  variant="outlined"
                  onClick={() => onChangeValue(option.value as any)}
                  color={value !== option.value ? 'primary' : 'success'}
                  {...EDITOR_BUTTON_PROPS}
                  sx={{ ...EDITOR_BUTTON_PROPS.sx, borderWidth: 2 }}
                >
                  {option.label}
                </Button>
              </Grid>
            );
          })}
        </Grid>
      </ThemeProvider>
    </>
  );

  // Old implementation #1: Select Menu
  // return (
  //   <FormControl variant="standard" fullWidth>
  //     <InputLabel id={labelId}>{label}</InputLabel>
  //     <Select
  //       labelId={labelId}
  //       label={label}
  //       onChange={evt => onChangeValue(evt.target.value as any)}
  //       onBlur={onBlur}
  //       value={value}
  //     >
  //       <MenuItem value="0">&nbsp;</MenuItem>
  //       <MenuItem value="1">1 - High</MenuItem>
  //       <MenuItem value="2">2 - Medium</MenuItem>
  //       <MenuItem value="3">3 - Low</MenuItem>
  //     </Select>
  //   </FormControl>
  // );

  // Old implementation #2: Radio Buttons
  // return (
  //   <FormControl variant="standard" fullWidth>
  //     <FormLabel id={labelId}>{label}</FormLabel>
  //     <RadioGroup
  //       id={labelId}
  //       name={label}
  //       value={value}
  //       onChange={evt => onChangeValue(evt.target.value as any)}
  //       onBlur={onBlur}
  //       row
  //     >
  //       <FormControlLabel value="0" control={<Radio />} label="N/A" />
  //       <FormControlLabel value="1" control={<Radio />} label="1 - High" />
  //       <FormControlLabel value="2" control={<Radio />} label="2 - Medium" />
  //       <FormControlLabel value="3" control={<Radio />} label="3 - Low" />
  //     </RadioGroup>
  //   </FormControl>
  // );
}
