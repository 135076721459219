import * as Sentry from '@sentry/react';
import { apiConfig } from 'api';
import { TaskResultResponse } from 'api/generated/MNT';
import { maybe, sleep } from 'async-result/utils';
import axios, { AxiosError, AxiosResponse } from 'axios';

(window as any).axios = axios;

export const interceptBackgroundTask = async (response: AxiosResponse<TaskResultResponse>) => {
  if (response.status !== 202 || !response.data.link) {
    return response;
  }

  const { config } = response;

  let step = 1;

  while (true) {
    await sleep(Math.min(step, 10) * 1000);
    step += 1;
    const [rawRes, err] = await maybe(fetch(response.data.link).then(res => res.json()));
    console.log(`Background task '${config.url}' status (step: ${step}):`, rawRes);
    const res = rawRes as TaskResultResponse;
    if (res.task_is_pending || !res.result_http_status) {
      continue;
    }
    if (res.task_is_error) {
      console.error(`Background task '${config.url}' failed:`, rawRes);
      Sentry.captureException(new Error(`Unexpected background task failure: ${config.url}`));
    }
    const resp = {
      status: res.result_http_status,
      statusText: '',
      data: res.result_http_body_json,
      headers: response.headers,
      config: response.config,
      request: response.request,
    };
    if (!config.validateStatus?.(resp.status)) {
      throw new AxiosError(
        'Request failed with status code ' + resp.status,
        [AxiosError.ERR_BAD_REQUEST, AxiosError.ERR_BAD_RESPONSE][Math.floor(resp.status / 100) - 4],
        resp.config,
        resp.request,
        resp,
      );
    }
    return res;
  }
};
