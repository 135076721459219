import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  ThemeProvider,
} from '@mui/material';
import _ from 'lodash';
import { EDITOR_BUTTON_PROPS, EDITOR_BUTTON_THEME } from './food-editor';

type LowMediumHighValues = '' | 'low' | 'medium' | 'high';

export default function LowMediumHighPicker({
  label,
  onChangeValue,
  value,
  onBlur,
}: {
  label: string,
  onChangeValue: (newValue: LowMediumHighValues) => void,
  value: LowMediumHighValues,
  onBlur?: () => void,
}) {
  const labelId = _.uniqueId();

  const BUTTON_OPTIONS: { value: LowMediumHighValues, label: string }[] = [
    { value: '', label: 'N/A' },
    { value: 'low', label: '3 - Low' },
    { value: 'medium', label: '2 - Medium' },
    { value: 'high', label: '1 - High' },
  ];

  return (
    <>
      <FormLabel id={labelId}>{label}</FormLabel>
      <ThemeProvider theme={EDITOR_BUTTON_THEME}>
        <Grid container spacing={2}>
          {BUTTON_OPTIONS.map(option => {
            return (
              <Grid item key={option.value} xs={3}>
                <Button
                  variant="contained"
                  onClick={() => onChangeValue(option.value as any)}
                  color={value !== option.value ? 'primary' : 'success'}
                  {...EDITOR_BUTTON_PROPS}
                >
                  {option.label}
                </Button>
              </Grid>
            );
          })}
        </Grid>
      </ThemeProvider>
    </>
  );

  // Old implementation #1: Select Menu
  // return (
  //   <FormControl variant="standard" fullWidth>
  //     <InputLabel id={labelId}>{label}</InputLabel>
  //     <Select
  //       labelId={labelId}
  //       label={label}
  //       onChange={evt => onChangeValue(evt.target.value as any)}
  //       onBlur={onBlur}
  //       value={value}
  //     >
  //       <MenuItem value="">&nbsp;</MenuItem>
  //       <MenuItem value="high">High</MenuItem>
  //       <MenuItem value="medium">Medium</MenuItem>
  //       <MenuItem value="low">Low</MenuItem>
  //     </Select>
  //   </FormControl>
  // );

  // Old implementation #2: Radio Buttons
  // return (
  //   <FormControl variant="standard" fullWidth>
  //     <FormLabel id={labelId}>{label}</FormLabel>
  //     <RadioGroup
  //       id={labelId}
  //       name={label}
  //       value={value}
  //       onChange={evt => onChangeValue(evt.target.value as any)}
  //       onBlur={onBlur}
  //       row
  //     >
  //       <FormControlLabel value="" control={<Radio />} label="N/A" />
  //       <FormControlLabel value="high" control={<Radio />} label="1 - High" />
  //       <FormControlLabel value="medium" control={<Radio />} label="2 - Medium" />
  //       <FormControlLabel value="low" control={<Radio />} label="3 - Low" />
  //     </RadioGroup>
  //   </FormControl>
  // );
}
