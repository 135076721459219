/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { TelemetryEventRequest } from '../models';
/**
 * TelemetryApi - axios parameter creator
 * @export
 */
export const TelemetryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update metric
         * @param {string} name 
         * @param {string} key_b64 
         * @param {TelemetryEventRequest} TelemetryEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiTelemetryEventPostTelemetryEventInc: async (name: string, key_b64: string, TelemetryEventRequest: TelemetryEventRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('appApiTelemetryEventPostTelemetryEventInc', 'name', name)
            // verify required parameter 'key_b64' is not null or undefined
            assertParamExists('appApiTelemetryEventPostTelemetryEventInc', 'key_b64', key_b64)
            // verify required parameter 'TelemetryEventRequest' is not null or undefined
            assertParamExists('appApiTelemetryEventPostTelemetryEventInc', 'TelemetryEventRequest', TelemetryEventRequest)
            const localVarPath = `/telemetry/event/{name}/{key_b64}/inc`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"key_b64"}}`, encodeURIComponent(String(key_b64)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(TelemetryEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update metric
         * @param {string} name 
         * @param {string} key_b64 
         * @param {TelemetryEventRequest} TelemetryEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiTelemetryEventPutTelemetryEvent: async (name: string, key_b64: string, TelemetryEventRequest: TelemetryEventRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('appApiTelemetryEventPutTelemetryEvent', 'name', name)
            // verify required parameter 'key_b64' is not null or undefined
            assertParamExists('appApiTelemetryEventPutTelemetryEvent', 'key_b64', key_b64)
            // verify required parameter 'TelemetryEventRequest' is not null or undefined
            assertParamExists('appApiTelemetryEventPutTelemetryEvent', 'TelemetryEventRequest', TelemetryEventRequest)
            const localVarPath = `/telemetry/event/{name}/{key_b64}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"key_b64"}}`, encodeURIComponent(String(key_b64)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(TelemetryEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TelemetryApi - functional programming interface
 * @export
 */
export const TelemetryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TelemetryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update metric
         * @param {string} name 
         * @param {string} key_b64 
         * @param {TelemetryEventRequest} TelemetryEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiTelemetryEventPostTelemetryEventInc(name: string, key_b64: string, TelemetryEventRequest: TelemetryEventRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiTelemetryEventPostTelemetryEventInc(name, key_b64, TelemetryEventRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update metric
         * @param {string} name 
         * @param {string} key_b64 
         * @param {TelemetryEventRequest} TelemetryEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiTelemetryEventPutTelemetryEvent(name: string, key_b64: string, TelemetryEventRequest: TelemetryEventRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiTelemetryEventPutTelemetryEvent(name, key_b64, TelemetryEventRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TelemetryApi - factory interface
 * @export
 */
export const TelemetryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TelemetryApiFp(configuration)
    return {
        /**
         * 
         * @summary Update metric
         * @param {string} name 
         * @param {string} key_b64 
         * @param {TelemetryEventRequest} TelemetryEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiTelemetryEventPostTelemetryEventInc(name: string, key_b64: string, TelemetryEventRequest: TelemetryEventRequest, options?: any): AxiosPromise<void> {
            return localVarFp.appApiTelemetryEventPostTelemetryEventInc(name, key_b64, TelemetryEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update metric
         * @param {string} name 
         * @param {string} key_b64 
         * @param {TelemetryEventRequest} TelemetryEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiTelemetryEventPutTelemetryEvent(name: string, key_b64: string, TelemetryEventRequest: TelemetryEventRequest, options?: any): AxiosPromise<void> {
            return localVarFp.appApiTelemetryEventPutTelemetryEvent(name, key_b64, TelemetryEventRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiTelemetryEventPostTelemetryEventInc operation in TelemetryApi.
 * @export
 * @interface TelemetryApiAppApiTelemetryEventPostTelemetryEventIncRequest
 */
export interface TelemetryApiAppApiTelemetryEventPostTelemetryEventIncRequest {
    /**
     * 
     * @type {string}
     * @memberof TelemetryApiAppApiTelemetryEventPostTelemetryEventInc
     */
    readonly name: string

    /**
     * 
     * @type {string}
     * @memberof TelemetryApiAppApiTelemetryEventPostTelemetryEventInc
     */
    readonly key_b64: string

    /**
     * 
     * @type {TelemetryEventRequest}
     * @memberof TelemetryApiAppApiTelemetryEventPostTelemetryEventInc
     */
    readonly TelemetryEventRequest: TelemetryEventRequest
}

/**
 * Request parameters for appApiTelemetryEventPutTelemetryEvent operation in TelemetryApi.
 * @export
 * @interface TelemetryApiAppApiTelemetryEventPutTelemetryEventRequest
 */
export interface TelemetryApiAppApiTelemetryEventPutTelemetryEventRequest {
    /**
     * 
     * @type {string}
     * @memberof TelemetryApiAppApiTelemetryEventPutTelemetryEvent
     */
    readonly name: string

    /**
     * 
     * @type {string}
     * @memberof TelemetryApiAppApiTelemetryEventPutTelemetryEvent
     */
    readonly key_b64: string

    /**
     * 
     * @type {TelemetryEventRequest}
     * @memberof TelemetryApiAppApiTelemetryEventPutTelemetryEvent
     */
    readonly TelemetryEventRequest: TelemetryEventRequest
}

/**
 * TelemetryApi - object-oriented interface
 * @export
 * @class TelemetryApi
 * @extends {BaseAPI}
 */
export class TelemetryApi extends BaseAPI {
    /**
     * 
     * @summary Update metric
     * @param {TelemetryApiAppApiTelemetryEventPostTelemetryEventIncRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TelemetryApi
     */
    public appApiTelemetryEventPostTelemetryEventInc(requestParameters: TelemetryApiAppApiTelemetryEventPostTelemetryEventIncRequest, options?: AxiosRequestConfig) {
        return TelemetryApiFp(this.configuration).appApiTelemetryEventPostTelemetryEventInc(requestParameters.name, requestParameters.key_b64, requestParameters.TelemetryEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update metric
     * @param {TelemetryApiAppApiTelemetryEventPutTelemetryEventRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TelemetryApi
     */
    public appApiTelemetryEventPutTelemetryEvent(requestParameters: TelemetryApiAppApiTelemetryEventPutTelemetryEventRequest, options?: AxiosRequestConfig) {
        return TelemetryApiFp(this.configuration).appApiTelemetryEventPutTelemetryEvent(requestParameters.name, requestParameters.key_b64, requestParameters.TelemetryEventRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
