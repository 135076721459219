import { QueryFunctionContext, useInfiniteQuery } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';

import * as Sentry from '@sentry/react';
import { ChangeLogItem } from 'api/generated/MNT';
import type { ReviewerAuthInfo } from 'apiClients/auth';
import type { QALog } from 'apiClients/mpq';
import { acknowledgeFeedback, getFeedback } from 'apiClients/mpq';
import type { MealQueueItem } from 'apiClients/mpq';
import { BlobImage } from 'components/BlobImage';
import MainCard from 'components/MainCard';
import { confidenceNumberToText, QAQuestionnaireView } from 'components/MealQALogs';
import { AppCtx } from 'context/appContext';
import { logTrackedError } from 'errorTracking';
import { MealChangeLogsTable } from 'food-editor/components/FoodChangeLogs';
import { MealChangeLogs } from 'pages/LegacyReviewItem';
import { useMealChangeLogs } from './QueueItem/meal-builder/useMealChangeLogs';

const FeedbackText = (props: { item: QALog }) => {
  const item = props.item;
  return (
    <>
      {item.confidence_identification && (
        <span>
          Identification: {confidenceNumberToText(item.confidence_identification)}
          <br />
        </span>
      )}
      {item.confidence_sizing && (
        <span>
          Sizing: {confidenceNumberToText(item.confidence_sizing)}
          <br />
        </span>
      )}
      {item.level_of_difficulty && (
        <span>
          Difficulty: {confidenceNumberToText(item.level_of_difficulty)}
          <br />
        </span>
      )}
    </>
  );
};

const FeedbackRow = (props: { item: QALog, authInfo: ReviewerAuthInfo, onAcknowledge: any }) => {
  const [acknowledged, setAcknowledged] = React.useState(false);

  const acknowledge = async (id: number) => {
    try {
      await props.onAcknowledge(id);
    } catch (error: any) {
      logTrackedError({
        sourceName: 'FeedbackRow.acknowledge',
        origin: error,
        stackError: new Error(),
        context: { id },
        userMessage: `Error acknowledging feedback: ${error.message}.`,
      });
      return;
    }
    setAcknowledged(true);
    mixpanel.track('Labeller: QA feedback acknowledged', {
      'Identification accuracy': item.confidence_identification,
      'Sizing accuracy': item.confidence_sizing,
      'Difficulty': item.level_of_difficulty,
    });
  };

  const item = props.item;
  if (!item.meal_photo_queue_item) {
    return (
      <tr>
        <td colSpan={4}>
          <i>(no mpq item)</i>
        </td>
      </tr>
    );
  }

  // Remove feedbacks with no useful information
  if ((item.questionnaire == null || JSON.stringify(item.questionnaire) == '{}') && item.note == '') {
    return null;
  }

  return (
    <tr>
      <td>
        <Link to={`/queue-item/${item.meal_photo_queue_item.id}?back=/`}>
          {item.meal_photo_queue_item.queue_type === 'photo_ffe_queue'
            ? (
              <BlobImage
                className="queueImage"
                groupId={item.meal_photo_queue_item.data_reviewer_group_id.toString()}
                queueItemId={item.meal_photo_queue_item.id.toString()}
                access_token={props.authInfo.access_token}
              />
            )
            : (
              <span>
                {item.meal_photo_queue_item.patient_note}
              </span>
            )}
        </Link>
      </td>
      <td>{item.meal_photo_queue_item.first_reviewer_user_id}</td>
      <td>
        <FeedbackText item={item} />
        <QAQuestionnaireView questionnaire={item.questionnaire} />
      </td>
      <td>
        {(item.note && item.note != '') && (
          <p>
            Reviewer Note:&nbsp;
            {item.note}
            <br />
          </p>
        )}
        <FeedbackChangeLogs
          queueItem={item.meal_photo_queue_item}
          reviewerID={item.meal_photo_queue_item.first_reviewer_user_id}
        />
      </td>
      <td>
        <Form.Check
          disabled={acknowledged}
          onChange={(evt) => {
            if (evt.target.checked) {
              acknowledge(item.id);
            }
          }}
        />
      </td>
    </tr>
  );
};

export const Feedback = () => {
  const { authInfo } = useContext(AppCtx);
  if (!authInfo?.access_token || !authInfo?.reviewer_id) {
    throw new Error('not logged in');
  }

  const fetchPage = (context: QueryFunctionContext): Promise<QALog[]> => {
    return getFeedback(authInfo.access_token, {
      data_reviewer_id: authInfo.reviewer_id,
      perPage: context.pageParam?.perPage || 5,
      highestId: context.pageParam?.highestId || null,
    });
  };

  const {
    data,
    isError,
    error,
    isLoading,
    fetchNextPage,
  } = useInfiniteQuery(['feedback'], fetchPage, {
    getNextPageParam: (lastPage, pages) => {
      const highestId = Math.max(...lastPage.map(item => item.id));
      return { perPage: 1, highestId: highestId };
    },
  });

  const acknowledge = async (item_id: number) => {
    await acknowledgeFeedback(authInfo.access_token, { data_reviewer_id: authInfo.reviewer_id, item_id });
    fetchNextPage();
  };

  if (isLoading) {
    return (
      <div>
        Loading...
      </div>
    );
  }
  if (isError && error instanceof Error) {
    return (
      <div>
        {error.message}
      </div>
    );
  }

  return (
    <div style={{ overflowX: 'scroll' }}>
      {data?.pages?.length && data.pages[0].length > 0
        ? (
          <table className="table">
            <thead style={{ display: 'table-header-group' }}>
              <tr>
                <th>Item</th>
                <th>Reviewer</th>
                <th>Feedback</th>
                <th>Reviewer Note and Changes</th>
                <th>Read</th>
              </tr>
            </thead>
            <tbody>
              {data.pages.map((group, i) => (
                <React.Fragment key={i}>
                  {group.sort((lhs, rhs) => lhs.meal_photo_queue_id - rhs.meal_photo_queue_id).map(item => (
                    <FeedbackRow
                      key={item.id}
                      item={item}
                      authInfo={authInfo}
                      onAcknowledge={acknowledge}
                    />
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )
        : <div>No feedback</div>}
    </div>
  );
};

export const FeedbackPage = () => {
  return (
    <MainCard>
      <Feedback />
    </MainCard>
  );
};

const FeedbackChangeLogs = (props: { queueItem: MealQueueItem, reviewerID: number | null | undefined }) => {
  const { queueItem, reviewerID } = props;

  const mealChangeLogsRes = useMealChangeLogs(queueItem);

  return (
    <>
      {mealChangeLogsRes.query.isError && <div>{'' + mealChangeLogsRes.query.error}</div>}
      {mealChangeLogsRes.query.isLoading && <div>Loading...</div>}
      {mealChangeLogsRes.query.isSuccess && (mealChangeLogsRes.data
        ? (
          <div>
            <p>
              Reviewer Changes:
            </p>
            <MealChangeLogsTable
              changeLogs={mealChangeLogsRes.data}
              reviewerID={reviewerID}
              firstReviewerID={queueItem.first_reviewer_user_id}
            />
          </div>
        )
        : null)}
    </>
  );
};
