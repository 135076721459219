import { useAuth } from 'context/appContext';
import { useInterval } from 'food-editor/components/HumanTime';
import mixpanel from 'mixpanel-browser';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import SocketIO, { Socket } from 'socket.io-client';
import { buildUrl } from 'utils';
import { telemetrySend } from 'utils/telemetry';
import { create } from 'zustand';
import { config } from '../config';

const socketIOConnect = (opts: {
  authToken?: string,
  setConnected: (connected: boolean) => void,
}) => {
  const socket = SocketIO(config.NOTIFICATION_ENGINE_URL, {
    transports: ['websocket', 'polling'],
    auth: {
      token: opts.authToken,
    },
  });

  const updateConnected = () => opts.setConnected(socket.connected);
  socket.on('connect', updateConnected);
  socket.on('connect', (...args) => {
    console.log('SocketIO: connected!', args);
  });

  socket.onAnyOutgoing((event, ...args) => {
    console.log('SocketIO -> server:', event, args);
  });

  socket.onAny((event, ...args) => {
    console.log('SocketIO <- server:', event, args);
  });

  socket.on('disconnect', updateConnected);
  socket.on('disconnect', (...args) => {
    console.log('SocketIO: disconnected!', args);
  });

  return socket;
};

const socketIODisconnect = (socket: Socket) => {
  socket.close();
  socket.off();
};

export type SocketIOClientStore = {
  socket: Socket | null,
  connected: boolean,
  telemetry: {
    rttTotal: number,
    rttSuccess: number,
    rttFail: number,
    rttLatest: number | null,
    rttMean: number,
    rttp50: number,
    rttp90: number,
    rttErrorRate: number,
  },
};

const useSocketIOClientStore = create<SocketIOClientStore>(() => ({
  socket: null,
  connected: false,
  telemetry: {
    rttTotal: 0,
    rttSuccess: 0,
    rttFail: 0,
    rttLatest: null,
    rttMean: 0,
    rttp50: 0,
    rttp90: 0,
    rttErrorRate: 0,
  },
}));

/**
 * Connect to the SocketIO server.
 *
 * Should only be called once from the root of the app.
 */
export const useSocketIOConnect = () => {
  const io = useSocketIOClientStore();
  const { authInfo } = useAuth();

  useInitSocketIOTelementry();

  useEffect(() => {
    if (io.socket) {
      socketIODisconnect(io.socket);
    }

    const socket = socketIOConnect({
      authToken: authInfo?.access_token,
      setConnected: connected => {
        useSocketIOClientStore.setState({ connected });
      },
    });

    useSocketIOClientStore.setState({ socket });
    return () => {
      socketIODisconnect(socket);
    };
  }, [authInfo?.access_token]);
};

const useInitSocketIOTelementry = () => {
  const io = useSocketIOClientStore();
  const maxPingHistory = 100;
  const recentPings = useRef<Array<{ ts: number, rtt?: number }>>([]);

  useInterval(1000 * 10, () => {
    if (!io.connected) {
      return;
    }
    const ping = { ts: Date.now() };
    recentPings.current.push(ping);
    if (recentPings.current.length > maxPingHistory) {
      recentPings.current.shift();
    }
    io.socket?.volatile.emit('ping', ping);
  });

  useSocketEvent(
    io,
    'pong',
    useCallback((data: { ts: number }) => {
      const ping = recentPings.current.find(p => p.ts === data.ts);
      if (!ping) {
        console.warn('SocketIO: received pong for unknown ping', data);
        return;
      }
      ping.rtt = Date.now() - data.ts;
      recalcTelemetry();
    }, [io.socket]),
  );

  const recalcTelemetry = () => {
    const telemetry = getSocketTelemetryData(recentPings.current);
    window.RX_SOCKET_TELEMETRY_LAST_RESULT = telemetry;
    useSocketIOClientStore.setState({ telemetry });
  };

  useInterval(1000 * 10, recalcTelemetry);

  useInterval(1000 * 300, () => {
    const telemetry = io.telemetry;

    if (!telemetry.rttTotal) {
      return;
    }

    mixpanel.track('SocketIO: RTT', {
      Transport: io.socket?.io?.engine?.transport?.name,
      RTT: telemetry.rttLatest,
      'RTT Mean': telemetry.rttMean,
      'RTT P90': telemetry.rttp90,
      'RTT Count': telemetry.rttTotal,
      'RTT Error rate': telemetry.rttErrorRate,
    });

    /*
    disabling for now because we can get it from Mixpanel
    telemetrySend({
      name: 'SocketIO:RTT',
      key: '' + recentPings.current[recentPings.current.length - 1].ts,
      value: telemetry.rttLatest ?? 0,
      properties: {
        transport: io.socket?.io?.engine?.transport?.name,
        ...telemetry,
      },
    });
    */
  });
};

export const useSocketIOClient = () => {
  return useSocketIOClientStore();
};

export const useSocketOnConnect = (io: SocketIOClientStore, callback: (socket: Socket) => void) => {
  useEffect(() => {
    if (io.connected) {
      callback(io.socket!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [io.socket, io.connected, callback]);
};

export const useSocketEvent = (io: SocketIOClientStore, event: string, callback: (...args: any[]) => void) => {
  useEffect(() => {
    io.socket?.on(event, callback);
    return () => {
      io.socket?.off(event, callback);
    };
  }, [io, event, callback]);
};

/**
 * Send `ident` to the SocketIO presence server.
 */
export const useSocketIOPresenceIdent = () => {
  const io = useSocketIOClient();
  const location = useLocation();

  const currentPath = buildUrl(location.pathname, location.search);

  useSocketOnConnect(
    io,
    useCallback(socket => {
      socket.emit('presence:ident', {
        location: currentPath,
      });
    }, [currentPath]),
  );
};

type PresenceIdent = any;

interface PresenceStateUser {
  socketId: string;
  addr: string;
  uid: string | undefined;
  connectedAt: Date;
  ident: PresenceIdent;
}

/**
 * Maintain a list of connected users from the SocketIO presence server.
 */
export const useSocketIOPresenceActiveUsers = () => {
  const io = useSocketIOClient();
  const [activeUsers, setActiveUsers] = useState({
    activeUsersById: {} as Record<string, PresenceStateUser>,
    allActiveUsers: [] as PresenceStateUser[],
  });

  useSocketOnConnect(
    io,
    useCallback(socket => {
      socket.emit('presence:join');
    }, [io.socket]),
  );

  useSocketEvent(
    io,
    'presence:state',
    useCallback((clients: PresenceStateUser[]) => {
      setActiveUsers({
        activeUsersById: clients.reduce((acc, client) => {
          if (!client.uid) {
            return acc;
          }
          acc[client.uid] = client;
          return acc;
        }, {} as Record<string, PresenceStateUser>),
        allActiveUsers: clients,
      });
    }, []),
  );

  return {
    ...activeUsers,
    isConnected: io.connected,
  };
};

const getSocketTelemetryData = (recentPings: Array<{ ts: number, rtt?: number }>) => {
  if (recentPings.length === 0) {
    return {
      rttTotal: 0,
      rttSuccess: 0,
      rttFail: 0,
      rttLatest: null,
      rttMean: 0,
      rttp50: 0,
      rttp90: 0,
      rttErrorRate: 0,
    };
  }

  const successPings = recentPings.filter(p => !!p.rtt) as Array<{ ts: number, rtt: number }>;
  const failedPings = recentPings.filter(p => !p.rtt);
  const gtFiveSecPings = recentPings.filter(p => Date.now() - p.ts > 5 * 1000);

  return {
    rttTotal: recentPings.length,
    rttSuccess: successPings.length,
    rttFail: failedPings.length,
    rttLatest: gtFiveSecPings.length ? gtFiveSecPings[gtFiveSecPings.length - 1].rtt ?? 0 : null,
    rttMean: successPings.length
      ? successPings.reduce((a, b) => a + (b.rtt ?? 0), 0)
        / successPings.length
      : 0,
    rttp50: successPings.length
      ? successPings.sort((a, b) => a.rtt - b.rtt)[Math.ceil(successPings.length * 0.5) - 1].rtt
      : 0,
    rttp90: successPings.length
      ? successPings.sort((a, b) => a.rtt - b.rtt)[Math.ceil(successPings.length * 0.9) - 1].rtt
      : 0,
    rttErrorRate: failedPings.length / recentPings.length,
  };
};

export const useSocketTelemetryData = () => {
  const io = useSocketIOClientStore();
  return io.telemetry;
};
