import { config } from 'config';
import { useInterval } from 'hooks/useInterval';
import React, { useEffect } from 'react';

export const useAppUpdateService = () => {
  const [updateAvailable, setUpdateAvailable] = React.useState(null as string | null);

  const checkForUpdates = React.useCallback(() => {
    if (!config.COMMIT_SHA_URL) {
      return;
    }
    console.log('appUpdateService: checking for update:', config.COMMIT_SHA_URL);
    fetch(config.COMMIT_SHA_URL + '&t=' + Date.now())
      .then((res) => {
        if (res.status > 299) {
          throw new Error(`appUpdateService: failed to check for update: ${res.status}`);
        }
        return res.text();
      })
      .then((latestSha) => latestSha.trim())
      .then((latestSha) => {
        const needsUpdate = latestSha != config.COMMIT_SHA;
        console.log(
          `appUpdateService: needsUpdate=${needsUpdate} `
            + `current=${JSON.stringify(config.COMMIT_SHA)} `
            + `latest=${JSON.stringify(latestSha)}`,
        );
        if (needsUpdate) {
          setUpdateAvailable(latestSha);
        }
      })
      .catch((err) => {
        console.error('appUpdateService: failed to check for update', err);
      });
  }, []);

  useEffect(() => checkForUpdates(), [checkForUpdates]);

  useInterval(checkForUpdates, updateAvailable ? null : 1000 * 60 * 15);

  return { updateAvailable };
};
