import { type MealQueueItem } from 'apiClients/mpq';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'App.css';
import { useQuery } from '@tanstack/react-query';
import { mealApi } from 'api';
import { DraftItem } from 'types/DraftItem';

export const useDraftItems = (queueItem: MealQueueItem) => {
  const draftItemsQuery = useQuery<DraftItem[]>(['get-draft-items', queueItem.id], async () => {
    const meal = await mealApi.appApiMealGetMeal({
      meal_id: queueItem.created_meal_id,
      patient_id: queueItem.patient_id,
    });

    return meal.data.meal_items.map(item => ({
      id: item.id,
      editing: false,
      item: {
        ...item,
        custom_usda_id: null,
        custom_item_source: item.custom_item_source ?? null,
        custom_item_source_id: item.custom_item_source_id ?? null,
        servings: item.servings ?? 1,
      },
      searchItem: null,
      queryText: item?.food_name,
      foodMatchDetails: null,
    }));
  });

  return {
    draftItems: draftItemsQuery.isSuccess ? draftItemsQuery.data : [],
    query: draftItemsQuery,
  };
};
