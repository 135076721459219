import * as Sentry from '@sentry/react';
import { dataReviewApi, patientApi } from 'api';
import type { FavoriteItemResponse, FoodResponse } from 'api/generated/MNT';
import { config } from 'config';
import { logTrackedError } from 'errorTracking';

export type FavoriteItem = {
  addon_names?: string[] | null,
  carbohydrate_g?: number,
  custom_label?: string | null,
  favorite_id: number,
  food_name: string,
  note?: string | null,
  replacement_food_name?: string | null,
  serving_type_label?: string | null,
  serving_type_multiplier?: number | null,
  serving_unit_amount?: number | null,
  serving_unit_label?: string | null,
  servings?: number | null,
  similar_items?: string[],
};

export type ContextPredictedItemsResponse = {
  favorites: FavoriteItemResponse[],
  image_rec_items: FoodResponse[],
  ocr_items: FoodResponse[],
  similar_photo_items: FoodResponse[],
  meal_note_items: FoodResponse[],
};

export const getPatientNote = async (group_id: number, reviewer_id: number, queue_id: number, access_token: string) => {
  const url =
    `${config.API_URL}/api/data_reviewer_group/${group_id}/data_reviewer/${reviewer_id}/meal_photo_queue/${queue_id}/patient_note`;
  const response = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
    mode: 'cors',
  });

  if (response.status != 200) {
    throw new Error(`${response.status}`);
  }

  const results = await response.json();
  return results.note;
};

export const postPatientNote = async (
  group_id: number,
  reviewer_id: number,
  queue_id: number,
  access_token: string,
  pNote: string,
) => {
  const url =
    `${config.API_URL}/api/data_reviewer_group/${group_id}/data_reviewer/${reviewer_id}/meal_photo_queue/${queue_id}/patient_note`;
  const response = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({ note: pNote }),
  });

  if (response.status !== 200) {
    logTrackedError({
      sourceName: 'postPatientNote',
      origin: response,
      stackError: new Error(),
      context: {
        reviewer_id,
        queue_id,
        pNote,
      },
      userMessage: 'Error posting patient note. Please try again.',
    });
    return false;
  }

  return true;
};

/** @deprecated: use `usePatientContext` instead */
export const getPatientContext = async (group_id: number, reviewer_id: number, queue_id: number) => {
  const patientResponse = await dataReviewApi.appApiQueuePatientContextGetPatientContext({
    data_reviewer_group_id: group_id,
    data_reviewer_id: reviewer_id,
    meal_photo_queue_id: queue_id,
  });
  return patientResponse.data;
};

export const getPredictedItemsContext = async (
  group_id: number,
  reviewer_id: number,
  queue_id: number,
): Promise<ContextPredictedItemsResponse> => {
  const context = await getPatientContext(group_id, reviewer_id, queue_id);
  const items = {
    favorites: context.favorites || [],
    image_rec_items: context.image_rec_items || [],
    ocr_items: context.ocr_items || [],
    similar_photo_items: context.similar_photo_items || [],
    meal_note_items: context.meal_note_items || [],
  };
  return items;
};
