import { nutrientValueToStrOrEmpty } from 'food-editor/components/food-editor';
import { FoodEditorValue } from '../types';
import { formatPct } from './utils';

// Note: copied from mnt:app/image_detection/nft_parser/nft_parser.py:_nutrient_default_rda_values
export const NUTRIENT_RDA_VALUES: Partial<FoodEditorValue> = {
  fatG: 75,
  transFatG: 20,
  satFatG: 20,
  fibreG: 28,
  calciumMg: 1300,
  ironMg: 18,
  magnesiumMg: 420,
  phosphorusMg: 1250,
  potassiumMg: 3400,
  sodiumMg: 2300,
  zincMg: 11,
  thiaminMg: 1.2,
  riboflavinMg: 1.3,
  niacinMg: 16,
  folateDfeUg: 400,
  cholesterolMg: 300,
  vitaminB6Mg: 1.7,
  vitaminB12Ug: 2.4,
  vitaminCMg: 90,
  vitaminEMg: 15,
  vitaminKUg: 120,
  sugarsG: 100,
  cholineMg: 550,
  pantothenicAcidMg: 5,
  seleniumMcg: 55,
  vitaminARaeUg: 900,
  vitaminAIu: 3000,
  vitaminD2d3Ug: 20,
};

export const nutrientValueIsRdaPct = (
  nutrient: keyof FoodEditorValue,
  value: string | number | null | undefined,
): value is string => {
  const rdaVal = NUTRIENT_RDA_VALUES[nutrient];
  if (!rdaVal) {
    return false;
  }

  if (typeof value !== 'string') {
    return false;
  }

  if (!value) {
    return false;
  }

  return value.indexOf('%') >= 0 && !isNaN(parseFloat(value.replace('%', '')));
};

export const nutrientValueToRdaPct = (nutrient: keyof FoodEditorValue, value: string | null | undefined) => {
  const rdaVal = NUTRIENT_RDA_VALUES[nutrient];
  if (!rdaVal) {
    return null;
  }

  if (!value) {
    return null;
  }

  return formatPct(parseFloat(value) / parseFloat(rdaVal as string), 1);
};

export const nutrientRdaPctToValue = (
  nutrient: keyof FoodEditorValue,
  pct: string | null | undefined,
) => {
  const rdaVal = NUTRIENT_RDA_VALUES[nutrient];
  if (!rdaVal) {
    return null;
  }

  if (!nutrientValueIsRdaPct(nutrient, pct)) {
    return null;
  }

  const pctFloat = parseFloat(pct.replace('%', '')) / 100;
  if (isNaN(pctFloat)) {
    return null;
  }

  return pctFloat * parseFloat(rdaVal as string);
};
