import { useAsyncResult } from 'react-use-async-result';

import mixpanel from 'mixpanel-browser';

import { config } from 'config';
import { useAuth } from 'context/appContext';
import { AuthenticatedImage } from './AuthenticatedImage';

const BlobImage = (
  props: { className: string, groupId: string, queueItemId: string, access_token: string | undefined },
) => {
  const src =
    `${config.API_URL}/api/data_reviewer_group/${props.groupId}/meal_photo_queue/${props.queueItemId}/original_photo`;
  return <AuthenticatedImage className={props.className} src={src} />;
};

export { BlobImage };
