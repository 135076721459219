import { Delete } from '@mui/icons-material';
import { Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Autocomplete, TableContainer, TextField } from '@mui/material';
import { useState } from 'react';
import { Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { useAsyncResult } from 'react-use-async-result';
import { FoodSearchResult } from '../client-search/food-search-index';
import { useFoodSearchIndex } from '../client-search/useFoodSearchIndex';

import { foodApi } from 'api';

/*  Meal Entry Test:
  Searching through db and adding to a food object that can be submitted if needed.
  TODO:
    FEATURES:
      - Food size option
        - add default sizing from db
        - add plural checking for servings (1 cup vs 1 cups) or just cup(s)
      - Edit foods -> add swap food function instead of remove and add new food??
      - Text parsing for serving size???
      - Edit alerts to be nicer (MUI component)
    FIXES:
      - add text validation for inputs (numbers only? and no empty text) !
      - Updating food serving info doesn't update state
      - Need to go through and update types that are any
      - set default values if fn params are undefined (for fn that have event as param)
      - pressing enter doesnt do anything !
      - options for serving type are not exhaustive - should allow custom values or empty values (1 apple)
*/

const SERVING_TYPES = ['cups', 'g', 'ml', 'tbsp', 'oz'];

export const MealEntryTest = () => {
  const searchIndexRes = useFoodSearchIndex();
  const [useAzure, toggleAzure] = useState(true);
  const [value, setValue] = useState('');
  const [results, setResults] = useState<string[]>([]);
  const [selectedFoods, setSelectedFoods] = useState<
    { name: string, serving: string, servingSize: string }[]
  >([]);
  const searchResults = ['flexi', 'elastic', 'fuse'].map((engine) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return { engine, res: useAsyncResult<FoodSearchResult>() };
  });

  if (searchIndexRes.isError) {
    return <div>Error loading search index: {'' + searchIndexRes.error}</div>;
  }

  if (!searchIndexRes.isDone) {
    return <div>Loading...</div>;
  }

  const searchIndex = searchIndexRes.result;

  const doSearch = (query: string) => {
    let tempArr: string[] = [];
    if (useAzure) {
      foodApi
        .appApiFoodFoodSearchAzureAutocomplete({ partial_term: query })
        .then(
          (res: any) => {
            tempArr = res.data.autocomplete_results?.map((result: any) => {
              return result.food_name;
            }) || [];
            setResults(tempArr);
          },
          (err: any) => {
            console.log('error when searching', err);
          },
        );
      return;
    }

    searchResults.forEach((res) => {
      if (!searchIndex.enabledEngines.includes(res.engine as any)) {
        return;
      }
      res.res.bind(searchIndex.search(res.engine as any, query));
    });
    if (!searchResults[0].res.isDone) {
      return;
    }
    tempArr = searchResults[0].res.result.items.map((r) => r.name);
    setResults(tempArr);
  };

  const handleSelect = (e: any) => {
    setValue(e.target.textContent);
  };
  const handleChange = (e: any) => {
    doSearch(e.target.value);
    setValue(e.target.value);
  };
  const handleAdd = () => {
    if (selectedFoods.find((food) => food.name === value)) {
      alert('Alr in foods');
      return;
    }
    if (!value) {
      alert('Enter food');
      return;
    }
    const newFoodArr = selectedFoods;
    newFoodArr.push({ name: value, serving: 'g', servingSize: '300' });
    setSelectedFoods(newFoodArr);
    setValue('');
  };
  const handleRemove = (e: any) => {
    const foodName = e.target.viewportElement.getAttribute('id');
    const newFoodArr = selectedFoods.filter((food) => foodName !== food.name);
    setSelectedFoods(newFoodArr);
  };

  return (
    <Container style={{ marginTop: 20 }}>
      <Button
        onClick={() => {
          toggleAzure(!useAzure);
        }}
      >
        {useAzure ? `Azure` : `Flexi`}
      </Button>
      <Row>
        <Col>
          <Autocomplete
            freeSolo
            options={results}
            onChange={handleSelect}
            onInputChange={handleChange}
            value={value}
            inputValue={value}
            renderInput={(params) => <TextField {...params} variant="standard" label="Food Name" />}
          />
          <Button
            onClick={handleAdd}
            sx={{ margin: '10px' }}
            variant="contained"
          >
            Add Food
          </Button>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="50%">Food</TableCell>
                <TableCell width="20%">Serving</TableCell>
                <TableCell width="25%" />
                <TableCell width="5%" />
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedFoods
                && selectedFoods.map((food, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{food.name}</TableCell>
                      <TableCell>
                        <TextField
                          required
                          id="serving size"
                          defaultValue={food.servingSize}
                        />
                      </TableCell>
                      <TableCell>
                        <Autocomplete
                          disablePortal
                          disableClearable
                          id="serving-type"
                          defaultValue={food.serving}
                          options={SERVING_TYPES}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          color="error"
                          aria-label="remove-food"
                          component="label"
                          onClick={handleRemove}
                        >
                          <Delete id={food.name} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};
