import { Link, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { spellCheckFoodTerm } from 'apiClients/search';
import { useEffect, useState } from 'react';

export function SpellCheck(props: {
  term: string,
  updateSpelling: (correctSpelling: string) => void,
}) {
  const handleClick = () => {
    if (query.isSuccess) {
      props.updateSpelling(query.data.trim());
    }
  };

  const query = useQuery(['spell-check', props.term], async () => {
    const res = await spellCheckFoodTerm(props.term);
    return res.toLowerCase();
  });

  if (query.isLoading) {
    return null;
  }

  if (query.isError) {
    return <Typography>Spell Check Error: {'' + query.error}</Typography>;
  }

  if (query.data === props.term || !query.data) {
    return null;
  }

  const termArray = props.term.split(' ');
  const correctArray = query.data.split(' ');
  const suggestion = correctArray.map((word, index) => {
    return (
      <>
        {index > 0 && ' '}
        {word == termArray[index] ? word : (
          <strong>
            <em>{word}</em>
          </strong>
        )}
      </>
    );
  });

  return (
    <Stack direction="row">
      <Typography>
        Did you mean:{'\t'}
        <Link
          component="button"
          color="inherit"
          onClick={handleClick}
        >
          {suggestion}
        </Link>
      </Typography>
    </Stack>
  );
}
