// project import
import SimpleBar from 'components/third-party/SimpleBar';
import { Navigation } from './Navigation';

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => (
  <SimpleBar
    sx={{
      '& .simplebar-content': {
        display: 'flex',
        flexDirection: 'column',
      },
      '& .simplebar-placeholder': {
        height: '0px !important',
      },
    }}
  >
    <Navigation />
  </SimpleBar>
);

export default DrawerContent;
