import { useEffect } from 'react';

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { dataReviewApi } from 'api';
import { PatientContextQueueResponse } from 'api/generated/MNT';
import { type MealQueueItem } from 'apiClients/mpq';
import { useAuth } from 'context/appContext';
import { useAsyncResult } from 'react-use-async-result';

export type PatientContext = {
  query: UseQueryResult<unknown>,
  context: PatientContextQueueResponse | null,
  is_legal_alcohol_age: boolean,
  // @deprecated - use `query` to determine loading state
  loading: boolean,
  // @deprecated - use `query` to determine loading state
  error: boolean,
};

export const usePatientContext = (item: MealQueueItem | null): PatientContext => {
  const { authInfo } = useAuth();
  const query = useQuery(['patient-context', authInfo?.reviewer_id, item?.id], async () => {
    if (!item || !authInfo?.reviewer_id) {
      return null;
    }

    const res = await dataReviewApi.appApiQueuePatientContextGetPatientContext({
      data_reviewer_group_id: item.data_reviewer_group_id,
      data_reviewer_id: authInfo.reviewer_id,
      meal_photo_queue_id: item.id,
    });
    return res.data;
  }, {
    refetchOnWindowFocus: false,
  });

  const context = query.data ?? null;

  const is_legal_alcohol_age = context?.country === 'USA'
    ? context?.age >= 21
    : (context?.age ?? 99) >= 18;

  return {
    query,
    context,
    is_legal_alcohol_age,
    loading: query.isLoading,
    error: query.isError,
  };
};
