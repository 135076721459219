// material-ui
import { ChipProps } from '@mui/material';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface MenuBarState {
  openItem: string[];
  openComponent: string;
  drawerOpen: boolean;
  chips: Record<string, ChipProps>;
  setActiveItem: (openItem: string[]) => void;
  setActiveComponent: (openComponent: string) => void;
  setDrawerOpen: (drawerOpen: boolean) => void;
}


export const useMenuBar = create<MenuBarState>()(devtools((set) => ({
  openItem: ['dashboard'],
  openComponent: 'buttons',
  drawerOpen: false,
  chips: {},
  setActiveItem: (openItem) => set({ openItem }),
  setActiveComponent: (openComponent) => set({ openComponent }),
  setDrawerOpen: (drawerOpen) => set({ drawerOpen }),
})));