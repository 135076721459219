import * as React from 'react';

import { faExternalLinkSquareAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueries } from '@tanstack/react-query';

import type { MealItem } from 'apiClients/mpq';
import { getFoodByQuery } from 'apiClients/search';
import { useAuth } from 'context/appContext';
import type { DraftItem } from 'types/DraftItem';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'App.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { encodeQueryParams } from 'utils';

export const LegacyMealSummary = (props: {
  draftItems: DraftItem[],
  updateDraftItemMealItem: (draftItem: DraftItem, newMealItem: Partial<MealItem>) => void,
  removeDraftItem: (item: DraftItem) => void,
  // removeReplacement: (id: number | string, item: MealItem) => void,
  // removeAddon: (id: number | string, name: string, item: MealItem) => void,
  // removeCustomAddon: (id: number | string, name: string, item: MealItem) => void,
}) => {
  const { draftItems, updateDraftItemMealItem } = props;
  const { authInfo } = useAuth();
  const [customTipItem, setCustomTipItem] = useState<DraftItem | null>(null);

  const allFoodNames: string[] = [];
  draftItems.forEach(v => {
    allFoodNames.push(v.item.food_name);
    v.item.custom_addons?.forEach(addon => {
      allFoodNames.push(addon.food_name);
    });
  });

  const foodCarbs: { [name: string]: number } = {};
  useQueries({
    queries: allFoodNames.map(foodName => ({
      queryKey: ['foods', foodName],
      queryFn: () => getFoodByQuery(foodName, authInfo!.access_token),
      cacheTime: 1000 * 60 * 60 * 24,
      staleTime: 1000 * 60 * 60 * 24,
    })),
  }).forEach(r => {
    if (r.isSuccess) {
      foodCarbs[r.data.name] = r.data.carbohydrate_g!;
    }
  });

  const getItemWeight = (item: { serving_unit_amount: number, servings: number }) => (
    item.serving_unit_amount * item.servings
  );

  const getTotalWeight = (item: MealItem) => {
    let addonWeight = 0;
    item.custom_addons?.forEach(addon => {
      addonWeight += getItemWeight(addon);
    });
    return addonWeight + getItemWeight(item);
  };

  const getItemCarbs = (item: { food_name: string, serving_unit_amount: number, servings: number }) => {
    const carbs = foodCarbs[item.food_name] ?? NaN;
    return (item.serving_unit_amount * item.servings) / 100 * carbs;
  };

  const getTotalCarbs = (item: MealItem) => {
    let addonCarbs = 0;
    item.custom_addons?.forEach(addon => {
      addonCarbs += getItemCarbs(addon);
    });
    return addonCarbs + getItemCarbs(item);
  };

  const formatCarbs = (val: number) => {
    if (isNaN(val)) {
      return '–';
    }
    return val.toFixed(1) + 'g';
  };

  const removeAddon = (draftItem: DraftItem, addon: string) => {
    updateDraftItemMealItem(draftItem, {
      addons: draftItem.item.addons.filter(a => a !== addon),
    });
  };

  const removeCustomAddon = (draftItem: DraftItem, addonFoodName: string) => {
    updateDraftItemMealItem(draftItem, {
      custom_addons: draftItem.item.custom_addons?.filter(a => a.food_name != addonFoodName) || [],
    });
  };

  const getFoodEditorUrl = (foodName: string) => {
    return `/foods/${encodeURIComponent(foodName)}`;
  };
  return (
    <div>
      <div className="summaryTableHeader" style={{ fontSize: 14, fontStyle: 'italic' }}>
        <div>Name & Addons</div>
        <div>Sizing</div>
        <div>Weight</div>
        <div>Carbs</div>
      </div>
      {[...props.draftItems].reverse().map((draftItem, idx) => (
        <div key={idx} className={`${idx % 2 == 0 ? 'summaryTable' : 'summaryTable-odd'}`}>
          <div className="summaryTableSubrow">
            <div
              className="summaryTableFoodName"
              onMouseEnter={() =>
                setCustomTipItem(draftItem)}
              onMouseLeave={() =>
                setCustomTipItem(null)}
            >
              {draftItem.item.food_name}{' '}
              <FontAwesomeIcon
                className="qaActionButton"
                icon={faTrash}
                onClick={() => props.removeDraftItem(draftItem)}
                size="sm"
                color="orange"
              />{' '}
              <Link
                to={getFoodEditorUrl(draftItem.item.food_name)}
                target="_blank"
              >
                <FontAwesomeIcon
                  className="qaActionButton"
                  icon={faExternalLinkSquareAlt}
                  size="sm"
                  color="black"
                />
              </Link>
            </div>
            <div>
              {draftItem.item.serving_unit_label} ({draftItem.item.servings}x{draftItem.item.serving_unit_amount}g)
            </div>
            <div>{parseFloat(getItemWeight(draftItem.item).toFixed(2))}g</div>
            <div>{formatCarbs(getItemCarbs(draftItem.item))}</div>
          </div>
          {draftItem.item.addons.map((addon) => (
            <div key={addon} className="summaryTableSubrow">
              <div className="summaryTableAddon">
                <label>
                  {addon}{' '}
                  <FontAwesomeIcon
                    className="qaActionButton"
                    icon={faTrash}
                    onClick={() => removeAddon(draftItem, addon)}
                    size="sm"
                    color="orange"
                  />{' '}
                  <Link
                    to={getFoodEditorUrl(addon)}
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      className="qaActionButton"
                      icon={faExternalLinkSquareAlt}
                      size="sm"
                      color="black"
                    />
                  </Link>
                </label>
              </div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
            </div>
          ))}
          {draftItem.item.custom_addons?.map((a) => (
            <div key={a.food_name} className="summaryTableSubrow">
              <div className="summaryTableAddon">
                <label>
                  {a.food_name}{' '}
                  <FontAwesomeIcon
                    className="qaActionButton"
                    icon={faTrash}
                    onClick={() => removeCustomAddon(draftItem, a.food_name)}
                    size="sm"
                    color="orange"
                  />{' '}
                  <Link
                    to={getFoodEditorUrl(a.food_name)}
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      className="qaActionButton"
                      icon={faExternalLinkSquareAlt}
                      size="sm"
                      color="black"
                    />
                  </Link>
                </label>
              </div>
              <div>{a.serving_unit_label} ({a.servings}x{a.serving_unit_amount}g)</div>
              <div>{parseFloat(getItemWeight(a).toFixed(2))}g</div>
              <div>{formatCarbs(getItemCarbs(a))}</div>
            </div>
          ))}
        </div>
      ))}
      <div className="summaryTable" style={{ borderBottom: '0px', paddingBottom: '1rem' }}>
        <div className="summaryTableSubrow">
          <div>Total</div>
          <div />
          <div>{(draftItems.reduce((acc, v) => acc + getTotalWeight(v.item), 0)).toFixed(2)}g</div>
          <div>{formatCarbs(draftItems.reduce((acc, v) => acc + getTotalCarbs(v.item), 0))}</div>
          <div style={{ fontSize: 10 }}>
            (<FontAwesomeIcon icon={faTrash} size="sm" color="orange" />
            - remove item)<br />
            (<FontAwesomeIcon icon={faExternalLinkSquareAlt} size="sm" color="black" /> - view in editor)
          </div>
        </div>
      </div>
      <div>
        Tip: {!customTipItem ? '' : customTipItem?.searchItem?.custom_tip || <i>No tip for this item</i>}
      </div>
    </div>
  );
};
