import axios from 'axios';

import { config } from 'config';
import { globalLogout } from 'context/appContext';
import { FoodEngineApi } from './generated/food-engine';
import {
  AdminApi,
  Configuration,
  DataManagementApi,
  DataReviewApi,
  FoodApi,
  ImageDetectionApi,
  MealApi,
  MealPushQuestionsApi,
  OauthApi,
  PatientApi,
  ReportingApi,
  TelemetryApi,
  TranslationsApi,
} from './generated/MNT';
import { interceptBackgroundTask } from './interceptors/backgroundTask';

export const apiConfig = new Configuration({
  basePath: config.API_URL + '/api',
});

export const client = axios.create({ baseURL: config.API_URL });
client.interceptors.response.use(interceptBackgroundTask);
client.interceptors.response.use(
  response => response,
  error => {
    const shouldReThrowError = !axios.isAxiosError(error)
      || error.response?.status != 401
      || !apiConfig.accessToken
      || error.config.url?.includes('/oauth/authorize_swagger');
    if (shouldReThrowError) {
      throw error;
    }

    console.warn('Got HTTP 401, logging out and redirecting to login page', error);
    globalLogout.logout();
    window.location.href = '/login?next=' + encodeURIComponent(window.location.pathname);
    throw error;
  },
);

export const adminApi = new AdminApi(apiConfig, config.API_URL, client);
export const oauthApi = new OauthApi(apiConfig, config.API_URL, client);
export const dataReviewApi = new DataReviewApi(apiConfig, config.API_URL, client);
export const foodApi = new FoodApi(apiConfig, config.API_URL, client);
export const dataManagementApi = new DataManagementApi(apiConfig, config.API_URL, client);
export const mealApi = new MealApi(apiConfig, config.API_URL, client);
export const mealPushQuestionApi = new MealPushQuestionsApi(apiConfig, config.API_URL, client);
export const patientApi = new PatientApi(apiConfig, config.API_URL, client);
export const telemetryApi = new TelemetryApi(apiConfig, config.API_URL, client);
export const translationsApi = new TranslationsApi(apiConfig, config.API_URL, client);
export const reportingApi = new ReportingApi(apiConfig, config.API_URL, client);
export const imageDetectionApi = new ImageDetectionApi(apiConfig, config.API_URL, client);

export const foodEngineApiConfig = new Configuration({
  basePath: config.FOOD_ENGINE_URL + '/api',
});
export const foodEngineApi = new FoodEngineApi(foodEngineApiConfig, config.FOOD_ENGINE_URL, client);
