export default (
  ontologyLevels?: string[],
) => {
  if (!ontologyLevels || ontologyLevels.length == 9) {
    return;
  }

  if (
    ontologyLevels.length == 1 && ontologyLevels[0] == 'baby food'
  ) {
    return `This food's ontology is "${ontologyLevels[0]}", which might be a temporary ontology. Please double check.`;
  }
};
