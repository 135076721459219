import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';
import reportWebVitals from './reportWebVitals';

import { initAnalytics } from 'analytics';
import './index.css';
import { DateTime } from 'luxon';
import { config } from './config';

const checkPreviewBuildAge = () => {
  if (!config.IS_PREVIEW) {
    return;
  }

  const now = DateTime.now();
  const twoDaysOld = now.minus({ days: 2 }).toFormat('yyMMddhhmm');
  const buildIsValid = twoDaysOld < config.BUILD_DATE;
  console.log('Preview build date:', config.BUILD_DATE, 'two days old:', twoDaysOld);
  if (buildIsValid) {
    return;
  }
  const res = confirm(
    'This preview build has expired, and may not work correctly.\n'
      + 'Are you sure you want to continue using it?',
  );
  if (res) {
    alert('You are using an expired preview build. Please proceed with caution.');
    return;
  }
  window.location.href = window.location.toString().replace('preview-', '');
};

initAnalytics();
checkPreviewBuildAge();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
