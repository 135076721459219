/* tslint:disable */
/* eslint-disable */
/**
 * Food Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: wolever@rxfood.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FoodEngineFoodResponse } from '../models';
// @ts-ignore
import { FoodEnginePaginatedResponseFoodEngineFoodResponse } from '../models';
/**
 * FoodEngineApi - axios parameter creator
 * @export
 */
export const FoodEngineApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById', 'id', id)
            const localVarPath = `/food/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} queryText 
         * @param {string} [sources] 
         * @param {'en' | 'fr'} [lang] 
         * @param {number} [user_id] 
         * @param {any} [meal_name] 
         * @param {string} [addon_of] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchByTerm: async (queryText: string, sources?: string, lang?: 'en' | 'fr', user_id?: number, meal_name?: any, addon_of?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryText' is not null or undefined
            assertParamExists('searchByTerm', 'queryText', queryText)
            const localVarPath = `/food/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (queryText !== undefined) {
                localVarQueryParameter['queryText'] = queryText;
            }

            if (sources !== undefined) {
                localVarQueryParameter['sources'] = sources;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (user_id !== undefined) {
                localVarQueryParameter['user_id'] = user_id;
            }

            if (meal_name !== undefined) {
                localVarQueryParameter['meal_name'] = meal_name;
            }

            if (addon_of !== undefined) {
                localVarQueryParameter['addon_of'] = addon_of;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FoodEngineApi - functional programming interface
 * @export
 */
export const FoodEngineApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FoodEngineApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodEngineFoodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} queryText 
         * @param {string} [sources] 
         * @param {'en' | 'fr'} [lang] 
         * @param {number} [user_id] 
         * @param {any} [meal_name] 
         * @param {string} [addon_of] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchByTerm(queryText: string, sources?: string, lang?: 'en' | 'fr', user_id?: number, meal_name?: any, addon_of?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodEnginePaginatedResponseFoodEngineFoodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchByTerm(queryText, sources, lang, user_id, meal_name, addon_of, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FoodEngineApi - factory interface
 * @export
 */
export const FoodEngineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FoodEngineApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(id: string, options?: any): AxiosPromise<FoodEngineFoodResponse> {
            return localVarFp.getById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} queryText 
         * @param {string} [sources] 
         * @param {'en' | 'fr'} [lang] 
         * @param {number} [user_id] 
         * @param {any} [meal_name] 
         * @param {string} [addon_of] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchByTerm(queryText: string, sources?: string, lang?: 'en' | 'fr', user_id?: number, meal_name?: any, addon_of?: string, options?: any): AxiosPromise<FoodEnginePaginatedResponseFoodEngineFoodResponse> {
            return localVarFp.searchByTerm(queryText, sources, lang, user_id, meal_name, addon_of, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getById operation in FoodEngineApi.
 * @export
 * @interface FoodEngineApiGetByIdRequest
 */
export interface FoodEngineApiGetByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodEngineApiGetById
     */
    readonly id: string
}

/**
 * Request parameters for searchByTerm operation in FoodEngineApi.
 * @export
 * @interface FoodEngineApiSearchByTermRequest
 */
export interface FoodEngineApiSearchByTermRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodEngineApiSearchByTerm
     */
    readonly queryText: string

    /**
     * 
     * @type {string}
     * @memberof FoodEngineApiSearchByTerm
     */
    readonly sources?: string

    /**
     * 
     * @type {'en' | 'fr'}
     * @memberof FoodEngineApiSearchByTerm
     */
    readonly lang?: 'en' | 'fr'

    /**
     * 
     * @type {number}
     * @memberof FoodEngineApiSearchByTerm
     */
    readonly user_id?: number

    /**
     * 
     * @type {any}
     * @memberof FoodEngineApiSearchByTerm
     */
    readonly meal_name?: any

    /**
     * 
     * @type {string}
     * @memberof FoodEngineApiSearchByTerm
     */
    readonly addon_of?: string
}

/**
 * FoodEngineApi - object-oriented interface
 * @export
 * @class FoodEngineApi
 * @extends {BaseAPI}
 */
export class FoodEngineApi extends BaseAPI {
    /**
     * 
     * @param {FoodEngineApiGetByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodEngineApi
     */
    public getById(requestParameters: FoodEngineApiGetByIdRequest, options?: AxiosRequestConfig) {
        return FoodEngineApiFp(this.configuration).getById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FoodEngineApiSearchByTermRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodEngineApi
     */
    public searchByTerm(requestParameters: FoodEngineApiSearchByTermRequest, options?: AxiosRequestConfig) {
        return FoodEngineApiFp(this.configuration).searchByTerm(requestParameters.queryText, requestParameters.sources, requestParameters.lang, requestParameters.user_id, requestParameters.meal_name, requestParameters.addon_of, options).then((request) => request(this.axios, this.basePath));
    }
}
