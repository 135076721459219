import { useQuery } from '@tanstack/react-query';
import { mealApi } from 'api';
import { MealPhotoQueueResponse } from 'api/generated/MNT';

export const useMealForQueue = (props: {
  queueItem: MealPhotoQueueResponse,
}) => {
  const { queueItem } = props;
  const query = useQuery(['meal-for-queue', [queueItem?.id, queueItem?.patient_id]], async () => {
    if (!(queueItem?.created_meal_id && queueItem?.patient_id)) {
      return {
        meal: null,
      };
    }

    // Returns 404 for photo queues without an associated meal
    const response = await mealApi.appApiMealGetMeal({
      patient_id: queueItem.patient_id,
      meal_id: queueItem.created_meal_id,
    }).then(res => res.data);

    return {
      meal: response,
    };
  }, { enabled: !!queueItem.created_meal_id });
  return {
    query,
    meal: query.data?.meal,
  };
};
