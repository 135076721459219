import { set } from 'lodash';
import moment, { Moment } from 'moment';
import React from 'react';
import { useEffect, useState } from 'react';

/**
 *  Calls ``callback`` every ``interval`` ms, returning the new result.
 *
 *  For example::
 *
 *    function Counter() {
 *      const curCount = useInterval(1000, () => count++)
 *      return <div>Count: {curCount}</div>
 *    }
 *
 *  Would yield a counter which increments every second.
 */
export function useInterval<T>(ms: number, cb: () => T): T {
  const [res, setRes] = useState<T>();

  // Note: to make sure that the callback can be updated between calls,
  // we need to store it on an object that's local to this particular
  // component.

  const [cbObj, _] = useState<any>({});
  cbObj.cb = cb;

  useEffect(() => {
    setRes(cbObj.cb());
    const interval = setInterval(() => {
      setRes(cbObj.cb());
    }, ms);
    return () => clearInterval(interval);
  }, [ms, cbObj]);

  return res!;
}

export function formatHumanTime(
  value: string | number | Date | Moment | null,
  now?: Moment,
  hideSeconds?: boolean,
): string {
  const time = value ? moment(value) : null;
  if (!time || !time.toISOString(true)) {
    return '–';
  }

  if (!now) {
    now = moment();
  }

  const delta = (now.diff(time)) / 1000;
  const absDelta = Math.abs(delta);
  const suffix = delta > 0 ? 'ago' : 'from now';
  return (
    absDelta < 10
      ? `just now`
      : absDelta < 60
      ? `${Math.floor(absDelta)} seconds ${suffix}`
      : delta < 60 * 60
      ? `${Math.floor(absDelta / 60)} minutes ${suffix}`
      : delta < 60 * 60 * 24
      ? `${Math.floor(absDelta / 60 / 60)} hours ${suffix}`
      : hideSeconds
      ? time.toISOString(true)
        .replace('T', ' ')
        .replace(/-\d+:\d+$/, '') // Strip timezone
        .replace(/\.\d+$/, '') // Strip fractions of a second
        .replace(/\:\d+$/, '') // Strip seconds
      : time.toISOString(true)
        .replace('T', ' ')
        .replace(/-\d+:\d+$/, '') // Strip timezone
        .replace(/\.\d+$/, '') // Strip fractions of a second
  );
}

export function HumanTime(props: {
  value: string | number | Date | Moment | null | undefined,
  isUTC?: boolean,
  now?: Moment,
  hideSeconds?: boolean,
}) {
  const time = (props.value && props.isUTC)
    ? moment.utc(props.value).local()
    : props.value
    ? moment(props.value)
    : null;
  const now = props.now || moment().local();

  const humanTime = formatHumanTime(time, now, props.hideSeconds);

  const delta = Math.abs(now.diff(time || 0) / 1000);
  const appropriateInterval = delta < 60
    ? 1000
    : delta < 60 * 60
    ? 60 * 1000
    : 60 * 60 * 1000;
  const [forceUpdateCounter, setForceUpdateCounter] = useState(0);
  useInterval(appropriateInterval, () => setForceUpdateCounter(forceUpdateCounter + 1));

  if (!time || !time.toISOString()) {
    return <span>–</span>;
  }

  return <span style={{ whiteSpace: 'nowrap' }} title={time.toISOString().replace('T', ' ')}>{humanTime}</span>;
}
